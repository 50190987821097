import React, { useEffect, useState } from "react";
import "../../assets/styles/MakeProductStyle/MakeProduct.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ShowAlert, ProductError } from "../../action/index";
import TopBar from "../../components/TopBar/TopBar";
import Upload from "../../components/ProductGallery/UploadImage";
import AddProperty from "../../components/AddProperty/AddProperty";
import SimpleProduct from "../../components/SimpleProduct/SimpleProduct";
import AddTag from "../../components/AddTag/AddTag";
import CategoryProduct from "../../components/CategoryProduct/CategoryProduct";
import BarresiMahsool from "../../components/CheckProduct/checkProduct";
import ShowEye from "../../assets/icons/ShowEye.svg";
import BookMark from "../../assets/icons/Bookmark.svg";
import UploadIcon from "../../assets/icons/Upload.svg";
import Seo from "../../components/AddSeo/Seo";
import IntroductionProduction from "../../components/IntroductionProduction/IntroductionProduction";
import axios from "../../js/Axios/Axios";
import useForceUpdate from "use-force-update";

export default function Product() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();
  const Product_info = useSelector((state) => state.ProductInfo);
  const Tag_id = useSelector((state) => state.TagId);
  const Categorys_id = useSelector((state) => {
    return state.SendCategoryId;
  });
  const productProperty = useSelector((state) => state.SendPropertyValueId);
  const seoInfo = useSelector((state) => state.SendSeoInfo);
  const seoQuestionAnswer = useSelector((state) => state.SendSeoQuestionAnswer);
  const productReview = useSelector((state) => state.SendProductReview);
  const productgalleryinfo = useSelector(
    (state) => state.SendProductGalleryInfo
  );
  const simpleProductPrice = useSelector(
    (state) => state.SendSimpleProductPrice
  );
  const variableProductPrice = useSelector(
    (state) => state.SendVariableProductPrice
  );
  const productPriceType = useSelector((state) => state.ProductPriceType);
  const [axiosProductReview, setAxiosProductReview] = useState(false);
  const [finalProductInfo, setFinalProductInfo] = useState({
    title: "",
    latin_title: "",
    url: "",
    country: "",
    brand_name: "",
    barcode: "",
    product_introduction: "",
    temp_status: null,
  });

  useEffect(() => {
    setFinalProductInfo(Product_info.Product_info);
  }, [Product_info]);

  function sendProductClick(temp_status) {
    if (temp_status === 1) {
      // if (
      //   (simpleProductPrice.simpleProductPrice[0].price != null &&
      //     simpleProductPrice.simpleProductPrice[0].height != null &&
      //     simpleProductPrice.simpleProductPrice[0].length != null &&
      //     simpleProductPrice.simpleProductPrice[0].width != null &&
      //     simpleProductPrice.simpleProductPrice[0].weight != null) ||
      //     variableProductPrice.variableProductPrice.length != 0
      // ) {
      axios
        .post("/product", {
          title: finalProductInfo["title"],
          latin_title: finalProductInfo["latin_title"],
          url: finalProductInfo["url"],
          country: finalProductInfo["country"],
          brand_name: finalProductInfo["brand_name"],
          barcode: finalProductInfo["barcode"],
          product_introduction: finalProductInfo["product_introduction"],
          productTags: Tag_id.Tag_id,
          temp_status: temp_status,
          productCategories: Categorys_id.categorysId,
        })
        .then((res) => {
          console.log(res);
          if (res.data.status === 0) {
            if (Product_info.Product_info.title === "") {
              dispatch(ShowAlert(true, "پر کردن فیلد عنوان محصول الزامی است"));
              dispatch(ProductError({ title: true }));
            }
            if (variableProductPrice.variableProductPrice.length === 0) {
              dispatch(
                ProductError({
                  price: true,
                  weight: true,
                  height: true,
                  length: true,
                  width: true,
                  min_order: true,
                  max_order: true,
                })
              );
              dispatch(
                ShowAlert(
                  true,
                  "کامل کردن قیمت و ارتفاع و طول و عرض و وزن الزامی است ."
                )
              );
            }
            if (
              simpleProductPrice.simpleProductPrice[0].price === null &&
              simpleProductPrice.simpleProductPrice[0].height === null &&
              simpleProductPrice.simpleProductPrice[0].length === null &&
              simpleProductPrice.simpleProductPrice[0].width === null &&
              simpleProductPrice.simpleProductPrice[0].weight === null
            ) {
              dispatch(
                ProductError({
                  price: true,
                  weight: true,
                  height: true,
                  length: true,
                  width: true,
                })
              );
              dispatch(
                ShowAlert(
                  true,
                  "کامل کردن قیمت و ارتفاع و طول و عرض و وزن الزامی است ."
                )
              );
            }
          }
          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));
            productProperty.productProperties.map((item) => {
              item.product_id = res.data.data.id;
            });
            productReview.map((item) => {
              item.product_id = res.data.data.id;
            });
            seoInfo.seo_info.product_id = res.data.data.id;

            productgalleryinfo.productGalleries.map((item) => {
              item.product_id = res.data.data.id;
            });
            if (variableProductPrice.variableProductPrice.length !== 0) {
              variableProductPrice.variableProductPrice.map((item) => {
                item.product_id = res.data.data.id;
              });
            } else {
              if (
                simpleProductPrice.simpleProductPrice[0].price != null &&
                simpleProductPrice.simpleProductPrice[0].height != null &&
                simpleProductPrice.simpleProductPrice[0].length != null &&
                simpleProductPrice.simpleProductPrice[0].width != null &&
                simpleProductPrice.simpleProductPrice[0].weight != null
              ) {
                simpleProductPrice.simpleProductPrice[0].product_id =
                  res.data.data.id;
              }
            }
            if (productPriceType === 1) {
              if (variableProductPrice.variableProductPrice.length !== 0) {
                axios
                  .post(
                    "/productPrice",
                    variableProductPrice.variableProductPrice
                  )
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }
            }

            if (productPriceType === 0) {
              if (
                simpleProductPrice.simpleProductPrice[0].price != null &&
                simpleProductPrice.simpleProductPrice[0].height != null &&
                simpleProductPrice.simpleProductPrice[0].length != null &&
                simpleProductPrice.simpleProductPrice[0].width != null &&
                simpleProductPrice.simpleProductPrice[0].weight != null
              ) {
                axios
                  .post("/productPrice", simpleProductPrice.simpleProductPrice)
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }
            }

            if (productProperty.productProperties.length !== 0) {
              axios
                .post("/productProperty", productProperty)
                .then((res) => {
                  if (res.data.status === 0) {
                    dispatch(ShowAlert(true, res.data.messages));
                  }
                  if (res.data.status === 1) {
                    dispatch(ShowAlert(true, res.data.messages));
                  }
                  console.log(res);
                })
                .catch((e) => {
                  console.log(e);
                });
            }
            if (productgalleryinfo.productGalleries.length !== 0) {
              axios
                .post("/productGallery", productgalleryinfo)
                .then((res) => {
                  console.log(res);
                })
                .catch((e) => {
                  console.log(e);
                });
            }
            let checkValueProductReview = false;
            productReview.forEach((item) => {
              if (
                item.description != null &&
                item.media_id != null &&
                item.product_id != null &&
                item.title != null
              ) {
                checkValueProductReview = true;
              }
            });

            if (checkValueProductReview) {
              axios
                .post("/productReview", productReview)
                .then((res) => {
                  console.log(res);
                })
                .catch((e) => {
                  console.log(e);
                });
            }
            if (
              (seoInfo.seo_info.canonical != null,
              seoInfo.seo_info.meta != null,
              seoInfo.seo_info.title != null,
              seoInfo.seo_info.url != null)
            ) {
              axios
                .post("/productSeo", seoInfo.seo_info)
                .then((res) => {
                  console.log(res);
                  if (res.data.status) {
                    seoQuestionAnswer.productSeo_id = res.data.data.id;
                    if (seoQuestionAnswer.faqs.length > 1) {
                      seoQuestionAnswer.faqs.map((item, index) => {
                        if (
                          (item.question === null || item.question === "") &&
                          (item.answer === null || item.answer === "")
                        ) {
                          console.log(index);
                          seoQuestionAnswer.faqs.splice(index, 1);
                        }
                      });
                    }
                    axios
                      .post("/productSeoFaq", seoQuestionAnswer)
                      .then((res) => {
                        console.log(res);
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  }
                })
                .catch((e) => {
                  console.log(e);
                });
            }
            navigate("/AllProduct");
          }
        })
        .catch((e) => {
          console.log(e);
        });
      // } else {
      //   dispatch(
      //     ShowAlert(true, ["برای انتشار محصول فیلد قیمت و ابعاد الزامی است"])
      //   );
      // }
    } else {
      axios
        .post("/product", {
          title: finalProductInfo["title"],
          latin_title: finalProductInfo["latin_title"],
          url: finalProductInfo["url"],
          country: finalProductInfo["country"],
          brand_name: finalProductInfo["brand_name"],
          barcode: finalProductInfo["barcode"],
          product_introduction: finalProductInfo["product_introduction"],
          productTags: Tag_id.Tag_id,
          temp_status: temp_status,
          productCategories: Categorys_id.categorysId,
        })
        .then((res) => {
          console.log(res);
          if (res.data.status === 0) {
            dispatch(ShowAlert(true, res.data.data.title));
            if (Product_info.Product_info.title === "") {
              dispatch(ShowAlert(true, "پر کردن فیلد عنوان محصول الزامی است"));
              dispatch(ProductError({ title: true }));
            }
          }
          if (res.data.status) {
            productProperty.productProperties.map((item) => {
              item.product_id = res.data.data.id;
            });
            productReview.map((item) => {
              item.product_id = res.data.data.id;
            });
            seoInfo.seo_info.product_id = res.data.data.id;

            productgalleryinfo.productGalleries.map((item) => {
              item.product_id = res.data.data.id;
            });
            if (variableProductPrice.variableProductPrice.length !== 0) {
              variableProductPrice.variableProductPrice.map((item) => {
                item.product_id = res.data.data.id;
              });
            } else {
              if (simpleProductPrice.simpleProductPrice.length !== 0) {
                simpleProductPrice.simpleProductPrice[0].product_id =
                  res.data.data.id;
              }
            }
            if (productPriceType === 1) {
              if (variableProductPrice.variableProductPrice.length !== 0) {
                axios
                  .post(
                    "/productPrice",
                    variableProductPrice.variableProductPrice
                  )
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }
            }

            if (productPriceType === 0) {
              if (simpleProductPrice.simpleProductPrice.length !== 0) {
                axios
                  .post("/productPrice", simpleProductPrice.simpleProductPrice)
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }
            }

            if (productProperty.productProperties.length !== 0) {
              axios
                .post("/productProperty", productProperty)
                .then((res) => {
                  console.log(res);
                })
                .catch((e) => {
                  console.log(e);
                });
            }
            if (productgalleryinfo.productGalleries.length !== 0) {
              axios
                .post("/productGallery", productgalleryinfo)
                .then((res) => {
                  console.log(res);
                })
                .catch((e) => {
                  console.log(e);
                });
            }

            let checkValueProductReview = false;
            productReview.forEach((item) => {
              if (
                item.description != null &&
                item.media_id != null &&
                item.product_id != null &&
                item.title != null
              ) {
                checkValueProductReview = true;
              }
            });

            if (checkValueProductReview) {
              axios
                .post("/productReview", productReview)
                .then((res) => {
                  console.log(res);
                })
                .catch((e) => {
                  console.log(e);
                });
            }

            if (
              (seoInfo.seo_info.canonical != null,
              seoInfo.seo_info.meta != null,
              seoInfo.seo_info.title != null,
              seoInfo.seo_info.url != null)
            ) {
              axios
                .post("/productSeo", seoInfo.seo_info)
                .then((res) => {
                  console.log(res);
                  if (res.data.status) {
                    seoQuestionAnswer.productSeo_id = res.data.data.id;
                    if (seoQuestionAnswer.faqs.length > 1) {
                      seoQuestionAnswer.faqs.map((item, index) => {
                        if (
                          (item.question === null || item.question === "") &&
                          (item.answer === null || item.answer === "")
                        ) {
                          console.log(index);
                          seoQuestionAnswer.faqs.splice(index, 1);
                        }
                      });
                    }

                    axios
                      .post("/productSeoFaq", seoQuestionAnswer)
                      .then((res) => {
                        console.log(res);
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  }
                })
                .catch((e) => {
                  console.log(e);
                });
            }
            navigate("/AllDraftProduct");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }
  return (
    <div className="ProductAll">
      <TopBar />
      <div className="productBox">
        <div className="ProductAddressProductTopButttonBox">
          {/* <div className="ProductPageAddressBox">
          <p className="ProductPageAddressTittle">&nbsp;محصولات</p>
          <p></p>
          <p className="ProductPageAddressTittle"> &gt;</p>
          <p className="ProductPageAddressTittle">&nbsp;ایجاد محصول&nbsp;</p>
          <p className="ProductPageAddressTittle"> &gt;</p>
          <p className="ProductPageAddressTittle">ذخیره محصول&nbsp;</p>
        </div> */}
          <div className="ProductTopButtonBox">
            <button
              onClick={() => sendProductClick(1)}
              className={
                simpleProductPrice.simpleProductPrice.length !== 0 ||
                variableProductPrice.variableProductPrice.length !== 0
                  ? "DownButtonShareProduct"
                  : "DownButtonShareProductDisabled"
              }
            >
              <p className="ProductShowButtonTittle"> محصول منتشر شود </p>
              <img className="ProductShareButtonImg" src={UploadIcon} />
              {/* <IosShareIcon className="ProductShowButtonIcon" /> */}
            </button>
            <button
              onClick={() => sendProductClick(0)}
              className="ProductSaveButton"
            >
              <img className="ProductSaveButtonImg" src={BookMark} />
              {/* <BookmarkBorderIcon className="ProductShowButtonIcon" /> */}
              <p className="ProductShowButtonTittle"> ذخیره در پیش‌نویس ها</p>
            </button>
            {/* <button className="ProductShowButton">
              <img className="ProductShowButtonImg" src={ShowEye} />
              <p className="ProductShowButtonTittle">نمایش محصول</p>
            </button> */}
          </div>
        </div>
        {/* /////////////////////moarefie mahsool */}

        <IntroductionProduction />
        {/* /////////////////////////gallery mahsool */}
        <Upload />
        {/* /////////////////////////mahsoole sade mahsoole moteghayer afzoodane vizegi */}

        <div className="MahsoolBox">
          <div className="MahsoolBoxMahsooleSadeBox">
            <SimpleProduct />
          </div>
          <div className="MahsoolBoxAfzoodanVijegiBox">
            <AddProperty />
          </div>
        </div>

        {/* /////////////////////////////dastebandie mahsoolat afzoodane barchasb */}
        {/* <div className="DastebandiBox"> */}
        <div className="DastebandiBox2">
          <div className="AfzoodanBarchasbBox">
            <AddTag />
          </div>
          <div className="dasteBandiMahsoolatBox">
            <CategoryProduct />
          </div>
        </div>
        {/* </div> */}

        {/* barresssssiiiiiiiii mahsoolat */}
        <BarresiMahsool />

        {/* /////////////////////////////Afzoodan seo */}
        <Seo />
        {/* ///////////////// */}
        <div className="DownButtonBox">
          <button
            onClick={() => sendProductClick(1)}
            className={
              simpleProductPrice.simpleProductPrice.length !== 0 ||
              variableProductPrice.variableProductPrice.length !== 0
                ? "DownButtonShareProduct"
                : "DownButtonShareProductDisabled"
            }
          >
            <p className="ProductShowButtonTittle"> محصول منتشر شود </p>
            {/* <IosShareIcon className="ProductShowButtonIcon" /> */}
            <img className="ProductShareButtonImg" src={UploadIcon} />
          </button>

          <button
            onClick={() => sendProductClick(0)}
            className="DownButtonSaveInList"
          >
            <p className="ProductShowButtonTittle"> ذخیره در پیش‌نویس ها</p>
            {/* <BookmarkBorderIcon className="ProductShowButtonIcon" /> */}
            <img className="ProductSaveButtonImg" src={BookMark} />
          </button>
        </div>
      </div>
    </div>
  );
}
