import React, { useState } from "react";
import "../../../assets/styles/PhoneNumberRegisterStyle/PhoneNumberRegister.css";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { ShowAlert } from "../../../action/index";
import useForceUpdate from "use-force-update";
import axios from "../../../js/Axios/Axios";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { AddAdminInfo } from "../../../action/index";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import calling from "../../../assets/icons/Iconly-Bulk-Calling.svg";

function PhoneNUmberRegister() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const forcUpdate = useForceUpdate();
  const [activityIndicator, setActivityIndicator] = useState(false);
  const [Number, setNumber] = useState({
    phone_number: "",
    is_owner: 1,
  });
  const sendName = () => {
    setActivityIndicator(true);
    forcUpdate();
    axios
      .post("/UserSystem/register/phoneNumber", Number)
      .then((res) => {
        console.log(res);
        if (res.data.status === 0) {
          dispatch(ShowAlert(true, res.data.message));
          setActivityIndicator(false);
          forcUpdate();
        }
        if (res.data.status) {
          dispatch(AddAdminInfo(Number.phone_number));
          dispatch(ShowAlert(true, res.data.message));
          setActivityIndicator(false);
          forcUpdate();
          navigate("/VerifyCode");
        }
      })
      .catch((e) => {
        console.log(e);
        setActivityIndicator(false);
        forcUpdate();
      });
  };
  const sendNameEnter = (e) => {
    console.log(e);
    if (e.key === "Enter") {
      sendName();
    }
  };

  return (
    <div className="PhoneRegisterAll">
      <div className="aut-img">{/* <img  src={login_img}/> */}</div>
      <div className="PhoneRegisterBox">
        <h3 className="PhoneRegisterFirstTittleBox">ایجاد فروشگاه</h3>

        <p className="Phone-Register-Second-Tittle">
          فقط یک قدم تا ایجاد فروشگاه فاصله دارید.
        </p>

        <hr className="PhoneRegisterEdge" />

        <div className="PhoneRegisterInputBox">
          <div className="PhoneRegisterInputBoxName">
            <img src={calling} alt="" className="PhoneRegisterInputNameIcon" />

            <input
              onChange={(e) =>
                setNumber((prev) => {
                  return {
                    is_owner: prev.is_owner,
                    phone_number: e.target.value,
                  };
                })
              }
              type="number"
              className="PhoneRegisterNameInput"
              placeholder="لطفا شماره موبایل خود را وارد کنید"
            />
          </div>
        </div>

        <div className="PhoneRegisterButtonsBox">
          <button
            onKeyDown={(e) => sendNameEnter(e)}
            onClick={sendName}
            className="PhoneRegisterRegisterButton"
          >
            {activityIndicator === false ? (
              "ثبت ‌نام"
            ) : (
              <CircularProgress className="aut-button-progress" />
            )}
          </button>
        </div>
        <div className="Aut-Login-Under-Box-Down-Box">
          <p className="Aut-Login-Forget-Register-Tittle">
            {" "}
            قبلا در فروشگاه ثبت نام کردید؟{" "}
          </p>
          <button
            onClick={() => navigate("/")}
            className="Aut-Login-Under-Box-Down-Box-Button"
          >
            ورود به ویتسل
          </button>
        </div>
      </div>
    </div>
  );
}
export default PhoneNUmberRegister;
