import React, { useEffect, useState } from "react";
import "../../assets/styles/AllProductUpdateStyle/AllProductUpdate.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import { ResetToInit } from "../../action/index";
import { useSelector, useDispatch } from "react-redux";
import { ProductInfoForEdit } from "../../action/index";
import { Navigate } from "react-router-dom";
import { ShowAlert, ProductError } from "../../action/index";
import axios from "../../js/Axios/Axios";
import TopBar from "../../components/TopBar/TopBar";
import ShowInStore from "../../components/ShowProductInStore/ShowProductInStore";
import ProductIntroDuction from "../../components/IntroductionProduction/IntroductionProduction";
import ProductGallery from "../../components/ProductGallery/UploadImage";
import AddProperty from "../../components/AddProperty/AddProperty";
import ProductPrice from "../../components/SimpleProduct/SimpleProduct";
import AddTag from "../../components/AddTag/AddTag";
import CategoryProduct from "../../components/CategoryProduct/CategoryProduct";
import CheckProduct from "../../components/CheckProduct/checkProduct";
import AddSeo from "../../components/AddSeo/Seo";
import UploadIcon from "../../assets/icons/Upload.svg";
import BookMark from "../../assets/icons/Bookmark.svg";
import useForceUpdate from "use-force-update";
import { useLocation } from "react-router-dom";
export default function AllProductUpdate() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const [allData, setAllData] = useState({});
  const Product_info = useSelector((state) => state.ProductInfo);
  const Categorys_id = useSelector((state) => state.SendCategoryId);
  const productProperty = useSelector((state) => state.SendPropertyValueId);
  const productReview = useSelector((state) => state.SendProductReview);
  const Tag_id = useSelector((state) => state.TagId);
  const seoInfo = useSelector((state) => state.SendSeoInfo);
  const seoQuestionAnswer = useSelector((state) => state.SendSeoQuestionAnswer);
  const productgalleryinfo = useSelector(
    (state) => state.SendProductGalleryInfo
  );
  const simpleProductPrice = useSelector(
    (state) => state.SendSimpleProductPrice
  );
  const variableProductPrice = useSelector(
    (state) => state.SendVariableProductPrice
  );

  const id = useSelector((state) => state.UpdateAllProductIds);

  useEffect(() => {
    axios
      .get(`/product/${id.inistateProductid}/edit`)
      .then((res) => {
        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));
          console.log(res);
          setAllData(res.data.data);
          dispatch(ProductInfoForEdit(res.data.data));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const shareProdcut = (temp_status) => {
    console.log(temp_status);
    if (temp_status === 1) {
      if (
        (simpleProductPrice.simpleProductPrice[0].price != null &&
          simpleProductPrice.simpleProductPrice[0].height != null &&
          simpleProductPrice.simpleProductPrice[0].length != null &&
          simpleProductPrice.simpleProductPrice[0].width != null &&
          simpleProductPrice.simpleProductPrice[0].weight) ||
        variableProductPrice.variableProductPrice.length != 0
      ) {
        axios
          .put(`/product/${id.inistateProductid}`, {
            title: Product_info.Product_info.title,
            latin_title: Product_info.Product_info.latin_title,
            url: Product_info.Product_info.url,
            country: Product_info.Product_info.country,
            brand_name: Product_info.Product_info.brand_name,
            barcode: Product_info.Product_info.barcode,
            product_introduction:
              Product_info.Product_info.product_introduction,
            productTags: Tag_id.Tag_id,
            temp_status: temp_status,
            productCategories: Categorys_id.categorysId,
          })
          .then((res) => {
            console.log(res);
            if (res.data.status) {
              dispatch(ShowAlert(true, res.data.message));
              if (productgalleryinfo.productGalleries.length !== 0) {
                productgalleryinfo.productGalleries.map((item) => {
                  item.product_id = id.inistateProductid;
                });
                productgalleryinfo.editProduct_id = id.inistateProductid;
                axios
                  .post("/productGallery", productgalleryinfo)
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }
              if (productProperty.productProperties.length !== 0) {
                productProperty.productProperties.map((item) => {
                  item.product_id = id.inistateProductid;
                });
                productProperty.isProductEdit = 1;
                axios
                  .post("/productProperty", productProperty)
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }
              productReview.map((item) => {
                item.product_id = id.inistateProductid;
              });
              let checkValueProductReview = false;
              productReview.forEach((item) => {
                if (
                  item.description != null &&
                  item.media_id != null &&
                  item.product_id != null &&
                  item.title != null
                ) {
                  checkValueProductReview = true;
                }
              });

              if (checkValueProductReview) {
                axios
                  .post("/productReview", productReview)
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }
              if (
                (seoInfo.seo_info.canonical != null,
                seoInfo.seo_info.meta != null,
                seoInfo.seo_info.title != null,
                seoInfo.seo_info.url != null)
              ) {
                const product_id = { product_id: id.inistateProductid };
                const finalSeo = Object.assign(seoInfo.seo_info, product_id);
                console.log(seoInfo.seo_info.seoId);
                if (seoInfo.seo_info.seoId != null) {
                  axios
                    .put(`/productSeo/${seoInfo.seo_info.seoId}`, finalSeo)
                    .then((res) => {
                      console.log(res);
                      if (res.data.status) {
                        seoQuestionAnswer.productSeo_id = res.data.data.id;
                        seoQuestionAnswer.is_edit = 1;
                        let array = {
                          productSeo_id: seoQuestionAnswer.productSeo_id,
                          is_edit: seoQuestionAnswer.is_edit,
                          faqs: [],
                        };
                        // if (seoQuestionAnswer.faqs.length > 1) {
                        console.log("first if");
                        seoQuestionAnswer.faqs.map((item, index) => {
                          console.log(item);
                          if (
                            item.question != null &&
                            item.question != "" &&
                            item.answer != null &&
                            item.answer != ""
                          ) {
                            console.log(index);
                            array.faqs.push(item);
                          }
                        });
                        forceUpdate();
                        // }
                        axios
                          .post("/productSeoFaq", array)
                          .then((res) => {
                            console.log(res);
                          })
                          .catch((e) => {
                            console.log(e);
                          });
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                } else {
                  axios
                    .post("/productSeo", finalSeo)
                    .then((res) => {
                      console.log(res);
                      if (res.data.status) {
                        seoQuestionAnswer.productSeo_id = res.data.data.id;
                        seoQuestionAnswer.is_edit = 0;
                        let array = {
                          productSeo_id: seoQuestionAnswer.productSeo_id,
                          is_edit: seoQuestionAnswer.is_edit,
                          faqs: [],
                        };
                        // if (seoQuestionAnswer.faqs.length > 1) {
                        console.log("first if");
                        seoQuestionAnswer.faqs.map((item, index) => {
                          console.log(item);
                          if (
                            item.question != null &&
                            item.question != "" &&
                            item.answer != null &&
                            item.answer != ""
                          ) {
                            console.log(index);
                            array.faqs.push(item);
                          }
                        });
                        forceUpdate();
                        // }
                        axios
                          .post("/productSeoFaq", array)
                          .then((res) => {
                            console.log(res);
                          })
                          .catch((e) => {
                            console.log(e);
                          });
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }
              }
              if (variableProductPrice.variableProductPrice.length === 0) {
                if (allData.productPrices.length !== 0) {
                  allData.productPrices.map((item, index) => {
                    simpleProductPrice.simpleProductPrice[0].editPrice_id =
                      item.id;
                  });
                } else {
                  simpleProductPrice.simpleProductPrice[0].product_id =
                    allData.id;
                }
                axios
                  .post("/productPrice", simpleProductPrice.simpleProductPrice)
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              } else if (variableProductPrice.variableProductPrice.length > 0) {
                if (allData.productPrices.length !== 0) {
                  allData.productPrices.map((item, index) => {
                    variableProductPrice.variableProductPrice[
                      index
                    ].editPrice_id = item.id;
                  });
                }
                variableProductPrice.variableProductPrice.map((item, index) => {
                  item.product_id = allData.id;
                });
                axios
                  .post(
                    "/productPrice",
                    variableProductPrice.variableProductPrice
                  )
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }
              // if (variableProductPrice.variableProductPrice.length === 0) {
              //   if (allData.productPrices.length !== 0) {
              //     allData.productPrices.map((item, index) => {
              //       simpleProductPrice.simpleProductPrice[index].editPrice_id =
              //         item.id;
              //     });
              //   } else {
              //     simpleProductPrice.simpleProductPrice[0].product_id =
              //       allData.id;
              //   }
              //   axios
              //     .post("/productPrice", simpleProductPrice.simpleProductPrice)
              //     .then((res) => {
              //       console.log(res);
              //     })
              //     .catch((e) => {
              //       console.log(e);
              //     });
              // } else if (variableProductPrice.variableProductPrice.length > 0) {
              //   if (allData.productPrices.length !== 0) {
              //     allData.productPrices.map((item, index) => {
              //       variableProductPrice.variableProductPrice[
              //         index
              //       ].editPrice_id = item.id;
              //     });
              //   } else {
              //     variableProductPrice.variableProductPrice.map(
              //       (item, index) => {
              //         item.product_id = allData.id;
              //       }
              //     );
              //   }
              //   axios
              //     .post(
              //       "/productPrice",
              //       variableProductPrice.variableProductPrice
              //     )
              //     .then((res) => {
              //       console.log(res);
              //     })
              //     .catch((e) => {
              //       console.log(e);
              //     });
              // }
            }
            dispatch(ResetToInit());
            navigate("/AllDraftProduct");
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        dispatch(
          ProductError({
            price: true,
            weight: true,
            height: true,
            length: true,
            width: true,
          })
        );
        dispatch(
          ShowAlert(true, "برای انتشار محصول فیلد قیمت و ابعاد الزامی است")
        );
      }
    } else {
      axios
        .put(`/product/${id.inistateProductid}`, {
          title: Product_info.Product_info.title,
          latin_title: Product_info.Product_info.latin_title,
          url: Product_info.Product_info.url,
          country: Product_info.Product_info.country,
          brand_name: Product_info.Product_info.brand_name,
          barcode: Product_info.Product_info.barcode,
          product_introduction: Product_info.Product_info.product_introduction,
          productTags: Tag_id.Tag_id,
          temp_status: temp_status,
          productCategories: Categorys_id.categorysId,
        })
        .then((res) => {
          console.log(res);
          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));
            if (productgalleryinfo.productGalleries.length !== 0) {
              productgalleryinfo.productGalleries.map((item) => {
                item.product_id = id.inistateProductid;
              });
              productgalleryinfo.editProduct_id = id.inistateProductid;
              axios
                .post("/productGallery", productgalleryinfo)
                .then((res) => {
                  console.log(res);
                })
                .catch((e) => {
                  console.log(e);
                });
            }
            if (productProperty.productProperties.length !== 0) {
              productProperty.productProperties.map((item) => {
                item.product_id = id.inistateProductid;
              });
              productProperty.isProductEdit = 1;
              axios
                .post("/productProperty", productProperty)
                .then((res) => {
                  console.log(res);
                })
                .catch((e) => {
                  console.log(e);
                });
            }
            productReview.map((item) => {
              item.product_id = id.inistateProductid;
            });
            let checkValueProductReview = false;
            productReview.forEach((item) => {
              if (
                item.description != null &&
                item.media_id != null &&
                item.product_id != null &&
                item.title != null
              ) {
                checkValueProductReview = true;
              }
            });

            if (checkValueProductReview) {
              axios
                .post("/productReview", productReview)
                .then((res) => {
                  console.log(res);
                })
                .catch((e) => {
                  console.log(e);
                });
            }
            if (
              (seoInfo.seo_info.canonical != null,
              seoInfo.seo_info.meta != null,
              seoInfo.seo_info.title != null,
              seoInfo.seo_info.url != null)
            ) {
              const product_id = { product_id: id.inistateProductid };
              const finalSeo = Object.assign(seoInfo.seo_info, product_id);
              if (seoInfo.seo_info.seoId != null) {
                axios
                  .put(`/productSeo/${seoInfo.seo_info.seoId}`, finalSeo)
                  .then((res) => {
                    console.log(res);
                    if (res.data.status) {
                      seoQuestionAnswer.productSeo_id = res.data.data.id;
                      seoQuestionAnswer.is_edit = 1;

                      let array = {
                        productSeo_id: seoQuestionAnswer.productSeo_id,
                        is_edit: seoQuestionAnswer.is_edit,
                        faqs: [],
                      };
                      // if (seoQuestionAnswer.faqs.length > 1) {
                      seoQuestionAnswer.faqs.map((item, index) => {
                        if (
                          item.question != null &&
                          item.question != "" &&
                          item.answer != null &&
                          item.answer != ""
                        ) {
                          console.log(index);
                          array.faqs.push(item);
                        }
                      });
                      forceUpdate();
                      // }
                      axios
                        .post("/productSeoFaq", array)
                        .then((res) => {
                          console.log(res);
                        })
                        .catch((e) => {
                          console.log(e);
                        });
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              } else {
                axios
                  .post("/productSeo", finalSeo)
                  .then((res) => {
                    console.log(res);
                    if (res.data.status) {
                      seoQuestionAnswer.productSeo_id = res.data.data.id;
                      seoQuestionAnswer.is_edit = 0;

                      let array = {
                        productSeo_id: seoQuestionAnswer.productSeo_id,
                        is_edit: seoQuestionAnswer.is_edit,
                        faqs: [],
                      };
                      // if (seoQuestionAnswer.faqs.length > 1) {
                      seoQuestionAnswer.faqs.map((item, index) => {
                        console.log(item);
                        if (
                          item.question != null &&
                          item.question != "" &&
                          item.answer != null &&
                          item.answer != ""
                        ) {
                          array.faqs.push(item);
                        }
                      });
                      forceUpdate();
                      // }
                      axios
                        .post("/productSeoFaq", array)
                        .then((res) => {
                          console.log("zzzzz", res);
                        })
                        .catch((e) => {
                          console.log(e);
                        });
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }
            }
            if (variableProductPrice.variableProductPrice.length === 0) {
              if (allData.productPrices.length !== 0) {
                allData.productPrices.map((item, index) => {
                  simpleProductPrice.simpleProductPrice[0].editPrice_id =
                    item.id;
                });
              // } else {
                simpleProductPrice.simpleProductPrice[0].product_id =
                  allData.id;
              }
              axios
                .post("/productPrice", simpleProductPrice.simpleProductPrice)
                .then((res) => {
                  console.log(res);
                })
                .catch((e) => {
                  console.log(e);
                });
            } else if (variableProductPrice.variableProductPrice.length > 0) {
              if (allData.productPrices.length !== 0) {
                allData.productPrices.map((item, index) => {
                  variableProductPrice.variableProductPrice[
                    index
                  ].editPrice_id = item.id;
                });
              }
              variableProductPrice.variableProductPrice.map((item, index) => {
                item.product_id = allData.id;
              });
              axios
                .post(
                  "/productPrice",
                  variableProductPrice.variableProductPrice
                )
                .then((res) => {
                  console.log(res);
                })
                .catch((e) => {
                  console.log(e);
                });
            }
          }
          dispatch(ResetToInit());
          navigate("/AllDraftProduct");
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <div className="AllProductUpdateAll">
      <div className="AllProductUpdateTopBar">
        <TopBar />
      </div>
      <div className="AllProductUpdateTopButtonBox">
        <button
          onClick={() => shareProdcut(1)}
          className="AllProductUpdateTopButtonShareProduct"
        >
          <img src={UploadIcon} />
          <p className="AllProductUpdateTopButtonShareProductTittle">
            محصول منتشر شود
          </p>
        </button>
        <button
          onClick={() => shareProdcut(0)}
          className="AllProductUpdateTopButtonSaveProductTittle"
        >
          <img src={BookMark} />
          <p className="AllProductUpdateTopButtonShareProductTittle">
            ذخیره در پیش‌نویس ها
          </p>
        </button>
      </div>
      <div className="AllProductUpdateStore">
        {id.inistateProductid === "" && <Navigate to="/AllDraftProduct" />}
        <ShowInStore data={allData} />
      </div>
      <div className="AllProductUpdateComponentBox">
        <div className="AllProductUpdateIntroductionBox">
          <ProductIntroDuction />
        </div>
        <div className="AllProductUpdateProductGalleryBox">
          <ProductGallery />
        </div>
        <div className="AllProductUpdatePriceandPropertyBox">
          <div className="AllProductUpdatePriceBox">
            <ProductPrice />
          </div>
          <div className="AllProductUpdatePropertyBox">
            <AddProperty />
          </div>
        </div>
        <div className="AllProductUpdateAddTagandAddCategory">
          <div className="AllProductUpdateAddTagBox">
            <AddTag />
          </div>
          <div className="AllProductUpdateAddCategoryBox">
            <CategoryProduct />
          </div>
        </div>
        <div className="AllProductUpdateProductReview">
          <CheckProduct />
        </div>
        <div className="AllProductUpdateAddSeo">
          <AddSeo />
        </div>
      </div>
    </div>
  );
}
