import React, { useEffect, useState } from "react";
import "../../assets/styles/EditStore/EditStoreOption.css";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import axios from "../../js/Axios/Axios";
import { ShowAlert } from "../../action/index";
import { useSelector, useDispatch } from "react-redux";
import useForceUpdate from "use-force-update";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import Uploadinmedia from "../../assets/icons/Uploadinmedia.svg";
import professionalConsulting from "../../assets/images/professional-consulting.svg";
import sadDarsad from "../../assets/images/sad-darsad.svg";
import expressNew from "../../assets/images/express-new.svg";
import whiteEdit from "../../assets/icons/whiteEdit.svg";
import cross from "../../assets/icons/cross.svg";
import PictureIcon from "../../assets/images/PictureIcon.svg";
import Delete from "../../assets/icons/Delete.svg";
import DeleteRed from "../../assets/icons/DeleteRed.svg";
import UploadImg from "../../assets/icons/UploadImg.svg";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { convertObjectToArrOnlyValue } from "../../helper/Helper";
export default function EditStoreOption() {
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [allImg, setAllImg] = useState([]);
  const [progress, setProgress] = useState(false);
  const [imgInfo, setImgInfo] = useState({
    link: null,
    id: null,
  });
  const [imgLink, setImgLink] = useState([
    {
      img: null,
    },
    {
      img: null,
    },
    {
      img: null,
    },
  ]);
  const [marketing, SetMarketing] = useState([
    {
      imgLink: null,
      title: null,
      description: null,
    },
    {
      imgLink: null,
      title: null,
      description: null,
    },
    {
      imgLink: null,
      title: null,
      description: null,
    },
  ]);
  const [witchIndex, setWitchIndex] = useState(0);
  const [marketingInfo, setMarketingInfo] = useState([
    {
      media_id: null,
      title: null,
      description: null,
      storeMarketing_id: null,
    },
    {
      media_id: null,
      title: null,
      description: null,
      storeMarketing_id: null,
    },
    {
      media_id: null,
      title: null,
      description: null,
      storeMarketing_id: null,
    },
  ]);
  useEffect(() => {
    axios
      .get("/StoreMarketing")
      .then((res) => {
        console.log(res);
        if (res.data.data.length > 0) {
          res.data.data.map((item, index) => {
            SetMarketing((prev) => {
              prev[index].description = item.description;
              prev[index].imgLink = item.media_link;
              prev[index].title = item.title;
              forceUpdate();
              return prev;
            });
            setMarketingInfo((prev) => {
              prev[index].description = item.description;
              prev[index].media_id = item.media_id;
              prev[index].storeMarketing_id = item.id;
              prev[index].title = item.title;
              forceUpdate();
              return prev;
            });
            setImgLink((prev) => {
              prev[index].img = item.media_link;
              return prev;
            });
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const openFirstModal = (index) => {
    setModal(true);
    setWitchIndex(index);
    forceUpdate();
  };
  const closeFirstModal = () => {
    setModal(false);
    setError((prev) => {
      prev.description = false;
      prev.image = false;
      prev.title = false;
      forceUpdate();
      return prev;
    });
    if (imgLink[witchIndex].img === null) {
      axios
        .get("/StoreMarketing")
        .then((res) => {
          console.log(res);
          if (res.data.data.length > 0) {
            res.data.data.map((item, index) => {
              SetMarketing((prev) => {
                prev[index].description = item.description;
                prev[index].imgLink = item.media_link;
                prev[index].title = item.title;
                forceUpdate();
                return prev;
              });
              setMarketingInfo((prev) => {
                prev[index].description = item.description;
                prev[index].media_id = item.media_id;
                prev[index].storeMarketing_id = item.id;
                prev[index].title = item.title;
                forceUpdate();
                return prev;
              });
              setImgLink((prev) => {
                prev[index].img = item.media_link;
                return prev;
              });
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  const header = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  // const UploadImg = (e) => {
  //   console.log(e);
  //   let formData = new FormData();
  //   formData.append("media", e.target.files[0]);
  //   axios
  //     .post("/media", formData, header)
  //     .then((res) => {
  //       console.log(res);
  //       if (res.data.status) {
  //         setImgLink((prev) => {
  //           prev[witchIndex].img = res.data.data.media_link;
  //           forceUpdate();
  //           return prev;
  //         });
  //         setMarketingInfo((prev) => {
  //           prev[witchIndex].media_id = res.data.data.id;
  //           forceUpdate();
  //           return prev;
  //         });
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  // multiple upload
  const uploadImage = (e) => {
    setProgress(true);

    let media = convertObjectToArrOnlyValue(e.target.files);

    let formData = new FormData();

    media.forEach((file, index) => {
      formData.append(`media[]`, media[index]);
    });

    axios
      .post("/media/multipleUpload", formData, header)
      .then((res) => {
        if (res.data.status === 0) {
          setProgress(false);
        }
        console.log(res);
        if (res.data.status) {
          setProgress(false);
          dispatch(ShowAlert(true, res.data.message));
          axios
            .get("/media?isPaginated=0")
            .then((res) => {
              if (res.data.status) {
                console.log(res);
                // setImgModal(!imgModal);
                setAllImg(res.data.data);
              }
              forceUpdate();
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        setProgress(false);
        console.log(e);
      });
  };
  const [error, setError] = useState({
    image: false,
    description: false,
    title: false,
  });
  const shareMarketing = () => {
    console.log(marketingInfo[witchIndex]);
    if (
      marketingInfo[witchIndex].description != null &&
      marketingInfo[witchIndex].description != "" &&
      marketingInfo[witchIndex].title != "" &&
      marketingInfo[witchIndex].title != "" &&
      marketingInfo[witchIndex].media_id != null &&
      marketingInfo[witchIndex].media_id != ""
    ) {
      axios
        .post("/StoreMarketing", marketingInfo[witchIndex])
        .then((res) => {
          console.log(res);
          if (res.data.status) {
            setModal(false);
            dispatch(ShowAlert(true, res.data.message));
            setMarketingInfo((prev) => {
              prev[witchIndex].description = null;
              prev[witchIndex].media_id = null;
              prev[witchIndex].title = null;
              forceUpdate();
              return prev;
            });
            setImgLink((prev) => {
              prev[witchIndex].img = null;
              forceUpdate();
              return prev;
            });
            axios
              .get("/StoreMarketing")
              .then((res) => {
                console.log(res);
                if (res.data.data.length > 0) {
                  res.data.data.map((item, index) => {
                    SetMarketing((prev) => {
                      prev[index].description = item.description;
                      prev[index].imgLink = item.media_link;
                      prev[index].title = item.title;
                      forceUpdate();
                      return prev;
                    });
                    setMarketingInfo((prev) => {
                      prev[index].description = item.description;
                      prev[index].media_id = item.media_id;
                      prev[index].storeMarketing_id = item.id;
                      prev[index].title = item.title;
                      forceUpdate();
                      return prev;
                    });
                  });
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      let text = `برای ذخیره خدمات پر کردن فیلد های 
${
  marketingInfo[witchIndex].media_id == null ||
  marketingInfo[witchIndex].media_id == ""
    ? "آپلود عکس و"
    : ""
}
${
  marketingInfo[witchIndex].title == null ||
  marketingInfo[witchIndex].title == ""
    ? "عنوان فروشگاه"
    : ""
}
${
  marketingInfo[witchIndex].description == null ||
  marketingInfo[witchIndex].description == ""
    ? "توضیحات"
    : ""
}
الزامی است .
`;
      dispatch(ShowAlert(true, text));
      if (
        marketingInfo[witchIndex].description == null ||
        marketingInfo[witchIndex].description == ""
      ) {
        setError((prev) => {
          prev.description = true;
          forceUpdate();
          return prev;
        });
      }
      if (
        marketingInfo[witchIndex].title == null ||
        marketingInfo[witchIndex].title == ""
      ) {
        setError((prev) => {
          prev.title = true;
          forceUpdate();
          return prev;
        });
      }
      if (
        marketingInfo[witchIndex].media_id == null ||
        marketingInfo[witchIndex].media_id == ""
      ) {
        setError((prev) => {
          prev.image = true;
          forceUpdate();
          return prev;
        });
      }
    }
  };
  const DelteModalImg = () => {
    if (imgLink[witchIndex].img !== null) {
      setImgLink((prev) => {
        prev[witchIndex].img = null;
        forceUpdate();
        return prev;
      });
      setMarketingInfo((prev) => {
        prev[witchIndex].media_id = null;
        forceUpdate();
        return prev;
      });
    }
  };
  const openUloadModal = () => {
    if (uploadModal === false) {
      setUploadModal(!uploadModal);
      setImgInfo((prev) => {
        prev.id = null;
        prev.link = null;
        forceUpdate();
        return prev;
      });
      axios
        .get("/media?isPaginated=0")
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            // setImgModal(!imgModal);
            setAllImg(res.data.data);
          }
          forceUpdate();
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setUploadModal(!uploadModal);
      setImgInfo((prev) => {
        prev.id = null;
        prev.link = null;
        forceUpdate();
        return prev;
      });
    }
  };

  const addImg = (media_link, id) => {
    setImgInfo((prev) => {
      prev.id = id;
      prev.link = media_link;
      forceUpdate();
      return prev;
    });
  };
  const finalAddOptionImg = () => {
    if (imgInfo.id != null) {
      setImgLink((prev) => {
        prev[witchIndex].img = imgInfo.link;
        forceUpdate();
        return prev;
      });
      setMarketingInfo((prev) => {
        prev[witchIndex].media_id = imgInfo.id;
        forceUpdate();
        return prev;
      });
      setUploadModal(!uploadModal);
      setError((prev) => {
        prev.image = false;
        forceUpdate();
        return prev;
      });
    } else {
      dispatch(ShowAlert(true, "انتخاب عکس الزامی است ."));
    }
  };
  return (
    <div className="edit-store-option">
      {/* ///////////////////////////////// */}
      <Modal
        onBackdropClick={() => openUloadModal()}
        className="img-modal"
        open={uploadModal}
      >
        <div className="img-modal-box">
          <div className="img-modal-top-bar-box">
            <p>انتخاب تصویر</p>
            <img src={cross} onClick={() => openUloadModal()} />
          </div>
          <div className="img-modal-img-box">
            <div className="img-modal-img-box-top-bar">
              <p>گالری</p>
              {/* <p>حداکثر 20 تصویر</p> */}
            </div>
            <SimpleBar
              forceVisible="x"
              style={{
                width: "100%",
                height: "450px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <div className="img-modal-img-box-2">
                <label
                  htmlFor="upload_img"
                  className="img-modal-img-box-upload-box"
                >
                  {progress === true ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <div className="img-modal-img-box-upload-box-button">
                        آپلود عکس
                      </div>
                      <img src={UploadImg} />
                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="upload_img"
                        multiple
                        onChange={(e) => uploadImage(e)}
                      />
                    </>
                  )}
                </label>
                {allImg.map((item, index) => {
                  return (
                    <div
                      onClick={() => addImg(item.media_link, item.id, item)}
                      key={index}
                      className={
                        imgInfo.id === item.id
                          ? "img-modal-img-box-img-show-active"
                          : "img-modal-img-box-img-show"
                      }
                    >
                      <img src={item.media_link} />
                    </div>
                  );
                })}
              </div>
            </SimpleBar>
          </div>
          <div className="img-modal-img-box-3">
            <div>
              <button
                onClick={() => openUloadModal()}
                className="img-modal-img-box-3-cancel-Button"
              >
                لغو
              </button>
            </div>
            <div>
              {/* <p>{productGalleryInfo.productGalleries.length}</p> */}
              {/* <p>از 20 تصویر انتخاب شده</p> */}
              <button
                onClick={() => finalAddOptionImg()}
                className="img-modal-img-box-3-send-Button"
              >
                افزودن
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* /////////////////////////////////// */}
      <Modal
        open={modal}
        className="edit-store-option-modall-all"
        onBackdropClick={() => closeFirstModal()}
      >
        <div className="edit-store-option-modall-box-all">
          <div className="edit-store-option-modall-box-all-header">
            <p>ویرایش خدمات</p>
            <img
              style={{ cursor: "pointer" }}
              src={cross}
              onClick={() => closeFirstModal()}
            />
          </div>
          <div className="edit-store-option-modall-upload-box">
            <div
              className={
                error.image === false
                  ? "edit-store-option-modall-upload-box-2"
                  : "edit-store-option-modall-upload-box-2-red"
              }
            >
              {imgLink[witchIndex].img === null ? (
                <label
                  onClick={() => openUloadModal()}
                  htmlFor={`UploadMarketing${witchIndex}`}
                >
                  {/* <input
                    onChange={(e) => UploadImg(e)}
                    style={{ display: "none" }}
                    type="file"
                    name="upload"
                    id={`UploadMarketing${witchIndex}`}
                  /> */}
                  <img
                    className="edit-store-option-modall-upload-box-2-img-1"
                    src={Uploadinmedia}
                  />
                  <p>آپلود تصویر</p>
                </label>
              ) : (
                <img
                  className="edit-store-option-modall-upload-box-2-img-2"
                  src={imgLink[witchIndex].img}
                />
              )}
              <div className="edit-store-option-modall-upload-box-delte-img-box">
                <img
                  onClick={() => DelteModalImg()}
                  src={imgLink[witchIndex].img === null ? Delete : DeleteRed}
                />
              </div>
            </div>
          </div>
          <div className="edit-store-option-modall-input-box">
            <InputComponent
              value={marketingInfo[witchIndex].title}
              width="calc(100% - 20px)"
              height="44px"
              type="text"
              inputType="input"
              placeholder="عنوان فروشگاه"
              borderColor={error.title === true ? "#F23B3B" : "#dcdcdc"}
              onchange={(e) => {
                setMarketingInfo((prev) => {
                  prev[witchIndex].title = e.target.value;
                  forceUpdate();
                  return prev;
                });
                setError((prev) => {
                  prev.title = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
            <InputComponent
              value={marketingInfo[witchIndex].description}
              width="calc(100% - 20px)"
              height="44px"
              type="text"
              inputType="input"
              placeholder="توضیحات ( شعار )"
              borderColor={error.description === true ? "#F23B3B" : "#dcdcdc"}
              onchange={(e) => {
                setMarketingInfo((prev) => {
                  prev[witchIndex].description = e.target.value;
                  forceUpdate();
                  return prev;
                });
                setError((prev) => {
                  prev.description = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
          </div>
          <div className="edit-store-option-modall-button-box">
            <button
              onClick={() => closeFirstModal()}
              className="edit-store-option-modall-button-cancel"
            >
              لغو
            </button>
            <button
              onClick={() => shareMarketing()}
              className="edit-store-option-modall-button-save"
            >
              ذخیره
            </button>
          </div>
        </div>
      </Modal>
      {marketing.map((item, index) => {
        return (
          <div className="edit-store-option-box-1">
            <div
              className={
                marketing[index].imgLink === null
                  ? "edit-store-option-box-1-img"
                  : "edit-store-option-box-1-img-enable"
              }
            >
              <img
                src={
                  marketing[index].imgLink === null
                    ? PictureIcon
                    : marketing[index].imgLink
                }
              />
            </div>
            <div
              className={
                marketing[index].title === null || marketing[index].title === ""
                  ? "edit-store-option-box-1-title-box"
                  : "edit-store-option-box-1-title-box-enable"
              }
            >
              {marketing[index].title === null ||
              marketing[index].title === "" ? (
                <div className="edit-store-option-box-1-title-box-2"></div>
              ) : (
                <p className="edit-store-option-box-1-title">
                  {marketing[index].title}
                </p>
              )}
            </div>
            <div
              className={
                marketing[index].description === null ||
                marketing[index].description === ""
                  ? "edit-store-option-box-1-description-box"
                  : "edit-store-option-box-1-description-box-enable"
              }
            >
              {marketing[index].description === null ||
              marketing[index].description === "" ? (
                <div className="edit-store-option-box-1-description-box-1"></div>
              ) : (
                <p className="edit-store-option-box-1-description">
                  {marketing[index].description}
                </p>
              )}
            </div>
            <button
              onClick={() => openFirstModal(index)}
              className="edit-store-option-box-1-edit-button"
            >
              <img style={{ marginLeft: "10px" }} src={whiteEdit} />
              ویرایش
            </button>
          </div>
        );
      })}
    </div>
  );
}
