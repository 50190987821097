import { set } from "date-fns";
import React, { useEffect } from "react";
import { useState } from "react";
import useForceUpdate from "use-force-update";
import "../../assets/styles/VisitorStatisticsStyle/SalesReportStyle/SalesReportIranChart.css";
import axios from "../../js/Axios/Axios";
import Iran from "../IranComponent/IranComponent";
export default function VisitorStatisticsIranChart() {
  const forceUpdate = useForceUpdate();
  const [stateName, setStateName] = useState("");
  const [data, setData] = useState({
    city_total_price: null,
    most_sale_city: null,
    order_count: null,
    province: null,
    province_product_count: null,
    province_total_price: null,
  });
  const stateNameClick = (name, stateId) => {
    setStateName(name);
    axios
      .get(`Province/reportMap?province_id=${stateId}`)
      .then((res) => {
        console.log(res);
        setData((prev) => {
          prev.city_total_price = res.data.city_total_price;
          prev.most_sale_city = res.data.most_sale_city;
          prev.order_count = res.data.order_count;
          prev.province = res.data.province;
          prev.province_product_count = res.data.province_product_count;
          prev.province_total_price = res.data.province_total_price;

          forceUpdate();
          return prev;
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="sales-report-iran-chart-all">
      <div className="sales-report-iran-chart-title-box">
        <p>گزارش فروش در کشور</p>
      </div>

      <div className="sales-report-iran-chart-box-parent">
        <div className="sales-report-iran-chart-box">
          <div className="sales-report-iran-chart-box-state-name_box">
            <div className="sales-report-iran-chart-box-state-name_box-2">
            <div className="sales-report-iran-chart-box-state-name-dot"/>
              <p>نام استان</p>
            </div>
            <div className="sales-report-iran-chart-box-state-name_box-2">
            <div className="sales-report-iran-chart-box-state-name-dot"/>
            <p>تعداد سفارش</p>
            </div>
            <div className="sales-report-iran-chart-box-state-name_box-2">
            <div className="sales-report-iran-chart-box-state-name-dot"/>
            <p>جمع مبلغ سفارشات</p>
            </div>
            <div className="sales-report-iran-chart-box-state-name_box-2">
            <div className="sales-report-iran-chart-box-state-name-dot"/>
            <p>تعداد محصولات خریداری شده</p>
            </div>
            <div className="sales-report-iran-chart-box-state-name_box-2">
            <div className="sales-report-iran-chart-box-state-name-dot"/>
            <p>پرفروش ترین شهر</p>
            </div>
          </div>
          <div className="sales-report-iran-chart-box-state-name_box-3">
            <div className="sales-report-iran-chart-box-state-name_box-4">
          <p>{stateName}</p>

            </div>
            <div className="sales-report-iran-chart-box-state-name_box-4">
            <p>{data.order_count}</p>

            </div>
            <div className="sales-report-iran-chart-box-state-name_box-4">
            <p>{data.province_total_price}</p>

            </div>
            <div className="sales-report-iran-chart-box-state-name_box-4">
            <p>{data.province_product_count}</p>

            </div>
            <div className="sales-report-iran-chart-box-state-name_box-4">
            <p>{data.most_sale_city}</p>

            </div>
          
          </div>
          {/* <div className="sales-report-iran-chart-box-state-name">
            <div className="chart-titles-and-border">
              <div className="sales-report-iran-chart-box-state-name-dot"></div>
              <p>نام استان</p>
            </div>
            <span />
            <div className="sales-report-iran-chart-box-state-name-1">
              <p>{stateName}</p>
            </div>
          </div> */}

          {/* <div className="sales-report-iran-chart-box-state-name">
            <div className="chart-titles-and-border">
              <div className="sales-report-iran-chart-box-state-name-dot"></div>
              <p>تعداد سفارش</p>
            </div>
            <span />
            <div className="sales-report-iran-chart-box-state-name-1">
              <p>{data.order_count}</p>
            </div>
          </div> */}

          {/* <div className="sales-report-iran-chart-box-state-name">
            <div className="chart-titles-and-border">
              <div className="sales-report-iran-chart-box-state-name-dot"></div>
              <p>جمع مبلغ سفارشات</p>
            </div>
            <span />
            <div className="sales-report-iran-chart-box-state-name-1">
              <p>{data.province_total_price}</p>
            </div>
          </div> */}

          {/* <div className="sales-report-iran-chart-box-state-name">
            <div className="chart-titles-and-border">
              <div className="sales-report-iran-chart-box-state-name-dot"></div>
              <p>تعداد محصولات خریداری شده</p>
            </div>
            <span />
            <div className="sales-report-iran-chart-box-state-name-1">
              <p>{data.province_product_count}</p>
            </div>
          </div> */}
          {/* <div className="sales-report-iran-chart-box-state-name">
            <div className="chart-titles-and-border">
              <div className="sales-report-iran-chart-box-state-name-dot"></div>
              <p>پرفروش ترین شهر</p>
            </div>
            <span />
            <div className="sales-report-iran-chart-box-state-name-1">
              <p>{data.most_sale_city}</p>
            </div>
          </div> */}
        </div>
        <div className="sales-report-iran-chart-box-2">
          <Iran name={stateNameClick} />
        </div>
      </div>
    </div>
  );
}
