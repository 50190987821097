import React, { useEffect } from "react";
import "../../assets/styles/SmsSystemStyle/SmsManage.css";
import TopBar from "../../components/TopBar/TopBar";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import axios from "../../js/Axios/Axios";
import Modal from "@mui/material/Modal";
import { ShowAlert } from "../../action/index";
import Pagination from "../../components/Pagination/Pagination";
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import useForceUpdate from "use-force-update";
import AllProductAdd from "../../assets/icons/AllProductAdd.svg";
import SearchLogo from "../../assets/icons/SearchLogo.svg";
import FilterLogo from "../../assets/icons/Filter.svg";
import GarbageDelte from "../../assets/icons/GarbageDelte.svg";
import check from "../../assets/icons/check.svg";
import Eye from "../../assets/icons/AllProductEye.svg";
import uncheck from "../../assets/icons/uncheck.svg";
import DeleteRed from "../../assets/icons/DeleteRed.svg";
import Delete from "../../assets/icons/Delete.svg";
import { useState } from "react";

export default function SmsManage() {
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const paginatedData = useSelector((state) => state.PaginatedData);
  const [data, setData] = useState([]);
  const [from, setFrom] = useState(null);
  const [modal, setModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [firstAxios, setFirstAxios] = useState("/SmsSystem");
  useEffect(() => {
    axios
      .get("/SmsSystem")
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  useEffect(() => {
    if (paginatedData.length != 0) {
      setData(paginatedData.data);
      setCurrentPage(paginatedData.meta.current_page);
      setFrom(paginatedData.meta.from);
    }
  }, [paginatedData]);
  const openDeleteModal = (id) => {
    setModal(true);
    setDeleteId(id);
    forceUpdate();
  };
  const cancelDeleteClick = () => {
    setModal(true);
    setDeleteId(null);
    forceUpdate();
  };
  const deleteSms = () => {
    axios
      .delete(`/SmsSystem/${deleteId}`)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          setModal(false);
          setDeleteId(null);
          dispatch(ShowAlert(true, res.data.message));
          axios
            .get(
              `/SmsSystem?page=${
                data.length === 1 ? currentPage - 1 : currentPage
              }`
            )
            .then((res) => {
              if (res.data.status) {
                console.log(res);
                setFirstAxios(
                  `/SmsSystem?page=${
                    data.length === 1 ? currentPage - 1 : currentPage
                  }`
                );
                setData(res.data.data);
                setCurrentPage(res.data.meta.current_page);
                setFrom(res.data.meta.from);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const searchSms = (e) => [
    axios
      .get(`/SmsSystem?search=${e.target.value}`)
      .then((res) => {
        console.log(res);
        setData(res.data.data);
        setCurrentPage(res.data.meta.current_page);
        setFrom(res.data.meta.from);
        setFirstAxios(`/SmsSystem?search=${e.target.value}`);
      })
      .catch((e) => {
        console.log(e);
      }),
  ];
  return (
    <div className="sms-manage-all">
      <Modal
        onBackdropClick={() => cancelDeleteClick()}
        className="sms-manage-modal"
        open={modal}
      >
        <div className="sms-manage-modal-box">
          <div className="sms-manage-modal-top-bar-box">
            <p>حذف پیامک</p>
            <img src={DeleteRed} />
          </div>
          <div className="sms-manage-modal-text-box">
            <p>ایا از پاک کردن کوپن بطور کامل مطمئن هستید ؟</p>
          </div>
          <div className="sms-manage-modal-button-box">
            <button
              onClick={() => cancelDeleteClick()}
              className="sms-manage-cancel-button"
            >
              انصراف
            </button>
            <button
              onClick={() => deleteSms()}
              className="sms-manage-delte-button"
            >
              حذف
            </button>
          </div>
        </div>
      </Modal>
      <div className="sms-manage-top-bar-box">
        <TopBar />
      </div>
      <div className="sms-manage-search-button-box">
        <div className="sms-manage-button-box">
          <button
            onClick={() => navigate("/SmsSystem")}
            className="sms-manage-button-create-sms"
          >
            <img src={AllProductAdd} />
            <p>ایجاد پیامک</p>
          </button>
        </div>
        <div className="sms-manage-search-box">
          <InputComponent
            width="47%"
            height="44px"
            type="text"
            inputType="inputWithIcon"
            iconSide="right"
            iconSrc={SearchLogo}
            backgroundColor="#f7f7f7"
            placeholder="جستجو محصول.."
            borderColor="#dcdcdc"
            onchange={(e) => searchSms(e)}
          />
        </div>
      </div>
      <div className="sms-manage-table-box">
        <div className="sms-manage-table-top-bar">
          <div className="sms-manage-table-top-bar-hashtag">
            <p>#</p>
          </div>
          <div className="sms-manage-table-top-bar-title">
            <p>عنوان پیام</p>
          </div>
          <div className="sms-manage-table-top-bar-text">
            <p>متن پیام</p>
          </div>
          <div className="sms-manage-table-top-bar-add-on">
            <p>اعمال بر روی</p>
          </div>
          <div className="sms-manage-table-top-bar-send-situation">
            <p>وضعیت ارسال</p>
          </div>
          <div className="sms-manage-table-top-bar-sms-oriantion">
            <p>عملیات</p>
          </div>
        </div>
        {data.map((item, index) => {
          return (
            <div
              key={index}
              style={
                data.length == index + 1
                  ? { borderRadius: "0 0 10px 10px" }
                  : {}
              }
              className="sms-manage-table-item-box"
            >
              <div className="sms-manage-table-item-hashtag">
                <p>{from + index}</p>
              </div>
              <div className="sms-manage-table-item-title">
                {item.sms_title != null && (
                  <p>
                    {item.sms_title.slice(0, 30) +
                      (item.sms_title.length > 30 ? "..." : "")}{" "}
                  </p>
                )}
              </div>
              <div className="sms-manage-table-item-text">
                {item.sms_text != null && (
                  <p>
                    {" "}
                    {item.sms_text.slice(0, 85) +
                      (item.sms_text.length > 85 ? "..." : "")}
                  </p>
                )}
              </div>
              <div className="sms-manage-table-item-add-on">
                <p>{item.users.length} کاربر</p>
              </div>
              <div className="sms-manage-table-item-send-situation">
                <p>{item.send_time.slice(0, 10)}</p>
              </div>
              <div className="sms-manage-table-item-sms-oriantion">
                <img
                  onClick={() => openDeleteModal(item.id)}
                  src={DeleteRed}
                  // onMouseOver={(e) => (e.currentTarget.src = DeleteRed)}
                  // onMouseOut={(e) => (e.currentTarget.src = Delete)}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="sms-manage-pagination-box">
        <Pagination firstAxios={firstAxios} currentAxios="/SmsSystem?page=" />
      </div>
    </div>
  );
}
