import React from "react";
import "../../assets/styles/OrderStyle/EditOrder.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import axios from "../../js/Axios/Axios";
import TopBar from "../../components/TopBar/TopBar";
import DropDown from "../../components/DropDown/DropDown";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import { ShowAlert } from "../../action/index";
import Modal from "@mui/material/Modal";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import DeleteRed from "../../assets/icons/DeleteRed.svg";
import SearchLogo from "../../assets/icons/SearchLogo.svg";
import cross from "../../assets/icons/cross.svg";
import AllProductCalender from "../../assets/icons/AllProductCalender.svg";
import Edit from "../../assets/icons/Edit.svg";
import check from "../../assets/icons/check.svg";
import Barcode from "../../assets/icons/Barcode.svg";
import uncheck from "../../assets/icons/uncheck.svg";
import Minus from "../../assets/icons/Minus.svg";
import Plus from "../../assets/icons/Plus.svg";
import AllProductAdd from "../../assets/icons/AllProductAdd.svg";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import useForceUpdate from "use-force-update";
import { Calendar } from "react-datepicker2";
import * as moment from "jalali-moment";
import TextField from "@mui/material/TextField";
import { ClockPicker } from "@mui/x-date-pickers/ClockPicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import { useState } from "react";
export default function EditOrder() {
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const order_id = useSelector((state) => state.SendIdForEdit);
  const [data, setData] = useState(null);
  const [addressModal, setAddressModal] = useState(false);
  const [deliveryModal, setDeliveryModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [productModal, setProductModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [productData, setProductData] = useState([]);
  const [statusShow, setStatusShow] = useState(null);
  const [paymentShow, setPaymentShow] = useState(null);
  const [paymentCalender, setPaymentCalender] = useState(false);
  const [deliveryCalender, setDeliveryCalender] = useState(false);
  const [paymentDateTime, setPaymentDateTime] = useState("date");
  const [deliveryDateTime, setDeliveryDateTime] = useState("date");
  const [final, setFinal] = useState({
    price: null,
    count: null,
  });
  const [addresses, setAddresses] = useState([]);
  const [isOther, setIsOther] = useState(false);
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const [deliveryShow, setDeliveryShow] = useState("");
  const [orderDetails, setOrderDetails] = useState({
    order_status: null,
    delivery_address_id: null,
    transferee_fullName: null,
    transferee_mobile: null,
    storeDeliveryType_id: null,
    delivery_dateTime: null,
    final_save: 1,
  });
  const [paymentType, setPaymentType] = useState({
    transaction_id: null,
    payment_type: null,
    payment_date: null,
    order_id: null,
    price: null,
  });
  const [orderItems, setOrderItems] = useState({
    final_save: 1,
    order_id: null,
    orderItems: null,
  });

  useEffect(() => {
    if (order_id.id !== null) {
      axios
        .get(`/Order/${order_id.id}/edit`)
        .then((res) => {
          console.log(res);
          if (res.data.status) {
            let order = res.data.data;
            setData(res.data.data);
            setOrderDetails((prev) => {
              prev.delivery_dateTime = order.delivery_date;
              prev.order_status = order.order_status
              forceUpdate();
              return prev;
            });
            if (order.isTransferee_another === true) {
              setIsOther(true);
              setOrderDetails((prev) => {
                prev.transferee_fullName = order.transferee_fullName;
                prev.transferee_mobile = order.transferee_mobile;
                forceUpdate();
                return prev;
              });
            }
            if (order.order_payment != null) {
              setPaymentType((prev) => {
                prev.order_id = order.order_id;
                prev.payment_date = order.order_payment.payment_date;
                prev.payment_type = order.order_payment.payment_type;
                prev.price = order.final_amount_order;
                prev.transaction_id = order.order_payment.transaction_id;
                forceUpdate();
                return prev;
              });
            }

            products.map((item, index) => {
              products.splice(index);
            });
            res.data.data.order_items.map((item) => {
              products.push({
                product_count: item.product_count,
                order_id: item.order_id,
                product_price_id: item.productPrice_id,
                orderItem_id: item.id,
                price: item.price,
                name: item.product_name,
                variableProperty_alterValue: item.variableProperty_alterValue,
                variableProperty_key: item.variableProperty_key,
                variableProperty_value: item.variableProperty_value,
                image_link: item.product_image,
                checked: true,
              });
              forceUpdate();
              setFinal((prev) => {
                prev.count = null;
                prev.price = null;
                forceUpdate();
                return prev;
              });
              setFinal((prev) => {
                prev.count += item.product_count;
                prev.price += item.price * item.product_count;
                return prev;
              });
              forceUpdate();
            });
            setOrderItems((prev) => {
              prev.order_id = order.order_id;
              prev.orderItems = products;
              forceUpdate();
              return prev;
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (order_id.id === null) {
      navigate("/Order");
    }
  }, [order_id]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [stateSreach, setStateSearch] = useState("");
  const [citySreach, setCitySearch] = useState("");
  const [stateShow, setStateShow] = useState(null);
  const [cityShow, setCityShow] = useState(null);
  const cityOnChange = (e) => {
    setCitySearch(e.target.value);
    forceUpdate();
  };
  const stateOnChange = (e) => {
    setStateSearch(e.target.value);
    forceUpdate();
  };
  const openAddressModal = () => {
    if (addressModal === false) {
      if (data !== null) {
        setAddressDetails((prev) => {
          prev.user_id = data.customer_id;
          forceUpdate();
          return prev;
        });
        axios
          .get(`Address/search?user_id=${data.customer_id}`)
          .then((res) => {
            console.log(res);
            if (res.data.status) {
              addresses.map((item, index) => {
                addresses.splice(index);
                forceUpdate();
              });
              res.data.data.map((item) => {
                addresses.push({
                  id: item.address_id,
                  title: item.title,
                  address: item.address_details,
                  city: item.city,
                  state: item.state,
                  postCode: item.post_code,
                  city_id: item.city_id,
                  state_id: item.state_id,
                });
                forceUpdate();
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });
        axios
          .get("/Province")
          .then((res) => {
            console.log(res);
            if (res.data.status) {
              states.map((item, index) => {
                states.splice(index);
                forceUpdate();
              });
              res.data.data.map((item, index) => {
                states.push({
                  title: item.province,
                  id: item.id,
                });
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });
        {
          data !== null &&
            axios
              .get(`/Province/${data.delivery_addresses.state_id}`)
              .then((res) => {
                console.log(res);
                if (res.data.status) {
                  cities.map((item, index) => {
                    cities.splice(index);
                    forceUpdate();
                  });
                  res.data.data.cities.map((item, index) => {
                    cities.push({
                      title: item.province,
                      id: item.id,
                    });
                  });
                }
              })
              .catch((e) => {
                console.log(e);
              });
        }
      }
      setAddressModal(true);
    } else {
      setAddressModal(false);
    }
    forceUpdate();
  };
  const [addressDetails, setAddressDetails] = useState({
    title: null,
    city_id: null,
    post_code: null,
    address_details: null,
    default: 0,
    province_id: null,
    user_id: null,
    city: null,
    state: null,
  });
  const [addressShow, setAddressShow] = useState(null);
  const addAddress = (item) => {
    console.log(item);
    setAddressDetails((prev) => {
      prev.title = item.title;
      prev.city_id = item.city_id;
      prev.post_code = item.postCode;
      prev.address_details = item.address;
      prev.province_id = item.state_id;
      prev.city = item.city;
      prev.state = item.state;
      forceUpdate();
      return prev;
    });
    setAddressShow(false);
    setTimeout(() => {
      setAddressShow(true);
    }, 100);
  };
  const addStateClick = (id, title) => {
    setAddressDetails((prev) => {
      prev.province_id = id;
      prev.state = title;
      prev.city = null;
      prev.city_id = null;
      forceUpdate();
      return prev;
    });
    setStateShow(false);
    setTimeout(() => {
      setStateShow(true);
    }, 100);
    axios
      .get(`/Province/${id}`)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          cities.map((item, index) => {
            cities.splice(index);
            forceUpdate();
          });
          res.data.data.cities.map((item, index) => {
            cities.push({
              title: item.province,
              id: item.id,
            });
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const addCityClick = (id, title) => {
    setAddressDetails((prev) => {
      prev.city_id = id;
      prev.city = title;
      forceUpdate();
      return prev;
    });
    setCityShow(false);
    setTimeout(() => {
      setCityShow(true);
    }, 100);
  };
  const cancelStoreAddress = () => {
    setAddressModal(false);
    setAddressDetails((prev) => {
      prev.title = null;
      prev.city_id = null;
      prev.post_code = null;
      prev.address_details = null;
      prev.province_id = null;
      prev.user_id = null;
      prev.city = null;
      prev.state = null;
      forceUpdate();
      return prev;
    });
    setIsOther(false);
  };
  const storeAddressClick = () => {
    if (addressDetails.city_id !== null) {
      {
        data !== null &&
          axios
            .put(
              `Address/${data.delivery_addresses.address_id}`,
              addressDetails
            )
            .then((res) => {
              console.log(res);
              if (res.data.status === 0) {
                dispatch(ShowAlert(true, res.data.data));
              }
              if (res.data.status) {
                dispatch(ShowAlert(true, res.data.message));
                setAddressModal(false);
                if (order_id.id !== null) {
                  axios
                    .get(`/Order/${order_id.id}/edit`)
                    .then((res) => {
                      if (res.data.status) {
                        let order = res.data.data;
                        setData(res.data.data);
                        setOrderDetails((prev) => {
                          prev.delivery_dateTime = order.delivery_date;
                          forceUpdate();
                          return prev;
                        });
                        if (order.isTransferee_another === true) {
                          setIsOther(true);
                          setOrderDetails((prev) => {
                            prev.transferee_fullName =
                              order.transferee_fullName;
                            prev.transferee_mobile = order.transferee_mobile;
                            forceUpdate();
                            return prev;
                          });
                        }
                        setPaymentType((prev) => {
                          prev.order_id = order.order_id;
                          prev.payment_date = order.order_payment.payment_date;
                          prev.payment_type = order.order_payment.payment_type;
                          prev.price = order.final_amount_order;
                          prev.transaction_id =
                            order.order_payment.transaction_id;
                          forceUpdate();
                          return prev;
                        });
                      }
                      console.log(res);
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }
              }
            })
            .catch((e) => {
              console.log(e);
            });
      }
    } else {
      dispatch(ShowAlert(true, "وارد کردن شهر الزامی است ."));
    }
  };
  const opendeliveryModal = () => {
    if (deliveryModal === false) {
      setDeliveryModal(true);
      axios
        .get(
          `StoreDeliveryType?user_id=${data.customer_id}&order_id=${data.order_id}`
        )
        .then((res) => {
          console.log(res);
          if (res.data.status) {
            deliveryTypes.map((item, index) => {
              deliveryTypes.splice(index);
            });
            res.data.data.map((item) => {
              deliveryTypes.push({
                title: item.deliveryType_title,
                id: item.storeDeliveryType_id,
                price: item.price,
              });
            });
            forceUpdate();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setDeliveryModal(false);
    }
    forceUpdate();
  };
  const addDelivery = (id, title) => {
    console.log(id, title);
    console.log(deliveryTypes);
    if (data !== null) {
      setData((prev) => {
        prev.delivery_type = title;
        forceUpdate();
        return prev;
      });
      deliveryTypes.map((item) => {
        if (item.id === id) {
          setData((prev) => {
            prev.delivery_price = item.price;
            forceUpdate();
            return prev;
          });
        }
      });
    }
    setOrderDetails((prev) => {
      prev.storeDeliveryType_id = id;
      forceUpdate();
      return prev;
    });
    setDeliveryShow(false);
    setTimeout(() => {
      setDeliveryShow(true);
    }, 100);
  };
  const openPaymentModal = () => {
    setPaymentModal(!paymentModal);
    forceUpdate();
  };
  const finalIncreasmentCount = (index) => {
    setProducts((prev) => {
      prev[index].product_count = prev[index].product_count + 1;
      forceUpdate();
      return prev;
    });
    setFinal((prev) => {
      prev.count = null;
      prev.price = null;
      return prev;
    });
    products.map((item) => {
      setFinal((prev) => {
        prev.count += item.product_count;
        prev.price += item.price * item.product_count;
        return prev;
      });
      forceUpdate();
    });
  };
  const finalDecreamentCount = (index) => {
    if (products[index].product_count > 1) {
      setProducts((prev) => {
        prev[index].product_count = prev[index].product_count - 1;
        forceUpdate();
        return prev;
      });
    } else if (products[index].product_count === 1) {
      products.splice(index, 1);
    }
    setFinal((prev) => {
      prev.count = null;
      prev.price = null;
      return prev;
    });
    products.map((item) => {
      setFinal((prev) => {
        prev.count += item.product_count;
        prev.price += item.price * item.product_count;
        return prev;
      });
      forceUpdate();
    });
    forceUpdate();
  };
  const openProductModal = () => {
    if (productModal === false) {
      setProductModal(true);
      axios
        .get("/product?isPaginated=0")
        .then((res) => {
          console.log(res);
          if (res.data.status) {
            let array = res.data.data;
            array.map((item) => {
              item["checked"] = false;
              item["counter"] = 1;
            });
            array.map((item) => {
              products.map((item_product) => {
                if (item.productPrice_id === item_product.product_price_id) {
                  // if (item_product.product_count > 1) {
                  item.checked = true;
                  item.counter = item_product.product_count;
                  // }
                  forceUpdate();
                }
              });
            });
            setProductData(array);
            forceUpdate();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (productModal === true) {
      setProductModal(false);
    }
  };
  const searchProduct = (e) => {
    axios
      .get(`/product?isPaginated=0&search=${e.target.value}`)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          let array = res.data.data;
          array.map((item) => {
            item["checked"] = false;
            item["counter"] = 1;
          });
          array.map((item) => {
            products.map((item_product) => {
              if (item.productPrice_id === item_product.product_price_id) {
                // if (item_product.product_count > 1  ) {
                item.checked = true;
                item.counter = item_product.product_count;
                // }
                forceUpdate();
              }
            });
          });
          setProductData(array);
          forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const addProductClick = (item, index) => {
    productData.map((item_product, i) => {
      if (item_product.productPrice_id === item.productPrice_id) {
        if (item_product.checked === true) {
          item_product.checked = false;
          item_product.counter = 1;
          products.splice(index, 1);
        } else if (item_product.checked === false) {
          item_product.checked = true;
          products.push({
            product_count: 1,
            order_id: null,
            product_price_id: item.productPrice_id,
            orderItem_id: null,
            checked: false,
            image_link: item.product_image,
            name: item.product,
            price: item.price,
            variableProperty_key: item.variable_key,
            variableProperty_alterValue: item.variable_alternative_value,
            variableProperty_value: item.variable_main_value,
          });
        }
      }
    });
    forceUpdate();
  };
  const increasementCount = (id, index) => {
    productData[index].counter = productData[index].counter + 1;
    products.map((item) => {
      if (item.product_price_id === id) {
        item.product_count = item.product_count + 1;
      }
      forceUpdate();
    });
  };
  const decreamenttCount = (id, index) => {
    if (productData[index].counter > 1) {
      productData[index].counter = productData[index].counter - 1;
      products.map((item) => {
        if (item.product_price_id === id) {
          item.product_count = item.product_count - 1;
        }
        forceUpdate();
      });
    }
  };
  const addProduct = () => {
    setFinal((prev) => {
      prev.count = null;
      prev.price = null;
      return prev;
    });
    products.map((item) => {
      item.checked = true;
      setFinal((prev) => {
        prev.count += item.product_count;
        prev.price += item.price * item.product_count;
        return prev;
      });
      forceUpdate();
    });
    setProductModal(false);
  };
  const addStatus = (id, title) => {
    setOrderDetails((prev) => {
      prev.order_status = title;
      forceUpdate();
      return prev;
    });
    setStatusShow(false);
    setTimeout(() => {
      setStatusShow(true);
    }, 100);
  };
  const addPayment = (id, title) => {
    setPaymentType((prev) => {
      prev.payment_type = title;
      forceUpdate();
      return prev;
    });
    setPaymentShow(false);
    setTimeout(() => {
      setPaymentShow(true);
    }, 100);
    if (title === "آنلاین") {
      setPaymentType((prev) => {
        prev.payment_date = null;
        prev.transaction_id = null;
        forceUpdate();
        return prev;
      });
    }
  };
  const createPaymentBarcodeClick = () => {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 10; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    setPaymentType((prev) => {
      prev.transaction_id = text;
      forceUpdate();
      return prev;
    });
    forceUpdate();
  };
  const openPaymentCalender = () => {
    setPaymentCalender(!paymentCalender);
  };
  const cancelEditPayment = () => {
    if (data !== null) {
      console.log(data);
      setPaymentType((prev) => {
        prev.order_id = data.order_id;
        prev.payment_date = data.order_payment.payment_date;
        prev.payment_type = data.order_payment.payment_type;
        prev.price = data.final_amount_order;
        prev.transaction_id = data.order_payment.transaction_id;
        forceUpdate();
        return prev;
      });
    }
    setPaymentModal(!paymentModal);
  };
  const storeEditPayment = () => {
    if (
      (paymentType.payment_type === "پرداخت در محل" ||
        paymentType.payment_type === "کارت به کارت") &&
      (paymentType.payment_date != null || paymentType.transaction_id != null)
    ) {
      axios
        .put(`OrderPayment/${data.order_payment.id}`, paymentType)
        .then((res) => {
          console.log(res);
          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));
            axios
              .get(`/Order/${res.data.data.order_id}/edit`)
              .then((res) => {
                if (res.data.status) {
                  let order = res.data.data;
                  setData(res.data.data);
                  if (order.isTransferee_another === true) {
                    setIsOther(true);
                    setOrderDetails((prev) => {
                      prev.transferee_fullName = order.transferee_fullName;
                      prev.transferee_mobile = order.transferee_mobile;
                      forceUpdate();
                      return prev;
                    });
                  }
                  setPaymentType((prev) => {
                    prev.order_id = order.order_id;
                    prev.payment_date = order.order_payment.payment_date;
                    prev.payment_type = order.order_payment.payment_type;
                    prev.price = order.final_amount_order;
                    prev.transaction_id = order.order_payment.transaction_id;
                    forceUpdate();
                    return prev;
                  });
                }
                console.log(res);
              })
              .catch((e) => {
                console.log(e);
              });
            setPaymentModal(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      dispatch(
        ShowAlert(
          true,
          "برای وضیعت کارت به کارت و پرداخت درب منزل تاریخ و کد پیگیری الزامی است."
        )
      );
    }

    if (paymentType.payment_type === "آنلاین") {
      axios
        .put(`OrderPayment/${data.order_payment.id}`, paymentType)
        .then((res) => {
          console.log(res);
          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));
            axios
              .get(`/Order/${res.data.data.order_id}/edit`)
              .then((res) => {
                if (res.data.status) {
                  let order = res.data.data;
                  setData(res.data.data);
                  if (order.isTransferee_another === true) {
                    setIsOther(true);
                    setOrderDetails((prev) => {
                      prev.transferee_fullName = order.transferee_fullName;
                      prev.transferee_mobile = order.transferee_mobile;
                      forceUpdate();
                      return prev;
                    });
                  }
                  setPaymentType((prev) => {
                    prev.order_id = order.order_id;
                    prev.payment_date = order.order_payment.payment_date;
                    prev.payment_type = order.order_payment.payment_type;
                    prev.price = order.final_amount_order;
                    prev.transaction_id = order.order_payment.transaction_id;
                    forceUpdate();
                    return prev;
                  });
                }
                console.log(res);
              })
              .catch((e) => {
                console.log(e);
              });
            setPaymentModal(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  const addDateDeliveryTime = () => {
    setDeliveryCalender(true);
    forceUpdate();
  };
  const cancelDeliveryType = () => {
    axios
      .get(`/Order/${order_id.id}/edit`)
      .then((res) => {
        if (res.data.status) {
          let order = res.data.data;
          setData(res.data.data);
          setOrderDetails((prev) => {
            prev.delivery_dateTime = order.delivery_date;
            prev.storeDeliveryType_id = order.storeDeliveryType_id;
            forceUpdate();
            return prev;
          });
          setDeliveryModal(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const storeDeliveryClick = () => {
    axios
      .put(`Order/${order_id.id}`, orderDetails)
      .then((res) => {
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
    setDeliveryModal(false);
  };
  const cancelEditOrder = () => {
    navigate("/Order");
  };
  const ShareEditOrder = () => {
    if (data != null) {
      products.map((item) => {
        item.order_id = data.order_id;
        forceUpdate();
      });
      axios
        .put(`Order/${data.order_id}` , orderDetails)
        .then((res) => {
          console.log(res);
          if (res.data.status) {
            // dispatch(ShowAlert(true, res.data.message));
            axios
              .post("/OrderItem", orderItems)
              .then((res) => {
                console.log(res);
                dispatch(ShowAlert(true, res.data.message));
                if (res.data.status) {
                  navigate("/Order");
                }
              })
              .then((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  const DeleteClick = () => {
    if (data != null) {
      let array = [];
      array.push(data.order_id);
      axios
        .delete("Order/throwingGarbage", {
          data: { order_ids: array },
        })
        .then((res) => {
          console.log(res);
          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));
            navigate("/Order");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  return (
    <div className="edit-order-all">
      <TopBar />

      {/* //////////////////////////////////// */}

      <Modal
        onBackdropClick={() => addDateDeliveryTime()}
        className="create-order-modal-all"
        open={deliveryCalender}
      >
        <div style={{ backgroundColor: "#fff", direction: "ltr", zoom: "1.3" }}>
          {deliveryDateTime === "date" && (
            <Calendar
              isGregorian={false}
              onChange={(value) => {
                let date = moment(value._d, "YYYY/MM/DD")
                  .locale("fa")
                  .format("YYYY/MM/DD");
                setOrderDetails((prev) => {
                  prev.delivery_dateTime = date;
                  forceUpdate();
                  return prev;
                });
                setData((prev) => {
                  prev.delivery_date = date;
                  forceUpdate();
                  return prev;
                });
                setDeliveryDateTime("time");
              }}
            />
          )}
          {deliveryDateTime === "time" && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticTimePicker
                ampm={false}
                orientation="landscape"
                openTo="minutes"
                // value={value}
                onChange={(e) => {
                  let time = moment(e).format("HH:mm");
                  setOrderDetails((prev) => {
                    prev.delivery_dateTime =
                      prev.delivery_dateTime + " " + time;
                    forceUpdate();
                    return prev;
                  });
                  setData((prev) => {
                    prev.delivery_date = prev.delivery_date + " " + time;
                    forceUpdate();
                    return prev;
                  });
                  setDeliveryDateTime("date");
                  setDeliveryCalender(false);
                  forceUpdate();
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          )}
        </div>
      </Modal>
      {/* //////////////////////////////////// */}
      <Modal
        onBackdropClick={() => openPaymentCalender()}
        className="create-order-modal-all"
        open={paymentCalender}
      >
        <div style={{ backgroundColor: "#fff", direction: "ltr", zoom: "1.3" }}>
          {paymentDateTime === "date" && (
            <Calendar
              isGregorian={false}
              onChange={(value) => {
                let date = moment(value._d, "YYYY/MM/DD")
                  .locale("fa")
                  .format("YYYY/MM/DD");
                setPaymentType((prev) => {
                  prev.payment_date = date;
                  forceUpdate();
                  return prev;
                });
                setPaymentDateTime("time");
              }}
            />
          )}
          {paymentDateTime === "time" && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticTimePicker
                ampm={false}
                orientation="landscape"
                openTo="minutes"
                // value={value}
                onChange={(e) => {
                  let time = moment(e).format("HH:mm");
                  setPaymentType((prev) => {
                    prev.payment_date = prev.payment_date + " " + time;
                    forceUpdate();
                    return prev;
                  });
                  setPaymentDateTime("date");
                  setPaymentCalender(false);
                  forceUpdate();
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          )}
        </div>
      </Modal>
      <Modal
        onBackdropClick={() => openProductModal()}
        className="create-order-modal-all"
        open={productModal}
      >
        <div className="create-order-modal-box">
          <div className="create-order-modal-box-top-bar">
            <p>افزودن محصول</p>
            <img src={cross} onClick={() => openProductModal()} />
          </div>
          <div className="create-order-modal-box-2">
            <InputComponent
              width="calc(100% - 20px)"
              height="44px"
              type="text"
              inputType="inputWithIcon"
              iconSide="right"
              iconSrc={SearchLogo}
              backgroundColor="#f7f7f7"
              placeholder="جستجو محصول.."
              borderColor="#dcdcdc"
              onchange={(e) => searchProduct(e)}
            />
          </div>
          <SimpleBar
            forceVisible="y"
            className="create-order-modal-box-product-box"
          >
            {productData.map((item, index) => {
              return (
                <div
                  key={index}
                  className="create-order-modal-box-product-box-2"
                >
                  <label
                    htmlFor={`product${index}`}
                    className="create-order-modal-box-product-box-2-check-box"
                  >
                    <input
                      style={{ display: "none" }}
                      type="checkbox"
                      id={`product${index}`}
                      name={`product${index}`}
                      onChange={() => addProductClick(item, index)}
                    />
                    <img src={item.checked === true ? check : uncheck} />
                  </label>
                  <div className="create-order-modal-box-product-box-2-img-box">
                    <div className="create-order-modal-box-product-box-2-img-box-2">
                      <img src={item.product_image} />
                    </div>
                  </div>
                  <div className="create-order-modal-box-product-box-2-title-box">
                    <p>{item.product}</p>
                    {item.variable_key !== null && item.variable_key === "رنگ" && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "auto",
                          maxWidth: "70px",
                          height: "30px",
                          marginTop: "17px",
                          borderRadius: "10px",
                          border: `solid 1px ${item.variable_alternative_value}`,
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontFamily: "IRANSansFaNum",
                            fontWeight: "900",
                            direction: "rtl",
                            fontSize: "16px",
                            color: `${item.variable_alternative_value}`,
                          }}
                        >
                          {item.variable_main_value}
                        </p>
                      </div>
                    )}
                    {item.variable_key !== null && item.variable_key !== "رنگ" && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "auto",
                          maxWidth: "70px",
                          height: "30px",
                          marginTop: "17px",
                          borderRadius: "10px",
                          border: "solid 1px #000000",
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontFamily: "IRANSansFaNum",
                            fontWeight: "900",
                            direction: "rtl",
                            fontSize: "16px",
                            color: "#000000",
                          }}
                        >
                          {item.variable_main_value}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="create-order-modal-box-product-box-2-price">
                    <div className="create-order-modal-box-product-box-2-price-2">
                      <p>{item.price}</p>
                      <p>(تومان)</p>
                    </div>
                    {item.checked === true && (
                      <div className="create-order-modal-box-product-box-2-price-3">
                        <button
                          onClick={() =>
                            increasementCount(item.productPrice_id, index)
                          }
                          className="create-order-modal-box-product-box-2-price-3-plus"
                        >
                          <img src={Plus} />
                        </button>
                        <div className="create-order-modal-box-product-box-2-price-3-number">
                          <p>{item.counter}</p>
                        </div>
                        <button
                          onClick={() =>
                            decreamenttCount(item.productPrice_id, index)
                          }
                          className="create-order-modal-box-product-box-2-price-3-minus"
                        >
                          <img src={Minus} />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </SimpleBar>
          <div className="create-order-modal-box-button-box">
            <button
              onClick={() => addProduct()}
              className="create-order-modal-box-button-box-button"
            >
              افزودن محصولات
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        onBackdropClick={() => openPaymentModal()}
        className="create-order-modal-all"
        open={paymentModal}
      >
        <div className="edit-order-delivery-modal-box">
          <div className="create-order-modal-box-top-bar">
            <p>ویرایش اطلاعات پرداخت</p>
            <img src={cross} onClick={() => openPaymentModal()} />
          </div>
          <div className="edit-order-address-modal-box-2">
            <div className="edit-order-address-modal-input-box">
              <p>مبلغ قابل پرداخت</p>
              <InputComponent
                // margin="0 25px 0 0"
                value={paymentType.price}
                disabled={true}
                width="65%"
                height="44px"
                type="text"
                placeholder="استان"
                borderColor="#dcdcdc"
                inputType="input"
                // onchange={(e) =>
                // }
              />
            </div>
            <div className="edit-order-address-modal-input-box">
              <p> نوع پرداخت </p>
              <DropDown
                width="65%"
                height="44px"
                dropDownHeight="200px"
                zindex="1"
                value={paymentType.payment_type}
                //   onchange={(e) => cityOnChange(e)}
                data={[
                  { title: "آنلاین" },
                  { title: "پرداخت در محل" },
                  { title: "کارت به کارت" },
                ]}
                itemClick={(title, id) => addPayment(title, id)}
                search={""}
                //   Data={Data}
                show={paymentShow}
                // axiosname="address"
                placeholder=""
              />
            </div>
            {paymentType.payment_type !== "آنلاین" && (
              <>
                <div className="edit-order-address-modal-input-box">
                  <p>تاریخ و زمان پرداخت</p>
                  <InputComponent
                    disabled={true}
                    value={paymentType.payment_date}
                    width="65% "
                    height="44px"
                    type="text"
                    // id={"barcodeInput"}
                    placeholder="تاریخ ایجاد"
                    borderColor="#dcdcdc"
                    inputType="inputWithIcon"
                    iconSide="left"
                    onClick={() => openPaymentCalender()}
                    iconSrc={AllProductCalender}
                    // onchange={(e) =>
                    // }
                  />
                </div>
                <div className="edit-order-address-modal-input-box">
                  <p>کد پیگیری پرداخت</p>
                  <InputComponent
                    // margin="0 25px 0 0"
                    value={paymentType.transaction_id}
                    disabled={true}
                    width="65%"
                    height="44px"
                    placeholder="شناسه پرداخت"
                    borderColor="#dcdcdc"
                    inputType="inputWithIcon"
                    iconSide="left"
                    onClick={() => createPaymentBarcodeClick()}
                    iconSrc={Barcode}
                    // onchange={(e) =>
                    // }
                  />
                </div>
              </>
            )}
            <div className="edit-order-address-modal-button-box">
              <button
                onClick={() => cancelEditPayment()}
                className="create-order-modal-box-button-box-add-customer-cancel-button"
              >
                لغو
              </button>
              <button
                onClick={() => storeEditPayment()}
                className="create-order-modal-box-button-box-button"
              >
                ثبت تغییرات
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        onBackdropClick={() => opendeliveryModal()}
        className="create-order-modal-all"
        open={deliveryModal}
      >
        <div className="edit-order-delivery-modal-box">
          <div className="create-order-modal-box-top-bar">
            <p>ویرایش اطلاعات ارسال</p>
            <img src={cross} onClick={() => opendeliveryModal()} />
          </div>
          <div className="edit-order-address-modal-box-2">
            <div className="edit-order-address-modal-input-box">
              <p>نوع ارسال</p>
              <DropDown
                width="65%"
                height="44px"
                dropDownHeight="200px"
                zindex="1"
                value={data != null && data.delivery_type}
                //   onchange={(e) => cityOnChange(e)}
                data={deliveryTypes}
                itemClick={(id, title) => addDelivery(id, title)}
                search={""}
                //   Data={Data}
                show={deliveryShow}
                // axiosname="address"
                placeholder="نوع ارسال"
              />
            </div>
            <div className="edit-order-address-modal-input-box">
              <p>هزینه ارسال</p>
              <InputComponent
                // margin="0 25px 0 0"
                value={data != null && data.delivery_price}
                disabled={true}
                width="65%"
                height="44px"
                type="text"
                placeholder="استان"
                borderColor="#dcdcdc"
                inputType="input"
              />
            </div>
            <div className="edit-order-address-modal-input-box">
              <p>تاریخ و زمان تحویل</p>
              <InputComponent
                disabled={true}
                value={orderDetails.delivery_dateTime}
                width="65% "
                height="44px"
                type="text"
                // id={"barcodeInput"}
                placeholder="تاریخ ایجاد"
                borderColor="#dcdcdc"
                inputType="inputWithIcon"
                iconSide="left"
                onClick={() => addDateDeliveryTime()}
                iconSrc={AllProductCalender}
              />
            </div>
            <div className="edit-order-address-modal-button-box">
              <button
                onClick={() => cancelDeliveryType()}
                className="create-order-modal-box-button-box-add-customer-cancel-button"
              >
                لغو
              </button>
              <button
                onClick={() => storeDeliveryClick()}
                className="create-order-modal-box-button-box-button"
              >
                ثبت تغییرات
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* //////////////////////////////// */}
      <Modal
        onBackdropClick={() => openAddressModal()}
        className="create-order-modal-all"
        open={addressModal}
      >
        <div className="edit-order-address-modal-box">
          <div className="create-order-modal-box-top-bar">
            <p>ویرایش آدرس ارسال</p>
            <img src={cross} onClick={() => openAddressModal()} />
          </div>
          <div className="edit-order-address-modal-box-2">
            <div className="edit-order-address-modal-input-box">
              <p>آدرس انتخاب شده</p>
              <DropDown
                width="65%"
                height="44px"
                dropDownHeight="300px"
                zindex="1"
                //   onchange={(e) => cityOnChange(e)}
                data={addresses}
                itemClick={(item) => addAddress(item)}
                //   search={citySearch}
                //   Data={Data}
                show={addressShow}
                axiosname="address"
                placeholder="آدرس"
              />
            </div>
            <div className="edit-order-address-modal-input-box">
              <p>آدرس پستی</p>
              <InputComponent
                // margin="0 25px 0 0"
                value={addressDetails.address_details}
                // disabled={true}
                width="65%"
                height="73px"
                type="text"
                placeholder="استان"
                borderColor="#dcdcdc"
                inputType="input"
                onchange={(e) =>
                  setAddressDetails((prev) => {
                    prev.address_details = e.target.value;
                    forceUpdate();
                    return prev;
                  })
                }
              />
            </div>
            <div className="edit-order-address-modal-input-box">
              <p>استان - شهر</p>
              <div className="edit-order-address-modal-input-box-2">
                <DropDown
                  width="43%"
                  height="44px"
                  dropDownHeight="120px"
                  zindex="1"
                  data={states}
                  onchange={(e) => stateOnChange(e)}
                  search={stateSreach}
                  show={stateShow}
                  value={addressDetails.state}
                  itemClick={(id, title) => addStateClick(id, title)}
                  placeholder="استان"
                />
                <DropDown
                  width="43% "
                  height="44px"
                  dropDownHeight="120px"
                  zindex="1"
                  onchange={(e) => cityOnChange(e)}
                  data={cities}
                  search={citySreach}
                  show={cityShow}
                  value={addressDetails.city}
                  itemClick={(id, title) => addCityClick(id, title)}
                  placeholder="شهر"
                />
              </div>
            </div>
            <div className="edit-order-address-modal-input-box">
              <p>کد پستی</p>
              <InputComponent
                // margin="0 25px 0 0"
                value={addressDetails.post_code}
                // disabled={true}
                width="65%"
                height="44px"
                type="text"
                placeholder="کد پستی"
                borderColor="#dcdcdc"
                inputType="input"
                onchange={(e) =>
                  setAddressDetails((prev) => {
                    prev.post_code = e.target.value;
                    forceUpdate();
                    return prev;
                  })
                }
              />
            </div>
            <div className="edit-order-address-modal-input-box-3">
              <img
                style={{ cursor: "pointer" }}
                onClick={() => setIsOther(!isOther)}
                src={isOther === true ? check : uncheck}
              />
              <p>افزودن گیرنده</p>
            </div>
            {isOther === true && (
              <>
                <div className="edit-order-address-modal-input-box">
                  <p>نام و نام خانوادگی گیرنده</p>
                  <InputComponent
                    // margin="0 25px 0 0"
                    value={orderDetails.transferee_fullName}
                    // disabled={true}
                    width="65%"
                    height="44px"
                    type="text"
                    placeholder="نام و نام خانوادگی گیرنده"
                    borderColor="#dcdcdc"
                    inputType="input"
                    onchange={(e) =>
                      setOrderDetails((prev) => {
                        prev.transferee_fullName = e.target.value;
                        forceUpdate();
                        return prev;
                      })
                    }
                  />
                </div>
                <div className="edit-order-address-modal-input-box">
                  <p>شماره موبایل گیرنده</p>
                  <InputComponent
                    // margin="0 25px 0 0"
                    value={orderDetails.transferee_mobile}
                    // disabled={true}
                    width="65%"
                    height="44px"
                    type="text"
                    placeholder="شماره موبایل گیرنده"
                    borderColor="#dcdcdc"
                    inputType="input"
                    onchange={(e) =>
                      setOrderDetails((prev) => {
                        prev.transferee_mobile = e.target.value;
                        forceUpdate();
                        return prev;
                      })
                    }
                  />
                </div>
              </>
            )}
            <div className="edit-order-address-modal-button-box">
              <button
                onClick={() => cancelStoreAddress()}
                className="create-order-modal-box-button-box-add-customer-cancel-button"
              >
                لغو
              </button>
              <button
                onClick={() => storeAddressClick()}
                className="create-order-modal-box-button-box-button"
              >
                ثبت تغییرات
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* //////////////////////////////// */}
      <div className="edit-order-all-box">
        <div className="edit-order-detail-box">
          <div className="edit-order-detail-title-box">
            <div className="edit-order-detail-title-box-1">
              <p>سفارش</p>
              {data !== null && <p>{data.order_barcode}</p>}

              <div className="edit-order-detail-title-box-1-dropdown-box">
                <p>وضعیت سفارش</p>
                <DropDown
                  width="170px"
                  height="44px"
                  dropDownHeight="300px"
                  value={orderDetails.order_status}
                  zindex="1"
                  data={[
                    {
                      title: "لغو شده",
                    },
                    {
                      title: "در انتظار پرداخت",
                    },
                    {
                      title: "در حال انجام",
                    },
                    {
                      title: "تکمیل شده",
                    },
                  ]}
                  search={""}
                  itemClick={(title, id) => addStatus(title, id)}
                  //   Data={Data}
                  show={statusShow}
                  // axiosname="province"
                  placeholder={`${orderDetails.order_status}`}
                />
              </div>
            </div>
            <div className="edit-order-detail-title-box-1-delete-box">
              <button onClick={() => DeleteClick()}>
                <img src={DeleteRed} />
                حذف سفارش
              </button>
            </div>
          </div>
          <div className="edit-order-detail-title-box-2">
            <div className="edit-order-detail-title-box-2-order-data">
              <p>اطلاعات سفارش</p>
            </div>
            <div className="edit-order-detail-title-box-2-payment-data">
              <p>اطلاعات پرداخت</p>
              <img src={Edit} onClick={() => openPaymentModal()} />
            </div>
            <div className="edit-order-detail-title-box-2-address-data">
              <p>آدرس</p>
              <img src={Edit} onClick={() => openAddressModal()} />
            </div>
            <div className="edit-order-detail-title-box-2-delivery-data">
              <p>اطلاعات ارسال</p>
              <img onClick={() => opendeliveryModal()} src={Edit} />
            </div>
          </div>
          {data !== null && (
            <div className="edit-order-detail-title-box-3">
              <div className="edit-order-detail-title-box-3-order-data">
                <div className="edit-order-detail-title-box-3-order-data-title">
                  <p>تاریخ و زمان ثبت</p>
                  <p>نام مشتری</p>
                  <p>شماره موبایل</p>
                  <p>ایمیل</p>
                </div>
                <div className="edit-order-detail-title-box-3-order-data-title-2">
                  <p>{data.submit_date}</p>
                  <p>
                    {data.customer_name}
                    {data.customer_family}
                  </p>
                  <p>{data.customer_mobile}</p>
                  <p>{data.customer_email}</p>
                </div>
              </div>
              <div className="edit-order-detail-title-box-3-payment-data">
                <div className="edit-order-detail-title-box-3-order-data-title">
                  <p>نوع پرداخت </p>
                  <p>مبلغ قابل پرداخت</p>
                  <p>تاریخ و زمان پرداخت</p>
                  <p>کد پیگیری پرداخت</p>
                </div>
                <div className="edit-order-detail-title-box-3-order-data-title-2">
                  {data.order_payment !== null && (
                    <>
                      <p>{data.order_payment.payment_type}</p>
                      <p>{data.order_payment.total_payment_amount} تومان</p>
                      <p>{data.order_payment.payment_date}</p>
                      <p>{data.order_payment.transaction_id}</p>
                    </>
                  )}
                </div>
              </div>
              <div className="edit-order-detail-title-box-3-address-data">
                <div className="edit-order-detail-title-box-3-address-data-title">
                  <p>آدرس</p>
                  <p>پستی کد</p>
                  <p>گیرنده سفارش</p>
                </div>
                <div className="edit-order-detail-title-box-3-address-data-title-2">
                  <div className="edit-order-detail-title-box-3-address-data-title-3">
                    <p>
                      {data.delivery_addresses.title}{" "}
                      {data.delivery_addresses.address_details}
                    </p>
                    <p>
                      {data.delivery_addresses.state} ,{" "}
                      {data.delivery_addresses.city}
                    </p>
                  </div>
                  <p>{data.delivery_addresses.post_code}</p>
                  <p>{data.transferee_fullName}</p>
                </div>
              </div>
              <div className="edit-order-detail-title-box-3-delivery-data">
                <div className="edit-order-detail-title-box-3-order-data-title">
                  <p>تاریخ و زمان تحویل</p>
                  <p>نوع ارسال</p>
                  <p>هزینه ارسال</p>
                </div>
                <div className="edit-order-detail-title-box-3-order-data-title-2">
                  <p>{data.delivery_date}</p>
                  <p>{data.delivery_type}</p>
                  <p>{data.delivery_price} تومان</p>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="create-order-table-box">
          <div className="create-order-table-box-2">
            <div className="create-order-table-top-bar">
              <div className="create-order-table-top-bar-hashtag">
                <p>#</p>
              </div>
              <div className="create-order-table-top-bar-product">
                <p>محصول</p>
              </div>
              <div className="create-order-table-top-bar-variable">
                <p>متغیر</p>
              </div>
              <div className="create-order-table-top-bar-price">
                <p>مبلغ (تومان)</p>
              </div>
              <div className="create-order-table-top-bar-count">
                <p>تعداد</p>
              </div>
            </div>
            <SimpleBar forceVisible="y" className="create-order-table-box-3">
              {products.map((item, index) => {
                return (
                  <>
                    {item.checked === true && (
                      <div className="create-order-table-item-box">
                        <div className="create-order-table-item-hashtag">
                          <p>{index + 1}</p>
                        </div>
                        <div className="create-order-table-item-product">
                          <div>
                            <img src={item.image_link} />
                          </div>
                          <p>{item.name}</p>
                        </div>
                        <div className="create-order-table-item-variable">
                          {item.variableProperty_key !== null &&
                            item.variableProperty_key === "رنگ" && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "70px",
                                  // maxWidth: "70px",
                                  height: "30px",
                                  marginTop: "17px",
                                  borderRadius: "10px",
                                  border: `solid 1px ${item.variableProperty_alterValue}`,
                                }}
                              >
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontFamily: "IRANSansFaNum",
                                    fontWeight: "900",
                                    direction: "rtl",
                                    fontSize: "16px",
                                    color: `${item.variableProperty_alterValue}`,
                                  }}
                                >
                                  {item.variableProperty_value}
                                </p>
                              </div>
                            )}
                          {item.variableProperty_key !== null &&
                            item.variableProperty_key !== "رنگ" && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "70px",
                                  // maxWidth: "70px",
                                  height: "30px",
                                  marginTop: "17px",
                                  borderRadius: "10px",
                                  border: "solid 1px #000000",
                                }}
                              >
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontFamily: "IRANSansFaNum",
                                    fontWeight: "900",
                                    direction: "rtl",
                                    fontSize: "16px",
                                    color: "#000000",
                                  }}
                                >
                                  {item.variableProperty_value}
                                </p>
                              </div>
                            )}
                        </div>
                        <div className="create-order-table-item-price">
                          <p>{item.price}</p>
                        </div>
                        <div className="create-order-table-item-count">
                          <button
                            onClick={() => finalIncreasmentCount(index)}
                            className="create-order-table-item-count-plus"
                          >
                            <img src={Plus} />
                          </button>
                          <div className="create-order-table-item-count-number">
                            <p>{item.product_count}</p>
                          </div>
                          <button
                            onClick={() => finalDecreamentCount(index)}
                            className="create-order-table-item-count-minus"
                          >
                            <img
                              src={item.product_count === 1 ? DeleteRed : Minus}
                            />
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </SimpleBar>
            <div className="create-order-table-footer">
              <button
                onClick={() => openProductModal()}
                className="create-order-table-footer-add-product-button"
              >
                <img src={AllProductAdd} />
                افزودن محصول
              </button>
              <div className="create-order-table-footer-details-box">
                <p>
                  {final.count}
                  محصول
                </p>
                <p>
                  {final.price}
                  تومان
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="edit-order-buttons-box">
          <button
            onClick={() => cancelEditOrder()}
            className="edit-order-buttons-box-cancel-button"
          >
            لغو
          </button>
          <button
            onClick={() => ShareEditOrder()}
            className="edit-order-buttons-box-share-button"
          >
            ثبت تغییرات
          </button>
        </div>
      </div>
    </div>
  );
}
