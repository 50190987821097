// import React, { useEffect, useRef, useState } from "react";
// import "../../../assets/styles/Blog/CreateBlogStyle/BlogContent.css";
// import TextInputComponent from "../../TextInputComponent/TextInputComponent";

// import axios from "../../../js/Axios/Axios";
// import camera from "../../../assets/icons/cameraIconTextEditor.svg";
// import { SendBlogContent } from "../../../action/index";
// import { useSelector, useDispatch } from "react-redux";
// import { isObjectEmpty } from "../../../helper/Helper";
// import { useLocation } from "react-router-dom";

// /////////////////////////
// import { convertToHTML, convertFromHTML } from "draft-convert";
// import createResizeablePlugin from "@draft-js-plugins/resizeable";
// import {
//   convertToRaw,
//   convertFromRaw,
//   EditorState,
//   AtomicBlockUtils,
// } from "draft-js";
// import createLinkifyPlugin from "@draft-js-plugins/linkify";
// import createImagePlugin from "@draft-js-plugins/image";
// import linkifyIt from "linkify-it";
// import tlds from "tlds";
// import Editor, {
//   createEditorStateWithText,
//   composeDecorators,
// } from "@draft-js-plugins/editor";
// import createDividerPlugin from "@draft-js-plugins/divider";
// import createTextAlignmentPlugin from "@draft-js-plugins/text-alignment";
// import createToolbarPlugin, {
//   Separator,
// } from "@draft-js-plugins/static-toolbar";
// import {
//   ItalicButton,
//   BoldButton,
//   UnderlineButton,
//   CodeButton,
//   HeadlineOneButton,
//   HeadlineTwoButton,
//   HeadlineThreeButton,
//   UnorderedListButton,
//   OrderedListButton,
//   BlockquoteButton,
//   CodeBlockButton,
// } from "@draft-js-plugins/buttons";
// import editorStyles from "../../../../node_modules/@draft-js-plugins/static-toolbar/lib/plugin.css";

// export default function BlogContent() {
//   ////////////////////////////////

//   const blogEdit = useSelector((state) => state.BlogEdit);
//   const location = useLocation();
//   const [showEditor, setShowEditor] = useState(true);
//   const [contentInfo, setContentInfo] = useState(createEditorStateWithText(""));

//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(SendBlogContent(convertToRaw(contentInfo.getCurrentContent())));
//   }, [contentInfo]);

//   useEffect(() => {
//     if (!isObjectEmpty(blogEdit)) {
//       if (location.pathname === "/EditBlog") {
//         let text = JSON.parse(blogEdit.blog_text);
//         let contentstate = convertFromRaw({
//           blocks: text.blocks,
//           entityMap: text.entityMap,
//         });
//         let editorstate = EditorState.createWithContent(contentstate);
//         setContentInfo(editorstate);
//       }
//     }
//   }, [blogEdit]);

//   const onChange = (e) => {
//     setContentInfo(e);
//   };
//   const editorRef = useRef(null);

//   const [
//     {
//       plugins,
//       Toolbar,
//       textAlignmentPlugin,
//       dividerPlugin,
//       DividerButton,
//       imagePlugin,
//       resizePlugin,
//     },
//   ] = useState(() => {
//     const textAlignmentPlugin = createTextAlignmentPlugin();
//     const linkifyPlugin = createLinkifyPlugin({
//       component(props) {
//         return <a {...props} onClick={() => window.open(props.href)} />;
//       },
//       customExtractLinks: (text) =>
//         linkifyIt().tlds(tlds).set({ fuzzyEmail: false }).match(text),
//     });
//     const resizePlugin = createResizeablePlugin({});
//     const decorator = composeDecorators(resizePlugin.decorator);
//     const dividerPlugin = createDividerPlugin();
//     const imagePlugin = createImagePlugin({ decorator });
//     const { DividerButton } = dividerPlugin;
//     const toolbarPlugin = createToolbarPlugin();
//     const { Toolbar } = toolbarPlugin;
//     const plugins = [
//       toolbarPlugin,
//       textAlignmentPlugin,
//       dividerPlugin,
//       linkifyPlugin,
//       imagePlugin,
//       resizePlugin,
//     ];
//     return {
//       plugins,
//       Toolbar,
//       textAlignmentPlugin,
//       DividerButton,
//       dividerPlugin,
//       imagePlugin,
//       resizePlugin,
//     };
//   });

//   const header = {
//     headers: {
//       "Content-Type": "multipart/form-data",
//     },
//   };

//   const uploadImg = (e) => {
//     let formData = new FormData();
//     formData.append("media", e.target.files[0]);
//     axios
//       .post("/media", formData, header)
//       .then((res) => {
//         if (res.data.status) {
//           console.log(res);
//           onChange(
//             imagePlugin.addImage(contentInfo, res.data.data.media_link, null)
//           );
//         }
//       })
//       .catch((e) => {
//         console.log(e);
//       });
//   };

//   return (
//     <div className="blog-content-all">
//       <div
//         onClick={() => editorRef.current && editorRef.current.focus()}
//         className="blog-content-input-box"
//       >
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "column-reverse",
//             justifyContent: "flex-end",
//             width: "100%",
//             height: "413px",
//             fontFamily: "IRANSansWebNoEn",
//             fontSize: "18px",
//             border: "solid 1px #d1d1d1",
//             borderRadius: "10px",
//           }}
//           className={editorStyles.editor}
//         >
//           <div
//             className="blog-content-scroll-div"
//             style={{
//               padding: "20px 10px 10px 10px",
//               fontFamily: "IRANSansWebNoEn",
//               fontSize: "18px",
//               overflow: "scroll",
//             }}
//           >
//             <Editor
//               placeholder="بلاگ خود را بنویسید"
//               editorState={contentInfo}
//               onChange={(e) => onChange(e)}
//               plugins={plugins}
//               ref={(element) => (editorRef.current = element)}
//             />
//           </div>
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               width: "100%",
//               height: "50px",
//               borderBottom: "solid 1px #d1d1d1",
//             }}
//           >
//             <div
//               style={{
//                 height: "40px",
//                 marginRight: "10px",
//               }}
//             >
//               <Toolbar>
//                 {(externalProps) => (
//                   <div
//                     style={{
//                       display: "flex",
//                       alignItems: "center",
//                       backgroundColor: "#fff",
//                     }}
//                   >
//                     <BoldButton {...externalProps} />
//                     <ItalicButton {...externalProps} />
//                     <UnderlineButton {...externalProps} />
//                     <CodeButton {...externalProps} />
//                     <Separator {...externalProps} />
//                     <HeadlineOneButton {...externalProps} />
//                     <HeadlineTwoButton {...externalProps} />
//                     <HeadlineThreeButton {...externalProps} />
//                     <Separator {...externalProps} />
//                     <UnorderedListButton {...externalProps} />
//                     <OrderedListButton {...externalProps} />
//                     <BlockquoteButton {...externalProps} />
//                     <Separator {...externalProps} />
//                     <textAlignmentPlugin.TextAlignment {...externalProps} />
//                     <DividerButton {...externalProps} />
//                     <Separator {...externalProps} />
//                     <label
//                       style={{
//                         display: "flex",
//                         alignItems: "center",
//                       }}
//                       htmlFor="camera"
//                     >
//                       <input
//                         style={{
//                           display: "none",
//                         }}
//                         id="camera"
//                         onChange={(e) => uploadImg(e)}
//                         type="file"
//                       />
//                       <img
//                         className="cameraIcon"
//                         style={{
//                           width: "25px",
//                           height: "25px",
//                           marginLeft: "10px",
//                         }}
//                         src={camera}
//                       />
//                     </label>
//                   </div>
//                 )}
//               </Toolbar>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="blog-content-title-box">
//         <p>محتوای بلاگ</p>
//       </div>
//     </div>
//   );
// }

// import React from 'react'
// import { Editor } from 'react-draft-wysiwyg';
// import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import "../../../assets/styles/Blog/CreateBlogStyle/BlogContent.css";

// export default function BlogContent() {
//   const config = (e) => {
//     console.log(e);
//   }
//   return (
//     <div style={{direction:"ltr"}}>
//       <Editor
//        wrapperClassName="wrapper-class"
//        editorClassName="editor-class"
//        toolbarClassName="toolbar-class"
//        placeholder='jgfkgdhdfhgkdfhgjh'
//        textAlignment ="right"
//        toolbar={{
//         options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'remove', 'history'],
//         image: {
//           // icon: image,
//           className: undefined,
//           component: undefined,
//           popupClassName: undefined,
//           urlEnabled: true,
//           uploadEnabled: true,
//           alignmentEnabled: true,
//           uploadCallback: config,
//           previewImage: false,
//           inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
//           alt: { present: false, mandatory: false },
//           defaultSize: {
//             height: 'auto',
//             width: 'auto',
//           },
//         },
//       }}

//       />
//     </div>
//   )
// }
///////////////////////////////////////////////////////
import React, { useEffect, useRef, useState } from "react";
import "../../../assets/styles/Blog/CreateBlogStyle/BlogContent.css";
import TextInputComponent from "../../TextInputComponent/TextInputComponent";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "../../../js/Axios/Axios";
import camera from "../../../assets/icons/cameraIconTextEditor.svg";
import { SendBlogContent, BlogError } from "../../../action/index";
import { useSelector, useDispatch } from "react-redux";
import { isObjectEmpty } from "../../../helper/Helper";
import { useLocation } from "react-router-dom";
import useForceUpdate from "use-force-update";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import cross from "../../../assets/icons/cross.svg";
import Modal from "@mui/material/Modal";

import { Component } from "react";
import PropTypes from "prop-types";
import { Modifier } from "draft-js";

// class CustomOption extends Component {
//   static propTypes = {
//     onChange: PropTypes.func,
//     editorState: PropTypes.object,
//   };
// http://api.vitsell.ir/storage//upload/admin/Image/original/1657713196.png
// addStar = () => {
//   const { editorState, onChange } = this.props;
//   const contentState = Modifier.replaceText(
//     editorState.getCurrentContent(),
//     editorState.getSelection(),
//     '⭐',
//     editorState.getCurrentInlineStyle()
//   );
//   // const contentState = new Promise((resolve, reject) => {
//   //   editorState.getCurrentContent()
//   //   resolve({ data: { link: "http://api.vitsell.ir/storage//upload/admin/Image/original/1657713196.png" } });
//   // });
//   // console.log(contentState);
//   // onChange(EditorState.push(editorState, contentState, "insert-characters"));
// };

//   header = {
//     headers: {
//       "Content-Type": "multipart/form-data",
//     },
//   };
//   addStar = () => {
//     let formData = new FormData();
//     formData.append("media", e);
//     return new Promise((resolve, reject) => {
//       axios
//         .post("/media", formData, header)
//         .then((res) => {
//           if (res.data.status) {
//             console.log(res);
//             resolve({ data: { link: res.data.data.media_link } });
//             console.log(resolve);
//           }
//         })
//         .catch((e) => {
//           console.log(e);
//         });
//     });
//   };
//   render() {
//     return (
//       <div
//       // onClick={this.addStar}
//       >
//         <input onChange={()=>this.addStar.bind(this,e)} type="file" />
//         zzz
//       </div>
//     );
//   }
// }

function CustomOption() {
  const header = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const addStar = (e) => {
    let formData = new FormData();
    formData.append("media", e);
    return new Promise((resolve, reject) => {
      axios
        .post("/media", formData, header)
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            resolve({ data: { link: res.data.data.media_link } });
            console.log(resolve);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };

  return (
    <div
    // onClick={this.addStar}
    >
      <input onChange={(e) => addStar(e)} type="file" />
      zzz
    </div>
  );
}

export default function BlogContent() {
  const blogEdit = useSelector((state) => state.BlogEdit);
  const Error = useSelector((state) => state.Error);
  const location = useLocation();
  const [errors, SetErrors] = useState({
    title: false,
  });
  const [contentInfo, setContentInfo] = useState(EditorState.createEmpty());

  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    dispatch(SendBlogContent(convertToRaw(contentInfo.getCurrentContent())));
  }, [contentInfo]);

  useEffect(() => {
    if (!isObjectEmpty(blogEdit)) {
      if (location.pathname === "/EditBlog") {
        let text = JSON.parse(blogEdit.blog_text);
        let contentstate = convertFromRaw({
          blocks: text.blocks,
          entityMap: text.entityMap,
        });
        let editorstate = EditorState.createWithContent(contentstate);
        setContentInfo(editorstate);
      }
    }
  }, [blogEdit]);
  const onEditorStateChange = (e) => {
    setContentInfo(e);
    SetErrors((prev) => {
      prev.title = false;
      forceUpdate();
      return prev;
    });
    if (Error.blogText === true) {
      dispatch(BlogError({ BlogText: false }));
    }
  };

  const header = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  useEffect(() => {
    console.log(Error);
    if (Error.blogText === true) {
      SetErrors((prev) => {
        prev.title = true;
        forceUpdate();
        return prev;
      });
    }
  }, [Error]);
  const config = (e) => {
    let formData = new FormData();
    formData.append("media", e);
    return new Promise((resolve, reject) => {
      axios
        .post("/media", formData, header)
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            resolve({ data: { link: res.data.data.media_link } });
            console.log(resolve);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    });
    
  };

  return (
    <div className="blog-content-all">
      <div
        // onClick={() => editorRef.current && editorRef.current.focus()}
        className="blog-content-input-box"
      >
        <div
          style={{
            display: "flex",
            width: "calc(100% - 20px)",
            height: "calc(413px - 30px)",
            // minHeight: "calc(413px - 30px)",
            // maxHeight: "calc(700px - 30px)",
            fontFamily: "IRANSansWebNoEn",
            fontSize: "18px",
            border: `${
              errors.title === true ? "solid 1px #F23B3B" : "solid 1px #d1d1d1"
            }`,
            borderRadius: "10px",
            direction: "ltr",
            padding: "20px 10px 10px 10px",
          }}
        >
          <Editor
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            placeholder=" ... بلاگ خود را بنویسید"
            
            textAlignment="right"
            editorState={contentInfo}
            onEditorStateChange={(e) => onEditorStateChange(e)}
            // toolbarCustomButtons={[<CustomOption />]}
            toolbar={{
              options: [
                "inline",
                "blockType",
                "fontSize",
                "fontFamily",
                "list",
                "textAlign",
                "colorPicker",
                "link",
                "emoji",
                "image",
                "remove",
                "history",
              ],
              fontFamily: {
                options: [
                  "Arial",
                  "Georgia",
                  "Impact",
                  "Tahoma",
                  "Times New Roman",
                  "Verdana",
                  "IRANSansFaNum",
                ],
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
              },
              image: {
                // icon: image,
                className: undefined,
                component: undefined,
                popupClassName: undefined,
                urlEnabled: true,
                uploadEnabled: true,
                alignmentEnabled: true,
                uploadCallback: config,
                previewImage: true,
                inputAccept:
                  "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                alt: { present: true, mandatory: true },
                defaultSize: {
                  height: "auto",
                  width: "auto",
                },
              },
            }}
          />
        </div>
      </div>

      <div className="blog-content-title-box">
        <p>محتوای بلاگ</p>
      </div>
    </div>
  );
}

////////////////////////////////////////////////////////
