import React, { useState, useEffect } from "react";
import "../../assets/styles/CategoryProductStyle/CategoryProduct.css";
import DropDownComponent from "../DropDown/DropDown";
import InputComponent from "../TextInputComponent/TextInputComponent";
import ArrowDown from "../../assets/icons/ArrowDown.svg";
import { useSelector, useDispatch } from "react-redux";
import { SendCategoryId } from "../../action/index";
import { sendCategoryNameAndId } from "../../action/index";
import axios from "../../js/Axios/Axios";
import useForceUpdate from "use-force-update";
import check from "../../assets/icons/check.svg";
import uncheck from "../../assets/icons/uncheck.svg";
import { isObjectEmpty } from "../../helper/Helper";
import { useLocation } from "react-router-dom";
import { ShowAlert } from "../../action/index";
import TreeMenu from "../TreeMenu/TreeMenu";

export default function CategoryProduct() {
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const location = useLocation();
  const [categoryData, setCategoryData] = useState([]);
  const [allCategoryData, setAllCategoryData] = useState([]);

  const [categoryNameId, setCategoryNameId] = useState([]); // state for save category and id for sendig to redux
  const [handelSearch, setHandelSearch] = useState("");
  const [show, setShow] = useState();
  const ProductInfoForEdit = useSelector((state) => state.ProductInfoForEdit);
  const [categoryInfo, setCategoryInfo] = useState({
    productCategory_id: null,
    title: null,
    is_active: 1,
    productCategory_title: null,
  });
  const handelSearchChange = (e) => {
    setHandelSearch(e.target.value);
    console.log(handelSearch);
  };
  const addCategoryItem = (id, title) => {
    setCategoryInfo((prev) => {
      return {
        productCategory_id: id,
        title: prev.title,
        is_active: prev.is_active,
        productCategory_title: title,
      };
    });
    setShow(false);
    setTimeout(() => {
      setShow(true);
    }, 100);
  };
  const addCategory = async () => {
    await axios
      .post("/productCategory", categoryInfo)
      .then((res) => {
        console.log(res);
        if (res.data.status === 0) {
          dispatch(ShowAlert(true, res.data.message));
        }
        if (res.data.status) {
          setCategoryInfo(() => {
            return {
              productCategory_id: null,
              title: null,
              is_active: null,
              productCategory_title: null,
            };
          });
          const input = document.getElementById("categoryTitle");
          input.value = "";
          const categoryDrop = document.getElementById("categoryDrop");
          categoryDrop.value = "";
        }
        axios
          .get("/productCategory?isPaginated=0&isNested=1")
          .then((res) => {
            const category = res.data.data;
            setCategoryData(category);
          })
          .catch((e) => {
            console.log(e);
          });

        axios
          .get("/productCategory?isPaginated=0&isNested=0")
          .then((res) => {
            if (res.data.status) {
              const category = res.data.data;
              setAllCategoryData(category);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      })

      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    axios
      .get("/productCategory?isPaginated=0&isNested=1")
      .then((res) => {
        if (res.data.status) {
          const category = res.data.data;
          setCategoryData(category);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    axios
      .get("/productCategory?isPaginated=0&isNested=0")
      .then((res) => {
        if (res.data.status) {
          const category = res.data.data;
          setAllCategoryData(category);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);



  // useEffect(() => {
  //   if (
  //     (isObjectEmpty(ProductInfoForEdit) === false) &&
  //     (location.pathname === "/AllProductUpdate" || location.pathname === "/AllDraftProductUpdate")
  //   ) {

  //     ProductInfoForEdit.state.productCategories.map((item) => {
  //       chooseCategory.push(item.id);
  //       categoryNameId.push({
  //         title: item.title,
  //         id: item.id,
  //       });
  //       dispatch(sendCategoryNameAndId(categoryNameId));
  //       dispatch(SendCategoryId(chooseCategory));
  //       forceUpdate();
  //     });
  //   }
  // }, [ProductInfoForEdit]);

  return (
    <div className="CategoryCategoryAll">

      <div className="CategoryCategoryTittleBox">
        <p className="CategoryCategoryTittle"> دسته بندی محصولات </p>
      </div>

      <div className="CategoryCategoryBox">


        <div className="CategoryChooseCategoryCheckBox">



          <TreeMenu data={categoryData} type="product"
            childs="all_product_childes_categories" />


        </div>
        <div className="CategoryChooseCategoryInputBox">
          <div className="CategoryChooseCategoryDropdownAndInputBox">
            <DropDownComponent
              id="categoryDrop"
              width="calc(100% - 25px)"
              height="44px"
              dropDownHeight="300px"
              zindex="1"
              search={handelSearch}
              show={show}
              // axiosname="CategoryData"
              onchange={(e) => handelSearchChange(e)}
              value={categoryInfo.productCategory_title}
              itemClick={(id, title) => addCategoryItem(id, title)}
              data={allCategoryData}
              placeholder="انتخاب دسته بندی"
            />
            <InputComponent
              margin="25px 0 0 0"
              value={categoryInfo.title}
              id="categoryTitle"
              width="calc(100% - 25px)"
              height="44px"
              placeholder="افزودن دسته بندی جدید"
              borderColor="#dcdcdc"
              inputType="input"
              type="text"
              onchange={(e) =>
                setCategoryInfo((prev) => {
                  return {
                    productCategory_id: prev.productCategory_id,
                    title: e.target.value,
                    is_active: prev.is_active,
                    productCategory_title: prev.productCategory_title,
                  };
                })
              }
            />
          </div>
          <div className="CategoryChooseCategoryButtonBox">
            <button
              disabled={categoryInfo.title === null ? true : false}
              onClick={() => addCategory()}
              className={
                categoryInfo.title === null
                  ? "CategoryChooseCategoryButton"
                  : "CategoryChooseCategoryButtonActive"
              }
            >

              افزودن دسته بندی
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
