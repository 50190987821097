const iniState = {};
const BlogEditReducer = (state = iniState, action) => {
  switch (action.type) {
    case "SEND_BLOG_INFO_FOR_EDIT":
      state = action.blog;
      return state;
    default:
      return state;
  }
};
export default BlogEditReducer;
