const iniState = [];
  const PaginatedDataReducer = (state = iniState, action) => {
    switch (action.type) {
      case "PAGINATE_DATA":
       
      state = action.payload
  
      default:
        return state;
    }
  };
  export default PaginatedDataReducer;