const iniState = {
  id: null,
};
const ReturnedOrderEditReducer = (state = iniState, action) => {
  switch (action.type) {
    case "SEND_RETURNED_ORDER_ID":
      return { ...state, id: action.id };

    default:
      return state;
  }
};
export default ReturnedOrderEditReducer;
