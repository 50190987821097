import { ActionTypes } from "../../action/actionTypes";

const iniState = {
  tablesData: null,
};
const TableReducer = (state = iniState, action) => {
  switch (action.type) {
    case ActionTypes.SET_TABLE_DATA:
      return { ...iniState, tablesData: action.payload };

    default:
      return state;
  }
};
export default TableReducer;
