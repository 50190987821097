import Image from "../TableComponent/Image";
import check from "../../assets/icons/check.svg";
import uncheck from "../../assets/icons/uncheck.svg";
import EditActive from "../../assets/icons/EditActive.svg";

export const productsTab = [
  {
    title: "محصولات منتشر شده",
    key: "release",
    url:"/product?isPaginated=1",
    action:[{
      title:"زباله دان",
      key:"garbage",
      url:"",
    }],
    columns: [
      {
        title: "#",
        size: 1,
        key: "from",
        component: null,
      },
      {
        title: "",
        size: 1,
        key: "checkBox",
        component: () => {
          return <img src={uncheck} />;
        },
      },
      {
        title: "تصویر",
        size: 4,
        key: "product_image",
        component: (image) => {
          return <Image url={image} />;
        },
      },
      {
        title: "نام",
        size: 8,
        key: "product",
        component: null,
      },
      {
        title: "شناسه",
        size: 6,
        key: "barcode",
        component: null,
      },
      {
        title: "قیمت (تومان)",
        size: 6,
        key: "price",
        component: null,
      },
      {
        title: "تخفبف (تومان)",
        size: 6,
        key: "discount",
        component: null,
      },
      {
        title: "موجودی",
        size: 7,
        key: "available_type",
        component: null,
      },
      {
        title: "دسته بندی",
        size: 5,
        key: "product_category",
        component: null,
      },
      {
        title: "وضیعت انتشار",
        size: 5,
        key: "releaseTime",
        component: null,
      },
      {
        title: "عملیات",
        size: 2,
        key: "opariation",
        component: null,
        action: [
          {
            name: "edit",
            icon: EditActive,
            id: "product_id",
            onClick:"",
          },
        ],
      },
    ],
  },
  {
    title: "محصولات پیش نویس",
    key: "draft",
    url:"/product/draft",
    columns: [
      {
        title: "#",
        size: 1,
        key: "from",
        component: null,
      },
 
      {
        title: "تصویر",
        size: 4,
        key: "productGallery",
        component: (image) => {
          return <Image url={image} />;
        },
      },
      {
        title: "نام",
        size: 8,
        key: "product_name",
        component: null,
      },
      {
        title: "شناسه",
        size: 6,
        key: "barcode",
        component: null,
      },
      {
        title: "قیمت (تومان)",
        size: 6,
        key: "price",
        component: null,
      },
      {
        title: "تخفبف (تومان)",
        size: 6,
        key: "discount",
        component: null,
      },
      {
        title: "موجودی",
        size: 7,
        key: "available_type",
        component: null,
      },
      {
        title: "دسته بندی",
        size: 5,
        key: "productCategory",
        component: null,
      },
      {
        title: "وضیعت انتشار",
        size: 5,
        key: "releaseTime",
        component: null,
      },
      {
        title: "عملیات",
        size: 2,
        key: "opariation",
        component: null,
        action: [
          {
            name: "edit",
            icon: EditActive,
            id: "product_id",
            onClick:"",
          },
        ],
      },
    ],
  },
];
