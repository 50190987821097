import update from "react-addons-update";
const iniState = [
  {
    title: null,
    description: null,
    media_id: null,
    product_id: null,
    imgLink: null,
  },
];
const SendProductReviewReducer = (state = iniState, action) => {
  switch (action.type) {
    case "ADD_OBJECT_PRODUCT_REVIEW":
      let AddObject = {
        title: null,
        description: null,
        media_id: null,
        product_id: null,
        imgLink: null,
        editProductReview_id: null,
      };
      const newarray = update(state, { $push: [AddObject] });
      state = newarray;
      return state;
    case "SEND_PRODUCT_REVIEW_TITLE":
      const newtitle = update(state, {
        [action.index]: { title: { $set: action.title } },
      });
      state = newtitle;

      return state;

      case "SEND_PRODUCT_REVIEW_ID":
      const newid = update(state, {
        [action.index]: { editProductReview_id: { $set: action.id } },
      });
      state = newid;
      return state;



    case "SEND_PRODUCT_REVIEW_DESCRIPTION":
      const newdescription = update(state, {
        [action.index]: { description: { $set: action.description } },
      });
      state = newdescription;
      return state;

    case "SEND_PRODUCT_REVIEW_MEDIA_ID":
      const newmedia = update(state, {
        [action.index]: {
          media_id: { $set: action.media_id },
          imgLink: { $set: action.imgLink },
        },
      });
      state = newmedia;

      return state;

    case "Delete_PRODUCT_REVIEW_MEDIA_ID":
      const deltemedia = update(state, {
        [action.index]: {
          media_id: { $set: null },
          imgLink: { $set: null },
        },
      });
      state = deltemedia;
      return state;

    case "Delete_PRODUCT_REVIEW_ALL_LINE":
      if (state.length > 1) {
        const deletereview = update(state, { $splice: [[action.index, 1]] });
        state = deletereview;
      } else {
        let AddObject = {
          title: null,
          description: null,
          media_id: null,
          product_id: null,
          imgLink: null,
        };
        const deletereview = update(state, { $splice: [[action.index, 1]] });
        state = deletereview;
        const deleteobj = update(state, { $push: [AddObject] });
        state = deleteobj;
      }
      return state;

    default:
      return state;
  }
};
export default SendProductReviewReducer;
