import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import { ShowAlert } from "../../../action/index";
import CircularProgress from "@mui/material/CircularProgress";
import "../../../assets/styles/LoginStyle/Aut.css";
import PersonIcon from "@mui/icons-material/Person";
import login_img from "../../../assets/images/login-img.svg";
import useForceUpdate from "use-force-update";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { LogIn, updateToken } from "../../../action/index";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import axios from "../../../js/Axios/Axios";
import calling from "../../../assets/icons/Iconly-Bulk-Calling.svg";
import lock from "../../../assets/icons/Lock-login.svg";

export default function Aut() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const forcUpdate = useForceUpdate();
  const [isError, setIsError] = useState("");
  const [activityIndicator, setActivityIndicator] = useState(false);
  const [isRemeberMe, setIsRemeberMe] = useState(false);
  const [ShowPassword, setPassWord] = useState();
  const [userNameValue, setUserNameValue] = useState();
  const [LoginInfo, setLoginInfo] = useState({
    password: "",
    phone_number: "",
  });
  const [LoginInfoSaved, setLoginInfoSaved] = useState({
    phoneNumber: "",
    password: "",
  });
  useEffect(() => {
    const username = localStorage.getItem("username");
    setUserNameValue(username);
  });
  const shopassword = (e) => {
    e.preventDefault();
    setPassWord(!ShowPassword);
    forcUpdate();
  };
  const isremeberme = (e) => {
    if (isRemeberMe === false) {
      setIsRemeberMe(true);
    } else if (isRemeberMe === true) {
      setIsRemeberMe(false);
    }
  };

  function login(e) {
    e.preventDefault();
    setActivityIndicator(true);
    forcUpdate();
    axios
      .post("/UserSystem/login", LoginInfo)
      .then((res) => {
        console.log(res);
        if (res.data.status === 0) {
          dispatch(ShowAlert(true, res.data.message));
        }

        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));
          setActivityIndicator(false);
          forcUpdate();
          dispatch(LogIn(LoginInfo));
          dispatch(updateToken(res.data.access_token));
          localStorage.setItem("access_token", res.data.access_token);


          // ? reload page
          window.location.href = "/";
          // navigate("/");
          if (isRemeberMe === true && LoginInfo.phone_number !== "") {
            localStorage.setItem("username", LoginInfo.phone_number);
          }
        } else if (res.data.status === 0) {
          setActivityIndicator(false);
          setIsError(res.data.message.password);
          forcUpdate();
          setTimeout(() => {
            setIsError("");
          }, 8000);
          forcUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
        // dispatch(ShowAlert(true, e.data.error));
        setActivityIndicator(false);
        forcUpdate();
      });
  }
  const loginEntre = (e) => {
    if (e.key === "Enter") {
      setActivityIndicator(true);
      forcUpdate();
      axios
        .post("/UserSystem/login", LoginInfo)
        .then((res) => {
          console.log(res);
          if (res.data.status === 0) {
            dispatch(ShowAlert(true, res.data.message));
          }

          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));
            setActivityIndicator(false);
            forcUpdate();
            dispatch(LogIn(LoginInfo));
            dispatch(updateToken(res.data.access_token));
            localStorage.setItem("access_token", res.data.access_token);
            navigate("/");
            // location.href = "panel.vitsell.ir/Dashboard"
            if (isRemeberMe === true && LoginInfo.phone_number !== "") {
              localStorage.setItem("username", LoginInfo.phone_number);
            }
          } else if (res.data.status === 0) {
            setActivityIndicator(false);
            setIsError(res.data.message.password);
            forcUpdate();
            setTimeout(() => {
              setIsError("");
            }, 8000);
            forcUpdate();
          }
        })
        .catch((e) => {
          console.log(e);
          // dispatch(ShowAlert(true, e.data.error));
          setActivityIndicator(false);
          forcUpdate();
        });
    }
  };

  return (
    <div className="AutAll">
      <div className="aut-img">{/* <img  src={login_img}/> */}</div>
      <div className="AutBox">
        <h3 className="AutLoginTittleBox">ورود به فروشگاه</h3>
        <p className="AutLoginDEtails">
          اطلاعات کاربری خود را در فیلدهای زیر نوشته و وارد شوید
        </p>
        <hr className="AutLoginEdge" />
        <form>
          <div className="AutLoginInputsBox">
            <div className="AutLoginFirstInputBox">
              {/* <PersonIcon className="AutLoginFirstInputIcon" /> */}
              <img
                src={calling}
                alt=""
                className="PhoneRegisterInputNameIcon"
              />
              <input
                onKeyDown={(e) => loginEntre(e)}
                type="number"
                onChange={(e) =>
                  setLoginInfo((prev) => {
                    return {
                      password: prev.password,
                      phone_number: e.target.value,
                    };
                  })
                }
                value={userNameValue}
                className="AutLoginFirstInput"
                placeholder="شماره موبایل"
              />
            </div>
            <div className="AutLoginSecondInputBox">
              <img src={lock} />

              <input
                onKeyDown={(e) => loginEntre(e)}
                onChange={(e) =>
                  setLoginInfo((prev) => {
                    return {
                      phone_number: prev.phone_number,
                      password: e.target.value,
                    };
                  })
                }
                type={!ShowPassword ? "password" : "text"}
                placeholder="رمز عبور"
                className="AutLoginSecondInput"
              />
              <button
                className="AutLoginSecondInputIconButton"
                onClick={(e) => shopassword(e)}
              >
                {ShowPassword ? (
                  <VisibilityIcon className="AutLoginSecondInputIcon" />
                ) : (
                  <VisibilityOffIcon className="AutLoginSecondInputIcon" />
                )}
              </button>
            </div>
          </div>
          <div className="aut-forgot-password">
            <p onClick={() => navigate("/ForgetPassword")}>بازیابی رمز عبور</p>
          </div>
          <div className="AutLoginButtonBox">
            <button
              // onKeyDown={(e) => loginEntre(e)}
              onClick={(e) => login(e)}
              type="submit"
              className="AutLoginButton"
            >
              {activityIndicator === false ? (
                "ورود به فروشگاه"
              ) : (
                <CircularProgress className="aut-button-progress" />
              )}
            </button>
          </div>
        </form>
        <div className="Aut-Login-Under-Box-Down-Box">
          <p className="Aut-Login-Forget-Register-Tittle">
            {" "}
            هنوز در ویتسل ثبت نام نکرده‌اید؟{" "}
          </p>
          <button
            onClick={() => navigate("/PhoneNumberRegister")}
            className="Aut-Login-Under-Box-Down-Box-Button"
          >
            ثبت نام در ویتسل
          </button>
        </div>
      </div>
    </div>
  );
}
