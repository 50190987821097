import React, { useEffect } from "react";
import "../../assets/styles/FrequentlyQuestion/FrequentlyQuestion.css";
import TopBar from "../../components/TopBar/TopBar";
import axios from "../../js/Axios/Axios";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import { ShowAlert } from "../../action/index";
import { useSelector, useDispatch } from "react-redux";
import SearchLogo from "../../assets/icons/SearchLogo.svg";
import faqIcon from "../../assets/icons/faqIcon.svg";
import loginInFrequently from "../../assets/icons/loginInFrequently.svg";
import openTablee from "../../assets/icons/openTable.svg";
import Edit from "../../assets/icons/Edit.svg";
import deleteRed from "../../assets/icons/DeleteRed.svg";
import EditActive from "../../assets/icons/EditActive.svg";
import closeTable from "../../assets/icons/closeTable.svg";
import arrowDownFrequently from "../../assets/icons/arrowDownFrequently.svg";
import arrowUpFrequently from "../../assets/icons/arrowUpFrequently.svg";
import AllProductAdd from "../../assets/icons/AllProductAdd.svg";
import { useState } from "react";
import useForceUpdate from "use-force-update";
export default function FrequentlyQuestion() {
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [itemShow, setItemShow] = useState(null);
  const [answerShow, setAnswerShow] = useState(null);
  const [isEdit, setIsEdit] = useState(null);
  const [frequntlyIsEdit, setFrequntlyIsEdit] = useState(null);
  const [frequentlyAnswerShow, setFrequentlyAnswerShow] = useState(null);
  const [showFrequntly, setShowFrequnetly] = useState(true);

  // state for add new category
  const [newCategory, setNewcategory] = useState({
    active: false,
    title: "",
  });

  // state for update category
  const [updateCategoryInfo, setUpdateCategoryInfo] = useState({
    isEdit: null,
    title: "",
  });

  // state for add new question for specific category
  const [newQuestionCategory, setNewQuestionCategory] = useState({
    active: null,
    frequentlyQuestionCategory_id: "",
    isFrequently: 0,
    question: "",
    answer: "",
  });
  const [updateInfo, setUpdateInfo] = useState({
    question: null,
    answer: null,
  });
  useEffect(() => {
    axios
      .get("/StoreFrequentlyQuestion")
      .then((res) => {
        if (res.data.status) {
          setData(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const openItem = (index) => {
    if (isEdit === null) {
      if (itemShow === index) {
        setItemShow(null);
      } else if (itemShow != index) {
        setItemShow(index);
      }
      forceUpdate();
    }
  };
  const openAnswer = (index, i) => {
    if (answerShow === index + "" + i) {
      setAnswerShow(null);
      setIsEdit(null);
      setFrequntlyIsEdit(null);
      forceUpdate();
    } else if (answerShow !== index + "" + i) {
      setAnswerShow(index + "" + i);
    }
    forceUpdate();
  };
  const editClick = (e, index, i, item) => {
    e.stopPropagation();
    if (isEdit === null) {
      setAnswerShow(index + "" + i);
      setIsEdit(index + "" + i);
      setFrequentlyAnswerShow(null);
      setFrequntlyIsEdit(null);
      setUpdateInfo((prev) => {
        prev.question = item.title_question;
        prev.answer = item.answer;
        return prev;
      });
      forceUpdate();
    }
  };
  const cancelEdit = () => {
    setIsEdit(null);
    setFrequntlyIsEdit(null);
    forceUpdate();
  };
  const SearchOnChange = (e) => {
    axios
      .get(`/StoreFrequentlyQuestion?search=${e.target.value}`)
      .then((res) => {
        if (res.data.status) {
          setData(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateQuestion = (id) => {
    if (updateInfo.answer != "" && updateInfo.question != "") {
      axios
        .put(`/StoreFrequentlyQuestion/${id}`, updateInfo)
        .then((res) => {
          if (res.data.status === 0) {
            dispatch(ShowAlert(true, res.data.message));
          }
          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));
            axios
              .get("/StoreFrequentlyQuestion")
              .then((res) => {
                if (res.data.status) {
                  setData(res.data.data);
                }
              })
              .catch((e) => {
                console.log(e);
              });
            setIsEdit(null);
            setItemShow(null);
            setAnswerShow(null);
            setFrequentlyAnswerShow(null);
            setFrequntlyIsEdit(null);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      dispatch(ShowAlert(true, "پر کردن فیلد سوال و جواب الزامی است"));
    }
  };

  const openFrequentlyAnswer = (index) => {
    if (frequentlyAnswerShow === index) {
      setFrequentlyAnswerShow(null);

      setFrequntlyIsEdit(null);
    } else {
      setFrequentlyAnswerShow(index);
    }
    forceUpdate();
  };

  const editFrequntlyAnswerClick = (e, index, item) => {
    e.stopPropagation();
    if (frequntlyIsEdit === null) {
      setFrequentlyAnswerShow(index);
      setFrequntlyIsEdit(index);
      setAnswerShow(null);
      setIsEdit(null);
      forceUpdate();
      setUpdateInfo((prev) => {
        prev.question = item.title_question;
        prev.answer = item.answer;
        forceUpdate();
        return prev;
      });
    }
  };

  // show new Category box for add new category
  const addCategory = () => {
    setNewcategory((prev) => {
      prev.active = true;
      forceUpdate();
      return prev;
    });
  };

  // submit new category
  const submitCategory = () => {
    // add new Category Box
    if (newCategory.title !== "") {
      axios
        .post("/FrequentlyQuestionCategory", newCategory)
        .then((res) => {
          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));
            axios
              .get("/StoreFrequentlyQuestion")
              .then((res) => {
                if (res.data.status) {
                  setNewcategory((prev) => {
                    prev.active = false;
                    prev.title = "";
                    forceUpdate();
                    return prev;
                  });
                  setData(res.data.data);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      dispatch(
        ShowAlert(
          true,
          "برای افزودن دسته بندی پر کردن فیلد عنوان دسته بندی الزامی است ."
        )
      );
      setError((prev) => {
        prev.category = true;
        forceUpdate();
        return prev;
      });
    }
  };

  // function for setCategories info for new category
  const categoryOnChange = (e) => {
    // set state
    setNewcategory((prev) => {
      prev.title = e.target.value;
      forceUpdate();
      return prev;
    });
    setError((prev) => {
      prev.category = false;
      forceUpdate();
      return prev;
    });
  };

  // destroy categories
  const destoryCategory = (e, id) => {
    e.stopPropagation();
    // destroy fqCategory

    axios
      .delete(`FrequentlyQuestionCategory/${id}`)
      .then((res) => {
        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));
          axios
            .get("/StoreFrequentlyQuestion")
            .then((res) => {
              if (res.data.status) {
                setData(res.data.data);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })

      .catch((e) => {
        console.log(e);
      });
  };

  // edit Category
  const editCategory = (e, index, title) => {
    e.stopPropagation();
    setItemShow(null);
    setUpdateCategoryInfo((prev) => {
      prev.isEdit = index;
      prev.title = title;
      forceUpdate();
      return prev;
    });
  };

  // cancle edit category
  const cancleEditCategory = () => {
    setUpdateCategoryInfo((prev) => {
      prev.isEdit = null;
      forceUpdate();
      return prev;
    });
  };

  // update category
  const updateCategory = (id) => {
    axios
      .put(`FrequentlyQuestionCategory/${id}`, updateCategoryInfo)
      .then((res) => {
        if (res.data.status) {
          setUpdateCategoryInfo((prev) => {
            prev.isEdit = null;
            prev.title = "";
            forceUpdate();
            return prev;
          });

          dispatch(ShowAlert(true, res.data.message));
          axios
            .get("/StoreFrequentlyQuestion")
            .then((res) => {
              if (res.data.status) {
                setData(res.data.data);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // add new question for specific category
  const addQuestion = (e, id, index, isFrequently) => {
    e.stopPropagation();

    setNewQuestionCategory((prev) => {
      prev.active = index;
      prev.frequentlyQuestionCategory_id = id;
      prev.isFrequently = isFrequently;
      forceUpdate();
      return prev;
    });
  };
  const [error, setError] = useState({
    category: false,
    add_question: false,
    add_answer: false,
  });
  // submit new question
  const submitNewQuestion = () => {
    // axios for add new question
    console.log(newQuestionCategory);
    if (
      newQuestionCategory.answer != "" &&
      newQuestionCategory.question != ""
    ) {
      axios
        .post("StoreFrequentlyQuestion", newQuestionCategory)
        .then((res) => {
          console.log(res);
          if (res.data.status) {
            setNewQuestionCategory((prev) => {
              prev.active = null;
              prev.answer = "";
              prev.question = "";
              prev.frequentlyQuestionCategory_id = "";
              forceUpdate();
              return prev;
            });

            // show alert
            dispatch(ShowAlert(true, res.data.message));

            axios
              .get("/StoreFrequentlyQuestion")
              .then((res) => {
                if (res.data.status) {
                  setData(res.data.data);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })

        .catch((e) => {
          console.log(e);
        });
    } else {
      let text = `برای افزودن سوال پر کردن فیلد های 
      ${newQuestionCategory.question === "" ? "متن سوال و" : ""}
      ${newQuestionCategory.answer === "" ? "پاسخ" : ""}
      الزامی است .
      `;
      dispatch(ShowAlert(true, text));
      if (newQuestionCategory.answer === "") {
        setError((prev) => {
          prev.add_answer = true;
          forceUpdate();
          return prev;
        });
      }
      if (newQuestionCategory.question === "") {
        setError((prev) => {
          prev.add_question = true;
          forceUpdate();
          return prev;
        });
      }
    }
  };

  // remove questions
  const removeQuestionCategory = (e, id) => {
    e.stopPropagation();

    // axios for destroy question
    axios
      .delete(`StoreFrequentlyQuestion/${id}`)
      .then((res) => {
        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));

          axios
            .get("/StoreFrequentlyQuestion")
            .then((res) => {
              if (res.data.status) {
                setData(res.data.data);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="frequently-question-all">
      <div className="frequently-question-top-bar-box">
        <TopBar />
      </div>
      <div className="frequently-question-serach-box">
        <button onClick={() => addCategory()} className="fq-add-category">
          <img src={AllProductAdd} />
          افزودن دسته بندی
        </button>

        <InputComponent
          width="433px"
          height="44px"
          type="text"
          inputType="inputWithIcon"
          iconSide="right"
          iconSrc={SearchLogo}
          backgroundColor="#f7f7f7"
          placeholder="جستجو .."
          borderColor="#dcdcdc"
          onchange={(e) => SearchOnChange(e)}
        />
      </div>

      {/* add new category Box */}
      <span
        onClick={() => {
          setNewcategory((prev) => {
            prev.active = false;
            prev.title = "";
            forceUpdate();
            return prev;
          });
        }}
        style={newCategory.active ? { display: "flex" } : { display: "none" }}
        className="blure-layout"
      ></span>
      <div
        style={newCategory.active ? { display: "flex" } : { display: "none" }}
        className="add-new-category-parent"
      >
        <div className="add-new-category-box">
          <InputComponent
            width="309px"
            height="43px"
            type="text"
            inputType="input"
            value={newCategory.title}
            backgroundColor="#f7f7f7"
            placeholder="عنوان دسته بندی"
            borderColor={error.category === true ? "#F23B3B" : "#dcdcdc"}
            onchange={(e) => categoryOnChange(e)}
          />

          <button
            onClick={() => submitCategory()}
            className="submit-add-category"
          >
            ثبت
          </button>
        </div>
      </div>

      <div className="frequently-question-box">
        {data != null &&
          data.question_categories.map((item, index) => {
            return (
              <div
                style={
                  itemShow == index
                    ? {
                        maxHeight: "10000px",
                        transition: "max-height ease-in-out 3s",
                      }
                    : {
                        transition: "max-height ease-in-out 3s",
                        maxHeight: "92px",
                      }
                }
                key={index}
                className="frequently-question-box-2"
              >
                {updateCategoryInfo.isEdit == index ? (
                  <div className="edit-category-div">
                    <InputComponent
                      width="309px"
                      height="43px"
                      type="text"
                      inputType="input"
                      value={updateCategoryInfo.title}
                      backgroundColor="#f7f7f7"
                      placeholder="عنوان دسته بندی"
                      borderColor="#dcdcdc"
                      onchange={(e) => {
                        setUpdateCategoryInfo((prev) => {
                          prev.title = e.target.value;
                          forceUpdate();
                          return prev;
                        });
                      }}
                    />

                    <div className="edit-category-buttons">
                      <button
                        onClick={() => cancleEditCategory()}
                        className="edit-category-cancle"
                      >
                        لغو
                      </button>

                      <button
                        onClick={() => updateCategory(item.id)}
                        className="submit-add-category"
                      >
                        ثبت
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={() => openItem(index)}
                    className="frequently-question-box-3"
                  >
                    <span
                      onClick={(e) => e.stopPropagation()}
                      style={
                        newQuestionCategory.active == index
                          ? { display: "flex", position: "absolute" }
                          : { display: "none" }
                      }
                      className="blure-layout"
                    ></span>

                    <div className="frequently-question-item-title-box">
                      <img src={faqIcon} />
                      <p className="frequently-question-item-title">
                        {item.title}
                      </p>
                      <p className="frequently-question-item-title-2">
                        ( پرسش و پاسخ {item.questions.length})
                      </p>
                    </div>

                    <div className="frequently-question-item-icon-box">
                      <button
                        style={itemShow === index ? { display: "flex" } : {}}
                        onClick={(e) => addQuestion(e, item.id, index, 0)}
                        className="fq-add-question"
                      >
                        <img src={AllProductAdd} />
                        افزودن سوال
                      </button>

                      <img
                        onClick={(e) => editCategory(e, index, item.title)}
                        style={itemShow === index ? { display: "flex" } : {}}
                        src={EditActive}
                      />

                      <img
                        onClick={(e) => destoryCategory(e, item.id)}
                        style={itemShow === index ? { display: "flex" } : {}}
                        src={deleteRed}
                      />

                      <img src={itemShow === index ? closeTable : openTablee} />
                    </div>
                  </div>
                )}

                {newQuestionCategory.active == index ? (
                  <div className="frequently-question-item-question-box-3">
                    <div
                      style={{ marginBottom: "0" }}
                      className="frequently-question-item-question-box-2"
                    >
                      <div className="frequently-question-item-question-input">
                        <p>متن سوال :</p>
                        <InputComponent
                          value={newQuestionCategory.question}
                          width="calc(100% - 120px)"
                          height="75px"
                          type="text"
                          inputType="textarea"
                          placeholder=""
                          borderColor={
                            error.add_question ? "#F23B3B" : "#dcdcdc"
                          }
                          onchange={(e) => {
                            setNewQuestionCategory((prev) => {
                              prev.question = e.target.value;
                              forceUpdate();
                              return prev;
                            });
                            setError((prev) => {
                              prev.add_question = false;
                              forceUpdate();
                              return prev;
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{ margin: "20px 0 !important" }}
                      className="frequently-question-item-line"
                    ></div>

                    <div className="frequently-question-item-answer-input-box">
                      <div className="frequently-question-item-answer-input-box-2">
                        <p>پاسخ :</p>
                        <InputComponent
                          value={newQuestionCategory.answer}
                          width="calc(100% - 120px)"
                          height="127px"
                          type="text"
                          inputType="textarea"
                          placeholder=""
                          borderColor={error.add_answer ? "#F23B3B" : "#dcdcdc"}
                          onchange={(e) => {
                            setNewQuestionCategory((prev) => {
                              prev.answer = e.target.value;
                              forceUpdate();
                              return prev;
                            });
                            setError((prev) => {
                              prev.add_answer = false;
                              forceUpdate();
                              return prev;
                            });
                          }}
                        />
                      </div>
                      <div className="frequently-question-item-answer-input-button-box">
                        <button
                          onClick={() => {
                            setNewQuestionCategory((prev) => {
                              prev.active = null;
                              prev.question = "";
                              prev.answer = "";
                              forceUpdate();
                              return prev;
                            });
                          }}
                          className="frequently-question-item-answer-input-button-cancel"
                        >
                          <p>لغو</p>
                        </button>
                        <button
                          onClick={() => submitNewQuestion()}
                          className="frequently-question-item-answer-input-button-share"
                        >
                          <p>ذخیره</p>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  // Map Questions Is Here

                  itemShow === index &&
                  item.questions.map((item2, i) => {
                    return (
                      <div
                        key={index + "" + i}
                        className={
                          answerShow === index + "" + i
                            ? "frequently-question-item-question-box-3"
                            : "frequently-question-item-question-box"
                        }
                      >
                        <div className="frequently-question-item-question-box-2">
                          {isEdit !== index + "" + i && (
                            <div
                              style={
                                answerShow == index + "" + i
                                  ? { alignItems: "flex-start" }
                                  : { alignItems: "center" }
                              }
                              onClick={() => openAnswer(index, i)}
                              className="frequently-question-item-question-text-box"
                            >
                              {answerShow === index + "" + i ? (
                                <p className="frequently-question-item-question-text">
                                  {item2.title_question}
                                </p>
                              ) : (
                                <p className="frequently-question-item-question-text">
                                  {item2.title_question.slice(0, 100) +
                                    (item2.title_question.length > 100
                                      ? "..."
                                      : "")}
                                </p>
                              )}
                            </div>
                          )}
                          {answerShow === index + "" + i &&
                            isEdit === index + "" + i && (
                              <div className="frequently-question-item-question-input">
                                <p>متن سوال :</p>
                                <InputComponent
                                  value={updateInfo.question}
                                  width="calc(100% - 120px)"
                                  height="75px"
                                  type="text"
                                  inputType="textarea"
                                  placeholder=""
                                  borderColor="#dcdcdc"
                                  onchange={(e) =>
                                    setUpdateInfo((prev) => {
                                      prev.question = e.target.value;
                                      forceUpdate();
                                      return prev;
                                    })
                                  }
                                />
                              </div>
                            )}

                          {isEdit != index + "" + i && (
                            <div
                              style={
                                answerShow == index + "" + i
                                  ? { alignItems: "flex-start" }
                                  : { alignItems: "center" }
                              }
                              className="frequently-question-item-question-icon-box"
                            >
                              <img
                                onClick={(e) => editClick(e, index, i, item2)}
                                src={EditActive}
                                // onMouseOver={(e) =>
                                //   (e.currentTarget.src = EditActive)
                                // }
                                // onMouseOut={(e) => (e.currentTarget.src = Edit)}
                              />

                              <img
                                onClick={(e) =>
                                  removeQuestionCategory(e, item2.id)
                                }
                                src={deleteRed}
                              />

                              <img
                                onClick={() => openAnswer(index, i)}
                                src={
                                  answerShow === index + "" + i
                                    ? arrowUpFrequently
                                    : arrowDownFrequently
                                }
                              />
                            </div>
                          )}
                        </div>
                        {answerShow === index + "" + i &&
                          isEdit !== index + "" + i && (
                            <>
                              <div className="frequently-question-item-line"></div>
                              <div className="frequently-question-item-answer-box">
                                <p>{item2.answer}</p>
                              </div>
                            </>
                          )}
                        {answerShow === index + "" + i &&
                          isEdit === index + "" + i && (
                            <div className="frequently-question-item-answer-input-box">
                              <div className="frequently-question-item-answer-input-box-2">
                                <p>پاسخ :</p>
                                <InputComponent
                                  value={updateInfo.answer}
                                  width="calc(100% - 120px)"
                                  height="127px"
                                  type="text"
                                  inputType="textarea"
                                  placeholder=""
                                  borderColor="#dcdcdc"
                                  onchange={(e) =>
                                    setUpdateInfo((prev) => {
                                      prev.answer = e.target.value;
                                      forceUpdate();
                                      return prev;
                                    })
                                  }
                                />
                              </div>
                              <div className="frequently-question-item-answer-input-button-box">
                                <button
                                  onClick={() => cancelEdit()}
                                  className="frequently-question-item-answer-input-button-cancel"
                                >
                                  <p>لغو</p>
                                </button>
                                <button
                                  onClick={() => updateQuestion(item2.id)}
                                  className="frequently-question-item-answer-input-button-share"
                                >
                                  <p>ذخیره</p>
                                </button>
                              </div>
                            </div>
                          )}
                      </div>
                    );
                  })
                )}
              </div>
            );
          })}
        {data !== null && (
          // <div className="frequently-question-box-4">
          <div
            style={
              showFrequntly
                ? {
                    maxHeight: "10000px",
                    transition: "max-height ease-in-out 1s",
                  }
                : {
                    maxHeight: "92px",
                    transition: "max-height ease-in-out 1s",
                  }
            }
            className="frequently-question-box-5"
          >
            <div
              onClick={() => {
                if (frequntlyIsEdit == null) {
                  setShowFrequnetly(!showFrequntly);
                }
              }}
              className="frequently-question-box-6"
            >
              <span
                onClick={(e) => e.stopPropagation()}
                style={
                  newQuestionCategory.active == 10000
                    ? { display: "flex", position: "absolute" }
                    : { display: "none" }
                }
                className="blure-layout"
              ></span>

              <div className="frequently-question-title-box">
                <p className="frequently-question-title">سوالات متداول</p>
                <p className="frequently-question-title-2">
                  ( پرسش و پاسخ {data.frequently_questions.length})
                </p>
              </div>
              <div className="frequently-question-icon-box">
                <button
                  onClick={(e) => addQuestion(e, null, 10000, 1)}
                  style={
                    showFrequntly ? { display: "flex" } : { display: "none" }
                  }
                  className="fq-add-question"
                >
                  <img src={AllProductAdd} />
                  افزودن سوال
                </button>

                <img
                  onClick={(e) => {
                    e.stopPropagation();
                    if (frequntlyIsEdit == null) {
                      setShowFrequnetly(!showFrequntly);
                    }
                  }}
                  src={showFrequntly === true ? closeTable : openTablee}
                />
              </div>
            </div>

            {newQuestionCategory.active == 10000 ? (
              <div className="frequently-question-item-question-box-3">
                <div
                  style={{ marginBottom: "0" }}
                  className="frequently-question-item-question-box-2"
                >
                  <div className="frequently-question-item-question-input">
                    <p>متن سوال :</p>
                    <InputComponent
                      value={newQuestionCategory.question}
                      width="calc(100% - 120px)"
                      height="75px"
                      type="text"
                      inputType="textarea"
                      placeholder=""
                      borderColor={
                        error.add_question ? "#F23B3B" : "#dcdcdc"
                      }
                      onchange={(e) => {
                        setNewQuestionCategory((prev) => {
                          prev.question = e.target.value;
                          forceUpdate();
                          return prev;
                        });
                        setError((prev) => {
                          prev.add_question = false;
                          forceUpdate();
                          return prev;
                        });
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{ margin: "20px 0 !important" }}
                  className="frequently-question-item-line"
                ></div>

                <div className="frequently-question-item-answer-input-box">
                  <div className="frequently-question-item-answer-input-box-2">
                    <p>پاسخ :</p>
                    <InputComponent
                      value={newQuestionCategory.answer}
                      width="calc(100% - 120px)"
                      height="127px"
                      type="text"
                      inputType="textarea"
                      placeholder=""
                      borderColor={
                        error.add_answer ? "#F23B3B" : "#dcdcdc"
                      }
                      onchange={(e) => {
                        setNewQuestionCategory((prev) => {
                          prev.answer = e.target.value;
                          forceUpdate();
                          return prev;
                        });
                        setError((prev) => {
                          prev.add_answer = false;
                          forceUpdate();
                          return prev;
                        });
                      }}
                    />
                  </div>
                  <div className="frequently-question-item-answer-input-button-box">
                    <button
                      onClick={() => {
                        setNewQuestionCategory((prev) => {
                          prev.active = null;
                          prev.question = "";
                          prev.answer = "";
                          forceUpdate();
                          return prev;
                        });
                      }}
                      className="frequently-question-item-answer-input-button-cancel"
                    >
                      <p>لغو</p>
                    </button>
                    <button
                      onClick={() => submitNewQuestion()}
                      className="frequently-question-item-answer-input-button-share"
                    >
                      <p>ذخیره</p>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              showFrequntly === true &&
              data.frequently_questions.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={
                      frequentlyAnswerShow === index
                        ? "frequently-question-item-question-box-3"
                        : "frequently-question-item-question-box"
                    }
                  >
                    <div
                      onClick={() => openFrequentlyAnswer(index)}
                      className="frequently-question-item-question-box-2"
                    >
                      {frequntlyIsEdit !== index && (
                        <div
                          style={
                            frequentlyAnswerShow == index
                              ? { alignItems: "flex-start" }
                              : { alignItems: "center" }
                          }
                          className="frequently-question-item-question-text-box"
                        >
                          <p className="frequently-question-item-question-text">
                            {item.title_question.slice(0, 100) +
                              (item.title_question.length > 100 ? "..." : "")}
                          </p>
                        </div>
                      )}

                      {frequentlyAnswerShow === index &&
                        frequntlyIsEdit === index && (
                          <div className="frequently-question-item-question-input">
                            <p>متن سوال :</p>
                            <InputComponent
                              value={updateInfo.question}
                              width="90%"
                              height="75px"
                              type="text"
                              inputType="textarea"
                              placeholder=""
                              borderColor="#dcdcdc"
                              onchange={(e) =>
                                setUpdateInfo((prev) => {
                                  prev.question = e.target.value;
                                  forceUpdate();
                                  return prev;
                                })
                              }
                            />
                          </div>
                        )}

                      {frequntlyIsEdit == null && (
                        <div
                          style={
                            frequentlyAnswerShow == index
                              ? { alignItems: "flex-start" }
                              : { alignItems: "center" }
                          }
                          className="frequently-question-item-question-icon-box"
                        >
                          <img
                            onClick={(e) =>
                              editFrequntlyAnswerClick(e, index, item)
                            }
                            src={EditActive}
                          />

                          <img
                            onClick={(e) => removeQuestionCategory(e, item.id)}
                            src={deleteRed}
                          />

                          <img
                            onClick={() => openFrequentlyAnswer(index)}
                            src={
                              frequentlyAnswerShow === index
                                ? arrowUpFrequently
                                : arrowDownFrequently
                            }
                          />
                        </div>
                      )}
                    </div>
                    {frequentlyAnswerShow === index &&
                      frequntlyIsEdit !== index && (
                        <>
                          <div className="frequently-question-item-line"></div>
                          <div className="frequently-question-item-answer-box">
                            <p>{item.answer}</p>
                          </div>
                        </>
                      )}
                    {frequentlyAnswerShow === index &&
                      frequntlyIsEdit === index && (
                        <div className="frequently-question-item-answer-input-box">
                          <div className="frequently-question-item-answer-input-box-2">
                            <p>پاسخ :</p>
                            <InputComponent
                              value={updateInfo.answer}
                              width="90%"
                              height="127px"
                              type="text"
                              inputType="textarea"
                              placeholder=""
                              borderColor="#dcdcdc"
                              onchange={(e) =>
                                setUpdateInfo((prev) => {
                                  prev.answer = e.target.value;
                                  forceUpdate();
                                  return prev;
                                })
                              }
                            />
                          </div>
                          <div className="frequently-question-item-answer-input-button-box">
                            <button
                              onClick={() => cancelEdit()}
                              className="frequently-question-item-answer-input-button-cancel"
                            >
                              <p>لغو</p>
                            </button>
                            <button
                              onClick={() => updateQuestion(item.id)}
                              className="frequently-question-item-answer-input-button-share"
                            >
                              <p>ذخیره</p>
                            </button>
                          </div>
                        </div>
                      )}
                  </div>
                );
              })
            )}
          </div>
          /* </div> */
        )}
      </div>
    </div>
  );
}
