import Modal from "@mui/material/Modal";
import axios from "../../js/Axios/Axios";
import { ShowAlert } from "../../action/index";
import { useSelector, useDispatch } from "react-redux";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { useEffect, useState } from "react";
import "../../assets/styles/OrderStyle/createReturnedOrder.css";
import SearchLogo from "../../assets/icons/SearchLogo.svg";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import cross from "../../assets/icons/cross.svg";
import arrowLeft from "../../assets/icons/ArrowDown.svg";
import arrowLeftActive from "../../assets/icons/ArrowDownActive.svg";
import userOrder from "../../assets/icons/userOrder.svg";
import useForceUpdate from "use-force-update";
import uncheck from "../../assets/icons/uncheck.svg";
import check from "../../assets/icons/check.svg";
import Minus from "../../assets/icons/Minus.svg";
import Plus from "../../assets/icons/Plus.svg";
import DropDownComponent from "../../components/DropDown/DropDown";
import EditActive from "../../assets/icons/EditActive.svg";
import { useRef } from "react";

function CreateReturnedOrder(prop) {
  const dispatch = useDispatch();

  const [editProductPrice, setEditProductPrice] = useState(0);
  const [editProductDiscount, setEditProductDiscount] = useState(0);

  // ? get returnedOrder_id for edit
  const returnedOrder_id = useSelector((state) => state.ReturnedOrderEdit.id);
  // edit info state
  const [editInfo, setEditInfo] = useState(null);

  // ? useEffect for update returned order
  useEffect(() => {
    // ? axios for update returned order
    axios
      .get(`ReturnedOrder/${returnedOrder_id}/edit`)
      .then((res) => {
        if (res.data.status) {
          setEditInfo(res.data.data);
          setEditProductPrice(res.data.data.price);
          setEditProductDiscount(res.data.data.discount);

          setEditResultSearch(res.data.data.returned_cause);
          forceUpdate();
          // ? go to state 4
          setState(4);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [returnedOrder_id]);

  const forceUpdate = useForceUpdate();
  const { isOpenProp, isOpenPropForEdit, closeModal, closeEditModal } = prop;

  const [state, setState] = useState(1);

  const [allOrders, setAllOrders] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [onHover, setOnHover] = useState(null);

  const [selectedOrder, setSelectedOrder] = useState(null);

  // ? state for cause returned
  const [causesReturned, setCausesReturned] = useState([
    { id: "1", title: "خرابی محصول " },
    { id: "2", title: "محصول شبیه عکس هایش نبود ." },
    { id: "3", title: "کیفیت پایین محصول" },
  ]);
  const [resultSearch, setResultSearch] = useState("");
  const [showDropDown, setShowDropDown] = useState(null);

  const [editResultSearch, setEditResultSearch] = useState("");
  const [editShowDropDown, setEditShowDropDown] = useState(null);

  const [showReturnedPrice, setShowReturnedPrice] = useState(false);
  const [editShowReturnedPrice, setEditShowReturnedPrice] = useState(false);
  // ? final state for create returned order
  const [returnedInfo, setReturnedInfo] = useState({
    count: 1,
    index: null,
    orderItem_id: null,
    price: null,
    discount: null,
    returned_price: 0,
    returned_adminDescription: "",
    returned_cause: "",
  });

  //   ? useeffect for get all orders
  useEffect(() => {
    axios
      .get(`/ReturnedOrder/relatedList`)
      .then((res) => {
        if (res.data.status) {
          setAllOrders(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  // ? check is open modal for remove search value
  useEffect(() => {
    if (isOpenProp == false) {
      setSearchValue("");
      setState(1);
      setSelectedOrder(null);

      setReturnedInfo({
        count: 1,
        index: null,
        orderItem_id: null,
        price: null,
        discount: null,
        returned_price: 0,
        returned_adminDescription: "",
      });
    } else {
      axios
        .get(`/ReturnedOrder/relatedList`)
        .then((res) => {
          if (res.data.status) {
            setAllOrders(res.data.data);
            setState(1);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [isOpenProp]);

  useEffect(() => {
    axios
      .get(`ReturnedOrder/${returnedOrder_id}/edit`)
      .then((res) => {
        if (res.data.status) {
          setEditInfo(res.data.data);

          setEditResultSearch(res.data.data.returned_cause);
          forceUpdate();
          // ? go to state 4
          setState(4);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [isOpenPropForEdit]);

  //   ? function for search orders
  const searchOrders = (e) => {
    setSearchValue(e.target.value);
    axios
      .get(`ReturnedOrder/relatedList?search=${e.target.value}`)
      .then((res) => {
        if (res.data.status) {
          // set result data
          setAllOrders((prev) => {
            prev = res.data.data;
            forceUpdate();
            return prev;
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // ? function for go to step two (for create returned order)
  function goToStepTwo(order) {
    axios
      .get(`ReturnedOrder/${order.id}`)
      .then((res) => {
        if (res.data.status) {
          setSelectedOrder(res.data.data);
          setState(2);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  // ? select order item for returned
  const selectOrderitem = (orderItem) => {
    if (orderItem.orderItem_id == returnedInfo.orderItem_id) {
      setReturnedInfo((prev) => {
        prev.count = 1;
        prev.index = null;
        prev.orderItem_id = null;
        prev.price = null;
        prev.discount = null;
        prev.returned_price = 0;
        prev.returned_adminDescription = "";
        forceUpdate();
        return prev;
      });

      setState(2);
    } else {
      setReturnedInfo((prev) => {
        prev.price = prev.count * orderItem.price;
        prev.discount = prev.count * orderItem.discount;
        prev.returned_price =
          prev.count * orderItem.price - prev.count * orderItem.discount;
        prev.orderItem_id = orderItem.orderItem_id;

        forceUpdate();
        return prev;
      });
    }
  };

  // ? function for decrease returned count
  const incrementReturendCount = (product, index) => {
    if (returnedInfo.count < product.product_count - product.returned_count) {
      setReturnedInfo((prev) => {
        prev.count += 1;
        prev.index = index;
        prev.price = prev.count * product.price;
        prev.discount = prev.count * product.discount;
        prev.returned_price =
          prev.count * product.price - prev.count * product.discount;

        prev.orderItem_id = product.orderItem_id;

        forceUpdate();
        return prev;
      });
    }
  };

  // ? function for decrease returned count
  const decrementReturnedCount = (product, index) => {
    if (returnedInfo.count > 1) {
      setReturnedInfo((prev) => {
        prev.count -= 1;
        prev.index = index;
        prev.price = prev.count * product.price;
        prev.discount = prev.count * product.discount;
        prev.returned_price =
          prev.count * product.price - prev.count * product.discount;
        prev.orderItem_id = product.orderItem_id;

        forceUpdate();
        return prev;
      });
    }
  };

  // ? go to three state
  const goToStateThree = (orderItem) => {
    setState(3);
  };

  // ? function for back to before state
  const backState = (state) => {
    setState(state);
    setReturnedInfo({
      count: 1,
      index: null,
      orderItem_id: null,
      price: null,
      discount: null,
      returned_price: 0,
      returned_adminDescription: "",
      returned_cause: "",
    });
  };

  // ? function for click items of dropdown
  const itemClickEvent = (id, title) => {
    setResultSearch(title);
    setReturnedInfo((prev) => {
      prev.returned_cause = title;
      forceUpdate();
      return prev;
    });
    setShowDropDown(false);
    setTimeout(() => {
      setShowDropDown(true);
    }, 100);
  };

  // ? function for click items of dropdown in edit
  const editItemClickEvent = (id, title) => {
    setEditResultSearch(title);
    setEditInfo((prev) => {
      prev.returned_cause = title;
      forceUpdate();
      return prev;
    });
    setEditShowDropDown(false);
    setTimeout(() => {
      setEditShowDropDown(true);
    }, 100);
  };

  // ? function for final submit for returned order
  function submitReturnedOrder() {
    let requestData = {
      orderItem_id: returnedInfo.orderItem_id,
      returned_count: returnedInfo.count,
      returned_adminDescription: returnedInfo.returned_adminDescription,
      returned_price: returnedInfo.returned_price,
      returned_cause: returnedInfo.returned_cause,
    };

    // ? axios for create returned order
    axios
      .post(`ReturnedOrder`, requestData)
      .then((res) => {
        if (res.data.status) {
          setReturnedInfo({
            count: 1,
            index: null,
            orderItem_id: null,
            price: null,
            discount: null,
            returned_price: 0,
            returned_adminDescription: "",
            returned_cause: "",
          });

          // ? show alert
          dispatch(ShowAlert(true, res.data.message));
          closeModal(false);
        } else {
          dispatch(ShowAlert(true, res.data.message));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  // ? function for increment returned cout in edit
  function incrementEdit() {
    if (editInfo.returned_count < editInfo.product_count) {
      setEditInfo((prev) => {
        prev.returned_count += 1;
        prev.price = prev.returned_count * editProductPrice;
        prev.discount = prev.returned_count * editProductDiscount;

        forceUpdate();
        return prev;
      });
    }
  }

  // ? function for decrement returned count in edit
  function decrementEdit() {
    if (editInfo.returned_count > 1) {
      setEditInfo((prev) => {
        prev.returned_count -= 1;
        prev.price = prev.returned_count * editProductPrice;
        prev.discount = prev.returned_count * editProductDiscount;

        forceUpdate();
        return prev;
      });
    }
  }

  // ? funcion for update returned order
  const updateReturnedOrder = () => {
    // ? axios for update returned order

    if (editInfo != null && returnedOrder_id != null) {
      let updateInfo = {
        returned_count: editInfo.returned_count,
        returned_adminDescription: editInfo.returned_admin_description,
        returned_price: editInfo.returned_price,
        returned_cause: editInfo.returned_cause,
      };

      axios
        .put(`ReturnedOrder/${returnedOrder_id}`, updateInfo)
        .then((res) => {
          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));
            closeEditModal();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <>
      {state != 4 ? (
        <Modal
          className="create-order-modal-all"
          onBackdropClick={() => closeModal(false)}
          open={isOpenProp}
        >
          <div className="modal-product-comment-all">
            <div className="modal-product-comment-top-bar">
              {selectedOrder == null ? (
                <p>ایجاد درخواست مرجوعی</p>
              ) : (
                <p>مرجوع سفارش {selectedOrder.order_barcode}</p>
              )}
              <img onClick={() => closeModal(false)} src={cross} />
            </div>

            {state == 1 && (
              <div className="modal-product-comment-search-box">
                <InputComponent
                  width="100%"
                  height="44px"
                  type="text"
                  value={searchValue}
                  inputType="inputWithIcon"
                  iconSide="right"
                  iconSrc={SearchLogo}
                  backgroundColor="#f7f7f7"
                  placeholder="جستجوی سفارش"
                  borderColor="#dcdcdc"
                  onchange={(e) => searchOrders(e)}
                />
              </div>
            )}

            {state == 1 && (
              <div className="modal-product-comment-choose-product-box">
                {allOrders.map((item, index) => {
                  return (
                    <div
                      onClick={() => goToStepTwo(item)}
                      onMouseMove={() => setOnHover(index)}
                      onMouseLeave={() => setOnHover(null)}
                      key={index}
                      style={index != 0 ? { borderTop: "1px solid #bbb" } : {}}
                      className="show-orders-content"
                    >
                      <div className="content-barcode">
                        <div className="content-barcode-right">
                          <p>{item.order_barcode}</p>
                          <p>{item.submit_date}</p>
                        </div>

                        <img
                          src={onHover == index ? arrowLeftActive : arrowLeft}
                        />
                      </div>
                      <div className="content-customerName">
                        <img src={userOrder} />
                        <p>{item.customer_name}</p>
                      </div>
                      <div className="content-products">
                        <div className="content-products-right">
                          {item.product_galleries.map((product, p) => {
                            return (
                              <span key={p}>
                                <img src={product.image} />
                              </span>
                            );
                          })}

                          <p>+{item.products_count}</p>
                        </div>

                        <p className="final-price">
                          {item.final_amount}
                          <span>تومان</span>
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            {(state == 2 || state == 3) && (
              <div className="show-order-and-items-content">
                <div className="items-content-top">
                  <div className="items-content-top-right">
                    <div className="order-info-titles">
                      <p>شناسه سفارش</p>
                      <p>تاریخ و زمان تحویل</p>
                      <p>نوع پرداخت</p>
                      <p>کد پیگیری</p>
                    </div>

                    {selectedOrder != null && (
                      <div className="order-info-value">
                        <p>{selectedOrder.order_barcode}</p>
                        <p>{selectedOrder.delivery_dateTime}</p>
                        <p>{selectedOrder.payment_type}</p>
                        <p>{selectedOrder.transaction_id}</p>
                      </div>
                    )}
                  </div>
                  <div className="items-content-top-right">
                    <div className="customer-info-titles">
                      <p>مشتری</p>
                      <p>شماره تماس</p>
                    </div>

                    {selectedOrder != null && (
                      <div className="customer-info-value">
                        <p>{selectedOrder.customer}</p>
                        <p>{selectedOrder.phone_number}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="items-content-bottom">
                  <p className="items-header">انتخاب محصول مرجوعی</p>

                  <SimpleBar
                    forceVisible="y"
                    className="create-order-modal-box-product-box"
                  >
                    <div className="order-items-products">
                      {selectedOrder != null &&
                        selectedOrder.order_items.map((orderItem, oi) => {
                          return (
                            <div
                              key={oi}
                              style={
                                oi + 1 != selectedOrder.order_items.length
                                  ? { borderBottom: "1px solid #dcdcdc" }
                                  : {}
                              }
                              className="order-item-single"
                            >
                              {returnedInfo.orderItem_id !=
                                orderItem.orderItem_id &&
                              returnedInfo.orderItem_id != null ? (
                                <span className="hide-item" />
                              ) : null}
                              <div className="order-item-single-right">
                                <label htmlFor={`orderItemChecked${oi}`}>
                                  <img
                                    src={
                                      orderItem.orderItem_id ==
                                      returnedInfo.orderItem_id
                                        ? check
                                        : uncheck
                                    }
                                  />
                                  <input
                                    style={{ display: "none" }}
                                    onChange={() => selectOrderitem(orderItem)}
                                    id={`orderItemChecked${oi}`}
                                    name="orderItemChecked"
                                    type="checkbox"
                                  />
                                </label>

                                <span className="orderItem-img">
                                  <img src={orderItem.image} />
                                </span>

                                <p className="orderItem-productName">
                                  {orderItem.product_name}
                                </p>

                                <p className="orderItem-prop">
                                  {orderItem.key == "رنگ" ? (
                                    <span
                                      style={{
                                        backgroundColor:
                                          orderItem.alternative_value,
                                      }}
                                      className="color-key"
                                    />
                                  ) : null}
                                  {orderItem.main_value}
                                </p>
                              </div>
                              <div className="order-item-single-left">
                                <p>
                                  {orderItem.price}
                                  <span>تومان</span>
                                </p>
                                <div className="create-order-modal-box-product-box-2-price-3">
                                  <button
                                    onClick={() =>
                                      incrementReturendCount(orderItem, oi)
                                    }
                                    className="create-order-modal-box-product-box-2-price-3-plus"
                                  >
                                    <img src={Plus} />
                                  </button>
                                  <div className="create-order-modal-box-product-box-2-price-3-number">
                                    {returnedInfo.index == oi ? (
                                      <p>{returnedInfo.count}</p>
                                    ) : (
                                      <p>1</p>
                                    )}
                                  </div>
                                  <button
                                    onClick={() =>
                                      decrementReturnedCount(orderItem, oi)
                                    }
                                    className="create-order-modal-box-product-box-2-price-3-minus"
                                  >
                                    <img src={Minus} />
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </SimpleBar>
                </div>
                {state == 2 && (
                  <div class="submit-and-continue">
                    {returnedInfo.orderItem_id == null ? (
                      <>
                        <button onClick={() => backState(1)} className="back">
                          بازگشت
                        </button>
                        <button className="submit">ثبت مرجوعی</button>
                      </>
                    ) : (
                      <>
                        {" "}
                        <button onClick={() => backState(1)} className="back">
                          بازگشت
                        </button>
                        <button
                          onClick={() => goToStateThree()}
                          className="continue"
                        >
                          ثبت و ادامه
                        </button>
                      </>
                    )}
                  </div>
                )}
                {state == 3 && (
                  <div className="returned-cause-content">
                    <div className="returned-cause-content-top">
                      <div className="cause-right">
                        <DropDownComponent
                          width="90%"
                          height="54px"
                          dropDownHeight="146px"
                          zindex="1"
                          data={causesReturned}
                          search={resultSearch}
                          show={showDropDown}
                          onchange={(e) => setResultSearch(e.target.value)}
                          value={resultSearch}
                          itemClick={(id, title) => itemClickEvent(id, title)}
                          placeholder="علت مرجوعی"
                        />

                        <InputComponent
                          className="IntroductionBox4-input"
                          value={returnedInfo.returned_adminDescription}
                          width="90%"
                          height="121px"
                          type="text"
                          margin="30px 0 0 0"
                          inputType="textarea"
                          placeholder="توضیحات فروشگاه"
                          borderColor="#dcdcdc"
                          onchange={(e) => {
                            setReturnedInfo((prev) => {
                              prev.returned_adminDescription = e.target.value;
                              forceUpdate();
                              return prev;
                            });
                          }}
                        />
                      </div>
                      <div className="cause-left">
                        <div className="cause-price-box">
                          <div className="cause-price-box-line">
                            <p>قیمت محصول</p>
                            <p>
                              {returnedInfo.price} <span>تومان</span>
                            </p>
                          </div>
                          <div
                            style={{ marginBottom: "20px" }}
                            className="cause-price-box-line"
                          >
                            <p>تخفیف محصول</p>
                            <p>
                              {returnedInfo.discount} <span>تومان</span>
                            </p>
                          </div>

                          {showReturnedPrice == false ? (
                            <div className="cause-price-box-line">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  width: "50%",
                                }}
                              >
                                <p className="returned-price-title">
                                  مبلغ مرجوعی
                                </p>
                                <img
                                  style={{
                                    width: "15px",
                                    height: "15px",
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setShowReturnedPrice(true)}
                                  src={EditActive}
                                />
                              </div>

                              <p className="returned-price-value">
                                {returnedInfo.returned_price} <span>تومان</span>
                              </p>
                            </div>
                          ) : (
                            <InputComponent
                              keyDown={(e) => {
                                if (e.key == "Enter") {
                                  setShowReturnedPrice(false);
                                }
                              }}
                              className="IntroductionBox4-input"
                              value={returnedInfo.returned_price}
                              width="calc(100% - 20px)"
                              height="44px"
                              type="number"
                              // margin="30px 0 0 0"
                              inputType="input"
                              placeholder="مبلغ مرجوعی"
                              borderColor="#dcdcdc"
                              onchange={(e) => {
                                setReturnedInfo((prev) => {
                                  prev.returned_price = e.target.value;
                                  forceUpdate();
                                  return prev;
                                });
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="returned-cause-content-bottom">
                      <button onClick={() => backState(2)} className="back">
                        بازگشت
                      </button>
                      <button
                        onClick={() => submitReturnedOrder()}
                        className="continue"
                      >
                        ثبت
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </Modal>
      ) : (
        <Modal
          className="create-order-modal-all"
          onBackdropClick={() => closeEditModal(false)}
          open={isOpenPropForEdit}
        >
          <div className="modal-product-comment-all">
            <div className="modal-product-comment-top-bar">
              <p>ویرایش سفارش مرجوعی</p>
              {/* TODO */}
              <img
                onClick={() => {
                  closeEditModal(false);
                  setState(1);
                }}
                src={cross}
              />
            </div>

            <div className="show-order-and-items-content">
              <div className="items-content-top">
                <div className="items-content-top-right">
                  <div className="order-info-titles">
                    <p>شناسه سفارش</p>
                    <p>تاریخ و زمان تحویل</p>
                    <p>نوع پرداخت</p>
                    <p>کد پیگیری</p>
                  </div>

                  {editInfo != null && (
                    <div className="order-info-value">
                      <p>{editInfo.order_barcode}</p>
                      <p>{editInfo.delivery_date}</p>
                      <p>{editInfo.payment_type}</p>
                      <p>{editInfo.transaction_id}</p>
                    </div>
                  )}
                </div>
                <div className="items-content-top-right">
                  <div className="customer-info-titles">
                    <p>مشتری</p>
                    <p>شماره تماس</p>
                  </div>

                  {editInfo != null && (
                    <div className="customer-info-value">
                      <p>{editInfo.full_name}</p>
                      <p>{editInfo.phone_number}</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="items-content-bottom">
                <p className="items-header">محصول مرجوعی انتخاب شده</p>

                <div className="order-items-products">
                  <div
                    style={{
                      backgroundColor: "#f2f2f2",
                      borderRadius: "10px",
                      padding: "20px !important",
                    }}
                    className="order-item-single"
                  >
                    <div className="order-item-single-right">
                      <span
                        style={{ marginRight: 0 }}
                        className="orderItem-img"
                      >
                        <img src={editInfo.image} />
                      </span>

                      <p className="orderItem-productName">
                        {editInfo.product_name}
                      </p>

                      <p className="orderItem-prop">
                        {editInfo.key == "رنگ" ? (
                          <span
                            style={{
                              backgroundColor: editInfo.alternative_value,
                            }}
                            className="color-key"
                          />
                        ) : null}
                        {editInfo.main_value}
                      </p>
                    </div>
                    <div className="order-item-single-left">
                      <p>
                        {editInfo.price}
                        <span>تومان</span>
                      </p>
                      <div className="create-order-modal-box-product-box-2-price-3">
                        <button
                          onClick={() => incrementEdit()}
                          className="create-order-modal-box-product-box-2-price-3-plus"
                        >
                          <img src={Plus} />
                        </button>
                        <div className="create-order-modal-box-product-box-2-price-3-number">
                          {editInfo.returned_count}
                        </div>
                        <button
                          onClick={() => decrementEdit()}
                          className="create-order-modal-box-product-box-2-price-3-minus"
                        >
                          <img src={Minus} />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="returned-cause-content">
                <div className="returned-cause-content-top">
                  <div className="cause-right">
                    <DropDownComponent
                      width="90%"
                      height="54px"
                      dropDownHeight="146px"
                      zindex="1"
                      data={causesReturned}
                      search={editResultSearch}
                      show={editShowDropDown}
                      onchange={(e) =>
                        setEditResultSearch((prev) => {
                          prev = e.target.value;
                          return prev;
                        })
                      }
                      value={editResultSearch}
                      itemClick={(id, title) => editItemClickEvent(id, title)}
                      placeholder="علت مرجوعی"
                    />

                    <InputComponent
                      className="IntroductionBox4-input"
                      value={editInfo.returned_admin_description}
                      width="90%"
                      height="121px"
                      type="text"
                      margin="30px 0 0 0"
                      inputType="textarea"
                      placeholder="توضیحات فروشگاه"
                      borderColor="#dcdcdc"
                      onchange={(e) => {
                        setEditInfo((prev) => {
                          prev.returned_admin_description = e.target.value;
                          forceUpdate();
                          return prev;
                        });
                      }}
                    />
                  </div>
                  <div className="cause-left">
                    <div className="cause-price-box">
                      <div className="cause-price-box-line">
                        <p>قیمت محصول</p>
                        <p>
                          {editInfo.price} <span>تومان</span>
                        </p>
                      </div>
                      <div
                        style={{ marginBottom: "20px" }}
                        className="cause-price-box-line"
                      >
                        <p>تخفیف محصول</p>
                        <p>
                          {editInfo.discount} <span>تومان</span>
                        </p>
                      </div>

                      {editShowReturnedPrice == false ? (
                        <div className="cause-price-box-line">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              width: "50%",
                            }}
                          >
                            <p className="returned-price-title">مبلغ مرجوعی</p>
                            <img
                              style={{
                                width: "15px",
                                height: "15px",
                                marginRight: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => setEditShowReturnedPrice(true)}
                              src={EditActive}
                            />
                          </div>

                          <p className="returned-price-value">
                            {editInfo.returned_price} <span>تومان</span>
                          </p>
                        </div>
                      ) : (
                        <InputComponent
                          keyDown={(e) => {
                            if (e.key == "Enter") {
                              setEditShowReturnedPrice(false);
                            }
                          }}
                          className="IntroductionBox4-input"
                          value={editInfo.returned_price}
                          width="calc(100% - 20px)"
                          height="44px"
                          type="number"
                          // margin="30px 0 0 0"
                          inputType="input"
                          placeholder="مبلغ مرجوعی"
                          borderColor="#dcdcdc"
                          onchange={(e) => {
                            setEditInfo((prev) => {
                              prev.returned_price = e.target.value;
                              forceUpdate();
                              return prev;
                            });
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="returned-cause-content-bottom">
                  <button
                    onClick={() => {
                      closeEditModal(false);
                      setState(1);
                    }}
                    className="back"
                  >
                    لغو
                  </button>
                  <button
                    onClick={() => updateReturnedOrder()}
                    className="continue"
                  >
                    ثبت
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default CreateReturnedOrder;
