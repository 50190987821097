import React, { useEffect, useRef, useState } from "react";
import "../../assets/styles/ManageCustomerFeedbackStyle/ManageCustomerFeedback.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import axios from "../../js/Axios/Axios";
import { ShowAlert } from "../../action/index";
import { SendBlogInfoForEdit } from "../../action/index";
import Modal from "@mui/material/Modal";
import useForceUpdate from "use-force-update";
import { useSelector, useDispatch } from "react-redux";
import TopBar from "../../components/TopBar/TopBar";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import Pagination from "../../components/Pagination/Pagination";
import AllProductAdd from "../../assets/icons/AllProductAdd.svg";
import SearchLogo from "../../assets/icons/SearchLogo.svg";
import AllProductCalender from "../../assets/icons/AllProductCalender.svg";
import Delete from "../../assets/icons/Delete.svg";
import DeleteRed from "../../assets/icons/DeleteRed.svg";
import Eye from "../../assets/icons/AllProductEye.svg";
import headerCheck from "../../assets/icons/headerCheck.svg";
import check from "../../assets/icons/check.svg";
import uncheck from "../../assets/icons/uncheck.svg";
import openTable from "../../assets/icons/openTable.svg";
import closeTable from "../../assets/icons/closeTable.svg";
import cross from "../../assets/icons/cross.svg";
import FilterLogo from "../../assets/icons/Filter.svg";
import WhiteCheck from "../../assets/icons/WhiteCheck.svg";
import WhiteCross from "../../assets/icons/WhiteCross.svg";
import sadFace from "../../assets/icons/sadFace.svg";
import smileFace from "../../assets/icons/smileFace.svg";
import pokerFace from "../../assets/icons/pokerFace.svg";
import { VictoryPie, VictoryTheme } from "victory";
import CircularSlider from "@fseehawer/react-circular-slider";
import Filter from "../../components/Filter/Filter";
import AllProductTrash from "../../assets/icons/GarbageDelte.svg";
import AllProductTrashRed from "../../assets/icons/GarbageDelteRed.svg";
import SimpleBar from "simplebar-react";
import ScrollDrag from "react-indiana-drag-scroll";

export default function ManageBlog() {
  const location = useLocation();
  const paginatedData = useSelector((state) => state.PaginatedData);
  const user_id = useSelector((state) => state.UserFilter);
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const [firstAxios, setFirstAxios] = useState("/ProductComment/search");
  const [lastPage, setLastPage] = useState(null);
  const [allComment, setAllComment] = useState([]);
  const [from, setFrom] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [tableOpen, setTableOpen] = useState(null);
  const [detailComment, setDetailComment] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [modal, setModal] = useState(false);
  const [allProduct, setAllProduct] = useState([]);
  const [error, setError] = useState({
    comment: false,
  });
  const [checkIds, setCheckIds] = useState({
    comment_status: null,
    productComment_ids: [],
  }); // checkbox ids
  const [commentInfo, setCommentInfo] = useState({
    comment_text: null,
    buy_suggestion: 1,
    fakeUserName: null,
    product_id: null,
    user_id: null,
  });
  const [productInfo, setProductInfo] = useState({
    imgLink: null,
    productName: null,
    productPrice: null,
    index: null,
  });
  const [rateInfo, setRateInfo] = useState([]);

  const scrollTitleRef = useRef();
  const scrollContentRef = useRef();

  useEffect(() => {
    console.log(paginatedData);
    if (paginatedData.length != 0) {
      setAllComment(paginatedData.data);
      setCurrentPage(paginatedData.meta.current_page);
      setFrom(paginatedData.meta.from);
      setLastPage(paginatedData.meta.last_page);
      setTableOpen(null);
    }
  }, [paginatedData]);

  useEffect(() => {
    if (user_id.nameid === "product") {
      setShowFilter(true);
    }
  }, [user_id]);

  useEffect(() => {
    if (user_id.id === null) {
      console.log("zzz");
      axios
        .get("/ProductComment/search")
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            setAllComment(res.data.data);
            setFrom(res.data.meta.from);
            setCurrentPage(res.data.meta.current_page);
            setLastPage(res.data.meta.last_page);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  // useeffect for SimpleBar and ScrollDrag
  useEffect(() => {
    // set scroll position title and content for together

    const handleScrollTitle = (e) => {
      scrollContentRef.current.scrollLeft = scrollTitleRef.current.scrollLeft;
    };

    scrollTitleRef.current.addEventListener("scroll", handleScrollTitle);

    const handleScrollContent = (e) => {
      scrollTitleRef.current.scrollLeft = scrollContentRef.current.scrollLeft;
    };
    scrollContentRef.current.addEventListener("scroll", handleScrollContent);
  }, []);

  const Data = (data) => {
    console.log(data);
    setAllComment(data.data);
    setFrom(data.meta.from);
    setCurrentPage(data.meta.current_page);
  };
  const Url = (Url) => {
    setFirstAxios(Url)
  }
  const searchComment = (e) => {
    axios
      .get(`/ProductComment/search?search=${e.target.value}`)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setAllComment(res.data.data);
          setFrom(res.data.meta.from);
          setCurrentPage(res.data.meta.current_page);
          setLastPage(res.data.meta.last_page);
          setFirstAxios(`/ProductComment/search?search=${e.target.value}`)
          forceUpdate()
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const openTableItem = (index, id) => {
    if (tableOpen === index) {
      setTableOpen(null);
      setDetailComment(null);
    } else {
      setTableOpen(index);
      setDetailComment(null);
      axios
        .get(`/ProductComment/relatedList?comment_id=${id}`)
        .then((res) => {
          console.log(res);
          setDetailComment(res.data.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  const updateCommentClick = (name) => {
    if (checkIds.productComment_ids.length != 0) {
      checkIds.comment_status = name;
      axios
        .put("/ProductComment/changeStatus", checkIds)
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            checkIds.productComment_ids.map((item, i) => {
              checkIds.productComment_ids.splice(i);
            });
            checkIds.comment_status = null;
          }
          axios
            .get(`/ProductComment/search?page=${currentPage}`)
            .then((res) => {
              if (res.data.status) {
                console.log(res);
                setAllComment(res.data.data);
                setFrom(res.data.meta.from);
                setCurrentPage(res.data.meta.current_page);
                setLastPage(res.data.meta.last_page);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      dispatch(ShowAlert(true, "لطفا نظرات را انتخاب کنید ."));
    }
  };
  const checkBoxOnChange = (id) => {
    if (checkIds.productComment_ids.includes(id)) {
      var index = checkIds.productComment_ids.indexOf(id);
      checkIds.productComment_ids.splice(index, 1);
    } else {
      checkIds.productComment_ids.push(id);
    }
    forceUpdate();
  };
  const allCheckBoxOnChange = () => {
    if (allComment.length === checkIds.productComment_ids.length) {
      allComment.map((item, index) => {
        checkIds.productComment_ids.splice(index);
      });
    } else {
      allComment.map((item) => {
        if (!checkIds.productComment_ids.includes(item.id)) {
          checkIds.productComment_ids.push(item.id);
        }
      });
    }
    forceUpdate();
  };
  const openCommentClick = () => {
    axios
      .get("/product/relatedList")
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setAllProduct(res.data.data);
          setModal(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const searchProduct = (e) => {
    axios
      .get(`/product/relatedList?search=${e.target.value}`)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setAllProduct(res.data.data);
          setModal(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const addIdProductClick = (item, index) => {
    setCommentInfo((prev) => {
      return {
        comment_text: prev.comment_text,
        buy_suggestion: prev.buy_suggestion,
        fakeUserName: prev.fakeUserName,
        product_id: item.product_id,
        user_id: prev.user_id,
      };
    });
    setProductInfo(() => {
      return {
        imgLink: item.product_gallery,
        productPrice: item.product_prices,
        productName: item.product_name,
        index: index,
      };
    });
    if (
      item.category_rating_types !== null &&
      item.category_rating_types.length !== 0
    ) {
      item.category_rating_types.map((item) => {
        rateInfo.push({
          rate: 0,
          productComment_id: null,
          category_rating_type_id: item.id,
          title: item.title,
        });
      });
      forceUpdate();
    }
  };
  const closeModal = () => {
    setModal(false);
    setCommentInfo((prev) => {
      return {
        comment_text: null,
        buy_suggestion: null,
        fakeUserName: null,
        product_id: null,
        user_id: null,
      };
    });
    setProductInfo(() => {
      return {
        imgLink: null,
        productPrice: null,
        productName: null,
        index: null,
      };
    });
    rateInfo.map((item, index) => {
      rateInfo.splice(index);
    });
    forceUpdate();
  };
  const silderOnChange = (value, index) => {
    rateInfo[index].rate = value;
    forceUpdate();
  };
  const userNameOnChange = (e) => {
    setCommentInfo((prev) => {
      return {
        comment_text: prev.comment_text,
        buy_suggestion: prev.buy_suggestion,
        fakeUserName: e.target.value,
        product_id: prev.product_id,
        user_id: prev.user_id,
      };
    });
  };
  const commentOnChange = (e) => {
    setCommentInfo((prev) => {
      return {
        comment_text: e.target.value,
        buy_suggestion: prev.buy_suggestion,
        fakeUserName: prev.fakeUserName,
        product_id: prev.product_id,
        user_id: prev.user_id,
      };
    });
    setError((prev) => {
      prev.comment = false;
      forceUpdate();
      return prev;
    });
  };
  const radioButtonOnChange = (number) => {
    setCommentInfo((prev) => {
      return {
        comment_text: prev.comment_text,
        buy_suggestion: number,
        fakeUserName: prev.fakeUserName,
        product_id: prev.product_id,
        user_id: prev.user_id,
      };
    });
  };
  const shareComment = () => {
    if (commentInfo.comment_text !== null && commentInfo.comment_text !== "") {
      axios
        .post("/ProductComment", commentInfo)
        .then((res) => {
          // if(res.data.status === 0){
          //   dispatch(ShowAlert(true , "ثبت دیدگاه موفقیت آمیز نبود "))
          //   console.log(res);

          // }
          if (res.data.status) {
            setModal(false);
            dispatch(ShowAlert(true, "دیدگاه با موفقیت ثبت شد"));
            setCommentInfo((prev) => {
              return {
                comment_text: null,
                buy_suggestion: null,
                fakeUserName: null,
                product_id: null,
                user_id: null,
              };
            });
            setProductInfo(() => {
              return {
                imgLink: null,
                productPrice: null,
                productName: null,
                index: null,
              };
            });

            axios
              .get(
                `/ProductComment/search?page=${
                  lastPage == currentPage && allComment.length === 5
                    ? currentPage + 1
                    : currentPage
                }`
              )
              .then((res) => {
                if (res.data.status) {
                  setFirstAxios(
                    `/ProductComment/search?page=${
                      lastPage == currentPage && allComment.length === 5
                        ? currentPage + 1
                        : currentPage
                    }`
                  );
                  console.log(res);
                  setAllComment(res.data.data);
                  setFrom(res.data.meta.from);
                  setCurrentPage(res.data.meta.current_page);
                  setLastPage(res.data.meta.last_page);
                }
                forceUpdate();
              })
              .catch((e) => {
                console.log(e);
              });
            if (rateInfo.length != 0) {
              rateInfo.map((item) => {
                item.productComment_id = res.data.data.comment_id;
              });
              axios
                .post("/ProductRate", rateInfo)
                .then((res) => {
                  console.log("zzzz", res);
                  if (res.data.status) {
                    // setModal(false);
                    setCommentInfo((prev) => {
                      return {
                        comment_text: null,
                        buy_suggestion: null,
                        fakeUserName: null,
                        product_id: null,
                        user_id: null,
                      };
                    });
                    setProductInfo(() => {
                      return {
                        imgLink: null,
                        productPrice: null,
                        productName: null,
                        index: null,
                      };
                    });
                    rateInfo.map((item, index) => {
                      rateInfo.splice(index);
                    });
                    axios
                      .get(
                        `/ProductComment/search?page=${
                          lastPage == currentPage && allComment.length === 5
                            ? currentPage + 1
                            : currentPage
                        }`
                      )
                      .then((res) => {
                        if (res.data.status) {
                          setFirstAxios(
                            `/ProductComment/search?page=${
                              lastPage == currentPage && allComment.length === 5
                                ? currentPage + 1
                                : currentPage
                            }`
                          );
                          console.log(res);
                          setAllComment(res.data.data);
                          setFrom(res.data.meta.from);
                          setCurrentPage(res.data.meta.current_page);
                          setLastPage(res.data.meta.last_page);
                        }
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  }
                })
                .catch((e) => {
                  console.log(e);
                });
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setError((prev) => {
        prev.comment = true;
        forceUpdate();
        return prev;
      });
      dispatch(ShowAlert( true ,"برای ساخت دیدگاه پر کردن فیلد دیدگاه الزامی است ."));
    }
  };
  const openFilter = () => {
    setShowFilter(!showFilter);
  };
  const delteComment = (id) => {
    let array = [];
    array.push(id);
    let array2 = JSON.stringify(array);
    axios
      .delete(`/ProductComment/throwingGarbage?productComment_ids=${array2}`)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          dispatch(ShowAlert(true, res.data.message));
          axios
            .get(
              `/ProductComment/search?page=${
                currentPage === lastPage && allComment.length === 1
                  ? currentPage - 1
                  : currentPage
              }`
            )
            .then((res) => {
              setFirstAxios(
                `/ProductComment/search?page=${
                  lastPage == currentPage && allComment.length === 1
                    ? currentPage - 1
                    : currentPage
                }`
              );
              if (res.data.status) {
                console.log(res);
                setAllComment(res.data.data);
                setFrom(res.data.meta.from);
                setCurrentPage(res.data.meta.current_page);
                setLastPage(res.data.meta.last_page);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const garbageClick = () => {
    if (checkIds.productComment_ids.length != 0) {
      let array = JSON.stringify(checkIds.productComment_ids);
      axios
        .delete(`/ProductComment/throwingGarbage?productComment_ids=${array}`)
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            dispatch(ShowAlert(true, res.data.message));
            axios
              .get(
                `/ProductComment/search?page=${
                  (currentPage === lastPage &&
                    allComment.length === checkIds.productComment_ids.length) ||
                  allComment.length === 1
                    ? currentPage - 1
                    : currentPage
                }`
              )
              .then((res) => {
                if (res.data.status) {
                  setFirstAxios(
                    `/ProductComment/search?page=${
                      currentPage === lastPage &&
                      (allComment.length ===
                        checkIds.productComment_ids.length ||
                        allComment.length === 1)
                        ? currentPage - 1
                        : currentPage
                    }`
                  );
                  console.log(res);
                  setAllComment(res.data.data);
                  setFrom(res.data.meta.from);
                  setCurrentPage(res.data.meta.current_page);
                  setLastPage(paginatedData.meta.last_page);
                  checkIds.productComment_ids.map((item, index) => {
                    checkIds.productComment_ids.splice(index);
                  });
                  forceUpdate();
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      dispatch(ShowAlert(true, "لطفا نظرات را انتخاب کنید ."));
    }
  };
  return (
    <div className="product-comment-blog-all">
      <Modal
        onBackdropClick={() => closeModal()}
        className="modal-product-comment"
        open={modal}
      >
        <div className="modal-product-comment-all">
          <div className="modal-product-comment-top-bar">
            <p>ایجاد دیدگاه</p>
            <img onClick={() => closeModal()} src={cross} />
          </div>
          <div className="modal-product-comment-search-box">
            <InputComponent
              width="100%"
              height="44px"
              type="text"
              inputType="inputWithIcon"
              iconSide="right"
              iconSrc={SearchLogo}
              backgroundColor="#f7f7f7"
              placeholder="جستجو .."
              borderColor="#dcdcdc"
              onchange={(e) => searchProduct(e)}
            />
          </div>
          {commentInfo.product_id === null ? (
            <div className="modal-product-comment-choose-product-box">
              {allProduct.map((item, index) => {
                return (
                  <div
                    onClick={() => addIdProductClick(item, index)}
                    key={index}
                    className="modal-product-comment-product-show-box"
                  >
                    <img
                      src={item.product_gallery}
                      className="modal-product-comment-product-show-img"
                    />
                    <div className="modal-product-comment-product-show-title-price">
                      <p className="modal-product-comment-product-show-title">
                        {item.product_name}
                      </p>
                      <div className="modal-product-comment-product-show-price">
                        <p>{item.product_prices}</p>
                        <p>(تومان)</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              <div className="modal-product-comment-product-show">
                <img
                  src={productInfo.imgLink}
                  className="modal-product-comment-product-show-img"
                />
                <div className="modal-product-comment-product-show-title-price">
                  <p className="modal-product-comment-product-show-title">
                    {productInfo.productName}
                  </p>
                  <div className="modal-product-comment-product-show-price">
                    <p>{productInfo.productPrice}</p>
                    <p>(تومان)</p>{" "}
                  </div>
                </div>
              </div>

              <div className="modal-product-comment-username-box">
                <InputComponent
                  width="100%"
                  height="44px"
                  type="text"
                  inputType="input"
                  placeholder="نام کاربری"
                  borderColor="#dcdcdc"
                  onchange={(e) => userNameOnChange(e)}
                />
              </div>
              <div className="modal-product-comment-comment-box">
                <div className="modal-product-comment-comment-box-2">
                  <textarea
                    onChange={(e) => commentOnChange(e)}
                    placeholder="لطفا دیدگاه خود را در مورد محصول بنویسید .."
                    className={error.comment === false ?  "modal-product-comment-comment-textarea" : "modal-product-comment-comment-textarea-error"}
                  />
                  <div className="modal-product-comment-comment-tittle-box">
                    <p>آیا خرید این محصول را به دیگران پیشنهاد میکنید؟</p>
                  </div>
                  <div className="modal-product-comment-comment-radio-box">
                    <label htmlFor="yesRadio">
                      <div className="modal-product-comment-comment-radio-box-2">
                        <div className="modal-product-comment-comment-radio-box-3">
                          <input
                            style={{ display: "none" }}
                            onChange={() => radioButtonOnChange(1)}
                            name="radio-button"
                            id="yesRadio"
                            type="radio"
                          />
                          <div
                            className={
                              commentInfo.buy_suggestion === 1
                                ? "modal-product-comment-comment-radio-box-5"
                                : "modal-product-comment-comment-radio-box-4"
                            }
                          ></div>
                        </div>
                        <p>بله</p>
                      </div>
                    </label>
                    <label htmlFor="noRadio">
                      <div className="modal-product-comment-comment-radio-box-2">
                        <div className="modal-product-comment-comment-radio-box-3">
                          <input
                            style={{ display: "none" }}
                            onChange={() => radioButtonOnChange(0)}
                            id="noRadio"
                            type="radio"
                            name="radio-button"
                          />
                          <div
                            className={
                              commentInfo.buy_suggestion === 0
                                ? "modal-product-comment-comment-radio-box-5"
                                : "modal-product-comment-comment-radio-box-4"
                            }
                          ></div>
                        </div>
                        <p>خیر</p>
                      </div>
                    </label>
                    <label htmlFor="notSureRadio">
                      <div className="modal-product-comment-comment-radio-box-poker-face">
                        <div className="modal-product-comment-comment-radio-box-3">
                          <input
                            style={{ display: "none" }}
                            onChange={() => radioButtonOnChange(2)}
                            id="notSureRadio"
                            name="radio-button"
                            type="radio"
                          />
                          <div
                            className={
                              commentInfo.buy_suggestion === 2
                                ? "modal-product-comment-comment-radio-box-5"
                                : "modal-product-comment-comment-radio-box-4"
                            }
                          ></div>
                        </div>
                        <p>مطمئن نیستم</p>
                      </div>
                    </label>
                  </div>
                </div>
              </div>

              <div className="modal-product-comment-rate-box">
                {rateInfo.length !== 0 && (
                  <>
                    {rateInfo.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="modal-product-comment-rate-1"
                        >
                          <div className="modal-product-comment-rate-2">
                            <CircularSlider
                              width={110}
                              hideLabelValue={true}
                              min={0}
                              max={100}
                              knobColor={"#ffffff"}
                              progressSize={15}
                              trackSize={15}
                              trackColor={"#eeeeee"}
                              progressColorFrom="#00589c"
                              progressColorTo="#00589c"
                              onChange={(value) => silderOnChange(value, index)}
                            />
                            <p>{item.rate}%</p>
                          </div>
                          <p>{item.title}</p>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </>
          )}
          <div className="modal-product-comment-buutons-box">
            <button
              onClick={() => closeModal()}
              className="modal-product-comment-buuton-cancel"
            >
              <p>لغو</p>
            </button>
            <button
              onClick={() => shareComment()}
              className="modal-product-comment-buuton-share"
            >
              <p>انتشار</p>
            </button>
          </div>
        </div>
      </Modal>
      <div className="product-comment-blog-top-bar">
        <TopBar />
      </div>
      <div className="product-comment-blog-search-dropdown-buttons-box">
        <div className="product-comment-blog-buttons-box">
          <button
            onClick={() => openCommentClick()}
            className="product-comment-blog-create-blog-button"
          >
            <img src={AllProductAdd} />
            <p>ایجاد دیدگاه</p>
          </button>

          <button
            onClick={() => openFilter()}
            className="product-comment-blog-show-filter-button"
          >
            <img src={FilterLogo} />
            <p>{showFilter === false ? "نمایش فیلتر" : "بستن فیلتر"}</p>
          </button>
        </div>

        <div className="product-comment-blog-search-input-box">
          <InputComponent
            width="337px"
            height="44px"
            type="text"
            inputType="inputWithIcon"
            iconSide="right"
            iconSrc={SearchLogo}
            backgroundColor="#f7f7f7"
            placeholder="جستجو .."
            borderColor="#dcdcdc"
            onchange={(e) => searchComment(e)}
          />
        </div>
      </div>

      <div className="product-comment-blog-table-box">
        <div
          className={
            showFilter === false
              ? "product-comment-blog-table-box-2"
              : "product-comment-blog-table-box-3"
          }
        >
          <SimpleBar
            forceVisible="x"
            // scrollableNodeProps={{ ref: this.scrollTitleRef }}
            style={{
              width: "100%",
              borderRadius: "10px 10px 0 0",
              borderLeft: "1px solid rgb(220, 220, 220)",
              borderRight: "1px solid rgb(220, 220, 220)",
              borderBottom: "1px solid rgb(220, 220, 220)",
              cursor: "grab",
            }}
          >
            <ScrollDrag innerRef={scrollTitleRef}>
              <div className={"all-products-header dragscroll"}>
                <div className="all-products-tab-bar">
                  <div className="all-products-tab-bar-right">
                    <p
                      onClick={() => navigate("/ManageCustomerFeedback")}
                      style={
                        location.pathname == "/ManageCustomerFeedback"
                          ? {
                              color: "#00457b",
                              fontWeight: "bold",
                            }
                          : {}
                      }
                    >
                      دیدگاه ها
                    </p>

                    <p
                      onClick={() => navigate("/GarbageCommentProduct")}
                      style={
                        location.pathname == "/GarbageCommentProduct"
                          ? {
                              color: "#00457b",
                              fontWeight: "bold",
                            }
                          : {}
                      }
                    >
                      زباله دان
                    </p>
                  </div>

                  <div className="all-products-tab-bar-left">
                    <button
                      onClick={() => garbageClick()}
                      className="product-comment-blog-garbage-button"
                    >
                      <img src={AllProductTrashRed} />

                      <p>زباله دان</p>
                    </button>

                    <button
                      onClick={() => updateCommentClick("تایید شده")}
                      className="product-comment-blog-accept-comment-button"
                    >
                      <img src={WhiteCheck} />
                      <p>تایید </p>
                    </button>

                    <button
                      style={
                        checkIds.productComment_ids.length > 0
                          ? { backgroundColor: "#f23b3b" }
                          : {}
                      }
                      onClick={() => updateCommentClick("رد شده")}
                      className="product-comment-blog-decline-comment-button"
                    >
                      <img src={WhiteCross} />
                      <p
                        style={
                          checkIds.productComment_ids.length > 0
                            ? { color: "#fff" }
                            : {}
                        }
                      >
                        رد کردن
                      </p>
                    </button>
                  </div>
                </div>

                <div className="product-comment-blog-table-top-bar">
                  <p className="product-comment-blog-top-bar-hashtag">#</p>
                  <div className="product-comment-blog-top-bar-checkbox">
                    <label>
                      <img
                        src={
                          checkIds.productComment_ids.length ===
                            allComment.length && allComment.length != 0
                            ? headerCheck
                            : uncheck
                        }
                      />
                      <input
                        style={{ display: "none" }}
                        onChange={() => allCheckBoxOnChange()}
                        id="allCheckBox"
                        name="allCheckBox"
                        type="checkbox"
                      />
                    </label>
                  </div>
                  <p className="product-comment-blog-top-bar-limit-comment">
                    متن دیدگاه
                  </p>
                  <p className="product-comment-blog-top-bar-blog-title">
                    محصول
                  </p>
                  <p className="product-comment-blog-top-bar-username">
                    نام کاربری
                  </p>
                  <p className="product-comment-blog-top-bar-date">تاریخ ثبت</p>
                  <p className="product-comment-blog-top-bar-situation">
                    وضعیت
                  </p>
                  <p className="product-comment-blog-top-bar-opation">
                    پاسخ/عملیات
                  </p>
                </div>
              </div>
            </ScrollDrag>
          </SimpleBar>

          <SimpleBar
            forceVisible="x"
            // scrollableNodeProps={{ ref: this.scrollContentRef }}
            style={{
              width: "calc(100% - 3px)",
              borderLeft: "solid 1px #dcdcdc",
              borderRight: "solid 1px #dcdcdc",
              borderBottom: "solid 1px #dcdcdc",
              borderRadius: "0 0 10px 10px",
            }}
          >
            <ScrollDrag
              className="scroll-container"
              innerRef={scrollContentRef}
            >
              {allComment.map((item, index) => {
                return (
                  <div
                    style={
                      allComment.length == index + 1
                        ? {
                            borderRadius: "0 0 10px 10px",
                            borderBottom: "none",
                          }
                        : {}
                    }
                    key={index}
                    className={
                      tableOpen === index
                        ? "product-comment-blog-item-box-open"
                        : "product-comment-blog-item-box"
                    }
                  >
                    <div
                      onClick={() => openTableItem(index, item.id)}
                      className="product-comment-blog-item-detail-box"
                    >
                      <div className="product-comment-blog-item-number">
                        <p>{from + index}</p>
                      </div>
                      <div className="product-comment-blog-item-checkbox">
                        <label htmlFor={`id${index}`}>
                          <img
                            src={
                              checkIds.productComment_ids.includes(item.id)
                                ? check
                                : uncheck
                            }
                          />
                          <input
                            style={{ display: "none" }}
                            id={`id${index}`}
                            name={`id${index}`}
                            onChange={() => checkBoxOnChange(item.id)}
                            type="checkbox"
                          />
                        </label>
                      </div>
                      <div className="product-comment-blog-item-limit-comment">
                        <p>{item.comment_text}</p>
                      </div>
                      <div className="product-comment-blog-item-blog-title">
                        <p>{item.product}</p>
                      </div>
                      <div className="product-comment-blog-item-username">
                        <p>{item.user_name}</p>
                      </div>
                      <div className="product-comment-blog-item-date">
                        <p>{item.created_at}</p>
                      </div>
                      <div className="product-comment-blog-item-situation">
                        {item.comment_status === "تایید شده" && (
                          <div className="product-comment-blog-item-situation-1">
                            <p>{item.comment_status}</p>
                          </div>
                        )}

                        {item.comment_status === "رد شده" && (
                          <div className="product-comment-blog-item-situation-2">
                            <p>{item.comment_status}</p>
                          </div>
                        )}

                        {item.comment_status === "در انتظار بررسی" && (
                          <div className="product-comment-blog-item-situation-3">
                            <p>{item.comment_status}</p>
                          </div>
                        )}
                      </div>
                      <div className="product-comment-blog-item-operation">
                        <img
                          onClick={() => openTableItem(index, item.id)}
                          src={tableOpen === index ? closeTable : openTable}
                        />
                        <img
                          onClick={() => delteComment(item.id)}
                          src={DeleteRed}
                          // onMouseOver={(e) => (e.currentTarget.src = DeleteRed)}
                          // onMouseOut={(e) => (e.currentTarget.src = Delete)}
                        />
                      </div>
                    </div>
                    {tableOpen === index && (
                      <div className="product-comment-blog-item-answer-box">
                        <div className="product-comment-blog-item-answer-title-box">
                          <div>
                            <p>متن کامل دیدگاه</p>
                          </div>
                          <div>
                            <p>امتیاز دهی کاربر</p>
                          </div>
                        </div>
                        <div className="product-comment-blog-item-answer-line"></div>
                        <div className="product-comment-blog-item-answer-input-box">
                          <div className="product-comment-blog-item-answer-full-comment-box">
                            <p>
                              {detailComment != null &&
                                detailComment.comment_text}
                            </p>
                          </div>
                          <div className="Product-comment-item-detail-box">
                            {detailComment != null &&
                              detailComment.buy_suggestion === 1 && (
                                <div className="product-comment-item-detail-status-box">
                                  <img src={smileFace} />
                                  <p>خرید این محصول را پیشنهاد میکنم</p>
                                </div>
                              )}

                            {detailComment != null &&
                              detailComment.buy_suggestion === 0 && (
                                <div className="product-comment-item-detail-status-box-2">
                                  <img src={sadFace} />
                                  <p>خرید این محصول را پیشنهاد نمیکنم</p>
                                </div>
                              )}

                            {detailComment != null &&
                              detailComment.buy_suggestion === 2 && (
                                <div className="product-comment-item-detail-status-box-3">
                                  <img src={pokerFace} />
                                  <p>در مورد خرید این محصول مطمئن نیستم</p>
                                </div>
                              )}
                            {detailComment != null && (
                              <div className="product-comment-item-detail-doughnat-box">
                                {detailComment.product_rates.map(
                                  (item, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="product-comment-item-detail-doughnat-box-2"
                                      >
                                        <div className="product-comment-item-detail-doughnat-box-3">
                                          <VictoryPie
                                            padAngle={0}
                                            // used to hide labels
                                            labelComponent={<span />}
                                            innerRadius={90}
                                            width={200}
                                            height={200}
                                            data={[
                                              { key: "", y: item.rate },
                                              { key: "", y: 100 - item.rate },
                                            ]}
                                            colorScale={["#00589c", "#eeeeee"]}
                                          />
                                          <p className="product-comment-item-detail-doughnat-box-2-tittle">
                                            {item.rate}%
                                          </p>
                                        </div>
                                        <p className="product-comment-item-detail-doughnat-box-2-tittle-2">
                                          {item.category_rating_type}
                                        </p>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </ScrollDrag>
          </SimpleBar>

          <div className={"product-comment-blog-paginate-box"}>
            <Pagination
              firstAxios={firstAxios}
              currentAxios="/ProductComment/search?page="
            />
          </div>
        </div>
        {showFilter === true && (
          <Filter
            Date={true}
            Situation={true}
            Category={false}
            User={true}
            UserAxios="/User?isNotPaginated=1"
            Data={Data}
            Url={Url}
            axiosURL="/ProductComment/search"
          />
        )}
      </div>
    </div>
  );
}
