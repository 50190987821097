import React, { useEffect } from "react";
import "../../assets/styles/TextInputComponentStyle/TextInputComponent.css";

export default function TextInputComponent(props) {
  const {
    id,
    value,
    onchange,
    placeholder,
    width,
    type,
    inputType,
    disabled,
    height,
    borderColor,
    onClick,
    onClick2,
    zIndex,
    iconStyle,
    iconSrc,
    iconSrcMouseOver,
    iconSrc2,
    iconSrc2MouseOver,
    iconSide,
    backgroundColor,
    reference,
    margin,
    keyDown,
  } = props;

  // id === inputs id
  // value === inputs value
  // onchange === inputs onchange
  // placeholder === inputs placeholder
  // width === inputs width
  // type === inputs type  "Text or Number"
  // inputType === inputs inputType "input or inputWithIcon"
  // disabled === inputs disabled "when you want disabled input"
  // height === inputs height
  // borderColor === inputs bordercolor
  //   onClick === onclick for inputtype inputWithIcon for first icon
  //   onClick2 === onclick for inputtype inputWithIcon for second icon
  //   iconSrc === first icon src
  //   iconSrcMouseOver === first icon src onMouseOver
  //   iconSrc2 === second icon src
  //   iconSrc2MouseOver === second icon src onMouseOver
  //   iconSide === icon side
  //   backgroundColor === input backgroundcolor
  // keyDown Event for input number (conditional input type number for inputs haven't keyDown prop)

  return (
    <div
      onClick={() => {
        if (reference != undefined) {
          reference.current.focus();
        }
      }}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: `${width}`,
        height: `${height}`,
        backgroundColor: `${
          backgroundColor != undefined ? backgroundColor : "#fff"
        }`,
        borderWidth: "1px",
        outline: "none",
        borderRadius: "10px",
        borderStyle: "solid",
        borderColor: `${borderColor}`,
        padding: "0px 10px",
        margin: margin == "undefined" || margin == "null" ? "0" : margin,
        direction: "rtl",
        zIndex: zIndex,
      }}
    >
      {inputType === "inputWithIcon" &&
        iconSide === "left" &&
        iconSrc2 != undefined && (
          <>
            <input
              id={id}
              type={type}
              value={value}
              disabled={disabled}
              onChange={onchange}
              className="TextInputComponent"
              style={{
                display: "flex",
                width: "85%",
                height: "85%",
                backgroundColor: "inherit",
                outline: "none",
                border: "none",
                fontFamily: "IRANSansFaNum",
                direction: "rtl",
                fontSize: "16px",
                color: "#bbb",
              }}
              placeholder={placeholder}
            ></input>
            <button
              onClick={onClick}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "7.5%",
                height: "85%",
                outline: "none",
                border: "none",
                backgroundColor: "unset",
              }}
            >
              <img
                src={iconSrc}
                style={
                  iconStyle.length > 0 && iconStyle != undefined
                    ? iconStyle[0]
                    : {}
                }
                onMouseOver={(e) => (e.currentTarget.src = iconSrcMouseOver)}
                onMouseOut={(e) => (e.currentTarget.src = iconSrc)}
              />
            </button>
            <button
              onClick={onClick2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "7.5%",
                height: "85%",
                outline: "none",
                border: "none",
                backgroundColor: "unset",
              }}
            >
              <img
                src={iconSrc2}
                style={
                  iconStyle.length > 0 && iconStyle != undefined
                    ? iconStyle[1]
                    : {}
                }
                onMouseOver={(e) => (e.currentTarget.src = iconSrc2MouseOver)}
                onMouseOut={(e) => (e.currentTarget.src = iconSrc2)}
              />
            </button>
          </>
        )}
      {inputType === "inputWithIcon" &&
        iconSide === "right" &&
        iconSrc2 != undefined && (
          <>
            <button
              onClick={onClick}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "7.5%",
                height: "85%",
                outline: "none",
                border: "none",
                backgroundColor: "unset",
              }}
            >
              <img
                src={iconSrc}
                onMouseOver={(e) => (e.currentTarget.src = iconSrcMouseOver)}
                onMouseOut={(e) => (e.currentTarget.src = iconSrc)}
              />
            </button>
            <button
              onClick={onClick2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "7.5%",
                height: "85%",
                outline: "none",
                border: "none",
                backgroundColor: "unset",
              }}
            >
              <img
                src={iconSrc2}
                onMouseOver={(e) => (e.currentTarget.src = iconSrc2MouseOver)}
                onMouseOut={(e) => (e.currentTarget.src = iconSrc2)}
              />
            </button>
            <input
              id={id}
              type={type}
              value={value}
              disabled={disabled}
              onChange={onchange}
              className="TextInputComponent"
              style={{
                display: "flex",
                width: "85%",
                height: "85%",
                backgroundColor: "inherit",
                outline: "none",
                border: "none",
                fontFamily: "IRANSansFaNum",
                direction: "rtl",
                fontSize: "16px",
                color: "#bbb",
              }}
              placeholder={placeholder}
            ></input>
          </>
        )}

      {inputType === "inputWithIcon" &&
        iconSide === "left" &&
        iconSrc2 === undefined && (
          <>
            <input
              id={id}
              type={type}
              value={value}
              disabled={disabled}
              onChange={onchange}
              className="TextInputComponent"
              style={{
                display: "flex",
                width: "95%",
                height: "85%",
                backgroundColor: "inherit",
                outline: "none",
                border: "none",
                fontFamily: "IRANSansFaNum",
                direction: "rtl",
                fontSize: "16px",
                color: "#bbb",
              }}
              placeholder={placeholder}
            ></input>
            <button
              onClick={onClick}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "5%",
                height: "85%",
                outline: "none",
                border: "none",
                backgroundColor: "unset",
              }}
            >
              <img src={iconSrc} />
            </button>
          </>
        )}
      {inputType === "inputWithIcon" &&
        iconSide === "right" &&
        iconSrc2 === undefined && (
          <>
            <button
              onClick={onClick}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "10%",
                height: "85%",
                outline: "none",
                border: "none",
                backgroundColor: "unset",
              }}
            >
              <img src={iconSrc} />
            </button>
            <input
              id={id}
              type={type}
              value={value}
              disabled={disabled}
              onChange={onchange}
              className="TextInputComponent"
              style={{
                display: "flex",
                width: "90%",
                height: "85%",
                backgroundColor: "inherit",
                outline: "none",
                border: "none",
                fontFamily: "IRANSansFaNum",
                direction: "rtl",
                fontSize: "16px",
                color: "#bbb",
              }}
              placeholder={placeholder}
            ></input>
          </>
        )}
      {inputType === "input" && (
        <input
          id={id}
          type={type}
          value={value}
          disabled={disabled}
          onChange={onchange}
          className="TextInputComponent"
          onKeyDown={(e) => keyDown != undefined && keyDown(e)}
          style={{
            display: "flex",
            width: "98%",
            height: "85%",
            backgroundColor: "inherit",
            outline: "none",
            border: "none",
            fontFamily: "IRANSansFaNum",
            direction: `${type === "number" ? "ltr" : "rtl"}`,
            fontSize: "16px",
            color: "#bbb",
          }}
          placeholder={placeholder}
        ></input>
      )}
      {inputType === "textarea" && (
        <textarea
          ref={reference}
          id={id}
          type={type}
          value={value}
          disabled={disabled}
          onChange={onchange}
          className="TextInputComponent"
          style={{
            display: "flex",
            width: "98%",
            height: "85%",
            backgroundColor: "inherit",
            outline: "none",
            border: "none",
            fontFamily: "IRANSansFaNum",
            direction: "rtl",
            fontSize: "16px",
            color: "#bbb",
            resize: "none",
            zIndex: zIndex,
            cursor: "auto",
          }}
          placeholder={placeholder}
        ></textarea>
      )}
    </div>
  );
}
