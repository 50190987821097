import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "../../assets/styles/DiscountStyle/CreateDiscount/CreateDiscount.css";
import InputComponent from "../TextInputComponent/TextInputComponent";
import { ShowAlert } from "../../action/index";
import { ResetToInit } from "../../action/index";
import { isObjectEmpty } from "../../helper/Helper";
import axios from "../../js/Axios/Axios";
import Barcode from "../../assets/icons/Barcode.svg";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import * as moment from "jalali-moment";
import { Calendar } from "react-datepicker2";
import { useSelector, useDispatch } from "react-redux";
import { AxiosNameDiscount } from "../../action/index";
import RightThumbSlider from "../../assets/icons/RightThumbSlider.svg";
import LeftThumbSlider from "../../assets/icons/LeftThumbSlider.svg";
import increament from "../../assets/icons/Plus.svg";
import decreament from "../../assets/icons/decreament.svg";
import check from "../../assets/icons/check.svg";
import uncheck from "../../assets/icons/uncheck.svg";
import AllProductCalender from "../../assets/icons/AllProductCalender.svg";
import EditActive from "../../assets/icons/EditActive.svg";
import useForceUpdate from "use-force-update";
export default function CreateDiscountComponent(props) {
  const { ProductsID, CategoryID, UserID } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const forceUpdate = useForceUpdate();
  const coupenData = useSelector((state) => state.SendCoupenForEdit);
  const [showCalender, setShowCalender] = useState(false);
  const [discountInfo, setDiscountInfo] = useState({
    code: null,
    description: null,
    is_percentage: 0,
    amount: null,
    min_buy: null,
    max_amount: null,
    max_usage: 0,
    expiration: null,
    allow_with_other_coupons: 0,
    doNotAllowOnProductsHaveDiscount: 0,
    release_status: null,
    userDiscount_ids: [],
    productPriceDiscount_ids: [],
    productCategoryDiscount_ids: [],
  });
  useEffect(() => {
    setDiscountInfo((prev) => {
      prev.productPriceDiscount_ids = ProductsID;
      return prev;
    });
  }, [ProductsID]);
  useEffect(() => {
    setDiscountInfo((prev) => {
      prev.productCategoryDiscount_ids = CategoryID;
      return prev;
    });
  }, [CategoryID]);
  useEffect(() => {
    setDiscountInfo((prev) => {
      prev.userDiscount_ids = UserID;
      return prev;
    });
  }, [UserID]);
  useEffect(() => {
    if (
      isObjectEmpty(coupenData) === false &&
      location.pathname === "/UpdateDiscount"
    ) {
      setDiscountInfo((prev) => {
        prev.code = coupenData.code;
        prev.description = coupenData.description;
        prev.is_percentage = coupenData.is_percentage;
        prev.amount = coupenData.amount;
        prev.min_buy = coupenData.min_buy;
        prev.max_amount = coupenData.max_amount;
        prev.max_usage = coupenData.max_usage;
        prev.expiration = coupenData.expiration_date;
        prev.allow_with_other_coupons = coupenData.allow_with_other_coupons;
        prev.doNotAllowOnProductsHaveDiscount =
          coupenData.do_not_allow_on_products_have_discount;
        prev.release_status = null;
        prev.userDiscount_ids = coupenData.user_ids;
        prev.productPriceDiscount_ids = coupenData.productPrice_ids;
        prev.productCategoryDiscount_ids = coupenData.productCategory_ids;
        return prev;
      });
    }
  }, []);

  const iOSBoxShadow =
    "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";
  const IOSSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.mode === "dark" ? "#3880ff" : "#3880ff",
    height: 5,
    cursor: "pointer",
    padding: "15px 0",
    "& .MuiSlider-thumb": {
      height: "15px",
      width: "25px",
      cursor: "pointer",
      borderRadius: "10px",
      backgroundColor: "#fff",
      boxShadow: iOSBoxShadow,
      "&:focus, &:hover, &.Mui-active": {
        boxShadow:
          "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          boxShadow: iOSBoxShadow,
        },
      },
    },
    "& .MuiSlider-valueLabel": {
      fontSize: 14,
      fontWeight: "normal",
      top: 2,
      fontFamily: "IRANSansFaNum",
      backgroundColor: "unset",
      color: theme.palette.text.primary,
      "&:before": {
        display: "none",
      },
      "& *": {
        background: "transparent",
        color: theme.palette.mode === "dark" ? "#fff" : "#000",
      },
    },
    "& .MuiSlider-track": {
      border: "none",
      color: "#00589c",
      cursor: "pointer",
    },
    "& .MuiSlider-rail": {
      opacity: 0.5,
      backgroundColor: "#bfbfbf",
      cursor: "pointer",
    },
    "& .MuiSlider-mark": {
      backgroundColor: "#bfbfbf",
      height: 8,
      width: 1,
      "&.MuiSlider-markActive": {
        opacity: 1,
        backgroundColor: "currentColor",
      },
    },
  }));
  function AirbnbThumbComponent(props) {
    const { children, ...other } = props;
    // console.log(children);
    // console.log(other.ownerState.onChange);
    return (
      <SliderThumb {...other}>
        {children}
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
          }}
        >
          <img
            style={{ width: "10px", height: "10px" }}
            src={RightThumbSlider}
          />
          <img
            style={{ width: "10px", height: "10px" }}
            src={LeftThumbSlider}
          />
        </div>
      </SliderThumb>
    );
  }
  const discountType = (id) => {
    if (id !== discountInfo.is_percentage) {
      setDiscountInfo((prev) => {
        prev.is_percentage = id;
        return prev;
      });
      setDiscountInfo((prev) => {
        prev.amount = null;
        return prev;
      });
      setDiscountInfo((prev) => {
        prev.max_amount = null;
        return prev;
      });
      forceUpdate();
    }
  };
  const createBarcodeClick = () => {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_";

    for (var i = 0; i < 7; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    setDiscountInfo((prev) => {
      prev.code = text;
      return prev;
    });
    setError((prev) => {
      prev.code = false;
      forceUpdate();
      return prev;
    });
    forceUpdate();
  };
  const maxUsage = (number) => {
    setDiscountInfo((prev) => {
      prev.max_usage = number;
      return prev;
    });
    forceUpdate();
  };
  const maxUsageNumber = (id) => {
    if (discountInfo.max_usage != 0) {
      if (id === "increament") {
        setDiscountInfo((prev) => {
          prev.max_usage = prev.max_usage + 1;
          return prev;
        });
      } else if (id === "decreament") {
        if (discountInfo.max_usage > 1) {
          setDiscountInfo((prev) => {
            prev.max_usage = prev.max_usage - 1;
            return prev;
          });
        }
      }
      forceUpdate();
    }
  };
  const howUseDiscount = (id) => {
    if (id === "allowUseWithOther") {
      if (discountInfo.allow_with_other_coupons != 1) {
        setDiscountInfo((prev) => {
          prev.allow_with_other_coupons = 1;
          return prev;
        });
      } else {
        setDiscountInfo((prev) => {
          prev.allow_with_other_coupons = 0;
          return prev;
        });
      }
    } else if (id === "dontAllow") {
      if (discountInfo.doNotAllowOnProductsHaveDiscount != 1) {
        setDiscountInfo((prev) => {
          prev.doNotAllowOnProductsHaveDiscount = 1;
          return prev;
        });
      } else {
        setDiscountInfo((prev) => {
          prev.doNotAllowOnProductsHaveDiscount = 0;
          return prev;
        });
      }
    }
    forceUpdate();
  };
  const showCalenderClick = () => {
    setShowCalender(!showCalender);
  };
  const addExpDate = (value) => {
    let date = moment(value, "YYYY/MM/DD").locale("fa").format("YYYY/MM/DD");
    setDiscountInfo((prev) => {
      prev.expiration = date;
      return prev;
    });
    setShowCalender(false);
    setError((prev) => {
      prev.expiration = false;
      forceUpdate();
      return prev;
    });
    forceUpdate();
  };

  // code: null,
  // description: null,
  // is_percentage: 0,
  // amount: null,
  // min_buy: null,
  // max_amount: null,
  // max_usage: 0,
  // expiration: null,
  // allow_with_other_coupons: 0,
  // doNotAllowOnProductsHaveDiscount: 0,
  // release_status: null,
  // userDiscount_ids: [],
  // productPriceDiscount_ids: [],
  // productCategoryDiscount_ids: [],
  const [error, setError] = useState({
    code: false,
    min_buy: false,
    amount: false,
    expiration: false,
    description: false,
  });
  const shareDiscount = (id) => {
    // setDiscountInfo((prev) => {
    //   prev.release_status = id;
    //   forceUpdate();
    //   return prev;
    // });
    discountInfo.release_status = id
    if (
      discountInfo.code != null &&
      discountInfo.code != "" &&
      discountInfo.min_buy !== null &&
      discountInfo.min_buy !== "" &&
      discountInfo.amount != null &&
      discountInfo.amount !== "" &&
      discountInfo.expiration !== null &&
      discountInfo.expiration !== "" &&
      discountInfo.description !== null &&
      discountInfo.description !== ""
    ) {
      if (discountInfo.release_status === 0) {
        if (location.pathname === "/CreateDiscount") {
          axios
            .post("/Discount", discountInfo)
            .then((res) => {
              if (res.data.status === 0) {
                // dispatch(ShowAlert(true, res.data.data));
                console.log(res);
              }
              if (res.data.status) {
                console.log(res);
                // dispatch(ShowAlert(true, res.data.message));
                navigate("/ManageDiscount");
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } else if (location.pathname === "/UpdateDiscount") {
          axios
            .put(`/Discount/${coupenData.id}`, discountInfo)
            .then((res) => {
              if (res.data.status) {
                console.log(res);
                dispatch(ShowAlert(true, res.data.message));
                navigate("/ManageDiscount");
                dispatch(ResetToInit());
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      } else {
        if (
          discountInfo.productCategoryDiscount_ids.length > 0 ||
          discountInfo.productPriceDiscount_ids.length > 0 ||
          discountInfo.userDiscount_ids.length > 0
        ) {
          if (location.pathname === "/CreateDiscount") {
            axios
              .post("/Discount", discountInfo)
              .then((res) => {
                if (res.data.status === 0) {
                  // dispatch(ShowAlert(true, res.data.data));
                  console.log(res);
                }
                if (res.data.status) {
                  console.log(res);
                  // dispatch(ShowAlert(true, res.data.message));
                  navigate("/ManageDiscount");
                }
              })
              .catch((e) => {
                console.log(e);
              });
          } else if (location.pathname === "/UpdateDiscount") {
            axios
              .put(`/Discount/${coupenData.id}`, discountInfo)
              .then((res) => {
                if (res.data.status) {
                  console.log(res);
                  dispatch(ShowAlert(true, res.data.message));
                  navigate("/ManageDiscount");
                  dispatch(ResetToInit());
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        } else {
          dispatch(
            ShowAlert(
              true,
              "برای انتشار کوپن انتخاب یکی از فیلدهای محصول , دسته بندی و یا مشتری الزامی است ."
            )
          );
        }
      }
    } else {
      let text = `برای منتشر کردن کوپن فیلد های 
      ${
        discountInfo.code === null || discountInfo.code === ""
          ? "شناسه کوپن و"
          : ""
      }
      ${
        discountInfo.amount === null || discountInfo.amount === ""
          ? "مبلغ و"
          : ""
      }
      ${
        discountInfo.min_buy === null || discountInfo.min_buy === ""
          ? "حداقل مبلغ خرید و"
          : ""
      }
      ${
        discountInfo.description === null || discountInfo.description === ""
          ? "توضیحات و"
          : ""
      }
      ${
        discountInfo.expiration === null || discountInfo.expiration === ""
          ? "تاریخ انقضا"
          : ""
      }
      الزامی است .
      `;
      dispatch(ShowAlert(true, text));
      if (discountInfo.code !== null || discountInfo.code !== "") {
        setError((prev) => {
          prev.code = true;
          forceUpdate();
          return prev;
        });
      }
      if (discountInfo.amount !== null || discountInfo.amount !== "") {
        setError((prev) => {
          prev.amount = true;
          forceUpdate();
          return prev;
        });
      }
      if (discountInfo.min_buy !== null || discountInfo.min_buy !== "") {
        setError((prev) => {
          prev.min_buy = true;
          forceUpdate();
          return prev;
        });
      }
      if (discountInfo.expiration !== null || discountInfo.expiration !== "") {
        setError((prev) => {
          prev.expiration = true;
          forceUpdate();
          return prev;
        });
      }
      if (
        discountInfo.description !== null ||
        discountInfo.description !== ""
      ) {
        setError((prev) => {
          prev.description = true;
          forceUpdate();
          return prev;
        });
      }
    }
  };

  return (
    <div className="discount-All">
      {showCalender === true && (
        <div
          onClick={() => showCalenderClick()}
          className="discount-calender-all"
        ></div>
      )}
      <div className="discount-title">
        <p>کوپن خرید</p>
      </div>
      <div className="discount-introduction-barcode-box">
        <InputComponent
          disabled={true}
          value={discountInfo.code}
          width="100%"
          height="44px"
          type="text"
          id={"barcodeInput"}
          placeholder="شناسه کوپن "
          borderColor={error.code === false ? "#dcdcdc" : "#F23B3B"}
          inputType="inputWithIcon"
          iconSide="left"
          onClick={() => createBarcodeClick(5)}
          iconSrc={Barcode}
        />
        <InputComponent
          value={discountInfo.description}
          width="100%"
          height="100px"
          type="text"
          inputType="textarea"
          placeholder="توضیحات"
          borderColor={error.description === false ? "#dcdcdc" : "#F23B3B"}
          onchange={(e) => {
            setDiscountInfo((prev) => {
              prev.description = e.target.value;
              forceUpdate();
              return prev;
            });
            setError((prev) => {
              prev.description = false;
              forceUpdate();
              return prev;
            });
          }}
        />
      </div>
      <div className="discount-price-box">
        <div className="discount-price-percentage-box">
          <label
            style={{
              display: "flex",
              width: "30%",
            }}
            htmlFor="percentage"
          >
            <div className="discount-price-percentage-box-2">
              <div className="discount-price-percentage-box-3">
                <input
                  onChange={() => discountType(1)}
                  style={{
                    display: "none",
                  }}
                  type="radio"
                  id="percentage"
                  name="discount"
                />
                <div
                  className={
                    discountInfo.is_percentage === 1
                      ? "discount-price-percentage-box-6"
                      : "discount-price-percentage-box-5"
                  }
                ></div>
              </div>
              <p>کوپن درصدی</p>
            </div>
          </label>
          <div className="discount-price-percentage-box-4">
            <IOSSlider
              disabled={discountInfo.is_percentage === 1 ? false : true}
              components={{ Thumb: AirbnbThumbComponent }}
              aria-label="ios slider"
              defaultValue={
                discountInfo.is_percentage === 1 ? discountInfo.amount : 0
              }
              min={0}
              max={100}
              draggable={true}
              valueLabelDisplay="on"
              onChange={(e) => {
                setDiscountInfo((prev) => {
                  prev.amount = e.target.value;
                  return prev;
                });
                setError((prev) => {
                  prev.amount = false;
                  return prev;
                });
              }}
            />
          </div>
        </div>
        <div className="discount-price-percentage-box">
          <label
            style={{
              display: "flex",
              width: "30%",
            }}
            htmlFor="cash"
          >
            <div className="discount-price-percentage-box-2">
              <div className="discount-price-percentage-box-3">
                <input
                  onChange={() => discountType(0)}
                  style={{
                    display: "none",
                  }}
                  type="radio"
                  id="cash"
                  name="discount"
                />
                <div
                  className={
                    discountInfo.is_percentage === 0
                      ? "discount-price-percentage-box-6"
                      : "discount-price-percentage-box-5"
                  }
                ></div>
              </div>
              <p>کوپن مبلغی</p>
            </div>
          </label>
          <div className="discount-price-percentage-box-7">
            <InputComponent
              value={
                discountInfo.is_percentage === 0 ? discountInfo.amount : ""
              }
              disabled={discountInfo.is_percentage === 0 ? false : true}
              width="100%"
              height="44px"
              type="number"
              inputType="input"
              placeholder="مبلغ را وارد کنید"
              borderColor={error.amount === false ? "#dcdcdc" : "#F23B3B"}
              onchange={(e) => {
                setDiscountInfo((prev) => {
                  prev.amount = e.target.value;
                  forceUpdate();
                  return prev;
                });
                setError((prev) => {
                  prev.amount = false;
                  return prev;
                });
              }}
            />
          </div>
        </div>
        <div className="discount-price-limit-box">
          <InputComponent
            disabled={discountInfo.is_percentage === 1 ? false : true}
            value={
              discountInfo.is_percentage === 1 ? discountInfo.max_amount : ""
            }
            width="calc(45% - 20px)"
            height="44px"
            type="number"
            inputType="input"
            placeholder="حداکثر مبلغ تخفیف"
            borderColor="#dcdcdc"
            onchange={(e) => {
              setDiscountInfo((prev) => {
                prev.max_amount = e.target.value;
                forceUpdate();
                return prev;
              });
            }}
          />
          <InputComponent
            value={discountInfo.min_buy}
            width="calc(45% - 20px)"
            height="44px"
            type="number"
            inputType="input"
            placeholder="حداقل مبلغ خرید"
            borderColor={error.min_buy === false ? "#dcdcdc" : "#F23B3B"}
            onchange={(e) => {
              setDiscountInfo((prev) => {
                prev.min_buy = e.target.value;
                forceUpdate();
                return prev;
              });
              setError((prev) => {
                prev.min_buy = false;
                forceUpdate();
                return prev;
              });
            }}
          />
        </div>
      </div>
      <div className="discount-limit-box">
        <div className="discount-limit-box-2">
          <label
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
              marginBottom: "15px",
            }}
            htmlFor="infinity"
          >
            <div className="discount-limit-box-3">
              <div className="discount-limit-box-4">
                <input
                  style={{ display: "none" }}
                  onChange={() => maxUsage(0)}
                  id="infinity"
                  name="limit"
                  type="radio"
                />
                <div
                  className={
                    discountInfo.max_usage === 0
                      ? "discount-price-percentage-box-6"
                      : "discount-price-percentage-box-5"
                  }
                ></div>
              </div>
              <p>استفاده بی‌نهایت از کوپن</p>
            </div>
          </label>

          <div className="discount-limit-percentage">
            <label
              style={{
                display: "flex",
                justifyContent: "center",
                width: "50%",
              }}
              htmlFor="notinfinity"
            >
              <div className="discount-limit-box-3">
                <div className="discount-limit-box-4">
                  <input
                    style={{ display: "none" }}
                    onChange={() => maxUsage(1)}
                    id="notinfinity"
                    name="limit"
                    type="radio"
                  />
                  <div
                    className={
                      discountInfo.max_usage > 0
                        ? "discount-price-percentage-box-6"
                        : "discount-price-percentage-box-5"
                    }
                  ></div>
                </div>
                <p>محدودیت استفاده</p>
              </div>
            </label>

            <div className="discount-limit-number-box">
              <div className="discount-limit-counter">
                <div
                  onClick={() => maxUsageNumber("increament")}
                  className="discount-limit-counter-increament"
                >
                  <img
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                    src={increament}
                  />
                </div>
                <div className="discount-limit-counter-show">
                  <p>{discountInfo.max_usage}</p>
                </div>
                <div
                  onClick={() => maxUsageNumber("decreament")}
                  className="discount-limit-counter-decreament"
                >
                  <img
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                    src={decreament}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <label
          className="discount-limit-use-other-box"
          htmlFor="allowusewithother"
        >
          <div className="discount-limit-use-other-box-2">
            <input
              style={{
                display: "none",
              }}
              id="allowusewithother"
              name="allowusewithother"
              type="checkbox"
              onChange={() => howUseDiscount("allowUseWithOther")}
            />
            <img
              style={{
                width: "20px",
                height: "20px",
              }}
              src={
                discountInfo.allow_with_other_coupons === 1 ? check : uncheck
              }
            />
          </div>
          <p>کوپن مجاز به استفاده همزمان با کوپن های دیگر نباشد</p>
        </label>
        <label htmlFor="dontAllow" className="discount-limit-use-other-box">
          <div className="discount-limit-use-other-box-2">
            <input
              style={{
                display: "none",
              }}
              id="dontAllow"
              name="dontAllow"
              type="checkbox"
              onChange={() => howUseDiscount("dontAllow")}
            />
            <img
              style={{
                width: "20px",
                height: "20px",
              }}
              src={
                discountInfo.doNotAllowOnProductsHaveDiscount === 1
                  ? check
                  : uncheck
              }
            />
          </div>
          <p>کوپن بر روی محصولات تخفیف خورده قابل استفاده نباشد</p>
        </label>
        {showCalender === true && (
          <div className="discount-calender-box">
            <Calendar
              min={moment().locale("en").format("YYYY/M/D")}
              onChange={(value) => addExpDate(value._d)}
              isGregorian={false}
            />
          </div>
        )}
        <div
          onClick={() => showCalenderClick()}
          className={
            error.expiration === false
              ? "discount-limit-date-box"
              : "discount-limit-date-box-error"
          }
        >
          <p>
            {discountInfo.expiration === null
              ? "تاریخ انقضا"
              : discountInfo.expiration}
          </p>
          <img style={{cursor:"pointer"}} src={AllProductCalender} />
        </div>
      </div>

      <div
        onClick={() => dispatch(AxiosNameDiscount("/productPrice"))}
        className="discount-button-box"
      >
        {/* <button
          
          className="discount-button-product"
        >
          <p>انتخاب محصول</p>
        </button> */}
        {
          <p className="discount-button-box-tittle">
            {discountInfo.productPriceDiscount_ids.length} محصول انتخاب شده
          </p>
        }

        <img src={EditActive} />
      </div>
      <div
        onClick={() =>
          dispatch(AxiosNameDiscount("/productCategory?isPaginated=1"))
        }
        className="discount-button-box"
      >
        {/* <button
         
          className="discount-button-product"
        >
          <p>انتخاب دسته بندی</p>
        </button> */}
        {
          <p className="discount-button-box-tittle">
            {discountInfo.productCategoryDiscount_ids.length} دسته بندی انتخاب
            شده
          </p>
        }

        <img src={EditActive} />
      </div>
      <div
        onClick={() => dispatch(AxiosNameDiscount("/User"))}
        className="discount-button-box"
      >
        {/* <button
          
          className="discount-button-customer"
        >
          <p>انتخاب مشتری</p>
        </button> */}
        {
          <p className="discount-button-box-tittle">
            {discountInfo.userDiscount_ids.length} مشتری انتخاب شده
          </p>
        }

        <img src={EditActive} />
      </div>
      <div className="discount-share-box">
        <button
          onClick={() => shareDiscount(1)}
          className="discount-share-button"
        >
          <p>منتشر شود</p>
        </button>
        <button
          onClick={() => shareDiscount(0)}
          className="discount-draft-button"
        >
          <p>پیش نویس شود</p>
        </button>
      </div>
    </div>
  );
}
