/* eslint-disable */
import React, { useEffect, useState } from "react";
import useForceUpdate from "use-force-update";
import axios from "../../js/Axios/Axios";
import * as moment from "jalali-moment";
import { Calendar } from "react-datepicker2";
import { SendUserIdForFilter } from "../../action/index";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { whichURL } from "../../helper/Helper";
import showFilterIcon from "../../assets/icons/showFilterIcon.svg";
import AllProductFilterUpArrow from "../../assets/icons/AllProductFilterUpArrow.svg";
import AllProductRightArrow from "../../assets/icons/AllProductRightArrow.svg";
import AllProductCalender from "../../assets/icons/AllProductCalender.svg";
import AllProductDeleteFilter from "../../assets/icons/AllProductDeleteFilter.svg";
import check from "../../assets/icons/check.svg";
import uncheck from "../../assets/icons/uncheck.svg";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  display: "flex",
  width: "350px",
  color: "#00589c",
  height: 3,
  "& .MuiSlider-thumb": {
    height: 21,
    width: 21,
    backgroundColor: "#00589c",
    border: "5px solid #ffffff",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0 0 2.5 0px rgba(0, 0, 0, 0.15)",
    },
    "& .airbnb-bar": {
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 3,
    cursor: "pointer",
  },
  "& .MuiSlider-rail": {
    color: "#ebebeb",
    height: 3,
    cursor: "pointer",
  },
}));

export default function Filter(props) {
  const {
    Date,
    Situation,
    Category,
    Price,
    User,
    UserAxios,
    CategoryAxios,
    axiosURL,
    width,
  } = props;
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const location = useLocation();
  const user_id = useSelector((state) => state.UserFilter);
  const [allHeight, setAllHeight] = useState();
  const [numberProps, setNumberProps] = useState({ count: 0 });
  const [showFilter, setShowFilter] = useState(true);
  const [whitchFliter, setWitchFilter] = useState(null);
  const [showCalender, setShowCalender] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [fromEnglishDate, setFromEnglishDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endEnglishDate, setEndEnglishDate] = useState(null);
  const [situation, setSituation] = useState("");
  const [allFilter, setAllFilter] = useState([]);
  const [allFilterId, setFilterId] = useState([]);
  const [allCategoryFilter, setAllCategoryFilter] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);
  const [allUser, setAllUser] = useState([]);
  const [userID, setUserID] = useState(null);
  const [userName, setUserName] = useState(null);
  const [startPrice, setStartPrice] = useState(null);
  const [endPrice, setEndPrice] = useState(null);
  const CloseFilter = () => {
    setShowFilter(!showFilter);
    forceUpdate();
  };
  useEffect(() => {
    if (
      (user_id.nameid === "blog" ||
        user_id.nameid === "product" ||
        user_id.nameid === "message" ||
        user_id.nameid === "order") &&
      user_id.id != null
    ) {
      axios.get(UserAxios).then((res) => {
        if (res.data.status) {
          console.log(res);
          res.data.data.map((item) => {
            if (item.id === user_id.id) {
              setUserName(item.fullName);
            }
          });
          setUserID(user_id.id);
        }
      });
    }
    forceUpdate();
  }, [user_id]);
  useEffect(() => {
    if (user_id.id != null) {
      addFilter();
    }
    setTimeout(() => {
      dispatch(SendUserIdForFilter(null, null));
    }, 5000);
  }, [userID]);

  // for set height of filter box
  useEffect(() => {
    if (Date === true) {
      setNumberProps((prev) => {
        return {
          count: prev.count + 1,
        };
      });
      forceUpdate();
    }
    if (Situation === true) {
      setNumberProps((prev) => {
        return {
          count: prev.count + 1,
        };
      });
      forceUpdate();
    }
    if (Category === true) {
      setNumberProps((prev) => {
        return {
          count: prev.count + 1,
        };
      });
      forceUpdate();
    }
    if (User === true) {
      setNumberProps((prev) => {
        return {
          count: prev.count + 1,
        };
      });
      forceUpdate();
    }
    if (Price === true) {
      setNumberProps((prev) => {
        return {
          count: prev.count + 1,
        };
      });
      forceUpdate();
    }
    setNumberProps((prev) => {
      return {
        count: prev.count * 50,
      };
    });
  }, []);
  useEffect(() => {
    setAllHeight(`${numberProps.count + 70}px`);
  }, [numberProps]);
  const addFilter = () => {
    if (
      Date === true &&
      Category === false &&
      Situation === true &&
      User === true &&
      Price != true
    ) {
      if (
        (fromDate != null && endDate != null) ||
        situation != "" ||
        userID != null
      ) {
        whichURL(axiosURL);
        if (fromDate != null && endDate != null) {
          var min = `${fromDate} 00:00:00`;
          var max = `${endDate} 23:59:59`;
        } else {
          var min = "";
          var max = "";
        }
        axios
          .get(
            `${axiosURL}?${whichURL().minDate}=${min}&${
              whichURL().maxDate
            }=${max}&${whichURL().situationStatus}=${situation}&${
              userID != null && whichURL().user_id
            }=${userID != null && userID} `
          )
          .then((res) => {
            if (res.data.status) {
              console.log(res);
              props.Data(res.data);
              props.Url(
                `${axiosURL}?${whichURL().minDate}=${min}&${
                  whichURL().maxDate
                }=${max}&${whichURL().situationStatus}=${situation}&${
                  userID != null && whichURL().user_id
                }=${userID != null && userID} `
              );
              if (fromDate != null && endDate != null) {
                if (!allFilterId.includes("Date")) {
                  allFilter.push({
                    title: `محدوده تاریخ ( از ${fromDate} تا  ${endDate})`,
                    id: "Date",
                  });
                  allFilterId.push("Date");
                }
              }
              if (situation != "") {
                if (!allFilterId.includes("Situation")) {
                  allFilter.push({
                    title: `وضیعت ${situation}`,
                    id: "Situation",
                  });
                  allFilterId.push("Situation");
                }
              }
              if (userID != null) {
                if (!allFilterId.includes("USERID")) {
                  allFilter.push({
                    title: `${userName}`,
                    id: "USERID",
                  });
                  allFilterId.push("USERID");
                }
              }
              forceUpdate();
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
    if (
      Date === true &&
      Category === true &&
      Situation === false &&
      Price != true
    ) {
      whichURL(axiosURL);
      if ((fromDate != null && endDate != null) || categoryIds.length != 0) {
        if (fromDate != null && endDate != null) {
          var min = `${fromDate} 00:00:00`;
          var max = `${endDate} 23:59:59`;
        } else {
          var min = "";
          var max = "";
        }
        var ids = JSON.stringify(categoryIds);
        axios
          .get(
            `${axiosURL}&${whichURL().minDate}=${min}&${
              whichURL().maxDate
            }=${max}&${whichURL().category}=${ids}`
          )
          .then((res) => {
            if (res.data.data) {
              console.log(res);
              let route = `${axiosURL}&${whichURL().minDate}=${min}&${
                whichURL().maxDate
              }=${max}&${whichURL().category}=${ids}`;
              props.Data(res.data, route);
              if (fromDate != null && endDate != null) {
                if (!allFilterId.includes("Date")) {
                  allFilter.push({
                    title: `محدوده تاریخ ( از ${fromDate} تا  ${endDate})`,
                    id: "Date",
                  });
                  allFilterId.push("Date");
                }
              }
              if (categoryIds.length != 0) {
                if (!allFilterId.includes("Category")) {
                  allFilter.push({
                    title: `(دسته بندی)`,
                    id: "Category",
                  });
                  allFilterId.push("Category");
                }
              }
            }
            forceUpdate();
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
    if (
      Date === false &&
      Category === false &&
      Situation === true &&
      Price != true
    ) {
      axios
        .get(`${axiosURL}?status=${situation}`)
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            props.Data(res.data);
            props.Url(`${axiosURL}?status=${situation}`);
            if (situation !== "") {
              if (!allFilterId.includes("Situation")) {
                allFilter.push({
                  title: `وضیعت ${situation === 1 ? "منتشر شده" : "پیش نویس"}`,
                  id: "Situation",
                });
                allFilterId.push("Situation");
              }
            }
          }
          forceUpdate();
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (
      Date === true &&
      Category === false &&
      Situation === true &&
      Price === true &&
      User === true
    ) {
      let user_id = "";
      let Situation = "";
      let StartPrice = "";
      let EndPrice = "";
      let FromDate = "";
      let EndDate = "";
      if (userID !== null) {
        user_id = userID;
      }
      if (situation !== null) {
        Situation = situation;
      }
      if (startPrice !== null) {
        StartPrice = startPrice;
      }
      if (endPrice !== null) {
        EndPrice = endPrice;
      }
      if (fromDate != null) {
        FromDate = fromDate;
      }
      if (endDate != null) {
        EndDate = endDate;
      }
      axios
        .get(
          `${axiosURL}?user_id=${user_id}&payment_type=${Situation}&order_min_price=${StartPrice}&order_max_price=${EndPrice}&order_min_registrationDate=${FromDate}&order_max_registrationDate=${EndDate}`
        )
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            let route = `${axiosURL}?user_id=${user_id}&payment_type=${Situation}&order_min_price=${StartPrice}&order_max_price=${EndPrice}&order_min_registrationDate=${FromDate}&order_max_registrationDate=${EndDate}`;
            props.Data(res.data, route);
            if (situation !== "") {
              if (!allFilterId.includes("Situation")) {
                allFilter.push({
                  title: `وضیعت ${situation}`,
                  id: "Situation",
                });
                allFilterId.push("Situation");
              }
            }
            if (user_id !== "") {
              if (!allFilterId.includes("Situation")) {
                allFilter.push({
                  title: `${userName}`,
                  id: "USERID",
                });
                allFilterId.push("USERID");
              }
            }
            if (EndPrice !== "" && StartPrice !== "") {
              if (!allFilterId.includes("PRICE")) {
                allFilter.push({
                  title: `قیمت از ${StartPrice} تا ${EndPrice}`,
                  id: "PRICE",
                });
                allFilterId.push("PRICE");
              }
            }
            if (FromDate != "" && EndDate != "") {
              if (!allFilterId.includes("Date")) {
                allFilter.push({
                  title: `محدوده تاریخ ( از ${FromDate} تا  ${EndDate})`,
                  id: "Date",
                });
                allFilterId.push("Date");
              }
            }
            forceUpdate();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    if (
      Date === true &&
      Category === false &&
      Situation === false &&
      Price === false
    ) {
      let FromDate = "";
      let EndDate = "";

      if (fromDate != null) {
        FromDate = fromDate;
      }
      if (endDate != null) {
        EndDate = endDate;
      }

      axios
        .get(
          `${axiosURL}?fromReturnedTime=${FromDate}&toReturnedTime=${EndDate}`
        )
        .then((res) => {
          if (res.data.status) {
            let route = `${axiosURL}?fromReturnedTime=${FromDate}&toReturnedTime=${EndDate}`;
            props.Data(res.data, route);
            if (FromDate != "" && EndDate != "") {
              if (!allFilterId.includes("Date")) {
                allFilter.push({
                  title: `محدوده تاریخ ( از ${FromDate} تا  ${EndDate})`,
                  id: "Date",
                });
                allFilterId.push("Date");
              }
            }
            forceUpdate();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  const delteFilter = (id, index) => {
    if (id === "Date") {
      var min = "";
      var max = "";
    } else if (fromDate != null && endDate != null) {
      var min = `${fromDate} 00:00:00`;
      var max = `${endDate} 23:59:59`;
    } else if (fromDate === null && endDate === null) {
      var min = "";
      var max = "";
    }
    if (id === "Situation") {
      setSituation("");
    }
    if (id === "Category") {
      categoryIds.map((item, index) => {
        categoryIds.splice(index);
      });
    }

    // todo
    if (
      Date === true &&
      Category === false &&
      Situation === false &&
      Price == false
    ) {
      console.log(axiosURL);
      console.log(allFilter);
      axios
        .get(`${axiosURL}?fromReturnedTime=${min}&toReturnedTime=${max}`)
        .then((res) => {
          if (res.data.status) {
            let route = `${axiosURL}?fromReturnedTime=${min}&toReturnedTime=${max}`;
            props.Data(res.data, route);
            // props.Url(
            //   `${axiosURL}?fromReturnedTime=${min}&fromReturnedTime=${max}`
            // );
            if (id === "Date") {
              allFilter.splice(index, 1);
              allFilterId.splice(index, 1);
              setFromDate(null);
              setFromEnglishDate(null);
              setEndDate(null);
              setEndEnglishDate(null);
            }

            forceUpdate();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    if (
      Date === true &&
      Category === true &&
      Situation === false &&
      Price != true
    ) {
      var ids = JSON.stringify(categoryIds);
      axios
        .get(
          `${axiosURL}&${whichURL().minDate}=${min}&${
            whichURL().maxDate
          }=${max}&${whichURL().category}=${ids}`
        )
        .then((res) => {
          if (res.data.data) {
            let route = `${axiosURL}&${whichURL().minDate}=${min}&${
              whichURL().maxDate
            }=${max}&${whichURL().category}=${ids}`;
            console.log(res);
            props.Data(res.data, route);
            if (id === "Date") {
              allFilter.splice(index, 1);
              allFilterId.splice(index, 1);
              setFromDate(null);
              setFromEnglishDate(null);
              setEndDate(null);
              setEndEnglishDate(null);
            }
            if (id === "Category") allFilter.splice(index, 1);
            allFilterId.splice(index, 1);
          }
          forceUpdate();
        })
        .catch((e) => {
          console.log(e);
        });
    }

    if (
      Date === true &&
      Category === false &&
      Situation === true &&
      User === true &&
      Price != true
    ) {
      axios
        .get(
          `${axiosURL}?${whichURL().minDate}=${min}&${
            whichURL().maxDate
          }=${max}&${whichURL().situationStatus}=${
            id === "Situation" ? "" : situation
          }&${id != "USERID" && userID != null && whichURL().user_id}=${
            id != "USERID" && userID != null && userID
          } `
        )
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            props.Data(res.data);
            props.Url(
              `${axiosURL}?${whichURL().minDate}=${min}&${
                whichURL().maxDate
              }=${max}&${whichURL().situationStatus}=${
                id === "Situation" ? "" : situation
              }&${id != "USERID" && userID != null && whichURL().user_id}=${
                id != "USERID" && userID != null && userID
              } `
            );
            if (id === "Date") {
              allFilter.splice(index, 1);
              allFilterId.splice(index, 1);
              setFromDate(null);
              setFromEnglishDate(null);
              setEndDate(null);
              setEndEnglishDate(null);
            }
            if (id === "Situation") {
              allFilter.splice(index, 1);
              allFilterId.splice(index, 1);
            }
            if (id === "USERID") {
              setUserID(null);
              allFilter.splice(index, 1);
              allFilterId.splice(index, 1);
              dispatch(SendUserIdForFilter(null, null));
            }
            forceUpdate();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (
      Date === false &&
      Category === false &&
      Situation === true &&
      Price != true
    ) {
      axios
        .get(`${axiosURL}`)
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            props.Data(res.data);
            props.Url(`${axiosURL}`);
            if (id === "Situation") {
              allFilter.splice(index, 1);
              allFilterId.splice(index, 1);
            }
          }
          forceUpdate();
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (
      Date === true &&
      Category === false &&
      Situation === true &&
      Price === true &&
      User === true
    ) {
      let user_id = "";
      let Situation = "";
      if (id === "Date") {
        var FromDate = "";
        var EndDate = "";
      } else if (fromDate != null && endDate != null) {
        var FromDate = `${fromDate} 00:00:00`;
        var EndDate = `${endDate} 23:59:59`;
      } else if (fromDate === null && endDate === null) {
        var FromDate = "";
        var EndDate = "";
      }
      if (id === "Situation") {
        setSituation("");
      } else {
        Situation = situation;
      }
      if (id === "USERID") {
        setUserID("");
      } else {
        user_id = userID;
      }
      if (id === "PRICE") {
        var StartPrice = "";
        var EndPrice = "";
      } else if (startPrice != null && endPrice != null) {
        var StartPrice = startPrice;
        var EndPrice = endPrice;
      } else if (startPrice === null && endPrice === null) {
        var StartPrice = "";
        var EndPrice = "";
      }
      axios
        .get(
          `${axiosURL}?user_id=${user_id}&payment_type=${Situation}&order_min_price=${StartPrice}&order_max_price=${EndPrice}&order_min_registrationDate=${FromDate}&order_max_registrationDate=${EndDate}`
        )
        .then((res) => {
          if (res.data.status) {
            let route = `${axiosURL}?user_id=${user_id}&payment_type=${Situation}&order_min_price=${StartPrice}&order_max_price=${EndPrice}&order_min_registrationDate=${FromDate}&order_max_registrationDate=${EndDate}`;
            props.Data(res.data, route);
            if (id === "Date") {
              allFilter.splice(index, 1);
              allFilterId.splice(index, 1);
              setFromDate(null);
              setFromEnglishDate(null);
              setEndDate(null);
              setEndEnglishDate(null);
            }
            if (id === "Situation") {
              allFilter.splice(index, 1);
              allFilterId.splice(index, 1);
            }
            if (id === "USERID") {
              setUserID(null);
              allFilter.splice(index, 1);
              allFilterId.splice(index, 1);
              dispatch(SendUserIdForFilter(null, null));
            }
            if (id === "PRICE") {
              allFilter.splice(index, 1);
              allFilterId.splice(index, 1);
              setStartPrice(null);
              setEndPrice(null);
            }
            forceUpdate();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const hoverChooseFilter = (id) => {
    if (id === "chooseFilter") {
      let filter = document.getElementById("chooseFilter");
      filter.style.backgroundColor = "#f8f8f8";
      filter.style.color = "#3d3d3d";
    }
    if (id === "situationFilter") {
      let situationFilter = document.getElementById("situationFilter");
      situationFilter.style.backgroundColor = "#f8f8f8";
      situationFilter.style.color = "#3d3d3d";
    }
    if (id === "priceFilter") {
      let situationFilter = document.getElementById("priceFilter");
      situationFilter.style.backgroundColor = "#f8f8f8";
      situationFilter.style.color = "#3d3d3d";
    }
    if (id === "categoryFilter") {
      let situationFilter = document.getElementById("categoryFilter");
      situationFilter.style.backgroundColor = "#f8f8f8";
      situationFilter.style.color = "#3d3d3d";
    }
  };
  const notHoverChosseFilter = (id) => {
    if (id === "chooseFilter") {
      let filter = document.getElementById("chooseFilter");
      filter.style.backgroundColor = "#ffffff";
      filter.style.color = "#707070";
    }
    if (id === "situationFilter") {
      let situationFilter = document.getElementById("situationFilter");
      situationFilter.style.backgroundColor = "#ffffff";
      situationFilter.style.color = "#707070";
    }
    if (id === "categoryFilter") {
      let situationFilter = document.getElementById("categoryFilter");
      situationFilter.style.backgroundColor = "#ffffff";
      situationFilter.style.color = "#707070";
    }
    if (id === "userFilter") {
      let situationFilter = document.getElementById("userFilter");
      situationFilter.style.backgroundColor = "#ffffff";
      situationFilter.style.color = "#707070";
    }
    if (id === "priceFilter") {
      let situationFilter = document.getElementById("priceFilter");
      situationFilter.style.backgroundColor = "#ffffff";
      situationFilter.style.color = "#707070";
    }
    if (id === "categoryFilter") {
      let situationFilter = document.getElementById("categoryFilter");
      situationFilter.style.backgroundColor = "#ffffff";
      situationFilter.style.color = "#707070";
    }
  };
  // set height when filter open
  const chooseFilterClick = (name) => {
    setWitchFilter(name);
    if (name === "DATE") {
      setAllHeight("400px");
    }
    if (name === "SITUATION") {
      setAllHeight("420px");
    }
    if (name === "PRICE") {
      setAllHeight("490px");
    }
    if (name === "CATEGORY") {
      setAllHeight("490px");
      axios
        .get(CategoryAxios)
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            setAllCategoryFilter(res.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (name === "USER") {
      setAllHeight("490px");
      axios
        .get(UserAxios)
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            setAllUser(res.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    forceUpdate();
  };
  const backClick = () => {
    setWitchFilter(null);
    setShowCalender(false);
    setAllHeight(`${numberProps.count + 70}px`);
  };
  const showCalenderClick = (id) => {
    setShowCalender(id);
    setAllHeight("600px");
    forceUpdate();
  };
  const fromDateOnChange = (value) => {
    if (showCalender === "from") {
      if (fromDate === null) {
        let date = moment(value, "YYYY/MM/DD")
          .locale("fa")
          .format("YYYY/MM/DD");
        setFromDate(date);
        setFromEnglishDate(value);
        setShowCalender(false);
        setAllHeight("400px");
      } else {
        let date = moment(value, "YYYY/MM/DD")
          .locale("fa")
          .format("YYYY/MM/DD");
        setFromDate(date);
        setFromEnglishDate(value);
        setShowCalender(false);
        setAllHeight("400px");
        allFilter.map((item) => {
          var index = allFilter.indexOf(item.id === "Date");
          allFilter.splice(index, 1);
        });
        allFilterId.map((item) => {
          let index = allFilterId.indexOf(item === "Date");
          allFilterId.splice(index, 1);
        });
        forceUpdate();
      }
    }
    if (showCalender === "end") {
      if (endDate === null) {
        let date = moment(value, "YYYY/MM/DD")
          .locale("fa")
          .format("YYYY/MM/DD");
        setEndDate(date);
        setShowCalender(false);
        setEndEnglishDate(value);
        setAllHeight("400px");
      } else {
        let date = moment(value, "YYYY/MM/DD")
          .locale("fa")
          .format("YYYY/MM/DD");
        setEndDate(date);
        setShowCalender(false);
        setEndEnglishDate(value);
        setAllHeight("400px");
        allFilter.map((item) => {
          var index = allFilter.indexOf(item.id === "Date");
          allFilter.splice(index, 1);
        });
        allFilterId.map((item) => {
          let index = allFilterId.indexOf(item === "Date");
          allFilterId.splice(index, 1);
        });
        forceUpdate();
      }
    }
  };
  const situationOnChange = (id) => {
    if (situation === "") {
      setSituation(id);
    } else {
      setSituation(id);
      allFilter.map((item) => {
        var index = allFilter.indexOf(item.id === "Situation");
        allFilter.splice(index, 1);
      });
      allFilterId.map((item) => {
        let index = allFilterId.indexOf(item === "Situation");
        allFilterId.splice(index, 1);
      });
    }
  };
  const addCategoryOnChange = (id) => {
    if (!categoryIds.includes(id)) {
      categoryIds.push(id);
    } else {
      var indexof = categoryIds.indexOf(id);
      categoryIds.splice(indexof, 1);
    }
    forceUpdate();
  };
  const userOnChange = (id, name) => {
    setUserID(id);
    setUserName(name);
    forceUpdate();
  };
  const handlePriceChange = (event, newValue, activeThumb) => {
    if (activeThumb === 0) {
      setStartPrice(newValue[0] * 100000);
    } else if (activeThumb === 1) {
      setEndPrice(newValue[1] * 1000000);
    }
    forceUpdate();
  };

  return (
    <div
      style={{
        width: width != undefined ? width : "400px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "auto",
          maxHeight: `${showFilter === true ? allHeight : "50px"}`,
          border: "solid 1px #818181",
          borderRadius: "10px",
          transition: "max-height 0.3s",
        }}
      >
        <div
          onClick={() => CloseFilter()}
          style={{
            display: "flex",
            justifyContent:"space-between",
            alignItems: "center",
            width: "calc(100% - 40px)",
            height: "50px",
            cursor: "pointer",
            padding: `${
              showFilter === true ? "10px 20px 0px 20px" : "10px 20px 10px 20px"
            }`,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "auto",
              height: "100%",
              cursor: "pointer",
            }}
          >
            <img
              style={{
                width: "23px",
                height: "23px",
                cursor: "pointer",
              }}
              src={showFilterIcon}
            />
            <p
              style={{
                fontFamily: "IRANSansWebNoEn",
                color: "#121212",
                fontSize: "20px",
                cursor: "pointer",
                marginRight: "10px",
              }}
            >
              افزودن فیلتر
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "auto",
              height: "100%",
              cursor: "pointer",
            }}
          >
            <img
              onClick={() => CloseFilter()}
              src={AllProductFilterUpArrow}
              style={{
                cursor: "pointer",
                width: "23px",
                height: "23px",
                transform: `${
                  showFilter === true ? "rotate(0deg)" : "rotate(-180deg)"
                }`,
                transition: "transform 0.3s",
              }}
            />
          </div>
        </div>
        {showFilter === true && whitchFliter === null && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "auto",
              padding: "20px 0px 0px 0px",
            }}
          >
            {Date === true && (
              <div
                id={"chooseFilter"}
                onClick={() => chooseFilterClick("DATE")}
                onMouseEnter={() => hoverChooseFilter("chooseFilter")}
                onMouseLeave={() => notHoverChosseFilter("chooseFilter")}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "95%",
                  height: "50px",
                  backgroundColor: "#ffffff",
                  padding: "0px 20px 0px 0px",
                  fontFamily: "IRANSansWebNoEn",
                  color: "#707070",
                  fontSize: "20px",
                  cursor: "pointer",
                  //   marginRight:"10px"
                }}
              >
                <p
                  style={{
                    cursor: "pointer",
                  }}
                >
                  تاریخ انتشار
                </p>
              </div>
            )}
            {Situation === true && (
              <div
                id={"situationFilter"}
                onClick={() => chooseFilterClick("SITUATION")}
                onMouseEnter={() => hoverChooseFilter("situationFilter")}
                onMouseLeave={() => notHoverChosseFilter("situationFilter")}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "calc(100% - 20px)",
                  height: "50px",
                  backgroundColor: "#ffffff",
                  padding: "0px 20px 0px 0px",
                  fontFamily: "IRANSansWebNoEn",
                  color: "#707070",
                  fontSize: "20px",
                  cursor: "pointer",
                  //   marginRight:"10px"
                }}
              >
                <p
                  style={{
                    cursor: "pointer",
                  }}
                >
                  وضیعت
                </p>
              </div>
            )}
            {Price === true && (
              <div
                id={"priceFilter"}
                onClick={() => chooseFilterClick("PRICE")}
                onMouseEnter={() => hoverChooseFilter("priceFilter")}
                onMouseLeave={() => notHoverChosseFilter("priceFilter")}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "95%",
                  height: "50px",
                  backgroundColor: "#ffffff",
                  padding: "0px 20px 0px 0px",
                  fontFamily: "IRANSansWebNoEn",
                  color: "#707070",
                  fontSize: "20px",
                  cursor: "pointer",
                  //   marginRight:"10px"
                }}
              >
                <p
                  style={{
                    cursor: "pointer",
                  }}
                >
                  بازه قیمتی
                </p>
              </div>
            )}

            {Category === true && (
              <div
                id={"categoryFilter"}
                onClick={() => chooseFilterClick("CATEGORY")}
                onMouseEnter={() => hoverChooseFilter("categoryFilter")}
                onMouseLeave={() => notHoverChosseFilter("categoryFilter")}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "95%",
                  height: "50px",
                  backgroundColor: "#ffffff",
                  padding: "0px 20px 0px 0px",
                  fontFamily: "IRANSansWebNoEn",
                  color: "#707070",
                  fontSize: "20px",
                  cursor: "pointer",
                  //   marginRight:"10px"
                }}
              >
                <p
                  style={{
                    cursor: "pointer",
                  }}
                >
                  دسته بندی
                </p>
              </div>
            )}
            {User === true && (
              <div
                id={"userFilter"}
                onClick={() => chooseFilterClick("USER")}
                onMouseEnter={() => hoverChooseFilter("userFilter")}
                onMouseLeave={() => notHoverChosseFilter("userFilter")}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "95%",
                  height: "50px",
                  backgroundColor: "#ffffff",
                  padding: "0px 20px 0px 0px",
                  fontFamily: "IRANSansWebNoEn",
                  color: "#707070",
                  fontSize: "20px",
                  cursor: "pointer",
                  //   marginRight:"10px"
                }}
              >
                <p
                  style={{
                    cursor: "pointer",
                  }}
                >
                  مشتریان
                </p>
              </div>
            )}
          </div>
        )}

        {showFilter === true && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              borderRadius: "0px 0px 10px 10px",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "96%",
                height: "50px",
              }}
            >
              {whitchFliter != null && (
                <button
                  onClick={() => backClick()}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "23%",
                    height: "100%",
                    border: "none",
                    outline: "none",
                    backgroundColor: "unset",
                    cursor: "pointer",
                  }}
                >
                  <img
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    src={AllProductRightArrow}
                  />
                  <p
                    style={{
                      fontFamily: "IRANSansWebNoEn",
                      color: "#9e9e9e",
                      marginRight: "10px",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    بازگشت
                  </p>
                </button>
              )}
            </div>
            {whitchFliter === "DATE" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "90%",
                  height: "100%",
                  padding: "0px 20px 0px 20px",
                  borderRadius: "0px 0px 20px 20px",
                  backgroundColor: "#f4fffd",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: "50px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "IRANSansWebNoEn",
                      color: "#00457b",
                      fontSize: "16px",
                      fontWeight: "900",
                    }}
                  >
                    تاریخ انتشار
                  </p>
                </div>
                {showCalender === false && (
                  <>
                    <div
                      onClick={() => showCalenderClick("from")}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "93%",
                        height: "44px",
                        borderRadius: "10px",
                        padding: "0px 10px 0px 10px",
                        border: "solid 1px #dcdcdc",
                        backgroundColor: "#fff",
                        cursor: "pointer",
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "IRANSansFaNum",
                          color: "#c2c2c2",
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                      >
                        {fromDate != null ? fromDate : "از"}
                      </p>
                      <img
                        style={{ cursor: "pointer" }}
                        src={AllProductCalender}
                      />
                    </div>

                    <div
                      onClick={() => showCalenderClick("end")}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "93%",
                        height: "44px",
                        marginTop: "20px",
                        borderRadius: "10px",
                        padding: "0px 10px 0px 10px",
                        border: "solid 1px #dcdcdc",
                        backgroundColor: "#fff",
                        cursor: "pointer",
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "IRANSansFaNum",
                          color: "#c2c2c2",
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                      >
                        {endDate != null ? endDate : "تا"}
                      </p>
                      <img
                        style={{ cursor: "pointer" }}
                        src={AllProductCalender}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "120px",
                      }}
                    >
                      <button
                        onClick={() => addFilter()}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "324px",
                          height: "44px",
                          borderRadius: "10px",
                          border: "none",
                          outline: "none",
                          backgroundColor: "#00457b",
                        }}
                      >
                        <p
                          style={{
                            fontFamily: "IRANSansFaNum",
                            color: "#fff",
                            fontSize: "16px",
                          }}
                        >
                          اعمال فیلتر
                        </p>
                      </button>
                    </div>
                  </>
                )}
                {showCalender === "from" && (
                  <div>
                    <Calendar
                      max={endEnglishDate}
                      onChange={(value) => fromDateOnChange(value._d)}
                      isGregorian={false}
                    />
                  </div>
                )}
                {showCalender === "end" && (
                  <div>
                    <Calendar
                      min={fromEnglishDate}
                      onChange={(value) => fromDateOnChange(value._d)}
                      isGregorian={false}
                    />
                  </div>
                )}
              </div>
            )}
            {whitchFliter === "SITUATION" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "calc(100% - 40px)",
                  height: "100%",
                  padding: "0px 20px 0px 20px",
                  borderRadius: "0px 0px 20px 20px",
                  backgroundColor: "#f4fffd",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "20%",
                      height: "50px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "IRANSansWebNoEn",
                        color: "#00457b",
                        fontSize: "16px",
                        fontWeight: "900",
                      }}
                    >
                      وضیعت
                    </p>
                  </div>
                  {axiosURL != "/UserMessage" &&
                    axiosURL != "/Discount" &&
                    axiosURL != "/Order" && (
                      <>
                        <label htmlFor="situationAccept">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "50%",
                              height: "50px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "20%",
                                height: "100%",
                              }}
                            >
                              <input
                                style={{ display: "none" }}
                                id="situationAccept"
                                name="situation"
                                onChange={() => situationOnChange("تایید شده")}
                                type="radio"
                              />
                              <div
                                style={{
                                  display: "flex",
                                  width: `${
                                    situation === "تایید شده" ? "10px " : "15px"
                                  }`,
                                  height: `${
                                    situation === "تایید شده" ? "10px " : "15px"
                                  }`,
                                  borderRadius: "15px",
                                  border: `${
                                    situation === "تایید شده"
                                      ? "solid 4px #00589c"
                                      : "solid 1px #000000"
                                  }`,
                                }}
                              ></div>
                            </div>
                            <p
                              style={{
                                fontFamily: "IRANSansWebNoEn",
                                color: "#484848",
                                fontSize: "16px",
                              }}
                            >
                              تایید شده
                            </p>
                          </div>
                        </label>

                        <label htmlFor="situationDecline">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "50%",
                              height: "50px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "20%",
                                height: "100%",
                              }}
                            >
                              <input
                                style={{ display: "none" }}
                                id="situationDecline"
                                name="situation"
                                onChange={() => situationOnChange("رد شده")}
                                type="radio"
                              />
                              <div
                                style={{
                                  display: "flex",
                                  width: `${
                                    situation === "رد شده" ? "10px " : "15px"
                                  }`,
                                  height: `${
                                    situation === "رد شده" ? "10px " : "15px"
                                  }`,
                                  borderRadius: "15px",
                                  border: `${
                                    situation === "رد شده"
                                      ? "solid 4px #00589c"
                                      : "solid 1px #000000"
                                  }`,
                                }}
                              ></div>
                            </div>
                            <p
                              style={{
                                fontFamily: "IRANSansWebNoEn",
                                color: "#484848",
                                fontSize: "16px",
                              }}
                            >
                              رد شده
                            </p>
                          </div>
                        </label>

                        <label htmlFor="situationWaiting">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "50%",
                              height: "50px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "20%",
                                height: "100%",
                              }}
                            >
                              <input
                                style={{ display: "none" }}
                                id="situationWaiting"
                                name="situation"
                                onChange={() =>
                                  situationOnChange("در انتظار بررسی")
                                }
                                type="radio"
                              />
                              <div
                                style={{
                                  display: "flex",
                                  width: `${
                                    situation === "در انتظار بررسی"
                                      ? "10px "
                                      : "15px"
                                  }`,
                                  height: `${
                                    situation === "در انتظار بررسی"
                                      ? "10px "
                                      : "15px"
                                  }`,
                                  borderRadius: "15px",
                                  border: `${
                                    situation === "در انتظار بررسی"
                                      ? "solid 4px #00589c"
                                      : "solid 1px #000000"
                                  }`,
                                }}
                              ></div>
                            </div>
                            <p
                              style={{
                                fontFamily: "IRANSansWebNoEn",
                                color: "#484848",
                                fontSize: "16px",
                              }}
                            >
                              در انتظار بررسی
                            </p>
                          </div>
                        </label>
                      </>
                    )}
                  {axiosURL === "/UserMessage" && (
                    <>
                      <label htmlFor="waitForAnswer">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "50%",
                            height: "50px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "20%",
                              height: "100%",
                            }}
                          >
                            <input
                              style={{ display: "none" }}
                              id="waitForAnswer"
                              name="situation"
                              onChange={() =>
                                situationOnChange("در انتظار پاسخ")
                              }
                              type="radio"
                            />
                            <div
                              style={{
                                display: "flex",
                                width: `${
                                  situation === "در انتظار پاسخ"
                                    ? "10px "
                                    : "15px"
                                }`,
                                height: `${
                                  situation === "در انتظار پاسخ"
                                    ? "10px "
                                    : "15px"
                                }`,
                                borderRadius: "15px",
                                border: `${
                                  situation === "در انتظار پاسخ"
                                    ? "solid 4px #00589c"
                                    : "solid 1px #000000"
                                }`,
                              }}
                            ></div>
                          </div>
                          <p
                            style={{
                              fontFamily: "IRANSansWebNoEn",
                              color: "#484848",
                              fontSize: "16px",
                            }}
                          >
                            در انتظار پاسخ
                          </p>
                        </div>
                      </label>
                      <label htmlFor="Answer">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "50%",
                            height: "50px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "20%",
                              height: "100%",
                            }}
                          >
                            <input
                              style={{ display: "none" }}
                              id="Answer"
                              name="situation"
                              onChange={() => situationOnChange("پاسخ داده")}
                              type="radio"
                            />
                            <div
                              style={{
                                display: "flex",
                                width: `${
                                  situation === "پاسخ داده" ? "10px " : "15px"
                                }`,
                                height: `${
                                  situation === "پاسخ داده" ? "10px " : "15px"
                                }`,
                                borderRadius: "15px",
                                border: `${
                                  situation === "پاسخ داده"
                                    ? "solid 4px #00589c"
                                    : "solid 1px #000000"
                                }`,
                              }}
                            ></div>
                          </div>
                          <p
                            style={{
                              fontFamily: "IRANSansWebNoEn",
                              color: "#484848",
                              fontSize: "16px",
                            }}
                          >
                            پاسخ داده
                          </p>
                        </div>
                      </label>
                    </>
                  )}

                  {axiosURL === "/Discount" && (
                    <>
                      <label htmlFor="shared">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "50%",
                            height: "50px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "20%",
                              height: "100%",
                            }}
                          >
                            <input
                              style={{ display: "none" }}
                              id="shared"
                              name="situation"
                              onChange={() => situationOnChange(1)}
                              type="radio"
                            />
                            <div
                              style={{
                                display: "flex",
                                width: `${situation === 1 ? "10px " : "15px"}`,
                                height: `${situation === 1 ? "10px " : "15px"}`,
                                borderRadius: "15px",
                                border: `${
                                  situation === 1
                                    ? "solid 4px #00589c"
                                    : "solid 1px #000000"
                                }`,
                              }}
                            ></div>
                          </div>
                          <p
                            style={{
                              fontFamily: "IRANSansWebNoEn",
                              color: "#484848",
                              fontSize: "16px",
                            }}
                          >
                            منتشر شده
                          </p>
                        </div>
                      </label>
                      <label htmlFor="Drafts">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "50%",
                            height: "50px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "20%",
                              height: "100%",
                            }}
                          >
                            <input
                              style={{ display: "none" }}
                              id="Drafts"
                              name="situation"
                              onChange={() => situationOnChange(0)}
                              type="radio"
                            />
                            <div
                              style={{
                                display: "flex",
                                width: `${situation === 0 ? "10px " : "15px"}`,
                                height: `${situation === 0 ? "10px " : "15px"}`,
                                borderRadius: "15px",
                                border: `${
                                  situation === 0
                                    ? "solid 4px #00589c"
                                    : "solid 1px #000000"
                                }`,
                              }}
                            ></div>
                          </div>
                          <p
                            style={{
                              fontFamily: "IRANSansWebNoEn",
                              color: "#484848",
                              fontSize: "16px",
                            }}
                          >
                            پیش نویس
                          </p>
                        </div>
                      </label>
                    </>
                  )}
                  {/* //////////////////////// */}
                  {axiosURL === "/Order" && (
                    <>
                      <label htmlFor="Online">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "50%",
                            height: "50px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "20%",
                              height: "100%",
                            }}
                          >
                            <input
                              style={{ display: "none" }}
                              id="Online"
                              name="situation"
                              onChange={() => situationOnChange("آنلاین")}
                              type="radio"
                            />
                            <div
                              style={{
                                display: "flex",
                                width: `${
                                  situation === "آنلاین" ? "10px " : "15px"
                                }`,
                                height: `${
                                  situation === "آنلاین" ? "10px " : "15px"
                                }`,
                                borderRadius: "15px",
                                border: `${
                                  situation === "آنلاین"
                                    ? "solid 4px #00589c"
                                    : "solid 1px #000000"
                                }`,
                              }}
                            ></div>
                          </div>
                          <p
                            style={{
                              fontFamily: "IRANSansWebNoEn",
                              color: "#484848",
                              fontSize: "16px",
                            }}
                          >
                            آنلاین
                          </p>
                        </div>
                      </label>
                      <label htmlFor="InLocation">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "50%",
                            height: "50px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "20%",
                              height: "100%",
                            }}
                          >
                            <input
                              style={{ display: "none" }}
                              id="InLocation"
                              name="situation"
                              onChange={() =>
                                situationOnChange("پرداخت در محل")
                              }
                              type="radio"
                            />
                            <div
                              style={{
                                display: "flex",
                                width: `${
                                  situation === "پرداخت در محل"
                                    ? "10px "
                                    : "15px"
                                }`,
                                height: `${
                                  situation === "پرداخت در محل"
                                    ? "10px "
                                    : "15px"
                                }`,
                                borderRadius: "15px",
                                border: `${
                                  situation === "پرداخت در محل"
                                    ? "solid 4px #00589c"
                                    : "solid 1px #000000"
                                }`,
                              }}
                            ></div>
                          </div>
                          <p
                            style={{
                              fontFamily: "IRANSansWebNoEn",
                              color: "#484848",
                              fontSize: "16px",
                            }}
                          >
                            پرداخت در محل
                          </p>
                        </div>
                      </label>
                      <label htmlFor="CartToCart">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "50%",
                            height: "50px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "20%",
                              height: "100%",
                            }}
                          >
                            <input
                              style={{ display: "none" }}
                              id="CartToCart"
                              name="situation"
                              onChange={() => situationOnChange("کارت به کارت")}
                              type="radio"
                            />
                            <div
                              style={{
                                display: "flex",
                                width: `${
                                  situation === "کارت به کارت"
                                    ? "10px "
                                    : "15px"
                                }`,
                                height: `${
                                  situation === "کارت به کارت"
                                    ? "10px "
                                    : "15px"
                                }`,
                                borderRadius: "15px",
                                border: `${
                                  situation === "کارت به کارت"
                                    ? "solid 4px #00589c"
                                    : "solid 1px #000000"
                                }`,
                              }}
                            ></div>
                          </div>
                          <p
                            style={{
                              fontFamily: "IRANSansWebNoEn",
                              color: "#484848",
                              fontSize: "16px",
                            }}
                          >
                            کارت به کارت
                          </p>
                        </div>
                      </label>
                    </>
                  )}
                  {/* ////////////////////////////// */}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "120px",
                    }}
                  >
                    <button
                      onClick={() => addFilter()}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "324px",
                        height: "44px",
                        borderRadius: "10px",
                        border: "none",
                        outline: "none",
                        backgroundColor: "#00457b",
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "IRANSansFaNum",
                          color: "#fff",
                          fontSize: "16px",
                        }}
                      >
                        اعمال فیلتر
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            )}
            {whitchFliter === "USER" && (
              <div
                style={{
                  display: "flex",
                  display: "flex",
                  flexDirection: "column",
                  width: "90%",
                  height: "400px",
                  padding: "0px 20px 0px 20px",
                  borderRadius: "0px 0px 20px 20px",
                  backgroundColor: "#f4fffd",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "20%",
                    height: "50px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "IRANSansWebNoEn",
                      color: "#00457b",
                      fontSize: "16px",
                      fontWeight: "900",
                    }}
                  >
                    مشتریان
                  </p>
                </div>
                <div
                  className="filter-component-scrollbar"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "228px",
                    overflowY: "scroll",
                  }}
                >
                  {allUser.map((item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100",
                          height: "50px",
                          marginBottom: "15px",
                        }}
                      >
                        <label
                          htmlFor={`userRadio${index}`}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            cursor: "pointer",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "10%",
                              height: "100%",
                              cursor: "pointer",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: `${
                                  userID === item.id ? "10px" : "15px"
                                }`,

                                height: `${
                                  userID === item.id ? "10px" : "15px"
                                }`,
                                borderRadius: "15px",
                                border: `${
                                  userID === item.id
                                    ? "solid 4px #00589c"
                                    : "solid 1px #000000"
                                }`,
                                cursor: "pointer",
                              }}
                            ></div>
                            <input
                              style={{
                                display: "none",
                              }}
                              name="userRadio"
                              id={`userRadio${index}`}
                              onChange={() =>
                                userOnChange(item.id, item.fullName)
                              }
                              type="radio"
                            />
                          </div>
                          <p
                            style={{
                              fontFamily: "IRANSansFaNum",
                              color: "#484848",
                              fontSize: "16px",
                              cursor: "pointer",
                            }}
                          >
                            {item.fullName}
                          </p>
                        </label>
                      </div>
                    );
                  })}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "120px",
                  }}
                >
                  <button
                    onClick={() => addFilter()}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "324px",
                      height: "44px",
                      borderRadius: "10px",
                      border: "none",
                      outline: "none",
                      backgroundColor: "#00457b",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "IRANSansFaNum",
                        color: "#fff",
                        fontSize: "16px",
                      }}
                    >
                      اعمال فیلتر
                    </p>
                  </button>
                </div>
              </div>
            )}

            {/* //////////////////////////// */}
            {whitchFliter === "PRICE" && (
              <div
                style={{
                  display: "flex",
                  display: "flex",
                  flexDirection: "column",
                  width: "90%",
                  height: "260px",
                  padding: "0px 20px 0px 20px",
                  borderRadius: "0px 0px 20px 20px",
                  backgroundColor: "#f4fffd",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "20%",
                    height: "50px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "IRANSansWebNoEn",
                      color: "#00457b",
                      fontSize: "16px",
                      fontWeight: "900",
                    }}
                  >
                    بازه قیمتی
                  </p>
                </div>
                <div
                  className="filter-component-scrollbar"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    height: "calc(228px - 13px)",
                    padding: "13px 0px 0px 0px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "IRANSansWebNoEn",
                      color: "#9a9a9a",
                      fontSize: "14px",
                    }}
                  >
                    قیمت ها به تومان است
                  </p>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      zoom: "1.25",
                    }}
                  >
                    <AirbnbSlider
                      style={{ width: "95%" }}
                      // disabled={
                      //   this.state.disabledPrice === false ? false : true
                      // }
                      onChange={handlePriceChange}
                      getAriaLabel={(index) =>
                        index === 0 ? "Minimum price" : "Maximum price"
                      }
                      defaultValue={[0, 100]}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      height: "auto",
                      padding: "10px 0px 0px 0px",
                    }}
                  >
                    <InputComponent
                      // margin="0 25px 0 0"
                      value={startPrice}
                      width="calc(45% - 20px)"
                      height="44px"
                      type="number"
                      placeholder="از"
                      borderColor="#dcdcdc"
                      inputType="input"
                      onchange={(e) => setStartPrice(e.target.value)}
                    />
                    <InputComponent
                      // margin="0 25px 0 0"
                      value={endPrice}
                      width="calc(45% - 20px)"
                      height="44px"
                      type="number"
                      placeholder="تا"
                      borderColor="#dcdcdc"
                      inputType="input"
                      onchange={(e) => setEndPrice(e.target.value)}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "120px",
                  }}
                >
                  <button
                    onClick={() => addFilter()}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "324px",
                      height: "44px",
                      borderRadius: "10px",
                      border: "none",
                      outline: "none",
                      backgroundColor: "#00457b",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "IRANSansFaNum",
                        color: "#fff",
                        fontSize: "16px",
                      }}
                    >
                      اعمال فیلتر
                    </p>
                  </button>
                </div>
              </div>
            )}

            {/* /////////////////////////////// */}
            {whitchFliter === "CATEGORY" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "90%",
                  height: "400px",
                  padding: "0px 20px 0px 20px",
                  borderRadius: "0px 0px 20px 20px",
                  backgroundColor: "#f4fffd",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "20%",
                    height: "50px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "IRANSansWebNoEn",
                      color: "#00457b",
                      fontSize: "16px",
                      fontWeight: "900",
                    }}
                  >
                    دسته بندی
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "228px",
                  }}
                >
                  <div
                    className="filter-component-scrollbar"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: "228px",
                      overflowY: "scroll",
                    }}
                  >
                    {allCategoryFilter.map((item, index) => {
                      return (
                        <label key={index} htmlFor={`category${index}`}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "23%",
                              height: "50px",
                            }}
                          >
                            <input
                              style={{ display: "none" }}
                              type="checkbox"
                              id={`category${index}`}
                              name={`category${index}`}
                              onChange={() => addCategoryOnChange(item.id)}
                            />
                            <img
                              src={
                                categoryIds.includes(item.id) ? check : uncheck
                              }
                            />
                            <p
                              style={{
                                fontFamily: "IRANSansFaNum",
                                color: "#484848",
                                fontSize: "16px",
                              }}
                            >
                              {item.title}
                            </p>
                          </div>
                        </label>
                      );
                    })}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "120px",
                  }}
                >
                  <button
                    onClick={() => addFilter()}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "324px",
                      height: "44px",
                      borderRadius: "10px",
                      border: "none",
                      outline: "none",
                      backgroundColor: "#00457b",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "IRANSansFaNum",
                        color: "#fff",
                        fontSize: "16px",
                      }}
                    >
                      اعمال فیلتر
                    </p>
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {allFilter.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "95%",
                height: "50px",
                borderRadius: "10px",
                border: "solid 1px #818181",
                marginTop: "10px",
                padding: "0px 10px 0px 10px",
              }}
            >
              <p
                style={{
                  fontFamily: "IRANSansFaNum",
                  color: "#818181",
                  fontSize: "16px",
                }}
              >
                {item.title}
              </p>
              <img
                style={{ cursor: "pointer" }}
                onClick={() => delteFilter(item.id, index)}
                src={AllProductDeleteFilter}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
