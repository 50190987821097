import React, { useEffect } from "react";
import CreateDiscount from "./CreateDiscount";
import { useSelector, useDispatch } from "react-redux";
import { isObjectEmpty } from "../../../helper/Helper";
import { ResetToInit } from "../../../action/index"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";

export default function UpdateDiscount() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const coupenData = useSelector((state) => state.SendCoupenForEdit);

  // when redux is empty navigate too manageDiscount and reset to init redux
  useEffect(() => {
    if (isObjectEmpty(coupenData) === true) {
      navigate("/ManageDiscount");
      dispatch(ResetToInit())
    }
  }, []);
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "auto",
      }}
    >
      <CreateDiscount />
    </div>
  );
}
