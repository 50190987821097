import React, { useState, useContext, useEffect, useRef } from "react";
import "../../assets/styles/AddPropertyStyle/AddProperty.css";
import DropDownComponent from "../DropDown/DropDown";
import Delete from "../../assets/icons/Delete.svg";
import DeleteRed from "../../assets/icons/DeleteRed.svg";
import axios from "../../js/Axios/Axios";
import useForceUpdate from "use-force-update";
import { useSelector, useDispatch } from "react-redux";
import { SendPropertyValueId } from "../../action/index";
import { SendToggleIsKey } from "../../action/index";
import { DeletPropertyValueId } from "../../action/index";
import { SendProperyName } from "../../action/index";
import { isObjectEmpty } from "../../helper/Helper";
import { useLocation } from "react-router-dom";

function AddProperty() {
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const location = useLocation();
  const ProductInfoForEdit = useSelector((state) => state.ProductInfoForEdit);
  const [allProperty, setAllProperty] = useState([]); // all property
  const [propertyValueSearch, setPropertyValueSearch] = useState("");
  const [valueId, setValueId] = useState([]);
  const [property, setProperty] = useState("");
  const [show, setShow] = useState();
  const [propertyShow, setPropertyShow] = useState([]);
  const [searchProperty, setSearchProperty] = useState("");

  useEffect(() => {
    axios.get("/property?isPaginated=0").then((res) => {
      console.log(res);
      setAllProperty(res.data.data);
      forceUpdate();
    });
  }, []);

  const turnOnSwitch = (myindex, is_key) => {
    propertyShow[myindex].is_key = !propertyShow[myindex].is_key;
    dispatch(SendToggleIsKey(myindex, propertyShow[myindex].is_key));

    forceUpdate();
  };
  const deletePropertyClick = (myindex) => {
    propertyShow.splice(myindex, 1);
    dispatch(DeletPropertyValueId(propertyShow));
    forceUpdate();
  };
  // add value of property and to arrray & redux
  const addValueClick = (value, myindex, id) => {
    console.log(value, myindex, id);
    propertyShow[myindex].valuetittle = value;
    propertyShow[myindex].property_value_id = id;
    setPropertyValueSearch("");
    setShow(false);
    setTimeout(() => {
      setShow(true);
    }, 100);
    dispatch(SendPropertyValueId(myindex, id, value));
    forceUpdate();
  };

  const handelSearchProperty = (e) => {
    setSearchProperty(e.target.value);
    setProperty(e.target.value);
  };

  const handelSearchValue = (e, myindex) => {
    propertyShow[myindex].valuetittle = e.target.value;
    setPropertyValueSearch(e.target.value);
    forceUpdate();
  };

  const addPropertyToShow = (key, id) => {
    setValueId(id);
    setProperty(key);
    setShow(false);
    setTimeout(() => {
      setShow(true);
    }, 100);
    forceUpdate();
  };

  // add property to array & redux
  const addpropertyClick = () => {
    if (allProperty.some((prop) => prop.key === property)) {
      propertyShow.push({
        key: property,
        showvaluedropdown: false,
        valueid: valueId,
        is_key: false,
        valuetittle: "",
        property_value_id: null,
      });
      dispatch(SendProperyName(propertyShow));
      setProperty("");
      setSearchProperty("");
      forceUpdate();
    } else {
      axios
        .post("/property", { key: property })
        .then((res) => {
          propertyShow.push({
            key: res.data.data.key,
            showvaluedropdown: false,
            valueid: res.data.data.id,
            is_key: false,
            valuetittle: "",
            property_value_id: null,
          });
          dispatch(SendProperyName(propertyShow));
          setProperty("");
          setSearchProperty("");
          setShow(false);
          setTimeout(() => {
            setShow(true);
          }, 100);
          forceUpdate();
          axios.get("/property?isPaginated=0").then((res) => {
            console.log(res);
            setAllProperty(res.data.data);
            forceUpdate();
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  // TODO get data from axios and send data to redux
  useEffect(() => {
    if (
      !isObjectEmpty(ProductInfoForEdit) &&
      (location.pathname === "/AllProductUpdate" ||
        location.pathname === "/AllDraftProductUpdate")
    ) {
      ProductInfoForEdit.state.productProperties.map((item, myindex) => {
        propertyShow.push({
          key: item.propertyValue.property.key,
          showvaluedropdown: false,
          valueid: item.propertyValue.property.id,
          is_key: item.is_key === 1 ? true : false,
          valuetittle: item.propertyValue.value,
          property_value_id: item.propertyValue.id,
        });
        dispatch(SendProperyName(propertyShow));
        dispatch(
          SendPropertyValueId(
            myindex,
            item.propertyValue.id,
            item.propertyValue.value
          )
        );
        dispatch(SendToggleIsKey(myindex, item.is_key === 1 ? true : false));
      });
      forceUpdate();
    }
  }, [ProductInfoForEdit]);

  //   property_id: 198
  // title: ""
  // value: "llll"

  const addValueEnter = (myindex, valueid) => {
    // var propValue = document.getElementById(`propValue${myindex}`);
    // propValue.addEventListener("keydown", function (e) {
    // if (e.code === "Enter") {
    allProperty.map((item) => {
      if (item.id === valueid) {
        if (
          !item.property_values.some(
            (value) => value.value === propertyShow[myindex].valuetittle
          )
        ) {
          axios
            .post("/propertyValue", {
              property_id: valueid,
              value: propertyShow[myindex].valuetittle,
            })
            .then((res) => {
              console.log(res);
              propertyShow[myindex].valuetittle = res.data.data.value;
              propertyShow[myindex].property_value_id = res.data.data.id;
              setPropertyValueSearch("");
              setShow(false);
              setTimeout(() => {
                setShow(true);
              }, 100);
              dispatch(
                SendPropertyValueId(
                  myindex,
                  res.data.data.id,
                  res.data.data.value
                )
              );
              forceUpdate();
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
    });
    // }
    // })
  };
  // const addpropertyEntre = (e) => {
  //   if (e.key  === "Enter") {
  //     addpropertyClick()
  //   }
  // }
  const addPropertyEnter = () => {
    if (allProperty.some((prop) => prop.key === property)) {
      propertyShow.push({
        key: property,
        showvaluedropdown: false,
        valueid: valueId,
        is_key: false,
        valuetittle: "",
        property_value_id: null,
      });
      dispatch(SendProperyName(propertyShow));
      setProperty("");
      setSearchProperty("");
      forceUpdate();
    } else {
      axios
        .post("/property", { key: property })
        .then((res) => {
          propertyShow.push({
            key: res.data.data.key,
            showvaluedropdown: false,
            valueid: res.data.data.id,
            is_key: false,
            valuetittle: "",
            property_value_id: null,
          });
          dispatch(SendProperyName(propertyShow));
          setProperty("");
          setSearchProperty("");
          setShow(false);
          setTimeout(() => {
            setShow(true);
          }, 100);
          forceUpdate();
          axios.get("/property?isPaginated=0").then((res) => {
            console.log(res);
            setAllProperty(res.data.data);
            forceUpdate();
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <div className="MahsoolLeftBox2">
      <div className="MahsoolLeftBox2tittleBox">
        <p className="MahsoolLeftBox2tittle">افزودن ویژگی</p>
      </div>
      <div className="MahsoolLeftBox2DropDown">
        <div className="AddPropertyDropDownBox">
          <DropDownComponent
            id={"mainDropDown"}
            value={property != "" ? property : searchProperty}
            width="calc(100% - 15px)"
            height="44px"
            dropDownHeight="300px"
            zindex="1"
            show={show}
            enterClick={() => addPropertyEnter()}
            search={searchProperty}
            onchange={(e) => handelSearchProperty(e)}
            itemClick={(key, id) => addPropertyToShow(key, id)}
            axiosurl={"/property?isPaginated=0"}
            axiosname="Propertydata"
            placeholder="ویژگی جدید را انتخاب یا وارد کنید"
          />
        </div>
        <div className="AddPropertyButtonBox">
          <button
            // onKeyDown={(e) => addpropertyEntre(e)}
            disabled={property != "" ? false : true}
            onClick={() => addpropertyClick()}
            className={
              property != "" ? "AddPropertyButton" : "AddPropertyButtonDisabled"
            }
          >
            <p className="AddPropertyButtonTittle">افزودن ویژگی</p>
          </button>
        </div>
      </div>
      <div className="PropertyBar">
        <div className="PropertyBarTittlebox3">
          <p className="PropertyBarTittle">نام</p>
        </div>
        <div className="PropertyBarTittlebox2">
          <p className="PropertyBarTittle">مقدار</p>
        </div>
        <div className="PropertyBarTittlebox1">
          <p className="PropertyBarTittle">ویژگی کلیدی</p>
        </div>
      </div>

      <div className="ProductPropertyBox">
        {propertyShow.map((myitem, myindex) => (
          <div key={myindex} className="PropertyBox2">
            <div className="ProductPropertyKeyBox">
              <p className="ProductPropertyKeyTittlekey">{myitem.key}</p>
            </div>
            <div className="DropDownBoxProperty">
              <DropDownComponent
                id={`propValue${myindex}`}
                value={propertyShow[myindex].valuetittle}
                width="92%"
                height="44px"
                dropDownHeight="200px"
                zindex="1"
                show={show}
                search={propertyValueSearch}
                enterClick={() => addValueEnter(myindex, myitem.valueid)}
                onchange={(e) => handelSearchValue(e, myindex)}
                itemClick={(value, id) => addValueClick(value, myindex, id)}
                axiosurl={`/property/${propertyShow[myindex].valueid}`}
                axiosname="PropertyValuedata"
                placeholder="مقدار را انتخاب کنید"
              />
            </div>
            {/* <button
              onClick={() => addValueEnter(myindex, myitem.valueid)}
            ></button> */}
            <div className="ProductPropertySWitchBox">
              <div
                onClick={() =>
                  turnOnSwitch(myindex, propertyShow[myindex].is_key)
                }
                className={
                  propertyShow[myindex].is_key === false
                    ? "ProductPropertySWitchBoxBody"
                    : "ProductPropertySWitchBoxBodyActive"
                }
              >
                <div
                  className={
                    propertyShow[myindex].is_key === false
                      ? "ProductPropertySWitch"
                      : "ProductPropertySWitchActive"
                  }
                ></div>
              </div>
            </div>

            <button className="PropertyBox2IconBox">
              <img
                onClick={() => deletePropertyClick(myindex)}
                src={Delete}
                onMouseOver={(e) => (e.currentTarget.src = DeleteRed)}
                onMouseOut={(e) => (e.currentTarget.src = Delete)}
              />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AddProperty;
