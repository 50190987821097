const inistate= {
    
    inistatePriceid :"",
    inistateProductid :""
}
const UpdateAllProductIdsReducer = (state = inistate , action) =>{
    switch(action.type){
      
            case "UPDATE_ALL_PRODUCT_PRODUCT_ID":
        
                state.inistateProductid = action.ProductId
            
            return state
        default: 
        return state
    }
}
export default UpdateAllProductIdsReducer;