import React, { useEffect, useState } from "react";
// import "../../assets/styles/SwitchButtonStyle/SwitchButton.css"

export default function SwitchButton(props) {
  const { width, height, on } = props; // on = true or fals from parent
  const [turnOn, setTurnOn] = useState(false);

  const SwitchClick = () => {
    if (turnOn == false) {
      setTurnOn(true);
    } else if (turnOn == true) {
      setTurnOn(false);
    }
  };
  useEffect(() => {
    props.Value(turnOn);
  }, [turnOn]);
  useEffect(() => {
    if (on === 1) {
      setTurnOn(true);
    } else if (on === 0) {
      setTurnOn(false);
    }
  }, [on]);

  return (
    <div className="SwitchAll">
      <button
        onClick={() => SwitchClick()}
        style={{
          display: "flex",
          position: "relative",
          alignItems: "center",
          justifyContent: "flex-end",
          width: `${width}`,
          height: `${height}`,
          border: "none",
          //   padding: "0px 3px 0px 3px",
          borderRadius: "20px",
          transition: "all 0.3s",
          cursor: "pointer",
          backgroundColor: `${turnOn === true ? "#00589C" : "#C7C7C7"}`,
        }}
      >
        <div
          style={{
            display: "flex",
            position: "absolute",
            transform: `${
              turnOn === true ? "translateX(120%)" : "translateX(-10%)"
            }`,
            transition: "transform 0.3s",
            width: "18px",
            height: "18px",
            borderRadius: "15px",
            cursor: "pointer",
            boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
            backgroundColor: "#FFFFFF",
          }}
        ></div>
      </button>
    </div>
  );
}
