import React, { useState } from "react";
import "../../../assets/styles/RegisterStyle/Register.css";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import useForceUpdate from "use-force-update";
import axios from "../../../js/Axios/Axios";
import { useSelector, useDispatch } from "react-redux";
import { AddAdminInfo } from "../../../action/index";
import { ShowAlert } from "../../../action/index";
import iconProfile from "../../../assets/icons/Iconly-Bold-Profile.svg";
import lock from "../../../assets/icons/Lock-login.svg";
import CircularProgress from "@mui/material/CircularProgress";

// import axios from "axios";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";

function Register() {
  const dispatch = useDispatch();
  const forcUpdate = useForceUpdate()
  const user_info = useSelector((state) => state.AddAdminInfo);
  const navigate = useNavigate();
  const [ShowPassword, setPassWord] = useState(true);
  const [AdminInfo, setAdminInfo] = useState({
    name: "",
    family: "",
    phone_number: user_info.user_info,
    password: "",
  });
  const [activityIndicator , setActivityIndicator] = useState(false)
  const shopassword = () => {
    setPassWord(!ShowPassword);
    forcUpdate();
    console.log(ShowPassword);
  };
  const admininfo = () => {
    // navigate("/ChooseCategory");
    setActivityIndicator(true)
    forcUpdate()
    axios
      .post("/UserSystem/register/userSystemInfo", AdminInfo)
      .then((res) => {
        console.log(res);
        if (res.data.status === 0) {
          dispatch(ShowAlert(true, res.data.message));
          setActivityIndicator(false)
          forcUpdate()
        }
        if (res.data.status) {
          dispatch(AddAdminInfo(AdminInfo));
          dispatch(ShowAlert(true, res.data.message));
          navigate("/ChooseCategory");
          setActivityIndicator(false)
          forcUpdate()
        }
      })
      .catch((e) => {
        console.log(e);
        setActivityIndicator(false)
        forcUpdate()
      });
  };
  const admininfoEnter = (e) => {
    if (e.key === "Enter") {
      admininfo();
    }
  };

  return (
    <div className="RegisterAll">
      <div className="aut-img">{/* <img  src={login_img}/> */}</div>
      <div className="Verify-Register-All-2">
        <div className="Verify-Register-All-3">
          <div className="Verify-Register-All-4">
            <div className="Verify-Register-All-3-border">
              <div className="Verify-Register-All-3-circle">
                <p>1</p>
              </div>
            </div>
            <hr className="Verify-Register-All-3-line-blue" />
            <div className="Verify-Register-All-3-border">
              <div className="Verify-Register-All-3-circle">
                <p>2</p>
              </div>
            </div>
            <hr className="Verify-Register-All-3-line" />
            {/* <div className="Verify-Register-All-3-border-gray"> */}
            <div className="Verify-Register-All-3-circle-gray">
              <p>3</p>
            </div>
            {/* </div> */}
          </div>
          <div className="Verify-Register-All-5">
            <p>تایید هویت</p>
            <p>اطلاعات حساب</p>
            <p>اطلاعات فروشگاه</p>
          </div>
        </div>
        <div className="RegisterBox">
          <h3 className="RegisterFirstTittleBox">ایجاد فروشگاه</h3>

          <p className="RegisterSecondTittle">
            فقط یک قدم تا ایجاد فروشگاه فاصله دارید
          </p>
          <hr className="RegisterEdge" />

          <div className="RegisterInputBox">
            <div className="RegisterInputBoxName">
              <img src={iconProfile} className="RegisterInputNameIcon" alt="" />

              <input
                onChange={(e) =>
                  setAdminInfo((prev) => {
                    return {
                      phone_number: prev.phone_number,
                      name: e.target.value,
                      family: prev.family,
                      password: prev.password,
                    };
                  })
                }
                className="RegisterNameInput"
                placeholder="نام "
              />
            </div>
            <div className="RegisterInputBoxName">
              <img src={iconProfile} className="RegisterInputNameIcon" alt="" />
              <input
                onChange={(e) =>
                  setAdminInfo((prev) => {
                    return {
                      phone_number: prev.phone_number,
                      name: prev.name,
                      family: e.target.value,
                      password: prev.password,
                    };
                  })
                }
                className="RegisterNameInput"
                placeholder="نام خانوادگی"
              />
            </div>

            <div className="RegisterInputBoxPassword">
              <img src={lock} />
              <input
                onChange={(e) =>
                  setAdminInfo((prev) => {
                    return {
                      phone_number: prev.phone_number,
                      name: prev.name,
                      family: prev.family,
                      password: e.target.value,
                    };
                  })
                }
                type={ShowPassword ? "password" : "text"}
                className="RegisterInput"
                placeholder="رمز عبور"
              />
              <button
                onClick={shopassword}
                className="RegisterInputPasswordIconButton"
              >
                {!ShowPassword ? (
                  <VisibilityIcon className="RegisterInputPasswordIcon" />
                ) : (
                  <VisibilityOffIcon className="RegisterInputPasswordIcon" />
                )}
              </button>
            </div>
          </div>

          <div className="RegisterButtonsBox">
            {/* <button onClick={() => navigate("/VerifyCode")}
            className="RegisterCancleButton">
            بازگشت
          </button> */}

            <button
              onKeyDown={(e) => admininfoEnter(e)}
              onClick={admininfo}
              className="RegisterRegisterButton"
            >
              {activityIndicator === false ? (
                "‌بعدی"
              ) : (
                <CircularProgress className="aut-button-progress" />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Register;
