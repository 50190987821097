 import { UPDATE_TOKEN } from "../action";

 const iniState ={
     token : '' ,
     user_info : {
         name : '',
         family: '',
         phone_number: "",
         password: "",
     }
 }
const AddAdminInfoReducer = (state = {} , action) =>{
    switch(action.type){
        case "ADD_ADDMIN_INFO":
        return {...iniState, user_info :  action.payload}
        case UPDATE_TOKEN : 
        return{
            ...iniState ,
            token : action.token
        }
        default: 
        return state
    }
}
export default AddAdminInfoReducer;