import React, { useEffect } from "react";
import "../../assets/styles/VisitorStatisticsStyle/VisitorStatisticsDevices.css";
import { convertObjToArray } from "../../helper/Helper";
import axios from "../../js/Axios/Axios";
import {
  VictoryPie,
  VictoryTheme,
  VictoryLabel,
  VictoryTooltip,
} from "victory";
import { useState } from "react";
import useForceUpdate from "use-force-update";
export default function VisitorStatisticsDevices() {
  const forceUpdate = useForceUpdate();
  const [data, setData] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const [witchData, setWitchData] = useState(null);
  useEffect(() => {
    axios
      .get("/visitInfo/devicesReport")
      .then((res) => {
        console.log(res);
        let array = convertObjToArray(res.data.data);
        setData(convertObjToArray(res.data.data));
        dataChart.map((item, index) => {
          dataChart.splice(index);
        });
        array.map((item) => {
          dataChart.push({
            x: item.key,
            y: item.value.percentage_visit_device,
          });
        });
        forceUpdate();
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div className="visitor-statistics-devices">
      <div className="visitor-statistics-devices-detail">
        {data.map((item, index) => {
          return (
            <div key={index} className="visitor-statistics-devices-detail-2">
              <p>{item.key === "Computer" && "کامپیوتر"}</p>
              <p>{item.key === "Mobile" && "موبایل"}</p>
              <p>{item.key === "Tablet" && "تبلت"}</p>

              <div className="visitor-statistics-devices-detail-count">
                {item.key === "Mobile" && (
                  <div className="visitor-statistics-devices-detail-count-color"></div>
                )}
                {item.key === "Computer" && (
                  <div className="visitor-statistics-devices-detail-count-color-2"></div>
                )}
                {item.key === "Tablet" && (
                  <div className="visitor-statistics-devices-detail-count-color-3"></div>
                )}
                <p>{item.value.visit_count_device} مشتری</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="visitor-statistics-devices-pie-chart">
        {data.length != 0 && (
          <VictoryPie
            padAngle={0}
            labels={(props) => props.datum.y === 0 ? "" : [`% ${props.datum.y}`]}
            style={{
              labels: {
                fill: "#fff",
                fontSize: 12,
                fontWeight: "500",
                fontFamily: "IRANSansFaNum",
              },
            }}
            labelComponent={<VictoryLabel dy={-0} textAnchor="middle" />}
            innerRadius={90}
            width={200}
            height={200}
            data={dataChart}
            // data={[
            //   { x: [data[0].key], y: data[0].value.percentage_visit_device },
            //   { x: [data[1].key], y: data[1].value.percentage_visit_device },
            //   { x: [data[2].key], y: data[2].value.percentage_visit_device },
            // ]}
            colorScale={["#00d3ff", "#8de2f3", "#2d99ff"]}
            events={[
              {
                target: "data",
                eventHandlers: {
                  onMouseOver: () => {
                    return [
                      {
                        mutation: (props) => {
                          console.log(props);
                          setWitchData(data[props.index]);
                        },
                      },
                    ];
                  },
                  onMouseOut: () => {
                    return [
                      {
                        mutation: () => {
                          setWitchData(null);
                        },
                      },
                    ];
                  },
                },
              },
            ]}
          />
        )}
        {witchData !== null && (
          <div className="visitor-statistics-devices-pie-chart-label">
            <p>{witchData.key === "Computer" && "کامپیوتر"}</p>
            <p>{witchData.key === "Mobile" && "موبایل"}</p>
            <p>{witchData.key === "Tablet" && "تبلت"}</p>
            <p>{witchData.value.percentage_visit_device}%</p>
          </div>
        )}
      </div>
    </div>
  );
}
