import React, { useEffect } from "react";
import "../../assets/styles/OrderStyle/CreateOrder.css";
import TopBar from "../../components/TopBar/TopBar";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import DropDown from "../../components/DropDown/DropDown";
import Modal from "@mui/material/Modal";
import axios from "../../js/Axios/Axios";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import { ShowAlert } from "../../action/index";
import { isObjectEmpty } from "../../helper/Helper";
import { SendOrderId } from "../../action/index";
import { useSelector, useDispatch } from "react-redux";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import AllProductCalender from "../../assets/icons/AllProductCalender.svg";
import Barcode from "../../assets/icons/Barcode.svg";
import check from "../../assets/icons/check.svg";
import uncheck from "../../assets/icons/uncheck.svg";
import AllProductAdd from "../../assets/icons/AllProductAdd.svg";
import Minus from "../../assets/icons/Minus.svg";
import Plus from "../../assets/icons/Plus.svg";
import cross from "../../assets/icons/cross.svg";
import SearchLogo from "../../assets/icons/SearchLogo.svg";
import DeleteRed from "../../assets/icons/DeleteRed.svg";
import { useState } from "react";
import { Calendar } from "react-datepicker2";
import TextField from "@mui/material/TextField";
import { ClockPicker } from "@mui/x-date-pickers/ClockPicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import * as moment from "jalali-moment";
import useForceUpdate from "use-force-update";
export default function CreateOrder() {
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dateTime, setDateTime] = useState("date");
  const [storeUser, setStoreUser] = useState({
    name: null,
    family: null,
    email: null,
    mobile_number: null,
  });
  const [storeAddress, setStoreAddress] = useState({
    title: null,
    city_id: null,
    post_code: null,
    address_details: null,
    province_id: null,
    user_id: null,
    city: null,
    state: null,
    default: 0,
  });
  const [states, setStates] = useState([]);
  const [stateSearch, setStateSearch] = useState("");
  const [stateShow, setStateShow] = useState(null);
  const [cities, setCities] = useState([]);
  const [citySearch, setCitySearch] = useState("");
  const [cityShow, setCityShow] = useState(null);
  const orders = useSelector((state) => state.SendOrderEdit);
  const [customerModal, setCustomerModal] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [dateModal, setDateModal] = useState(false);
  const [statusShow, setStatusShow] = useState(null);
  const [info, setInfo] = useState({
    name: null,
    family: null,
    phoneNumber: null,
    email: null,
    city: null,
    state: null,
    postCode: null,
  });
  const [addressShow, setAddressShow] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [userShow, setUserShow] = useState(null);
  const [userSearch, serUserSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [product, setProduct] = useState([]);
  const [modal, setModal] = useState(false);
  const [isOther, setIsOther] = useState(false);
  const [chooseProduct, setChooseProduct] = useState([]);
  const [final, setFinal] = useState({
    price: null,
    count: null,
  });
  const [OrderItems, setOrderItems] = useState({
    final_save: 0,
    order_id: null,
    orderItems: null,
  });
  const [orderDetails, setOrderDetails] = useState({
    store_id: null,
    order_barcode: null,
    user_id: null,
    delivery_address_id: null,
    transferee_fullName: null,
    transferee_mobile: null,
    final_save: 0,
    submit_date: null,
    order_status: null,
    order_id: null,
  });
  useEffect(() => {
    if (orders.order !== null) {
      axios
        .get(`Address/search?user_id=${orders.order.customer_id}`)
        .then((res) => {
          console.log(res);
          if (res.data.status) {
            res.data.data.map((item) => {
              addresses.push({
                id: item.address_id,
                title: item.title,
                address: item.address_details,
                city: item.city,
                state: item.state,
                postCode: item.post_code,
              });
              forceUpdate();
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
      if (orders.order.isTransferee_another === true) {
        setIsOther(true);
        setOrderDetails((prev) => {
          prev.transferee_fullName = orders.order.transferee_fullName;
          prev.transferee_mobile = orders.order.transferee_mobile;
          forceUpdate();
          return prev;
        });
      }
      setOrderDetails((prev) => {
        prev.delivery_address_id = orders.order.delivery_addresses.address_id;
        prev.final_save = 0;
        prev.order_barcode = orders.order.order_barcode;
        prev.order_id = orders.order.order_id;
        prev.order_status = orders.order.order_status;
        prev.submit_date = orders.order.submit_date;
        prev.user_id = orders.order.customer_id;
        forceUpdate();
        return prev;
      });
      setOrderItems((prev) => {
        prev.order_id = orders.order.order_id;
        forceUpdate();
        return prev;
      });
      setInfo((prev) => {
        prev.city = orders.order.delivery_addresses.city;
        prev.state = orders.order.delivery_addresses.state;
        prev.email = orders.order.customer_email;
        prev.family = orders.order.customer_family;
        prev.name = orders.order.customer_name;
        prev.phoneNumber = orders.order.customer_mobile;
        prev.postCode = orders.order.delivery_addresses.post_code;
        forceUpdate();
        return prev;
      });
      orders.order.order_items.map((item, index) => {
        chooseProduct.push({
          product_count: item.product_count,
          product_price_id: item.productPrice_id,
          checked: true,
          image_link: item.product_image,
          title: item.product_name,
          price: item.price,
          variable_key: item.variableProperty_key,
          variable_alternative_value: item.variableProperty_alterValue,
          variable_main_value: item.variableProperty_value,
        });
        forceUpdate();
      });
    }
  }, [orders]);

  useEffect(() => {
    axios
      .get("User/search")
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          res.data.data.map((item, index) => {
            users.push({
              title: item.fullName,
              id: item.id,
              name: item.name,
              family: item.family,
              email: item.email,
              phoneNumber: item.mobile_number,
            });
          });
          forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const addProductModalClick = () => {
    if (modal === false) {
      setModal(true);
      axios
        .get("/product?isPaginated=0")
        .then((res) => {
          console.log(res);
          if (res.data.status) {
            let array = res.data.data;
            array.map((item) => {
              item["checked"] = false;
              item["counter"] = 1;
            });
            array.map((item) => {
              chooseProduct.map((item_product) => {
                if (item.productPrice_id === item_product.product_price_id) {
                  // if (item_product.product_count > 1) {
                  item.checked = true;
                  item.counter = item_product.product_count;
                  // }
                  forceUpdate();
                }
              });
            });
            setProduct(array);
            forceUpdate();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (modal === true) {
      setModal(false);
    }
  };
  const searchProduct = (e) => {
    axios
      .get(`/product?isPaginated=0&search=${e.target.value}`)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          let array = res.data.data;
          array.map((item) => {
            item["checked"] = false;
            item["counter"] = 1;
          });
          array.map((item) => {
            chooseProduct.map((item_product) => {
              if (item.productPrice_id === item_product.product_price_id) {
                // if (item_product.product_count > 1  ) {
                item.checked = true;
                item.counter = item_product.product_count;
                // }
                forceUpdate();
              }
            });
          });
          setProduct(array);
          forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const addProductClick = (item, index) => {
    product.map((item_product, i) => {
      if (item_product.productPrice_id === item.productPrice_id) {
        if (item_product.checked === true) {
          item_product.checked = false;
          item_product.counter = 1;
          chooseProduct.splice(index, 1);
        } else if (item_product.checked === false) {
          item_product.checked = true;
          chooseProduct.push({
            product_count: 1,
            product_price_id: item.productPrice_id,
            checked: false,
            image_link: item.product_image,
            title: item.product,
            price: item.price,
            variable_key: item.variable_key,
            variable_alternative_value: item.variable_alternative_value,
            variable_main_value: item.variable_main_value,
          });
        }
      }
    });
    forceUpdate();
  };
  const increasementCount = (id, index) => {
    product[index].counter = product[index].counter + 1;
    chooseProduct.map((item) => {
      if (item.product_price_id === id) {
        item.product_count = item.product_count + 1;
      }
      forceUpdate();
    });
  };
  const decreamenttCount = (id, index) => {
    if (product[index].counter > 1) {
      product[index].counter = product[index].counter - 1;
      chooseProduct.map((item) => {
        if (item.product_price_id === id) {
          item.product_count = item.product_count - 1;
        }
        forceUpdate();
      });
    }
  };
  const addProduct = () => {
    setFinal((prev) => {
      prev.count = null;
      prev.price = null;
      return prev;
    });
    chooseProduct.map((item) => {
      item.checked = true;
      setFinal((prev) => {
        prev.count += item.product_count;
        prev.price += item.price * item.product_count;
        return prev;
      });
      forceUpdate();
    });
    setModal(false);
  };
  const addUser = (id, title) => {
    setUserShow(false);
    setErrors((prev) => {
      prev.userName = false;
      forceUpdate();
      return prev;
    });
    setOrderDetails((prev) => {
      prev.user_id = id;
      forceUpdate();
      return prev;
    });
    users.map((item) => {
      if (item.title === title) {
        setInfo((prev) => {
          prev.email = item.email;
          prev.family = item.family;
          prev.name = item.name;
          prev.phoneNumber = item.phoneNumber;
          return prev;
        });
        forceUpdate();
      }
    });
    setTimeout(() => {
      setUserShow(true);
    }, 100);
    axios
      .get(`Address/search?user_id=${id}`)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          addresses.map((item, index) => {
            addresses.splice(index);
          });
          res.data.data.map((item) => {
            addresses.push({
              id: item.address_id,
              title: item.title,
              address: item.address_details,
              city: item.city,
              state: item.state,
              postCode: item.post_code,
            });
            forceUpdate();
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const addAddress = (item) => {
    setErrors((prev) => {
      prev.address = false;
      forceUpdate();
      return prev;
    });
    setOrderDetails((prev) => {
      prev.delivery_address_id = item.id;
      forceUpdate();
      return prev;
    });
    setAddressShow(false);
    setInfo((prev) => {
      prev.city = item.city;
      prev.state = item.state;
      prev.postCode = item.postCode;
      forceUpdate();
      return prev;
    });
    setTimeout(() => {
      setAddressShow(true);
    }, 100);
  };
  const isOtherClick = () => {
    setIsOther(!isOther);
    setOrderDetails((prev) => {
      prev.transferee_fullName = null;
      prev.transferee_mobile = null;
      forceUpdate();
      return prev;
    });
  };
  const createBarcodeClick = () => {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 10; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    setOrderDetails((prev) => {
      prev.order_barcode = text;
      forceUpdate();
      return prev;
    });
    setErrors((prev) => {
      prev.barcode = false;
      forceUpdate();
      return prev;
    });
    forceUpdate();
  };
  const addDateModal = () => {
    setDateModal(!dateModal);
    forceUpdate();
  };
  const addStatus = (id, title) => {
    setErrors((prev) => {
      prev.status = false;
      forceUpdate();
      return prev;
    });
    setOrderDetails((prev) => {
      prev.order_status = title;
      forceUpdate();
      return prev;
    });
    setStatusShow(false);
    setTimeout(() => {
      setStatusShow(true);
    }, 100);
  };
  const finalIncreasmentCount = (index) => {
    setChooseProduct((prev) => {
      prev[index].product_count = prev[index].product_count + 1;
      forceUpdate();
      return prev;
    });
    setFinal((prev) => {
      prev.count = null;
      prev.price = null;
      return prev;
    });
    chooseProduct.map((item) => {
      item.checked = true;
      setFinal((prev) => {
        prev.count += item.product_count;
        prev.price += item.price * item.product_count;
        return prev;
      });
      forceUpdate();
    });
  };
  const finalDecreamentCount = (index) => {
    if (chooseProduct[index].product_count > 1) {
      setChooseProduct((prev) => {
        prev[index].product_count = prev[index].product_count - 1;
        forceUpdate();
        return prev;
      });
    } else if (chooseProduct[index].product_count === 1) {
      chooseProduct.splice(index, 1);
    }
    setFinal((prev) => {
      prev.count = null;
      prev.price = null;
      return prev;
    });
    chooseProduct.map((item) => {
      item.checked = true;
      setFinal((prev) => {
        prev.count += item.product_count;
        prev.price += item.price * item.product_count;
        return prev;
      });
      forceUpdate();
    });
    forceUpdate();
  };
  const [errors, setErrors] = useState({
    address: false,
    barcode: false,
    status: false,
    userName: false,
  });
  const addOrder = () => {
    console.log(orderDetails);
    if (
      orderDetails.delivery_address_id !== null &&
      orderDetails.order_barcode !== null &&
      orderDetails.order_status !== null &&
      orderDetails.user_id !== null
    ) {
      axios
        .post("Order", orderDetails)
        .then((res) => {
          console.log(res);
          if (res.data.status === 0) {
            dispatch(ShowAlert(true, res.data.message));
          }
          if (res.data.status) {
            dispatch(SendOrderId(res.data.data.order_id));
            setOrderItems((prev) => {
              prev.order_id = res.data.data.order_id;
              prev.orderItems = chooseProduct;
              forceUpdate();
              return prev;
            });
            setOrderDetails((prev) => {
              prev.order_id = res.data.data.order_id;
              forceUpdate();
              return prev;
            });
            // dispatch(ShowAlert(true, res.data.message));
            axios
              .post("OrderItem", OrderItems)
              .then((res) => {
                console.log(res);
                if (res.data.status === 0) {
                  dispatch(ShowAlert(true, res.data.message));
                }
                if (res.data.status) {
                  navigate("/CreateOrderDelivery");
                  dispatch(ShowAlert(true, res.data.message));
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      dispatch(
        ShowAlert(
          true,
          `برای ثبت سفارش پر کردن فیلد های ${
            orderDetails.delivery_address_id === null ? "آدرس و" : ""
          }  ${orderDetails.order_barcode === null ? "شناسه سفارش و" : ""}  ${
            orderDetails.order_status === null ? "وضیعت و  " : ""
          } ${orderDetails.user_id === null ? "مشتری" : ""} الزامی است .`
        )
      );
      if (orderDetails.delivery_address_id === null) {
        setErrors((prev) => {
          prev.address = true;
          forceUpdate();
          return prev;
        });
      }
      if (orderDetails.order_barcode === null) {
        setErrors((prev) => {
          prev.barcode = true;
          forceUpdate();
          return prev;
        });
      }
      if (orderDetails.order_status === null) {
        setErrors((prev) => {
          prev.status = true;
          forceUpdate();
          return prev;
        });
      }
      if (orderDetails.user_id === null) {
        setErrors((prev) => {
          prev.userName = true;
          forceUpdate();
          return prev;
        });
      }
    }
  };
  const openCustomerModal = () => {
    if (customerModal === false) {
      setCustomerModal(true);
      states.map((item, index) => {
        states.splice(index);
      });
      axios
        .get("/Province")
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            res.data.data.map((item, index) => {
              states.push({
                title: item.province,
                id: item.id,
              });
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (customerModal === true) {
      setCustomerModal(false);
    }
    forceUpdate();
  };
  const openAddressModal = () => {
    if (addressModal === false) {
      setAddressModal(true);
      states.map((item, index) => {
        states.splice(index);
      });
      axios
        .get("/Province")
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            res.data.data.map((item, index) => {
              states.push({
                title: item.province,
                id: item.id,
              });
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (addressModal === true) {
      setAddressModal(false);
    }
    forceUpdate();
  };
  const stateOnChange = (e) => {
    setStateSearch(e.target.value);
  };
  const cityOnChange = (e) => {
    setCitySearch(e.target.value);
  };
  const addStateClick = (id, title) => {
    setStoreAddress((prev) => {
      prev.province_id = id;
      prev.state = title;
      forceUpdate();
      return prev;
    });
    setAddressError((prev) => {
      prev.state = false;
      forceUpdate();
      return prev;
    });
    setUserError((prev) => {
      prev.state = false;
      forceUpdate();
      return prev;
    });
    setStateShow(false);
    setTimeout(() => {
      setStateShow(true);
    }, 100);
    axios
      .get(`/Province/${id}`)
      .then((res) => {
        console.log(res);
        res.data.data.cities.map((item, index) => {
          cities.push({
            title: item.province,
            id: item.id,
          });
        });
        forceUpdate();
      })

      .catch((e) => {
        console.log(e);
      });
  };
  const addCityClick = (id, title) => {
    setStoreAddress((prev) => {
      prev.city_id = id;
      prev.city = title;
      forceUpdate();
      return prev;
    });
    setAddressError((prev) => {
      prev.city = false;
      forceUpdate();
      return prev;
    });
    setUserError((prev) => {
      prev.city = false;
      forceUpdate();
      return prev;
    });
    setCityShow(false);
    setTimeout(() => {
      setCityShow(true);
    }, 100);
  };
  const cancelStoreUser = () => {
    setStoreAddress((prev) => {
      prev.address_details = null;
      prev.city = null;
      prev.city_id = null;
      prev.post_code = null;
      prev.province_id = null;
      prev.state = null;
      prev.title = null;
      prev.user_id = null;
      forceUpdate();
      return prev;
    });
    setStoreUser((prev) => {
      prev.email = null;
      prev.family = null;
      prev.mobile_number = null;
      prev.name = null;
      forceUpdate();
      return prev;
    });
    setCustomerModal(false);
  };
  const [userError, setUserError] = useState({
    family: false,
    name: false,
    number: false,
    post_code: false,
    address: false,
    city: false,
    state: false,
  });
  const storeUserClick = () => {
    console.log("zzz", storeAddress);
    if (storeUser.name === null || storeUser.name === "") {
      setUserError((prev) => {
        prev.name = true;
        forceUpdate();
        return prev;
      });
    }
    if (storeUser.family === null || storeUser.family === "") {
      setUserError((prev) => {
        prev.family = true;
        forceUpdate();
        return prev;
      });
    }
    if (storeUser.mobile_number === null || storeUser.mobile_number === "") {
      setUserError((prev) => {
        prev.number = true;
        forceUpdate();
        return prev;
      });
    }
    if (
      storeAddress.address_details === null ||
      storeAddress.address_details === ""
    ) {
      setUserError((prev) => {
        prev.address = true;
        forceUpdate();
        return prev;
      });
    }
    if (storeAddress.city_id === null || storeAddress.city_id === "") {
      setUserError((prev) => {
        prev.city = true;
        forceUpdate();
        return prev;
      });
    }
    if (storeAddress.province_id === null || storeAddress.province_id === "") {
      setUserError((prev) => {
        prev.state = true;
        forceUpdate();
        return prev;
      });
    }
    if (storeAddress.post_code === null || storeAddress.post_code === "") {
      setUserError((prev) => {
        prev.post_code = true;
        forceUpdate();
        return prev;
      });
    }
    if (
      storeUser.family != null &&
      storeUser.family != "" &&
      storeUser.name != null &&
      storeUser.name != "" &&
      storeUser.mobile_number != null &&
      storeUser.mobile_number != "" &&
      storeAddress.address_details !== null &&
      storeAddress.address_details !== "" &&
      storeAddress.city_id !== null &&
      storeAddress.city_id !== "" &&
      storeAddress.province_id !== null &&
      storeAddress.province_id !== "" &&
      storeAddress.post_code !== null &&
      storeAddress.post_code !== ""
    ) {
      axios
        .post("/User", storeUser)
        .then((res) => {
          console.log(res);
          if (res.data.status === 0) {
            dispatch(ShowAlert(true, res.data.message));
          }
          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));
            setStoreAddress((prev) => {
              prev.user_id = res.data.data.user_id;
              forceUpdate();
              return prev;
            });
            users.map((item, index) => {
              users.splice(index);
              forceUpdate();
            });
            axios
              .get("User/search")
              .then((res) => {
                console.log(res);
                if (res.data.status) {
                  res.data.data.map((item, index) => {
                    users.push({
                      title: item.fullName,
                      id: item.id,
                      name: item.name,
                      family: item.family,
                      email: item.email,
                      phoneNumber: item.mobile_number,
                    });
                  });
                  forceUpdate();
                }
              })
              .catch((e) => {
                console.log(e);
              });
            axios
              .post("Address", storeAddress)
              .then((res) => {
                console.log(res);
                if (res.data.status === 0) {
                  dispatch(ShowAlert(true, res.data.data));
                }
                if (res.data.status) {
                  dispatch(ShowAlert(true, res.data.message));
                  setStoreAddress((prev) => {
                    prev.address_details = null;
                    prev.city = null;
                    prev.city_id = null;
                    prev.post_code = null;
                    prev.province_id = null;
                    prev.state = null;
                    prev.title = null;
                    prev.user_id = null;
                    forceUpdate();
                    return prev;
                  });
                  setStoreUser((prev) => {
                    prev.email = null;
                    prev.family = null;
                    prev.mobile_number = null;
                    prev.name = null;
                    forceUpdate();
                    return prev;
                  });
                  setCustomerModal(false);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      // storeUser.family != null &&
      // storeUser.family != "" &&
      // storeUser.name != null &&
      // storeUser.name != "" &&
      // storeUser.mobile_number != null &&
      // storeUser.mobile_number != ""
      let text = `برای ثبت مشتری پر کردن فیلد 
      ${storeUser.name == null || storeUser.name == "" ? "نام و " : ""}
      ${
        storeUser.family == null || storeUser.family == ""
          ? "نام خانوادگی و "
          : ""
      }
      ${
        storeUser.mobile_number == null || storeUser.mobile_number == ""
          ? "موبایل و"
          : ""
      }
      ${
        storeAddress.address_details == null ||
        storeAddress.address_details == ""
          ? "ادرس پستی و"
          : ""
      }
      ${
        storeAddress.province_id == null || storeAddress.province_id == ""
          ? "استان و "
          : ""
      }
      ${
        storeAddress.city_id == null || storeAddress.city_id == ""
          ? "شهر و "
          : ""
      }
      ${
        storeAddress.post_code == null || storeAddress.post_code == ""
          ? "کد پستی "
          : ""
      }
      الزامی است .
      `;
      dispatch(ShowAlert(true, text));
    }
  };
  const [fullName, setFullName] = useState(null);
  const addUserIdClick = (id, title) => {
    setStoreAddress((prev) => {
      prev.user_id = id;
      forceUpdate();
      return prev;
    });
    setAddressError((prev) => {
      prev.user = false;
      forceUpdate();
      return prev;
    });
    setFullName(title);
    setUserShow(false);
    setTimeout(() => {
      setUserShow(true);
    }, 100);
  };
  const cancelStoreAddress = () => {
    setStoreAddress((prev) => {
      prev.address_details = null;
      prev.city = null;
      prev.city_id = null;
      prev.post_code = null;
      prev.province_id = null;
      prev.state = null;
      prev.title = null;
      prev.user_id = null;
      forceUpdate();
      return prev;
    });
    setFullName(null);
    setAddressModal(false);
  };
  const [addressError, setAddressError] = useState({
    address: false,
    city: false,
    state: false,
    user: false,
    post_code: false,
  });
  const storeAddressClick = () => {
    if (
      storeAddress.address_details !== null &&
      storeAddress.address_details !== "" &&
      storeAddress.city_id !== null &&
      storeAddress.city_id !== "" &&
      storeAddress.post_code !== null &&
      storeAddress.post_code !== "" &&
      storeAddress.province_id !== null &&
      storeAddress.province_id !== "" &&
      storeAddress.user_id !== null &&
      storeAddress.user_id !== ""
    ) {
      axios
        .post("Address", storeAddress)
        .then((res) => {
          console.log(res);
          if (res.data.status === 0) {
            dispatch(ShowAlert(true, res.data.data));
          }
          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));
            setStoreAddress((prev) => {
              prev.address_details = null;
              prev.city = null;
              prev.city_id = null;
              prev.post_code = null;
              prev.province_id = null;
              prev.state = null;
              prev.title = null;
              prev.user_id = null;
              forceUpdate();
              return prev;
            });
            setFullName(null);
            setAddressModal(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      let text = `برای ثبت ادرس پر کردن فیلد های
      ${
        storeAddress.address_details === null ||
        storeAddress.address_details === ""
          ? "آدر پستی و"
          : ""
      }
      ${
        storeAddress.city_id === null || storeAddress.city_id === ""
          ? "شهر و"
          : ""
      }
      ${
        storeAddress.post_code === null || storeAddress.post_code === ""
          ? "کد پستی و"
          : ""
      }
      ${
        storeAddress.province_id === null || storeAddress.province_id === ""
          ? "استان و"
          : ""
      }
      ${
        storeAddress.user_id !== null || storeAddress.user_id !== ""
          ? "مشتری"
          : ""
      }
      الزامی است .
      `;
      dispatch(ShowAlert(true, text));
      if (
        storeAddress.address_details === null ||
        storeAddress.address_details === ""
      ) {
        setAddressError((prev) => {
          prev.address = true;
          forceUpdate();
          return prev;
        });
      }
      if (storeAddress.city_id === null || storeAddress.city_id === "") {
        setAddressError((prev) => {
          prev.city = true;
          forceUpdate();
          return prev;
        });
      }
      if (storeAddress.post_code === null || storeAddress.post_code === "") {
        setAddressError((prev) => {
          prev.post_code = true;
          forceUpdate();
          return prev;
        });
      }
      if (
        storeAddress.province_id === null ||
        storeAddress.province_id === ""
      ) {
        setAddressError((prev) => {
          prev.state = true;
          forceUpdate();
          return prev;
        });
      }
      if (storeAddress.user_id !== null || storeAddress.user_id !== "") {
        setAddressError((prev) => {
          prev.user = true;
          forceUpdate();
          return prev;
        });
      }
    }
  };
  return (
    <div className="create-order-all">
      <Modal
        onBackdropClick={() => addDateModal()}
        className="create-order-modal-all"
        open={dateModal}
      >
        <div style={{ backgroundColor: "#fff", direction: "ltr" }}>
          {dateTime === "date" ? (
            <Calendar
              isGregorian={false}
              onChange={(value) => {
                let date = moment(value._d, "YYYY/MM/DD")
                  .locale("fa")
                  .format("YYYY/MM/DD");
                setOrderDetails((prev) => {
                  prev.submit_date = date;
                  forceUpdate();
                  return prev;
                });
                setDateTime("time");
              }}
            />
          ) : (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticTimePicker
                ampm={false}
                orientation="landscape"
                openTo="minutes"
                // value={value}
                onChange={(e) => {
                  let time = moment(e).format("HH:mm");
                  setOrderDetails((prev) => {
                    prev.submit_date = prev.submit_date + " " + time;
                    forceUpdate();
                    return prev;
                  });
                  setDateTime("date");
                  setDateModal(!dateModal);
                  forceUpdate();
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          )}
        </div>
      </Modal>
      {/* //////////////////////////////////// */}
      <Modal
        onBackdropClick={() => openAddressModal()}
        className="create-order-modal-all"
        open={addressModal}
      >
        <div className="create-order-modal-box">
          <div className="create-order-modal-box-top-bar">
            <p>افزودن آدرس</p>
            <img src={cross} onClick={() => openAddressModal()} />
          </div>
          <div className="create-order-customer-modal-box">
            <DropDown
              width="calc(100% - 20px)"
              height="44px"
              dropDownHeight="300px"
              value={fullName}
              zindex="1"
              onchange={(e) => serUserSearch(e.target.value)}
              data={users}
              search={userSearch}
              borderColor={addressError.user === true ? "#F23B3B" : "#dcdcdc"}
              itemClick={(title, id) => addUserIdClick(title, id)}
              // Data={users}
              show={userShow}
              // axiosname="province"
              placeholder="مشتری"
            />
            <InputComponent
              margin="20px 0px 0 0"
              value={storeAddress.title}
              width="calc(100% - 20px)"
              height="44px"
              type="text"
              placeholder="عنوان"
              borderColor="#dcdcdc"
              inputType="input"
              onchange={(e) =>
                setStoreAddress((prev) => {
                  prev.title = e.target.value;
                  forceUpdate();
                  return prev;
                })
              }
            />
            <InputComponent
              margin="20px 0px 0 0"
              value={storeAddress.address_details}
              width="calc(100% - 20px)"
              height="73px"
              type="text"
              placeholder="آدرس پستی"
              borderColor={
                addressError.address === true ? "#F23B3B" : "#dcdcdc"
              }
              inputType="input"
              onchange={(e) => {
                setStoreAddress((prev) => {
                  prev.address_details = e.target.value;
                  forceUpdate();
                  return prev;
                });
                setAddressError((prev) => {
                  prev.address = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
            <div className="create-order-customer-modal-box-address-drop-down-box">
              <DropDown
                width="45% "
                height="44px"
                dropDownHeight="120px"
                zindex="1"
                data={states}
                onchange={(e) => stateOnChange(e)}
                search={stateSearch}
                show={stateShow}
                value={storeAddress.state}
                borderColor={
                  addressError.state === true ? "#F23B3B" : "#dcdcdc"
                }
                itemClick={(id, title) => addStateClick(id, title)}
                placeholder="استان"
              />
              <DropDown
                width="45% "
                height="44px"
                dropDownHeight="120px"
                zindex="1"
                onchange={(e) => cityOnChange(e)}
                data={cities}
                search={citySearch}
                show={cityShow}
                borderColor={addressError.city === true ? "#F23B3B" : "#dcdcdc"}
                value={storeAddress.city}
                itemClick={(id, title) => addCityClick(id, title)}
                placeholder="شهر"
              />
            </div>

            <InputComponent
              margin="20px 0px 0 0"
              value={storeAddress.post_code}
              width="calc(100% - 20px)"
              height="44px"
              type="number"
              placeholder="کد بستی"
              borderColor={
                addressError.post_code === true ? "#F23B3B" : "#dcdcdc"
              }
              inputType="input"
              onchange={(e) => {
                setStoreAddress((prev) => {
                  prev.post_code = e.target.value;
                  forceUpdate();
                  return prev;
                });
                setAddressError((prev) => {
                  prev.post_code = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
          </div>

          <div className="create-order-modal-box-button-box">
            <button
              onClick={() => cancelStoreAddress()}
              className="create-order-modal-box-button-box-add-customer-cancel-button"
            >
              لغو
            </button>
            <button
              onClick={() => storeAddressClick()}
              className="create-order-modal-box-button-box-button"
            >
              ثبت آدرس
            </button>
          </div>
        </div>
      </Modal>
      {/* /////////////////////////// */}
      <Modal
        onBackdropClick={() => openCustomerModal()}
        className="create-order-modal-all"
        open={customerModal}
      >
        <div className="create-order-modal-box">
          <div className="create-order-modal-box-top-bar">
            <p>افزودن مشتری جدید</p>
            <img src={cross} onClick={() => openCustomerModal()} />
          </div>
          <div className="create-order-customer-modal-box">
            <InputComponent
              // margin="0 25px 0 0"
              value={storeUser.name}
              width="calc(100% - 20px)"
              height="44px"
              type="text"
              placeholder="نام"
              borderColor={userError.name === true ? "#F23B3B" : "#dcdcdc"}
              inputType="input"
              onchange={(e) => {
                setStoreUser((prev) => {
                  prev.name = e.target.value;
                  forceUpdate();
                  return prev;
                });
                setUserError((prev) => {
                  prev.name = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
            <InputComponent
              margin="20px 0px 0 0"
              value={storeUser.family}
              width="calc(100% - 20px)"
              height="44px"
              type="text"
              placeholder="نام خانوادگی"
              borderColor={userError.family === true ? "#F23B3B" : "#dcdcdc"}
              inputType="input"
              onchange={(e) => {
                setStoreUser((prev) => {
                  prev.family = e.target.value;
                  forceUpdate();
                  return prev;
                });
                setUserError((prev) => {
                  prev.family = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
            <InputComponent
              margin="20px 0px 0 0"
              value={storeUser.email}
              width="calc(100% - 20px)"
              height="44px"
              type="text"
              placeholder="ایمیل"
              borderColor="#dcdcdc"
              inputType="input"
              onchange={(e) =>
                setStoreUser((prev) => {
                  prev.email = e.target.value;
                  forceUpdate();
                  return prev;
                })
              }
            />
            <InputComponent
              margin="20px 0px 0 0"
              value={storeUser.mobile_number}
              width="calc(100% - 20px)"
              height="44px"
              type="number"
              placeholder="موبایل"
              borderColor={userError.number === true ? "#F23B3B" : "#dcdcdc"}
              inputType="input"
              onchange={(e) => {
                setStoreUser((prev) => {
                  prev.mobile_number = e.target.value;
                  forceUpdate();
                  return prev;
                });
                setUserError((prev) => {
                  prev.number = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
            <div className="create-order-customer-modal-box-address">
              <p>افزودن آدرس</p>
            </div>
            <InputComponent
              margin="20px 0px 0 0"
              value={storeAddress.title}
              width="calc(100% - 20px)"
              height="44px"
              type="text"
              placeholder="عنوان"
              borderColor="#dcdcdc"
              inputType="input"
              onchange={(e) =>
                setStoreAddress((prev) => {
                  prev.title = e.target.value;
                  forceUpdate();
                  return prev;
                })
              }
            />
            <InputComponent
              margin="20px 0px 0 0"
              value={storeAddress.address_details}
              width="calc(100% - 20px)"
              height="73px"
              type="text"
              placeholder="آدرس پستی"
              borderColor={userError.address === true ? "#F23B3B" : "#dcdcdc"}
              inputType="input"
              onchange={(e) => {
                setStoreAddress((prev) => {
                  prev.address_details = e.target.value;
                  forceUpdate();
                  return prev;
                });
                setUserError((prev) => {
                  prev.address = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
            <div className="create-order-customer-modal-box-address-drop-down-box">
              <DropDown
                width="45% "
                height="44px"
                dropDownHeight="120px"
                zindex="1"
                data={states}
                onchange={(e) => stateOnChange(e)}
                search={stateSearch}
                show={stateShow}
                value={storeAddress.state}
                borderColor={userError.state === true ? "#F23B3B" : "#dcdcdc"}
                itemClick={(id, title) => addStateClick(id, title)}
                placeholder="استان"
              />
              <DropDown
                width="45% "
                height="44px"
                dropDownHeight="120px"
                zindex="1"
                onchange={(e) => cityOnChange(e)}
                data={cities}
                search={citySearch}
                show={cityShow}
                value={storeAddress.city}
                borderColor={userError.city === true ? "#F23B3B" : "#dcdcdc"}
                itemClick={(id, title) => addCityClick(id, title)}
                placeholder="شهر"
              />
            </div>
            <InputComponent
              margin="20px 0px 0 0"
              value={storeAddress.post_code}
              width="calc(100% - 20px)"
              height="44px"
              type="number"
              placeholder="کد بستی"
              borderColor={userError.post_code === true ? "#F23B3B" : "#dcdcdc"}
              inputType="input"
              onchange={(e) => {
                setStoreAddress((prev) => {
                  prev.post_code = e.target.value;
                  forceUpdate();
                  return prev;
                });
                setUserError((prev) => {
                  prev.post_code = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
          </div>

          <div className="create-order-modal-box-button-box">
            <button
              onClick={() => cancelStoreUser()}
              className="create-order-modal-box-button-box-add-customer-cancel-button"
            >
              لغو
            </button>
            <button
              onClick={() => storeUserClick()}
              className="create-order-modal-box-button-box-button"
            >
              ثبت مشتری
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        onBackdropClick={() => addProductModalClick()}
        className="create-order-modal-all"
        open={modal}
      >
        <div className="create-order-modal-box">
          <div className="create-order-modal-box-top-bar">
            <p>افزودن محصول</p>
            <img src={cross} onClick={() => addProductModalClick()} />
          </div>
          <div className="create-order-modal-box-2">
            <InputComponent
              width="calc(100% - 20px)"
              height="44px"
              type="text"
              inputType="inputWithIcon"
              iconSide="right"
              iconSrc={SearchLogo}
              backgroundColor="#f7f7f7"
              placeholder="جستجو محصول.."
              borderColor="#dcdcdc"
              onchange={(e) => searchProduct(e)}
            />
          </div>
          <SimpleBar
            forceVisible="y"
            className="create-order-modal-box-product-box"
          >
            {product.map((item, index) => {
              return (
                <div
                  key={index}
                  className="create-order-modal-box-product-box-2"
                >
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={`product${index}`}
                    className="create-order-modal-box-product-box-2-check-box"
                  >
                    <input
                      style={{ display: "none" }}
                      type="checkbox"
                      id={`product${index}`}
                      name={`product${index}`}
                      onChange={() => addProductClick(item, index)}
                    />
                    <img
                      style={{ cursor: "pointer" }}
                      src={item.checked === true ? check : uncheck}
                    />
                  </label>
                  
                  <div className="create-order-modal-box-product-box-2-img-box">
                    <div className="create-order-modal-box-product-box-2-img-box-2">
                      <img src={item.product_image} />
                    </div>
                  </div>
                  <div className="create-order-modal-box-product-box-2-title-box">
                    <p>{item.product}</p>
                    {item.variable_key !== null && item.variable_key === "رنگ" && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "auto",
                          maxWidth: "70px",
                          height: "30px",
                          marginTop: "17px",
                          borderRadius: "10px",
                          border: `solid 1px ${item.variable_alternative_value}`,
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontFamily: "IRANSansFaNum",
                            fontWeight: "900",
                            direction: "rtl",
                            fontSize: "16px",
                            color: `${item.variable_alternative_value}`,
                          }}
                        >
                          {item.variable_main_value}
                        </p>
                      </div>
                    )}
                    {item.variable_key !== null && item.variable_key !== "رنگ" && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "auto",
                          maxWidth: "70px",
                          height: "30px",
                          marginTop: "17px",
                          borderRadius: "10px",
                          border: "solid 1px #000000",
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontFamily: "IRANSansFaNum",
                            fontWeight: "900",
                            direction: "rtl",
                            fontSize: "16px",
                            color: "#000000",
                          }}
                        >
                          {item.variable_main_value}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="create-order-modal-box-product-box-2-price">
                    <div className="create-order-modal-box-product-box-2-price-2">
                      <p>{item.price}</p>
                      <p>(تومان)</p>
                    </div>
                    {item.checked === true && (
                      <div className="create-order-modal-box-product-box-2-price-3">
                        <button
                          onClick={() =>
                            increasementCount(item.productPrice_id, index)
                          }
                          className="create-order-modal-box-product-box-2-price-3-plus"
                        >
                          <img src={Plus} />
                        </button>
                        <div className="create-order-modal-box-product-box-2-price-3-number">
                          <p>{item.counter}</p>
                        </div>
                        <button
                          onClick={() =>
                            decreamenttCount(item.productPrice_id, index)
                          }
                          className="create-order-modal-box-product-box-2-price-3-minus"
                        >
                          <img src={Minus} />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </SimpleBar>
          <div className="create-order-modal-box-button-box">
            <button
              onClick={() => addProduct()}
              className="create-order-modal-box-button-box-button"
            >
              افزودن محصولات
            </button>
          </div>
        </div>
      </Modal>
      <TopBar />
      <div className="create-order-all-box">
        <div className="create-order-inputs-box">
          <div className="create-order-inputs-box-general-box">
            <p>1. کلیات سفارش</p>
            <div className="create-order-inputs-box-2">
              <InputComponent
                disabled={true}
                value={orderDetails.submit_date}
                width="calc(100% - 20px)"
                height="44px"
                type="text"
                // id={"barcodeInput"}
                placeholder="تاریخ ایجاد"
                borderColor="#dcdcdc"
                inputType="inputWithIcon"
                iconSide="left"
                onClick={() => addDateModal()}
                iconSrc={AllProductCalender}
                // onchange={(e) =>
                //   setProductInfo((prev) => {
                //     return {
                //       title: prev.title,
                //       latin_title: prev.latin_title,
                //       brand_name: prev.brand_name,
                //       url: prev.url,
                //       barcode: e.target.value,
                //       country: prev.country,
                //       product_introduction: prev.product_introduction,
                //       temp_status: prev.temp_status,
                //     };
                //   })
                // }
              />
            </div>
            <div className="create-order-inputs-box-2">
              <DropDown
                value={orderDetails.order_status}
                width="100%"
                height="44px"
                dropDownHeight="200px"
                zindex="1"
                borderColor={errors.status === true ? "#F23B3B" : "#dcdcdc"}
                // onchange={(e) => cityOnChange(e)}
                data={[
                  {
                    title: "لغو شده",
                  },
                  {
                    title: "در انتظار پرداخت",
                  },
                  {
                    title: "در حال انجام",
                  },
                  {
                    title: "تکمیل شده",
                  },
                ]}
                itemClick={(title, id) => addStatus(title, id)}
                search={""}
                //   Data={Data}
                show={statusShow}
                // axiosname="province"
                placeholder="وضیعت"
              />
            </div>

            <div className="create-order-inputs-box-2">
              <InputComponent
                disabled={true}
                value={orderDetails.order_barcode}
                width="calc(100% - 20px)"
                height="44px"
                type="text"
                // id={"barcodeInput"}
                placeholder="شناسه سفارش"
                borderColor={errors.barcode === true ? "#F23B3B" : "#dcdcdc"}
                inputType="inputWithIcon"
                iconSide="left"
                onClick={() => createBarcodeClick()}
                iconSrc={Barcode}
                // onchange={(e) =>
                //   setProductInfo((prev) => {
                //     return {
                //       title: prev.title,
                //       latin_title: prev.latin_title,
                //       brand_name: prev.brand_name,
                //       url: prev.url,
                //       barcode: e.target.value,
                //       country: prev.country,
                //       product_introduction: prev.product_introduction,
                //       temp_status: prev.temp_status,
                //     };
                //   })
                // }
              />
            </div>
          </div>
          <div className="create-order-inputs-box-general-box">
            <p>2. اطلاعات مشتری</p>

            <div className="create-order-inputs-box-2">
              <DropDown
                width="100%"
                height="44px"
                dropDownHeight="300px"
                zindex="1"
                onchange={(e) => serUserSearch(e.target.value)}
                data={users}
                search={userSearch}
                itemClick={(title, id) => addUser(title, id)}
                borderColor={errors.userName === true ? "#F23B3B" : "#dcdcdc"}
                // Data={users}
                show={userShow}
                // axiosname="province"
                placeholder="مشتری"
              />
            </div>
            <div className="create-order-inputs-box-2">
              <InputComponent
                // margin="0 25px 0 0"
                value={info.name}
                disabled={true}
                width="calc(48% - 20px)"
                height="44px"
                type="text"
                placeholder="نام"
                borderColor="#dcdcdc"
                inputType="input"
                // onchange={(e) =>
                //   setProductInfo((prev) => {
                //     return {
                //       title: prev.title,
                //       latin_title: prev.latin_title,
                //       url: prev.url,
                //       country: e.target.value,
                //       barcode: prev.barcode,
                //       brand_name: prev.brand_name,
                //       product_introduction: prev.product_introduction,
                //       temp_status: prev.temp_status,
                //     };
                //   })
                // }
              />
              <InputComponent
                // margin="0 25px 0 0"
                value={info.family}
                disabled={true}
                width="calc(48% - 20px)"
                height="44px"
                type="text"
                placeholder="نام خانوادگی"
                borderColor="#dcdcdc"
                inputType="input"
                // onchange={(e) =>
                //   setProductInfo((prev) => {
                //     return {
                //       title: prev.title,
                //       latin_title: prev.latin_title,
                //       url: prev.url,
                //       country: e.target.value,
                //       barcode: prev.barcode,
                //       brand_name: prev.brand_name,
                //       product_introduction: prev.product_introduction,
                //       temp_status: prev.temp_status,
                //     };
                //   })
                // }
              />
            </div>
            <div className="create-order-inputs-box-2">
              <InputComponent
                // margin="0 25px 0 0"
                value={info.phoneNumber}
                disabled={true}
                width="calc(48% - 20px)"
                height="44px"
                type="text"
                placeholder="موبایل"
                borderColor="#dcdcdc"
                inputType="input"
                // onchange={(e) =>
                //   setProductInfo((prev) => {
                //     return {
                //       title: prev.title,
                //       latin_title: prev.latin_title,
                //       url: prev.url,
                //       country: e.target.value,
                //       barcode: prev.barcode,
                //       brand_name: prev.brand_name,
                //       product_introduction: prev.product_introduction,
                //       temp_status: prev.temp_status,
                //     };
                //   })
                // }
              />
              <InputComponent
                // margin="0 25px 0 0"
                value={info.email}
                disabled={true}
                width="calc(48% - 20px)"
                height="44px"
                type="text"
                placeholder="ایمیل"
                borderColor="#dcdcdc"
                inputType="input"
                // onchange={(e) =>
                //   setProductInfo((prev) => {
                //     return {
                //       title: prev.title,
                //       latin_title: prev.latin_title,
                //       url: prev.url,
                //       country: e.target.value,
                //       barcode: prev.barcode,
                //       brand_name: prev.brand_name,
                //       product_introduction: prev.product_introduction,
                //       temp_status: prev.temp_status,
                //     };
                //   })
                // }
              />
            </div>
            <div className="create-order-inputs-box-4">
              <button
                onClick={() => openCustomerModal()}
                className="create-order-inputs-box-4-add-customer"
              >
                <img src={AllProductAdd} />
                افزودن مشتری
              </button>
            </div>
          </div>
          <div className="create-order-inputs-box-general-box">
            <p>3. آدرس</p>
            <div className="create-order-inputs-box-2">
              <DropDown
                width="100%"
                height="44px"
                dropDownHeight="300px"
                zindex="1"
                //   onchange={(e) => cityOnChange(e)}
                borderColor={errors.address === true ? "#F23B3B" : "#dcdcdc"}
                data={addresses}
                itemClick={(item) => addAddress(item)}
                //   search={citySearch}
                //   Data={Data}
                show={addressShow}
                axiosname="address"
                placeholder="آدرس"
              />
            </div>
            <div className="create-order-inputs-box-2">
              <InputComponent
                // margin="0 25px 0 0"
                value={info.state}
                disabled={true}
                width="calc(48% - 20px)"
                height="44px"
                type="text"
                placeholder="استان"
                borderColor="#dcdcdc"
                inputType="input"
                // onchange={(e) =>
                //   setProductInfo((prev) => {
                //     return {
                //       title: prev.title,
                //       latin_title: prev.latin_title,
                //       url: prev.url,
                //       country: e.target.value,
                //       barcode: prev.barcode,
                //       brand_name: prev.brand_name,
                //       product_introduction: prev.product_introduction,
                //       temp_status: prev.temp_status,
                //     };
                //   })
                // }
              />
              <InputComponent
                // margin="0 25px 0 0"
                value={info.city}
                disabled={true}
                width="calc(48% - 20px)"
                height="44px"
                type="text"
                placeholder="شهر"
                borderColor="#dcdcdc"
                inputType="input"
                // onchange={(e) =>
                //   setProductInfo((prev) => {
                //     return {
                //       title: prev.title,
                //       latin_title: prev.latin_title,
                //       url: prev.url,
                //       country: e.target.value,
                //       barcode: prev.barcode,
                //       brand_name: prev.brand_name,
                //       product_introduction: prev.product_introduction,
                //       temp_status: prev.temp_status,
                //     };
                //   })
                // }
              />
            </div>
            <div className="create-order-inputs-box-2">
              <InputComponent
                value={info.postCode}
                disabled={true}
                width="calc(100% - 20px)"
                height="44px"
                type="text"
                placeholder="کد پستی"
                borderColor="#dcdcdc"
                inputType="input"
                // onClick={() => createBarcodeClick()}
                // onchange={(e) =>
                //   setProductInfo((prev) => {
                //     return {
                //       title: prev.title,
                //       latin_title: prev.latin_title,
                //       brand_name: prev.brand_name,
                //       url: prev.url,
                //       barcode: e.target.value,
                //       country: prev.country,
                //       product_introduction: prev.product_introduction,
                //       temp_status: prev.temp_status,
                //     };
                //   })
                // }
              />
            </div>
            <label htmlFor="is_other" className="create-order-inputs-box-3">
              <input
                style={{ display: "none" }}
                id="is_other"
                name="is_other"
                type="checkbox"
                onChange={() => isOtherClick()}
              />
              <img
                style={{ cursor: "pointer" }}
                src={isOther === true ? check : uncheck}
              />
              <p>گیرنده شخص دیگری است</p>
            </label>
            {isOther === true && (
              <div className="create-order-inputs-box-2">
                <InputComponent
                  // margin="0 25px 0 0"
                  value={orderDetails.transferee_fullName}
                  width="calc(48% - 20px)"
                  height="44px"
                  type="text"
                  placeholder="نام و نام خانودگی"
                  borderColor="#dcdcdc"
                  inputType="input"
                  onchange={(e) =>
                    setOrderDetails((prev) => {
                      prev.transferee_fullName = e.target.value;
                      forceUpdate();
                      return prev;
                    })
                  }
                />
                <InputComponent
                  // margin="0 25px 0 0"
                  value={orderDetails.transferee_mobile}
                  width="calc(48% - 20px)"
                  height="44px"
                  type="number"
                  placeholder="شماره موبایل"
                  borderColor="#dcdcdc"
                  inputType="input"
                  onchange={(e) =>
                    setOrderDetails((prev) => {
                      prev.transferee_mobile = e.target.value;
                      forceUpdate();
                      return prev;
                    })
                  }
                />
              </div>
            )}

            <div className="create-order-inputs-box-5">
              <button
                onClick={() => openAddressModal()}
                className="create-order-inputs-box-4-add-customer"
              >
                <img src={AllProductAdd} />
                افزودن آدرس
              </button>
            </div>
          </div>
        </div>
        <div className="create-order-table-box">
          <div className="create-order-table-box-2">
            <div className="create-order-table-top-bar">
              <div className="create-order-table-top-bar-hashtag">
                <p>#</p>
              </div>
              <div className="create-order-table-top-bar-product">
                <p>محصول</p>
              </div>
              <div className="create-order-table-top-bar-variable">
                <p>متغیر</p>
              </div>
              <div className="create-order-table-top-bar-price">
                <p>مبلغ (تومان)</p>
              </div>
              <div className="create-order-table-top-bar-count">
                <p>تعداد</p>
              </div>
            </div>
            <SimpleBar forceVisible="y" className="create-order-table-box-3">
              {chooseProduct.map((item, index) => {
                return (
                  <>
                    {item.checked === true && (
                      <div className="create-order-table-item-box">
                        <div className="create-order-table-item-hashtag">
                          <p>{index + 1}</p>
                        </div>
                        <div className="create-order-table-item-product">
                          <div>
                            <img src={item.image_link} />
                          </div>
                          <p>{item.title}</p>
                        </div>
                        <div className="create-order-table-item-variable">
                          {item.variable_key !== null &&
                            item.variable_key === "رنگ" && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "70px",
                                  // maxWidth: "70px",
                                  height: "30px",
                                  marginTop: "17px",
                                  borderRadius: "10px",
                                  border: `solid 1px ${item.variable_alternative_value}`,
                                }}
                              >
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontFamily: "IRANSansFaNum",
                                    fontWeight: "900",
                                    direction: "rtl",
                                    fontSize: "16px",
                                    color: `${item.variable_alternative_value}`,
                                  }}
                                >
                                  {item.variable_main_value}
                                </p>
                              </div>
                            )}
                          {item.variable_key !== null &&
                            item.variable_key !== "رنگ" && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "70px",
                                  // maxWidth: "70px",
                                  height: "30px",
                                  marginTop: "17px",
                                  borderRadius: "10px",
                                  border: "solid 1px #000000",
                                }}
                              >
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontFamily: "IRANSansFaNum",
                                    fontWeight: "900",
                                    direction: "rtl",
                                    fontSize: "16px",
                                    color: "#000000",
                                  }}
                                >
                                  {item.variable_main_value}
                                </p>
                              </div>
                            )}
                        </div>
                        <div className="create-order-table-item-price">
                          <p>{item.price}</p>
                        </div>
                        <div className="create-order-table-item-count">
                          <button
                            onClick={() => finalIncreasmentCount(index)}
                            className="create-order-table-item-count-plus"
                          >
                            <img src={Plus} />
                          </button>
                          <div className="create-order-table-item-count-number">
                            <p>{item.product_count}</p>
                          </div>
                          <button
                            onClick={() => finalDecreamentCount(index)}
                            className="create-order-table-item-count-minus"
                          >
                            <img
                              src={item.product_count === 1 ? DeleteRed : Minus}
                            />
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </SimpleBar>
            <div className="create-order-table-footer">
              <button
                onClick={() => addProductModalClick()}
                className="create-order-table-footer-add-product-button"
              >
                <img src={AllProductAdd} />
                افزودن محصول
              </button>
              <div className="create-order-table-footer-details-box">
                <p>{final.count} محصول</p>
                <p>{final.price} تومان</p>
              </div>
            </div>
          </div>
        </div>
        <div className="create-order-end-button-box">
          <button
            onClick={() => navigate("/Order")}
            className="create-order-end-button-cancel"
          >
            لغو
          </button>
          <button
            onClick={() => addOrder()}
            className="create-order-end-button-add"
          >
            ثبت سفارش
          </button>
        </div>
      </div>
    </div>
  );
}
