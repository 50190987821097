import React, { useEffect } from "react";
import "../../assets/styles/OrderStyle/CreateOrderDelivery.css";
import TopBar from "../../components/TopBar/TopBar";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import { ShowAlert } from "../../action/index";
import { SendOrderEdit } from "../../action/index";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import axios from "../../js/Axios/Axios";
import { useSelector, useDispatch } from "react-redux";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import whiteEdit from "../../assets/icons/whiteEdit.svg";
import DeliveryTypeIcon from "../../assets/icons/DeliveryTypeIcon.svg";
import AllProductCalender from "../../assets/icons/AllProductCalender.svg";
import Barcode from "../../assets/icons/Barcode.svg";
import useForceUpdate from "use-force-update";
import Modal from "@mui/material/Modal";
import { Calendar } from "react-datepicker2";
import * as moment from "jalali-moment";
import TextField from "@mui/material/TextField";
import { ClockPicker } from "@mui/x-date-pickers/ClockPicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import { useState } from "react";

export default function CreateOrderDelivery() {
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const order_id = useSelector((state) => state.SendOrderId);
  const [modal, setModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [dateTime, setDateTime] = useState("date");
  const [paymentDateTime, setPaymentDateTime] = useState("date");
  const [orderData, setOrderData] = useState(null);
  const [deliveryType, setDeliveryType] = useState([]);
  const [paymentError, setPaymentError] = useState({
    date: false,
    barcode: false,
  });
  const [priceDetail, setPriceDetail] = useState({
    price: null,
    delivery: null,
    discount: null,
  });
  const [coupen, setCoupen] = useState({
    order_id: null,
    user_id: null,
    code: null,
    isAttach: null,
  });
  const [paymentType, setPaymentType] = useState({
    transaction_id: null,
    payment_type: "آنلاین",
    payment_date: null,
    order_id: null,
  });
  const [orderDetails, setOrderDetails] = useState({
    storeDeliveryType_id: null,
    delivery_dateTime: null,
    final_save: 1,
    order_id: null,
  });
  const [final, setFinal] = useState({
    price: null,
    count: null,
  });
  useEffect(() => {
    setOrderDetails((prev) => {
      prev.order_id = order_id.id;
      forceUpdate();
      return prev;
    });
    setPaymentType((prev) => {
      prev.order_id = order_id.id;
      forceUpdate();
      return prev;
    });
    setCoupen((prev) => {
      prev.order_id = order_id.id;
      forceUpdate();
      return prev;
    });
    axios
      .get(`Order/${order_id.id}`)
      .then((res) => {
        console.log(res);
        setOrderData(res.data);
        res.data.order_items.map((item) => {
          setFinal((prev) => {
            prev.count += item.product_count;
            forceUpdate();
            return prev;
          });
        });
        setPriceDetail((prev) => {
          prev.price = res.data.final_amount_order;
          forceUpdate();
          return prev;
        });
        setCoupen((prev) => {
          prev.user_id = res.data.customer_id;
          forceUpdate();
          return prev;
        });
        axios
          .get(
            `StoreDeliveryType?user_id=${res.data.customer_id}&order_id=${order_id.id}`
          )
          .then((res) => {
            console.log(res);
            if (res.data.status) {
              setDeliveryType(res.data.data);
              forceUpdate();
            }
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  useEffect(() => {
    if (order_id.id === null) {
      navigate("/Order");
    }
  }, [order_id]);
  const addDeliveryType = (id, price) => {
    setOrderDetails((prev) => {
      prev.storeDeliveryType_id = id;
      forceUpdate();
      return prev;
    });
    setPriceDetail((prev) => {
      prev.delivery = price;
      forceUpdate();
      return prev;
    });
  };
  const addDeliveryDateModal = () => {
    setModal(!modal);
    forceUpdate();
  };
  const paymentTypeClick = (id) => {
    setPaymentType((prev) => {
      prev.payment_type = id;
      prev.transaction_id = null;
      prev.payment_date = null;
      forceUpdate();
      return prev;
    });
  };
  const generateBarcode = () => {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 10; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    setPaymentType((prev) => {
      prev.transaction_id = text;
      forceUpdate();
      return prev;
    });
    setPaymentError((prev) => {
      prev.barcode = false
      forceUpdate()
      return prev
    })
    forceUpdate();
  };
  const OpenPaymentModal = () => {
    setPaymentModal(!paymentModal);
  };
  const verifyCoupen = () => {
    // setCoupen((prev) => {
    //   prev.isAttach = 0;
    //   forceUpdate();
    //   return prev;
    // });
    coupen.isAttach = 0;
    axios
      .post("/Discount/checkVerifyCoupon", coupen)
      .then((res) => {
        console.log(res);
        if (res.data.status === 0) {
          dispatch(ShowAlert(true, res.data.message));
        }
        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));
          setPriceDetail((prev) => {
            prev.discount = res.data.data;
            forceUpdate();
            return prev;
          });
          setCoupen((prev) => {
            prev.isAttach = 1;
            forceUpdate();
            return prev;
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const shareOrder = () => {
    if (paymentType.payment_type === "آنلاین") {
      axios
        .post("Order", orderDetails)
        .then((res) => {
          console.log( "zzzz", res);
          if (res.data.status) {
            navigate("/Order");
            dispatch(ShowAlert(true, res.data.message));
            // if (paymentType.payment_type != "آنلاین") {
            //   axios
            //     .post("OrderPayment", paymentType)
            //     .then((res) => {
            //       console.log(res);
            //     })
            //     .catch((e) => {
            //       console.log(e);
            //     });
            // }
            if (coupen.isAttach === 1) {
              axios
                .post("/Discount/checkVerifyCoupon", coupen)
                .then((res) => {
                  console.log(res);
                })
                .catch((e) => {
                  console.log(e);
                });
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      if (paymentType.payment_date === null || paymentType.payment_date === "") {
        setPaymentError((prev) => {
          prev.date = true;
          forceUpdate();
          return prev;
        });
      }
      if (paymentType.transaction_id === null || paymentType.transaction_id === "") {
        setPaymentError((prev) => {
          prev.barcode = true;
          forceUpdate();
          return prev;
        });
      }
      if (
        paymentType.payment_date != null &&
        paymentType.payment_date != "" &&
        paymentType.transaction_id != null &&
        paymentType.transaction_id != ""
      ) {
        axios
          .post("Order", orderDetails)
          .then((res) => {
            console.log( "zzzz", res);
            navigate("/Order");
            if (res.data.status) {
              dispatch(ShowAlert(true, res.data.message));
              // if (paymentType.payment_type != "آنلاین") {
              axios
                .post("OrderPayment", paymentType)
                .then((res) => {
                  console.log(res);
                })
                .catch((e) => {
                  console.log(e);
                });
              // }
              if (coupen.isAttach === 1) {
                axios
                  .post("/Discount/checkVerifyCoupon", coupen)
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        let text = `پر کردن فیلد  
        ${paymentType.payment_date == null ||
          paymentType.payment_date == "" ? "تاریخ و زمان پرداخت و " : "" }
        ${paymentType.transaction_id == null ||
          paymentType.transaction_id == "" ? "شناسه پرداخت" : "" }
          الزامی است .
        `
        dispatch(ShowAlert(true , text))
      }
    }
  };
  const EditOrder = () => {
    axios
      .get(`Order/${order_id.id}/edit`)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          dispatch(SendOrderEdit(res.data.data));
          navigate("/CreateOrder");
          dispatch(ShowAlert(true, res.data.message));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <div className="order-delivery-all">
      <TopBar />
      <Modal
        onBackdropClick={() => addDeliveryDateModal()}
        className="create-order-modal-all"
        open={modal}
      >
        <div style={{ backgroundColor: "#fff", direction: "ltr" }}>
          {dateTime === "date" && (
            <Calendar
              isGregorian={false}
              onChange={(value) => {
                let date = moment(value._d, "YYYY/MM/DD")
                  .locale("fa")
                  .format("YYYY/MM/DD");
                setOrderDetails((prev) => {
                  prev.delivery_dateTime = date;
                  forceUpdate();
                  return prev;
                });
                setDateTime("time");
              
              }}
              
            />
          )}
          {dateTime === "time" && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticTimePicker
                ampm={false}
                orientation="landscape"
                openTo="minutes"
                // value={value}
                onChange={(e) => {
                  let time = moment(e).format("HH:mm");
                  setOrderDetails((prev) => {
                    prev.delivery_dateTime =
                      prev.delivery_dateTime + " " + time;
                    forceUpdate();
                    return prev;
                  });
                  setDateTime("date");
                  setModal(!modal);
                  forceUpdate();
                  console.log(orderDetails);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          )}
        </div>
      </Modal>
      {/* ////////////////////////////// */}
      <Modal
        onBackdropClick={() => OpenPaymentModal()}
        className="create-order-modal-all"
        open={paymentModal}
      >
        <div style={{ backgroundColor: "#fff", direction: "ltr" }}>
          {paymentDateTime === "date" && (
            <Calendar
              isGregorian={false}
              onChange={(value) => {
                let date = moment(value._d, "YYYY/MM/DD")
                  .locale("fa")
                  .format("YYYY/MM/DD");
                setPaymentType((prev) => {
                  prev.payment_date = date;
                  forceUpdate();
                  return prev;
                });
                setPaymentDateTime("time");
                setPaymentError((prev) => {
                  prev.date = false
                  forceUpdate()
                  return prev
                })
              }}
            />
          )}
          {paymentDateTime === "time" && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticTimePicker
                ampm={false}
                orientation="landscape"
                openTo="minutes"
                // value={value}
                onChange={(e) => {
                  let time = moment(e).format("HH:mm");
                  setPaymentType((prev) => {
                    prev.payment_date = prev.payment_date + " " + time;
                    forceUpdate();
                    return prev;
                  });
                  setPaymentDateTime("date");
                  setPaymentModal(!paymentModal);
                  forceUpdate();
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          )}
        </div>
      </Modal>
      {/*  */}
      <div className="order-delivery-all-box">
        <div className="order-delivery-detail-box">
          <div className="order-delivery-detail-title-box">
            <div className="order-delivery-detail-title-box-2">
              {orderData !== null && (
                <>
                  <p>سفارش {orderData.order_barcode}</p>
                  <p>-</p>
                  <p>{orderData.submit_date}</p>
                  {orderData.order_status === "در انتظار پرداخت" && (
                    <div className="order-delivery-detail-title-box-2-waiting">
                      <p>{orderData.order_status}</p>
                    </div>
                  )}
                  {orderData.order_status === "در حال انجام" && (
                    <div className="order-delivery-detail-title-box-2-processing">
                      <p>{orderData.order_status}</p>
                    </div>
                  )}
                  {orderData.order_status === "تکمیل شده" && (
                    <div className="order-delivery-detail-title-box-2-completed">
                      <p>{orderData.order_status}</p>
                    </div>
                  )}
                  {orderData.order_status === "لغو شده" && (
                    <div className="order-delivery-detail-title-box-2-cancel">
                      <p>{orderData.order_status}</p>
                    </div>
                  )}
                </>
              )}
            </div>
            <button
              onClick={() => EditOrder()}
              className="order-delivery-detail-title-button"
            >
              <img src={whiteEdit} />
              ویرایش
            </button>
          </div>
          <div className="order-delivery-detail-title-box-3">
            <div className="order-delivery-detail-title-box-4">
              <div className="order-delivery-detail-title-box-5">
                <div className="order-delivery-detail-title-box-customer-details-1">
                  <p>اطلاعات مشتری</p>
                </div>

                <div className="order-delivery-detail-title-box-customer-details-3">
                  <p>آدرس</p>
                </div>
              </div>
              <div className="order-delivery-detail-title-box-6">
                <div className="order-delivery-detail-title-box-7">
                  <div className="order-delivery-detail-title-box-customer-box">
                    <p>نام و نام خانوادگی</p>
                    <p>موبایل</p>
                    <p>ایمیل</p>
                  </div>
                  <div className="order-delivery-detail-title-box-customer-box-2">
                    {orderData != null && (
                      <>
                        <p>{orderData.customer_name}</p>
                        <p>{orderData.customer_phoneNumber}</p>
                        <p>{orderData.customer_email}</p>
                      </>
                    )}
                  </div>
                </div>

                <div className="order-delivery-detail-title-box-9">
                  <div className="order-delivery-detail-title-box-customer-box-5">
                    <p>آدرس</p>
                    <p>کد پستی</p>
                    <p>گیرنده</p>
                  </div>
                  <div className="order-delivery-detail-title-box-customer-box-2">
                    {orderData != null && (
                      <>
                        <div className="order-delivery-detail-title-box-customer-box-3">
                          <p>
                            {orderData.delivery_address.title}:{" "}
                            {orderData.delivery_address.address_details}
                          </p>
                          <p>
                            {orderData.delivery_address.state} ,{" "}
                            {orderData.delivery_address.city}
                          </p>
                        </div>
                        <p>{orderData.delivery_address.post_code}</p>
                        <p>{orderData.transferee_fullName}</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="order-delivery-table-box">
              <div className="order-delivery-table-box-top-bar">
                <div className="order-delivery-table-box-top-bar-hashtag">
                  <p>#</p>
                </div>
                <div className="order-delivery-table-box-top-bar-product">
                  <p>محصولات</p>
                </div>
                <div className="order-delivery-table-box-top-bar-variable">
                  <p>متغیر</p>
                </div>
                <div className="order-delivery-table-box-top-bar-count">
                  <p>تعداد</p>
                </div>
                <div className="order-delivery-table-box-top-bar-price">
                  <p>قیمت (تومان)</p>
                </div>
              </div>
              <div className="order-delivery-table-box-item-box">
                {orderData !== null &&
                  orderData.order_items.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="order-delivery-table-box-item"
                      >
                        <div className="order-delivery-table-box-item-hashtag">
                          <p>{index + 1}</p>
                        </div>
                        <div className="order-delivery-table-box-item-product">
                          <div className="order-delivery-table-box-item-product-2">
                            <img src={item.product_image} />
                          </div>
                          <div className="order-delivery-table-box-item-product-name">
                          <p>{item.product_name}</p>

                          </div>
                        </div>
                        <div className="order-delivery-table-box-item-variable">
                          {item.variableProperty_key !== null &&
                            item.variableProperty_key === "رنگ" && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "36px",
                                  maxWidth: "36px",
                                  height: "10px",
                                  borderRadius: "10px",
                                  border: `solid 1px ${item.variableProperty_alterValue}`,
                                  backgroundColor: `${item.variableProperty_alterValue}`,
                                }}
                              ></div>
                            )}
                          {item.variableProperty_key !== null &&
                            item.variableProperty_key !== "رنگ" && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "36px",
                                  maxWidth: "36px",
                                  height: "10px",
                                  borderRadius: "10px",
                                  border: "solid 1px #000000",
                                }}
                              >
                                <p
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontFamily: "IRANSansFaNum",
                                    fontWeight: "400",
                                    direction: "rtl",
                                    fontSize: "10px",
                                    color: "#000000",
                                  }}
                                >
                                  {item.variableProperty_value}
                                </p>
                              </div>
                            )}
                        </div>
                        <div className="order-delivery-table-box-item-count">
                          <p>{item.product_count}</p>
                        </div>
                        <div className="order-delivery-table-box-item-price">
                          <p>{item.price}</p>
                          {item.discount_amount !== null && (
                            <div className="order-delivery-table-box-item-price-discount">
                              <p>{`${Math.round((item.discount_amount * 100) / (item.price))} %`}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="order-delivery-all-delivery-type-box">
          <div className="order-delivery-all-delivery-type-box-2">
            <p>4.انتخاب نحوه حمل و نقل</p>
            <InputComponent
              disabled={true}
              value={orderDetails.delivery_dateTime}
              margin="30px 0px 0px 0px"
              width="calc(100% - 20px)"
              height="44px"
              type="text"
              // id={"barcodeInput"}
              placeholder="تاریخ و زمان تحویل"
              borderColor="#dcdcdc"
              inputType="inputWithIcon"
              iconSide="left"
              onClick={() => addDeliveryDateModal()}
              iconSrc={AllProductCalender}
              // onchange={(e) =>
              //   setProductInfo((prev) => {
              //     return {
              //       title: prev.title,
              //       latin_title: prev.latin_title,
              //       brand_name: prev.brand_name,
              //       url: prev.url,
              //       barcode: e.target.value,
              //       country: prev.country,
              //       product_introduction: prev.product_introduction,
              //       temp_status: prev.temp_status,
              //     };
              //   })
              // }
            />
            <SimpleBar
              forceVisible="y"
              className="order-delivery-all-delivery-type-box-3-1"
            >
              {deliveryType.map((item, index) => {
                return (
                  <label
                    htmlFor={`delivery${index}`}
                    key={index}
                    className={
                      orderDetails.storeDeliveryType_id ===
                      item.storeDeliveryType_id
                        ? "order-delivery-all-delivery-type-box-4"
                        : "order-delivery-all-delivery-type-box-3"
                    }
                  >
                    <input
                      style={{ display: "none" }}
                      onChange={() =>
                        addDeliveryType(item.storeDeliveryType_id, item.price)
                      }
                      type="radio"
                      id={`delivery${index}`}
                      name="delivery_type"
                    />
                    <div className="order-delivery-all-delivery-type-box-5">
                      <img src={DeliveryTypeIcon} />
                      <p>{item.deliveryType_title}</p>
                    </div>
                    <div className="order-delivery-all-delivery-type-box-6">
                      <p>{item.price}</p>
                      <p>(تومان)</p>
                    </div>
                  </label>
                );
              })}
            </SimpleBar>
          </div>

          <div className="order-delivery-all-delivery-type-box-2">
            <p>5.پرداخت سفارش</p>
            <div className="order-delivery-all-delivery-type-box-3-2">
              <div className="order-delivery-all-delivery-type-box-3-2-box">
                <label
                  htmlFor={"payment_type-Online"}
                  className="order-delivery-all-delivery-type-box-3-2-box2"
                >
                  <div className="order-delivery-all-delivery-type-box-3-2-box-input-box">
                    <input
                      style={{ display: "none" }}
                      name="payment_type"
                      id="payment_type-Online"
                      type="radio"
                      onChange={() => paymentTypeClick("آنلاین")}
                    />
                    <div
                      className={
                        paymentType.payment_type === "آنلاین"
                          ? "order-delivery-all-delivery-type-box-3-2-box-input-active"
                          : "order-delivery-all-delivery-type-box-3-2-box-input"
                      }
                    ></div>
                  </div>
                  <p className="order-delivery-all-delivery-type-box-3-2-box-title">
                    آنلاین
                  </p>
                  <p className="order-delivery-all-delivery-type-box-3-2-box-title-2">
                    پرداخت توسط مشتری از درگاه
                  </p>
                </label>
              </div>
              <div className="order-delivery-all-delivery-type-box-3-2-box">
                <label
                  htmlFor={"payment_type-in_location"}
                  className="order-delivery-all-delivery-type-box-3-2-box2"
                >
                  <div className="order-delivery-all-delivery-type-box-3-2-box-input-box">
                    <input
                      style={{ display: "none" }}
                      name="payment_type"
                      id="payment_type-in_location"
                      type="radio"
                      onChange={() => paymentTypeClick("پرداخت در محل")}
                    />
                    <div
                      className={
                        paymentType.payment_type === "پرداخت در محل"
                          ? "order-delivery-all-delivery-type-box-3-2-box-input-active"
                          : "order-delivery-all-delivery-type-box-3-2-box-input"
                      }
                    ></div>
                  </div>
                  <p className="order-delivery-all-delivery-type-box-3-2-box-title">
                    پرداخت در محل
                  </p>
                </label>
                {paymentType.payment_type === "پرداخت در محل" && (
                  <>
                    <InputComponent
                      disabled={true}
                      value={paymentType.payment_date}
                      margin="20px 0px 0px 0px"
                      width="calc(100% - 20px)"
                      height="44px"
                      type="text"
                      // id={"barcodeInput"}
                      placeholder="تاریخ و زمان پرداخت"
                      borderColor={paymentError.date === true ? "#F23B3B" : "#dcdcdc"}
                      inputType="inputWithIcon"
                      iconSide="left"
                      onClick={() => OpenPaymentModal()}
                      iconSrc={AllProductCalender}
                      // onchange={(e) =>
                      //   setProductInfo((prev) => {
                      //     return {
                      //       title: prev.title,
                      //       latin_title: prev.latin_title,
                      //       brand_name: prev.brand_name,
                      //       url: prev.url,
                      //       barcode: e.target.value,
                      //       country: prev.country,
                      //       product_introduction: prev.product_introduction,
                      //       temp_status: prev.temp_status,
                      //     };
                      //   })
                      // }
                    />
                    <InputComponent
                      disabled={true}
                      value={paymentType.transaction_id}
                      margin="20px 0px 0px 0px"
                      width="calc(100% - 20px)"
                      height="44px"
                      type="text"
                      // id={"barcodeInput"}
                      placeholder="شناسه پرداخت"
                      borderColor={paymentError.barcode === true ? "#F23B3B" : "#dcdcdc"}
                      inputType="inputWithIcon"
                      iconSide="left"
                      onClick={() => generateBarcode()}
                      iconSrc={Barcode}
                      // onchange={(e) =>
                      //   setProductInfo((prev) => {
                      //     return {
                      //       title: prev.title,
                      //       latin_title: prev.latin_title,
                      //       brand_name: prev.brand_name,
                      //       url: prev.url,
                      //       barcode: e.target.value,
                      //       country: prev.country,
                      //       product_introduction: prev.product_introduction,
                      //       temp_status: prev.temp_status,
                      //     };
                      //   })
                      // }
                    />
                  </>
                )}
              </div>
              <div className="order-delivery-all-delivery-type-box-3-2-box">
                <label
                  htmlFor={"payment_type-cart_to_cart"}
                  className="order-delivery-all-delivery-type-box-3-2-box2"
                >
                  <div className="order-delivery-all-delivery-type-box-3-2-box-input-box">
                    <input
                      style={{ display: "none" }}
                      name="payment_type"
                      id="payment_type-cart_to_cart"
                      type="radio"
                      onChange={() => paymentTypeClick("کارت به کارت")}
                    />
                    <div
                      className={
                        paymentType.payment_type === "کارت به کارت"
                          ? "order-delivery-all-delivery-type-box-3-2-box-input-active"
                          : "order-delivery-all-delivery-type-box-3-2-box-input"
                      }
                    ></div>
                  </div>
                  <p className="order-delivery-all-delivery-type-box-3-2-box-title">
                    کارت به کارت
                  </p>
                </label>
                {paymentType.payment_type === "کارت به کارت" && (
                  <>
                    <InputComponent
                      disabled={true}
                      value={paymentType.payment_date}
                      margin="20px 0px 0px 0px"
                      width="calc(100% - 20px)"
                      height="44px"
                      type="text"
                      // id={"barcodeInput"}
                      placeholder="تاریخ و زمان پرداخت"
                      borderColor={paymentError.date === true ? "#F23B3B" : "#dcdcdc"}
                      inputType="inputWithIcon"
                      iconSide="left"
                      onClick={() => OpenPaymentModal()}
                      iconSrc={AllProductCalender}
                      // onchange={(e) =>
                      //   setProductInfo((prev) => {
                      //     return {
                      //       title: prev.title,
                      //       latin_title: prev.latin_title,
                      //       brand_name: prev.brand_name,
                      //       url: prev.url,
                      //       barcode: e.target.value,
                      //       country: prev.country,
                      //       product_introduction: prev.product_introduction,
                      //       temp_status: prev.temp_status,
                      //     };
                      //   })
                      // }
                    />
                    <InputComponent
                      disabled={true}
                      value={paymentType.transaction_id}
                      margin="20px 0px 0px 0px"
                      width="calc(100% - 20px)"
                      height="44px"
                      type="text"
                      // id={"barcodeInput"}
                      placeholder="شناسه پرداخت"
                      borderColor={paymentError.barcode === true ? "#F23B3B" : "#dcdcdc"}
                      inputType="inputWithIcon"
                      iconSide="left"
                      onClick={() => generateBarcode()}
                      iconSrc={Barcode}
                      // onchange={(e) =>
                      //   setProductInfo((prev) => {
                      //     return {
                      //       title: prev.title,
                      //       latin_title: prev.latin_title,
                      //       brand_name: prev.brand_name,
                      //       url: prev.url,
                      //       barcode: e.target.value,
                      //       country: prev.country,
                      //       product_introduction: prev.product_introduction,
                      //       temp_status: prev.temp_status,
                      //     };
                      //   })
                      // }
                    />
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="order-delivery-all-delivery-type-box-2">
            <div className="order-delivery-all-delivery-type-box-2-details-box">
              <div className="order-delivery-all-delivery-type-box-2-details-box-2">
                <div className="order-delivery-all-delivery-type-box-2-details-box-3">
                  <p>مجموع سفارش :</p>
                  <p>{final.count} محصول</p>
                </div>
                <div className="order-delivery-all-delivery-type-box-2-details-box-4">
                  {orderData != null && (
                    <>
                      <p>{priceDetail.price}</p>
                      <p>(تومان)</p>
                    </>
                  )}
                </div>
              </div>
              <div className="order-delivery-all-delivery-type-box-2-details-box-5">
                <div className="order-delivery-all-delivery-type-box-2-details-box-3">
                  <p>هزینه حمل و نقل :</p>
                </div>
                <div className="order-delivery-all-delivery-type-box-2-details-box-5-4">
                  <p>{priceDetail.delivery}</p>
                  <p>(تومان)</p>
                </div>
              </div>
              <div className="order-delivery-all-delivery-type-box-2-details-box-5-4-coupen-box">
                <InputComponent
                  value={coupen.code}
                  width={coupen.code === null ? "100%" : "62%"}
                  height="44px"
                  type="text"
                  inputType="input"
                  backgroundColor="#f7f7f7"
                  placeholder="کوپن تخفیف را وارد کنید"
                  borderColor="#dcdcdc"
                  onchange={(e) =>
                    setCoupen((prev) => {
                      prev.code = e.target.value;
                      forceUpdate();
                      return prev;
                    })
                  }
                />
                {coupen.code !== null && (
                  <button
                    onClick={() => verifyCoupen()}
                    className="order-delivery-all-delivery-type-box-2-details-box-5-4-coupen-box-verify-button"
                  >
                    ثبت کوپن
                  </button>
                )}
              </div>
              <div className="order-delivery-all-delivery-type-box-2-details-box-6">
                <div className="order-delivery-all-delivery-type-box-2-details-box-6-1">
                  <p>مبلغ پرداختی</p>
                </div>
                <div className="order-delivery-all-delivery-type-box-2-details-box-6-2">
                  <p>
                    {priceDetail.delivery +
                      priceDetail.price -
                      priceDetail.discount}
                  </p>
                  <p>(تومان)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="order-delivery-all-button-box">
          <button
            onClick={() => navigate("/Order")}
            className="order-delivery-all-cancel-button"
          >
            لغو
          </button>
          <button
            onClick={() => shareOrder()}
            className="order-delivery-all-share-button"
          >
            ثبت نهایی
          </button>
        </div>
      </div>
    </div>
  );
}
