import React, { useEffect, useRef, useState } from "react";
import "../../assets/styles/ContactUsStyle/ContactUs.css";
import "../../../node_modules/mapbox-gl/src/css/mapbox-gl.css";
import "../../../node_modules/mapbox-gl/dist/mapbox-gl.css";
import TopBar from "../../components/TopBar/TopBar";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import axios from "../../js/Axios/Axios";
import mapboxgl from "mapbox-gl";
import ContactUsPhoneNumber from "../../assets/icons/ContactUsPhoneNumber.svg";
import ContactUsEmail from "../../assets/icons/email-contactUs.svg";
import ContactUsLocation from "../../assets/icons/location-contactUs.svg";
// import ContactUsEmail from "../../assets/icons/ContactUsEmail.svg";
import useForceUpdate from "use-force-update";
import { useDispatch } from "react-redux";
import { ShowAlert } from "../../action/index";
/* eslint-disable import/no-webpack-loader-syntax */
// import mapboxgl from 'mapbox-gl';
// @ts-ignore
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
mapboxgl.accessToken =
  "pk.eyJ1IjoicGVqbWFuc2giLCJhIjoiY2wzOHBnaGpmMDI0MzNrcWY5OWFoYmxsOCJ9.P0FcG83WVSiheMJFwFk0MQ";

export default function ContactUs() {
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const marker = useRef(null);
  const [data, setData] = useState([]);
  const [errors, setError] = useState({
    phone_number: false,
    latlng: false,
    address: false,
    email: false,
    description: false,
  });
  const [desciription, setDescription] = useState({
    key: "description",
    value: "",
    contactToUs_id: null,
  });
  const [number, setNumber] = useState({
    key: "phone-number",
    value: "",
    contactToUs_id: null,
  });
  const [email, setEmail] = useState({
    key: "email",
    value: "",
    contactToUs_id: null,
  });
  const [address, setAddress] = useState({
    key: "Address",
    value: "",
    contactToUs_id: null,
  });
  const [latlng, setLatLng] = useState({
    key: "latlng",
    value: [],
    contactToUs_id: null,
  });
  const [lng, setLng] = useState(51.352694);
  const [lat, setLat] = useState(35.667952);
  const [zoom, setZoom] = useState(4);
  const [isEdit, setIsEdit] = useState(null);
  useEffect(() => {
    axios
      .get("/StoreContactToUs")
      .then((res) => {
        console.log(res);
        setData(res.data);
        if (res.data.data.length === 0) {
          setIsEdit("post");
        } else {
          res.data.data.map((item) => {
            if (item.key === "description") {
              setDescription((prev) => {
                prev.value = item.value;
                prev.contactToUs_id = item.id;
                forceUpdate();
                return prev;
              });
            } else if (item.key === "phone-number") {
              setNumber((prev) => {
                prev.value = item.value;
                prev.contactToUs_id = item.id;
                forceUpdate();
                return prev;
              });
            } else if (item.key === "email") {
              setEmail((prev) => {
                prev.value = item.value;
                prev.contactToUs_id = item.id;
                forceUpdate();
                return prev;
              });
            } else if (item.key === "Address") {
              setAddress((prev) => {
                prev.value = item.value;
                prev.contactToUs_id = item.id;
                forceUpdate();
                return prev;
              });
            } else if (item.key === "latlng") {
              let array = JSON.parse(item.value);
              setLatLng((prev) => {
                prev.value = array;
                prev.contactToUs_id = item.id;
                forceUpdate();
                return prev;
              });
              marker.current.setLngLat(latlng.value).addTo(map.current);
            }
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  //for persion text address
  useEffect(() => {
    if (mapboxgl.getRTLTextPluginStatus() === "unavailable") {
      mapboxgl.setRTLTextPlugin(
        "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
        null,
        true
      );
    }
  }, []);
  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom,
      
    });

    if (marker.current) return;
    marker.current = new mapboxgl.Marker({
      color: "blue",
      draggable:false,
    });
  }, []);

  // set lat lng when click on map
  useEffect(() => {
    if (isEdit !== null) {
      if (!map.current) return;
      map.current.on("click", () => {
        setLng(map.current.getCenter().lng);
        setLat(map.current.getCenter().lat);
        setZoom(map.current.getZoom().toFixed(2));
        let mynumber = parseInt(map.current.getZoom().toFixed(2));
        if (17 <= mynumber && mynumber <= 22.0) {
          marker.current
            .setLngLat([
              map.current.getCenter().lng,
              map.current.getCenter().lat,
            ])
            .addTo(map.current);
          setLatLng((prev) => {
            prev.value = [
              map.current.getCenter().lng,
              map.current.getCenter().lat,
            ];
            forceUpdate();
            return prev;
          });
        }
      });
    }
  });
  // set  zoom when scroll
  useEffect(() => {
    if (!map.current) return;
    map.current.on("move", () => {
      setZoom(map.current.getZoom().toFixed(2));
    });
  });
  const editClick = () => {
    if (isEdit === null) {
      setIsEdit("edit");
    } else if (
      isEdit !== null &&
      number.value != "" &&
      latlng.value.length != 0 &&
      address.value != "" &&
      email.value != "" &&
      desciription.value != ""
    ) {
      let ltlng = JSON.stringify(latlng.value);
      let array = [];
      array.push({
        key: "latlng",
        value: ltlng,
        contactToUs_id: latlng.contactToUs_id,
      });
      array.push(number);
      array.push(email);
      array.push(address);
      array.push(desciription);
      axios
        .post("/StoreContactToUs", array)
        .then((res) => {
          console.log(res);
          if (res.data.status === 0) {
            dispatch(ShowAlert(true, res.data.message));
          }
          if (res.data.status) {
            setIsEdit(null);
            axios
              .get("/StoreContactToUs")
              .then((res) => {
                console.log(res);
                setData(res.data);

                res.data.data.map((item) => {
                  if (item.key === "description") {
                    setDescription((prev) => {
                      prev.value = item.value;
                      prev.contactToUs_id = item.id;
                      forceUpdate();
                      return prev;
                    });
                  } else if (item.key === "phone-number") {
                    setNumber((prev) => {
                      prev.value = item.value;
                      prev.contactToUs_id = item.id;
                      forceUpdate();
                      return prev;
                    });
                  } else if (item.key === "email") {
                    setEmail((prev) => {
                      prev.value = item.value;
                      prev.contactToUs_id = item.id;
                      forceUpdate();
                      return prev;
                    });
                  } else if (item.key === "Address") {
                    setAddress((prev) => {
                      prev.value = item.value;
                      prev.contactToUs_id = item.id;
                      forceUpdate();
                      return prev;
                    });
                  } else if (item.key === "latlng") {
                    let array = JSON.parse(item.value);
                    setLatLng((prev) => {
                      prev.value = array;
                      prev.contactToUs_id = item.id;
                      forceUpdate();
                      return prev;
                    });
                    marker.current.setLngLat(latlng.value).addTo(map.current);
                  }
                });
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      if (number.value == "") {
        setError((prev) => {
          prev.phone_number = true;
          forceUpdate();
          return prev;
        });
      }
      if (latlng.value.length == 0) {
        setError((prev) => {
          prev.latlng = true;
          forceUpdate();
          return prev;
        });
      }
      if (address.value == "") {
        setError((prev) => {
          prev.address = true;
          forceUpdate();
          return prev;
        });
      }
      if (email.value == "") {
        setError((prev) => {
          prev.email = true;
          forceUpdate();
          return prev;
        });
      }
      if (desciription.value == "") {
        setError((prev) => {
          prev.description = true;
          forceUpdate();
          return prev;
        });
      }
      let text = `برای ثبت تماس با ما پر کردن فیلد های 
${desciription.value == "" ? "توضیحات و" : ""}
${number.value === "" ? "تلفن پشتیبانی و " : ""}
${latlng.value.length == 0 ? "موقعیت شرکت و" : ""}
${email.value == "" ? "ایمل و" : ""}
${address.value == "" ? "آدرس شرکت " : ""}
الزامی است .
`;
      dispatch(ShowAlert(true, text));
    }
  };

  return (
    <div className="contact-us-all">
      <div className="contact-us-Top-bar-Box">
        <TopBar />
      </div>
      <div className="contact-us-box">
        <div className="contact-us-title-box">
          <p>تماس با ما</p>
          <button onClick={() => editClick()} className="contact-us-edit-box">
            {isEdit === null ? "ویرایش تماس ما" : "ثبت تغیرات"}
          </button>
        </div>
        <div className="contact-us-text-box">
          {isEdit === null ? (
            <p>{desciription.value}</p>
          ) : (
            <InputComponent
              value={desciription.value}
              width="100%"
              height="100px"
              type="text"
              inputType="textarea"
              placeholder="درباره ما"
              borderColor={errors.description === true ? "#F23B3B" : "#dcdcdc"}
              onchange={(e) => {
                setDescription((prev) => {
                  prev.value = e.target.value;
                  forceUpdate();
                  return prev;
                });
                setError((prev) => {
                  prev.description = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
          )}
        </div>
        <div className="contact-us-info-map-box">
          <div className="contact-us-info-box">
            <div className="contact-us-info-title-box">
              <div className="contact-us-info-title-number-box">
                <img src={ContactUsPhoneNumber} />
                <p>تلفن پشتیبانی</p>
              </div>
              <div className="contact-us-info-title-number-box">
                <img src={ContactUsEmail} />
                <p>پست الکترونیکی</p>
              </div>
              <div className="contact-us-info-title-number-box">
                <img src={ContactUsLocation} />
                <p>آدرس شرکت</p>
              </div>
            </div>
            <div className="contact-us-info-description-box">
              <div className="contact-us-info-description-box-2">
                {isEdit === null ? (
                  <p>{number.value}</p>
                ) : (
                  <InputComponent
                    value={number.value}
                    width="100%"
                    height="44px"
                    type="number"
                    inputType="input"
                    placeholder="شماره تلفن را وارد کنید"
                    borderColor={
                      errors.phone_number === true ? "#F23B3B" : "#dcdcdc"
                    }
                    onchange={(e) => {
                      setNumber((prev) => {
                        prev.value = e.target.value;
                        forceUpdate();
                        return prev;
                      });
                      setError((prev) => {
                        prev.phone_number = false;
                        forceUpdate();
                        return prev;
                      });
                    }}
                  />
                )}
              </div>
              <div className="contact-us-info-description-box-2">
                {isEdit === null ? (
                  <p>{email.value}</p>
                ) : (
                  <InputComponent
                    value={email.value}
                    width="100%"
                    height="44px"
                    type="text"
                    inputType="input"
                    placeholder="ایمیل را وارد کنید"
                    borderColor={errors.email === true ? "#F23B3B" : "#dcdcdc"}
                    onchange={(e) => {
                      setEmail((prev) => {
                        prev.value = e.target.value;
                        forceUpdate();
                        return prev;
                      });
                      setError((prev) => {
                        prev.email = false;
                        forceUpdate();
                        return prev;
                      });
                    }}
                  />
                )}
              </div>
              <div className="contact-us-info-description-box-2">
                {isEdit === null ? (
                  <p>{address.value}</p>
                ) : (
                  <InputComponent
                    value={address.value}
                    width="100%"
                    height="44px"
                    type="text"
                    inputType="input"
                    placeholder="ادرس را وارد کنید"
                    borderColor={
                      errors.address === true ? "#F23B3B" : "#dcdcdc"
                    }
                    onchange={(e) => {
                      setAddress((prev) => {
                        prev.value = e.target.value;
                        forceUpdate();
                        return prev;
                      });
                      setError((prev) => {
                        prev.address = false;
                        forceUpdate();
                        return prev;
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="contact-us-map-box">
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                direction: "ltr",
              }}
              ref={mapContainer}
              className="map-container"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
