import axios from "axios";
import { useState } from "react";

// const baseURL = "http://127.0.0.1:8000/api";
// const baseURL = "http://192.168.1.7:8000/api";
const baseURL = "https://api.vitsell.ir/api";
const AUTH_TOKEN = localStorage.getItem("access_token");

export default axios.create({
  baseURL,
  timeout: 3000000,
  headers: {
    "Content-Type": "application/json",
    Authorization: `bearer ${AUTH_TOKEN}`,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Credentials": true,
    // 'Authorization' : AUTH_TOKEN
  },
});
