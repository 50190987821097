import React, { useEffect, useState } from "react";
import "../../../assets/styles/Blog/ManageBlogStyle/EditBlog.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import { ResetToInit } from "../../../action/index";
import TopBar from "../../../components/TopBar/TopBar";
import BlogIntroduction from "../../../components/Blog/BlogIntroduction/BlogIntroduction";
import BlogAddTag from "../../../components/Blog/BlogAddTag/BlogAddTag";
import BlogAddCategory from "../../../components/Blog/BlogAddCategory/BlogAddCategory";
import BlogSeo from "../../../components/Blog/BlogSeo/BlogSeo";
import BlogContent from "../../../components/Blog/BlogContent/BlogContent";
import { useSelector, useDispatch } from "react-redux";
import { ShowAlert } from "../../../action/index";
import axios from "../../../js/Axios/Axios";
import BookMark from "../../../assets/icons/Bookmark.svg";
import ShowEye from "../../../assets/icons/ShowEye.svg";
import UploadIcon from "../../../assets/icons/Upload.svg";
import { isObjectEmpty } from "../../../helper/Helper";
export default function EditBlog() {
  const blogEdit = useSelector((state) => state.BlogEdit);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const blogInfo = useSelector((state) => state.BlogInfo);
  const blogSeoInfo = useSelector((state) => state.BlogSeoInfo);
  const blogSeoFaq = useSelector((state) => state.BlogSeoFaq);
  const [blogSeoId, setBlogSeoId] = useState(null);

  const shareBlog = (status) => {
    const string = JSON.stringify(blogInfo.blog_text);
    blogInfo.blog_text = string;
    blogInfo.release_status = status;
    axios
      .put(`/blog/${blogEdit.id}`, blogInfo)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          blogSeoInfo.blog_id = res.data.data.id;

          // dispatch(ShowAlert(true , res.data.message));
          console.log(blogSeoId);
          if(blogSeoId !== null) {
            axios
            .put(`/blogSeo/${blogSeoId}`, blogSeoInfo)
            .then((res) => {
              console.log(res);
              if (res.data.status) {
                blogSeoFaq.blogSeo_id = res.data.data.id;
                blogSeoFaq.is_edit = 1;
                blogSeoFaq.faqs.map((item, index) => {
                  if (item.question === null || item.question === "") {
                    blogSeoFaq.faqs.splice(index, 1);
                  }
                });
                axios
                  .post("/blogSeoFaq", blogSeoFaq)
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }
            })
            .catch((e) => {
              console.log(e);
            });
          }else {
            axios
            .post("/blogSeo", blogSeoInfo)
            .then((res) => {
              console.log(res);
              if (res.data.status) {
                blogSeoFaq.blogSeo_id = res.data.data.id;
                blogSeoFaq.is_edit = 1;
                blogSeoFaq.faqs.map((item, index) => {
                  if (item.question === null || item.question === "") {
                    blogSeoFaq.faqs.splice(index, 1);
                  }
                });
                axios
                  .post("/blogSeoFaq", blogSeoFaq)
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }
            })
            .catch((e) => {
              console.log(e);
            });
          }
          
          dispatch(ResetToInit());
          navigate("/ManageBlog");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    if (isObjectEmpty(blogEdit)) {
      navigate("/ManageBlog");
    } else {
      blogEdit.blogSeos.map((item) => {
        setBlogSeoId(item.id);
      });
    }
  }, []);

  return (
    <div className="create-Blog-all">
      <TopBar />
      <div className="create-blog-button-route-box">
        <div className="create-blog-route-box">
          <p className="create-blog-route-title"> بلاگ </p>
          <p className="create-blog-route-title"> &gt;</p>
          <p className="create-blog-route-title">ایجاد بلاگ </p>
          <p className="create-blog-route-title"> &gt;</p>
          <p className="create-blog-route-title"> ذخیره بلاگ</p>
        </div>
        <div className="create-blog-button-box">
          <button
            onClick={() => shareBlog(1)}
            className="create-blog-share-button"
          >
            <img src={UploadIcon} />
            <p>بلاگ منتشر شود</p>
          </button>
          <button
            onClick={() => shareBlog(0)}
            className="create-blog-draft-button"
          >
            <img src={BookMark} />
            <p>ذخیره در پیش نویس ها</p>
          </button>
          {/* <button className="create-blog-show-button">
              <img src={ShowEye} />
              <p>نمایش بلاگ</p>
            </button> */}
        </div>
      </div>
      <div className="create-blog-introduction-box">
        <BlogIntroduction />
      </div>
      <div className="create-blog-content-box">
        <BlogContent />
      </div>
      <div className="create-blog-tag-category-box">
        <div className="create-blog-tag-box">
          <BlogAddTag />
        </div>
        <div className="create-blog-category-box">
          <BlogAddCategory />
        </div>
      </div>
      <div className="create-blog-seo-box">
        <BlogSeo />
      </div>

      <div className="create-blog-down-button-box">
        <button
          onClick={() => shareBlog(1)}
          className="create-blog-share-button"
        >
          <img src={UploadIcon} />
          <p>بلاگ منتشر شود</p>
        </button>
        <button
          onClick={() => shareBlog(0)}
          className="create-blog-draft-button"
        >
          <img src={BookMark} />
          <p>ذخیره در پیش نویس ها</p>
        </button>
      </div>
    </div>
  );
}
