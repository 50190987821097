import React, { Component } from "react";
import "../../../assets/styles/CategoryStyle/Category.css";
import { connect } from "react-redux";
import InputComponent from "../../../components/TextInputComponent/TextInputComponent";
import { PaginateData, ShowAlert } from "../../../action/index";
import { convertObjToArray } from "../../../helper/Helper";
import DropDownComponent from "../../../components/DropDown/DropDown";
import Pagination from "../../../components/Pagination/Pagination";
import TopBar from "../../../components/TopBar/TopBar";
import SearchLogo from "../../../assets/icons/SearchLogo.svg";
import Delete from "../../../assets/icons/Delete.svg";
import DeleteRed from "../../../assets/icons/DeleteRed.svg";
import Edit from "../../../assets/icons/Edit.svg";
import EditActive from "../../../assets/icons/EditActive.svg";
import Star from "../../../assets/icons/Star.svg";
import axios from "../../../js/Axios/Axios";

class BlogCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      currentAxios: "/blogCategory?isPaginated=1",
      searchCategory: "",
      show: true,
      CategoryAllData: [],
      CategoryData: [],
      isDelete: false,
      isEdit: false,

      numberPagination: [],
      currentpage: null,
      from: null,
      search: null,
      paginationLink: [],
      CategoryInfo: {
        id: null,
        title: "",
        lil_name: "",
        description: "",
        productCategory_id: null,
        parent_title: "",
        parent_id: null,
      },
      lastPage: null,
      buttonTittle: "افزودن دسته جدید",
      turnOn: true,
      turnOff: false,
      errors: { title: false },
    };

    this.initialData();
  }

  initialData = async () => {
    if (this.state.CategoryData.length === 0) {
      await axios
        .get("/blogCategory?isPaginated=1")
        .then(async (res) => {
          const tags = await res.data;
          this.setState({ CategoryData: tags.data });
          this.setState({ is_active: tags.data.is_active });
          this.setState({ currentpage: tags.meta.current_page });
          this.setState({ from: tags.meta.from });
          this.setState({ paginationLink: tags.meta.links });
          this.setState({ lastPage: tags.meta.last_page });
          // this.forceUpdate();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.PaginateData.data && props.PaginateData.data.length > 0) {
      state.CategoryData = props.PaginateData.data;
      state.is_active = props.PaginateData.data.is_active;
      state.currentpage = props.PaginateData.meta.current_page;
      state.from = props.PaginateData.meta.from;
      state.lastPage = props.PaginateData.meta.last_page;
      state.paginationLink = props.PaginateData.meta.links;
    }
  }

  searchDropDownChange = (e) => {
    this.state.searchCategory = e.target.value;
    this.forceUpdate();
  };

  searchHandelChange = (e) => {
    this.state.search = e.target.value;
    axios
      .get(`/blogCategory?isPaginated=1&search=${this.state.search}`)
      .then(async (res) => {
        const tags = res.data.data;
        await this.setState({ CategoryData: tags });
        await this.setState({ currentpage: res.data.meta.current_page });
        await this.setState({ from: res.data.meta.from });
        await this.setState({ is_active: res.data.data.is_active });
        await this.setState({ lastPage: res.data.meta.last_page });

        await this.setState({ paginationLink: res.data.meta.links });
        await this.setState({ currentAxios: `/blogCategory?isPaginated=1&search=${this.state.search}` });

        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  CategoryNameHandelChange = (e) => {
    this.state.CategoryInfo.title = e.target.value;
    this.state.errors.title = false;
    this.forceUpdate();
  };
  CategoryLilNameHandelChange = (e) => {
    this.state.CategoryInfo.lil_name = e.target.value;
    this.forceUpdate();
  };
  CategoryDescriptionHandelChange = (e) => {
    this.state.CategoryInfo.description = e.target.value;
    this.forceUpdate();
  };

  addCategoryClick = () => {
    axios
      .post("/blogCategory", this.state.CategoryInfo)
      .then((res) => {
        console.log(res);
        if (res.data.status === 0) {
          this.props.dispatch(ShowAlert(true, res.data.data));
          let array = convertObjToArray(res.data.data);
          array.map((item) => {
            if (item.key === "title") {
              this.state.errors.title = true;
            }
            this.forceUpdate();
          });
        }
        if (res.data.status) {
          console.log(res.data.message);
          this.props.dispatch(ShowAlert(true, res.data.message));
          this.setState({
            CategoryInfo: {
              title: "",
              lil_name: "",
              description: "",
              productCategory_id: null,
              parent_title: "",
              is_active: 1,
            },
          });
          axios
            .get(
              `/blogCategory?isPaginated=1&page=${
                this.state.currentpage == this.state.lastPage &&
                this.state.CategoryData.length === 5
                  ? this.state.currentpage + 1
                  : this.state.currentpage
              }`
            )
            .then((res) => {
              console.log(res);
              this.setState({
                currentAxios: `/blogCategory?isPaginated=1&page=
                ${
                  this.state.currentpage == this.state.lastPage &&
                  this.state.CategoryData.length === 5
                    ? this.state.currentpage + 1
                    : this.state.currentpage
                }`,
              });
              this.props.dispatch(PaginateData(res.data));
              const tags = res.data.data;
              this.setState({ CategoryData: tags });

              this.setState({ currentpage: res.data.meta.current_page });
              this.setState({ lastPage: res.data.meta.last_page });

              this.setState({ is_active: res.data.data.is_active });

              this.setState({ from: res.data.meta.from });
              this.setState({ paginationLink: res.data.meta.links });
              this.forceUpdate();
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  DeleteCategoryHandel = (id) => {
    axios
      .delete(`/blogCategory/${id}`, id)
      .then((res) => {
        console.log(res);
        if (res.data.status === 0) {
          this.props.dispatch(ShowAlert(true, res.data.message));
        }
        if (res.data.status) {
          this.setState({ isDelete: true });
          this.props.dispatch(ShowAlert(true, res.data.message));

          axios
            .get(
              `/blogCategory?isPaginated=1&page=${
                this.state.CategoryData.length == 1
                  ? this.state.currentpage - 1
                  : this.state.currentpage
              }`
            )
            .then(async (res) => {
              let tags = await res.data.data;
              console.log(tags);

              this.setState({
                currentAxios: `/blogCategory?isPaginated=1&page=
                ${
                  this.state.CategoryData.length == 1
                    ? this.state.currentpage - 1
                    : this.state.currentpage
                }`,
              });

              this.props.dispatch(PaginateData(res.data));
              this.setState({ CategoryData: tags });
              this.setState({ currentpage: res.data.meta.current_page });
              this.setState({ from: res.data.meta.from });
              this.setState({ is_active: res.data.data.is_active });
              this.setState({ paginationLink: res.data.meta.links });
              this.setState({ lastPage: res.data.meta.last_page });

              this.forceUpdate();
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  EditTagHandel = (id) => {
    axios
      .get(`/blogCategory/${id}/edit`)
      .then((res) => {
        console.log(res);
        if (res.data.status === 0) {
          this.props.dispatch(ShowAlert(true, res.data.message));
        }
        if (res.data.status) {
          this.props.dispatch(ShowAlert(true, res.data.message));
          this.state.CategoryInfo.title = res.data.data.title;
          this.state.CategoryInfo.lil_name = res.data.data.lil_name;
          this.state.CategoryInfo.description = res.data.data.description;
          this.setState({ is_active: res.data.data.is_active });
          this.state.CategoryInfo.parent_title = res.data.data.parent_title;
          this.state.CategoryInfo.parent_id = res.data.data.parent_id;
          this.state.CategoryInfo.id = id;
          this.state.buttonTittle = "ویرایش دسته";
          this.forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  toggleClick = async (id, myindex) => {
    await axios
      .put(`/blogCategory/${id}`, {
        is_active: !this.state.CategoryData[myindex].is_active,
      })
      .then((res) => {
        if (res.data.status === 0) {
          this.props.dispatch(ShowAlert(true, res.data.message));
        }
        if (res.data.status) {
          this.props.dispatch(ShowAlert(true, res.data.message));

          axios
            .get(`/blogCategory?isPaginated=1&page=${this.state.currentpage}`)
            .then((res) => {
              this.setState({ CategoryData: res.data.data });
              this.setState({ currentpage: res.data.meta.current_page });
              this.setState({ is_active: res.data.data.is_active });
              this.setState({ lastPage: res.data.meta.last_page });
              this.setState({ from: res.data.meta.from });
              this.setState({ paginationLink: res.data.meta.links });
              this.forceUpdate();
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  UpdateClick = () => {
    console.log(this.state.CategoryInfo);
    axios
      .put(
        `/blogCategory/${this.state.CategoryInfo.id}`,
        this.state.CategoryInfo
      )
      .then((res) => {
        console.log(res);
        if (res.data.status === 0) {
          this.props.dispatch(ShowAlert(true, res.data.message));
        }
        if (res.data.status) {
          this.props.dispatch(ShowAlert(true, res.data.message));
          this.state.CategoryInfo.title = "";
          this.state.CategoryInfo.lil_name = "";
          this.state.CategoryInfo.description = "";
          this.state.CategoryInfo.parent_title = "";
          this.state.CategoryInfo.parent_id = null;
          this.state.CategoryInfo.id = null;
          this.state.buttonTittle = " افزودن دسته جدید";
          axios
            .get(`/blogCategory?isPaginated=1&page=${this.state.currentpage}`)
            .then((res) => {
              if (res.data.status) {
                this.props.dispatch(PaginateData(res.data));
                const tags = res.data.data;
                this.setState({ CategoryData: tags });
                this.setState({ currentpage: res.data.meta.current_page });
                this.setState({ is_active: res.data.data.is_active });
                this.setState({ from: res.data.meta.from });
                this.setState({ paginationLink: res.data.meta.links });
                this.setState({ lastPage: res.data.meta.last_page });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  DropDownClick = (title, id) => {
    this.state.CategoryInfo.parent_id = id;
    this.state.CategoryInfo.parent_title = title;
    this.setState({ show: false });
    setTimeout(() => {
      this.setState({ show: true });
    }, 100);

    this.forceUpdate();
    console.log(id);
    console.log(title);
  };

  render() {
    return (
      <div className="CategoryAll">
        <div className="CategoryTopBar">
          <TopBar />
        </div>
        <div className="CategoryUpTitleAndSearchBox">
          <p className="CategoryUpTitleAndSearchBoxTitle">افزودن دسته جدید</p>

          <InputComponent
            width="337px"
            height="44px"
            type="text"
            inputType="inputWithIcon"
            iconSide="right"
            iconSrc={SearchLogo}
            backgroundColor="#f7f7f7"
            placeholder="جستجو دسته بندی.."
            borderColor="#dcdcdc"
            onchange={this.searchHandelChange.bind(this)}
          />
        </div>
        <div className="CategoryBox">
          <div className="CategoryAddCategoryBox">
            <div className="CategoryAddCategoryBoxTittleBox">
              <img className="CategoryAddCategoryBoxTittleIcon" src={Star} />
              <p className="CtegoryAddCategoryBoxTittle">
                دسته‌های محصولات شما از اینجا می‌توانند مدیریت شوند. برای تغییر
                ترتیب دسته‌ها برای نمایش به کاربران مرتب‌سازی کنید
              </p>
            </div>
            <div className="CategoryAddCategoryBoxInputBox">
              <div className="CategoryAddCategoryBoxInputBoxFirstInputBox">
                <p className="CategoryAddCategoryBoxInputBoxFirstInputTittle">
                  نام دسته :
                </p>
                <InputComponent
                  value={this.state.CategoryInfo.title}
                  width="80%"
                  height="44px"
                  type="text"
                  placeholder="برای نمایش به کاربران سایت"
                  borderColor={
                    this.state.errors.title === true ? "#F23B3B" : "#dcdcdc"
                  }
                  inputType="input"
                  onchange={this.CategoryNameHandelChange.bind(this)}
                />
              </div>

              <div className="CategoryAddCategoryBoxInputBoxSecondInputBox">
                <p className="CategoryAddCategoryBoxInputBoxSecondInputTittle">
                  نامک :
                </p>
                <InputComponent
                  value={this.state.CategoryInfo.lil_name}
                  width="80%"
                  height="44px"
                  type="text"
                  placeholder="باید انگلیسی بوده و کوتاهتر از 28 کاراکتر باشد"
                  borderColor="#dcdcdc"
                  inputType="input"
                  onchange={this.CategoryLilNameHandelChange}
                />
              </div>

              <div className="CategoryAddCategoryBoxDropDownBoxSecondDropDownBox">
                <p className="CategoryAddCategoryBoxDropDownBoxSecondDropDownBoxTittle">
                  دسته اصلی :
                </p>
                <DropDownComponent
                  width="80%"
                  height="44px"
                  dropDownHeight="330px"
                  zindex="1"
                  search={this.state.searchCategory}
                  show={this.state.show}
                  axiosname="CategoryData"
                  axiosurl={"/blogCategory?isPaginated=0&isNested=0"}
                  onchange={this.searchDropDownChange.bind(this)}
                  value={this.state.CategoryInfo.parent_title}
                  itemClick={this.DropDownClick.bind(this)}
                  placeholder=" با انتخاب یک (والد) سلسله‌مراتب بسازید"
                />
              </div>

              <div className="CategoryAddCategoryBoxTextAreaBox">
                <p className="CategoryAddCategoryBoxTextAreaBoxTittle">
                  توضیحات :
                </p>
                <InputComponent
                  value={this.state.CategoryInfo.description}
                  width="80%"
                  height="100%"
                  type="text"
                  placeholder="توضیحات دسته بندی "
                  borderColor="#dcdcdc"
                  inputType="textarea"
                  onchange={this.CategoryDescriptionHandelChange}
                />
              </div>

              <div className="CategoryAddCategoryButtonBox">
                {this.state.buttonTittle === "افزودن دسته جدید" ? (
                  <button
                    onClick={this.addCategoryClick.bind(this)}
                    className="CategoryAddCategoryButtonActive"
                  >
                    افزودن دسته جدید
                  </button>
                ) : (
                  <button
                    onClick={this.UpdateClick.bind(this)}
                    className="CategoryAddCategoryButtonActive"
                  >
                    ویرایش دسته
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="CategoryShowBox">
            <div className="CategoryShowBox2">
              <div className="CategoryshowBox2TopBar">
                <p className="CategoryhowBox2TopBarTittle6">#</p>
                <p className="CategoryshowBox2TopBarTittle5">نام</p>
                <p className="CategoryshowBox2TopBarTittle7">دسته اصلی</p>
                <p className="CategoryshowBox2TopBarTittle3">نامک</p>
                <p className="CategoryshowBox2TopBarTittle4">وضیعت</p>
                <p className="CategoryshowBox2TopBarTittle2">تعداد</p>
                <p className="CategoryshowBox2TopBarTittle1">عملیات</p>
              </div>

              {this.state.CategoryData.map((myitem, myindex) => {
                return (
                  <div
                    key={myitem.id}
                    style={{
                      borderRadius:
                        this.state.CategoryData.length == myindex + 1
                          ? "0 0 10px 10px"
                          : "",
                      borderBottom:
                        this.state.CategoryData.length == myindex + 1
                          ? "solid 0.5px #00589c"
                          : "",
                    }}
                    className="CategoryshowBox2ItemBox"
                  >
                    <div className="CategoryshowBox2ItemNumberListBox">
                      <p className="CategoryshowBox2ItemNumberListTittle">
                        {this.state.from + myindex}
                      </p>
                    </div>

                    <div className="CategoryshowBox2ItemNameBox">
                      <p className="CategoryshowBox2ItemNameTittle">
                        {myitem.title}
                      </p>
                    </div>

                    <div className="CategoryshowBox2ItemMainParentBox">
                      <p className="CategoryshowBox2ItemMainParentTittle">
                        {myitem.parent_category != null
                          ? `${myitem.parent_category}`
                          : "-"}
                      </p>
                    </div>

                    <div className="CategoryshowBox2ItemLilNameBox">
                      <p className="CategoryshowBox2ItemLilNameTittle">
                        {myitem.lil_name}
                      </p>
                    </div>

                    <div className="CategoryshowBox2ItemSituationBox">
                      <div className="CategoryshowBox2ItemSituation">
                        <div
                          className={
                            myitem.is_active === 1
                              ? "CategoryshowBox2ItemSituationHoverBox"
                              : "CategoryshowBox2ItemSituationHoverBox2"
                          }
                        ></div>

                        <p
                          onClick={this.toggleClick.bind(
                            this,
                            myitem.id,
                            myindex
                          )}
                          className={
                            myitem.is_active === 0
                              ? "CategoryshowBox2ItemSituationTittle2"
                              : "CategoryshowBox2ItemSituationTittle"
                          }
                        >
                          غیر فعال
                        </p>
                        <p
                          onClick={this.toggleClick.bind(
                            this,
                            myitem.id,
                            myindex
                          )}
                          className={
                            myitem.is_active === 1
                              ? "CategoryshowBox2ItemSituationTittle2"
                              : "CategoryshowBox2ItemSituationTittle"
                          }
                        >
                          فعال
                        </p>
                      </div>
                    </div>

                    <div className="CategoryshowBox2ItemNumberBox">
                      <p className="CategoryshowBox2ItemNumberTittle">
                        {myitem.blogs_count}
                      </p>
                    </div>

                    <div className="CategoryshowBox2ItemBoxIconBox">
                      <button
                        onClick={this.DeleteCategoryHandel.bind(
                          this,
                          myitem.id
                        )}
                        className="CategoryshowBox2ItemBoxIconDelteButton"
                      >
                        <img
                          src={DeleteRed}
                          // onMouseOver={(e) => (e.currentTarget.src = DeleteRed)}
                          // onMouseOut={(e) => (e.currentTarget.src = Delete)}
                        />
                      </button>
                      <button
                        onClick={this.EditTagHandel.bind(
                          this,
                          myitem.id,
                          myitem.title,
                          myitem.lil_name,
                          myitem.description
                        )}
                        className="CategoryshowBox2ItemBoxIconEditButton"
                      >
                        <img
                          src={EditActive}
                          // onMouseOver={(e) =>
                          //   (e.currentTarget.src = EditActive)
                          // }
                          // onMouseOut={(e) => (e.currentTarget.src = Edit)}
                        />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="CategoryPaginationBox">
              <Pagination
                // firstAxios="/productCategory?isPaginated=1"
                firstAxios={this.state.currentAxios}
                currentAxios="/blogCategory?isPaginated=1&page="

                // paginateNumber={this.state.paginationLink}
                // currentPage={this.state.currentpage}
                // currentClick={this.currentClick.bind(this)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    PaginateData: state.PaginatedData,
  };
};

export default connect(mapStateToProps)(BlogCategory);
