import React, { useEffect, useState } from "react";
import "../../assets/styles/Blog/GarbageBlog/GarbageBlog.css";
import TopBar from "../../components/TopBar/TopBar";
import axios from "../../js/Axios/Axios";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { ShowAlert } from "../../action/index";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import Pagination from "../../components/Pagination/Pagination";
import Modal from "@mui/material/Modal";
import { useSelector, useDispatch } from "react-redux";
import useForceUpdate from "use-force-update";
import AllProductAdd from "../../assets/icons/AllProductAdd.svg";
import GarbageDelte from "../../assets/icons/GarbageDelte.svg";
import restore from "../../assets/icons/restore.svg";
import restoreActive from "../../assets/icons/restoreActive.svg";
import AllProductTrashRed from "../../assets/icons/GarbageDelteRed.svg";
import AllProductTrash from "../../assets/icons/AllProductShare.svg";
import SearchLogo from "../../assets/icons/SearchLogo.svg";
import headerCheck from "../../assets/icons/headerCheck.svg";
import check from "../../assets/icons/check.svg";
import Delete from "../../assets/icons/Delete.svg";
import DeleteRed from "../../assets/icons/DeleteRed.svg";
import Eye from "../../assets/icons/AllProductEye.svg";
import uncheck from "../../assets/icons/uncheck.svg";

export default function GarbageProduct() {
  const location = useLocation();
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false); // for open modal
  const [garbageData, setGarbageData] = useState([]); // all garbage data
  const [searchGarbage, setSearchGarbage] = useState(); // seasrch state
  const [currentPage, setCurrentPage] = useState(null); // currentpage for paginated
  const [from, setFrom] = useState(null); // from to paginated
  const [checkedIds, setCheckedIds] = useState([]);
  const [topBarChecked, setTopBarChecked] = useState(false);
  const [firstAxios , setFirstAxios] = useState("/ProductComment/trash??isPaginated=1")
  const [blogIds, setBlogIds] = useState({
    productComment_ids: [],
  });
  const paginatedData = useSelector((state) => state.PaginatedData);
  // get all garbage data
  useEffect(() => {
    axios
      .get("/ProductComment/trash??isPaginated=1")
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setGarbageData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  useEffect(() => {
    if (paginatedData.length != 0) {
      // if (topBarChecked === true || checkedIds.length != 0) {
      //   setTopBarChecked(false);
      //   garbageData.map((i, index) => {
      //     checkedIds.splice(index);
      //   });
      // }
      setGarbageData(paginatedData.data);
      setCurrentPage(paginatedData.meta.current_page);
      setFrom(paginatedData.meta.from);
    }
  }, [paginatedData]);

  const checkBoxOnChange = (id) => {
    if (!blogIds.productComment_ids.includes(id)) {
      blogIds.productComment_ids.push(id);
    } else {
      var index = blogIds.productComment_ids.indexOf(id);
      blogIds.productComment_ids.splice(index, 1);
    }
    forceUpdate();
    console.log(blogIds);
  };
  const allCheckBoxOnChange = () => {
    if (blogIds.productComment_ids.length != garbageData.length) {
      garbageData.map((item) => {
        if (!blogIds.productComment_ids.includes(item.id)) {
          blogIds.productComment_ids.push(item.id);
        }
      });
    } else if (blogIds.productComment_ids.length === garbageData.length) {
      garbageData.map((item, i) => {
        blogIds.productComment_ids.splice(i);
      });
    }
    forceUpdate();
  };
  const restoreBlog = (id) => {
    let array = {
      productComment_ids: [],
    };
    array.productComment_ids.push(id);
    axios
      .put("/ProductComment/restoreGarbage", array)
      .then((res) => {
        console.log(res);
        if (res.data.status === 0) {
          dispatch(ShowAlert(true, res.data.message));
        }

        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));
          axios
            .get("/ProductComment/trash??isPaginated=1")
            .then((res) => {
              if (res.data.status) {
                console.log(res);
                setGarbageData(res.data.data);
                setCurrentPage(res.data.meta.current_page);
                setFrom(res.data.meta.from);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const allRestor = () => {
    if (blogIds.productComment_ids.length > 0) {
      axios
        .put("/ProductComment/restoreGarbage", blogIds)
        .then((res) => {
          console.log(res);
          if (res.data.status === 0) {
            dispatch(ShowAlert(true, res.data.message));
          }

          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));
            blogIds.productComment_ids.map((item, i) => {
              blogIds.productComment_ids.splice(i);
            });
            axios
              .get("/ProductComment/trash??isPaginated=1")
              .then((res) => {
                if (res.data.status) {
                  console.log(res);
                  setGarbageData(res.data.data);
                  setCurrentPage(res.data.meta.current_page);
                  setFrom(res.data.meta.from);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      dispatch(ShowAlert(true, "لطفا نظرات را انتخاب کنید ."));
    }
  };
  const openModalForDelte = () => {
    if (blogIds.productComment_ids.length != 0) {
      setModal(true);
      forceUpdate();
    } else {
      dispatch(ShowAlert(true, "لطفا نظرات را انتخاب کنید ."));
    }
  };
  const cancelDeleteClick = () => {
    setModal(false);
    blogIds.productComment_ids.map((item, i) => {
      blogIds.productComment_ids.splice(i);
    });
  };
  const compeleteDelete = () => {
    var blogids = JSON.stringify(blogIds.productComment_ids);
    axios
      .delete(`/ProductComment/forceDelete?productComment_ids=${blogids}`)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          dispatch(ShowAlert(true, res.data.message));
          blogIds.productComment_ids.map((item, index) => {
            blogIds.productComment_ids.splice(index);
          });
          setModal(false);
          axios
            .get("/ProductComment/trash??isPaginated=1")
            .then((res) => {
              if (res.data.status) {
                console.log(res);
                setGarbageData(res.data.data);
                setCurrentPage(res.data.meta.current_page);
                setFrom(res.data.meta.from);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const searchHandelChange = (e) => {
    axios
      .get(`/ProductComment/trash??isPaginated=1&search=${e.target.value}`)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setGarbageData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          setFirstAxios(`/ProductComment/trash??isPaginated=1&search=${e.target.value}`)
          forceUpdate()
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const delteComment = (id) => {
    blogIds.productComment_ids.push(id);
    setModal(true);
  };

  return (
    <div className="garbage-All">
      <div className="garbage-top-bar">
        <TopBar />
      </div>
      <div className="garbage-button-search-box">
        <Modal
          onBackdropClick={() => cancelDeleteClick()}
          className="garbage-modal"
          open={modal}
        >
          <div className="garbage-modal-delete-box">
            <div className="garbage-modal-delete-icon-box">
              <p>حذف دیدگاه محصول</p>
              <img src={DeleteRed} />
            </div>
            <div className="garbage-modal-delete-box-title-box">
              <p className="garbage-modal-delete-box-title">
                ایا از پاک کردن دیدگاه محصول به طور کامل مطمئن هستید؟
              </p>
            </div>
            <div className="garbage-modal-delete-box-button-box">
              <button
                onClick={() => cancelDeleteClick()}
                className="garbage-modal-delete-box-button-cancel"
              >
                <p className="garbage-modal-delete-box-button-cancel-title">
                  انصراف
                </p>
              </button>

              <button
                onClick={() => compeleteDelete()}
                className="garbage-modal-delete-box-button-delete"
              >
                <p className="garbage-modal-delete-box-button-delete-title">
                  حذف
                </p>
              </button>
            </div>
          </div>
        </Modal>
        <div className="garbage-button-box">
          <button
            onClick={() => navigate("/ManageCustomerFeedback")}
            className="garbage-add-button"
          >
            <img src={AllProductAdd} />
            <p className="garbage-add-button-title">ایجاد دیدگاه</p>
          </button>
        </div>
        <div className="garbage-search-box">
          <InputComponent
            width="337px"
            height="44px"
            type="text"
            inputType="inputWithIcon"
            iconSide="right"
            iconSrc={SearchLogo}
            backgroundColor="#f7f7f7"
            placeholder="جستجو محصول.."
            borderColor="#dcdcdc"
            onchange={(e) => searchHandelChange(e)}
          />
        </div>
      </div>
      <div className="garbage-tabel-box">
        <div
          style={{
            borderLeft: "1px solid #dcdcdc",
            borderRight: "1px solid #dcdcdc",
            borderRadius: "10px 10px 0 0",
          }}
          className={"all-products-header dragscroll"}
        >
          <div className="all-products-tab-bar">
            <div className="all-products-tab-bar-right">
              <p
                onClick={() => navigate("/ManageCustomerFeedback")}
                style={
                  location.pathname == "/ManageCustomerFeedback"
                    ? {
                      color: "#00457b",
                      fontWeight: "bold",
                    }
                    : {}
                }
              >
                دیدگاه ها
              </p>

              <p
                onClick={() => navigate("/GarbageCommentProduct")}
                style={
                  location.pathname == "/GarbageCommentProduct"
                    ? {
                      color: "#00457b",
                      fontWeight: "bold",
                    }
                    : {}
                }
              >
                زباله دان
              </p>
            </div>

            <div className="all-products-tab-bar-left">
              <button
                onClick={() => allRestor()}
                style={
                  blogIds.productComment_ids.length > 0
                    ? { backgroundColor: "#008753" }
                    : {}
                }
                className={"restore-button-deactive"}
              // className={
              //   checkedIds != 0
              //     ? "garbage-restor-button"
              //     : "garbage-restor-button-deactive"
              // }
              >
                {blogIds.productComment_ids.length > 0 ? (
                  <img src={restoreActive} />
                ) : (
                  <img src={restore} />
                )}
                <p
                  style={
                    blogIds.productComment_ids.length > 0
                      ? { color: "#fff" }
                      : {}
                  }
                  className="garbage-restor-button-title"
                >
                  بازیابی دیدگاه ها
                </p>
              </button>

              <button
                onClick={() => openModalForDelte()}
                style={
                  blogIds.productComment_ids.length > 0
                    ? { backgroundColor: "#f23b3b" }
                    : {}
                }
                // onClick={this.garbageClick.bind(this)}
                className="AllProductTopShowTrashButton"
              >
                {blogIds.productComment_ids.length > 0 ? (
                  <img src={GarbageDelte} />
                ) : (
                  <img src={AllProductTrashRed} />
                )}
                <p
                  style={
                    blogIds.productComment_ids.length > 0
                      ? { color: "#fff" }
                      : {}
                  }
                  className="AllProductTopShowTrashButtonTittle"
                >
                  حذف
                </p>
              </button>
            </div>
          </div>

          <div className="garbage-table-top-bar">
            <p className="comment-blog-top-bar-hashtag">#</p>
            <div className="comment-blog-top-bar-checkbox">
              <label>
                <img
                  src={
                    blogIds.productComment_ids.length === garbageData.length &&
                      garbageData.length != 0
                      ? headerCheck

                      : uncheck
                  }
                />
                <input
                  style={{ display: "none" }}
                  onChange={() => allCheckBoxOnChange()}
                  id="allCheckBox"
                  name="allCheckBox"
                  type="checkbox"
                />
              </label>
            </div>
            <p className="comment-blog-top-bar-limit-comment">متن دیدگاه</p>
            <p className="comment-blog-top-bar-blog-title">عنوان بلاگ</p>
            <p className="comment-blog-top-bar-username">نام کاربری</p>
            <p className="comment-blog-top-bar-date">تاریخ ثبت</p>
            <p className="comment-blog-top-bar-situation">وضعیت</p>
            <p className="comment-blog-top-bar-opation">عملیات</p>
          </div>
        </div>

        {garbageData.map((item, index) => {
          return (
            <div
              key={index}
              style={
                garbageData.length == index + 1
                  ? {
                    borderLeft: "1px solid #dcdcdc",
                    borderRight: "1px solid #dcdcdc",
                    borderBottom: "1px solid #dcdcdc",
                    borderRadius: "0 0 10px 10px",
                  }
                  : {
                    borderLeft: "1px solid #dcdcdc",
                    borderRight: "1px solid #dcdcdc",
                    borderBottom: "1px solid #dcdcdc",
                  }
              }
              className={"comment-blog-item-box"}
            >
              <div className="comment-blog-item-detail-box">
                <div className="comment-blog-item-number">
                  <p>{from + index}</p>
                </div>
                <div className="comment-blog-item-checkbox">
                  <label htmlFor={`id${index}`}>
                    <img
                      src={
                        blogIds.productComment_ids.includes(item.id)
                          ? check
                          : uncheck
                      }
                    />
                    <input
                      style={{ display: "none" }}
                      id={`id${index}`}
                      name={`id${index}`}
                      onChange={() => checkBoxOnChange(item.id)}
                      type="checkbox"
                    />
                  </label>
                </div>
                <div className="comment-blog-item-limit-comment">
                  <p>{item.comment_text}</p>
                </div>
                <div className="comment-blog-item-blog-title">
                  <p>{item.blog}</p>
                </div>
                <div className="comment-blog-item-username">
                  <p>{item.user_name}</p>
                </div>
                <div className="comment-blog-item-date">
                  <p>{item.created_at}</p>
                </div>
                <div className="comment-blog-item-situation">
                  {item.comment_status === "تایید شده" && (
                    <div className="comment-blog-item-situation-1">
                      <p>{item.comment_status}</p>
                    </div>
                  )}

                  {item.comment_status === "رد شده" && (
                    <div className="comment-blog-item-situation-2">
                      <p>{item.comment_status}</p>
                    </div>
                  )}

                  {item.comment_status === "در انتظار بررسی" && (
                    <div className="comment-blog-item-situation-3">
                      <p>{item.comment_status}</p>
                    </div>
                  )}
                </div>
                <div className="comment-blog-item-operation">
                  <img onClick={() => restoreBlog(item.id)} src={restore} />
                  <img
                    onClick={() => delteComment(item.id)}
                    src={DeleteRed}
                  // onMouseOver={(e) => (e.currentTarget.src = DeleteRed)}
                  // onMouseOut={(e) => (e.currentTarget.src = Delete)}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="garbage-pagination-box">
        <Pagination
          firstAxios={firstAxios}
          currentAxios="/ProductComment/trash??isPaginated=1&page="
        />
      </div>
    </div>
  );
}
