import React, { useEffect, useState } from "react";
import "../../../assets/styles/Blog/CreateBlogStyle/BlogAddCategory.css";
import axios from "../../../js/Axios/Axios";
import { useSelector, useDispatch } from "react-redux";
import useForceUpdate from "use-force-update";
import DropDownComponent from "../../DropDown/DropDown";
import TextInputComponent from "../../TextInputComponent/TextInputComponent";
import { ShowAlert } from "../../../action/index";
import { AddBlogCategory } from "../../../action/index";
import { isObjectEmpty } from "../../../helper/Helper";
import { useLocation } from "react-router-dom";
import check from "../../../assets/icons/check.svg";
import uncheck from "../../../assets/icons/uncheck.svg";
import TreeMenu from "../../TreeMenu/TreeMenu";
export default function BlogAddCategory() {
  const blogEdit = useSelector((state) => state.BlogEdit);
  const location = useLocation();
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const [search, setSearch] = useState("");
  const [show, setShow] = useState();
  const [allBlogCategory, setAllBlogCategory] = useState([]);
  const [dropDownCategories, setDropDownCategories] = useState([]);
  const [dropDownValue, setDropDownValue] = useState("");
  const [blogCategorySelected, setBlogCategorySelected] = useState([]);
  const [blogCategoryInfo, setBlogCategoryInfo] = useState({
    title: null,
    parent_id: null,
  });
  useEffect(() => {
    axios
      .get("/blogCategory?isNested=1")
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setAllBlogCategory(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });

    axios
      .get("/blogCategory?isNested=0")
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setDropDownCategories(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });

  }, []);

  const addBlogCategory = () => {
    if (blogCategoryInfo.title != null) {
      axios
        .post("/blogCategory", blogCategoryInfo)
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            dispatch(ShowAlert(true, res.data.message));
            setBlogCategoryInfo((prev) => {
              return {
                title: null,
                parent_id: null,
              };
            });
            setDropDownValue("");
            let blogCategoryInput = document.getElementById(
              "blog-category-input"
            );
            blogCategoryInput.value = null;
            forceUpdate();
            axios
              .get("/blogCategory?isNested=1")
              .then((res) => {
                if (res.data.status) {
                  console.log(res);
                  setAllBlogCategory(res.data.data);
                }
              })
              .catch((e) => {
                console.log(e);
              });

            axios
              .get("/blogCategory?isNested=0")
              .then((res) => {
                if (res.data.status) {
                  console.log(res);
                  setDropDownCategories(res.data.data);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handelSearchChange = (e) => {
    setSearch(e.target.value);
    setDropDownValue(e.target.value)
  };
  const addCategoryItem = (id, title) => {
    setBlogCategoryInfo((prev) => {
      return {
        title: prev.title,
        parent_id: id,
      };
    });
    setDropDownValue(title);
    setShow(false);
    setTimeout(() => {
      setShow(true);
    }, 100);
  };
  // useEffect(() => {
  //   if (!isObjectEmpty(blogEdit)) {
  //     if (location.pathname === "/EditBlog") {
  //       blogEdit.blogCategories.map((item) => {
  //         blogCategorySelected.push(item.id);
  //         dispatch(AddBlogCategory(item.id));
  //       });
  //     }
  //   }
  // }, [blogEdit]);

  return (
    <div className="blog-add-category-all">
      <div className="blog-add-category-title">
        <p>دسته بندی بلاگ</p>
      </div>
      <div className="blog-add-category-box">
        <div className="blog-add-category-choose-category-box">


          <TreeMenu data={allBlogCategory} type="blog"
            childs="all_blog_childes_categories" />

        </div>
        <div className="blog-add-category-dropdown-input-box">
          <div className="blog-add-category-dropdown-box">
            <DropDownComponent
              id="categoryDrop"
              width="95%"
              height="44px"
              dropDownHeight="250px"
              zindex="1"
              search={search}
              show={show}
              // axiosname="CategoryData"
              onchange={(e) => handelSearchChange(e)}
              value={dropDownValue}
              itemClick={(id, title) => addCategoryItem(id, title)}
              data={dropDownCategories}
              placeholder="انتخاب دسته بندی"
            />
          </div>
          <div className="blog-add-category-input-box">
            <TextInputComponent
              id={"blog-category-input"}
              value={blogCategoryInfo.title}
              width="97%"
              height="44px"
              placeholder="افزودن دسته بندی جدید"
              borderColor="#dcdcdc"
              inputType="input"
              type="text"
              onchange={(e) =>
                setBlogCategoryInfo((prev) => {
                  return {
                    title: e.target.value,
                    parent_id: prev.parent_id,
                  };
                })
              }
            />
          </div>
          <div className="blog-add-category-button-box">
            <button
              onClick={() => addBlogCategory()}
              className="blog-add-category-button"
            >
              <p>افزودن دسته بندی</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
