import React, { useEffect, useState } from "react";
import InputComponent from "../TextInputComponent/TextInputComponent";
import useForceUpdate from "use-force-update";
import { ShowAlert } from "../../action/index";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import RightThumbSlider from "../../assets/icons/RightThumbSlider.svg";
import LeftThumbSlider from "../../assets/icons/LeftThumbSlider.svg";
import axios from "../../js/Axios/Axios";

export default function InstancePrice(props) {
  const { productIDs } = props;
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const [priceInfo, setPriceInfo] = useState({
    productPrice_ids: [],
    is_percent: 1,
    change_amount: null,
  });
  const percentOnChange = (id) => {
    setPriceInfo((prev) => {
      prev.is_percent = id;
      forceUpdate();
      return prev;
    });
    if (priceInfo.change_amount != null) {
      setPriceInfo((prev) => {
        prev.change_amount = null;
        forceUpdate();
        return prev;
      });
    }
  };
  useEffect(() => {
    setPriceInfo((prev) => {
      prev.productPrice_ids = productIDs;
      forceUpdate();
      return prev;
    });
  }, [productIDs]);

  const iOSBoxShadow =
    "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";
  const IOSSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.mode === "dark" ? "#3880ff" : "#3880ff",
    height: 5,
    cursor: "pointer",
    padding: "15px 0",
    "& .MuiSlider-thumb": {
      height: "15px",
      width: "25px",
      cursor: "pointer",
      borderRadius: "10px",
      backgroundColor: "#fff",
      boxShadow: iOSBoxShadow,
      "&:focus, &:hover, &.Mui-active": {
        boxShadow:
          "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          boxShadow: iOSBoxShadow,
        },
      },
    },
    "& .MuiSlider-valueLabel": {
      fontSize: 14,
      fontWeight: "normal",
      top: 2,
      fontFamily: "IRANSansFaNum",
      backgroundColor: "unset",
      color: theme.palette.text.primary,
      "&:before": {
        display: "none",
      },
      "& *": {
        background: "transparent",
        color: theme.palette.mode === "dark" ? "#fff" : "#000",
      },
    },
    "& .MuiSlider-track": {
      border: "none",
      color: "#00589c",
      cursor: "pointer",
    },
    "& .MuiSlider-rail": {
      opacity: 0.5,
      backgroundColor: "#bfbfbf",
      cursor: "pointer",
    },
    "& .MuiSlider-mark": {
      backgroundColor: "#bfbfbf",
      height: 8,
      width: 1,
      "&.MuiSlider-markActive": {
        opacity: 1,
        backgroundColor: "currentColor",
      },
    },
  }));
  function AirbnbThumbComponent(props) {
    const { children, ...other } = props;
    return (
      <SliderThumb {...other}>
        {children}
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
          }}
        >
          <img
            style={{ width: "10px", height: "10px" }}
            src={RightThumbSlider}
          />
          <img
            style={{ width: "10px", height: "10px" }}
            src={LeftThumbSlider}
          />
        </div>
      </SliderThumb>
    );
  }
  const [error, setError] = useState(false);
  const changePriceClick = () => {
    if (priceInfo.productPrice_ids.length != 0) {
      if (priceInfo.change_amount !== null) {
        axios
          .post("/productPrice/changePrice", priceInfo)
          .then((res) => {
            if (res.data.status === 0) {
              dispatch(ShowAlert(true, res.data.message));
            } else if (res.data.status) {
              props.update("update");
              dispatch(ShowAlert(true, res.data.message));
              setPriceInfo((prev) => {
                prev.productPrice_ids = [];
                prev.change_amount = null;
                prev.is_percent = 1;
                forceUpdate();
                return prev;
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        dispatch(ShowAlert(true, "پر کردن فیلد قیمت الزامی است ."));
        setError(true);
        forceUpdate();
      }
    } else {
      dispatch(
        ShowAlert(true, "برای تغییر قیمت باید حتما محصول خود را انتخاب کنید")
      );
    }
  };
  return (
    <div className="instance-price-all">
      <div className="instance-price-title-box">
        <button
          onClick={() => percentOnChange(1)}
          className="instance-price-percent-button"
        >
          <p
            className={
              priceInfo.is_percent === 1
                ? "instance-price-percent-button-title-active"
                : "instance-price-percent-button-title-disable"
            }
          >
            تغییر به درصد
          </p>
        </button>
        <button
          onClick={() => percentOnChange(0)}
          className="instance-price-percent-button"
        >
          <p
            className={
              priceInfo.is_percent === 0
                ? "instance-price-percent-button-title-active"
                : "instance-price-percent-button-title-disable"
            }
          >
            تغییر به عدد
          </p>
        </button>
      </div>
      {priceInfo.is_percent === 1 && (
        <div className="instance-price-slider-box">
          <IOSSlider
            // disabled={discountInfo.is_percentage === 1 ? false : true}
            min={-100}
            max={100}
            components={{ Thumb: AirbnbThumbComponent }}
            aria-label="ios slider"
            defaultValue={
              priceInfo.change_amount != null ? priceInfo.change_amount : 0
            }
            valueLabelDisplay="on"
            onChange={(e) => {
              setPriceInfo((prev) => {
                prev.change_amount = e.target.value;
                return prev;
              });
            }}
          />
        </div>
      )}
      {priceInfo.is_percent === 0 && (
        <div className="instance-price-input-box">
          <InputComponent
            value={priceInfo.change_amount}
            width="100%"
            height="44px"
            type="number"
            inputType="input"
            placeholder="قیمت (تومان)"
            borderColor={error === false ? "#dcdcdc" : "#F23B3B"}
            onchange={(e) => {
              setPriceInfo((prev) => {
                prev.change_amount = e.target.value;
                forceUpdate();
                return prev;
              });
            }}
          />
        </div>
      )}
      <div className="intance-price-button-box">
        <button onClick={() => changePriceClick()}>اعمال تغییر</button>
      </div>
    </div>
  );
}
