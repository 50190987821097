const iniState = {
  blogSeo_id: null,
  is_edit: 0,
  faqs: [
    {
      question: null,
      answer: null,
    },
  ],
};
const BlogSeoFaqReducer = (state = iniState, action) => {
  switch (action.type) {
    case "ADD_BLOG_SEO_FAQ":
      state.faqs.push({
        question: null,
        answer: null,
      });
      return state;
    case "SEND_BLOG_SEO_FAQ_QUESTION":
      state.faqs[action.index].question = action.question;
      return state;
    case "SEND_BLOG_SEO_FAQ_ANSWER":
      state.faqs[action.index].answer = action.answer;
      return state;

    default:
      return state;
  }
};
export default BlogSeoFaqReducer;
