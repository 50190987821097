import update from "react-addons-update";
const iniState = {
  show: false,
  errors: [],
};

const showAlertReducer = (state = iniState, action) => {
  switch (action.type) {
    case "SHOW_ALERT":
      if (typeof action.errors === "object") {
        const emptyError2 = update(state, {
          show: { $set: false },
          errors: { $set: [] },
        });
        state = emptyError2;
        const newObject = Object.values(action.errors);
        newObject.map((item) => {
          if (Array.isArray(item) === true) {
            item.map((i) => {
              const newError3 = update(state, {
                show: { $set: action.show },
                errors: { $push: [i] },
              });
              state = newError3;
            });
          }
        });
        return state
      }
      //   const emptyError2 = update(state, {
      //     show: { $set: false },
      //     errors: { $set: [] },
      //   });
      //   state = emptyError2;
      //   if (Array.isArray(action.errors) === true) {
      //     action.errors.map((item) => {
      //       if (Array.isArray(item) === true) {
      //         item.map((i) => {
      //           const newError3 = update(state, {
      //             show: { $set: action.show },
      //             errors: { $push: [i] },
      //           });
      //           state = newError3;
      //         });
      //       }
      //     });
      //     console.log(state);
      //     return state;
      //   }
      if (typeof action.errors === "string") {
        const emptyError = update(state, {
          show: { $set: false },
          errors: { $set: [] },
        });
        state = emptyError;
        const newerror2 = update(state, {
          show: { $set: action.show },
          errors: { $push: [action.errors] },
        });
        state = newerror2;
      } else {
        const emptyError2 = update(state, {
          show: { $set: false },
          errors: { $set: [] },
        });
        state = emptyError2;
        const newErorr = update(state, {
          show: { $set: action.show },
          errors: { $set: action.errors },
        });

        state = newErorr;
      }

      return state;
    default:
      return state;
  }
};
export default showAlertReducer;
