import React, { useEffect, useState } from "react";
import "../../assets/styles/DashboardStyle/Dashboard.css";
import TopBar from "../../components/TopBar/TopBar";
import axios from "../../js/Axios/Axios";
import { useSelector, useDispatch } from "react-redux";
import { ShowAlert } from "../../action/index"
import OrderCard from "../../assets/images/OrderCard.svg";
import DashboardComment from "../../assets/images/DashboardComment.svg";
import DashboardMarjoo from "../../assets/images/DashboardMarjoo.svg";
import stareRating from "../../assets/icons/stareRating.svg";
import starYeloow from "../../assets/icons/starYeloow.svg";
import ArrowDown from "../../assets/icons/ArrowDown.svg";
import DashboardArrow from "../../assets/icons/DashboardArrow.svg";
import Avatar from "../../assets/icons/Avatar.svg";
import SelectBox from "../../components/SelectBox/SelectBox";
import Rating from "@mui/material/Rating";
import useForceUpdate from "use-force-update";
import SimpleBar from "simplebar-react";
import { SendUserIdForFilter } from "../../action/index";
import { permissions } from "../../helper/Permission";
import moment from "jalali-moment";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "simplebar/dist/simplebar.min.css";
import { objInObjToArray } from "../../helper/Helper";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  BarElement,
  Tooltip,
  Legend,
  Filler,
  defaults,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
ChartJS.register([
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  BarElement,
  Tooltip,
  Legend,
  Filler,
]);
export default function Dashboard(props) {
  // * loacetion
  let location = useLocation();

  defaults.font.family = "IRANSansFaNum";
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [orderCount, setOrderCount] = useState(null);
  const [commentCount, setCommentCount] = useState(null);
  const [returnedOrder, setReturnedOrder] = useState(null);
  const [adminName, setAdminName] = useState(null);
  const [storeName, setStoreName] = useState(null);
  const [storeDesc, setStoreDesc] = useState(null);
  const [rate, setRate] = useState(null);
  const [storeImg, setStoreImg] = useState(null);
  const [allComment_count, setAllComment_count] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [bestProduct, setBestProduct] = useState([]);
  const [value, setValue] = useState([]);
  const [label, setLabel] = useState(null);
  const [barData, setBarData] = useState({
    todayVisitCount: null,
    data: [],
  });
  const [monthValue, setMonthValue] = useState([
    { x: "فروردین", y: 0, z: "1" },
    { x: "اردیبهشت", y: 0, z: "2" },
    { x: "خرداد", y: 0, z: "3" },
    { x: "تیر", y: 0, z: "4" },
    { x: "مرداد", y: 0, z: "5" },
    { x: "شهریور", y: 0, z: "6" },
    { x: "مهر", y: 0, z: "7" },
    { x: "آبان", y: 0, z: "8" },
    { x: "آذر", y: 0, z: "9" },
    { x: "دی", y: 0, z: "10" },
    { x: "بهمن", y: 0, z: "11" },
    { x: "اسفند", y: 0, z: "12" },
  ]);

  const [havePermission, setHavePermission] = useState(false);

  useEffect(() => {
    // ? check admin have dashboaard permission
    axios
      .get("/UserSystemAccess/relatedList")
      .then((res) => {
        if (res.data.status) {
          let permissionfounded = res.data.data.find((permission) => {
            return permission.title == "Dashboard";
          });

          if (permissionfounded != undefined) {
            setHavePermission(true);
          } else if (res.data.data.length > 0) {
            let initAccess = res.data.data[0];

            let route = permissions().find((path) => {
              return (path.permission) == initAccess.title;
            });

            navigate(route.initPath);
          }

          forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    axios
      .get("/dashboard")
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          setOrderCount(res.data.orders_count);
          setCommentCount(res.data.all_comments_count);
          setReturnedOrder(res.data.order_returned_count);
          setAdminName(res.data.data.name);
          setStoreName(res.data.store.store_name);
          setStoreDesc(res.data.store.store_short_des);
          setRate(res.data.store.stars_average);
          setAllComment_count(res.data.all_comments_count);
          setStoreImg(res.data.store.storeIcon);

          forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
    axios
      .get("/dashboard/customers")
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          setCustomers(res.data.data);
        }
        forceUpdate();
      })
      .catch((e) => {
        console.log(e);
      });
    axios
      .get("/product/report")
      .then((res) => {
        console.log(res);
        if (res.data.status) {

          setBestProduct(res.data.data);
        }
        forceUpdate();
      })
      .catch((e) => {
        console.log(e);
      });
    axios
      .get("dashboard/ordersChart?filter=daily")
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          let array = [];
          let Price = [];
          setLabel("روزانه");
          array = objInObjToArray(res.data.data);
          array.map((item, index) => {
            Price.push({
              x: item.y,
              y: item.x,
            });
            forceUpdate();
          });
          setValue(Price);
          forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
    axios
      .get("/dashboard/visitChart")
      .then((res) => {
        console.log("vvvvv", res);
        let array = [];
        array = objInObjToArray(res.data.data.one_week_ago_visitInfo);
        let Array = [];
        array.map((item) => {
          Array.push({
            x: item.y,
            y: item.x,
          });
          forceUpdate();
        });
        setBarData((prev) => {
          prev.todayVisitCount = res.data.data.today_visit_count;
          prev.data = Array;
          forceUpdate();
          return prev;
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }, [havePermission]);

  useEffect(() => {
    axios
      .get("/dashboard")
      .then((res) => {
        console.log("kkkk",res);
        if(res.data.status == 0){
          dispatch(ShowAlert(true , res.data.message))
        }
        if (res.data.status) {
          setOrderCount(res.data.orders_count);
          setCommentCount(res.data.all_comments_count);
          setReturnedOrder(res.data.order_returned_count);
          setAdminName(res.data.data.name);
          setStoreName(res.data.store.store_name);
          setStoreDesc(res.data.store.store_short_des);
          setRate(res.data.store.stars_average);
          setAllComment_count(res.data.all_comments_count);
          setStoreImg(res.data.store.storeIcon);

          forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
    axios
      .get("/dashboard/customers")
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          setCustomers(res.data.data);
        }
        forceUpdate();
      })
      .catch((e) => {
        console.log(e);
      });
    axios
      .get("/product/report")
      .then((res) => {
        console.log(res);
        if (res.data.status) {

          setBestProduct(res.data.data);
        }
        forceUpdate();
      })
      .catch((e) => {
        console.log(e);
      });
    axios
      .get("dashboard/ordersChart?filter=daily")
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          let array = [];
          let Price = [];
          setLabel("روزانه");
          array = objInObjToArray(res.data.data);
          array.map((item, index) => {
            Price.push({
              x: item.y,
              y: item.x,
            });
            forceUpdate();
          });
          setValue(Price);
          forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
    axios
      .get("/dashboard/visitChart")
      .then((res) => {
        if (res.data.status) {
          let array = [];
          array = objInObjToArray(res.data.data.one_week_ago_visitInfo);
          let Array = [];
          array.map((item) => {
            Array.push({
              x: item.y,
              y: item.x,
            });
            forceUpdate();
          });
          setBarData((prev) => {
            prev.todayVisitCount = res.data.data.today_visit_count;
            prev.data = Array;
            forceUpdate();
            return prev;
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [localStorage.getItem("access_token")]);

  const dateClick = (key) => {
    setLabel(key);
    let filter = "";
    if (key === "روزانه") {
      filter = "daily";
    } else if (key === "ماهانه") {
      filter = "monthly";
    } else if (key === "سالانه") {
      filter = "yearly";
    }
    axios
      .get(`dashboard/ordersChart?filter=${filter}`)
      .then((res) => {
        console.log(res);
        let array = [];
        let Price = [];
        array = objInObjToArray(res.data.data);
        array.map((item, index) => {
          Price.push({
            x: item.y,
            y: item.x,
          });
          forceUpdate();
        });
        if (key !== "ماهانه") {
          setValue(Price);
        } else {
          Price.map((item) => {
            monthValue.map((month) => {
              if (month.z === item.x) {
                month.y = item.y;
              }
              forceUpdate();
            });
            setValue(monthValue);
            forceUpdate();
          });
        }

        forceUpdate();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const data = {
    datasets: [
      {
        label: "",
        data: value,
        fill: false,
        borderColor: "#2d99ff",
        backgroundColor: "#2d99ff",
        tension: 0.1,
        fontFamily: "IRANSansFaNum",
      },
    ],
  };
  const options = {
    responsive: true,
    showTooltips: true,
    font: { family: "IRANSansFaNum" },
    plugins: {
      tooltip: {
        intersect: false,
      },
      legend: {
        display: false,
        position: "top",
        text: "zzzz",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };
  const Bardata = {
    datasets: [
      {
        label: "",
        data: barData.data,
        fill: false,
        // borderColor: "#2d99ff",
        backgroundColor: "#D9D9D9",
        borderRadius: 10,
        tension: 0.1,
        fontFamily: "IRANSansFaNum",
      },
    ],
  };
  const Baroptions = {
    responsive: true,
    showTooltips: true,
    font: { family: "IRANSansFaNum" },
    plugins: {
      tooltip: {
        intersect: false,
      },
      legend: {
        display: false,
        position: "top",
        // text: "zzzz",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };
  const arrowClick = (id, name) => {
    dispatch(SendUserIdForFilter(id, name));
    navigate("/Order");
  };

  return havePermission ? (
    <div className="dashboard-all">
      <TopBar />
      <div className="dashboard-name-box">
        <p>سلام {adminName} جان</p>
        <p>( مدیر فروشگاه )</p>
      </div>
      <div className="dashboard-all-box">
        <div className="dashboard-all-box-right">
          <div className="dashboard-all-box-right-card-box">
            <div className="dashboard-all-box-right-card">
              <img src={OrderCard} />
              <div className="dashboard-all-box-right-card-title-box">
                <p>{orderCount}</p>
                <p>سفارشات ثبت شده روز</p>
              </div>
            </div>
            <div className="dashboard-all-box-right-card">
              <img src={DashboardComment} />
              <div className="dashboard-all-box-right-card-title-box">
                <p>{commentCount}</p>
                <p>دیدگاه جدید</p>
              </div>
            </div>
            <div className="dashboard-all-box-right-card">
              <img src={DashboardMarjoo} />
              <div className="dashboard-all-box-right-card-title-box">
                <p>{returnedOrder}</p>
                <p>محصولات مرجوعی روز </p>
              </div>
            </div>
          </div>
          <div className="dashboard-all-box-right-chart-box">
            <div className="dashboard-all-box-right-chart-box-2">
              <p>نمودار فروش</p>
              <SelectBox
                label={label}
                data={[{ key: "روزانه" }, { key: "ماهانه" }, { key: "سالانه" }]}
                width="calc(150px - 20px)"
                heightBox="100px"
                height="39px"
                backgroundColor="#f5f6fa"
                itemClik={(key) => dateClick(key)}
              />
            </div>
            <div className="dashboard-all-box-right-chart-box-3">
              <Line options={options} data={data} />
            </div>
          </div>

          <div className="dashboard-all-box-right-best-product-box">
            <div className="dashboard-all-box-right-best-product-box-title-box">
              <p>پرفروش ترین محصولات</p>
            </div>
            <div className="dashboard-all-box-right-best-product-box-top-bar">
              <div className="dashboard-all-box-right-best-product-box-top-bar-hashtag">
                <p>#</p>
              </div>
              <div className="dashboard-all-box-right-best-product-box-top-bar-product-name">
                <p>نام محصول</p>
              </div>
              <div className="dashboard-all-box-right-best-product-box-top-bar-price">
                <p>قیمت</p>
              </div>
              <div className="dashboard-all-box-right-best-product-box-top-bar-all-price">
                <p>جمع مبلغ فروش</p>
              </div>
              <div className="dashboard-all-box-right-best-product-box-top-bar-end">
                <p>تعداد فروش رفته / موجودی انبار </p>
              </div>
            </div>


            {bestProduct.length > 0 && (
              <div className="dashboard-all-box-right-best-product-box-item-box-0">
                <SimpleBar
                  forceVisible="y"
                  // scrollableNodeProps={{ ref: this.scrollContentRef }}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {bestProduct.map((item, index) => {
                    return (
                      <div className="dashboard-all-box-right-best-product-box-item-box">
                        <div className="dashboard-all-box-right-best-product-box-item-box-hashtag">
                          <p>{index + 1}</p>
                        </div>
                        <div className="dashboard-all-box-right-best-product-box-item-box-product-name">
                          <div className="dashboard-all-box-right-best-product-box-item-box-product-name-img">
                            <img src={item.image} />
                          </div>
                          <div className="dashboard-all-box-right-best-product-box-item-box-product-name-title-box">
                            <p className="dashboard-all-box-right-best-product-box-item-box-product-name-title">
                              {item.product_name}
                            </p>
                          </div>
                          {item.key !== null && 
                          item.key === "رنگ" && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "32px",
                                height: "17px",
                                borderRadius: "5px",
                                // marginRight: "8px",
                                border: `solid 1px ${item.alter_value}`,
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: "IRANSansFaNum",
                                  fontSize: "12px",
                                  color: `${item.alter_value}`,
                                }}
                              >
                                {item.main_value}
                              </p>
                            </div>
                          )}
                          {item.key !== null && 
                          item.key !== "رنگ" &&(
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "auto",
                                height: "auto",
                                padding: "4px 4px 4px 4px",
                                borderRadius: "5px",
                                // marginRight: "8px",
                                border: `solid 1px #000000`,
                              }}
                            >
                              <p
                                style={{
                                  fontFamily: "IRANSansFaNum",
                                  fontSize: "12px",
                                  color: "#000000",
                                }}
                              >
                                {item.main_value}
                              </p>
                            </div>
                          )
                        }
                        </div>
                        <div className="dashboard-all-box-right-best-product-box-item-box-price">
                          {item.final_price / item.final_sale_count} (تومان)
                        </div>
                        <div className="dashboard-all-box-right-best-product-box-item-box-all-price">
                          {item.final_price} (تومان)
                        </div>
                        <div className="dashboard-all-box-right-best-product-box-item-box-all-end">
                          <p>{item.final_sale_count}</p>
                          <p>/</p>
                          <p>{item.available_count}</p>
                        </div>
                      </div>
                    );
                  })}
                </SimpleBar>
              </div>
            )}
          </div>
        </div>
        <div className="dashboard-all-box-left">
          <div className="dashboard-all-box-left-store-detail-box">
            <div className="dashboard-all-box-left-store-detail-box-store-box">
              <button className="dashboard-all-box-left-store-detail-box-store-box-account-button">
                اشتراک نامحدود
              </button>
              <div className="dashboard-all-box-left-store-detail-box-store-box-img">
                <img src={storeImg} />
              </div>
            </div>
            <p className="dashboard-all-box-left-store-detail-store-name">
              {storeName}
            </p>
            <p className="dashboard-all-box-left-store-detail-store-description">
              {storeDesc}
            </p>
            <div className="dashboard-all-box-left-store-detail-store-star-box">
              <p>{`(${allComment_count} دیدگاه)`}</p>
              <div className="dashboard-all-box-left-store-detail-store-star-box-2">
                <Rating
                  name="read-only"
                  value={rate}
                  icon={<img src={starYeloow} />}
                  emptyIcon={<img src={stareRating} />}
                  precision={0.5}
                  readOnly
                  max={5}
                />
              </div>
              <div className="dashboard-all-box-left-store-detail-store-star-box-3">
                <p>{rate}</p>
                <p>از</p>
                <p>5</p>
              </div>
            </div>
          </div>
          <div className="dashboard-all-box-left-store-firs-buy-box">
            <div className="dashboard-all-box-left-store-firs-buy-box-title-box">
              <p>مشتریان خرید اولی</p>
              <p>{customers.length} نفر</p>
            </div>

            <div className="dashboard-all-box-left-store-firs-buy-person-box">
              <SimpleBar
                forceVisible="y"
                // scrollableNodeProps={{ ref: this.scrollContentRef }}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                {customers.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="dashboard-all-box-left-store-firs-buy-person-box-1"
                    >
                      <img
                        src={
                          item.userProf_img === null
                            ? Avatar
                            : item.userProf_img
                        }
                      />
                      <div className="dashboard-all-box-left-store-firs-buy-person-box-1-title">
                        <p>{item.full_name}</p>
                        <p>{item.phone_number}</p>
                      </div>
                      <div className="dashboard-all-box-left-store-firs-buy-person-box-1-price">
                        <p>{item.final_amount_order} تومان</p>
                      </div>
                      <img
                        onClick={() => arrowClick(item.user_id, "order")}
                        // dispatch(SendUserIdForFilter(id, name));
                        // navigate("/Order");
                        className="dashboard-all-box-left-store-firs-buy-person-box-1-arrow"
                        src={ArrowDown}
                      />
                    </div>
                  );
                })}
              </SimpleBar>
            </div>
          </div>
          <div className="dashboard-all-box-left-chart-box">
            <p className="dashboard-all-box-left-chart-box-title">
              ترافیک فروشگاه
            </p>
            <div className="dashboard-all-box-left-chart-box-2">
              <p>{barData.todayVisitCount}</p>
            </div>
            <div className="dashboard-all-box-left-chart-box-3">
              <p>تعداد بازدید امروز</p>
            </div>
            <div className="dashboard-all-box-left-chart-box-4">
              <Bar options={Baroptions} data={Bardata} />
            </div>
            <div className="dashboard-all-box-left-chart-box-5">
              <div
                onClick={() => navigate("/VisitorStatistics")}
                className="dashboard-all-box-left-chart-box-6"
              >
                <p>مشاهده آمار بازدیدکنندگان</p>
                <img src={DashboardArrow} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
}
