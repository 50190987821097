import React from "react";
import "../../../assets/styles/Blog/CreateBlogStyle/CreateBlog.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import TopBar from "../../../components/TopBar/TopBar";
import BlogIntroduction from "../../../components/Blog/BlogIntroduction/BlogIntroduction";
import BlogAddTag from "../../../components/Blog/BlogAddTag/BlogAddTag";
import BlogAddCategory from "../../../components/Blog/BlogAddCategory/BlogAddCategory";
import BlogSeo from "../../../components/Blog/BlogSeo/BlogSeo";
import BlogContent from "../../../components/Blog/BlogContent/BlogContent";
import { useSelector, useDispatch } from "react-redux";
import { ShowAlert, BlogError } from "../../../action/index";
import axios from "../../../js/Axios/Axios";
import BookMark from "../../../assets/icons/Bookmark.svg";
import ShowEye from "../../../assets/icons/ShowEye.svg";
import { useLocation } from "react-router-dom";
import UploadIcon from "../../../assets/icons/Upload.svg";

function CreateBlog() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const blogInfo = useSelector((state) => state.BlogInfo);
  const blogSeoInfo = useSelector((state) => state.BlogSeoInfo);
  const blogSeoFaq = useSelector((state) => state.BlogSeoFaq);
  const location = useLocation();

  // const checkBlogText = (blog_text) => {
  //   if(typeof blog_text == "object"){
  //   if (blog_text.blocks[0].text === "") {
  // dispatch(BlogError({ BlogText: true }));
  // dispatch(
  //   ShowAlert(
  //     true,
  //     "برای انتشار بلاگ پر کردن فیلد محتوای بلاگ الزامی است ."
  //   )
  // );
  //   } else {
  //     return true;
  //   }}else {
  //     var obj
  //   }
  // };

  const shareBlog = async (status) => {
    if (blogInfo.title !== null && blogInfo.blog_text.blocks[0].text !== "") {
      console.log("if");
      const string = JSON.stringify(blogInfo.blog_text);
      blogInfo.blog_text = string;
      blogInfo.release_status = status;
      axios
        .post("/blog", blogInfo)
        .then((res) => {
          console.log(res);
          if (res.data.status === 0) {
            console.log(blogInfo);
          }
          if (res.data.status) {
            blogSeoInfo.blog_id = res.data.data.id;
            axios
              .post("/blogSeo", blogSeoInfo)
              .then((res) => {
                if (res.data.status) {
                  blogSeoFaq.blogSeo_id = res.data.data.id;
                  console.log(res);
                  // dispatch(ShowAlert(res.data.message));
                  console.log(blogSeoFaq);
                  axios
                    .post("/blogSeoFaq", blogSeoFaq)
                    .then((res) => {
                      console.log(res);
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }
              })
              .catch((e) => {
                console.log(e);
              });
            navigate("/ManageBlog");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      console.log("else");
      if (blogInfo.title === null) {
        console.log("else title");
        dispatch(BlogError({ title: true }));
        dispatch(
          ShowAlert(true, "برای انتشار بلاگ پر کردن فیلد عنوان بلاگ الزامی .")
        );
      }
      if (blogInfo.blog_text.blocks[0].text === "") {
        console.log("else draft");
        dispatch(BlogError({ BlogText: true }));
        dispatch(
          ShowAlert(
            true,
            "برای انتشار بلاگ پر کردن فیلد محتوای بلاگ الزامی است ."
          )
        );
      }
    }
    
  };

  return (
    <div className="create-blog-parent">
      <TopBar />
      <div className="create-Blog-all">
        <div className="create-blog-button-route-box">
          <div className="create-blog-route-box">
            <p className="create-blog-route-title"> بلاگ </p>
            <p className="create-blog-route-title"> &gt;</p>
            <p className="create-blog-route-title">ایجاد بلاگ </p>
            <p className="create-blog-route-title"> &gt;</p>
            <p className="create-blog-route-title"> ذخیره بلاگ</p>
          </div>
          <div className="create-blog-button-box">
            <button
              onClick={() => shareBlog(1)}
              className="create-blog-share-button"
            >
              <img src={UploadIcon} />
              <p>بلاگ منتشر شود</p>
            </button>
            <button
              onClick={() => shareBlog(0)}
              className="create-blog-draft-button"
            >
              <img src={BookMark} />
              <p>ذخیره در پیش نویس ها</p>
            </button>
            {/* <button className="create-blog-show-button">
            <img src={ShowEye} />
            <p>نمایش بلاگ</p>
          </button> */}
          </div>
        </div>
        <div className="create-blog-introduction-box">
          <BlogIntroduction />
        </div>
        <div className="create-blog-content-box">
          <BlogContent />
        </div>
        <div className="create-blog-tag-category-box">
          <div className="create-blog-tag-box">
            <BlogAddTag />
          </div>
          <div className="create-blog-category-box">
            <BlogAddCategory />
          </div>
        </div>
        <div className="create-blog-seo-box">
          <BlogSeo />
        </div>

        <div className="create-blog-down-button-box">
          <button
            onClick={() => shareBlog(1)}
            className="create-blog-share-button"
          >
            <img src={UploadIcon} />
            <p>بلاگ منتشر شود</p>
          </button>
          <button
            onClick={() => shareBlog(0)}
            className="create-blog-draft-button"
          >
            <img src={BookMark} />
            <p>ذخیره در پیش نویس ها</p>
          </button>
        </div>
      </div>
    </div>
  );
}
export default CreateBlog;
