import React, { useEffect, useState } from "react";
import "../../../assets/styles/Blog/CreateBlogStyle/BlogIntroduction.css";
import TextInputComponent from "../../TextInputComponent/TextInputComponent";
import SwitchButton from "../../SwitchButton/SwitchButton";
import { SendBlogInfo , BlogError } from "../../../action/index";
import { useSelector, useDispatch } from "react-redux";
import { isObjectEmpty } from "../../../helper/Helper";
import { useLocation } from "react-router-dom";
import useForceUpdate from "use-force-update";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

function BlogIntroduction() {
  const blogEdit = useSelector((state) => state.BlogEdit);
  const Error = useSelector((state) => state.Error);
  const location = useLocation();
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate()
  const [errors, SetErrors] = useState({
    title: false,
  });
  const [blogInfo, setBlogInfo] = useState({
    title: null,
    latin_title: null,
    blog_description: null,
    blog_url: "",
    comments_status: 0,
    author: null,
  });

  const Value = (data) => {
    if (data === true) {
      setBlogInfo((prev) => {
        return {
          title: prev.title,
          latin_title: prev.latin_title,
          blog_description: prev.blog_description,
          blog_url: prev.blog_url,
          comments_status: 1,
          author: prev.author,
        };
      });
    } else if (data === false) {
      setBlogInfo((prev) => {
        return {
          title: prev.title,
          latin_title: prev.latin_title,
          blog_description: prev.blog_description,
          blog_url: prev.blog_url,
          comments_status: 0,
          author: prev.author,
        };
      });
    }
  };
  useEffect(() => {
    dispatch(SendBlogInfo(blogInfo));
  }, [blogInfo]);

  useEffect(() => {
    if (!isObjectEmpty(blogEdit)) {
      if (location.pathname === "/EditBlog") {
        console.log(blogEdit);
        setBlogInfo((prev) => {
          return {
            title: blogEdit.title,
            latin_title: blogEdit.latin_title,
            blog_description: blogEdit.blog_description,
            blog_url: blogEdit.blog_url,
            comments_status: blogEdit.comments_status,
            author: blogEdit.author,
          };
        });
      }
    }
  }, [blogEdit]);

  useEffect(() => {
    if (Error.productTittle === true) {
      SetErrors((prev) => {
        prev.title = true;
        forceUpdate()
        return prev;
      });
    }
  }, [Error]);

  return (
    <div className="blog-introduction-all">
      <div className="blog-introduction-title-box">
        <p>معرفی بلاگ</p>
      </div>
      <div className="blog-introduction-inputs-box">
        <div className="blog-introduction-right-inputs-box">
          <div
            style={{ marginBottom: "25px" }}
            className="blog-intoduction-input-box"
          >
            <TextInputComponent
              value={blogInfo.title}
              width="97%"
              height="44px"
              placeholder="عنوان بلاگ"
              borderColor={errors.title === true ? "#F23B3B" : "#dcdcdc"}
              inputType="input"
              type="text"
              onchange={(e) => {
                setBlogInfo((prev) => {
                  return {
                    title: e.target.value,
                    latin_title: prev.latin_title,
                    blog_description: prev.blog_description,
                    blog_url: prev.blog_url,
                    comments_status: prev.comments_status,
                    author: prev.author,
                  };
                });
                SetErrors((prev) => {
                  prev.title = false;
                  return prev
                });
                if (Error.productTittle === true) {
                  dispatch(BlogError({ title: false }));
                }
              }}
            />
          </div>

          <div
            style={{ marginBottom: "25px" }}
            className="blog-intoduction-input-box"
          >
            <TextInputComponent
              value={blogInfo.latin_title}
              width="100%"
              height="44px"
              placeholder="عنوان بلاگ به لاتین"
              borderColor="#dcdcdc"
              inputType="input"
              type="text"
              onchange={(e) =>
                setBlogInfo((prev) => {
                  return {
                    title: prev.title,
                    latin_title: e.target.value,
                    blog_description: prev.blog_description,
                    blog_url: prev.blog_url,
                    comments_status: prev.comments_status,
                    author: prev.author,
                  };
                })
              }
            />
          </div>

          <div
            style={{ marginBottom: "25px" }}
            className="blog-intoduction-input-box"
          >
            <TextInputComponent
              value={blogInfo.author}
              width="100%"
              height="44px"
              placeholder="نویسنده بلاگ"
              borderColor="#dcdcdc"
              inputType="input"
              type="text"
              onchange={(e) =>
                setBlogInfo((prev) => {
                  return {
                    title: prev.title,
                    latin_title: prev.latin_title,
                    blog_description: prev.blog_description,
                    blog_url: prev.blog_url,
                    comments_status: prev.comments_status,
                    author: e.target.value,
                  };
                })
              }
            />
          </div>
          <div className="blog-intoduction-input-box">
            <TextInputComponent
              value={blogInfo.blog_url}
              width="47%"
              height="44px"
              placeholder="URL"
              borderColor="#dcdcdc"
              inputType="input"
              type="text"
              onchange={(e) =>
                setBlogInfo((prev) => {
                  return {
                    title: prev.title,
                    latin_title: prev.latin_title,
                    blog_description: prev.blog_description,
                    blog_url: e.target.value,
                    comments_status: prev.comments_status,
                    author: prev.author,
                  };
                })
              }
            />
            <div className="blog-introduction-link-box">
              <p>پیوند یکتا :</p>
              <SimpleBar
                forceVisible="x"
                style={{
                  width: "calc(100% - 90px)",
                  // height : "100%"
                }}
              >
                <p className="IntroductionBox9linkTittle1">
                  {`http://vitsell.vistaapp.ir/${blogInfo.blog_url}`}
                </p>
              </SimpleBar>
              {/* <div className="blog-introduction-link-box-2">
                <p>http://vitsell.vistaapp.ir/{blogInfo.blog_url}</p>
              </div> */}
            </div>
          </div>
        </div>
        <div className="blog-introduction-left-inputs-box">
          <TextInputComponent
            value={blogInfo.blog_description}
            width="96.5%"
            height="200px"
            margin="0px 0px  25px 0px"
            type="text"
            inputType="textarea"
            placeholder="توضیحات بلاگ"
            borderColor="#dcdcdc"
            onchange={(e) =>
              setBlogInfo((prev) => {
                return {
                  title: prev.title,
                  latin_title: prev.latin_title,
                  blog_description: e.target.value,
                  blog_url: prev.blog_url,
                  comments_status: prev.comments_status,
                  author: prev.author,
                };
              })
            }
          />

          <div className="blog-introduction-access-customer-box">
            <p>مشتریان بتوانند دیدگاه خود را بنویسند</p>
            <SwitchButton
              width="50px"
              height="24px"
              Value={Value}
              on={blogInfo.comments_status}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default BlogIntroduction;
