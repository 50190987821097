import React, { useEffect, useState } from "react";
import "../../../assets/styles/InstanceChangeStyle/IntanceDiscount/IntanceDiscount.css";
import TopBar from "../../../components/TopBar/TopBar";
import InstanceDiscountComponentt from "../../../components/InstanceChangeComponents/InstanceDiscountComponentt";
import InstancePriceComponent from "../../../components/InstanceChangeComponents/InstancePriceComponent";
import InputComponent from "../../../components/TextInputComponent/TextInputComponent";
import Pagination from "../../../components/Pagination/Pagination";
import useForceUpdate from "use-force-update";
import axios from "../../../js/Axios/Axios";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SearchLogo from "../../../assets/icons/SearchLogo.svg";
import check from "../../../assets/icons/check.svg";
import headerCheck from "../../../assets/icons/headerCheck.svg";
import uncheck from "../../../assets/icons/uncheck.svg";

export default function InstanceDiscount() {
  const location = useLocation();
  const forceUpdate = useForceUpdate();
  const paginatedData = useSelector((state) => state.PaginatedData); // get paginated from reducer
  const [tableData, setTableData] = useState([]);
  const [from, setFrom] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [productIDs, setProductIDs] = useState([]);
  useEffect(() => {
    axios
      .get("/productPrice")
      .then((res) => {
        if (res.data.status) {
          setTableData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          console.log(res);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  useEffect(() => {
    console.log(paginatedData);
    if (paginatedData.length != 0) {
      setTableData(paginatedData.data);
      setCurrentPage(paginatedData.meta.current_page);
      setFrom(paginatedData.meta.from);
    }
  }, [paginatedData]);
  const SearchOnChange = (e) => {
    axios
      .get(`/productPrice?search=${e.target.value}`)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setTableData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const allCheckBox = () => {
    if (tableData.every((i) => productIDs.includes(i.id))) {
      tableData.map((titem, ti) => {
        productIDs.splice(productIDs.indexOf(titem.id), 1);

        forceUpdate();
      });
    } else {
      tableData.map((item, i) => {
        if (!productIDs.includes(item.id)) {
          productIDs.push(item.id);
        }
      });
      forceUpdate();
    }
  };
  const addProductID = (id) => {
    if (!productIDs.includes(id)) {
      productIDs.push(id);
    } else {
      var index = productIDs.indexOf(id);
      productIDs.splice(index, 1);
    }
    forceUpdate();
  };
  const update = (id) => {
    axios
      .get("/productPrice")
      .then((res) => {
        if (res.data.status) {
          setTableData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          console.log(res);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    productIDs.map((item, i) => {
      productIDs.splice(i)
    })
    forceUpdate()
  }


  console.log(location.pathname);
  return (
    <div className="instance-discount-all">
      <div className="instance-discount-top-bar">
        <TopBar />
      </div>
      <div className="instance-discount-serach-box">

        <h3 className="instance-discount-serach-box-title">
          {
            location.pathname == "/InstanceChangePrice" ? "تغییر قیمت" : "تغییر تخفیف"
          }
        </h3>

        <InputComponent
          width="337px"
          height="44px"
          type="text"
          inputType="inputWithIcon"
          iconSide="right"
          iconSrc={SearchLogo}
          backgroundColor="#f7f7f7"
          placeholder="جستجو .."
          borderColor="#dcdcdc"
          onchange={(e) => SearchOnChange(e)}
        />
      </div>
      <div className="instance-discount-box">
        <div className="instance-discount-component-box">
          {location.pathname === "/InstanceDiscount" && (
            <InstanceDiscountComponentt productIDs={productIDs} update={update} />
          )}
          {location.pathname === "/InstanceChangePrice" &&
            <InstancePriceComponent productIDs={productIDs} update={update} />
          }
        </div>
        <div className="instance-discount-table-box">
          <div className="instance-discount-table-top-bar">
            <div className="create-dicount-table-top-bar-hashtag">
              <p>#</p>
            </div>
            <div className="create-dicount-table-top-bar-checkBox">
              <label htmlFor="allproductCheckBox">
                <input
                  style={{
                    display: "none",
                  }}
                  id="allproductCheckBox"
                  name="allproductCheckBox"
                  type="checkbox"
                  onChange={() => allCheckBox()}
                />

                <img
                  src={
                    tableData.every((i) => productIDs.includes(i.id)) &&
                      tableData.length != 0
                      ? headerCheck
                      : uncheck
                  }
                />
              </label>
            </div>
            <div className="create-dicount-table-top-bar-img">
              <p>تصویر</p>
            </div>
            <div className="create-dicount-table-top-bar-name">
              <p>نام محصول</p>
            </div>
            <div className="create-dicount-table-top-bar-barcode">
              <p>شناسه</p>
            </div>
            <div className="create-dicount-table-top-bar-price">
              <p>قیمت(تومان)</p>
            </div>
            <div className="create-dicount-table-top-bar-Inventory">
              <p>موجودی</p>
            </div>
          </div>


          {tableData.map((item, index) => {
            return (
              <div key={index} style={
                (tableData.length == index + 1)
                  ? { borderRadius: "0 0 10px 10px" }
                  : {}
              }
                className="create-dicount-table-item-box">
                <div className="create-dicount-table-item-box-hashtag">
                  <p>{index + from}</p>
                </div>

                <div className="create-dicount-table-item-box-checkbox">
                  <label htmlFor={`product${index}`}>
                    <input
                      style={{
                        display: "none",
                      }}
                      type="checkbox"
                      id={`product${index}`}
                      name={`product${index}`}
                      onChange={() => addProductID(item.id)}
                    />
                    <img src={productIDs.includes(item.id) ? check : uncheck} />
                  </label>
                </div>
                <div className="create-dicount-table-item-box-image">
                  <span>
                    <img src={item.product_image}
                    />
                  </span>
                </div>
                <div className="create-dicount-table-item-box-name">
                  <p>{item.title}</p>
                </div>
                <div className="create-dicount-table-item-box-barcode">
                  <p>{item.barcode}</p>
                </div>
                <div className="create-dicount-table-item-box-price">
                  <p>{item.price}</p>
                </div>
                <div className="create-dicount-table-item-box-inventory">
                  <p>{item.available_type}</p>
                </div>
              </div>
            );
          })}
          <div className="change-discount-pagination-box">
            <Pagination
              firstAxios={"/productPrice"}
              currentAxios={"/productPrice?page="}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
