import React, { useEffect, useState } from "react";
import "../../assets/styles/VisitorStatisticsStyle/VisitorStatisticsChart.css";
import axios from "../../js/Axios/Axios";
import SelectBox from "../SelectBox/SelectBox";
import { objInObjToArray } from "../../helper/Helper";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  defaults,
} from "chart.js";
import { Line } from "react-chartjs-2";
import useForceUpdate from "use-force-update";
ChartJS.register([
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
]);
export default function VisitorStatisticsChart() {
  defaults.font.family = "IRANSansFaNum";
  const forceUpdate = useForceUpdate();
  const [allData, setAllData] = useState(null);
  const [value, setValue] = useState([]);
  const [monthValue, setMonthValue] = useState([
    { x: "فروردین", y: 0, z: 1 },
    { x: "اردیبهشت", y: 0, z: 2 },
    { x: "خرداد", y: 0, z: 3 },
    { x: "تیر", y: 0, z: 4 },
    { x: "مرداد", y: 0, z: 5 },
    { x: "شهریور", y: 0, z: 6 },
    { x: "مهر", y: 0, z: 7 },
    { x: "آبان", y: 0, z: 8 },
    { x: "آذر", y: 0, z: 9 },
    { x: "دی", y: 0, z: 10 },
    { x: "بهمن", y: 0, z: 11 },
    { x: "اسفند", y: 0, z: 12 },
  ]);
  const [label, setLabel] = useState(null);
  useEffect(() => {
    axios
      .get("/visitInfo")
      .then((res) => {
        console.log(res);
        setAllData(res.data.data);
        setLabel("روزانه");
        let array = [];
        res.data.data.daily.map((item) => {
          array.push({
            x: item.y,
            y: item.x,
          });
          forceUpdate();
        });
        setValue(array);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const itemClik = (key) => {
    console.log(allData);
    setLabel(key);
    if (key === "ماهانه") {
      let array = [];
      array = objInObjToArray(allData.monthly);
      array.map((item) => {
        monthValue.map((month) => {
          if (item.y === month.z) {
            month.y = item.x;
          }
          forceUpdate();
        });
        setValue(monthValue);
      });
    }
    if (key === "سالانه") {
      let array = [];
      let Array = [];
      array = objInObjToArray(allData.yearly);
      array.map((item) => {
        Array.push({
          x: item.y,
          y: item.x,
        });
        forceUpdate();
      });
      setValue(Array);
      forceUpdate();
    }
    if (key === "روزانه") {
      let array = [];
      allData.daily.map((item) => {
        array.push({
          x: item.y,
          y: item.x,
        });
        forceUpdate();
      });
      setValue(array);
    }
  };

  const data = {
    datasets: [
      {
        label: "",
        data: value,
        fill: false,
        borderColor: "#2d99ff",
        backgroundColor: "#2d99ff",
        tension: 0.1,
        fontFamily: "IRANSansFaNum",
      },
    ],
  };
  const options = {
    responsive: true,
    showTooltips: true,
    font: { family: "IRANSansFaNum" },
    plugins: {
      tooltip: {
        intersect: false,
      },
      legend: {
        display: false,
        position: "bottom",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };
  return (
    <div className="visitor-statistics-Chart">
      <div className="visitor-statistics-Chart-2">
        <div className="visitor-statistics-Chart-2-title-box">
          <p>نمودار بازدید از فروشگاه</p>
        </div>
        <SelectBox
          label={label}
          data={[{ key: "روزانه" }, { key: "ماهانه" }, { key: "سالانه" }]}
          width="calc(150px - 20px)"
          heightBox="100px"
          height="39px"
          backgroundColor="#f5f6fa"
          itemClik={(key)=>itemClik(key)}
        />
      </div>
      <div className="visitor-statistics-Chart-box">
        <Line options={options} data={data} />
      </div>
    </div>
  );
}
