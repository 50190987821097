import update from "react-addons-update";
const iniState = {
  id: null,
  nameid: null,
};
const UserFilterReducer = (state = iniState, action) => {
  switch (action.type) {
    case "SEND_USER_ID_FOR_FILTER":
        return { ...iniState, id: action.id, nameid: action.name };
    //   const newobj = update(iniState, {
    //     $merge: { id: action.id, nameid: action.name },
    //   });
    //   state = newobj;
    //   return state;
    default:
      return state;
  }
};
export default UserFilterReducer;
