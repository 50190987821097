// checking obj is empty
export function isObjectEmpty(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({});
}
// checking two array is Equal
export function isArrayEqual(a, b) {
  if (JSON.stringify(a) === JSON.stringify(b)) {
    return true;
  } else if (JSON.stringify(a) !== JSON.stringify(b)) return false;
}

// convert key value object too array
export const convertObjToArray = (obj) => {
  const arrayOfObj = Object.entries(obj).map((e) => ({
    key: e[0],
    value: e[1],
  }));
  return arrayOfObj;
};

// ? method for convert object to array only value

export const convertObjectToArrOnlyValue = (obj) => {
  let objEntries = Object.entries(obj);
  let media = [];
  for (const [key, value] of objEntries) {
    media.push(value);
  }
  return media;
};

// convert obj in obj to array
export const objInObjToArray = (obj) => {
  const array = Object.keys(obj).map((k) => obj[k]);
  return array;
};

// pluck method
// const pluck = (arr, key) => arr.map((i) => i[key]);
const iniState = {
  minDate: null,
  maxDate: null,
  situationStatus: null,
  category: null,
  user_id: null,
};

export const whichURL = (axiosURL) => {
  switch (axiosURL) {
    case "/blogComment/search":
      iniState.maxDate = "maxRegistrationDate";
      iniState.situationStatus = "status";
      iniState.minDate = "minRegistrationDate";
      iniState.user_id = "user_id";
      break;

    case "/blog?isPaginated=1":
      iniState.maxDate = "maxReleaseTime";
      iniState.category = "blogCategories";
      iniState.minDate = "minReleaseTime";
      iniState.user_id = "user_id";

      break;
    case "/ProductComment/search":
      iniState.maxDate = "maxRegistrationDate";
      iniState.situationStatus = "status";
      iniState.minDate = "minRegistrationDate";
      iniState.user_id = "user_id";
      break;

    case "/UserMessage":
      iniState.maxDate = "maxRegistrationAt";
      iniState.situationStatus = "messageStatus";
      iniState.minDate = "minRegistrationAt";
      iniState.user_id = "user_id";
      break;

    default:
      break;
  }

  return iniState;
};
