import React, { useEffect, useState } from "react";
import "../../assets/styles/VisitorStatisticsStyle/VisitorStatisticsWords.css";
import { convertObjToArray } from "../../helper/Helper";
import axios from "../../js/Axios/Axios";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

export default function VisitorStatisticsWords() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("/search")
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setData(convertObjToArray(res.data.data));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div className="visitor-statistics-words-all">
      <div className="visitor-statistics-words-all-title-box">
        <p>پراستفاده ترین کلمات</p>
      </div>
      <div className="visitor-statistics-words-box">
        <div className="visitor-statistics-words-top-bar">
          <div className="visitor-statistics-words-top-bar-hashtag">
            <p>#</p>
          </div>
          <div className="visitor-statistics-words-top-bar-word">
            <p>کلمه</p>
          </div>
          <div className="visitor-statistics-words-top-bar-count">
            <p>تعداد تکرار</p>
          </div>
        </div>
        <SimpleBar
          forceVisible="y"
          className="visitor-statistics-words-item-box"
        >
          {data.map((item, index) => {
            return (
              <div key={index} className="visitor-statistics-words-item-1">
                <div className="visitor-statistics-words-item-number">
                  <p>{index + 1}</p>
                </div>
                <div className="visitor-statistics-words-item-word">
                  <p>{item.key}</p>
                </div>
                <div className="visitor-statistics-words-item-count">
                  <p>{item.value}</p>
                </div>
              </div>
            );
          })}
        </SimpleBar>
      </div>
    </div>
  );
}
