import React, { useEffect, useRef, useState } from "react";
import "../../../assets/styles/DiscountStyle/ManageDiscount/ManageDiscount.css";
import TopBar from "../../../components/TopBar/TopBar";
import InputComponent from "../../../components/TextInputComponent/TextInputComponent";
import axios from "../../../js/Axios/Axios";
import Pagination from "../../../components/Pagination/Pagination";
import Filter from "../../../components/Filter/Filter";
import { SendCoupenForEdit } from "../../../action/index";
import { ShowAlert } from "../../../action/index";
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Modal from "@mui/material/Modal";
import useForceUpdate from "use-force-update";
import AllProductAdd from "../../../assets/icons/AllProductAdd.svg";
import SearchLogo from "../../../assets/icons/SearchLogo.svg";
import FilterLogo from "../../../assets/icons/Filter.svg";
import GarbageDelte from "../../../assets/icons/GarbageDelte.svg";
import check from "../../../assets/icons/check.svg";
import headerCheck from "../../../assets/icons/headerCheck.svg";
// import Eye from "../../../assets/icons/AllProductEye.svg";
import uncheck from "../../../assets/icons/uncheck.svg";
import DeleteRed from "../../../assets/icons/DeleteRed.svg";
import EditActive from "../../../assets/icons/EditActive.svg";

import AllProductTrash from "../../../assets/icons/GarbageDelte.svg";
import AllProductTrashRed from "../../../assets/icons/GarbageDelteRed.svg";

import SimpleBar from "simplebar-react";
import ScrollDrag from "react-indiana-drag-scroll";
import { Calculate, Edit } from "@mui/icons-material";

export default function ManageDiscount() {
  const location = useLocation();

  const scrollTitleRef = useRef();
  const scrollContentRef = useRef();

  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const paginatedData = useSelector((state) => state.PaginatedData);
  const [discountData, setDiscountData] = useState([]);
  const [from, setFrom] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [ids, setIds] = useState([]);
  const [modal, setModal] = useState(false);
  const [firstAxios, setFirsAxios] = useState("/Discount");
  useEffect(() => {
    axios
      .get("/Discount")
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          setDiscountData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  // useeffect for SimpleBar and ScrollDrag
  useEffect(() => {
    // set scroll position title and content for together

    const handleScrollTitle = (e) => {
      scrollContentRef.current.scrollLeft = scrollTitleRef.current.scrollLeft;
    };

    scrollTitleRef.current.addEventListener("scroll", handleScrollTitle);

    const handleScrollContent = (e) => {
      scrollTitleRef.current.scrollLeft = scrollContentRef.current.scrollLeft;
    };
    scrollContentRef.current.addEventListener("scroll", handleScrollContent);
  }, []);

  const Data = (data) => {
    setDiscountData(data.data);
    setFrom(data.meta.from);
    setCurrentPage(data.meta.current_page);
  };
  const Url = (Url) => {
    setFirsAxios(Url);
    forceUpdate();
  };

  useEffect(() => {
    if (paginatedData.length != 0) {
      setDiscountData(paginatedData.data);
      setCurrentPage(paginatedData.meta.current_page);
      setFrom(paginatedData.meta.from);
    }
  }, [paginatedData]);
  const searchDiscoupen = (e) => {
    axios
      .get(`/Discount?search=${e.target.value}`)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          setDiscountData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          setFirsAxios(`/Discount?search=${e.target.value}`);
          forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  // function for get specific deatiles of coupen
  const editCoupen = (id) => {
    axios
      .get(`/Discount/${id}/edit`)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          dispatch(SendCoupenForEdit(res.data.data));
          navigate("/UpdateDiscount");
          setTimeout(() => {
            dispatch(ShowAlert(true, res.data.message));
          }, 1000);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const itemCheckBoxOnChange = (id) => {
    if (!ids.includes(id)) {
      ids.push(id);
    } else {
      var index = ids.indexOf(id);
      ids.splice(index, 1);
    }
    console.log(ids);
    forceUpdate();
  };
  const allCheckBoxOnChange = () => {
    if (discountData.every((i) => ids.includes(i.id))) {
      discountData.map((item, i) => {
        ids.splice(ids.indexOf(item.id), 1);
        forceUpdate();
      });
    } else {
      discountData.map((item, index) => {
        if (!ids.includes(item.id)) {
          ids.push(item.id);
          forceUpdate();
        }
      });
    }
  };
  const openModalForDelete = () => {
    if (ids.length > 0) {
      setModal(true);
    } else {
      dispatch(ShowAlert(true, "لطفا کوپن های تخفیف را انتخاب کنید ."));
    }
  };
  const cancelDeleteClick = (cancled) => {
    setModal(false);
    if (cancled) {
      setIds([]);
    }
  };

  const forceDeleteCoupen = () => {
    axios
      .delete("/Discount/deleteMultiple", { data: { coupon_ids: ids } })
      .then((res) => {
        if (res.data.status) {
          setModal(false);
          console.log(res);
          dispatch(ShowAlert(true, res.data.message));
          axios
            .get("/Discount")
            .then((res) => {
              console.log(res);
              if (res.data.status) {
                setDiscountData(res.data.data);
                setCurrentPage(res.data.meta.current_page);
                setFrom(res.data.meta.from);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="manage-discount-all">
      <div className="manage-discount-top-bar">
        <TopBar />
      </div>
      <Modal
        onBackdropClick={() => cancelDeleteClick(false)}
        className="manage-discount-modal"
        open={modal}
      >
        <div className="manage-discount-modal-box">
          <div className="manage-discount-modal-title-box">
            <p>حذف کوپن</p>
            <img src={DeleteRed} />
          </div>
          <div className="manage-discount-modal-title-box-2">
            <p>ایا از پاک کردن کوپن بطور کامل مطمئن هستید ؟</p>
          </div>
          <div className="manage-discount-modal-button-box">
            <button
              onClick={() => cancelDeleteClick(true)}
              className="manage-discount-modal-button-cancel"
            >
              <p>انصراف</p>
            </button>
            <button
              onClick={() => forceDeleteCoupen()}
              className="manage-discount-modal-button-delete"
            >
              <p>حذف</p>
            </button>
          </div>
        </div>
      </Modal>
      <div className="manage-discount-serach-buttons-box">
        <div className="manage-discount-buttons-box">
          <button
            onClick={() => setShowFilter(!showFilter)}
            className="manage-discount-show-filter-button"
          >
            <img src={FilterLogo} />
            <p>{showFilter === false ? "نمایش فیلتر" : "بستن فیلتر"}</p>
          </button>
          <button
            onClick={() => navigate("/CreateDiscount")}
            className="manage-discount-create-discount-button"
          >
            <img src={AllProductAdd} />
            <p>ایجاد کوپن</p>
          </button>
          {/* <button
            onClick={() => openModalForDelete()}
            className={"manage-discount-delete-button"}
          >
            <img src={GarbageDelte} />
            <p>حذف</p>
          </button> */}
        </div>
        <InputComponent
          width="25%"
          height="44px"
          type="text"
          inputType="inputWithIcon"
          iconSide="right"
          iconSrc={SearchLogo}
          backgroundColor="#f7f7f7"
          placeholder="جستجو محصول.."
          borderColor="#dcdcdc"
          onchange={(e) => searchDiscoupen(e)}
        />
      </div>
      <div className="manage-discount-table-box">
        <div
          className={
            showFilter === false
              ? "manage-discount-table-box-2"
              : "manage-discount-table-box-3"
          }
        >
          <SimpleBar
            forceVisible="x"
            // scrollableNodeProps={{ ref: this.scrollTitleRef }}
            style={{
              width: "100%",
              borderRadius: "10px 10px 0 0",
              borderLeft: "1px solid #dcdcdc",
              borderRight: "1px solid #dcdcdc",
              cursor: "grab",
            }}
          >
            <ScrollDrag innerRef={scrollTitleRef}>
              <div
                style={{ minWidth: "1500px" }}
                className="all-products-header dragscroll"
              >
                <div className="all-products-tab-bar">
                  <div className="all-products-tab-bar-right">
                    <p
                      style={
                        location.pathname == "/ManageDiscount"
                          ? {
                              color: "#00457b",
                              fontWeight: "bold",
                            }
                          : {}
                      }
                    >
                      مدیریت کوپن ها
                    </p>
                  </div>

                  <div className="all-products-tab-bar-left">
                    <button
                      style={
                        ids.length > 0 ? { backgroundColor: "#f23b3b" } : {}
                      }
                      onClick={() => openModalForDelete()}
                      className="AllProductTopShowTrashButton"
                    >
                      {ids.length > 0 ? (
                        <img src={AllProductTrash} />
                      ) : (
                        <img src={AllProductTrashRed} />
                      )}
                      <p
                        style={ids.length > 0 ? { color: "#fff" } : {}}
                        className="AllProductTopShowTrashButtonTittle"
                      >
                        حذف
                      </p>
                    </button>
                  </div>
                </div>

                <div className="manage-discount-table-top-bar">
                  <div className="manage-discount-table-top-bar-hashtag">
                    <p>#</p>
                  </div>
                  <div className="manage-discount-table-top-bar-check-box">
                    <label htmlFor="allCheckBox">
                      <input
                        style={{
                          display: "none",
                        }}
                        id="allCheckBox"
                        name="allCheckBox"
                        type="checkbox"
                        onChange={() => allCheckBoxOnChange()}
                      />
                      <img
                        src={
                          discountData.every((i) => ids.includes(i.id)) &&
                          discountData.length != 0
                            ? headerCheck
                            : uncheck
                        }
                      />
                    </label>
                  </div>
                  <div className="manage-discount-table-top-bar-code">
                    <p>کد کوپن</p>
                  </div>
                  <div className="manage-discount-table-top-add-on">
                    <p>اعمال بر روی</p>
                  </div>
                  <div className="manage-discount-table-top-discount">
                    <p>میزان تخفیف</p>
                  </div>
                  <div className="manage-discount-table-top-limit">
                    <p>محدودیت مصرف</p>
                  </div>
                  <div className="manage-discount-table-top-date">
                    <p>تاریخ انقضا</p>
                  </div>
                  <div className="manage-discount-table-top-situation">
                    <p>وضعیت انتشار</p>
                  </div>
                  <div className="manage-discount-table-top-description">
                    <p>توضیحات</p>
                  </div>
                  <div className="manage-discount-table-top-opration">
                    <p>عملیات</p>
                  </div>
                </div>
              </div>
            </ScrollDrag>
          </SimpleBar>

          <SimpleBar
            forceVisible="x"
            // scrollableNodeProps={{ ref: this.scrollContentRef }}
            style={{
              width: "calc(100% - 3px)",
              borderLeft: "solid 1px #dcdcdc",
              borderRight: "solid 1px #dcdcdc",
              borderBottom: "solid 1px #dcdcdc",
              borderRadius: "0 0 10px 10px",
            }}
          >
            <ScrollDrag
              className="scroll-container"
              innerRef={scrollContentRef}
            >
              {discountData.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={
                      discountData.length == index + 1
                        ? {
                            borderRadius: "0 0 10px 10px",
                            borderBottom: "none",
                          }
                        : { borderBottom: "solid 1px #dcdcdc" }
                    }
                    className="manage-discount-table-item-box"
                  >
                    <div className="manage-discount-table-item-hashtag">
                      <p>{index + from}</p>
                    </div>

                    <div className="manage-discount-table-item-check-box">
                      <label htmlFor={`item${index}`}>
                        <input
                          style={{
                            display: "none",
                          }}
                          type="checkbox"
                          id={`item${index}`}
                          name={`item${index}`}
                          onChange={() => itemCheckBoxOnChange(item.id)}
                        />
                        <img src={ids.includes(item.id) ? check : uncheck} />
                      </label>
                    </div>
                    <div className="manage-discount-table-item-code">
                      <p>{item.code}</p>
                    </div>
                    <div className="manage-discount-table-item-add-on">
                      <p>
                        {item.apply_on_productCategories != 0 &&
                          `${item.apply_on_productCategories} دسته بندی`}
                      </p>
                      <p>
                        {item.apply_on_productPrices != 0 &&
                          `${item.apply_on_productPrices} محصول`}
                      </p>
                      <p>
                        {item.apply_on_users != 0 &&
                          `${item.apply_on_users} مشتری`}
                      </p>
                    </div>
                    <div className="manage-discount-table-item-discount">
                      <p>
                        {item.is_percentage === 1
                          ? `${item.amount} %`
                          : `${item.amount} تومان`}
                      </p>
                    </div>
                    <div className="manage-discount-table-item-limit">
                      <p>
                        {item.max_usage != 0
                          ? `${item.max_usage}  بار استفاده`
                          : "استفاده بی‌نهایت از کوپن"}
                      </p>
                    </div>
                    <div className="manage-discount-table-item-date">
                      <p>{item.expiration_date}</p>
                    </div>
                    <div className="manage-discount-table-item-situation">
                      <div
                        className={
                          item.release_status === "پیش نویس"
                            ? "manage-discount-table-item-situation-2"
                            : "manage-discount-table-item-situation-3"
                        }
                      >
                        <p>{item.release_status}</p>
                      </div>
                    </div>
                    <div className="manage-discount-table-item-description">
                      <p>{item.description}</p>
                    </div>
                    <div className="manage-discount-table-item-operation">
                      <img
                        onClick={() => editCoupen(item.id)}
                        src={EditActive}
                      />
                    </div>
                  </div>
                );
              })}
            </ScrollDrag>
          </SimpleBar>

          <div className="manage-discount-pagination-box">
            <Pagination
              firstAxios={firstAxios}
              currentAxios="/Discount?page="
            />
          </div>
        </div>
        {showFilter === true && (
          <Filter
            Date={false}
            Category={false}
            Situation={true}
            //    CategoryAxios={"/blogCategory"}
            Data={Data}
            Url={Url}
            axiosURL="/Discount"
          />
        )}
      </div>
    </div>
  );
}
