const iniState = {
  variableProductPrice: [],
};
const SendVariableProductPriceReducer = (state = iniState, action) => {
  switch (action.type) {
    case "SEND_VARIABLE_PRODUCT_PRICE":
      return { ...iniState, variableProductPrice: action.variableProductPrice };

    case "DELETE_VARIABLE_PRODUCT_PRICE":
      state.variableProductPrice.splice(action.index, 1);
      return state;
    case "CLEAR_VARIABLE_PRICE":
      state.variableProductPrice.map((item, i) => {
        state.variableProductPrice.splice(i, 1);
      });
      return state;
    default:
      return state;
  }
};
export default SendVariableProductPriceReducer;
