import React, { useEffect, useState } from "react";
import "../../assets/styles/VisitorStatisticsStyle/SalesReportStyle/SalesReportDetails.css";
import axios from "../../js/Axios/Axios";
export default function VisitorStatisticsSignUp() {
  const [info, setInfo] = useState(null);
  const [month, setMonth] = useState("");
  useEffect(() => {
    axios
      .get("/Order/report?")
      .then((res) => {
        console.log(res);
        setInfo(res.data.other_reports)
        if (res.data.other_reports.month === "01") {
          setMonth("فروردین");
        } else if (res.data.other_reports.month === "02") {
          setMonth("اردیبهشت");
        } else if (res.data.other_reports.month === "03") {
          setMonth("خرداد");
        } else if (res.data.other_reports.month === "04") {
          setMonth("تیر");
        } else if (res.data.other_reports.month === "05") {
          setMonth("مرداد");
        } else if (res.data.other_reports.month === "06") {
          setMonth("شهریور");
        } else if (res.data.other_reports.month === "07") {
          setMonth("مهر");
        } else if (res.data.other_reports.month === "08") {
          setMonth("آبان");
        } else if (res.data.other_reports.month === "09") {
          setMonth("آذر");
        } else if (res.data.other_reports.month === "10") {
          setMonth("دی");
        } else if (res.data.other_reports.month === "11") {
          setMonth("بهمن");
        } else if (res.data.other_reports.month === "12") {
          setMonth("اسفند");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
 

  return (
    <div className="sales-report-signup-all-box">
      <div className="sales-report-signup-all-box-1">
        <p>تعداد محصول فروخته شده :</p>
        <p>مجموع سفارشات فروشگاه :</p>
        <p> مجموع مبلغ سفارشات :</p>
        <p> پرفروشترین ماه :</p>
        <p> مبلغ پرفروشترین ماه :</p>
        <p> پرفروشترین روز :</p>
        <p> مبلغ پرفروشترین روز :</p>
      </div>
      {info != null && (
        <div className="sales-report-signup-all-box-2">
          <div className="sales-report-signup-all-box-3">
            <p>{info.products_count}</p>
            {/* <p className="sales-report-signup-all-box-3-title">(نفر)</p> */}
          </div>
          <div className="sales-report-signup-all-box-3">
            <p>{info.orders_count}</p>
            {/* <p>{() => witchMonth(info.most_visit_month)}</p> */}
          </div>
          <div className="sales-report-signup-all-box-3">
            <p>{info.orders_price}</p>
          </div>
          <div className="sales-report-signup-all-box-3">
            <p>{month}</p>
            {/* <p className="sales-report-signup-all-box-3-title">(نفر)</p> */}
          </div>
          <div className="sales-report-signup-all-box-3">
            <p>{info.sale_of_month}</p>
            {/* <p className="sales-report-signup-all-box-3-title">(نفر)</p> */}
          </div>
          <div className="sales-report-signup-all-box-3">
            <p>{info.most_sale_day}</p>
            {/* <p className="sales-report-signup-all-box-3-title">(نفر)</p> */}
          </div>
          <div className="sales-report-signup-all-box-3">
            <p>{info.most_sale_day_price}</p>
            {/* <p className="sales-report-signup-all-box-3-title">(نفر)</p> */}
          </div>
        </div>
      )}
    </div>
  );
}
