import Axios from "../js/Axios/Axios";
import { ActionTypes } from "./actionTypes";

export const UPDATE_TOKEN = "UPDATE_TOKEN";

export const AddPropertyName = (Property) => {
  return {
    type: "ADD_PROPERTY_NAME",
    payload: Property,
  };
};
export const SelectProperty = (Property) => {
  return {
    type: "SELECT_PROPERTY",
    payload: Property,
  };
};
export const ResetToInit = () => {
  return {
    type: "RESET_TO_INIT",
  };
};
export const AddTag = (Property) => {
  return {
    type: "ADD_TAG",
    payload: Property,
  };
};
export const ShowAlert = (show, errors) => {
  return {
    type: "SHOW_ALERT",
    show,
    errors,
  };
};
export const AddAdminInfo = (Property) => {
  return {
    type: "ADD_ADDMIN_INFO",
    payload: Property,
  };
};

export const updateToken = (token) => {
  return {
    type: UPDATE_TOKEN,
    token,
  };
};
export const LogIn = (Property) => {
  return {
    type: "LOG_IN",
    payload: Property,
  };
};
export const ProductInfo = (Property) => {
  return {
    type: "PRODUCT_INFO",
    payload: Property,
  };
};
export const TagId = (Property) => {
  return {
    type: "TAG_ID",
    payload: Property,
  };
};
export const DeleteTagId = (index) => {
  return {
    type: "DELETE_TAG_ID",
    index,
  };
};
export const CloseDrawerAction = (Property) => {
  return {
    type: "CLOSE_DRAWER",
    payload: Property,
  };
};
export const sendCategoryNameAndId = (Property) => {
  return {
    type: "SEND_CATEGORY_NAME_AND_ID",
    payload: Property,
  };
};
export const SendCategoryId = (id) => {
  return {
    type: "SEND_CATEGORY_ID",
    id,
  };
};
export const SendProperyName = (propertyName) => {
  return {
    type: "SEND_PROPERTY_NAME",
    propertyName,
  };
};
export const SendPropertyValueId = (index, value_id, value) => {
  return {
    type: "SEND_PRODUCT_PROPERTY_VALUE_ID",
    index,
    value_id,
    value,
  };
};
export const DeletPropertyValueId = (propertyShow) => {
  return {
    type: "DELETE_PRODUCT_PROPERTY_VALUE_ID",
    propertyShow,
  };
};
export const SendToggleIsKey = (index, toggle) => {
  return {
    type: "SEND_PRODUCT_PROPERTY_ISKEY",
    index,
    toggle,
  };
};

export const SendSeoInfo = (Property) => {
  return {
    type: "SEND_SEO_INFO",
    payload: Property,
  };
};
export const SendSeoQuestion = (myindex, question) => {
  return {
    type: "SEND_SEO_QUESTION",
    myindex,
    question,
  };
};
export const SendSeoAnswer = (myindex, answer) => {
  return {
    type: "SEND_SEO_ANSWER",
    myindex,
    answer,
  };
};
export const SendSeoQuestionAnswerArray = () => {
  return {
    type: "SEND_SEO_QUESTION_ANSWER_ARRAY",
  };
};

export const AddObjectProductReview = () => {
  return {
    type: "ADD_OBJECT_PRODUCT_REVIEW",
  };
};

export const SendProductReviewId = (index, id) => {
  return {
    type: "SEND_PRODUCT_REVIEW_ID",
    index,
    id,
  };
};

export const SendProductReviewTitle = (index, title) => {
  return {
    type: "SEND_PRODUCT_REVIEW_TITLE",
    index,
    title,
  };
};
export const SendProductReviewDescription = (index, description) => {
  return {
    type: "SEND_PRODUCT_REVIEW_DESCRIPTION",
    index,
    description,
  };
};
export const SendProductReviewMediaId = (index, media_id, imgLink) => {
  return {
    type: "SEND_PRODUCT_REVIEW_MEDIA_ID",
    index,
    media_id,
    imgLink,
  };
};
export const DeleteProductReviewMediaId = (index) => {
  return {
    type: "Delete_PRODUCT_REVIEW_MEDIA_ID",
    index,
  };
};
export const DeleteProductReviewAllLine = (index) => {
  return {
    type: "Delete_PRODUCT_REVIEW_ALL_LINE",
    index,
  };
};
export const SendProductGalleryInfo = (productGalleries) => {
  return {
    type: "SEND_PRODUCT_GALLERY_INFO",
    productGalleries,
  };
};
export const SendProductGalleryInfoIsMain = (index, is_main) => {
  return {
    type: "SEND_PRODUCT_GALLERY_INFO_IS_MAIN",
    index,
    is_main,
  };
};
export const DeleteProductGalleryInfoIsMain = (index) => {
  return {
    type: "Delete_PRODUCT_GALLERY_INFO_IS_MAIN",
    index,
  };
};
export const SendSimpleProductPrice = (productPrice) => {
  return {
    type: "SEND_SIMPLE_PRODUCT_PRICE",
    productPrice,
  };
};
export const SendVariableProductPrice = (variableProductPrice) => {
  return {
    type: "SEND_VARIABLE_PRODUCT_PRICE",
    variableProductPrice,
  };
};
export const DeleteVariableProductPrice = (index) => {
  return {
    type: "DELETE_VARIABLE_PRODUCT_PRICE",
    index,
  };
};
export const ProductPriceType = (Property) => {
  return {
    type: "PRODUCT_PRICE_TYPE",
    payload: Property,
  };
};
export const PaginateData = (Property) => {
  return {
    type: "PAGINATE_DATA",
    payload: Property,
  };
};
export const UpdateAllProductProductId = (ProductId) => {
  return {
    type: "UPDATE_ALL_PRODUCT_PRODUCT_ID",
    ProductId,
  };
};
export const ProductInfoForEdit = (ProductInfo) => {
  return {
    type: "PRODUCT_INFO_FOR_EDIT",
    ProductInfo,
  };
};

export const SendBlogInfo = (blogInfo) => {
  return {
    type: "SEND_BLOG_INFO",
    blogInfo,
  };
};
export const SendBlogContent = (content) => {
  return {
    type: "SEND_BLOG_CONTENT",
    content,
  };
};
export const SendBlogTag = (id) => {
  return {
    type: "SEND_BLOG_TAG",
    id,
  };
};
export const DeleteBlogTag = (index) => {
  return {
    type: "DELETE_BLOG_TAG",
    index,
  };
};
export const AddBlogCategory = (id) => {
  return {
    type: "ADD_BLOG_CATEGORY",
    id,
  };
};
export const SendBlogSeoInfo = (blogSeoInfo) => {
  return {
    type: "SEND_BLOG_SEO_INFO",
    blogSeoInfo,
  };
};
export const AddBlogSeoFaq = () => {
  return {
    type: "ADD_BLOG_SEO_FAQ",
  };
};
export const SendBlogSeoFaqQuestion = (question, index) => {
  return {
    type: "SEND_BLOG_SEO_FAQ_QUESTION",
    question,
    index,
  };
};
export const SendBlogSeoFaqAnswer = (answer, index) => {
  return {
    type: "SEND_BLOG_SEO_FAQ_ANSWER",
    answer,
    index,
  };
};
export const SendBlogInfoForEdit = (blog) => {
  return {
    type: "SEND_BLOG_INFO_FOR_EDIT",
    blog,
  };
};
export const SendUserIdForFilter = (id, name) => {
  return {
    type: "SEND_USER_ID_FOR_FILTER",
    id,
    name,
  };
};
export const AxiosNameDiscount = (name) => {
  return {
    type: "AXIOS_NAME_DISCOUNT",
    name,
  };
};
export const SendCoupenForEdit = (coupen) => {
  return {
    type: "SEND_COUPEN_FOR_EDIT",
    coupen,
  };
};
export const SendOrderId = (id) => {
  return {
    type: "SEND_ORDER_ID",
    id,
  };
};
export const SendOrderEdit = (data) => {
  return {
    type: "SEND_ORDER_Edit",
    data,
  };
};
export const SendIdForEdit = (id) => {
  return {
    type: "SEND_ID_FOR_EDIT",
    id,
  };
};
export const ProductError = (error) => {
  return {
    type: "PRODUCT_ERROR",
    error,
  };
};
export const BlogError = (error) => {
  return {
    type: "BLOG_ERROR",
    error,
  };
};
export const ClearSimplePrice = () => {
  return {
    type: "CLEAR_SIMPLE_PRICE",
  };
};
export const ClearVariablePrice = () => {
  return {
    type: "CLEAR_VARIABLE_PRICE",
  };
};

export const SendReturnedOrderId = (id) => {
  return {
    type: "SEND_RETURNED_ORDER_ID",
    id,
  };
};

export const SetTableData = (url) => async (dispatch) => {
  await Axios.get(url)
    .then((res) => {
      console.log("action",res);
      dispatch({ type: ActionTypes.SET_TABLE_DATA, payload: res });
    })
    .catch((e) => {
      console.log(e);
    });
};
