import React, { Component } from "react";
import "../../assets/styles/PropertyStyle/PropertyStyle.css";
import { connect } from "react-redux";
import { ShowAlert, Error } from "../../action/index";
import { PaginateData } from "../../action/index";
import { convertObjToArray } from "../../helper/Helper";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import Pagination from "../../components/Pagination/Pagination";
import TopBar from "../../components/TopBar/TopBar";
import SearchLogo from "../../assets/icons/SearchLogo.svg";
import Delete from "../../assets/icons/Delete.svg";
import DeleteRed from "../../assets/icons/DeleteRed.svg";
import Edit from "../../assets/icons/Edit.svg";
import EditActive from "../../assets/icons/EditActive.svg";
import Star from "../../assets/icons/Star.svg";
import SavedIconProperty from "../../assets/icons/SavedIconProperty.svg";
import SavedIconPropertyGray from "../../assets/icons/SavedIconPropertyGray.svg";
import axios from "../../js/Axios/Axios";
import Ghabli from "../../assets/icons/ghabli.svg";
import baadi from "../../assets/icons/baadi.svg";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

class Property extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PropertyData: [],
      numberPage: null,
      numberPagination: [],
      currentpage: null,
      prevPage: null,
      nextPage: null,
      from: null,
      search: null,
      paginationLink: [],
      PropertyInfo: {
        key: "",
        lil_name: "",
      },
      PropertyValueInfo: {
        value: "",
      },
      property_id: null,
      PropertyValueInput: [{ title: "", value: "", id: null }],

      ButtonTittle: "افزودن ویژگی",
      addValueButtonTittle: "افزودن مقدار",
      lastPage: null,
      firstAxios: "/property?isPaginated=1",
      Errors: {
        key: false,
        lil_key: false,
        value: [{ value: false }],
      },
    };

    axios
      .get("/property?isPaginated=1")
      .then((res) => {
        console.log(res);
        const Property = res.data.data;
        this.setState({ PropertyData: Property });
        this.setState({ numberPage: res.data.meta.last_page });
        this.setState({ currentpage: res.data.meta.current_page });
        this.setState({ prevPage: res.data.links.prev });
        this.setState({ nextPage: res.data.links.next });
        this.setState({ from: res.data.meta.from });
        this.setState({ lastPage: res.data.meta.last_page });
        // this.setState({ paginationLink: res.data.meta.links });
        
      })
      .catch((e) => {
        console.log(e);
      });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.PaginateData.data && props.PaginateData.data.length > 0) {
      state.PropertyData = props.PaginateData.data;
      // state.is_active = props.PaginateData.data.is_active;
      state.currentpage = props.PaginateData.meta.current_page;
      state.from = props.PaginateData.meta.from;
      state.paginationLink = props.PaginateData.meta.links;
      state.lastPage = props.PaginateData.meta.last_page;
    }
  }

  addValueClick = () => {
    if (
      this.state.PropertyValueInput[this.state.PropertyValueInput.length - 1]
        .value != ""
    ) {
      this.state.PropertyValueInput.push({
        title: "",
        value: "",
        id: null,
      });
      this.state.Errors.value.push({ value: false });
      this.forceUpdate();
    } else {
      this.props.dispatch(
        ShowAlert(true, "برای افزودن مقدار پر کردن فیلد مقدار الزامی است .")
      );
      this.state.Errors.value[this.state.Errors.value.length - 1].value = true;
      this.forceUpdate();
    }
  };

  searchHandelChange = (e) => {
    this.state.search = e.target.value;
    axios
      .get(`/property?isPaginated=1&page&search=${this.state.search}`)
      .then((res) => {
        const Property = res.data.data;
        this.setState({ PropertyData: Property });
        this.setState({ currentpage: res.data.meta.current_page });
        this.setState({ from: res.data.meta.from });
        this.setState({ prevPage: res.data.links.prev });
        this.setState({ nextPage: res.data.links.next });
        this.setState({ paginationLink: res.data.meta.links });
        this.setState({firstAxios : `/property?isPaginated=1&page&search=${this.state.search}`})
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  PropertyNameHandelChange = (e) => {
    this.state.PropertyInfo.key = e.target.value;
    this.state.Errors.key = false;
    this.forceUpdate();
  };
  PropertyLilNameHandelChange = (e) => {
    this.state.PropertyInfo.lil_name = e.target.value;
    this.state.Errors.key = false;
    this.forceUpdate();
  };
  PropertyValueHandelChange = (index, e) => {
    this.state.PropertyValueInput[index].value = e.target.value;
    this.state.Errors.value[index].value = false;
    this.forceUpdate();
  };

  addValues = (index) => {
    if (this.state.property_id === null) {
      axios
        .post("/property", this.state.PropertyInfo)
        .then((res) => {
          console.log(res);
          if (res.data.status === 0) {
            this.props.dispatch(ShowAlert(true, res.data.data));
            let array = convertObjToArray(res.data.data);
            console.log(array);
            array.map((item) => {
              if (item.key === "key") {
                this.state.Errors.key = true;
              }
              if (item.key === "lil_name") {
                this.state.Errors.lil_key = true;
              }
              this.forceUpdate();
            });
          }
          if (res.data.status) {
            this.props.dispatch(ShowAlert(true, res.data.message));
            this.state.property_id = res.data.data.id;
            this.state.PropertyValueInput[index].property_id =
              this.state.property_id;
            axios
              .post("/propertyValue", this.state.PropertyValueInput[index])
              .then((res) => {
                console.log(res);
                if (res.data.status === 0) {
                  this.props.dispatch(ShowAlert(true, res.data.message));
                }
                if (res.data.status) {
                  setTimeout(() => {
                    this.props.dispatch(ShowAlert(true, res.data.message));
                  }, 5000);
                  this.state.PropertyValueInput[index].id = res.data.data.id;
                  if (this.state.PropertyData.length == 5) {
                    this.state.currentpage = this.state.currentpage + 1;
                  }
                  axios
                    .get(
                      `/property?isPaginated=1&page=${this.state.currentpage}`
                    )
                    .then((res) => {
                      console.log(res);
                      const Property = res.data.data;
                      this.setState({ PropertyData: Property });
                      this.setState({ numberPage: res.data.meta.last_page });
                      this.setState({
                        currentpage: res.data.meta.current_page,
                      });
                      this.setState({ prevPage: res.data.links.prev });
                      this.setState({ nextPage: res.data.links.next });
                      this.setState({ from: res.data.meta.from });
                      this.setState({ paginationLink: res.data.meta.links });
                      this.setState({ lastPage: res.data.meta.last_page });
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      this.state.PropertyValueInput[index].property_id = this.state.property_id;
      if (this.state.PropertyValueInput[index].id === null) {
        axios
          .post("/propertyValue", this.state.PropertyValueInput[index])
          .then((res) => {
            if (res.data.status === 0) {
              this.props.dispatch(ShowAlert(true, res.data.message));
            }
            console.log(res);
            if (res.data.status) {
              this.state.PropertyValueInput[index].id = res.data.data.id;
              this.props.dispatch(ShowAlert(true, res.data.message));
              axios
                .get(`/property?isPaginated=1&page=${this.state.currentpage}`)
                .then((res) => {
                  console.log(res);
                  const Property = res.data.data;
                  this.setState({ PropertyData: Property });
                  this.setState({ numberPage: res.data.meta.last_page });
                  this.setState({ currentpage: res.data.meta.current_page });
                  this.setState({ prevPage: res.data.links.prev });
                  this.setState({ nextPage: res.data.links.next });
                  this.setState({ from: res.data.meta.from });
                  this.setState({ paginationLink: res.data.meta.links });
                  this.setState({ lastPage: res.data.meta.last_page });
                })
                .catch((e) => {
                  console.log(e);
                });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        axios
          .put(
            `/propertyValue/${this.state.PropertyValueInput[index].id}`,
            this.state.PropertyValueInput[index]
          )
          .then((res) => {
            console.log(res);
            if (res.data.status === 0) {
              this.props.dispatch(ShowAlert(true, res.data.message));
            }
            if (res.data.status) {
              this.props.dispatch(ShowAlert(true, res.data.message));
              axios
                .get(`/property?isPaginated=1&page=${this.state.currentpage}`)
                .then((res) => {
                  console.log(res);
                  if (res.data.status) {
                    const Property = res.data.data;
                    this.setState({ PropertyData: Property });
                    this.setState({ numberPage: res.data.meta.last_page });
                    this.setState({ currentpage: res.data.meta.current_page });
                    this.setState({ prevPage: res.data.links.prev });
                    this.setState({ nextPage: res.data.links.next });
                    this.setState({ from: res.data.meta.from });
                    this.setState({ paginationLink: res.data.meta.links });
                    this.setState({ lastPage: res.data.meta.last_page });
                  }
                })
                .catch((e) => {
                  console.log(e);
                });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  };
  deleteValues = (index) => {
    if (this.state.PropertyValueInput[index].id === null) {
      if (this.state.PropertyValueInput.length - 1 === 0) {
        this.state.PropertyValueInput.push({
          title: "",
          value: "",
          id: null,
        });
        this.forceUpdate();
        this.state.Errors.value.push({ value: false });
      }
      this.state.PropertyValueInput.splice(index, 1);
      this.state.Errors.value.splice(index, 1);
      this.forceUpdate();
    } else {
      axios
        .delete(`/propertyValue/${this.state.PropertyValueInput[index].id}`)
        .then((res) => {
          console.log(res);
          if (res.data.status === 0) {
            this.props.dispatch(ShowAlert(true, res.data.message));
          }
          if (res.data.status) {
            this.props.dispatch(ShowAlert(true, res.data.message));
            if (this.state.PropertyValueInput.length - 1 === 0) {
              this.state.PropertyValueInput.push({
                title: "",
                value: "",
                id: null,
              });
              this.forceUpdate();
              this.state.Errors.value.push({ value: false });
            }
            this.state.PropertyValueInput.splice(index, 1);
            this.state.Errors.value.splice(index, 1);
            this.forceUpdate();
          }
          axios
            .get(`/property?isPaginated=1&page=${this.state.currentpage}`)
            .then((res) => {
              console.log(res);
              if (res.data.status) {
                const Property = res.data.data;
                this.setState({ PropertyData: Property });
                this.setState({ numberPage: res.data.meta.last_page });
                this.setState({ currentpage: res.data.meta.current_page });
                this.setState({ prevPage: res.data.links.prev });
                this.setState({ nextPage: res.data.links.next });
                this.setState({ from: res.data.meta.from });
                this.setState({ paginationLink: res.data.meta.links });
                this.setState({ lastPage: res.data.meta.last_page });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  addPropertyClick = () => {
    if (this.state.property_id !== null) {
      axios
        .put(`/property/${this.state.property_id}`, this.state.PropertyInfo)
        .then((res) => {
          if (res.data.status === 0) {
            this.props.dispatch(ShowAlert(true, res.data.data));
            let array = convertObjToArray(res.data.data);
            console.log(array);
            array.map((item) => {
              if (item.key === "key") {
                this.state.Errors.key = true;
              }
              if (item.key === "lil_name") {
                this.state.Errors.lil_key = true;
              }
              this.forceUpdate();
            });
          }
          if (res.data.status) {
            this.props.dispatch(ShowAlert(true, res.data.message));
            console.log(res);
            this.setState({
              PropertyInfo: {
                key: "",
                lil_name: "",
              },
            });
            this.setState({
              PropertyValueInput: [{ title: "", value: "", id: null }],
            });
            this.setState({ ButtonTittle: "افزودن ویژگی" });
            this.setState({ property_id: null });
            axios
              .get(
                `/property?isPaginated=1&page=
                ${
                  this.state.currentpage == this.state.lastPage &&
                  this.state.PropertyData.length === 5
                    ? this.state.currentpage + 1
                    : this.state.currentpage
                }`
              )
              .then((res) => {
                this.setState({
                  firstAxios: `/property?isPaginated=1&page=
                  ${
                    this.state.currentpage == this.state.lastPage &&
                    this.state.PropertyData.length === 5
                      ? this.state.currentpage + 1
                      : this.state.currentpage
                  }`,
                });
                console.log(res);

                this.props.dispatch(PaginateData(res.data));
                const Property = res.data.data;
                this.setState({ PropertyData: Property });
                this.setState({ numberPage: res.data.meta.last_page });
                this.setState({ currentpage: res.data.meta.current_page });
                this.setState({ prevPage: res.data.links.prev });
                this.setState({ nextPage: res.data.links.next });
                this.setState({ from: res.data.meta.from });
                this.setState({ paginationLink: res.data.meta.links });
                this.setState({ lastPage: res.data.meta.last_page });
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      axios
        .post("/property", this.state.PropertyInfo)
        .then((res) => {
          console.log(res);
          if (res.data.status === 0) {
            this.props.dispatch(ShowAlert(true, res.data.data));

            let array = convertObjToArray(res.data.data);
            console.log(array);
            array.map((item) => {
              if (item.key === "key") {
                this.state.Errors.key = true;
              }
              if (item.key === "lil_name") {
                this.state.Errors.lil_key = true;
              }
              this.forceUpdate();
            });
          }
          if (res.data.status) {
            this.props.dispatch(ShowAlert(true, res.data.message));
            this.setState({
              PropertyInfo: {
                key: "",
                lil_name: "",
              },
            });
            this.setState({
              PropertyValueInput: [{ title: "", value: "", id: null }],
            });
            this.setState({ property_id: null });

            axios
              .get(
                `/property?isPaginated=1&page=
                    ${
                      this.state.currentpage == this.state.lastPage &&
                      this.state.PropertyData.length === 5
                        ? this.state.currentpage + 1
                        : this.state.currentpage
                    }`
              )
              .then((res) => {
                console.log(res);

                this.setState({
                  firstAxios: `/property?isPaginated=1&page=
                    ${
                      this.state.currentpage == this.state.lastPage &&
                      this.state.PropertyData.length === 5
                        ? this.state.currentpage + 1
                        : this.state.currentpage
                    }`,
                });
                this.props.dispatch(PaginateData(res.data));
                const Property = res.data.data;
                this.setState({ PropertyData: Property });
                this.setState({ numberPage: res.data.meta.last_page });
                this.setState({ currentpage: res.data.meta.current_page });
                this.setState({ prevPage: res.data.links.prev });
                this.setState({ nextPage: res.data.links.next });
                this.setState({ from: res.data.meta.from });
                this.setState({ paginationLink: res.data.meta.links });
                this.setState({ lastPage: res.data.meta.last_page });
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  DeletePropertyHandel = (id) => {
    axios
      .delete(`/property/${id}`, id)
      .then((res) => {
        if (res.data.status === 0) {
          this.props.dispatch(ShowAlert(true, res.data.message));
        }
        console.log(res);
        if (res.data.status) {
          this.props.dispatch(ShowAlert(true, res.data.message));
          axios
            .get(
              `/property?isPaginated=1&page=${
                this.state.PropertyData.length == 1
                  ? this.state.currentpage - 1
                  : this.state.currentpage
              }`
            )

            .then((res) => {
              console.log(res);
              this.setState({
                firstAxios: `/property?isPaginated=1&page=
                ${
                  this.state.PropertyData.length == 1
                    ? this.state.currentpage - 1
                    : this.state.currentpage
                }`,
              });
              this.props.dispatch(PaginateData(res.data));
              const Property = res.data.data;
              this.setState({ PropertyData: Property });
              this.setState({ numberPage: res.data.meta.last_page });
              this.setState({ currentpage: res.data.meta.current_page });
              this.setState({ prevPage: res.data.links.prev });
              this.setState({ nextPage: res.data.links.next });
              this.setState({ from: res.data.meta.from });
              this.setState({ paginationLink: res.data.meta.links });
              this.setState({ lastPage: res.data.meta.last_page });
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  editPropertyHandel = (id) => {
    this.state.PropertyValueInput =[]        
    this.state.Errors.value =[]
    axios
      .get(`/property/${id}`)
      .then((res) => {
        console.log(res);
        if (res.data.status === 0) {
          this.props.dispatch(ShowAlert(true, res.data.message));
        }
        if (res.data.status) {
          this.setState({
            PropertyInfo: {
              key: res.data.data.key,
              lil_name: res.data.data.lil_name,
            },
          });
          this.setState({ ButtonTittle: " ویرایش ویژگی " });
          if (res.data.data.property_values.length != 0) {

            res.data.data.property_values.map((item) => {
              this.state.Errors.value.push({ value: false });
              this.forceUpdate();
              console.log(this.state.Errors);
            });
            this.setState({
              PropertyValueInput: res.data.data.property_values,
            });
          }
          this.setState({ property_id: id });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  render() {
    return (
      <div className="PropertyAll">
        <div className="PropertyTopBar">
          <TopBar />
        </div>
        <div className="PropertyUpTitleAndSearchBox">
          <p className="PropertyUpTitleAndSearchBoxTitle">افزودن ویژگی جدید</p>
          <InputComponent
            width="337px"
            height="44px"
            type="text"
            inputType="inputWithIcon"
            iconSide="right"
            iconSrc={SearchLogo}
            backgroundColor="#f7f7f7"
            placeholder="جستجو ویژگی .."
            borderColor="#dcdcdc"
            onchange={this.searchHandelChange.bind(this)}
          />
        </div>
        <div className="PropertyBox">
          <div className="PropertyAddPropertyBox">
            <div className="PropertyAddPropertyBoxTittleBox">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "30px",
                }}
              >
                <img className="PropertyAddPropertyBoxTittleIcon" src={Star} />
                <p className="PropertyAddPropertyBoxTittle">
                  ویژگی‌ها به شما اجازه می دهد توضیحات بیشتری درباره محصول مانند
                  رنگ یا اندازه دهید{" "}
                </p>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={SavedIconProperty} />
                <p className="PropertyAddPropertyBoxTittle">
                  برای افزودن مقادیر تک به تک اقدام نمایید
                </p>
              </div>
            </div>
            <div className="PropertyAddPropertyBoxInputBox">
              <div className="PropertyAddPropertyBoxInputBoxFirstInputBox">
                <p className="PropertyAddPropertyBoxInputBoxFirstInputTittle">
                  نام ویژگی :
                </p>
                <InputComponent
                  value={this.state.PropertyInfo.key}
                  width="80%"
                  height="44px"
                  type="text"
                  placeholder="برای نمایش به کاربران سایت"
                  borderColor={`${
                    this.state.Errors.key === true ? "#F23B3B" : "#dcdcdc"
                  }`}
                  inputType="input"
                  onchange={this.PropertyNameHandelChange.bind(this)}
                />
              </div>
              <div className="PropertyAddPropertyBoxInputBoxSecondInputBox">
                <p className="PropertyAddPropertyBoxInputBoxSecondInputTittle">
                  {" "}
                  نامک :{" "}
                </p>
                <InputComponent
                  value={this.state.PropertyInfo.lil_name}
                  width="80%"
                  height="44px"
                  type="text"
                  placeholder="باید انگلیسی بوده و کوتاهتر از 28 کاراکتر باشد"
                  borderColor={`${
                    this.state.Errors.lil_key === true ? "#F23B3B" : "#dcdcdc"
                  }`}
                  inputType="input"
                  onchange={this.PropertyLilNameHandelChange}
                />
              </div>
              <SimpleBar className="PropertyAddPropertyBoxInputBoxThirthInputBox">
                {this.state.PropertyValueInput.map((item, index) => {
                  return (
                    <div className="PropertyAddPropertyBoxInputBoxThirthInputBoxInputBox2">
                      <p className="PropertyAddPropertyBoxInputBoxThirthInputTittle">
                        {" "}
                        مقدار :{" "}
                      </p>
                      <InputComponent
                        value={item.value}
                        width="80%"
                        height="44px"
                        type="text"
                        inputType="inputWithIcon"
                        iconSide="left"
                        onClick={this.addValues.bind(this, index)}
                        iconSrc={SavedIconPropertyGray}
                        iconStyle={[
                          { width: "20px", height: "20px" },
                          { width: "22px", height: "22px" },
                        ]}
                        iconSrcMouseOver={SavedIconProperty}
                        onClick2={this.deleteValues.bind(this, index)}
                        iconSrc2={Delete}
                        iconSrc2MouseOver={DeleteRed}
                        placeholder="مقدار"
                        borderColor={
                          this.state.Errors.value[index].value === true
                            ? "#F23B3B"
                            : "#dcdcdc"
                        }
                        onchange={this.PropertyValueHandelChange.bind(
                          this,
                          index
                        )}
                      />
                    </div>
                  );
                })}
              </SimpleBar>
              <div className="PropertyAddPropertyButtonBox">
                <button
                  onClick={this.addValueClick.bind(this)}
                  className="PropertyAddValueButtonActive"
                >
                  {this.state.addValueButtonTittle}
                </button>
                <button
                  onClick={this.addPropertyClick.bind(this)}
                  className="PropertyAddPropertyButtonActive"
                >
                  {this.state.ButtonTittle}
                </button>
              </div>
            </div>
          </div>

          <div className="PropertyShowBox">
            <div className="PropertyShowBox2">
              <div className="PropertyhowBox2TopBar">
                <p className="PropertyhowBox2TopBarTittle5">#</p>
                <p className="PropertyhowBox2TopBarTittle4">نام</p>
                <p className="PropertyhowBox2TopBarTittle3">نامک</p>
                <p className="PropertyhowBox2TopBarTittle2">مقدار</p>
                <p className="PropertyhowBox2TopBarTittle1">عملیات</p>
              </div>
              {this.state.PropertyData.map((myitem, myindex) => {
                return (
                  <div
                    key={myindex}
                    style={
                      this.state.PropertyData.length === myindex + 1
                        ? {
                            borderRadius: "0 0 10px 10px",
                            borderBottom: "1px solid #00589c",
                          }
                        : {}
                    }
                    className="PropertyShowBox2ItemBox"
                  >
                    <div className="PropertyShowBox2ItemNumberListBox">
                      <p className="PropertyShowBox2ItemNumberListTittle">
                        {this.state.from + myindex}
                      </p>
                    </div>
                    <div className="PropertyshowBox2ItemNameBox">
                      <p className="PropertyshowBox2ItemNameTittle">
                        {myitem.key}
                      </p>
                    </div>
                    <div className="PropertyshowBox2ItemLilNameBox">
                      <p className="PropertyshowBox2ItemLilNameTittle">
                        {myitem.lil_name}
                      </p>
                    </div>
                    <div className="PropertyshowBox2ItemNumberBox">
                      {myitem.property_values.map((values, valuesIndex) => {
                        return (
                          <p className="PropertyshowBox2ItemNumberTittle">
                            {" "}
                            {values.value}{" "}
                          </p>
                        );
                      })}
                    </div>
                    <div className="PropertyshowBox2ItemBoxIconBox">
                      <button
                        onClick={this.editPropertyHandel.bind(this, myitem.id)}
                        className="PropertyshowBox2ItemBoxIconEditButton"
                      >
                        <img
                          src={EditActive}
                          // onMouseOver={(e) =>
                          //   (e.currentTarget.src = EditActive)
                          // }
                          // onMouseOut={(e) => (e.currentTarget.src = Edit)}
                        />
                      </button>

                      <button
                        onClick={this.DeletePropertyHandel.bind(
                          this,
                          myitem.id
                        )}
                        className="PropertyshowBox2ItemBoxIconDelteButton"
                      >
                        <img
                          src={DeleteRed}
                          // onMouseOver={(e) => (e.currentTarget.src = DeleteRed)}
                          // onMouseOut={(e) => (e.currentTarget.src = Delete)}
                        />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="PropertyPaginationBox">
              <Pagination
                firstAxios={this.state.firstAxios}
                currentAxios="/property?isPaginated=1&page="
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    PaginateData: state.PaginatedData,
  };
};
export default connect(mapStateToProps)(Property);
