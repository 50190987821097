import React from "react";
import "../../assets/styles/VisitorStatisticsStyle/SalesReportStyle/SalesReports.css";
import TopBar from "../../components/TopBar/TopBar";
import SalesReportChart from "../../components/SalesReportComponent/SalesReportChart";
import Filter from "../../components/Filter/Filter";
import SalesReportDetails from "../../components/SalesReportComponent/SalesReportDetails";
import SalesReportCategory from "../../components/SalesReportComponent/SalesReportCategory";
import SalesReportIranChart from "../../components/SalesReportComponent/SalesReportIranChart";
import SalesReportState from "../../components/SalesReportComponent/SalesReportState";
import SalesReportCustomers from "../../components/SalesReportComponent/SalesReportCustomers";
import SalesReportDeliveryType from "../../components/SalesReportComponent/SalesReportDeliveryType";
import SalesReportBestProduct from "../../components/SalesReportComponent/SalesReportBestProduct";
export default function VisitorStatistics() {
  return (
    <div className="report-sales-all">
      <TopBar />
      <div className="report-sales-all-box">
        <div className="report-sales-box-2">
          <div className="report-sales-all-box-3">
            <div className="report-sales-filter-box">
              <Filter
                width="800px"
                Date={false}
                Category={false}
                Situation={true}
                //    CategoryAxios={"/blogCategory"}
                //   Data={Data}
                axiosURL="/Discount"
              />
            </div>
            <div className="report-sales-user-sign-up-box">
              <SalesReportDetails />
            </div>
          </div>
          <div className="report-sales-all-box-chart-box">
            <SalesReportChart />
          </div>
        </div>
        <div className="report-sales-all-box-4">
          <div className="report-sales-most-word-box">
            <SalesReportCategory />
          </div>
          <div className="report-sales-most-iran-chart">
            <SalesReportIranChart />
          </div>
        </div>
        {/* <div className="report-sales-all-box-5">
          <div className="report-sales-all-most-state-box">
          </div>
        </div> */}
        <div className="report-sales-all-box-6">
          <div className="report-sales-all-box-7">
            <div className="report-sales-all-box-9">
              <SalesReportState />
            </div>
            {/* <div className="report-sales-all-box-9">
              <VisitorStatisticsMostCity />
            </div> */}
            <div className="report-sales-all-box-10">
              <SalesReportDeliveryType />
            </div>
            <div className="report-sales-all-box-11">
              <SalesReportCustomers />
            </div>
          </div>
          <div className="report-sales-all-box-8">
            <SalesReportBestProduct />
          </div>
        </div>
      </div>
    </div>
  );
}
