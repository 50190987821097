import React, { useEffect, useState } from "react";
import "../../assets/styles/AboutUs/AboutUs.css";
import TopBar from "../../components/TopBar/TopBar";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import axios from "../../js/Axios/Axios";
import { ShowAlert } from "../../action/index";
import useForceUpdate from "use-force-update";
import { useSelector, useDispatch } from "react-redux";
import fastSend from "../../assets/images/fast-send-img.svg";
import simpleBuy from "../../assets/images/simple-buying-img.svg";
import simplePay from "../../assets/images/simple-paying.svg";
import productReturnimg from "../../assets/images/Product-return-guarantee.svg";
import onlineSupportimg from "../../assets/images/online-support.svg";
export default function AuboutUs() {
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const [isEdit, setIsEdit] = useState(null);
  const [aboutUs, setAboutUs] = useState({
    title: "about-us",
    description: "",
    aboutUs_id: null,
  });
  const [sendFast, setSendFast] = useState({
    title: "send-fast",
    description: "",
    aboutUs_id: null,
  });
  const [easyBuy, setEasyBuy] = useState({
    title: "easy-buy",
    description: "",
    aboutUs_id: null,
  });
  const [productReturn, setProductReturn] = useState({
    title: "product-return",
    description: "",
    aboutUs_id: null,
  });
  const [onlineSupport, setOnlineSupport] = useState({
    title: "online-support",
    description: "",
    aboutUs_id: null,
  });
  const [payMethod, setPayMethod] = useState({
    title: "pay-Method",
    description: "",
    aboutUs_id: null,
  });
  useEffect(() => {
    axios
      .get("AboutUs")
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          if (res.data.data.length === 0) {
            setIsEdit("post");
          } else {
            res.data.data.map((item, index) => {
              if (item.title === "about-us") {
                setAboutUs((prev) => {
                  prev.description = item.description;
                  prev.aboutUs_id = item.id;
                  forceUpdate();
                  return prev;
                });
              } else if (item.title === "send-fast") {
                setSendFast((prev) => {
                  prev.description = item.description;
                  prev.aboutUs_id = item.id;
                  forceUpdate();
                  return prev;
                });
              } else if (item.title === "easy-buy") {
                setEasyBuy((prev) => {
                  prev.description = item.description;
                  prev.aboutUs_id = item.id;
                  forceUpdate();
                  return prev;
                });
              } else if (item.title === "product-return") {
                setProductReturn((prev) => {
                  prev.description = item.description;
                  prev.aboutUs_id = item.id;
                  forceUpdate();
                  return prev;
                });
              } else if (item.title === "online-support") {
                setOnlineSupport((prev) => {
                  prev.description = item.description;
                  prev.aboutUs_id = item.id;
                  forceUpdate();
                  return prev;
                });
              } else if (item.title === "pay-Method") {
                setPayMethod((prev) => {
                  prev.description = item.description;
                  prev.aboutUs_id = item.id;
                  forceUpdate();
                  return prev;
                });
              }
            });
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const [error, setError] = useState({
    aboutUs: false,
    sendFast: false,
    easyBuy: false,
    productReturn: false,
    onlineSupport: false,
    payMethod: false,
  });
  const isEditClick = () => {
    if (isEdit === null) {
      setIsEdit("edit");
    } else if (
      isEdit !== null &&
      aboutUs.description != "" &&
      sendFast.description != "" &&
      easyBuy.description != "" &&
      productReturn.description != "" &&
      onlineSupport.description != "" &&
      payMethod.description != ""
    ) {
      let array = [];
      array.push(aboutUs);
      array.push(sendFast);
      array.push(easyBuy);
      array.push(productReturn);
      array.push(onlineSupport);
      array.push(payMethod);
      console.log("/AboutUs", { array });
      axios
        .post("/AboutUs", { aboutUsInfo: array })
        .then((res) => {
          console.log(res);
          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));
            setIsEdit(null);
            axios
              .get("AboutUs")
              .then((res) => {
                if (res.data.status) {
                  console.log(res);
                  res.data.data.map((item, index) => {
                    if (item.title === "about-us") {
                      setAboutUs((prev) => {
                        prev.description = item.description;
                        prev.aboutUs_id = item.id;
                        forceUpdate();
                        return prev;
                      });
                    } else if (item.title === "send-fast") {
                      setSendFast((prev) => {
                        prev.description = item.description;
                        prev.aboutUs_id = item.id;
                        forceUpdate();
                        return prev;
                      });
                    } else if (item.title === "easy-buy") {
                      setEasyBuy((prev) => {
                        prev.description = item.description;
                        prev.aboutUs_id = item.id;
                        forceUpdate();
                        return prev;
                      });
                    } else if (item.title === "product-return") {
                      setProductReturn((prev) => {
                        prev.description = item.description;
                        prev.aboutUs_id = item.id;
                        forceUpdate();
                        return prev;
                      });
                    } else if (item.title === "online-support") {
                      setOnlineSupport((prev) => {
                        prev.description = item.description;
                        prev.aboutUs_id = item.id;
                        forceUpdate();
                        return prev;
                      });
                    } else if (item.title === "pay-Method") {
                      setPayMethod((prev) => {
                        prev.description = item.description;
                        prev.aboutUs_id = item.id;
                        forceUpdate();
                        return prev;
                      });
                    }
                  });
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      if (aboutUs.description == "") {
        setError((prev) => {
          prev.aboutUs = true;
          forceUpdate();
          return prev;
        });
      }
      if (sendFast.description == "") {
        setError((prev) => {
          prev.sendFast = true;
          forceUpdate();
          return prev;
        });
      }
      if (easyBuy.description == "") {
        setError((prev) => {
          prev.easyBuy = true;
          forceUpdate();
          return prev;
        });
      }
      if (productReturn.description == "") {
        setError((prev) => {
          prev.productReturn = true;
          forceUpdate();
          return prev;
        });
      }
      if (onlineSupport.description == "") {
        setError((prev) => {
          prev.onlineSupport = true;
          forceUpdate();
          return prev;
        });
      }
      if (payMethod.description == "") {
        setError((prev) => {
          prev.payMethod = true;
          forceUpdate();
          return prev;
        });
      }
      let text = `برای ثبت درباره ی مای پر کردن فیلد های 
      ${aboutUs.description == "" ? "دربارهی ما و" : ""}
      ${sendFast.description == "" ? "ارسال سریع و" : ""}
      ${easyBuy.description == "" ? "خرید آسان و" : ""}
      ${productReturn.description == "" ? "ضمانت برگشت کالا و" : ""}
      ${onlineSupport.description == "" ? "پشتیبانی آنلاین و" : ""}
      ${payMethod.description == "" ? "روش های پرداخت " : ""}
      الزامی است .
      `;
      dispatch(ShowAlert(true, text));
    }
  };

  return (
    <div className="About-us-all">
      <TopBar />
      <div className="about-us-box">
        <div className="about-us-title-button-box">
          <h5>درباره ما</h5>
          <button
            onClick={() => isEditClick()}
            className="about-us-edit-button"
          >
            {isEdit === null ? "ویرایش" : "ثبت تغیرات"}
          </button>
        </div>
        <div className="about-us-description-box">
          {isEdit === null ? (
            <p>{aboutUs.description}</p>
          ) : (
            <InputComponent
              value={aboutUs.description}
              width="100%"
              height="300px"
              type="text"
              inputType="textarea"
              placeholder="درباره ما"
              borderColor={error.aboutUs === true ? "#F23B3B" : "#dcdcdc"}
              onchange={(e) => {
                setAboutUs((prev) => {
                  prev.description = e.target.value;
                  forceUpdate();
                  return prev;
                });
                setError((prev) => {
                  prev.aboutUs = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
          )}
        </div>
        <div className="about-us-line-img-box">
          <div className="about-us-line-img-box-2">
            <img style={{ width: "250px", height: "180px" }} src={fastSend} />
          </div>
          <p>ارسال سریع</p>
        </div>
        <div className="about-us-description-box">
          {isEdit === null ? (
            <p>{sendFast.description}</p>
          ) : (
            <InputComponent
              value={sendFast.description}
              width="100%"
              height="300px"
              type="text"
              inputType="textarea"
              placeholder="ارسال سریع"
              borderColor={error.sendFast === true ? "#F23B3B" : "#dcdcdc"}
              onchange={(e) => {
                setSendFast((prev) => {
                  prev.description = e.target.value;
                  forceUpdate();
                  return prev;
                });
                setError((prev) => {
                  prev.sendFast = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
          )}
        </div>
        <div className="about-us-line-img-box">
          <div className="about-us-line-img-box-2">
            <img style={{ width: "250px", height: "180px" }} src={simpleBuy} />
          </div>
          <p>خرید اسان</p>
        </div>
        <div className="about-us-description-box">
          {isEdit === null ? (
            <p>{easyBuy.description}</p>
          ) : (
            <InputComponent
              value={easyBuy.description}
              width="100%"
              height="300px"
              type="text"
              inputType="textarea"
              placeholder="خرید اسان"
              borderColor={error.easyBuy === true ? "#F23B3B" : "#dcdcdc"}
              onchange={(e) => {
                setEasyBuy((prev) => {
                  prev.description = e.target.value;
                  forceUpdate();
                  return prev;
                });
                setError((prev) => {
                  prev.easyBuy = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
          )}
        </div>
        <div className="about-us-line-img-box">
          <div className="about-us-line-img-box-2">
            <img
              style={{ width: "250px", height: "180px" }}
              src={productReturnimg}
            />
          </div>
          <p>ضمانت برگشت کالا</p>
        </div>
        <div className="about-us-description-box">
          {isEdit === null ? (
            <p>{productReturn.description}</p>
          ) : (
            <InputComponent
              value={productReturn.description}
              width="100%"
              height="300px"
              type="text"
              inputType="textarea"
              placeholder="ضمانت برگشت کالا"
              borderColor={error.productReturn === true ? "#F23B3B" : "#dcdcdc"}
              onchange={(e) => {
                setProductReturn((prev) => {
                  prev.description = e.target.value;
                  forceUpdate();
                  return prev;
                });
                setError((prev) => {
                  prev.productReturn = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
          )}
        </div>
        <div className="about-us-line-img-box">
          <div className="about-us-line-img-box-2">
            <img
              style={{ width: "250px", height: "180px" }}
              src={onlineSupportimg}
            />
          </div>
          <p>پشتیبانی آنلاین</p>
        </div>
        <div className="about-us-description-box">
          {isEdit === null ? (
            <p>{onlineSupport.description}</p>
          ) : (
            <InputComponent
              value={onlineSupport.description}
              width="100%"
              height="300px"
              type="text"
              inputType="textarea"
              placeholder="پشتیبانی آنلاین"
              borderColor={error.onlineSupport === true ? "#F23B3B" : "#dcdcdc"}
              onchange={(e) => {
                setOnlineSupport((prev) => {
                  prev.description = e.target.value;
                  forceUpdate();
                  return prev;
                });
                setError((prev) => {
                  prev.onlineSupport = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
          )}
        </div>
        <div className="about-us-line-img-box">
          <div className="about-us-line-img-box-2">
            <img style={{ width: "250px", height: "180px" }} src={simplePay} />
          </div>
          <p>روش های پرداخت</p>
        </div>
        <div className="about-us-description-box">
          {isEdit === null ? (
            <p>{payMethod.description}</p>
          ) : (
            <InputComponent
              value={payMethod.description}
              width="100%"
              height="300px"
              type="text"
              inputType="textarea"
              placeholder="روش های پرداخت"
              borderColor={error.payMethod === true ? "#F23B3B" : "#dcdcdc"}
              onchange={(e) => {
                setPayMethod((prev) => {
                  prev.description = e.target.value;
                  forceUpdate();
                  return prev;
                });
                setError((prev) => {
                  prev.payMethod = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
