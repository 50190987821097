import React, { Component } from "react";
import "../../assets/styles/AllDraftProductStyle/AllDraftProduct.css";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import { PaginateData, ShowAlert } from "../../action/index";
import { connect } from "react-redux";
import { UpdateAllProductProductId } from "../../action/index";
import Pagination from "../../components/Pagination/Pagination";
import axios from "../../js/Axios/Axios";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import TopBar from "../../components/TopBar/TopBar";
import AllProductAdd from "../../assets/icons/AllProductAdd.svg";
import Eye from "../../assets/icons/AllProductEye.svg";
// import ActiveEye from "../../assets/icons/AllProductEyeActive.svg";
import EditActive from "../../assets/icons/EditActive.svg";
import AllProductTrashRed from "../../assets/icons/GarbageDelteRed.svg";

import SearchLogo from "../../assets/icons/SearchLogo.svg";
import Delete from "../../assets/icons/Delete.svg";
import DeleteRed from "../../assets/icons/DeleteRed.svg";
import Modal from "@mui/material/Modal";
import { height } from "@mui/system";
import { BorderRight } from "@mui/icons-material";

class AllProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // tab bar states
      goToReleasedProducts: false,
      goToDraftsProducts: false,
      goToTrash: false,

      paginateUrl: null,
      allProductData: [],
      currentpage: null,
      from: null,
      search: null,
      makeProduct: false,
      showProductINstore: false,
      deleteModal: false,
      deleteId: null,
      openTable: null,
      firstAxios: "/product/draft",
    };
    // axios
    //   .get("/product/draft")
    //   .then((res) => {
    //     if (res.data.status) {
    //       console.log(res);
    //       let data = res.data.data;
    //       this.setState({ allProductData: data });
    //       this.setState({ from: res.data.meta.from });
    //       this.setState({ currentpage: res.data.meta.current_page });
    //       this.forceUpdate();
    //     }
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  }

  componentDidMount() {
    axios
      .get("/product/draft")
      .then(async (res) => {
        if (res.data.status) {
          console.log(res);
          let data = res.data.data;
          this.state.allProductData = data;
          // this.setState({ allProductData: data });
          this.setState({ from: res.data.meta.from });
          this.setState({ currentpage: res.data.meta.current_page });
          this.forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  showProductInStoreClick = (ProductId) => {
    this.setState({ showProductINstore: true });
    this.props.dispatch(UpdateAllProductProductId(ProductId));
    this.setState({ paginateUr: null });
    this.forceUpdate();
  };
  static getDerivedStateFromProps(props, state) {
    if (props.PaginateData.data && props.PaginateData.data.length > 0) {
      state.allProductData = props.PaginateData.data;
      state.currentpage = props.PaginateData.meta.current_page;
      state.from = props.PaginateData.meta.from;
    }
  }

  searchHandelChange = (e) => {
    this.state.search = e.target.value;
    axios
      .get(`/product/draft?search=${this.state.search}`)
      .then((res) => {
        if (res.data.status) {
          const data = res.data.data;
          this.setState({ allProductData: data });
          this.setState({ from: res.data.meta.from });
          this.setState({ currentpage: res.data.meta.current_page });
          this.setState({ firstAxios: `/product/draft?search=${this.state.search}` });
        }

        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  navigateToMakeProduct = () => {
    this.setState({ makeProduct: true });
    this.forceUpdate();
  };

  deleteModalOpenClick = (id) => {
    console.log(id);
    this.setState({ deleteModal: true });
    this.setState({ deleteId: id });
    this.forceUpdate();
  };
  deleteDraftProduct = () => {
    axios
      .delete(`/product/${this.state.deleteId}`)
      .then((res) => {
        if (res.data.status) {
          this.props.dispatch(ShowAlert(true, res.data.message));
          this.setState({ deleteModal: false });
          this.setState({ deleteId: null });
          axios
            .get(
              `/product/draft?page=${
                this.state.allProductData.length == 1
                  ? this.state.currentpage - 1
                  : this.state.currentpage
              }`
            )
            .then((res) => {
              if (res.data.status) {
                console.log(res);
                this.setState({
                  firstAxios: `/product/draft?page=${
                    this.state.allProductData.length == 1
                      ? this.state.currentpage - 1
                      : this.state.currentpage
                  }`,
                });
                this.props.dispatch(PaginateData(res.data));
                const data = res.data.data;
                this.setState({ allProductData: data });
                this.setState({ from: res.data.meta.from });
                this.setState({ currentpage: res.data.meta.current_page });

                this.forceUpdate();
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  closeDeleteModal = () => {
    this.setState({ deleteModal: false });
    this.setState({ deleteId: null });
  };
  openTableClick = (index, productPrice) => {
    if (productPrice.length > 1) {
      this.setState({ openTable: index });
    }
  };
  closeTableBox = (index, productPrice) => {
    if (productPrice.length > 1) {
      if (index === this.state.openTable) {
        this.setState({ openTable: null });
      } else if (index !== this.state.openTable) {
        this.setState({ openTable: index });
      }
    }
  };

  render() {
    return (
      <div className="AllDraftProductAll">
        <Modal
          onBackdropClick={this.closeDeleteModal.bind(this)}
          className="draft-product-modal"
          open={this.state.deleteModal}
        >
          <div className="draft-product-delete-box">
            <div className="draft-product-title-icon-box">
              <p>حذف محصول</p>
              <img src={DeleteRed} />
            </div>
            <p className="draft-product-delete-title">
              ایا از حذف کامل محصول اطمینان دارید ؟
            </p>
            <div className="draft-product-delete-button-box">
              <button
                onClick={this.closeDeleteModal.bind(this)}
                className="draft-product-cancel-delete-button"
              >
                <p>انصراف</p>
              </button>
              <button
                onClick={this.deleteDraftProduct.bind(this)}
                className="draft-product-delete-button"
              >
                <p>حذف</p>
              </button>
            </div>
          </div>
        </Modal>
        <div className="AllDraftProductTopBar">
          <TopBar />
        </div>
        <div className="AllDraftProductButtonAndSearchBox">
          <div className="AllDraftProductButtonAndSearchBoxButtonsBox">
            <button
              onClick={this.navigateToMakeProduct.bind(this)}
              className="AllDraftProductTopShowMakeProductButton"
            >
              <img src={AllProductAdd} />
              {this.state.makeProduct === true && <Navigate to="/Product" />}
              <p className="AllDraftProductTopShowMakeProductButtonTittle">
                ایجاد محصول
              </p>
            </button>
          </div>
          <div className="AllDraftProductButtonAndSearchBoxSearchBox">
            <InputComponent
              width="337px"
              height="44px"
              type="text"
              inputType="inputWithIcon"
              iconSide="right"
              iconSrc={SearchLogo}
              backgroundColor="#f7f7f7"
              placeholder="جستجو محصول.."
              borderColor="#dcdcdc"
              onchange={this.searchHandelChange.bind(this)}
            />
          </div>
        </div>

        <div className="AllDraftProductTableBox">
          <div className="AllDraftProductTableBox2">
            <div
              style={{
                borderLeft: "1px solid #dcdcdc",
                borderRight: "1px solid #dcdcdc",
                borderRadius: "10px 10px 0 0",
                minWidth: "1400px"

              }}
              className={"all-products-header dragscroll"}
            >
              <div className="all-products-tab-bar">
                <div className="all-products-tab-bar-right">
                  <p
                    onClick={() => {
                      this.setState({ goToReleasedProducts: true });
                      this.forceUpdate();
                    }}
                    style={
                      window.location.pathname == "/AllProduct"
                        ? {
                            color: "#00457b",
                            fontWeight: "bold",
                          }
                        : {}
                    }
                  >
                    {this.state.goToReleasedProducts && (
                      <Navigate to="/AllProduct" />
                    )}
                    محصولات منتشر شده
                  </p>

                  <p
                    /*onClick={() => {
                    this.setState({ goToDraftsProducts: true })
                    this.forceUpdate();
                  }}*/ style={
                      window.location.pathname == "/AllDraftProduct"
                        ? {
                            color: "#00457b",
                            fontWeight: "bold",
                          }
                        : {}
                    }
                  >
                    {this.state.goToDraftsProducts == true && (
                      <Navigate to="/AllDraftProduct" />
                    )}
                    محصولات پیش نویس
                  </p>

                  <p
                    style={
                      window.location.pathname == "/GarbageProduct"
                        ? {
                            color: "#00457b",
                            fontWeight: "bold",
                          }
                        : {}
                    }
                    onClick={() => {
                      this.setState({ goToTrash: true });
                      this.forceUpdate();
                    }}
                  >
                    {this.state.goToTrash && <Navigate to="/GarbageProduct" />}
                    زباله دان
                  </p>
                </div>

                <div className="all-products-tab-bar-left">
                  {/* <button
                    // onClick={this.garbageClick.bind(this)}
                    className="AllProductTopShowTrashButton"
                  >
                    <img src={AllProductTrashRed} />
                    <p className="AllProductTopShowTrashButtonTittle">زباله ‌دان</p>
                  </button> */}
                </div>
              </div>

              <div className="AllDraftProductTableBoxTopBar">
                <div className="AllDraftProductTableBoxTopBarHashtagBox">
                  <p>#</p>
                </div>

                <div className="AllDraftProductTableBoxTopBarImageBox">
                  <p>تصویر</p>
                </div>
                <div className="AllDraftProductTableBoxTopBarNameBox">
                  <p>نام</p>
                </div>
                <div className="AllDraftProductTableBoxTopBarBarcodeBox">
                  <p>شناسه</p>
                </div>
                <div className="AllDraftProductTableBoxTopBarPriceBox">
                  <p>قیمت (تومان)</p>
                </div>
                <div className="AllDraftProductTableBoxTopBarDiscontBox">
                  <p>تخفیف (تومان)</p>
                </div>
                <div className="AllDraftProductTableBoxTopBarInventoryBox">
                  <p>موجودی</p>
                </div>
                <div className="AllDraftProductTableBoxTopBarCategoryBox">
                  <p>دسته بندی</p>
                </div>
                <div className="AllDraftProductTableBoxTopBarReleaseStatusBox">
                  <p>وضعیت انتشار</p>
                </div>
                <div className="AllDraftProductTableBoxTopBaroperationsBox">
                  <p>عملیات</p>
                </div>
              </div>
            </div>

            {this.state.allProductData.map((item, index) => {
              return (
                <>
                  {this.state.openTable != index ? (
                    <div
                      onClick={this.openTableClick.bind(
                        this,
                        index,
                        item.product_price
                      )}
                      key={index}
                      style={
                        this.state.allProductData.length == index + 1
                          ? { borderRadius: "0 0 10px 10px" }
                          : {}
                      }
                      className="AllDraftProductTableItems"
                    >
                      <div className="AllDraftProductTableItemsNumber">
                        <p>{index + this.state.from}</p>
                      </div>

                      <div className="AllDraftProductTableItemsImage">
                        <span>
                          <img
                            src={item.productGallery}
                            className="AllDraftProductTableItemsImageImage"
                          />
                        </span>
                      </div>
                      <div className="AllDraftProductTableItemsName">
                        <p>{item.product_name}</p>
                      </div>

                      <div className="AllDraftProductTableItemsBarcode">
                        <p>{item.barcode}</p>
                      </div>
                      <div className="AllDraftProductTableItemsPrice">
                      {this.state.allProductData != undefined &&
                        this.state.allProductData.length > 0 &&
                        item.product_price   != undefined &&
                        item.product_price.length !== 0 && 
                          (
                            <p>{item.product_price[0].price}</p>
                          )}
                      </div>
                      <div className="AllDraftProductTableItemsDiscont">
                        { 
                        item.product_price   != undefined &&
                        item.product_price.length !== 0 && (
                          <p>{item.product_price[0].discount}</p>
                        )}
                      </div>
                      <div
                        className={
                          item.product_price   != undefined &&
                          item.product_price.length !== 0
                            ? item.product_price[0].available_type === "ناموجود"
                              ? "AllDraftProductTableItemsInventory"
                              : "AllDraftProductTableItemsInventory2"
                            : "AllDraftProductTableItemsInventory"
                        }
                      >
                        {item.product_price   != undefined &&
                        item.product_price.length !== 0 && (
                          <p>{item.product_price[0].available_type}</p>
                        )}
                      </div>
                      <div className="AllDraftProductTableItemsCategory">
                        <p>{item.productCategory}</p>
                      </div>
                      <div className="AllDraftProductTableItemsReleaseStatus">
                        <div className="AllDraftProductTableItemsReleaseStatusDateBox">
                          <p>{item.releaseStatus}</p>
                        </div>
                      </div>

                      <div className="AllDraftProductTableItemsOperation">
                        <button
                          onClick={this.showProductInStoreClick.bind(
                            this,
                            item.id
                          )}
                          className="AllDraftProductTableItemsOperationShow"
                        >
                          {this.state.showProductINstore === true && (
                            <Navigate to="/AllDraftProductUpdate" />
                          )}
                          <img
                            src={EditActive}
                            // onMouseEnter={(e) =>
                            //   (e.currentTarget.src = )
                            // }
                            // onMouseLeave={(e) => (e.currentTarget.src = Eye)}
                          />
                        </button>

                        <button
                          className="AllDraftProductTableItemsOperationShow"
                          onClick={this.deleteModalOpenClick.bind(
                            this,
                            item.id
                          )}
                        >
                          <img
                            src={DeleteRed}
                            // onMouseOver={(e) => (e.currentTarget.src = DeleteRed)}
                            // onMouseOut={(e) => (e.currentTarget.src = Delete)}
                          />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div
                      onClick={this.closeTableBox.bind(
                        this,
                        index,
                        item.product_price
                      )}
                      className="all-draft-open-table-item-box"
                    >
                      <div className="all-draft-open-table-item-box-2">
                        <div className="AllDraftProductTableItems-open">
                          <div className="AllDraftProductTableItemsNumber">
                            <p>{index + this.state.from}</p>
                          </div>

                          <div className="AllDraftProductTableItemsImage">
                            <img
                              src={item.productGallery}
                              className="AllDraftProductTableItemsImageImage"
                            />
                          </div>
                          <div className="AllDraftProductTableItemsName">
                            <p>{item.product_name}</p>
                          </div>

                          <div className="AllDraftProductTableItemsBarcode">
                            <p>{item.barcode}</p>
                          </div>
                          <div className="AllDraftProductTableItemsPrice">
                            {item.product_price.length !== 0 && (
                              <p>{item.product_price[0].price}</p>
                            )}
                          </div>
                          <div className="AllDraftProductTableItemsDiscont">
                            {item.product_price.length !== 0 && (
                              <p>{item.product_price[0].discount}</p>
                            )}
                          </div>
                          <div
                            className={
                              item.available_type == "ناموجود"
                                ? "AllDraftProductTableItemsInventory"
                                : "AllDraftProductTableItemsInventory2"
                            }
                          >
                            {item.product_price.length !== 0 && (
                              <p>{item.product_price[0].available_type}</p>
                            )}
                          </div>
                          <div className="AllDraftProductTableItemsCategory">
                            <p>{item.productCategory}</p>
                          </div>
                          <div className="AllDraftProductTableItemsReleaseStatus">
                          <div className="AllDraftProductTableItemsReleaseStatusDateBox">
                          <p>{item.releaseStatus}</p>
                        </div>
                          </div>

                          <div className="AllDraftProductTableItemsOperation">
                            <button
                              onClick={this.showProductInStoreClick.bind(
                                this,
                                item.product_id
                              )}
                              className="AllDraftProductTableItemsOperationShow"
                            >
                              {this.state.showProductINstore === true && (
                                <Navigate to="/AllDraftProductUpdate" />
                              )}
                              <img src={EditActive} />
                            </button>
                            <img
                              onClick={this.deleteModalOpenClick.bind(
                                this,
                                item.product_id
                              )}
                              src={DeleteRed}
                              // onMouseOver={(e) =>
                              //   (e.currentTarget.src = DeleteRed)
                              // }
                              // onMouseOut={(e) => (e.currentTarget.src = Delete)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="all-draft-product-variable-box-table">
                        {item.product_price.map((myitem, myindex) => {
                          return (
                            <div className="all-draft-variable-product-show-box">
                              <p>{myitem.variable_key}</p>
                              {myitem.variable_key === "رنگ" ? (
                                <div
                                  style={{
                                    display: "flex",
                                    width: "25px",
                                    height: "10px",
                                    borderRadius: "7px",
                                    backgroundColor: `${myitem.variable_alternative_value}`,
                                  }}
                                ></div>
                              ) : (
                                <p>{myitem.variable_alternative_value}</p>
                              )}
                              <p>{myitem.price} تومان</p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </>
              );
            })}
            <div className="AllDraftProductPaginatedBox">
              <Pagination
                firstAxios={this.state.firstAxios}
                currentAxios="/product/draft?page="
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    PaginateData: state.PaginatedData,
  };
};

export default connect(mapStateToProps)(AllProduct);
