import React, { useEffect } from "react";
import "../../assets/styles/EditStore/EditStoreFooter.css";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import axios from "../../js/Axios/Axios";
import { ShowAlert } from "../../action/index";
import { useSelector, useDispatch } from "react-redux";
import useForceUpdate from "use-force-update";
import Modal from "@mui/material/Modal";
import cross from "../../assets/icons/cross.svg";
import telegramWhite from "../../assets/icons/telegramWhite.svg";
import linkedinWhite from "../../assets/icons/linkedinWhite.svg";
import instagramWhite from "../../assets/icons/instagramWhite.svg";
import aparatwhite from "../../assets/icons/aparatwhite.svg";
import whatsappwhite from "../../assets/icons/whatsappwhite.svg";
import whatsappimg from "../../assets/icons/whatsapp.svg";
import linkedinimg from "../../assets/icons/linkedin.svg";
import telegramimg from "../../assets/icons/telegram.png";
import instagramimg from "../../assets/icons/instagram.png";
import aparatimg from "../../assets/icons/aparat.png";
import whiteEdit from "../../assets/icons/whiteEdit.svg";
import { useState } from "react";
export default function EditStoreFooter() {
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const [modal, SetModal] = useState(false);
  const [telegram, setTelegram] = useState({
    key: "telegram",
    value: null,
    contactToUs_id: null,
  });
  const [whatsapp, setWatsapp] = useState({
    key: "whatsapp",
    value: null,
    contactToUs_id: null,
  });
  const [instagram, setInstagram] = useState({
    key: "instagram",
    value: null,
    contactToUs_id: null,
  });
  const [aparat, setAparat] = useState({
    key: "aparat",
    value: null,
    contactToUs_id: null,
  });
  const [linkdin, setLinkdin] = useState({
    key: "linkdin",
    value: null,
    contactToUs_id: null,
  });
  useEffect(() => {
    axios
      .get("/StoreContactToUs")
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          res.data.data.map((item, index) => {
            if (item.key === "telegram") {
              setTelegram((prev) => {
                prev.value = item.value;
                prev.contactToUs_id = item.id;
                forceUpdate();
                return prev;
              });
            }
            if (item.key === "whatsapp") {
              setWatsapp((prev) => {
                prev.value = item.value;
                prev.contactToUs_id = item.id;
                forceUpdate();
                return prev;
              });
            }
            if (item.key === "instagram") {
              setInstagram((prev) => {
                prev.value = item.value;
                prev.contactToUs_id = item.id;
                forceUpdate();
                return prev;
              });
            }
            if (item.key === "aparat") {
              setAparat((prev) => {
                prev.value = item.value;
                prev.contactToUs_id = item.id;
                forceUpdate();
                return prev;
              });
            }
            if (item.key === "linkdin") {
              setLinkdin((prev) => {
                prev.value = item.value;
                prev.contactToUs_id = item.id;
                forceUpdate();
                return prev;
              });
            }
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const openModal = () => {
    SetModal(true);
  };
  const closeModal = () => {
    SetModal(false);
  };
  const shareSocial = () => {
    let array = [];
    if (telegram.value != null) {
      array.push(telegram);
    }
    if (whatsapp.value != null) {
      array.push(whatsapp);
    }
    if (instagram.value != null) {
      array.push(instagram);
    }
    if (aparat.value != null) {
      array.push(aparat);
    }
    if (linkdin.value != null) {
      array.push(linkdin);
    }
    console.log(array);
    axios
      .post("/StoreContactToUs", array)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));
          SetModal(false);
          axios
            .get("/StoreContactToUs")
            .then((res) => {
              console.log(res);
              if (res.data.status) {
                res.data.data.map((item, index) => {
                  if (item.key === "telegram") {
                    setTelegram((prev) => {
                      prev.value = item.value;
                      prev.contactToUs_id = item.id;
                      forceUpdate();
                      return prev;
                    });
                  }
                  if (item.key === "whatsapp") {
                    setWatsapp((prev) => {
                      prev.value = item.value;
                      prev.contactToUs_id = item.id;
                      forceUpdate();
                      return prev;
                    });
                  }
                  if (item.key === "instagram") {
                    setInstagram((prev) => {
                      prev.value = item.value;
                      prev.contactToUs_id = item.id;
                      forceUpdate();
                      return prev;
                    });
                  }
                  if (item.key === "aparat") {
                    setAparat((prev) => {
                      prev.value = item.value;
                      prev.contactToUs_id = item.id;
                      forceUpdate();
                      return prev;
                    });
                  }
                  if (item.key === "linkdin") {
                    setLinkdin((prev) => {
                      prev.value = item.value;
                      prev.contactToUs_id = item.id;
                      forceUpdate();
                      return prev;
                    });
                  }
                });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const whatsAppClick = () => {
    if (whatsapp.value !== null) {
      window.open(`https://${whatsapp.value}`);
    }
  };
  const linkdinClick = () => {
    if (linkdin.value !== null) {
      window.open(`https://${linkdin.value}`);
    }
  };
  const telegramClick = () => {
    if (telegram.value !== null) {
      window.open(`https://${telegram.value}`);
    }
  };
  const instagramClick = () => {
    if (instagram.value !== null) {
      window.open(`https://${instagram.value}`);
    }
  };
  const aparatClick = () => {
    if (aparat.value !== null) {
      window.open(`https://${aparat.value}`);
    }
  };
  return (
    <div className="edit-store-footer">
      <Modal
        onBackdropClick={() => closeModal()}
        className="edit-store-footer-modal-all"
        open={modal}
      >
        <div className="edit-store-footer-modal-all-box">
          <div className="edit-store-footer-modal-header">
            <p>ویرایش شبکه های اجتماعی</p>
            <img src={cross} onClick={() => closeModal()} />
          </div>
          <div className="edit-store-footer-modal-input-box">
            <div className="edit-store-footer-modal-input-box-2">
              <p>تلگرام :</p>
              <InputComponent
                value={telegram.value}
                width="calc(82% - 20px)"
                height="44px"
                type="text"
                inputType="input"
                placeholder=""
                borderColor="#dcdcdc"
                onchange={(e) => {
                  setTelegram((prev) => {
                    prev.value = e.target.value;
                    forceUpdate();
                    return prev;
                  });
                }}
              />
            </div>
            <div className="edit-store-footer-modal-input-box-2">
              <p>واتساپ :</p>
              <InputComponent
                value={whatsapp.value}
                width="calc(82% - 20px)"
                height="44px"
                type="text"
                inputType="input"
                placeholder=""
                borderColor="#dcdcdc"
                onchange={(e) => {
                  setWatsapp((prev) => {
                    prev.value = e.target.value;
                    forceUpdate();
                    return prev;
                  });
                }}
              />
            </div>
            <div className="edit-store-footer-modal-input-box-2">
              <p>اینستاگرام :</p>
              <InputComponent
                value={instagram.value}
                width="calc(82% - 20px)"
                height="44px"
                type="text"
                inputType="input"
                placeholder=""
                borderColor="#dcdcdc"
                onchange={(e) => {
                  setInstagram((prev) => {
                    prev.value = e.target.value;
                    forceUpdate();
                    return prev;
                  });
                }}
              />
            </div>
            <div className="edit-store-footer-modal-input-box-2">
              <p>آپارات :</p>
              <InputComponent
                value={aparat.value}
                width="calc(82% - 20px)"
                height="44px"
                type="text"
                inputType="input"
                placeholder=""
                borderColor="#dcdcdc"
                onchange={(e) => {
                  setAparat((prev) => {
                    prev.value = e.target.value;
                    forceUpdate();
                    return prev;
                  });
                }}
              />
            </div>
            <div className="edit-store-footer-modal-input-box-2">
              <p>لینکدین :</p>
              <InputComponent
                value={linkdin.value}
                width="calc(82% - 20px)"
                height="44px"
                type="text"
                inputType="input"
                placeholder=""
                borderColor="#dcdcdc"
                onchange={(e) => {
                  setLinkdin((prev) => {
                    prev.value = e.target.value;
                    forceUpdate();
                    return prev;
                  });
                }}
              />
            </div>
          </div>
          <div className="edit-store-footer-modal-buttons-box">
            <button
              onClick={() => closeModal()}
              className="edit-store-footer-modal-buttons-cancel"
            >
              لغو
            </button>
            <button
              onClick={() => shareSocial()}
              className="edit-store-footer-modal-buttons-share"
            >
              ذخیره
            </button>
          </div>
        </div>
      </Modal>
      <div className="edit-store-footer-icon-box">
        <div className="edit-store-footer-icon-box-2">
          <img
            whatsappwhite
            onClick={() => whatsAppClick()}
            className={
              whatsapp.value === null
                ? "edit-store-footer-icon-img"
                : "edit-store-footer-icon-img-enable"
            }
            src={whatsappimg}
            onMouseEnter={
              whatsapp.value != null
                ? (e) => (e.currentTarget.src = whatsappwhite)
                : (e) => (e.currentTarget.src = whatsappimg)
            }
            onMouseLeave={(e) => (e.currentTarget.src = whatsappimg)}
          />
          <img
            onClick={() => linkdinClick()}
            className={
              linkdin.value === null
                ? "edit-store-footer-icon-img"
                : "edit-store-footer-icon-img-enable"
            }
            src={linkedinimg}
            onMouseEnter={
              linkdin.value != null
                ? (e) => (e.currentTarget.src = linkedinWhite)
                : (e) => (e.currentTarget.src = linkedinimg)
            }
            onMouseLeave={(e) => (e.currentTarget.src = linkedinimg)}
          />
          <img
            onClick={() => telegramClick()}
            className={
              telegram.value === null
                ? "edit-store-footer-icon-img"
                : "edit-store-footer-icon-img-enable"
            }
            src={telegramimg}
            onMouseEnter={
              telegram.value != null
                ? (e) => (e.currentTarget.src = telegramWhite)
                : (e) => (e.currentTarget.src = telegramimg)
            }
            onMouseLeave={(e) => (e.currentTarget.src = telegramimg)}
          />
          <img
            onClick={() => instagramClick()}
            className={
              instagram.value === null
                ? "edit-store-footer-icon-img"
                : "edit-store-footer-icon-img-enable"
            }
            src={instagramimg}
            onMouseEnter={
              instagram.value != null
                ? (e) => (e.currentTarget.src = instagramWhite)
                : (e) => (e.currentTarget.src = instagramimg)
            }
            onMouseLeave={(e) => (e.currentTarget.src = instagramimg)}
          />
          <img
            onClick={() => aparatClick()}
            className={
              aparat.value === null
                ? "edit-store-footer-icon-img"
                : "edit-store-footer-icon-img-enable"
            }
            src={aparatimg}
            onMouseEnter={
              aparat.value != null
                ? (e) => (e.currentTarget.src = aparatwhite)
                : (e) => (e.currentTarget.src = aparatimg)
            }
            onMouseLeave={(e) => (e.currentTarget.src = aparatimg)}
          />
        </div>
        <p>کليه حقوق اين سايت متعلق به شرکت (جوان‌فکران داده گستر) می‌باشد</p>
      </div>
      <button onClick={() => openModal()} className="edit-store-footer-button">
        <img style={{ marginLeft: "10px" }} src={whiteEdit} />
        ویرایش
      </button>
    </div>
  );
}
