const initLoginStaate ={

    login_info : {
        phone_number: "",
        password: "",
    }
}
const LogInReducer = (state = [] , action) =>{
    switch(action.type){
        case "LOG_IN":
            return {...initLoginStaate, login_info :  action.payload}
        default: 
        return state
    }
}
export default LogInReducer;