import React, { useState } from "react";
import "../../../assets/styles/VerifyStyle/Verify.css";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import useForceUpdate from "use-force-update";
import axios from "../../../js/Axios/Axios";
import { useSelector, useDispatch } from "react-redux";
import { AddAdminInfo } from "../../../action/index";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import { ShowAlert } from "../../../action/index";
import otp from "../../../assets/icons/OTP.svg";
import CircularProgress from "@mui/material/CircularProgress";

function PhoneNUmberRegister() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const forcUpdate = useForceUpdate();
  const [activityIndicator , setActivityIndicator] = useState(false)
  const user_info = useSelector((state) => state.AddAdminInfo);
  const [PhoneCode, setPhoneCode] = useState({
    verify_code: " ",
    phone_number: user_info.user_info,
  });

  const sendverify = () => {
    setActivityIndicator(true)
    forcUpdate()
    axios
      .post("/UserSystem/register/checkVerifyCode", PhoneCode)
      .then((res) => {
        console.log(res);
        if (res.data.status === 0) {
          dispatch(ShowAlert(true, res.data.message));
          setActivityIndicator(false)
          forcUpdate()
        }
        if (res.data.status) {
          dispatch(AddAdminInfo(PhoneCode.phone_number));
          dispatch(ShowAlert(true, res.data.message));
          setActivityIndicator(false)
          forcUpdate()
          navigate("/Register");
        }
      })
      .catch((e) => {
        console.log(e);
        setActivityIndicator(false)
        forcUpdate()
      });
  };
  const sendverifyEnter = (e) => {
    if (e.key === "Enter") {
      sendverify();
    }
  };

  return (
    <div className="VerifyRegisterAll">
      <div className="aut-img">{/* <img  src={login_img}/> */}</div>
      <div className="Verify-Register-All-2">
        <div className="Verify-Register-All-3">
          <div className="Verify-Register-All-4">
            <div className="Verify-Register-All-3-border">
              <div className="Verify-Register-All-3-circle">
                <p>1</p>
              </div>
            </div>
            <hr className="Verify-Register-All-3-line" />
            {/* <div className="Verify-Register-All-3-border-gray"> */}
            <div className="Verify-Register-All-3-circle-gray">
              <p>2</p>
              {/* </div> */}
            </div>
            <hr className="Verify-Register-All-3-line" />
            {/* <div className="Verify-Register-All-3-border-gray"> */}
            <div className="Verify-Register-All-3-circle-gray">
              <p>3</p>
            </div>
            {/* </div> */}
          </div>
          <div className="Verify-Register-All-5">
            <p>تایید هویت</p>
            <p>اطلاعات حساب</p>
            <p>اطلاعات فروشگاه</p>
          </div>
        </div>
        <div className="VerifyRegisterBox">
          <h3 className="VerifyRegisterFirstTittleBox">ایجاد فروشگاه</h3>

          <p className="VerifyRegisterSecondTittle">
            فقط یک قدم تا ایجاد فروشگاه فاصله دارید
          </p>

          <hr className="VerifyRegisterEdge" />

          <div className="VerifyRegisterInputBox">
            <div className="VerifyRegisterInputBoxName">
              <img src={otp} alt="" className="VerifyRegisterInputNameIcon" />
              <input
                onChange={(e) =>
                  setPhoneCode((prev) => {
                    return {
                      phone_number: prev.phone_number,
                      verify_code: e.target.value,
                    };
                  })
                }
                type="number"
                className="VerifyRegisterNameInput"
                placeholder="لطفا کد چهار رقمی ارسال شده را وارد کنید"
              />
            </div>
          </div>
          <div className="VerifyRegisterButtonsBox">
            <button
              onClick={() => navigate("/PhoneNumberRegister")}
              className="VerifyRegisterCancleButton"
            >
              تغییر شماره مویابل
            </button>
            <button
              onKeyDown={(e) => sendverifyEnter(e)}
              onClick={sendverify}
              className="VerifyRegisterRegisterButton"
            >
              {activityIndicator === false ? (
                "ثبت ‌نام"
              ) : (
                <CircularProgress className="aut-button-progress" />
              )}
            </button>
          </div>
          <div className="Aut-Login-Under-Box-Down-Box">
            <p className="Aut-Login-Forget-Register-Tittle">
              {" "}
              قبلا در فروشگاه ثبت نام کردید؟{" "}
            </p>
            <button
              onClick={() => navigate("/")}
              className="Aut-Login-Under-Box-Down-Box-Button"
            >
              ورود به ویتسل
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PhoneNUmberRegister;
