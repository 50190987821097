import update from "react-addons-update";
const iniState = {
  categorysId: [],
};
const SendCategoryIdReducer = (state = iniState, action) => {
  switch (action.type) {
    case "SEND_CATEGORY_ID":
      if (state.categorysId.includes(action.id)) {
        state.categorysId.splice(state.categorysId.indexOf(action.id), 1);
      } else {
        const newArray = update(state.categorysId, { $push: [action.id] });
        console.log(newArray);
        state.categorysId = newArray;
      }
      return state;
    case "RESET_TO_INIT":
      let array = [];
      return { ...iniState, categorysId: array };
    default:
      return state;
  }
};
export default SendCategoryIdReducer;
