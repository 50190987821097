const iniState = {
  title: null,
  url: null,
  meta: null,
  canonical: null,
  blog_id: null,
};
const BlogSeoInfoReducer = (state = iniState, action) => {
  switch (action.type) {
    case "SEND_BLOG_SEO_INFO":
      return {
        ...state,
        title: action.blogSeoInfo.title,
        url: action.blogSeoInfo.url,
        meta: action.blogSeoInfo.meta,
        canonical: action.blogSeoInfo.canonical,
        blog_id: action.blogSeoInfo.blog_id,
      };

    default:
      return state;
  }
};
export default BlogSeoInfoReducer;
