import React, { useEffect, useState } from "react";
import "../../assets/styles/VisitorStatisticsStyle/VisitorStatisticsBestCustomer.css";
import SimpleBar from "simplebar-react";
import axios from "../../js/Axios/Axios";
export default function VisitorStatisticsBestCustomer() {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get("/User?isNotPaginated=1")
      .then((res) => {
        console.log(res);
        setData(res.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div className="visitor-statistics-best-customer">
      <div className="visitor-statistics-best-customer-title">
        <p>فعالترین مشتریان</p>
      </div>
      <div className="visitor-statistics-best-customer-box">
        <div className="visitor-statistics-best-customer-top-bar">
          <div className="visitor-statistics-best-customer-top-bar-hashtag">
            <p>#</p>
          </div>
          <div className="visitor-statistics-best-customer-top-bar-name">
            <p>نام و نام خانوادگی</p>
          </div>
          <div className="visitor-statistics-best-customer-top-bar-order-count">
            <p>تعداد سفارشات</p>
          </div>
          <div className="visitor-statistics-best-customer-top-bar-buy-count">
            <p>مجموع مبلغ خرید</p>
          </div>
        </div>
        <SimpleBar
          forceVisible="y"
          className="visitor-statistics-best-customer-item-box"
        >
          {data.map((item, index) => {
            return (
              <div
                key={index}
                className="visitor-statistics-best-customer-item"
              >
                <div className="visitor-statistics-best-customer-item-hashtag">
                  <p>{index + 1}</p>
                </div>
                <div className="visitor-statistics-best-customer-item-name">
                  <p>{item.fullName}</p>
                </div>
                <div className="visitor-statistics-best-customer-item-order-count">
                  <p>{item.totalOrderCount}</p>
                </div>
                <div className="visitor-statistics-best-customer-item-buy-count">
                  <p>{item.totalOrdersPrice}</p>
                </div>
              </div>
            );
          })}
        </SimpleBar>
      </div>
    </div>
  );
}
