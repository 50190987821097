import React, { useEffect } from "react";
import "../../assets/styles/SmsSystemStyle/SmsSystem.css";
import SmsComponent from "../../components/SmsComponent/SmsComponent";
import TopBar from "../../components/TopBar/TopBar";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import Pagination from "../../components/Pagination/Pagination";
import axios from "../../js/Axios/Axios";
import { useSelector, useDispatch } from "react-redux";
import useForceUpdate from "use-force-update";
import SearchLogo from "../../assets/icons/SearchLogo.svg";
import check from "../../assets/icons/check.svg";
import headerCheck from "../../assets/icons/headerCheck.svg";
import uncheck from "../../assets/icons/uncheck.svg";
import { useState } from "react";
export default function SmsSystem() {
  const forceUpdate = useForceUpdate();
  const paginatedData = useSelector((state) => state.PaginatedData); // get paginated from reducer
  const [data, setData] = useState([]);
  const [from, setFrom] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [userIds, setUserIds] = useState([]);
  useEffect(() => {
    axios
      .get("/User")
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          setData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  useEffect(() => {
    console.log(paginatedData);
    if (paginatedData.length != 0) {
      setData(paginatedData.data);
      setCurrentPage(paginatedData.meta.current_page);
      setFrom(paginatedData.meta.from);
    }
  }, [paginatedData]);

  const SearchUser = (e) => {
    axios
      .get(`/User?search=${e.target.value}`)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const addUserID = (id) => {
    if (!userIds.includes(id)) {
      userIds.push(id);
      forceUpdate();
    } else {
      var index = userIds.indexOf(id);
      userIds.splice(index, 1);
      forceUpdate();
    }
    console.log(userIds);
  };
  const allCheckBox = () => {
    if (data.every((i) => userIds.includes(i.id))) {
      data.map((titem, ti) => {
        userIds.splice(userIds.indexOf(titem.id), 1);

        forceUpdate();
      });
    } else {
      data.map((item, index) => {
        if (!userIds.includes(item.id)) {
          userIds.push(item.id);
        }
      });
    }
    forceUpdate();
  };
  const update = () => {
    userIds.map((item, index) => {
      userIds.splice(index);
    });
    forceUpdate()
    console.log(userIds);
  };
  return (
    <div className="sms-all">
      <div className="sms-top-bar">
        <TopBar />
      </div>
      <div className="sms-serach-box">
        <InputComponent
          id="searchAll"
          width="337px"
          height="44px"
          type="text"
          inputType="inputWithIcon"
          iconSide="right"
          iconSrc={SearchLogo}
          backgroundColor="#f7f7f7"
          placeholder="جستجو .."
          borderColor="#dcdcdc"
          onchange={(e) => SearchUser(e)}
        />
      </div>
      <div className="sms-table-box">
        <div className="sms-component-box">
          <SmsComponent UserID={userIds} update={update} />
        </div>
        <div className="sms-table-box-2">
          <div className="sms-table-top-bar">
            <div className="sms-table-top-bar-hashtag">
              <p>#</p>
            </div>
            <div className="sms-table-top-bar-checkbox">
              <label htmlFor="alluserCheckBox">
                <input
                  style={{
                    display: "none",
                  }}
                  id="alluserCheckBox"
                  name="alluserCheckBox"
                  type="checkbox"
                  onChange={() => allCheckBox()}
                />

                <img
                  src={
                    data.every((i) => userIds.includes(i.id)) &&
                      data.length != 0
                      ? headerCheck
                      : uncheck
                  }
                />
              </label>
            </div>
            <div className="sms-table-top-bar-name">
              <p>نام مشتری</p>
            </div>
            <div className="sms-table-top-bar-phone-number">
              <p>موبایل</p>
            </div>
            <div className="sms-table-top-bar-email">
              <p>ایمیل</p>
            </div>
            <div className="sms-table-top-bar-situation">
              <p>وضعیت</p>
            </div>
            <div className="sms-table-top-bar-price">
              <p>کل مبلغ خرید</p>
            </div>
          </div>
          {data.map((item, index) => {
            return (
              <div key={index} style={
                data.length == index + 1 ? { borderRadius: "0 0 10px 10px" } : {}
              } className="sms-table-item-box">
                <div className="sms-table-item-hashtag">
                  <p>{from + index}</p>
                </div>
                <div className="sms-table-item-checkBox">
                  <label htmlFor={`product${index}`}>
                    <input
                      style={{
                        display: "none",
                      }}
                      type="checkbox"
                      id={`product${index}`}
                      name={`product${index}`}
                      onChange={() => addUserID(item.id)}
                    />
                    <img src={userIds.includes(item.id) ? check : uncheck} />
                  </label>
                </div>
                <div className="sms-table-item-name">
                  <p>{item.fullName}</p>
                </div>
                <div className="sms-table-item-phone-number">
                  <p>{item.mobile_number}</p>
                </div>
                <div className="sms-table-item-email">
                  <p>{item.email}</p>
                </div>
                <div className="sms-table-item-situation">
                  {item.status === 1 && (
                    <div className="sms-table-item-situation-2">
                      <p>فعال</p>
                    </div>
                  )}
                  {item.status === 0 && (
                    <div className="sms-table-item-situation-3">
                      <p>غیر فعال</p>
                    </div>
                  )}
                </div>
                <div className="sms-table-item-price">
                  <p>{item.totalOrdersPrice}</p>
                </div>
              </div>
            );
          })}
          <div className="sms-pagination-box">
            <Pagination firstAxios="/User" currentAxios="/User?page=" />
          </div>
        </div>
      </div>
    </div>
  );
}
