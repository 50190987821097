import React, { useState, useEffect } from "react";
import "../../assets/styles/CheckProductStyle/CheckProduct.css";
import TextInputComponent from "../TextInputComponent/TextInputComponent";
import axios from "../../js/Axios/Axios";
import { useSelector, useDispatch } from "react-redux";
import { SendProductReviewTitle } from "../../action/index";
import { SendProductReviewDescription } from "../../action/index";
import { SendProductReviewMediaId } from "../../action/index";
import { DeleteProductReviewMediaId } from "../../action/index";
import { DeleteProductReviewAllLine } from "../../action/index";
import { SendProductReviewId } from "../../action/index";
import { AddObjectProductReview } from "../../action/index";
import { isObjectEmpty } from "../../helper/Helper";
import { useLocation } from "react-router-dom";
import useForceUpdate from "use-force-update";
import DownloadIcon from "../../assets/icons/Download.svg";
import cross from "../../assets/icons/cross.svg";
import UploadImg from "../../assets/icons/UploadImg.svg";
import AddIcon from "../../assets/icons/AddIcon.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import { ShowAlert } from "../../action/index";
import Modal from "@mui/material/Modal";
import DeleteRed from "../../assets/icons/DeleteRed.svg";
import CircularProgress from "@mui/material/CircularProgress";
import ScrollDrag from "react-indiana-drag-scroll";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

function CheckProduct() {
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const location = useLocation();
  const editProductId = useSelector((state) => state.UpdateAllProductIds);
  const [modal, setModal] = useState(false);
  const ProductInfoForEdit = useSelector((state) => state.ProductInfoForEdit);
  const [imgSelected, setImgSelected] = useState(null);
  const [allImg, setAllImg] = useState([]);
  const [imgModal, setImgModal] = useState(false);
  const [imgIndex, setImgIndex] = useState(false);
  const [productReview, setProductReview] = useState([
    {
      title: null,
      description: null,
      media_id: null,
      product_id: null,
      imgLink: null,
    },
  ]);
  const [errors, setErrors] = useState([
    {
      title: false,
      description: false,
      media_id: false,
    },
  ]);
  const [imgDeleted, setImageDeleted] = useState([]);
  const header = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const tittleChange = (e, index) => {
    productReview[index].title = e.target.value;
    dispatch(SendProductReviewTitle(index, productReview[index].title));
    errors[index].title = false;
    forceUpdate();
  };

  const descriptionChange = (e, index) => {
    productReview[index].description = e.target.value;
    errors[index].description = false;

    dispatch(
      SendProductReviewDescription(index, productReview[index].description)
    );

    forceUpdate();
  };
  const addImg = (media_link, id, item) => {
    console.log("zzzz");
    productReview[imgIndex].imgLink = media_link;
    productReview[imgIndex].media_id = id;
    dispatch(
      SendProductReviewMediaId(
        imgIndex,
        productReview[imgIndex].media_id,
        productReview[imgIndex].imgLink
      )
    );
    errors[imgIndex].media_id = false;
    setImgModal(false);
  };
  // const onChangeImg = (e, index) => {
  //   setModal(true);
  //   let formData = new FormData();
  //   formData.append("media", e.target.files[0]);
  //   axios
  //     .post("/media", formData, header)
  //     .then((res) => {
  //       if (res.data.status === 0) {
  //         setModal(false);
  //         dispatch(ShowAlert(true, res.data.message));
  //         errors[index].media_id = true;
  //       }
  //       if (res.data.status) {
  //         dispatch(ShowAlert(true, res.data.message));
  //         productReview[index].imgLink = res.data.data.media_link;
  //         productReview[index].media_id = res.data.data.id;
  //         imgDeleted.push({ imgDeleted: 0 });
  //         errors[index].media_id = false;
  //         dispatch(
  //           SendProductReviewMediaId(
  //             index,
  //             productReview[index].media_id,
  //             productReview[index].imgLink
  //           )
  //         );

  //         setModal(false);
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       setModal(false);
  //     });
  // };
  // const imgaeSelectedOnchange = (e, Index) => {
  //   if (imgSelected === null) {
  //     setImgSelected(Index);
  //   } else if (imgSelected === Index) {
  //     setImgSelected(null);
  //   }
  // };
  const deleteChange = (id, index) => {
    forceUpdate();
    // if (location.pathname === "/AllProductUpdate") {
    productReview[index].imgLink = null;
    dispatch(DeleteProductReviewMediaId(index));
    forceUpdate();
    // } else {

    //         productReview[index].imgLink = null;
    //         dispatch(DeleteProductReviewMediaId(index));
    //         forceUpdate();

    // }
  };
  const deleteAllLine = (index, id) => {
    // console.log(editProductId.inistateProductid);
    if (
      productReview[index].title != null ||
      productReview[index].description != null ||
      productReview[index].imgLink != null
    ) {
      dispatch(DeleteProductReviewAllLine(index));
      if (productReview.length > 1) {
        productReview.splice(index, 1);
        errors.splice(index, 1);
        forceUpdate();
      } else {
        productReview.splice(index, 1);
        errors.splice(index, 1);
        productReview.push({
          title: null,
          description: null,
          media_id: null,
          product_id: null,
          imgLink: null,
        });
        errors.push({
          title: false,
          description: false,
          media_id: false,
        });
        let title = document.getElementById("title0");
        let description = document.getElementById("description0");
        title.value = null;
        description.value = null;
      }
      if (
        isObjectEmpty(ProductInfoForEdit) === false &&
        location.pathname === "/AllProductUpdate"
      ) {
        axios
          .delete(
            `/productReview/${id}?editProduct_id=${editProductId.inistateProductid}`
          )
          .then((res) => {
            console.log(res);
          })
          .catch((e) => {
            console.log(e);
          });
      }
      forceUpdate();
    }
  };
  const AddClick = () => {
    if (productReview[productReview.length - 1].title === null) {
      setErrors((prev) => {
        prev[productReview.length - 1].title = true;
        forceUpdate();
        return prev;
      });
    }
    if (productReview[productReview.length - 1].description === null) {
      setErrors((prev) => {
        prev[productReview.length - 1].description = true;
        forceUpdate();
        return prev;
      });
    }
    if (productReview[productReview.length - 1].media_id === null) {
      setErrors((prev) => {
        prev[productReview.length - 1].media_id = true;
        forceUpdate();
        return prev;
      });
    }
    if (productReview.length < 5) {
      if (
        productReview[productReview.length - 1].imgLink !== null &&
        productReview[productReview.length - 1].title !== null &&
        productReview[productReview.length - 1].description !== null
      ) {
        productReview.push({
          title: null,
          description: null,
          media_id: null,
          product_id: null,
          imgLink: null,
          forceupdate: false,
        });
        errors.push({
          title: false,
          description: false,
          media_id: false,
        });
        dispatch(AddObjectProductReview());
        forceUpdate();
      } else {
        dispatch(
          ShowAlert(
            true,
            "برای افزودن بررسی محصول پر کردن فیلد عکس وعنوان و توضیحات الزامی است ."
          )
        );
      }
    } else {
      dispatch(ShowAlert(true, "بیشتر از ۵ تا بررسی محصول امکان پذیر نیست ."));
    }
  };
  useEffect(() => {
    if (
      isObjectEmpty(ProductInfoForEdit) === false &&
      (location.pathname === "/AllProductUpdate" ||
        location.pathname === "/AllDraftProductUpdate")
    ) {
      if (ProductInfoForEdit.state.productReviews.length > 0) {
        productReview.splice(0, 1);
        errors.splice(0, 1);
        ProductInfoForEdit.state.productReviews.map((item, index) => {
          productReview.push({
            title: item.title,
            description: item.description,
            media_id: item.media.id,
            product_id: null,
            imgLink: item.media.media_link,
            editProductReview_id: item.id,
          });
          errors.push({
            title: false,
            description: false,
            media_id: false,
          });

          {
            ProductInfoForEdit.state.productReviews.length - 1 != index &&
              dispatch(AddObjectProductReview());
          }
          dispatch(SendProductReviewTitle(index, item.title));
          dispatch(SendProductReviewDescription(index, item.description));
          dispatch(SendProductReviewId(index, item.id));
          dispatch(
            SendProductReviewMediaId(
              index,
              item.media.id,
              item.media.media_link
            )
          );
        });
      }
      forceUpdate();
    }
  }, [ProductInfoForEdit]);
  const openModal = (Index) => {
    setImgIndex(Index);
    forceUpdate();
    if (imgModal === false) {
      axios
        .get("/media?isPaginated=0")
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            setImgModal(!imgModal);
            setAllImg(res.data.data);
          }
          forceUpdate();
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setImgModal(!imgModal);
      // if (productGalleryInfo.productGalleries.length != 0) {
      //   productGalleryInfo.productGalleries.map((item, index) => {
      //     productGalleryInfo.productGalleries.splice(index);
      //   });
      // }
    }
  };
  const uploadImage = (e) => {
    setModal(true);
    let formData = new FormData();
    formData.append("media", e.target.files[0]);
    axios
      .post("/media", formData, header)
      .then((res) => {
        console.log(res);
        if (res.data.status === 0) {
          setModal(false);
          dispatch(ShowAlert(true, res.data.message));
        }
        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));
          setModal(false);
          axios
            .get("/media?isPaginated=0")
            .then((res) => {
              if (res.data.status) {
                console.log(res);
                setAllImg(res.data.data);
              }
              forceUpdate();
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
        setModal(false);
      });
  };

  return (
    <div
      style={location.pathname == "/Product" ? { marginBottom: "40px" } : {}}
      className="BaresiMahsoolBox2"
    >
      <Modal className="CheckProductUploadModal" open={modal}>
        <div className="CheckProductUploadModalBox">
          <CircularProgress />
          <p className="CheckProductUploadModalTittle">...در حال اپلود </p>
        </div>
      </Modal>
      <Modal
        onBackdropClick={() => openModal()}
        className="img-modal"
        open={imgModal}
      >
        <div className="img-modal-box">
          <div className="img-modal-top-bar-box">
            <p>انتخاب تصویر</p>
            <img src={cross} onClick={() => openModal()} />
          </div>
          <div className="img-modal-img-box">
            <div className="img-modal-img-box-top-bar">
              <p>گالری</p>
              <p>حداکثر 1 تصویر</p>
            </div>
            <SimpleBar
              forceVisible="x"
              style={{
                width: "100%",
                height: "450px",
                marginTop: "10px",
              }}
            >
              <div className="img-modal-img-box-2">
                <label
                  htmlFor="upload_img"
                  className="img-modal-img-box-upload-box"
                >
                  <div className="img-modal-img-box-upload-box-button">
                    آپلود عکس
                  </div>
                  <img src={UploadImg} />
                  <input
                    style={{ display: "none" }}
                    type="file"
                    id="upload_img"
                    onChange={(e) => uploadImage(e)}
                  />
                </label>
                {allImg.map((item, index) => {
                  return (
                    <div
                      onClick={() => addImg(item.media_link, item.id, item)}
                      key={index}
                      className="img-modal-img-box-img-show"
                    >
                      <img src={item.media_link} />
                    </div>
                  );
                })}
              </div>
            </SimpleBar>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "auto",
              padding: "20px 0px 0px 0px",
            }}
          ></div>
          {/* <div className="img-modal-img-box-3">
            <div>
              <button
                onClick={() => openModal()}
                className="img-modal-img-box-3-cancel-Button"
              >
                لغو
              </button>
            </div>
            <div>
              <p>{productGalleryInfo.productGalleries.length}</p>
              <p>از 20 تصویر انتخاب شده</p>
              <button
                onClick={() => finalAdd()}
                className="img-modal-img-box-3-send-Button"
              >
                افزودن
              </button>
            </div>
          </div> */}
        </div>
      </Modal>

      <div className="MahsoolTittleBox">
        <p className="MahsoolTittle">بررسی محصول</p>
      </div>
      {productReview.map((Item, Index) => {
        return (
          <div key={Index} className="UploadANdNAghdBox">
            <div className="BarresiComponentAll">
              <div className="BarresiComponentUploadBox">
                <TextInputComponent
                  id={`title${Index}`}
                  value={productReview[Index].title}
                  width="calc(100% - 40px)"
                  height="44px"
                  borderColor={
                    errors[Index].title === true ? "#F23B3B" : "#dcdcdc"
                  }
                  inputType="input"
                  type="text"
                  placeholder="مانند نقد و بررسی"
                  onchange={(e) => tittleChange(e, Index)}
                />
                <button
                  onClick={() => openModal(Index)}
                  className="BarresiComponentUploadButton"
                >
                  {/* <input
                    onChange={(e) => onChangeImg(e, Index)}
                    type="file"
                    className="BarresiComponentUploadInputUploader"
                  /> */}
                  <img
                    className="BarresiComponentUploadInputUploaderImg"
                    src={DownloadIcon}
                  />
                  <p className="BarresiComponentUploadButtonTittle">
                    آپلود عکس
                  </p>
                </button>

                <div className="BarresiComponentImageUploadBorderBox">
                  <div
                    // className={
                    //   imgSelected === Index
                    //     ? "BarresiComponentImageUploadBorderr"
                    //     : "BarresiComponentImageUploadBorder"
                    // }
                    className={
                      errors[Index].media_id === true
                        ? "BarresiComponentImageUploadBorderError"
                        : "BarresiComponentImageUploadBorder"
                    }
                  >
                    {/* <label
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "160px",
                        height: "185px",
                        alignItems: "center",
                        zIndex: 1,
                      }}
                      htmlFor={`imgPrv${Index}`}
                    >
                      <input
                        className="BarresiComponentUploadImageInput"
                        id={`imgPrv${Index}`}
                        name="productImage"
                        value={Index}
                        disabled={
                          productReview[Index].imgLink != null ? false : true
                        }
                        checked={imgSelected === `imgPrv${Index}`}
                        onChange={(e) => imgaeSelectedOnchange(e, Index)}
                        type="checkbox"
                      /> */}
                    {productReview[Index].imgLink != null ? (
                      <img
                        alt="NoImage"
                        src={Item.imgLink}
                        className="BarresiComponentUploadImage"
                      ></img>
                    ) : null}
                    {/* </label> */}
                  </div>

                  <div className="BarresiComponentUploadDeleteBox">
                    <button
                      // disabled={imgSelected === Index ? false : true}
                      className="BarresiComponentUploadDeleteBtn"
                      onClick={() => deleteChange(Item.media_id, Index)}
                    >
                      <DeleteIcon className="BarresiComponentUploadDeleteIconred" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="BarresiComponentTextAreaBox">
                <div className="BarresiComponentTextAreaDeleteBox">
                  <button
                    onClick={() =>
                      deleteAllLine(Index, Item.editProductReview_id)
                    }
                    className="BarresiComponentTextAreaDeleteBoxButton"
                  >
                    <img src={DeleteRed} />
                  </button>
                </div>
                <TextInputComponent
                  id={`description${Index}`}
                  value={productReview[Index].description}
                  width="calc(100% - 20px)"
                  height="270px"
                  type="text"
                  inputType="textarea"
                  placeholder="مانند: متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه "
                  borderColor={
                    errors[Index].description === true ? "#F23B3B" : "#dcdcdc"
                  }
                  onchange={(e) => descriptionChange(e, Index)}
                />
              </div>
            </div>
          </div>
        );
      })}

      <div className="BaresiMahsoolAddButtonBox">
        <button
          onClick={() => AddClick(productReview)}
          className="BaresiMahsoolAddButton"
        >
          <img src={AddIcon} />
        </button>
      </div>
    </div>
  );
}
export default CheckProduct;
