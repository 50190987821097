import React, { useEffect, useState } from "react";
import "../../../assets/styles/Blog/CreateBlogStyle/BlogAddTag.css";
import DropDownComponent from "../../DropDown/DropDown";
import useForceUpdate from "use-force-update";
import axios from "../../../js/Axios/Axios";
import CrossDelete from "../../../assets/icons/CrossDelete.svg";
import { ShowAlert } from "../../../action/index";
import { SendBlogTag } from "../../../action/index";
import { DeleteBlogTag } from "../../../action/index";
import { useSelector, useDispatch } from "react-redux";
import { isObjectEmpty } from "../../../helper/Helper";
import { useLocation } from "react-router-dom";

export default function BlogAddTag() {
  const blogEdit = useSelector((state) => state.BlogEdit);
  const location = useLocation();
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const [allBlogTag, setAllBlogTag] = useState([]);
  const [tagIdSelected, setTagIdSelected] = useState(null);
  const [tagShow, setTagShow] = useState([]);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState();
  const [tagInfo, setTagInfo] = useState({
    title: "",
  });
  useEffect(() => {
    axios
      .get("/blogTag?isPaginated=0")
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setAllBlogTag(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handelSearchTag = (e) => {
    setSearch(e.target.value);
    setTagInfo(() => {
      return {
        title: e.target.value,
      };
    });
  };
  const AddTagToPRoduct = (title, id) => {
    setTagInfo(() => {
      return {
        title: title,
      };
    });
    setTagIdSelected(id);
    setShow(false);
    setTimeout(() => {
      setShow(true);
    }, 100);
  };
  const AddTag = () => {
    if (tagInfo.title != "") {
      if (!allBlogTag.some((tag) => tag.title === tagInfo.title)) {
        axios
          .post("/blogTag", tagInfo)
          .then((res) => {
            if (res.data.status) {
              tagShow.push(res.data.data.title);
              dispatch(SendBlogTag(res.data.data.id));
              setShow(false);
              setTimeout(() => {
                setShow(true);
              }, 100);
              setSearch("");
              setTagInfo(() => {
                return {
                  title: "",
                };
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        tagShow.push(tagInfo.title);
        dispatch(SendBlogTag(tagIdSelected));
        setShow(false);
        setTimeout(() => {
          setShow(true);
        }, 100);
        setTagInfo(() => {
          return {
            title: "",
          };
        });
        setSearch("");
        forceUpdate();
      }
    }
  };
  const DeleteTagId = (index) => {
    tagShow.splice(index, 1);
    dispatch(DeleteBlogTag(index));
    forceUpdate();
  };
  const DelteTagFromServer = (id) => {
    console.log(id);
    axios
      .delete(`/blogTag/${id}`)
      .then((res) => {
        if (res.data.status === 0) {
          dispatch(ShowAlert(true, res.data.message));
        }
        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));
          setShow(false);
          setTimeout(() => {
            setShow(true);
          }, 100);
          console.log(res);
          axios
            .get("/blogTag?isPaginated=0")
            .then((res) => {
              if (res.data.status) {
                console.log(res);
                setAllBlogTag(res.data.data);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    if (!isObjectEmpty(blogEdit)) {
      if (location.pathname === "/EditBlog") {
        blogEdit.blogTags.map((item) => {
          tagShow.push(item.title);
          dispatch(SendBlogTag(item.id));
        });
      }
    }
  }, [blogEdit]);
  const addTagEnter = () => {
    if (tagInfo.title != "") {
      if (!allBlogTag.some((tag) => tag.title === tagInfo.title)) {
        axios
          .post("/blogTag", tagInfo)
          .then((res) => {
            if (res.data.status) {
              tagShow.push(res.data.data.title);
              dispatch(SendBlogTag(res.data.data.id));
              setShow(false);
              setTimeout(() => {
                setShow(true);
              }, 100);
              setSearch("");
              setTagInfo(() => {
                return {
                  title: "",
                };
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        tagShow.push(tagInfo.title);
        dispatch(SendBlogTag(tagIdSelected));
        setShow(false);
        setTimeout(() => {
          setShow(true);
        }, 100);
        setTagInfo(() => {
          return {
            title: "",
          };
        });
        setSearch("");
        forceUpdate();
      }
    }
  };

  return (
    <div className="blog-add-tag-all">
      <div className="blog-add-tag-title">
        <p>افزودن برچسب جدید</p>
      </div>
      <div className="blog-add-tag-button-dropdown-box">
        <DropDownComponent
          value={tagInfo.title}
          width="calc(75% - 15px)"
          height="44px"
          dropDownHeight="300px"
          zindex="1"
          show={show}
          search={search}
          enterClick={() => addTagEnter()}
          onchange={(e) => handelSearchTag(e)}
          itemClick={(title, id) => AddTagToPRoduct(title, id)}
          // iconClick={(id) => DelteTag      FromServer(id)}
          axiosurl={"/blogTag?isPaginated=0"}
          axiosname="Blogtagdata"
          placeholder="برچسب جدید را انتخاب یا وارد کنید."
        />
        <button onClick={() => AddTag()} className="blog-add-tag-button">
          <p>افزودن برچسب</p>
        </button>
      </div>
      <div className="blog-add-tag-show-box">
        {tagShow.map((item, index) => {
          return (
            <div key={index} className="blog-add-tag-item-box">
              <img
                onClick={() => DeleteTagId(index)}
                style={{ width: "20px", height: "20px", marginLeft: "10px" }}
                src={CrossDelete}
              />
              <p>{item}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
