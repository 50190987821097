import React, { useEffect, useState } from "react";
import "../../assets/styles/EditStore/EditStoreSlider.css";
import { ShowAlert } from "../../action/index";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import sliderImg1 from "../../assets/images/sliderImg1.png";
import sliderImg2 from "../../assets/images/sliderImg2.png";
import Uploadinmedia from "../../assets/icons/Uploadinmedia.svg";
import toLeft from "../../assets/icons/toLeft.svg";
import CircularProgress from "@mui/material/CircularProgress";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import toRight from "../../assets/icons/toRight.svg";
import sliderInactive from "../../assets/icons/sliderInactive.svg";
import sliderActive from "../../assets/icons/sliderActive.svg";
import menu from "../../assets/icons/menu.svg";
import whiteEdit from "../../assets/icons/whiteEdit.svg";
import showMoreIcon from "../../assets/icons/showMore.png";
import cross from "../../assets/icons/cross.svg";
import DownloadIcon from "../../assets/icons/Download.svg";
import Delete from "../../assets/icons/Delete.svg";
import DeleteRed from "../../assets/icons/DeleteRed.svg";
import UploadImg from "../../assets/icons/UploadImg.svg";
import leftArrowBanner from "../../assets/icons/leftArrowBanner.svg";
import PictureIcon from "../../assets/images/PictureIcon.svg";
import { Navigation, Pagination, Autoplay } from "swiper";
import useForceUpdate from "use-force-update";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import Modal from "@mui/material/Modal";
import axios from "../../js/Axios/Axios";
import { useSelector, useDispatch } from "react-redux";
import ScrollDrag from "react-indiana-drag-scroll";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { convertObjectToArrOnlyValue } from "../../helper/Helper";
export default function EditStoreSlider() {
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(false);
  const [bannerShow, setBannerShow] = useState(null);
  const [bannerInfo, setBannerInfo] = useState({
    media_id: null,
    storeBanner_id: null,
    banner_type: 2,
    banner_title: null,
    banner_link: null,
    banner_description: null,
    alt: null,
  });
  const [storeID, setStoreID] = useState(null);
  const [bannerModal, setBannerModal] = useState(false);
  const [slidrUploadModal, setSlidrUploadModal] = useState(false);
  const [activeImg, setActiveImg] = useState(null);
  const [aboutUsModal, setAboutUsModal] = useState(false);
  const [sliderModal, setSliderModal] = useState(false);
  const [uploadBannerModal, setUploadBannerModal] = useState(false);
  const [swiper, setSwiper] = useState(null);
  const [witchIndex, setWitchIndex] = useState(0);
  const [showMore, setShowMore] = useState(false);
  const [allImg, setAllImg] = useState([]);
  const [info, setInfo] = useState({
    title: null,
    descriotion: null,
    slider: [],
    banner: null,
    bannerTitle: null,
    banneLink: null,
  });
  const [abouUsinfo, setAboutUsInfo] = useState({
    store_name: null,
    aboutUsShortDes: null,
  });
  const [showImg, setShowImg] = useState([]);
  const [sliderInfo, setSliderInfo] = useState([]);

  useEffect(() => {
    axios
      .get("/Store/showInfo")
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setStoreID(res.data.data.id);
          setInfo((prev) => {
            prev.descriotion = res.data.data.about_us_short_des;
            prev.title = res.data.data.store_name;

            forceUpdate();
            return prev;
          });
          setAboutUsInfo((prev) => {
            prev.aboutUsShortDes = res.data.data.about_us_short_des;
            prev.store_name = res.data.data.store_name;
            return prev;
          });
          res.data.data.store_sliders.map((item, index) => {
            sliderInfo.push(item.id);
            showImg.push(item.media_link);
            info.slider.push({ img: item.media_link });
            forceUpdate();
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
    axios
      .get("/StoreBanner")
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          res.data.data.map((item, index) => {
            if (item.banner_type === 2) {
              setBannerInfo((prev) => {
                prev.media_id = item.media_id;
                prev.alt = item.alt;
                prev.banner_description = item.banner_description;
                prev.banner_link = item.banner_link;
                prev.banner_title = item.banner_title;
                prev.storeBanner_id = item.id;
                forceUpdate()
                return prev;
              });
              setBannerShow(item.media_link);
              setInfo((prev) => {
                prev.banner = item.media_link;
                prev.banneLink = item.banner_link;
                prev.bannerTitle = item.banner_title;
                forceUpdate()
                return prev;
              });
            }
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const swiperOnChange = (e) => {
    setWitchIndex(e.activeIndex);
    forceUpdate();
  };
  const PrevClick = () => {
    if (witchIndex !== 0) {
      setWitchIndex(witchIndex - 1);
      swiper.slideTo(witchIndex - 1);
      forceUpdate();
    }
  };
  const NextClick = () => {
    if (witchIndex !== info.slider.length - 1) {
      setWitchIndex(witchIndex + 1);
      swiper.slideTo(witchIndex + 1);
      forceUpdate();
    }
  };
  const sliderNavigation = (index) => {
    setWitchIndex(index);
    swiper.slideTo(index);
    forceUpdate();
  };
  const showMoreClick = () => {
    setShowMore(!showMore);
  };
  const OpenAboutUsModal = () => {
    setAboutUsModal(true);
  };
  const closeAboutUsModal = () => {
    setAboutUsModal(false);
  };
  const saveAbouUs = () => {
    axios
      .put(`/Store/${storeID}`, abouUsinfo)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          dispatch(ShowAlert(true, res.data.message));
          axios
            .get("/Store/showInfo")
            .then((res) => {
              if (res.data.status) {
                console.log(res);
                setStoreID(res.data.data.id);
                setAboutUsModal(false);
                setInfo((prev) => {
                  prev.descriotion = res.data.data.about_us_short_des;
                  prev.title = res.data.data.store_name;
                  forceUpdate();
                  return prev;
                });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const OpenSliderModal = () => {
    setSliderModal(true);
  };
  const closeSliderModal = () => {
    setSliderModal(false);
    sliderInfo.map((item, index) => {
      sliderInfo.splice(index);
    });
    showImg.map((item, index) => {
      showImg.splice(index);
    });
    axios
      .get("/Store/showInfo")
      .then((res) => {
        if (res.data.status) {
          console.log(res);

          res.data.data.store_sliders.map((item, index) => {
            sliderInfo.push(item.id);
            showImg.push(item.media_link);
            forceUpdate();
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
    forceUpdate();
  };
  const header = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const activeShowImg = (index) => {
    setActiveImg(index);
    forceUpdate();
  };
  const deleteSliderImg = (index) => {
    console.log("zzz", showImg);
    if (activeImg === index) {
      sliderInfo.splice(index, 1);
      showImg.splice(index, 1);
      forceUpdate();
    }
  };

  // ? multiple upload
  const uploadImage = (e) => {
    setProgress(true);

    let media = convertObjectToArrOnlyValue(e.target.files);

    let formData = new FormData();

    media.forEach((file, index) => {
      formData.append(`media[]`, media[index]);
    });

    axios
      .post("/media/multipleUpload", formData, header)
      .then((res) => {
        if (res.data.status === 0) {
          setProgress(false);
        }
        if (res.data.status) {
          setProgress(false);
          dispatch(ShowAlert(true, res.data.message));

          axios
            .get("/media?isPaginated=0")
            .then((res) => {
              if (res.data.status) {
                console.log(res);
                // setImgModal(!imgModal);
                setAllImg(res.data.data);
              }
              forceUpdate();
            })
            .catch((e) => {
              console.log(e);
            });
          forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
        setProgress(false);
      });
  };
  // const uploadSlider = (e) => {
  //   if (sliderInfo.length < 4) {
  //     let formData = new FormData();
  //     formData.append("media", e.target.files[0]);
  //     axios
  //       .post("/media", formData, header)
  //       .then((res) => {
  //         if (res.data.status) {
  //           console.log(res);
  //           sliderInfo.push(res.data.data.id);
  //           showImg.push(res.data.data.media_link);
  //           forceUpdate();
  //         }
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //       });
  //   }
  // };
  const shareSlider = () => {
    console.log({ storeSlider_ids: sliderInfo }, storeID);
    axios
      .put(`/Store/${storeID}`, { storeSlider_ids: sliderInfo })
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          sliderInfo.map((item, index) => {
            sliderInfo.splice(index);
          });
          showImg.map((item, index) => {
            showImg.splice(index);
          });
          setInfo((prev) => {
            prev.slider = [];
            return prev;
          });
          dispatch(ShowAlert(true, res.data.message));
          axios
            .get("/Store/showInfo")
            .then((res) => {
              if (res.data.status) {
                console.log(res);
                setStoreID(res.data.data.id);
                setSliderModal(false);
                setInfo((prev) => {
                  prev.descriotion = res.data.data.about_us_short_des;
                  prev.title = res.data.data.store_name;
                  forceUpdate();
                  return prev;
                });
                res.data.data.store_sliders.map((item, index) => {
                  sliderInfo.push(item.id);
                  showImg.push(item.media_link);

                  info.slider.push({ img: item.media_link });
                  forceUpdate();
                });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const openBannerModal = () => {
    setBannerModal(true);
  };
  // const UploadBanner = (e) => {
  //   let formData = new FormData();
  //   formData.append("media", e.target.files[0]);
  //   axios
  //     .post("/media", formData, header)
  //     .then((res) => {
  //       if (res.data.status) {
  //         console.log(res);
  //         setBannerInfo((prev) => {
  //           prev.media_id = res.data.data.id;
  //           return prev;
  //         });
  //         setBannerShow(res.data.data.media_link);
  //         forceUpdate();
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };
  const shareBanner = () => {
    console.log(bannerInfo);
    axios
      .post("/StoreBanner", bannerInfo)
      .then((res) => {
        if (res.data.status) {
          console.log("post",res);
          dispatch(ShowAlert(true, res.data.message));
          setBannerModal(false);
          axios
            .get("/StoreBanner")
            .then((res) => {
              if (res.data.status) {
                console.log("get",res);
                res.data.data.map((item, index) => {
                  if (item.banner_type === 2) {
                    setBannerInfo((prev) => {
                      prev.media_id = item.media_id;
                      prev.alt = item.alt;
                      prev.banner_description = item.banner_description;
                      prev.banner_link = item.banner_link;
                      prev.banner_title = item.banner_title;
                      prev.storeBanner_id = item.id;
                      forceUpdate()
                      return prev;
                    });
                    setBannerShow(item.media_link);
                    setInfo((prev) => {
                      prev.banner = item.media_link;
                      prev.banneLink = item.banner_link;
                      prev.bannerTitle = item.banner_title;
                      forceUpdate()
                      return prev;
                    });
                  }
                });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const closeBannerModal = () => {
    setBannerModal(false);
  };
  const DeleteBanner = () => {
    setBannerInfo((prev) => {
      prev.media_id = null;
      return prev;
    });
    setBannerShow(null);
    // setInfo((prev) => {
    //   prev.banner = null;
    //   return prev;
    // });
  };
  const BannerClick = () => {
    if (info.banneLink !== null) {
      navigate(`/${info.banneLink}`);
    }
  };
  const openSliderModal = () => {
    if (slidrUploadModal === false) {
      setSlidrUploadModal(!slidrUploadModal);
      axios
        .get("/media?isPaginated=0")
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            // setImgModal(!imgModal);
            setAllImg(res.data.data);
          }
          forceUpdate();
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setSlidrUploadModal(!slidrUploadModal);
    }
    forceUpdate();
  };
  const addSlider = (media_link, id) => {
    if (sliderInfo.length < 9) {
      if (!sliderInfo.includes(id)) {
        console.log("if");
        sliderInfo.push(id);
        showImg.push(media_link);
      } else {
        console.log("else");
        let index = sliderInfo.indexOf(id);
        let indexx = showImg.indexOf(media_link);
        sliderInfo.splice(index, 1);
        showImg.splice(indexx, 1);
      }
      forceUpdate();
    }
  };
  const openUploadBanner = () => {
    if (uploadBannerModal === false) {
      setUploadBannerModal(!uploadBannerModal);
      setUploadBannerInfo((prev) => {
        prev.id = null;
        prev.link = null;
        forceUpdate();
        return prev;
      });
      axios
        .get("/media?isPaginated=0")
        .then((res) => {
          if (res.data.status) {
            // setImgModal(!imgModal);
            setAllImg(res.data.data);
          }
          forceUpdate();
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setUploadBannerModal(!uploadBannerModal);
      setUploadBannerInfo((prev) => {
        prev.id = null;
        prev.link = null;
        forceUpdate();
        return prev;
      });
    }
    forceUpdate();
  };
  const [uploadBannerInfo, setUploadBannerInfo] = useState({
    id: null,
    link: null,
  });
  const addBanner = (media_link, id) => {
    setUploadBannerInfo((prev) => {
      prev.id = id;
      prev.link = media_link;
      forceUpdate();
      return prev;
    });
  };
  const finalAddBanner = () => {
    if (uploadBannerInfo.id !== null) {
      setBannerInfo((prev) => {
        prev.media_id = uploadBannerInfo.id;
        forceUpdate()
        return prev;
      });
      setBannerShow(uploadBannerInfo.link);
      setUploadBannerModal(!uploadBannerModal);
      forceUpdate();
    } else {
      dispatch(ShowAlert(true, "انتخاب عکس الزامی است ."));
    }
  };
  return (
    <div className="Edit-store-slider-all">
      {/* ///////////////////////////////// */}
      <Modal
        onBackdropClick={() => openUploadBanner()}
        className="img-modal"
        open={uploadBannerModal}
      >
        <div className="img-modal-box">
          <div className="img-modal-top-bar-box">
            <p>انتخاب تصویر</p>
            <img src={cross} onClick={() => openUploadBanner()} />
          </div>
          <div className="img-modal-img-box">
            <div className="img-modal-img-box-top-bar">
              <p>گالری</p>
              {/* <p>حداکثر 20 تصویر</p> */}
            </div>
            <SimpleBar
              forceVisible="x"
              style={{
                width: "100%",
                height: "450px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <div className="img-modal-img-box-2">
                <label
                  htmlFor="upload_img"
                  className="img-modal-img-box-upload-box"
                >
                  {progress === true ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <div className="img-modal-img-box-upload-box-button">
                        آپلود عکس
                      </div>
                      <img src={UploadImg} />
                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="upload_img"
                        multiple
                        onChange={(e) => uploadImage(e)}
                      />
                    </>
                  )}
                </label>
                {allImg.map((item, index) => {
                  return (
                    <div
                      onClick={() => addBanner(item.media_link, item.id, item)}
                      key={index}
                      className={
                        uploadBannerInfo.id === item.id
                          ? "img-modal-img-box-img-show-active"
                          : "img-modal-img-box-img-show"
                      }
                    >
                      <img src={item.media_link} />
                    </div>
                  );
                })}
              </div>
            </SimpleBar>
          </div>
          <div className="img-modal-img-box-3">
            <div>
              <button
                onClick={() => openUploadBanner()}
                className="img-modal-img-box-3-cancel-Button"
              >
                لغو
              </button>
            </div>
            <div>
              {/* <p>{productGalleryInfo.productGalleries.length}</p> */}
              {/* <p>از 20 تصویر انتخاب شده</p> */}
              <button
                onClick={() => finalAddBanner()}
                className="img-modal-img-box-3-send-Button"
              >
                افزودن
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* /////////////////////////////////// */}
      <Modal
        onBackdropClick={() => closeBannerModal()}
        className="Edit-store-slider-banner-modal"
        open={bannerModal}
      >
        <div className="Edit-store-slider-banner-modal-box">
          <div className="Edit-store-slider-banner-modal-box-header">
            <p>ویرایش بنر</p>
            <img
              style={{ cursor: "pointer" }}
              src={cross}
              onClick={() => closeBannerModal()}
            />
          </div>
          <div className="Edit-store-slider-banner-modal-img-box">
            <div className="Edit-store-slider-banner-modal-img-upload-box">
              {bannerShow === null ? (
                <label
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                  onClick={() => openUploadBanner()}
                  htmlFor="banner_upload"
                >
                  {/* <input
                    type="file"
                    // onChange={(e) => UploadBanner(e)}
                    id="banner_upload"
                    style={{ display: "none" }}
                  /> */}
                  <img style={{ cursor: "pointer" }} src={Uploadinmedia} />
                  <p>آپلود تصویر</p>
                </label>
              ) : (
                <img
                  style={{ width: "99%", height: "99%", borderRadius: "10px" }}
                  src={bannerShow}
                />
              )}
            </div>
            <img
              style={{ cursor: "pointer", width: "20px", height: "20px" }}
              onClick={() => DeleteBanner()}
              src={bannerInfo.media_id === null ? Delete : DeleteRed}
            />
          </div>
          <div className="Edit-store-slider-banner-modal-inputs-box">
            <div className="Edit-store-slider-banner-modal-inputs-box-2">
              <InputComponent
                value={bannerInfo.banner_title}
                width="45%"
                height="44px"
                type="text"
                inputType="input"
                placeholder="عنوان تصویر"
                borderColor="#dcdcdc"
                onchange={(e) => {
                  setBannerInfo((prev) => {
                    prev.banner_title = e.target.value;
                    forceUpdate();
                    return prev;
                  });
                }}
              />
              <InputComponent
                value={bannerInfo.banner_description}
                width="45%"
                height="44px"
                type="text"
                inputType="input"
                placeholder="توضیحات تصویر"
                borderColor="#dcdcdc"
                onchange={(e) => {
                  setBannerInfo((prev) => {
                    prev.banner_description = e.target.value;
                    forceUpdate();
                    return prev;
                  });
                }}
              />
            </div>
            <div className="Edit-store-slider-banner-modal-inputs-box-2">
              <InputComponent
                value={bannerInfo.alt}
                width="45%"
                height="44px"
                type="text"
                inputType="input"
                placeholder="متن جایگزین تصویر"
                borderColor="#dcdcdc"
                onchange={(e) => {
                  setBannerInfo((prev) => {
                    prev.alt = e.target.value;
                    forceUpdate();
                    return prev;
                  });
                }}
              />
              <InputComponent
                value={bannerInfo.banner_link}
                width="45%"
                height="44px"
                type="text"
                inputType="input"
                placeholder="آدرس لینک"
                borderColor="#dcdcdc"
                onchange={(e) => {
                  setBannerInfo((prev) => {
                    prev.banner_link = e.target.value;
                    forceUpdate();
                    return prev;
                  });
                }}
              />
            </div>
          </div>
          <div className="Edit-store-slider-banner-modal-button-box">
            <button
              onClick={() => closeBannerModal()}
              className="Edit-store-slider-banner-modal-button-cancel"
            >
              لغو
            </button>
            <button
              onClick={() => shareBanner()}
              className="Edit-store-slider-banner-modal-button-save"
            >
              ذخیره
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        onBackdropClick={() => openSliderModal()}
        className="img-modal"
        open={slidrUploadModal}
      >
        <div className="img-modal-box">
          <div className="img-modal-top-bar-box">
            <p>انتخاب تصویر</p>
            <img src={cross} onClick={() => openSliderModal()} />
          </div>
          <div className="img-modal-img-box">
            <div className="img-modal-img-box-top-bar">
              <p>گالری</p>
              <p>حداکثر 9 تصویر</p>
            </div>
            <SimpleBar
              forceVisible="x"
              style={{
                width: "100%",
                height: "450px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <div className="img-modal-img-box-2">
                <label
                  htmlFor="upload_img"
                  className="img-modal-img-box-upload-box"
                >
                  {progress === true ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <div className="img-modal-img-box-upload-box-button">
                        آپلود عکس
                      </div>
                      <img src={UploadImg} />
                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="upload_img"
                        multiple
                        onChange={(e) => uploadImage(e)}
                      />
                    </>
                  )}
                </label>
                {allImg.map((item, index) => {
                  return (
                    <div
                      onClick={() => addSlider(item.media_link, item.id, index)}
                      key={index}
                      className={
                        sliderInfo.includes(item.id)
                          ? "img-modal-img-box-img-show-active"
                          : "img-modal-img-box-img-show"
                      }
                    >
                      <img src={item.media_link} />
                    </div>
                  );
                })}
              </div>
            </SimpleBar>
          </div>
          <div className="img-modal-img-box-3">
            <div>
              <button
                onClick={() => openSliderModal()}
                className="img-modal-img-box-3-cancel-Button"
              >
                لغو
              </button>
            </div>
            <div>
              <p>{sliderInfo.length}</p>
              <p>از 9 تصویر انتخاب شده</p>
              <button
                onClick={() => openSliderModal()}
                className="img-modal-img-box-3-send-Button"
              >
                افزودن
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        onBackdropClick={() => closeSliderModal()}
        className="Edit-store-slider-slider-modal"
        open={sliderModal}
      >
        <div className="Edit-store-slider-slider-modal-box">
          <div className="Edit-store-slider-slider-modal-header">
            <p>ویرایش اسلایدر</p>
            <img
              style={{ cursor: "pointer" }}
              src={cross}
              onClick={() => closeSliderModal()}
            />
          </div>
          <div className="Edit-store-slider-slider-modal-button-box">
            <label onClick={() => openSliderModal()} htmlFor="slider_upload">
              <div className="Edit-store-slider-slider-modal-button-upload">
                {/* <input
                  onChange={(e) => uploadSlider(e)}
                  type="file"
                  id="slider_upload"
                  style={{ display: "none" }}
                /> */}
                <img src={DownloadIcon} />
                آپلود تصویر
              </div>
            </label>
          </div>
          <SimpleBar
            forceVisible="y"
            style={{
              display: "flex",
              flexDirection: "row",
              width: "calc(100% - 15px)",
              height: "100%",
              padding: "0px 0px 0px 15px",
            }}
          >
            <ScrollDrag>
              <div className="Edit-store-slider-slider-modal-img-box">
                {showImg.map((item, index) => {
                  return (
                    <label
                      key={index}
                      className="Edit-store-slider-slider-modal-img-box-1"
                      htmlFor={`slider${index}`}
                    >
                      <div
                        className={
                          activeImg === index
                            ? "Edit-store-slider-slider-modal-img-active"
                            : "Edit-store-slider-slider-modal-img"
                        }
                      >
                        <img src={item} />
                        <input
                          style={{ display: "none" }}
                          onChange={() => activeShowImg(index)}
                          type="radio"
                          id={`slider${index}`}
                          name="sliderModal"
                        />
                      </div>
                      <img
                        onClick={() => deleteSliderImg(index)}
                        src={activeImg === index ? DeleteRed : Delete}
                      />
                    </label>
                  );
                })}
              </div>
            </ScrollDrag>
          </SimpleBar>
          <div className="Edit-store-slider-slider-modal-buttons-box">
            <button
              onClick={() => closeSliderModal()}
              className="Edit-store-slider-slider-modal-buttons-cancel"
            >
              لغو
            </button>
            <button
              onClick={() => shareSlider()}
              className="Edit-store-slider-slider-modal-buttons-save"
            >
              ذخیره
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        onBackdropClick={() => closeAboutUsModal()}
        className="Edit-store-slider-about-us-modal"
        open={aboutUsModal}
      >
        <div className="Edit-store-slider-about-us-modal-box">
          <div className="Edit-store-slider-about-us-modal-header">
            <p>ویرایش درباره ما</p>
            <img
              style={{ cursor: "pointer" }}
              src={cross}
              onClick={() => closeAboutUsModal()}
            />
          </div>
          <div className="Edit-store-slider-about-us-modal-inputs-box">
            <InputComponent
              value={abouUsinfo.store_name}
              width="100%"
              height="44px"
              type="text"
              inputType="input"
              placeholder="عنوان فروشگاه"
              borderColor="#dcdcdc"
              onchange={(e) => {
                setAboutUsInfo((prev) => {
                  prev.store_name = e.target.value;
                  forceUpdate();
                  return prev;
                });
              }}
            />
            <InputComponent
              value={abouUsinfo.aboutUsShortDes}
              width="100%"
              height="278px"
              type="text"
              inputType="textarea"
              placeholder="توضیحات"
              borderColor="#dcdcdc"
              onchange={(e) => {
                setAboutUsInfo((prev) => {
                  prev.aboutUsShortDes = e.target.value;
                  forceUpdate();
                  return prev;
                });
              }}
            />
          </div>
          <div className="Edit-store-slider-about-us-modal-buttons-box">
            <button
              onClick={() => closeAboutUsModal()}
              className="Edit-store-slider-about-us-modal-cancel-buttons"
            >
              لغو
            </button>
            <button
              onClick={() => saveAbouUs()}
              className="Edit-store-slider-about-us-modal-save-buttons"
            >
              ذخیره
            </button>
          </div>
        </div>
      </Modal>
      <div className="Edit-store-baner-description-box">
        {info.descriotion === null || info.descriotion === "" ? (
          <div className="Edit-store-about-us-box-disabled">
            <div className="Edit-store-about-us-box-disabled-title">
              <p>درباره {info.title}</p>
            </div>
            <div className="Edit-store-about-us-box-disabled-decription-box">
              <div className="Edit-store-about-us-box-disabled-decription-box-1"></div>
              <div className="Edit-store-about-us-box-disabled-decription-box-1"></div>
              <div className="Edit-store-about-us-box-disabled-decription-box-1"></div>
            </div>
          </div>
        ) : (
          <div
            className={
              showMore === false
                ? "Edit-store-about-us-box"
                : "Edit-store-about-us-box-2"
            }
          >
            <div className="Edit-store-about-us-title-box">
              <img src={menu} />
              <p>درباره {info.title}</p>
            </div>
            <p
              className={
                showMore === false
                  ? "Edit-store-about-us-description"
                  : "Edit-store-about-us-description-2"
              }
            >
              {info.descriotion}
            </p>
            <div className="edit-store-about-us-show-more-box">
              <button
                onClick={() => showMoreClick()}
                className="edit-store-about-us-show-more-button"
              >
                {showMore === false ? <p>بیشتر</p> : <p>بستن</p>}

                <img
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    width: "10px",
                    height: "5px",
                    marginRight: "10px",
                    transform: `${
                      showMore === false ? "rotate(180deg)" : "rotate(0deg)"
                    } `,
                    transition: "transform 0.3s",
                  }}
                  src={showMoreIcon}
                />
              </button>
            </div>
          </div>
        )}
        <div className="edit-store-about-us-edit-button-box">
          <button
            onClick={() => OpenAboutUsModal()}
            className="edit-store-about-us-edit-button"
          >
            <img style={{ marginLeft: "10px" }} src={whiteEdit} />
            ویرایش
          </button>
        </div>


        {/* /////////////////////////////////////////// */}
        {showMore === false && (
          <>
            <div
              onClick={() => BannerClick()}
              className={
                info.banner === null
                  ? "edit-store-about-us-banner-disabled"
                  : "edit-store-about-us-banner"
              }
            >
              <img src={info.banner === null ? PictureIcon : info.banner} />
              {info.bannerTitle !== null || info.bannerTitle !== "" ? (
                <>
                  <div className="edit-store-about-us-banner-2"></div>
                  <div className="edit-store-about-us-banner-3">
                    <p>{info.bannerTitle}</p>
                    {info.banneLink !== null || info.banneLink !== null ? (
                      <button>
                        خرید <img src={leftArrowBanner} />
                      </button>
                    ) : null}
                  </div>
                </>
              ) : null}
            </div>
            <div className="edit-store-about-us-banner-edit-button-box">
              <button
                onClick={() => openBannerModal()}
                className="edit-store-about-us-banner-edit-button"
              >
                <img style={{ marginLeft: "10px" }} src={whiteEdit} />
                ویرایش
              </button>
            </div>
          </>
        )}
      </div>
      {/* //////////////////////////// */}
      <div className="Edit-store-slider-box-0">
        <div
          className={
            info.slider.length === 0
              ? "Edit-store-slider-box-disabled"
              : "Edit-store-slider-box"
          }
        >
          {info.slider.length === 0 ? (
            <img src={PictureIcon} />
          ) : (
            <Swiper
              modules={[Autoplay]}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              spaceBetween={50}
              slidesPerView={1}
              draggable={true}
              onSwiper={setSwiper}
              onSlideChange={(e) => swiperOnChange(e)}
              className="edit-store-slider"
            >
              {info.slider.map((item, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    draggable={true}
                    className="edit-store-slider-image-show"
                  >
                    <img
                      className="edit-store-slider-image-show-item"
                      src={item.img}
                    />
                  </SwiperSlide>
                );
              })}
              <img
                src={toLeft}
                onClick={() => PrevClick()}
                className="edit-store-slider-prev-button"
              />
              <img
                src={toRight}
                onClick={() => NextClick()}
                className="edit-store-slider-next-button"
              />
              <div className="edit-store-slider-navigation-box">
                {info.slider.map((item, index) => {
                  return (
                    <img
                      key={index}
                      src={witchIndex === index ? sliderActive : sliderInactive}
                      onClick={() => sliderNavigation(index)}
                      className="edit-store-slider-navigation"
                    />
                  );
                })}
              </div>
            </Swiper>
          )}
        </div>
        <button
          onClick={() => OpenSliderModal()}
          className="edit-store-slider-edit-button"
        >
          <img style={{ marginLeft: "10px" }} src={whiteEdit} />
          ویرایش
        </button>
      </div>
    </div>
  );
}
