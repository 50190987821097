import React, { useEffect, useState } from "react";
import "../../assets/styles/AddTagStyle/AddTag.css";
import DropDownComponent from "../DropDown/DropDown";
import useForceUpdate from "use-force-update";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../js/Axios/Axios";
import CrossDelete from "../../assets/icons/CrossDelete.svg";
import { TagId } from "../../action";
import { DeleteTagId } from "../../action";
import { useLocation } from "react-router-dom";
import { isObjectEmpty } from "../../helper/Helper";
import { ShowAlert } from "../../action/index";

function AddTag() {
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const location = useLocation();
  const ProductInfoForEdit = useSelector((state) => state.ProductInfoForEdit);
  const [TagData, setTagData] = useState([]);
  const [tagShow, setTagShow] = useState([]);
  const [productTagId, setProductTagId] = useState([]);
  const [inputChangeTag, setInputChangeTag] = useState({
    title: "",
  });
  const [searchTag, setSearchTag] = useState("");
  const [show, setShow] = useState();
  const AddTagToPRoduct = (title, id, axiosData) => {
    setInputChangeTag(() => {
      return {
        title: title,
      };
    });
    productTagId.push(id);
    setTagData(axiosData);
    setShow(false);
    setTimeout(() => {
      setShow(true);
    }, 100);
  };
  useEffect(() => {
    if (
      (isObjectEmpty(ProductInfoForEdit) === false) &&
      (location.pathname === "/AllProductUpdate" || location.pathname === "/AllDraftProductUpdate")
    ) {
      ProductInfoForEdit.state.productTags.map((item) => {
        tagShow.push(item.title);
        productTagId.push(item.id);
        dispatch(TagId(productTagId));
      });
      forceUpdate();
    }
  }, [ProductInfoForEdit]);

  const handelSearchTag = (e) => {
    setInputChangeTag(() => {
      return {
        title: e.target.value,
      };
    });
    forceUpdate();

    setSearchTag(e.target.value);
  };

  const AddTag = () => {
    if (!TagData.some((tag) => tag.title === inputChangeTag.title)) {
      axios
        .post("/tag", inputChangeTag)
        .then((res) => {
          if (res.data.status === 0) {
            dispatch(ShowAlert(true, res.data.message));
          }
          if (res.data.status) {
            tagShow.push(inputChangeTag.title);
            setInputChangeTag(() => {
              return {
                title: "",
              };
            });
            productTagId.push(res.data.data.id);
            setSearchTag("");
            dispatch(TagId(productTagId));
            setShow(false);
            setTimeout(() => {
              setShow(true);
            }, 100);
            forceUpdate();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      tagShow.push(inputChangeTag.title);
      setInputChangeTag(() => {
        return {
          title: "",
        };
      });
      forceUpdate();
      setShow(false);
      setTimeout(() => {
        setShow(true);
      }, 100);
    }
    dispatch(TagId(productTagId));
  };
  const deleteTagClick = (myindex) => {
    tagShow.splice(myindex, 1);
    dispatch(DeleteTagId(myindex));
    forceUpdate();
  };
  const addTagEnter = () => {
    if (!TagData.some((tag) => tag.title === inputChangeTag.title)) {
      axios
        .post("/tag", inputChangeTag)
        .then((res) => {
          if (res.data.status === 0) {
            dispatch(ShowAlert(true, res.data.message));
          }
          if (res.data.status) {
            tagShow.push(inputChangeTag.title);
            setInputChangeTag(() => {
              return {
                title: "",
              };
            });
            productTagId.push(res.data.data.id);
            setSearchTag("");
            dispatch(TagId(productTagId));
            setShow(false);
            setTimeout(() => {
              setShow(true);
            }, 100);
            forceUpdate();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      tagShow.push(inputChangeTag.title);
      setInputChangeTag(() => {
        return {
          title: "",
        };
      });
      forceUpdate();
      setShow(false);
      setTimeout(() => {
        setShow(true);
      }, 100);
    }
    dispatch(TagId(productTagId));
  }

  return (
    <div className="AddTagAll">
      <div className="AddTagTittleBox">
        <p className="AddTagTittle"> افزودن برچسب جدید</p>
      </div>
      <div className="DropDownAndBUttonBox">
        <div className="AddTAgDropDownBox">
          <DropDownComponent
            value={inputChangeTag.title}
            width="calc(100% - 15px)"
            height="44px"
            dropDownHeight="300px"
            zindex="1"
            show={show}
            search={searchTag}
            onchange={(e) => handelSearchTag(e)}
            enterClick={() => addTagEnter()}
            itemClick={(title, id, axiosData) =>
              AddTagToPRoduct(title, id, axiosData)
            }
            axiosurl={"/tag?isPaginated=0"}
            axiosname="tagdata"
            placeholder="برچسب جدید را انتخاب یا وارد کنید (با کاما از یکدیگر جدا کنید)"
          />
        </div>
        <div className="AddTagButtonBox">
          <button
            disabled={inputChangeTag.title != "" ? false : true}
            onClick={() => AddTag()}
            className={
              inputChangeTag.title != ""
                ? "AddTagButton"
                : "AddTagButtonNotActive"
            }
          >
            <p className="AddTagButtonTittle">افزودن برچسب </p>
          </button>
        </div>
      </div>
      {/* <div className="AddTAgTagBox"> */}
      <div className="AddTAgTagBox2">
        {tagShow.map((myitem, myindex) => (
          <div key={myindex} className="AddTagItemBox">
            <button
              className="AddTagDleteButton"
              onClick={() => deleteTagClick(myindex)}
            >
              <img src={CrossDelete} />
            </button>
            <p className="AddTagItemTittle">{myitem}</p>
          </div>
        ))}
      </div>
    </div>
    // </div>
  );
}
export default AddTag;
