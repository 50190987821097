const iniState ={
    seo_info : {
        title : null,
        url: null,
        canonical: null,
        meta: null,
        product_id: null,
        seoId: null
    }
}
const SendSeoInfoReducer = (state = {} , action) =>{
   switch(action.type){
       case "SEND_SEO_INFO":
       return {...iniState, seo_info :  action.payload}
     
       default: 
       return state
   }
}
export default SendSeoInfoReducer;