import React from "react";
import "../../assets/styles/DeliveryStyle/Delivery.css";
import TopBar from "../../components/TopBar/TopBar";
import CityDelivery from "../../components/DeliveryComponent/CityDelivery";
import StateDelivery from "../../components/DeliveryComponent/StateDelivery";
export default function Delivery() {
  return (
    <div className="delivery-all">
      <TopBar />
      <div className="delivery-box">
        <div className="delivery-state-box">
          <CityDelivery />
        </div>
        <div className="delivery-state-box">
          <StateDelivery />
        </div>
      </div>
    </div>
  );
}
