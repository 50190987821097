import React, { useEffect } from "react";
import "../../assets/styles/TopBarStyle/TopBar.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import axios from "../../js/Axios/Axios";
import TopBarPersonIcon from "../../assets/icons/TopBarPersonIcon.svg";
import TopBarNotification from "../../assets/icons/TopBarNotification.svg";
import TopBarCamera from "../../assets/icons/TopBarCamera.svg";
import TopBarDollarSign from "../../assets/icons/TopBarDollarSign.svg";
import TopBarStoreLogo from "../../assets/icons/TopBarStoreLogo.svg";
import TopBarOrder from "../../assets/icons/TopBarOrder.svg";
import TopBarComment from "../../assets/icons/TopBarComment.svg";
import TopBarGozaresh from "../../assets/icons/TopBarGozaresh.svg";
import TopBarExit from "../../assets/icons/TopBarExit.svg";

import moment from "jalali-moment";
import { useState } from "react";
export default function TopBar() {
  const navigate = useNavigate();
  const [drop, setDrop] = useState(false);
  const [storeName, setStoreName] = useState(null);
  const d = new Date();
  let day = d.getDay();
  const DayOfWeek = () => {
    if (day === 0) {
      return "یکشنبه";
    }
    if (day === 1) {
      return "دوشنبه";
    }
    if (day === 2) {
      return "سه شنبه";
    }
    if (day === 3) {
      return "چهارشنبه";
    }
    if (day === 4) {
      return "پنجشنبه";
    }
    if (day === 5) {
      return "جمعه";
    }
    if (day === 6) {
      return "شنبه";
    }
  };
  useEffect(() => {
    axios
      .get("/Store/showInfo")
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          setStoreName(res.data.data.store_name);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const openDropDown = () => {
    setDrop(!drop);
  };
  const exit = () => {
    // Remove Token And  LogOut Admin
    localStorage.removeItem("access_token");
    // redirect to login page
    navigate("/login");
  };

  return (
    <div className="ProductTopBar">
      {drop === true && (
        <span
          onClick={() => openDropDown()}
          className="top-bar-back-drop"
        ></span>
      )}
      <div className="ProductTopBarRight">
        <p className="TobBarRightTittle"> &nbsp;به پنل </p>
        <p className="TobBarRightTittleBold">
          &nbsp;فروشگاه اینترنتی {storeName}
        </p>
        <p className="TobBarRightTittle"> &nbsp;خوش آمدید</p>
      </div>

      <div className="ProductTopBarLeft">
        <div className="TopBarDateBox">
          <p className="DayOfWeek">{DayOfWeek()}</p>
          <p className="DateNumber">
            {moment().locale("fa").format("YYYY/M/D")}
          </p>
        </div>
        <div className="ProductBarLine" />
        <div className="TopBarIconBox">
          {/* <img className="TopBarDollarIcon" src={TopBarDollarSign} /> */}
          {/* <img className="TopBarCameraIcon" src={TopBarCamera} /> */}
          {/* <img className="TopBarNotifIcon" src={TopBarNotification} /> */}
          <img
            onClick={() => openDropDown()}
            className="TopBarPersonIcon"
            src={TopBarPersonIcon}
          />
        </div>
        {drop === true && (
          <div className="top-bar-drop-box">
            <div className="top-bar-drop-box-1">
              <img src={TopBarStoreLogo} />
              <div className="top-bar-drop-box-1-title-box">
                <p>فروشگاه {storeName}</p>
                <div className="top-bar-drop-box-1-title-box-link-box">
                  <p onClick={() => navigate("/")}>داشبورد</p>
                  <p onClick={() => navigate("/EditStore")}>تنظیمات فروشگاه</p>
                </div>
              </div>
            </div>
            <div className="top-bar-drop-box-2">
              <div>
                <img src={TopBarOrder} />
              </div>
              <div className="top-bar-drop-box-2-title-box">
                <p>سفارشات</p>
                <p onClick={() => navigate("/Order")}>لیست سفارش ها</p>
                <p onClick={() => navigate("/ReturnedOrder")}>سفارشات مرجوعی</p>
              </div>
            </div>
            <div className="top-bar-drop-box-2">
              <div>
                <img src={TopBarComment} />
              </div>
              <div className="top-bar-drop-box-2-title-box">
                <p>دیدگاه ها</p>
                <p onClick={() => navigate("/ManageCustomerFeedback")}>
                  دیدگاه محصولات
                </p>
                <p onClick={() => navigate("/CommentBlog")}>دیدگاه وبلاگ</p>
              </div>
            </div>
            <div className="top-bar-drop-box-3">
              <div>
                <img src={TopBarGozaresh} />
              </div>
              <div className="top-bar-drop-box-2-title-box">
                <p>گزارشات</p>
                <p onClick={() => navigate("/SalesReport")}>گزارش فروش</p>
                <p onClick={() => navigate("/VisitorStatistics")}>
                  آمار بازدیدکنندگان
                </p>
              </div>
            </div>
            <div onClick={() => exit()} className="top-bar-drop-box-4">
              <img src={TopBarExit} />
              <p>خروج از پنل</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
