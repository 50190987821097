import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import "../../../assets/styles/ForgetPasswordStyle/ForgetPassword.css";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CircularProgress from "@mui/material/CircularProgress";
import useForceUpdate from "use-force-update";
import axios from "../../../js/Axios/Axios";
import { ShowAlert } from "../../../action/index";
import { useSelector, useDispatch } from "react-redux";
import { AddAdminInfo } from "../../../action/index";
import calling from "../../../assets/icons/Iconly-Bulk-Calling.svg";

export default function ForgetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();
  const [activityIndicator, setActivityIndicator] = useState(false);
  const [ForgetPhoneNumber, setForgetPhoneNumber] = useState({
    phone_number: "",
  });
  const sendnumber = () => {
    setActivityIndicator(true)
    forceUpdate()
    axios
      .post("/UserSystem/forgetPass/phoneNumber", ForgetPhoneNumber)
      .then((res) => {
        if (res.data.status === 0) {
          dispatch(ShowAlert(true, res.data.message));
          setActivityIndicator(false)
          forceUpdate()
        }
        console.log(res);
        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));
          dispatch(AddAdminInfo(ForgetPhoneNumber.phone_number));
          navigate("/SetNewPassword");
          setActivityIndicator(false)
          forceUpdate()
        }
      })
      .catch((e) => {
        console.log(e);
        setActivityIndicator(false)
        forceUpdate()
      });
  };
  const sendnumberEnter = (e) => {
    if (e.key  === "Enter") {
      sendnumber();
    }
  }

  return (
    <div className="AutForgetAll">
      <div className="aut-img">{/* <img  src={login_img}/> */}</div>
      <div className="AutForgetBox">
        <h3 className="AutForgetTittleBox">بازیابی رمز عبور</h3>
        <p className="AutForgetDEtails">
          برای بازیابی رمز عبور حساب کاربری خود، از فرم زیر اقدام کنید
        </p>

        <hr className="AutForgetEdge" />

        <div className="AutForgetInputsBox">
          <div className="AutForgetFirstInputBox">
            <img src={calling} alt="" className="AutforgetFirstInputIcon" />

            <input
              type="number"
              onChange={(e) =>
                setForgetPhoneNumber((prev) => {
                  return {
                    phone_number: e.target.value,
                  };
                })
              }
              className="AutForgetFirstInput"
              placeholder=" لطفا شماره موبایل خود را وارد کنید   "
            />
          </div>
        </div>
        <div className="AutForgetNextLevelButtonBox">
          <button  onKeyDown={(e)=>sendnumberEnter(e)} onClick={sendnumber} className="AutForgetNextLevelButton">
            {activityIndicator === false ? (
              "مرحله بعدی"
            ) : (
              <CircularProgress className="aut-button-progress" />
            )}
          </button>
        </div>
        <div className="AutForgetRegisterBox">
          <button
            onClick={() => navigate("/")}
            className="AutForgetRegisterBoxRegisterButton"
          >
            ورود با رمز عبور
          </button>
        </div>
      </div>
    </div>
  );
}
