import React, { useEffect, useState } from "react";
import "../../assets/styles/VisitorStatisticsStyle/SalesReportStyle/SalesReportCategory.css";
import InputComponent from "../TextInputComponent/TextInputComponent";
import { convertObjToArray } from "../../helper/Helper";
import axios from "../../js/Axios/Axios";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import SearchLogo from "../../assets/icons/SearchLogo.svg";

export default function VisitorStatisticsWords() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("/productCategory/report")
      .then((res) => {
        console.log(res);
        setData(convertObjToArray(res.data));
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const searchHandelChange = (e) => {
    axios
    .get(`/productCategory/report?search=${e.target.value}`)
    .then((res) => {
      console.log(res);
      setData(convertObjToArray(res.data));
    })
    .catch((e) => {
      console.log(e);
    });
  }

  return (
    <div className="sales-report-words-all">
      <div className="sales-report-words-all-title-box">
        <p>براساس دسته بندی</p>
      </div>
      <div className="sales-report-words-serach-box">
        <InputComponent
          width="100%"
          height="44px"
          type="text"
          inputType="inputWithIcon"
          iconSide="right"
          iconSrc={SearchLogo}
          backgroundColor="#f7f7f7"
          placeholder="جستجو دسته.."
          borderColor="#dcdcdc"
          onchange={(e) => searchHandelChange(e)}
        />
      </div>
      <div className="sales-report-words-box">
        <div className="sales-report-words-top-bar">
          <div className="sales-report-words-top-bar-hashtag">
            <p>دسته</p>
          </div>
          <div className="sales-report-words-top-bar-word">
            <p>تعداد فروش</p>
          </div>
          <div className="sales-report-words-top-bar-count">
            <p>مجموع مبلغ</p>
          </div>
        </div>
        <SimpleBar forceVisible="y" className="sales-report-words-item-box">
          {data.map((item, index) => {
            return (
              <div key={index} className="sales-report-words-item-1">
                <div className="sales-report-words-item-number">
                  <p>{item.key}</p>
                </div>
                <div className="sales-report-words-item-word">
                  <p>{item.value.final_product_count}</p>
                </div>
                <div className="sales-report-words-item-count">
                  <p>{item.value.final_order_price}</p>
                </div>
              </div>
            );
          })}
        </SimpleBar>
      </div>
    </div>
  );
}
