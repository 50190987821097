import React, { useEffect, useState } from "react";
import "../../assets/styles/EditStore/EditStoreTopBar.css";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import DropDown from "../../components/DropDown/DropDown";
import { ShowAlert } from "../../action/index";
import Rating from "@mui/material/Rating";
import useForceUpdate from "use-force-update";
import Modal from "@mui/material/Modal";
import axios from "../../js/Axios/Axios";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import cross from "../../assets/icons/cross.svg";
import UploadImg from "../../assets/icons/UploadImg.svg";
import inactivecategory from "../../assets/icons/in-active-category.svg";
import activecategory from "../../assets/icons/active-category.svg";
import inActiveHeaderHome from "../../assets/icons/inActiveHeaderHome.svg";
import activeHeaderHome from "../../assets/icons/activeHeaderHome.svg";
import inActiveHeaderPhone from "../../assets/icons/inActiveHeaderPhone.svg";
import ActiveHeaderPhone from "../../assets/icons/ActiveHeaderPhone.svg";
import ActiveHeaderQuestion from "../../assets/icons/ActiveHeaderQuestion.svg";
import inActiveHeaderQuestion from "../../assets/icons/inActiveHeaderQuestion.svg";
import ActiveHeaderCart from "../../assets/icons/ActiveHeaderCart.svg";
import inActiveHeaderCart from "../../assets/icons/inActiveHeaderCart.svg";
import headerLocation from "../../assets/icons/headerLocation.svg";
import headerWithePhone from "../../assets/icons/headerWithePhone.svg";
import editHeaderCurv from "../../assets/icons/editHeaderCurv.svg";
import whiteEdit from "../../assets/icons/whiteEdit.svg";
import brandImg from "../../assets/images/brandImg.png";
import brandIcon from "../../assets/images/brandIcon.png";
import Delete from "../../assets/icons/Delete.svg";
import DeleteRed from "../../assets/icons/DeleteRed.svg";
import Uploadinmedia from "../../assets/icons/Uploadinmedia.svg";
import darkLoc from "../../assets/icons/darkLoc.svg";
import darkPhone from "../../assets/icons/darkPhone.svg";
import emptyLogo from "../../assets/images/emptyLogo.svg";
import PictureIcon from "../../assets/images/PictureIcon.svg";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { useNavigate } from "react-router-dom";
import { convertObjectToArrOnlyValue } from "../../helper/Helper";
export default function EditStoreTopBar() {
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [storeID, setStoreID] = useState(null);
  const [logoModal, setLogoModal] = useState(false);
  const [bannerModal, setBannerModal] = useState(false);
  const [modalLogo, setModalLogo] = useState(null);
  const [modalBanner, setModalBanner] = useState(null);
  const [stateArray, setStateArray] = useState([]);
  const [stateSearch, setStateSearch] = useState("");
  const [stateShow, setStateShow] = useState(null);
  const [stateValue, setStateValue] = useState(null);
  const [cityArray, setCityArray] = useState([]);
  const [citySearch, setCitySearch] = useState("");
  const [cityValue, setCityValue] = useState(null);
  const [cityShow, setCityShow] = useState(null);
  const [allImg, setAllImg] = useState([]);
  const [progress, setProgress] = useState(false);
  const [logo, setLogo] = useState({
    id: null,
    link: null,
  });
  const [headerInfo, setHeaderInfo] = useState({
    companyName: null,
    companyDesc: null,
    companyState: null,
    companyNumber: null,
    companyRate: null,
    companyLogo: null,
    companyBackground: null,
  });
  const [editHeader, setEditHeader] = useState({
    store_name: null,
    storeShortDes: null,
    province_id: null,
    city_id: null,
    phone_store: null,
    storeIcon_id: null,
  });
  const [bannerInfo, setBannerInfo] = useState({
    media_id: null,
    storeBanner_id: null,
    banner_type: 1,
  });
  useEffect(() => {
    axios
      .get("/Store/showInfo")
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          setHeaderInfo((prev) => {
            prev.companyDesc = res.data.data.store_short_des;
            prev.companyLogo = res.data.data.storeIcon;
            prev.companyName = res.data.data.store_name;
            prev.companyNumber = res.data.data.phone_store;
            prev.companyRate = res.data.data.stars_average;
            prev.companyState = res.data.data.province;
            forceUpdate();
            return prev;
          });
          setEditHeader((prev) => {
            prev.phone_store = res.data.data.phone_store;
            prev.storeIcon_id = res.data.data.storeIcon_id;
            prev.storeShortDes = res.data.data.store_short_des;
            prev.province_id = res.data.data.province_id;
            prev.city_id = res.data.data.city_id;
            prev.store_name = res.data.data.store_name;
            return prev;
          });
          setCityValue(res.data.data.city);
          setStateValue(res.data.data.province);
          setModalLogo(res.data.data.storeIcon);
          setStoreID(res.data.data.id);
          if (res.data.data.province_id != null) {
            axios
              .get(`/Province/${res.data.data.province_id}`)
              .then((res) => {
                console.log(res);
                res.data.data.cities.map((item, index) => {
                  cityArray.push({
                    title: item.province,
                    id: item.id,
                  });
                });
                forceUpdate();
              })

              .catch((e) => {
                console.log(e);
              });
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
    axios
      .get("/StoreBanner")
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          res.data.data.map((item, index) => {
            if (item.banner_type === 1) {
              setBannerInfo((prev) => {
                prev.storeBanner_id = item.id;
                prev.media_id = item.media_id;
                return prev;
              });
              setModalBanner(item.media_link);
              setHeaderInfo((prev) => {
                prev.companyBackground = item.media_link;
                forceUpdate();
                return prev;
              });
            }
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
    axios
      .get("/Province")
      .then((res) => {
        if (res.data.status) {
          res.data.data.map((item, index) => {
            stateArray.push({
              title: item.province,
              id: item.id,
            });
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const openEditModal = () => {
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };
  const header = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  // multiple upload
  const uploadImage = (e) => {
    setProgress(true);
    let media = convertObjectToArrOnlyValue(e.target.files);
    let formData = new FormData();

    media.forEach((file, index) => {
      formData.append(`media[]`, media[index]);
    });

    axios
      .post("/media/multipleUpload", formData, header)
      .then((res) => {
        if (res.data.status === 0) {
          setProgress(false);
        }
        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));
          setProgress(false);
          axios
            .get("/media?isPaginated=0")
            .then((res) => {
              if (res.data.status) {
                // setImgModal(!imgModal);
                setAllImg(res.data.data);
              }
              forceUpdate();
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
        setProgress(false);
      });
  };

  const editHeaderClik = () => {
    axios
      .post("/StoreBanner", bannerInfo)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setModalBanner(null);
          setBannerInfo((prev) => {
            prev.media_id = null;
            return prev;
          });
          axios
            .get("/StoreBanner")
            .then((res) => {
              if (res.data.status) {
                console.log(res);
                res.data.data.map((item, index) => {
                  if (item.banner_type === 1) {
                    setBannerInfo((prev) => {
                      prev.storeBanner_id = item.id;
                      prev.media_id = item.media_id;
                      return prev;
                    });
                    setModalBanner(item.media_link);
                    setHeaderInfo((prev) => {
                      prev.companyBackground = item.media_link;
                      forceUpdate();
                      return prev;
                    });
                  }
                });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });

    axios
      .put(`/Store/${storeID}`, editHeader)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          dispatch(ShowAlert(true, res.data.message));
          setEditHeader((prev) => {
            prev.phone_store = null;
            prev.storeIcon_id = null;
            prev.storeShortDes = null;
            prev.storeState = null;
            prev.store_name = null;
            return prev;
          });
          setModalLogo(null);
          setModal(false);
          axios
            .get("/Store/showInfo")
            .then((res) => {
              console.log(res);
              if (res.data.status) {
                setHeaderInfo((prev) => {
                  prev.companyDesc = res.data.data.store_short_des;
                  prev.companyLogo = res.data.data.storeIcon;
                  prev.companyName = res.data.data.store_name;
                  prev.companyNumber = res.data.data.phone_store;
                  prev.companyRate = res.data.data.stars_average;
                  prev.companyState = res.data.data.province;
                  forceUpdate();
                  return prev;
                });
                setEditHeader((prev) => {
                  prev.phone_store = res.data.data.phone_store;
                  prev.storeIcon_id = res.data.data.storeIcon_id;
                  prev.storeShortDes = res.data.data.store_short_des;
                  prev.province_id = res.data.data.province_id;
                  prev.city_id = res.data.data.city_id;
                  prev.store_name = res.data.data.store_name;
                  return prev;
                });
                setModalLogo(res.data.data.storeIcon);
                setStoreID(res.data.data.id);
                setCityValue(res.data.data.city);
                setStateValue(res.data.data.province);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const delteLogo = () => {
    setModalLogo(null);
    setEditHeader((prev) => {
      prev.storeIcon_id = null;
      forceUpdate();
      return prev;
    });
  };
  const delteBanner = () => {
    setModalBanner(null);
    setBannerInfo((prev) => {
      prev.media_id = null;
      forceUpdate();
      return prev;
    });
  };
  const stateOnChange = (e) => {
    setStateSearch(e.target.value);
    setStateValue(e.target.value);
  };
  const addStateClick = (id, title) => {
    setStateValue(title);
    setEditHeader((prev) => {
      prev.province_id = id;
      return prev;
    });
    setCityValue("");
    setEditHeader((prev) => {
      prev.city_id = null;
      return prev;
    });
    forceUpdate();
    setStateShow(false);
    setTimeout(() => {
      setStateShow(true);
    }, 100);
    axios
      .get(`/Province/${id}`)
      .then((res) => {
        console.log(res);
        if (cityArray.length !== 0) {
          cityArray.map((item, index) => {
            cityArray.splice(index);
          });
          forceUpdate();
        }
        res.data.data.cities.map((item, index) => {
          cityArray.push({
            title: item.province,
            id: item.id,
          });
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const cityOnChange = (e) => {
    setCitySearch(e.target.value);
    setCityValue(e.target.value);
  };
  const addCityClick = (id, title) => {
    setCityValue(title);
    setEditHeader((prev) => {
      prev.city_id = id;
      return prev;
    });
    setCityShow(false);
    setTimeout(() => {
      setCityShow(true);
    }, 100);
  };

  const openLogoModal = () => {
    if (logoModal === false) {
      setLogoModal(!logoModal);
      setLogo((prev) => {
        prev.id = null;
        prev.link = null;
        forceUpdate();
        return prev;
      });
      axios
        .get("/media?isPaginated=0")
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            // setImgModal(!imgModal);
            setAllImg(res.data.data);
          }
          forceUpdate();
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setLogoModal(!logoModal);
      setLogo((prev) => {
        prev.id = null;
        prev.link = null;
        forceUpdate();
        return prev;
      });
    }
    forceUpdate();
  };
  const addLogo = (media_link, id) => {
    setLogo((prev) => {
      prev.id = id;
      prev.link = media_link;
      forceUpdate();
      return prev;
    });
  };
  const finalAddLogo = () => {
    if (logo.id != null) {
      setEditHeader((prev) => {
        prev.storeIcon_id = logo.id;
        forceUpdate();
        return prev;
      });
      setModalLogo(logo.link);
      setLogoModal(false);
    } else {
      dispatch(
        ShowAlert(
          true,
          "برای اضافع کردن ایکون فروشگاه عکس مورد نظر را انتخاب نمایید ."
        )
      );
    }
  };
  const finalAddBanner = () => {
    if (logo.id != null) {
      setBannerInfo((prev) => {
        prev.media_id = logo.id;
        forceUpdate();
        return prev;
      });
      setModalBanner(logo.link);

      setBannerModal(false);
    } else {
      dispatch(
        ShowAlert(
          true,
          "برای اضافع کردن بنر فروشگاه عکس مورد نظر را انتخاب نمایید ."
        )
      );
    }
  };
  const openBannerModal = () => {
    if (bannerModal === false) {
      setBannerModal(!bannerModal);
      setLogo((prev) => {
        prev.id = null;
        prev.link = null;
        forceUpdate();
        return prev;
      });
      axios
        .get("/media?isPaginated=0")
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            // setImgModal(!imgModal);
            setAllImg(res.data.data);
          }
          forceUpdate();
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setBannerModal(!bannerModal);
      setLogo((prev) => {
        prev.id = null;
        prev.link = null;
        forceUpdate();
        return prev;
      });
    }
    forceUpdate();
  };
  return (
    <div className="Edit-Store-header-all">
      {/* ///////////////////////////////// */}
      <Modal
        onBackdropClick={() => openBannerModal()}
        className="img-modal"
        open={bannerModal}
      >
        <div className="img-modal-box">
          <div className="img-modal-top-bar-box">
            <p>انتخاب تصویر</p>
            <img src={cross} onClick={() => openBannerModal()} />
          </div>
          <div className="img-modal-img-box">
            <div className="img-modal-img-box-top-bar">
              <p>گالری</p>
              {/* <p>حداکثر 20 تصویر</p> */}
            </div>
            <SimpleBar
              forceVisible="x"
              style={{
                width: "100%",
                height: "450px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <div className="img-modal-img-box-2">
                <label
                  htmlFor="upload_img"
                  className="img-modal-img-box-upload-box"
                >
                  {progress === true ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <div className="img-modal-img-box-upload-box-button">
                        آپلود عکس
                      </div>
                      <img src={UploadImg} />
                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="upload_img"
                        multiple
                        onChange={(e) => uploadImage(e)}
                      />
                    </>
                  )}
                </label>
                {allImg.map((item, index) => {
                  return (
                    <div
                      onClick={() => addLogo(item.media_link, item.id, item)}
                      key={index}
                      className={
                        logo.id === item.id
                          ? "img-modal-img-box-img-show-active"
                          : "img-modal-img-box-img-show"
                      }
                    >
                      <img src={item.media_link} />
                    </div>
                  );
                })}
              </div>
            </SimpleBar>
          </div>
          <div className="img-modal-img-box-3">
            <div>
              <button
                onClick={() => openBannerModal()}
                className="img-modal-img-box-3-cancel-Button"
              >
                لغو
              </button>
            </div>
            <div>
              {/* <p>{productGalleryInfo.productGalleries.length}</p> */}
              {/* <p>از 20 تصویر انتخاب شده</p> */}
              <button
                onClick={() => finalAddBanner()}
                className="img-modal-img-box-3-send-Button"
              >
                افزودن
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* /////////////////////////////////// */}

      <Modal
        onBackdropClick={() => openLogoModal()}
        className="img-modal"
        open={logoModal}
      >
        <div className="img-modal-box">
          <div className="img-modal-top-bar-box">
            <p>انتخاب تصویر</p>
            <img src={cross} onClick={() => openLogoModal()} />
          </div>
          <div className="img-modal-img-box">
            <div className="img-modal-img-box-top-bar">
              <p>گالری</p>
              {/* <p>حداکثر 20 تصویر</p> */}
            </div>
            <SimpleBar
              forceVisible="x"
              style={{
                width: "100%",
                height: "450px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <div className="img-modal-img-box-2">
                <label
                  htmlFor="upload_img"
                  className="img-modal-img-box-upload-box"
                >
                  {progress === true ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <div className="img-modal-img-box-upload-box-button">
                        آپلود عکس
                      </div>
                      <img src={UploadImg} />
                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="upload_img"
                        multiple
                        onChange={(e) => uploadImage(e)}
                      />
                    </>
                  )}
                </label>
                {allImg.map((item, index) => {
                  return (
                    <div
                      onClick={() => addLogo(item.media_link, item.id, item)}
                      key={index}
                      className={
                        logo.id === item.id
                          ? "img-modal-img-box-img-show-active"
                          : "img-modal-img-box-img-show"
                      }
                    >
                      <img src={item.media_link} />
                    </div>
                  );
                })}
              </div>
            </SimpleBar>
          </div>
          <div className="img-modal-img-box-3">
            <div>
              <button
                onClick={() => openLogoModal()}
                className="img-modal-img-box-3-cancel-Button"
              >
                لغو
              </button>
            </div>
            <div>
              {/* <p>{productGalleryInfo.productGalleries.length}</p> */}
              {/* <p>از 20 تصویر انتخاب شده</p> */}
              <button
                onClick={() => finalAddLogo()}
                className="img-modal-img-box-3-send-Button"
              >
                افزودن
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        onBackdropClick={() => closeModal()}
        className="edit-store-header-modal-all"
        open={modal}
      >
        <div className="edit-store-header-modal">
          <div className="edit-store-header-modal-header">
            <p>ویرایش هدر</p>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => closeModal()}
              src={cross}
            />
          </div>
          <div className="edit-store-header-modal-upload-box-all">
            <div className="edit-store-header-modal-upload-logo">
              <div className="edit-store-header-modal-upload-logo-1">
                {modalLogo === null ? (
                  <label onClick={() => openLogoModal()} htmlFor="logo_upload">
                    <div className="edit-store-header-modal-upload-logo-2">
                      <img src={Uploadinmedia} />
                      <p>اپلود لوگو</p>
                      {/* <input
                        onChange={(e) => UploadLogo(e)}
                        type="file"
                        id="logo_upload"
                        style={{ display: "none" }}
                      /> */}
                    </div>
                  </label>
                ) : (
                  <div className="edit-store-header-modal-upload-logo-3">
                    <img src={modalLogo} />
                  </div>
                )}
              </div>
              <img
                onClick={() => delteLogo()}
                style={{ width: "18px", height: "18px", cursor: "pointer" }}
                src={modalLogo === null ? Delete : DeleteRed}
              />
            </div>
            <div className="edit-store-header-modal-upload-background">
              <div className="edit-store-header-modal-upload-background-1">
                {modalBanner === null ? (
                  <label
                    onClick={() => openBannerModal()}
                    htmlFor="banner_upload"
                  >
                    <div className="edit-store-header-modal-upload-background-2">
                      <img src={Uploadinmedia} />
                      <p>اپلود بنر</p>
                      {/* <input
                        onChange={(e) => openBannerModal(e)}
                        type="file"
                        id="banner_upload"
                        style={{ display: "none" }}
                      /> */}
                    </div>
                  </label>
                ) : (
                  <div className="edit-store-header-modal-upload-background-3">
                    <img src={modalBanner} />
                  </div>
                )}
              </div>
              <img
                onClick={() => delteBanner()}
                style={{ width: "18px", height: "18px", cursor: "pointer" }}
                src={modalBanner === null ? Delete : DeleteRed}
              />
            </div>
          </div>
          <div>
            <div className="edit-store-header-modal-inputs-box">
              <div className="edit-store-header-modal-inputs-box-1">
                <InputComponent
                  value={editHeader.store_name}
                  width="47%"
                  height="44px"
                  type="text"
                  inputType="input"
                  placeholder="عنوان فروشگاه"
                  borderColor="#dcdcdc"
                  onchange={(e) => {
                    setEditHeader((prev) => {
                      prev.store_name = e.target.value;
                      forceUpdate();
                      return prev;
                    });
                  }}
                />
                <InputComponent
                  value={editHeader.storeShortDes}
                  width="47%"
                  height="44px"
                  type="text"
                  inputType="input"
                  placeholder="توضیحات عنوان"
                  borderColor="#dcdcdc"
                  onchange={(e) => {
                    setEditHeader((prev) => {
                      prev.storeShortDes = e.target.value;
                      forceUpdate();
                      return prev;
                    });
                  }}
                />
              </div>
              <div className="edit-store-header-modal-inputs-box-2">
                <InputComponent
                  value={editHeader.phone_store}
                  width="47%"
                  height="44px"
                  type="number"
                  inputType="input"
                  placeholder="شماره تماس فروشگاه"
                  borderColor="#dcdcdc"
                  onchange={(e) => {
                    setEditHeader((prev) => {
                      prev.phone_store = e.target.value;
                      forceUpdate();
                      return prev;
                    });
                  }}
                />
                <div className="edit-store-header-modal-inputs-box-3">
                  <DropDown
                    width="42% "
                    height="44px"
                    dropDownHeight="120px"
                    zindex="1"
                    data={stateArray}
                    onchange={(e) => stateOnChange(e)}
                    search={stateSearch}
                    show={stateShow}
                    value={stateValue}
                    itemClick={(id, title) => addStateClick(id, title)}
                    placeholder="استان"
                  />
                  <DropDown
                    width="42% "
                    height="44px"
                    dropDownHeight="120px"
                    zindex="1"
                    onchange={(e) => cityOnChange(e)}
                    data={cityArray}
                    search={citySearch}
                    show={cityShow}
                    value={cityValue}
                    itemClick={(id, title) => addCityClick(id, title)}
                    placeholder="شهر"
                  />
                </div>
              </div>
            </div>
            <div className="edit-store-header-modal-button-box">
              <button
                onClick={() => closeModal()}
                className="edit-store-header-modal-cancel-button"
              >
                لغو
              </button>
              <button
                onClick={() => editHeaderClik()}
                className="edit-store-header-modal-save-button"
              >
                ذخیره
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="edit-store-header-box">
        <div className="edit-store-header-button-all-box">
          {/* <button className="edit-store-header-button-box-1">
            <img
              className="edit-store-header-button-box-1-img-in-active"
              src={inActiveHeaderHome}
            />
            <img
              className="edit-store-header-button-box-1-img-active"
              src={activeHeaderHome}
            />
            خانه
          </button> */}
          <button
            onClick={() => navigate("/Category")}
            className="edit-store-header-button-box-2"
          >
            <img
              className="edit-store-header-button-box-1-img-in-active-2"
              src={inactivecategory}
            />
            <img
              className="edit-store-header-button-box-1-img-active-2"
              src={activecategory}
            />
            دسته‌ بندی
          </button>
          <button
            onClick={() => navigate("/ContactUs")}
            className="edit-store-header-button-box-3"
          >
            <img
              className="edit-store-header-button-box-1-img-in-active-3"
              src={inActiveHeaderPhone}
            />
            <img
              className="edit-store-header-button-box-1-img-active-3"
              src={ActiveHeaderPhone}
            />
            تماس با ما
          </button>
          <button
            onClick={() => navigate("/FrequentlyQuestion")}
            className="edit-store-header-button-box-4"
          >
            <img
              className="edit-store-header-button-box-1-img-in-active-4"
              src={inActiveHeaderQuestion}
            />
            <img
              className="edit-store-header-button-box-1-img-active-4"
              src={ActiveHeaderQuestion}
            />
            سوال بپرسید
          </button>
        </div>
        {/* <div className="edit-store-header-button-all-box-2">
          <button className="edit-store-header-button-login">
            ورود / ثبت نام
          </button>
          <button className="edit-store-header-button-store-basket">
            <img
              className="edit-store-header-button-store-basket-img-active"
              src={inActiveHeaderCart}
            />
            <img
              className="edit-store-header-button-store-basket-img-in-active"
              src={ActiveHeaderCart}
            />
          </button>
        </div> */}
      </div>
      <div className="edit-store-header-image-box">
        <div
          style={{
            backgroundImage: `${
              headerInfo.companyBackground !== null &&
              `url(${headerInfo.companyBackground}`
            } )`,
          }}
          className={
            headerInfo.companyBackground === null
              ? "edit-store-header-image-box-2"
              : "edit-store-header-image-box-2-active"
          }
        >
          <div
            className={
              headerInfo.companyName !== null ||
              headerInfo.companyDesc !== null ||
              headerInfo.companyNumber !== null ||
              headerInfo.companyState !== null ||
              headerInfo.companyLogo !== null ||
              headerInfo.companyRate !== null ||
              headerInfo.companyBackground !== null
                ? "edit-store-header-image-box-2-cover-active"
                : "edit-store-header-image-box-2-cover"
            }
          >
            <div className="edit-store-header-image-box-2-brand-img-title-rate-box">
              <div className="edit-store-header-image-box-2-brand-img-title-box">
                <img
                  className={
                    headerInfo.companyLogo === null
                      ? "edit-store-header-image-box-2-brand-img"
                      : "edit-store-header-image-box-2-brand-img-active"
                  }
                  src={
                    headerInfo.companyLogo === null
                      ? emptyLogo
                      : headerInfo.companyLogo
                  }
                />
                <div className="edit-store-header-image-box-2-brand-title-box">
                  {headerInfo.companyName === null ||
                  headerInfo.companyName === "" ? (
                    <div className="edit-store-header-image-box-2-brand-title-box-1">
                      <p>عنوان فروشگاه</p>
                    </div>
                  ) : (
                    <p className="edit-store-header-company-name">
                      {headerInfo.companyName}
                    </p>
                  )}
                  {headerInfo.companyDesc === null ||
                  headerInfo.companyDesc === "" ? (
                    <div className="edit-store-header-image-box-2-brand-title-box-2">
                      <p>توضیحات عنوان</p>
                    </div>
                  ) : (
                    <p className="edit-store-header-company-desc">
                      {headerInfo.companyDesc.length > 100
                        ? headerInfo.companyDesc.slice(0, 100) + "..."
                        : headerInfo.companyDesc}
                    </p>
                  )}
                </div>
              </div>
              {headerInfo.companyBackground === null && (
                <img style={{ marginTop: "-30px" }} src={PictureIcon} />
              )}
              <div className="edit-store-header-rating-box">
                <div className="edit-store-header-rating-box-2">
                  {headerInfo.companyRate !== null && (
                    <>
                      <div className="edit-store-header-rating-number-box">
                        <p>{headerInfo.companyRate}</p>
                      </div>
                      <Rating
                        name="read-only"
                        value={headerInfo.companyRate / 5}
                        precision={0.5}
                        readOnly
                      />
                    </>
                  )}
                </div>
                <div className="edit-store-header-info-box">
                  <div
                    className={
                      headerInfo.companyState === null ||
                      headerInfo.companyState === ""
                        ? "edit-store-header-info-location-box"
                        : "edit-store-header-info-location-box-active"
                    }
                  >
                    <img
                      src={
                        // headerInfo.companyState === null
                        // ? darkLoc
                        // :
                        headerLocation
                      }
                    />
                    {headerInfo.companyState === null ? (
                      <p>استان</p>
                    ) : (
                      <p>{headerInfo.companyState}</p>
                    )}
                  </div>
                  <div
                    className={
                      headerInfo.companyNumber === null ||
                      headerInfo.companyNumber === ""
                        ? "edit-store-header-info-telephone-box"
                        : "edit-store-header-info-telephone-box-active"
                    }
                  >
                    <img
                      src={
                        // headerInfo.companyNumber === null
                        // ? darkPhone
                        // :
                        headerWithePhone
                      }
                    />
                    {headerInfo.companyNumber === null ||
                    headerInfo.companyNumber === "" ? (
                      <p>تلفن</p>
                    ) : (
                      <p>{headerInfo.companyNumber}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          style={{ backgroundImage: `url(${brandImg})` }}
          className="edit-store-header-image-box-2"
        >
          <div className="edit-store-header-image-box-2-cover">
            <div className="edit-store-header-image-box-2-brand-img-title-rate-box">
              <div className="edit-store-header-image-box-2-brand-img-title-box">
                <img
                  className="edit-store-header-image-box-2-brand-img"
                  src={brandIcon}
                />
                <div className="edit-store-header-image-box-2-brand-title-box">
                  <p>محصولات آرایش بهداشتی اینلی</p>
                  <p className="edit-store-header-image-box-2-brand-description">
                    تولید و پخش لوازم آرایشی و بهداشتی
                  </p>
                </div>
              </div>
              <div className="edit-store-header-rating-box">
                <div className="edit-store-header-rating-box-2">
                  <div className="edit-store-header-rating-number-box">
                    <p>13.4</p>
                  </div>
                  <Rating name="read-only" value={2} readOnly />
                  <p>( نظر134 )</p>
                </div>
                <div className="edit-store-header-info-box">
                  <div className="edit-store-header-info-location-box">
                    <img src={headerLocation} />
                    <p>استان تهران</p>
                  </div>
                  <div className="edit-store-header-info-telephone-box">
                    <img src={headerWithePhone} />
                    <p>021-23569888</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <img
          className="edit-store-header-image-box-2-curv"
          src={editHeaderCurv}
        />
      </div>
      <div className="edit-store-header-edit-button-box">
        <button
          onClick={() => openEditModal()}
          className="edit-store-header-edit-button"
        >
          <img style={{ marginLeft: "10px" }} src={whiteEdit} />
          ویرایش
        </button>
      </div>
    </div>
  );
}
