import React, { useEffect, useRef, useState } from "react";
// import "../../assets/styles/Blog/ManageBlogStyle/ManageBlog.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import axios from "../../js/Axios/Axios";
import { ShowAlert, UpdateAllProductProductId } from "../../action/index";
import { SendBlogInfoForEdit } from "../../action/index";
import Modal from "@mui/material/Modal";
import useForceUpdate from "use-force-update";
import { useSelector, useDispatch } from "react-redux";
import TopBar from "../../components/TopBar/TopBar";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import DropDownComponent from "../../components/DropDown/DropDown";
import Pagination from "../../components/Pagination/Pagination";
import AllProductAdd from "../../assets/icons/AllProductAdd.svg";
import AllProductTrash from "../../assets/icons/GarbageDelte.svg";
import FilterLogo from "../../assets/icons/Filter.svg";
import SearchLogo from "../../assets/icons/SearchLogo.svg";
import AllProductCalender from "../../assets/icons/AllProductCalender.svg";
import Delete from "../../assets/icons/Delete.svg";
import DeleteRed from "../../assets/icons/DeleteRed.svg";
import Eye from "../../assets/icons/AllProductEye.svg";
import headerCheck from "../../assets/icons/headerCheck.svg";
import check from "../../assets/icons/check.svg";
import uncheck from "../../assets/icons/uncheck.svg";
import * as moment from "jalali-moment";
import Filter from "../../components/Filter/Filter";
import { Calendar } from "react-datepicker2";
import EditActive from "../../assets/icons/EditActive.svg";
import AllProductDeleteFilter from "../../assets/icons/AllProductDeleteFilter.svg";

import AllProductFilterUpArrow from "../../assets/icons/AllProductFilterUpArrow.svg";
import WhiteCheck from "../../assets/icons/WhiteCheck.svg";
import WhiteCross from "../../assets/icons/WhiteCross.svg";

// import AllProductTrash from "../../../assets/icons/GarbageDelte.svg";
import AllProductTrashRed from "../../assets/icons/GarbageDelteRed.svg";

import SimpleBar from "simplebar-react";
import ScrollDrag from "react-indiana-drag-scroll";

const MarketPlace = () => {
  const location = useLocation();
  const scrollTitleRef = useRef();
  const scrollContentRef = useRef();
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const [allBlogs, setAllBlogs] = useState([]);
  const [idSelected, setIdSelected] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [showSellers, setShowSellers] = useState(false);
  const [from, setFrom] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [firstAxios, setFirstAxios] = useState("/seller");
  const [currentAxios, setCurrentAxios] = useState("/seller?page=");
  const paginatedData = useSelector((state) => state.PaginatedData); // get paginated from reducer

  // state for open table row
  const [openTable, setOpenTable] = useState(null);

  // state for get all sellers
  const [sellers, setSellers] = useState([]);
  // state for active seller
  const [activeSeller, setActiveSeller] = useState({
    id: "",
  });
  useEffect(() => {
    axios
      .get("/seller")
      .then((res) => {
        setAllBlogs(res.data.data);
        setSellers(res.data.sellers);
        setFrom(res.data.meta.from);
        setCurrentPage(res.data.meta.current_page);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const Data = (data, route) => {
    setAllBlogs(data.data);
    setFrom(data.meta.from);
    setCurrentPage(data.meta.current_page);
    setFirstAxios(route);
    setCurrentAxios(`${route}&page=`);
  };
  useEffect(() => {
    console.log(paginatedData);
    if (paginatedData.length != 0) {
      // if (topBarChecked === true || checkedIds.length != 0) {
      //   setTopBarChecked(false);
      //   garbageData.map((i, index) => {
      //     checkedIds.splice(index);
      //   });
      // }
      setAllBlogs(paginatedData.data);
      setCurrentPage(paginatedData.meta.current_page);
      setFrom(paginatedData.meta.from);
    }
  }, [paginatedData]);

  // useeffect for SimpleBar and ScrollDrag
  useEffect(() => {
    // set scroll position title and content for together

    const handleScrollTitle = (e) => {
      scrollContentRef.current.scrollLeft = scrollTitleRef.current.scrollLeft;
    };

    scrollTitleRef.current.addEventListener("scroll", handleScrollTitle);

    const handleScrollContent = (e) => {
      scrollTitleRef.current.scrollLeft = scrollContentRef.current.scrollLeft;
    };
    scrollContentRef.current.addEventListener("scroll", handleScrollContent);
  }, []);

  const checkBoxOnChange = (id) => {
    if (!idSelected.includes(id)) {
      idSelected.push(id);
    } else {
      var indexof = idSelected.indexOf(id);
      idSelected.splice(indexof, 1);
    }
    forceUpdate();
  };
  const allCheckBoxOnChange = () => {
    if (idSelected.length === allBlogs.length) {
      allBlogs.map((item, index) => {
        idSelected.splice(index);
      });
    } else {
      allBlogs.map((item, index) => {
        if (!idSelected.includes(item.id)) {
          idSelected.push(item.id);
        }
      });
    }
    forceUpdate();
  };

  // TODO
  const deleteBlog = () => {
    if (idSelected.length != 0) {
      console.log({ blog_ids: idSelected });
      let ids = JSON.stringify(idSelected);
      axios
        .delete(`/blog/throwingGarbage?blog_ids=${ids}`)
        .then((res) => {
          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));

            axios
              .get("/blog?isPaginated=1")
              .then((res) => {
                console.log(res);
                setAllBlogs(res.data.data);
                setFrom(res.data.meta.from);
                setIdSelected([]);
                setCurrentPage(res.data.meta.current_page);
              })
              .catch((e) => {
                console.log(e);
              });
          }
          console.log(res);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      console.log("teste delere");
      dispatch(ShowAlert(true, "لطفا وبلاگ ها را انتخاب کنید ."));
    }
  };

  // TODO
  const deleteOneBlog = (id) => {
    if (idSelected.length === 0) {
      let idArray = [];
      idArray.push(id);
      let ids = JSON.stringify(idArray);
      axios
        .delete(`/blog/throwingGarbage?blog_ids=${ids}`)
        .then((res) => {
          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));

            axios
              .get("/blog?isPaginated=1")
              .then((res) => {
                console.log(res);
                setAllBlogs(res.data.data);
                setFrom(res.data.meta.from);
                setCurrentPage(res.data.meta.current_page);
              })
              .catch((e) => {
                console.log(e);
              });
          }
          console.log(res);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  // edit product
  const editBlog = (id) => {
    dispatch(UpdateAllProductProductId(id));
    navigate("/AllProductUpdate");
  };

  const showFilterClick = () => {
    setShowFilter(!showFilter);
  };

  // function for seller's filter
  const sellerFilter = (item) => {
    // http req
    axios
      .get(`/seller/${item.id}`)
      .then((res) => {
        setAllBlogs(res.data.data);
        setSellers(res.data.sellers);
        setFrom(res.data.meta.from);
        setCurrentPage(res.data.meta.current_page);
        console.log(res);
        setActiveSeller(item);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // function for delete filter
  const delteFilter = () => {
    axios
      .get("/seller")
      .then((res) => {
        console.log(res);
        setAllBlogs(res.data.data);
        setSellers(res.data.sellers);
        setFrom(res.data.meta.from);
        setCurrentPage(res.data.meta.current_page);

        setActiveSeller({
          id: "",
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // function for open table item
  const openTableItem = (index) => {
    setOpenTable((prev) => {
      if (index === prev) {
        prev = null;
      } else {
        prev = index;
      }

      forceUpdate();
      return prev;
    });
  };

  return (
    <div className="manage-blog-all">
      <div className="manage-blog-top-bar">
        <TopBar />
      </div>
      <div className="manage-blog-search-dropdown-buttons-box">
        <div className="manage-blog-buttons-box">
          {/* <button
            onClick={() => navigate("/CreateBlog")}
            className="manage-blog-create-blog-button"
          >
            <img src={AllProductAdd} />
            <p>ایجاد بلاگ</p>
          </button> */}
          <button
            onClick={() => showFilterClick()}
            className="manage-blog-show-filter-button"
          >
            <img src={FilterLogo} />
            {showFilter === false ? (
              <p>نمایش فیلتر ها</p>
            ) : (
              <p>بستن فیلتر ها</p>
            )}
          </button>
        </div>
      </div>
      <div className="manage-blog-table-box">
        <div
          className={
            showFilter === false
              ? "manage-blog-table-box-2"
              : "manage-blog-table-box-3"
          }
        >
          <SimpleBar
            forceVisible="x"
            // scrollableNodeProps={{ ref: this.scrollTitleRef }}
            style={{
              width: "calc(100% - 3px)",
              borderLeft: "1px solid rgb(220, 220, 220)",
              borderRight: "1px solid rgb(220, 220, 220)",
              borderBottom: "1px solid rgb(220, 220, 220)",
              borderRadius: "10px 10px 0px 0px",
              cursor: "grab",
            }}
          >
            <ScrollDrag innerRef={scrollTitleRef}>
              <div
                style={{ minWidth: "1500px", height: "auto" }}
                className={"all-products-header dragscroll"}
              >
                {/* <div className="all-products-tab-bar">
                  <div className="all-products-tab-bar-right">
                    {activeSeller.id == "" ? (
                      <p>همه محصولات فروشندگان</p>
                    ) : (
                      <p>
                        محصولات {activeSeller.name + " " + activeSeller.family}
                      </p>
                    )}

                    <p
                      onClick={() => navigate("/GarbageBlog")}
                      style={
                        location.pathname == "/GarbageBlog"
                          ? {
                              color: "#00457b",
                              fontWeight: "bold",
                            }
                          : {}
                      }
                    >
                      زباله دان
                    </p>
                  </div>

                  <div className="all-products-tab-bar-left">
                    <button
                      style={
                        idSelected.length > 0
                          ? { backgroundColor: "#f23b3b" }
                          : {}
                      }
                      onClick={() => deleteBlog()}
                      className="manage-blog-trash-button"
                    >
                      {idSelected.length > 0 ? (
                        <img src={AllProductTrash} />
                      ) : (
                        <img src={AllProductTrashRed} />
                      )}

                      <p style={idSelected.length > 0 ? { color: "#fff" } : {}}>
                        زباله دان
                      </p>
                    </button>
                  </div>
                </div>  */}

                <div className="manage-blog-table-top-bar">
                  <p className="manage-blog-top-bar-hashtag">#</p>
                  {/* <div className="manage-blog-top-bar-checkbox">
                    <label>
                      <img
                        src={
                          idSelected.length === allBlogs.length &&
                          allBlogs.length != 0
                            ? headerCheck
                            : uncheck
                        }
                      />
                      <input
                        style={{ display: "none" }}
                        onChange={() => allCheckBoxOnChange()}
                        id="allCheckBox"
                        name="allCheckBox"
                        type="checkbox"
                      />
                    </label>
                  </div> */}
                  <p
                    style={{ width: "12%" }}
                    className="manage-blog-top-bar-title"
                  >
                    عکس محصول
                  </p>
                  <p
                    style={{ width: "20%" }}
                    className="manage-blog-top-bar-visit"
                  >
                    نام محصول
                  </p>
                  <p className="manage-blog-top-bar-comment">دسته بندی</p>
                  <p className="manage-blog-top-bar-category">بارکد</p>
                  <p className="manage-blog-top-bar-writer">تعداد فروشندگان</p>
                  <p className="manage-blog-top-bar-date">قیمت پایه</p>
                  <p className="manage-blog-top-bar-opation">عملیات</p>
                </div>
              </div>
            </ScrollDrag>
          </SimpleBar>

          <SimpleBar
            forceVisible="x"
            // scrollableNodeProps={{ ref: this.scrollContentRef }}
            style={{
              width: "calc(100% - 3px)",
              borderLeft: "solid 1px #dcdcdc",
              borderRight: "solid 1px #dcdcdc",
              borderBottom: "solid 1px #dcdcdc",
              borderRadius: "0 0 10px 10px",
            }}
          >
            <ScrollDrag
              className="scroll-container"
              innerRef={scrollContentRef}
            >
              {allBlogs.map((item, index) => {
                return (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    {/*  row title */}
                    <div
                      onClick={() => openTableItem(index)}
                      key={index}
                      style={
                        allBlogs.length != index + 1
                          ? {
                              borderBottom: "solid 1px #dcdcdc",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              height: "auto",
                              padding: "15px 0",
                            }
                          : {
                              justifyContent: "flex-start",
                              alignItems: "center",
                              height: "auto",
                              padding: "15px 0",
                            }
                      }
                      className="manage-blog-item-box"
                    >
                      <div className="manage-blog-item-number">
                        <p>{from + index}</p>
                      </div>
                      {/* <div className="manage-blog-item-checkbox">
                      <label htmlFor={`name${index}`}>
                        <img
                          src={idSelected.includes(item.id) ? check : uncheck}
                        />
                        <input
                          style={{ display: "none" }}
                          id={`name${index}`}
                          name={`name${index}`}
                          onChange={() => checkBoxOnChange(item.id)}
                          type="checkbox"
                        />
                      </label>
                    </div> */}

                      <div
                        style={{ width: "10%" }}
                        className="create-dicount-table-item-box-image"
                      >
                        <span>
                          <img src={item.product_image} />
                        </span>
                      </div>
                      <div
                        style={{ width: "25%" }}
                        className="manage-blog-item-title"
                      >
                        <p style={{ maxWidth: "300px" }}>{item.title}</p>
                      </div>
                      <div className="manage-blog-item-visit">
                        <p>{item.category}</p>
                      </div>
                      <div className="manage-blog-item-comment">
                        <p>{item.barcode}</p>
                      </div>
                      <div className="manage-blog-item-category">
                        <p>{item.seller.length}</p>
                      </div>
                      <div className="manage-blog-item-writer">
                        <p>{item.price} تومان</p>
                      </div>

                      {/* <div className="manage-blog-item-date">
                      <div
                        className={
                          item.release_status === 1
                            ? "manage-blog-item-date-box"
                            : "manage-blog-item-date-box-2"
                        }
                      >
                        <p>
                          {item.release_status === 1
                            ? item.release_time
                            : "پیش نویس"}
                        </p>
                      </div>
                    </div> */}
                      <div
                        style={{ justifyContent: "flex-end" }}
                        className="manage-blog-item-operation"
                      >
                        <img
                          onClick={() => editBlog(item.product_id)}
                          src={EditActive}
                        />
                      </div>
                    </div>
                    {/*  row content */}

                    {openTable == index && (
                      <div
                        style={{
                          padding: "30px",
                          height: "auto",
                          minWidth: "1513px",
                        }}
                        className="customers-table-open-box"
                      >
                        <div className="customers-table-open-information-box">
                          <div
                            style={{ marginRight: "10px" }}
                            className="customers-table-open-information-order-box"
                          >
                            <p>فروشنده</p>
                          </div>
                          <div
                            style={{ marginRight: "10px" }}
                            className="customers-table-open-information-user-box"
                          >
                            <p>تعداد موجودی</p>
                          </div>
                          <div
                            style={{ marginRight: "10px" }}
                            className="customers-table-open-activity-user-box"
                          >
                            <p>قیمت فروشنده</p>
                          </div>
                        </div>
                        <div className="customers-table-open-line"></div>

                        <div
                          style={{
                            flexDirection: "column",
                            padding: "20px 0 0 0",
                            height: "auto",
                          }}
                          className="customers-table-open-information-box-2"
                        >
                          {item.seller.map((seller, i) => {
                            return seller.id == activeSeller.id ? (
                              <>
                                <div
                                  key={i}
                                  style={{ margin: "15px 0" }}
                                  className="customers-table-open-information-box"
                                >
                                  <div
                                    style={{ marginRight: "10px" }}
                                    className="customers-table-open-information-order-box"
                                  >
                                    <p style={{ color: "#000" }}>
                                      {seller.name + " " + seller.family}
                                    </p>
                                  </div>
                                  <div className="customers-table-open-information-user-box">
                                    <p style={{ color: "#000" }}>
                                      {seller.pivot.available_count}
                                    </p>
                                  </div>
                                  <div className="customers-table-open-activity-user-box">
                                    <p style={{ color: "#000" }}>
                                      {seller.pivot.price} تومان
                                    </p>
                                  </div>
                                </div>

                                <div
                                  style={{
                                    backgroundColor: "rgb(220, 220, 220)",
                                    display: `${
                                      i != item.seller.length - 1
                                        ? "flex"
                                        : "none"
                                    }`,
                                  }}
                                  key={i}
                                  className="customers-table-open-line"
                                ></div>
                              </>
                            ) : (
                              activeSeller.id == "" && (
                                <>
                                  <div
                                    key={i}
                                    style={{ margin: "15px 0" }}
                                    className="customers-table-open-information-box"
                                  >
                                    <div
                                      style={{ marginRight: "10px" }}
                                      className="customers-table-open-information-order-box"
                                    >
                                      <p style={{ color: "#000" }}>
                                        {seller.name + " " + seller.family}
                                      </p>
                                    </div>
                                    <div className="customers-table-open-information-user-box">
                                      <p style={{ color: "#000" }}>
                                        {seller.pivot.available_count}
                                      </p>
                                    </div>
                                    <div className="customers-table-open-activity-user-box">
                                      <p style={{ color: "#000" }}>
                                        {seller.pivot.price} تومان
                                      </p>
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      backgroundColor: "rgb(220, 220, 220)",
                                      display: `${
                                        i != item.seller.length - 1
                                          ? "flex"
                                          : "none"
                                      }`,
                                    }}
                                    key={i}
                                    className="customers-table-open-line"
                                  ></div>
                                </>
                              )
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </ScrollDrag>
          </SimpleBar>

          <div className="manage-blog-paginate-box">
            <Pagination firstAxios={firstAxios} currentAxios={currentAxios} />
          </div>
        </div>
        {showFilter === true && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <div
              id={"userFilter"}
              // onClick={() => chooseFilterClick("USER")}
              // onMouseEnter={() => hoverChooseFilter("userFilter")}
              // onMouseLeave={() => notHoverChosseFilter("userFilter")}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                padding: "20px",
                width: "360px",
                height: "auto",
                backgroundColor: "#ffffff",
                fontFamily: "IRANSansWebNoEn",
                color: "#707070",
                fontSize: "20px",

                maxHeight: `${!showSellers ? "20px" : "280px"}`,
                overflow: "hidden",
                cursor: "pointer",
                border: "solid 1px #818181",
                borderRadius: "10px",
                transition: "max-height 0.3s",
              }}
            >
              <div
                onClick={() => setShowSellers((prev) => !prev)}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  cursor: "pointer",
                }}
              >
                <p
                  style={{
                    cursor: "pointer",
                    marginBottom: "30px",
                  }}
                >
                  فروشندگان
                </p>

                <img
                  style={{
                    cursor: "pointer",
                    width: "23px",
                    height: "23px",
                    color: "rgb(112 , 112 , 112)",
                    fill: "rgb(112 , 112 , 112)",
                    transform: `${
                      showSellers === true ? "rotate(0deg)" : "rotate(-180deg)"
                    }`,
                    transition: "transform 0.3s",
                  }}
                  src={AllProductFilterUpArrow}
                />
              </div>

              {/* single row sellers */}
              {sellers.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      marginBottom: "20px",
                    }}
                  >
                    <label
                      htmlFor={`userRadio${index}`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "10%",
                          height: "100%",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: `${
                              activeSeller.id === item.id ? "11px" : "15px"
                            }`,

                            height: `${
                              activeSeller.id === item.id ? "11px" : "15px"
                            }`,
                            borderRadius: "15px",
                            border: `${
                              activeSeller.id === item.id
                                ? "solid 4px #00589c"
                                : "solid 1px #000000"
                            }`,
                            cursor: "pointer",
                          }}
                        ></div>
                        <input
                          style={{
                            display: "none",
                          }}
                          name="userRadio"
                          id={`userRadio${index}`}
                          onChange={() => sellerFilter(item)}
                          type="radio"
                        />
                      </div>
                      <p
                        style={{
                          fontFamily: "IRANSansFaNum",
                          color: "#484848",
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                      >
                        {item.name + " " + item.family}
                      </p>
                    </label>
                  </div>
                );
              })}
            </div>

            {activeSeller.id != "" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "95%",
                  height: "50px",
                  borderRadius: "10px",
                  border: "solid 1px #818181",
                  marginTop: "10px",
                  padding: "0px 10px 0px 10px",
                }}
              >
                <p
                  style={{
                    fontFamily: "IRANSansFaNum",
                    color: "#818181",
                    fontSize: "16px",
                  }}
                >
                  محصولات {activeSeller.name + " " + activeSeller.family}
                </p>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => delteFilter()}
                  src={AllProductDeleteFilter}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MarketPlace;
