import React, { useEffect } from "react";
import "../../assets/styles/DeliveryStyle/CityDelivery.css";
import axios from "../../js/Axios/Axios";
import { ShowAlert } from "../../action/index";
import check from "../../assets/icons/check.svg";
import uncheck from "../../assets/icons/uncheck.svg";
import DropDown from "../DropDown/DropDown";
import InputComponent from "../TextInputComponent/TextInputComponent";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { useState } from "react";
import useForceUpdate from "use-force-update";
import { useSelector, useDispatch } from "react-redux";
export default function StateDelivery() {
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const [deliveryType, setDeliveryType] = useState([]);
  const [storeDelivery, setStoreDelivery] = useState([]);
  const [activeButton, setActiveButton] = useState(null);
  const [show, setShow] = useState(false);
  const [info, setInfo] = useState({
    title: null,
    id: null,
  });
  const [city, setCity] = useState({
    status: 1,
    price: null,
    deliveryType_id: null,
    province_id: null,
    edit_id: null,
    checked: false,
  });
  const [cityArray, setCityArray] = useState([]);
  const [citySearch, setCitySearch] = useState("");
  useEffect(() => {
    axios
      .get("/Store/showInfo")
      .then((res) => {
        console.log(res);
        setInfo((prev) => {
          prev.id = res.data.data.province_id;
          prev.title = res.data.data.city;
          forceUpdate();
          return prev;
        });
        setCity((prev) => {
          prev.province_id = res.data.data.city_id;
          forceUpdate();
          return prev;
        });
        forceUpdate();
      })
      .catch((e) => {
        console.log(e);
      });
    setTimeout(() => {
      axios
        .get("/StoreDeliveryType/search?isInProvince=1")
        .then((res) => {
          console.log("======== ", res);
          let data = res.data.data;
          data.map((item) => {
            item.inputs.map((input) => {
              input["checked"] = false;
            });
          });
          forceUpdate();
          if (data.length === 0) {
            setShow(true);
            setActiveButton("send");
          }
          data.map((item) => {
            storeDelivery.push(item);
            forceUpdate();
          });
          storeDelivery.map((item, index) => {
            console.log(item.title, info.title, item.title === info.title);
            if (item.title === info.title) {
              item.inputs.map((input, i) => {
                if (input.title === "پیک موتور" && item.inputs.length == 1) {
                  setCity((prev) => {
                    prev.deliveryType_id = input.deliveryType_id;
                    prev.price = input.price;
                    prev.province_id = input.province_id;
                    prev.status = input.status;
                    prev.edit_id = input.storeDeliveryType_id;
                    return prev;
                  });
                  item.inputs.splice(i, 1);
                  if (item.title === info.title) {
                    storeDelivery.splice(index, 1);
                  }
                  forceUpdate();
                } else if (
                  input.title === "پیک موتور" &&
                  item.inputs.length > 1
                ) {
                  console.log("else if");
                  setCity((prev) => {
                    prev.deliveryType_id = input.deliveryType_id;
                    prev.price = input.price;
                    prev.province_id = input.province_id;
                    prev.status = input.status;
                    prev.storeDeliveryType_id = input.storeDeliveryType_id;
                    return prev;
                  });
                  item.inputs.splice(i, 1);
                  forceUpdate();
                }
              });
            }
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }, 1000);

    axios
      .get("/DeliveryType/relatedList")
      .then((res) => {
        console.log(res);
        res.data.data.map((item) => {
          deliveryType.push({
            title: item.title,
            is_inProvince: item.is_inProvince,
            checked: false,
            status: 1,
            price: null,
            deliveryType_id: item.id,
            province_id: null,
            storeDeliveryType_id: null,
          });
          forceUpdate();
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  useEffect(() => {
    setTimeout(() => {
      axios
        .get(`/Province/${info.id}`)
        .then((res) => {
          console.log(res);
          res.data.data.cities.map((item, index) => {
            cityArray.push({
              title: item.province,
              id: item.id,
              checked: false,
              deleted: false,
            });
          });
          cityArray.map((item) => {
            storeDelivery.map((item2) => {
              if (item.id === item2.id) {
                item.checked = true;
                item.deleted = true;
              }
              forceUpdate();
            });
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }, 3000);
  }, []);
  const cityOnChange = (e) => {
    setCitySearch(e.target.value);
  };
  const deliveryTypeClick = (index, title, id) => {
    setDeliveryType((prev) => {
      prev[index].checked = !prev[index].checked;
      forceUpdate();
      return prev;
    });
    console.log(title, id);
    if (title === "پیک موتور") {
      setCity((prev) => {
        prev.deliveryType_id = id;
        prev.checked = !prev.checked;
        return prev;
      });
    }
  };
  const Data = (data) => {
    console.log(data);
    if (data.checked === true) {
      deliveryType.map((item, index) => {
        item.province_id = data.id;
      });
      forceUpdate();
      let deliverywitoutMostor = [];

      deliveryType.map((item, index) => {
        if (item.title != "پیک موتور") {
          deliverywitoutMostor.push(item);
        }
      });
      storeDelivery.push({
        title: data.title,
        id: data.id,
        inputs: deliverywitoutMostor,
      });
      forceUpdate();
    } else {
      storeDelivery.map((item, index) => {
        if (item.title === data.title && data.checked === false) {
          if (data.deleted === false) {
            storeDelivery.splice(index, 1);
          } else {
            axios
              .delete(`/StoreDeliveryType/throwingGarbageFromProvince`, {
                data: {
                  province_id: data.id,
                },
              })
              .then((res) => {
                console.log(res);
                if (res.data.status) {
                  dispatch(ShowAlert(true, res.data.message));
                  storeDelivery.map((item, index) => {
                    storeDelivery.splice(index);
                  });
                  cityArray.map((item, index) => {
                    cityArray.splice(index);
                  });
                  forceUpdate();
                  setShow(false);
                  setTimeout(() => {
                    setShow(true);
                  }, 100);
                  forceUpdate();
                  ///////////////
                  axios
                    .get("/StoreDeliveryType/search?isInProvince=1")
                    .then((res) => {
                      console.log("======== ", res);
                      let data = res.data.data;
                      data.map((item) => {
                        item.inputs.map((input) => {
                          input["checked"] = false;
                        });
                      });
                      forceUpdate();
                      data.map((item) => {
                        storeDelivery.push(item);
                        forceUpdate();
                      });
                      storeDelivery.map((item, index) => {
                        console.log(
                          item.title,
                          info.title,
                          item.title === info.title
                        );
                        if (item.title === info.title) {
                          console.log("first if");
                          item.inputs.map((input, i) => {
                            if (
                              input.title === "پیک موتور" &&
                              item.inputs.length == 1
                            ) {
                              setCity((prev) => {
                                prev.deliveryType_id = input.deliveryType_id;
                                prev.price = input.price;
                                prev.province_id = input.province_id;
                                prev.status = input.status;
                                prev.edit_id = input.storeDeliveryType_id;
                                return prev;
                              });
                              item.inputs.splice(i, 1);
                              if (item.title === info.title) {
                                storeDelivery.splice(index, 1);
                              }
                              forceUpdate();
                            } else if (
                              input.title === "پیک موتور" &&
                              item.inputs.length > 1
                            ) {
                              console.log("else if");
                              setCity((prev) => {
                                prev.deliveryType_id = input.deliveryType_id;
                                prev.price = input.price;
                                prev.province_id = input.province_id;
                                prev.status = input.status;
                                prev.storeDeliveryType_id =
                                  input.storeDeliveryType_id;
                                return prev;
                              });
                              item.inputs.splice(i, 1);
                              forceUpdate();
                            }
                          });
                        }
                      });
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                  //////////////
                  ////////////////
                  axios
                    .get(`/Province/${info.id}`)
                    .then((res) => {
                      console.log(res);
                      res.data.data.cities.map((item, index) => {
                        cityArray.push({
                          title: item.province,
                          id: item.id,
                          checked: false,
                          deleted: false,
                        });
                      });
                      cityArray.map((item) => {
                        storeDelivery.map((item2) => {
                          if (item.id === item2.id) {
                            item.checked = true;
                            item.deleted = true;
                          }
                          forceUpdate();
                        });
                      });
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                  ////////////////
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        }
        forceUpdate();
      });
    }
  };

  const priceCHange = (e, inputs, index, i) => {
    const newArray = [...storeDelivery];
    newArray[index].inputs[i] = {
      ...newArray[index].inputs[i],
      price: e.target.value,
    };
    setStoreDelivery(newArray);
    forceUpdate();
  };
  const shareLocationPrice = () => {
    setActiveButton(null);
    setShow(false);
    // console.log(storeDelivery);
    if (activeButton === "send") {
      let array = [];
      storeDelivery.map((item) => {
        item.inputs.map((item2) => {
          if (item2.price !== null) {
            let obj = {};
            // obj["title"] = item.title;
            obj["edit_id"] = item2.storeDeliveryType_id;
            obj["province_id"] = item.id;
            obj["deliveryType_id"] = item2.deliveryType_id;
            obj["status"] = item2.status;
            obj["price"] = item2.price;
            array.push(obj);
          }
        });
      });
      if (city.price !== null) {
        array.push(city);
        forceUpdate();
      }
      console.log(array);
      axios
        .post("/StoreDeliveryType", array)
        .then((res) => {
          console.log(res);
          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));
            storeDelivery.map((item, index) => {
              storeDelivery.splice(index);
            });
            cityArray.map((item, index) => {
              cityArray.splice(index);
            });
            ///////////////////////
            axios
              .get("/StoreDeliveryType/search?isInProvince=1")
              .then((res) => {
                console.log("======== ", res);
                let data = res.data.data;
                data.map((item) => {
                  item.inputs.map((input) => {
                    input["checked"] = false;
                  });
                });
                forceUpdate();
                data.map((item) => {
                  storeDelivery.push(item);
                  forceUpdate();
                });
                storeDelivery.map((item, index) => {
                  console.log(
                    item.title,
                    info.title,
                    item.title === info.title
                  );
                  if (item.title === info.title) {
                    console.log("first if");
                    item.inputs.map((input, i) => {
                      if (
                        input.title === "پیک موتور" &&
                        item.inputs.length == 1
                      ) {
                        setCity((prev) => {
                          prev.deliveryType_id = input.deliveryType_id;
                          prev.price = input.price;
                          prev.province_id = input.province_id;
                          prev.status = input.status;
                          prev.edit_id = input.storeDeliveryType_id;
                          return prev;
                        });
                        item.inputs.splice(i, 1);
                        if (item.title === info.title) {
                          storeDelivery.splice(index, 1);
                        }
                        forceUpdate();
                      } else if (
                        input.title === "پیک موتور" &&
                        item.inputs.length > 1
                      ) {
                        console.log("else if");
                        setCity((prev) => {
                          prev.deliveryType_id = input.deliveryType_id;
                          prev.price = input.price;
                          prev.province_id = input.province_id;
                          prev.status = input.status;
                          prev.storeDeliveryType_id =
                            input.storeDeliveryType_id;
                          return prev;
                        });
                        item.inputs.splice(i, 1);
                        forceUpdate();
                      }
                    });
                  }
                });
              })
              .catch((e) => {
                console.log(e);
              });
            //////////////

            /////////////////
            axios
              .get(`/Province/${info.id}`)
              .then((res) => {
                console.log(res);
                res.data.data.cities.map((item, index) => {
                  cityArray.push({
                    title: item.province,
                    id: item.id,
                    checked: false,
                    deleted: false,
                  });
                });
                cityArray.map((item) => {
                  storeDelivery.map((item2) => {
                    if (item.id === item2.id) {
                      item.checked = true;
                      item.deleted = true;
                    }
                    forceUpdate();
                  });
                });
              })
              .catch((e) => {
                console.log(e);
              });
            ///////////////
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  function getDifference(array1, array2) {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return object1.deliveryType_id === object2.deliveryType_id;
      });
    });
  }
  const editLocationPrice = (id) => {
    setActiveButton(id);
    setShow(true);
    if (activeButton === null) {
      storeDelivery.map((item) => {
        const difference = [
          ...getDifference(item.inputs, deliveryType),
          ...getDifference(deliveryType, item.inputs),
        ];
        if (item.inputs.length !== deliveryType.length) {
          difference.map((dif) => {
            console.log(dif);
            if (dif.title !== "پیک موتور") {
              item.inputs.push({
                checked: false,
                deliveryType_id: dif.deliveryType_id,
                is_inProvince: 0,
                price: null,
                province_id: item.id,
                status: "1",
                storeDeliveryType_id: null,
                title: dif.title,
              });
            }
          });
        }
      });

      storeDelivery.map((item) => {
        item.inputs.map((input) => {
          input.checked = true;
        });
      });
      deliveryType.map((item) => {
        item.checked = true;
      });
      setCity((prev) => {
        prev.checked = true;
        return prev;
      });
      forceUpdate();
    }
  };

  return (
    <div className="state-deliver-all">
      <p>ارسال به استان فروشگاه</p>
      <div className="state-deliver-send-model-box">
        <p>مدل ارسال را انتخاب کنید</p>
        {deliveryType.map((item, index) => {
          return (
            <label
              key={index}
              htmlFor={`${index}peyk_motori`}
              className="state-deliver-send-model-box-2"
            >
              <input
                style={{ display: "none" }}
                type="checkbox"
                id={`${index}peyk_motori`}
                name={`${index}peyk_motori`}
                onChange={() =>
                  deliveryTypeClick(index, item.title, item.deliveryType_id)
                }
              />
              <img
                src={item.checked === true ? check : uncheck}
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
              />
              <p>{item.title}</p>
            </label>
          );
        })}
      </div>
      <div className="state-deliver-send-model-drop-down-box">
        <DropDown
          width="100%"
          height="44px"
          dropDownHeight="300px"
          zindex="1"
          onchange={(e) => cityOnChange(e)}
          data={cityArray}
          search={citySearch}
          Data={Data}
          show={show}
          axiosname="province"
          placeholder="شهر"
        />
      </div>
      <div className="state-deliver-send-model-own-city-box">
        <p>{info.title}</p>
        <div className="state-deliver-send-model-input-box">
          <div className="state-deliver-send-model-input-box-0">
            <div
              className={
                city.checked === true
                  ? "state-deliver-send-model-input-box-2"
                  : "state-deliver-send-model-input-box-2-active"
              }
            >
              <p style={{ minWidth: "70px" }}>پیک موتور</p>
              {city.checked === true ? (
                <InputComponent
                  disabled={city.checked === false ? true : false}
                  width="auto"
                  height="40px"
                  value={city.price}
                  placeholder="تومان"
                  borderColor="#fff"
                  inputType="input"
                  type="number"
                  onchange={(e) => {
                    setCity((prev) => {
                      prev.price = e.target.value;
                      forceUpdate();
                      return prev;
                    });
                  }}
                />
              ) : (
                <p>{city.price}</p>
              )}
            </div>
          </div>
          <div className="state-deliver-send-model-input-box-3">
            <p>نکته: فقط شهر فروشگاه میتواند از سرویس پیک موتور استفاده کند</p>
          </div>
        </div>
      </div>
      <div className="state-deliver-send-model-own-city-box-2">
        <SimpleBar
          forceVisible="y"
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          {storeDelivery.map((item, index) => {
            return (
              <div
                key={index}
                className="state-deliver-send-model-own-city-box"
              >
                <p>{item.title}</p>
                <div className="state-deliver-send-model-input-box">
                  {storeDelivery.length != 0 &&
                    item.inputs.map((item_inputs, i) => {
                      return (
                        <div
                          key={i}
                          className="state-deliver-send-model-input-box-city-box"
                        >
                          <div
                            className={
                              item_inputs.checked === true
                                ? "state-deliver-send-model-input-box-2"
                                : "state-deliver-send-model-input-box-2-active"
                            }
                          >
                            <p>{item_inputs.title}</p>
                            {item_inputs.checked === true ? (
                              <InputComponent
                                disabled={
                                  item_inputs.checked === true ? false : true
                                }
                                width="auto"
                                height="40px"
                                value={storeDelivery[index].inputs[i].price}
                                placeholder="تومان"
                                borderColor="#fff"
                                inputType="input"
                                type="number"
                                onchange={(e) =>
                                  priceCHange(e, item.inputs, index, i)
                                }
                              />
                            ) : (
                              <p>{storeDelivery[index].inputs[i].price}</p>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
        </SimpleBar>
      </div>
      <div className="state-deliver-send-button-box">
        <button
          className={
            activeButton === null
              ? "state-deliver-edit-button"
              : "state-deliver-edit-button-disabled"
          }
          onClick={() => editLocationPrice("send")}
        >
          ویرایش
        </button>
        <button
          onClick={() => shareLocationPrice()}
          className={
            activeButton === "send"
              ? "state-deliver-send-button"
              : "state-deliver-send-button-disabled"
          }
        >
          ثبت
        </button>
      </div>
    </div>
  );
}
