import React, { Component } from "react";
import "../../assets/styles/AllProduct/AllProduct.css";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import { ShowAlert, PaginateData } from "../../action/index";
import { ResetToInit } from "../../action/index";
import { connect } from "react-redux";
import { UpdateAllProductProductId } from "../../action/index";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Pagination from "../../components/Pagination/Pagination";
import axios from "../../js/Axios/Axios";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import TopBar from "../../components/TopBar/TopBar";
import AllProductCalender from "../../assets/icons/AllProductCalender.svg";
import FilterLogo from "../../assets/icons/Filter.svg";
import AllProductAdd from "../../assets/icons/AllProductAdd.svg";
import AllProductShare from "../../assets/icons/AllProductShare.svg";
import AllProductTrash from "../../assets/icons/GarbageDelte.svg";
import AllProductTrashRed from "../../assets/icons/GarbageDelteRed.svg";
import AllProductDeleteFilter from "../../assets/icons/AllProductDeleteFilter.svg";
// import Edit from "../../assets/icons/Edit.svg";
import check from "../../assets/icons/check.svg";
import uncheck from "../../assets/icons/uncheck.svg";
import Eye from "../../assets/icons/AllProductEye.svg";
import ActiveEye from "../../assets/icons/AllProductEyeActive.svg";
import Edit from "../../assets/icons/Edit.svg";
import EditActive from "../../assets/icons/EditActive.svg";

import showFilterIcon from "../../assets/icons/showFilterIcon.svg";
import SearchLogo from "../../assets/icons/SearchLogo.svg";
import AllProductFilterUpArrow from "../../assets/icons/AllProductFilterUpArrow.svg";
import AllProductRightArrow from "../../assets/icons/AllProductRightArrow.svg";
import * as moment from "jalali-moment";
import DatePicker from "react-datepicker2";
import { Calendar } from "react-datepicker2";
import SimpleBar from "simplebar-react";
import ScrollDrag from "react-indiana-drag-scroll";
import Table from "../../components/TableComponent/Table";
import { productsTab } from "../../components/TableTabs/Tabs";

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  display: "flex",
  width: "350px",
  color: "#00589c",
  height: 3,
  "& .MuiSlider-thumb": {
    height: 21,
    width: 21,
    backgroundColor: "#00589c",
    border: "5px solid #ffffff",
    "&:hover": {
      boxShadow: "0 0 2.5 0px rgba(0, 0, 0, 0.15)",
    },
    "& .airbnb-bar": {
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  "& .MuiSlider-track": {
    height: 3,
  },
  "& .MuiSlider-rail": {
    color: "#ebebeb",
    height: 3,
  },
}));

class AllProduct extends Component {
  constructor(props) {
    super(props);
    // refs for scrolls
    this.scrollTitleRef = React.createRef();
    this.scrollContentRef = React.createRef();

    this.state = {
      // tab bar states
      goToReleasedProducts: false,
      goToDraftsProducts: false,
      goToTrash: false,
      paginateUrl: "/product?isPaginated=1",
      allProductData: [],
      allFilter: [],
      categoryFilterData: [],
      categorySelected: [],
      categoryNameSelected: [],
      disabledCategory: false,
      value: [0, 100],
      currentpage: null,
      from: null,
      search: null,
      showFilter: false,
      minimizeFilterBox: false,
      whichFilter: null,
      minReleaseTime: "",
      maxReleaseTime: "",
      isGregorian: false,
      datestart: false,
      dateend: null,
      minRange: null,
      endDateInput: false,
      startDateInput: true,
      dateSituation: false,
      showFilterButton: "نمایش فیلترها",
      typeProduct: "",
      typeProductShow: null,
      InventoryProduct: "",
      InventoryProductShow: null,
      priceValueShowStart: "",
      priceValueShowEnd: "",
      disabledPrice: false,
      tableChecked: [],
      tableCheckBoxAll: false,
      makeProduct: false,
      showProductINstore: false,
      showProductStoreData: null,
      lastPage: null,
    };
    this.props.dispatch(ResetToInit());
    axios
      .get("/product?isPaginated=1")
      .then((res) => {
        if (res.data.status) {
          const data = res.data.data;
          this.setState({ allProductData: data });
          this.setState({ from: res.data.meta.from });
          this.setState({ currentpage: res.data.meta.current_page });
          this.setState({ lastPage: res.data.meta.last_page });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  showProductInStoreClick = (ProductId) => {
    this.setState({ showProductINstore: true });
    this.props.dispatch(UpdateAllProductProductId(ProductId));
    this.setState({ paginateUr: null });
    this.forceUpdate();
  };

  static getDerivedStateFromProps(props, state) {
    if (props.PaginateData.data && props.PaginateData.data.length > 0) {
      // if (state.tableCheckBoxAll === true || state.tableChecked.length != 0) {
      //   state.tableCheckBoxAll = false;
      //   state.allProductData.map((item, index) => {
      //     state.tableChecked.splice(index);
      //   });
      // }
      state.allProductData = props.PaginateData.data;
      state.currentpage = props.PaginateData.meta.current_page;
      state.from = props.PaginateData.meta.from;
      state.lastPage = props.PaginateData.meta.last_page;
    }
  }
  addDateFilter = () => {
    if (this.state.minReleaseTime != "") {
      var min = `${this.state.minReleaseTime} 00:00:00`;
    } else if (this.state.minReleaseTime === "") {
      var min = "";
    }
    if (this.state.maxReleaseTime != "") {
      var max = `${this.state.maxReleaseTime} 23:59:59`;
    } else if (this.state.maxReleaseTime === "") {
      var max = "";
    }
    let filterCategories = JSON.stringify(this.state.categorySelected);
    this.state.paginateUrl = `/product?isPaginated=1&minReleaseTime=${min}&maxReleaseTime=${max}&productType=${this.state.typeProduct}&available_status=${this.state.InventoryProduct}&filterCategories=${filterCategories}&minPrice=${this.state.priceValueShowStart}&maxPrice=${this.state.priceValueShowEnd}`;

    axios
      .get(
        `/product?isPaginated=1&minReleaseTime=${min}&maxReleaseTime=${max}&productType=${this.state.typeProduct}&available_status=${this.state.InventoryProduct}&filterCategories=${filterCategories}&minPrice=${this.state.priceValueShowStart}&maxPrice=${this.state.priceValueShowEnd}`

        // &productType=${this.state.typeProduct}&available_status=${this.state.InventoryProduct}&filterCategories=${filterCategories}&minPrice=${this.state.priceValueShowStart}&maxPrice=${this.state.priceValueShowEnd}`
      )
      .then((res) => {
        // if (res.data.status === 0) {
        //   this.props.dispatch(ShowAlert(true, res.data.message));
        // }
        if (res.data.status) {
          // this.props.dispatch(ShowAlert(true, res.data.message));

          const data = res.data.data;
          this.setState({ allProductData: data });
          this.setState({ from: res.data.meta.from });
          this.setState({ currentpage: res.data.meta.current_page });
          this.setState({ lastPage: res.data.meta.last_page });
          this.setState({ minRange: null });
          if (
            (this.state.maxReleaseTime != "", this.state.minReleaseTime != "")
          ) {
            this.setState({ endDateInput: false });
            this.setState({ startDateInput: false });
          }
          if (this.state.dateSituation === true) {
            this.state.allFilter.push({
              title: `محدوده تاریخ ( از ${this.state.minReleaseTime} تا  ${this.state.maxReleaseTime})`,
              id: "date",
            });
            this.setState({ dateSituation: false });

            this.forceUpdate();
          }
          if (this.state.typeProduct != "") {
            this.state.allFilter.push({
              title: `نوع محصول (${this.state.typeProductShow})`,
              id: "Type",
            });
            let variableProducts = document.getElementById("variableProducts");
            variableProducts.disabled = true;

            let simpleProducts = document.getElementById("simpleProducts");
            simpleProducts.disabled = true;
            this.setState({ typeProduct: "" });
            this.forceUpdate();
          }
          if (this.state.InventoryProduct != "") {
            this.state.allFilter.push({
              title: ` وضعیت موجودی (${this.state.InventoryProductShow})`,
              id: "Inventory",
            });
            let AvailableProducts =
              document.getElementById("AvailableProducts");
            AvailableProducts.disabled = true;

            let NotAvailableProducts = document.getElementById(
              "NotAvailableProducts"
            );
            NotAvailableProducts.disabled = true;
            let ManageProducts = document.getElementById("ManageProducts");
            ManageProducts.disabled = true;
            this.setState({ InventoryProduct: "" });
            this.forceUpdate();
          }
          if (this.state.categorySelected.length != 0) {
            this.state.allFilter.push({
              title: ` دسته بندی های (${this.state.categoryNameSelected})`,
              id: "Category",
            });
            this.setState({ disabledCategory: true });
            this.state.categorySelected = [];
            this.forceUpdate();
          }
          if (
            this.state.priceValueShowStart != "" ||
            this.state.priceValueShowEnd != ""
          ) {
            this.state.allFilter.push({
              title: `محدوده قیمت (از ${this.state.priceValueShowStart} تا ${this.state.priceValueShowEnd})`,
              id: "Price",
            });
            this.setState({ priceValueShowEnd: "" });
            this.setState({ priceValueShowStart: "" });
            this.setState({ disabledPrice: true });
            this.forceUpdate();
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  deleteFilterClick = (index, id) => {
    this.state.allFilter.splice(index, 1);
    this.state.minReleaseTime = "";
    this.state.maxReleaseTime = "";
    this.state.paginateUrl = `/product?isPaginated=1&minReleaseTime=${this.state.minReleaseTime}&maxReleaseTime=${this.state.maxReleaseTime}&productType=${this.state.typeProduct}&available_status=${this.state.InventoryProduct}`;
    axios
      .get(
        `/product?isPaginated=1&minReleaseTime=${this.state.minReleaseTime}&maxReleaseTime=${this.state.maxReleaseTime}&productType=${this.state.typeProduct}&available_status=${this.state.InventoryProduct}`
      )
      .then((res) => {
        // if (res.data.status === 0) {
        //   this.props.dispatch(ShowAlert(true, res.data.message));
        // }
        if (res.data.status) {
          // this.props.dispatch(ShowAlert(true, res.data.message));
          const data = res.data.data;
          this.setState({ allProductData: data });
          this.setState({ from: res.data.meta.from });
          this.setState({ currentpage: res.data.meta.current_page });
          this.setState({ lastPage: res.data.meta.last_page });
          if (id === "date") {
            this.setState({ startDateInput: true });
            this.setState({ typeProductShow: "" });
            this.setState({ dateSituation: false });
          }
          if (id === "Type") {
            let variableProducts = document.getElementById("variableProducts");
            variableProducts.disabled = false;

            let simpleProducts = document.getElementById("simpleProducts");
            simpleProducts.disabled = false;
          }
          if (id === "Inventory") {
            let AvailableProducts =
              document.getElementById("AvailableProducts");
            AvailableProducts.disabled = false;

            let NotAvailableProducts = document.getElementById(
              "NotAvailableProducts"
            );
            NotAvailableProducts.disabled = false;
            let ManageProducts = document.getElementById("ManageProducts");
            ManageProducts.disabled = false;
          }
          if (id === "Category") {
            this.state.categoryNameSelected = [];
            this.state.categorySelected = [];
            this.setState({ disabledCategory: false });
          }
          if (id === "Price") {
            this.setState({ disabledPrice: false });
          }

          this.forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  searchHandelChange = (e) => {
    this.state.search = e.target.value;
    axios
      .get(`/product?isPaginated=1&page&search=${this.state.search}`)
      .then((res) => {
        if (res.data.status) {
          const data = res.data.data;
          this.setState({ allProductData: data });
          this.setState({ from: res.data.meta.from });
          this.setState({ currentpage: res.data.meta.current_page });
          this.setState({ lastPage: res.data.meta.last_page });
          this.setState({
            paginateUrl: `/product?isPaginated=1&page&search=${this.state.search}`,
          });
          // this.scrollTitleRef.current.scrollLeft = 0
          // this.scrollContentRef.current.scrollLeft = 0
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  // Delete Product
  garbageClick = () => {
    if (this.state.tableChecked.length > 0) {
      let checkedArray = JSON.stringify(this.state.tableChecked);
      axios
        .delete(
          `/productPrice/throwingGarbage?productPrice_ids=${checkedArray}`
        )
        .then((res) => {
          if (res.data.status === 0) {
            this.props.dispatch(ShowAlert(true, res.data.message));
          }
          if (res.data.status) {
            this.props.dispatch(ShowAlert(true, res.data.message));
            this.setState({ tableCheckBoxAll: false });

            axios
              .get(
                `/product?isPaginated=1&page=${
                  this.state.allProductData.length ==
                  this.state.tableChecked.length
                    ? this.state.currentpage - 1
                    : this.state.currentpage
                }`
              )
              .then((res) => {
                if (res.data.status) {
                  this.setState({
                    paginateUrl: `/product?isPaginated=1&page=${
                      this.state.allProductData.length ==
                      this.state.tableChecked.length
                        ? this.state.currentpage - 1
                        : this.state.currentpage
                    }`,
                  });
                  this.props.dispatch(PaginateData(res.data));
                  console.log(res);

                  this.state.tableCheckBoxAll = false;
                  this.state.allProductData.map((item, index) => {
                    this.state.tableChecked.splice(index);
                  });

                  const data = res.data.data;
                  this.setState({ allProductData: data });
                  this.setState({ from: res.data.meta.from });
                  this.setState({ currentpage: res.data.meta.current_page });
                  this.setState({ lastPage: res.data.meta.last_page });
                  this.forceUpdate();
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      this.props.dispatch(ShowAlert(true, "لطفا محصولات را انتخاب کنید ."));
    }
  };
  // All CheckBox
  TableAllCheckBoxClick = () => {
    if (this.state.allProductData.length > this.state.tableChecked.length) {
      this.setState({ tableCheckBoxAll: true });

      this.state.allProductData.map((item, index) => {
        if (this.state.tableChecked.includes(item.productPrice_id) === false) {
          this.state.tableChecked.push(item.productPrice_id);
        }
      });
    } else if (
      this.state.allProductData.length === this.state.tableChecked.length
    ) {
      this.setState({ tableCheckBoxAll: false });
      this.state.allProductData.map((item, index) => {
        this.state.tableChecked.splice(index);
      });
    }
    this.forceUpdate();
    console.log(this.state.tableChecked);
  };

  // check boxes
  TableCheckBoxClick = (id, index) => {
    if (this.state.tableChecked.includes(id) == false) {
      this.state.tableChecked.push(id);
      this.forceUpdate();
    } else {
      this.state.tableChecked.map((i) => {
        if (i === id) {
          let indexof = this.state.tableChecked.indexOf(i);

          this.state.tableChecked.splice(indexof, 1);
        }
      });
    }
    if (this.state.allProductData.length === this.state.tableChecked.length) {
      this.setState({ tableCheckBoxAll: true });
    }

    this.forceUpdate();
  };

  showFilterClick = () => {
    this.setState({ showFilter: !this.state.showFilter });
    if (this.state.showFilter === true) {
      this.state.showFilterButton = "نمایش فیلترها";
    } else if (this.state.showFilter === false) {
      this.state.showFilterButton = "بستن فیلترها";
    }
    this.forceUpdate();
  };
  minimizeFilterBoxClick = () => {
    this.setState({ minimizeFilterBox: !this.state.minimizeFilterBox });
  };
  dateFilter = () => {
    this.setState({ whichFilter: "Date" });
  };
  typeProductClick = () => {
    this.setState({ whichFilter: "TypeProduct" });
  };
  priceRangeClick = () => {
    this.setState({ whichFilter: "PriceRange" });
  };
  avalableProductClick = () => {
    this.setState({ whichFilter: "InventoryProduct" });
  };
  categoryProductClick = () => {
    this.setState({ whichFilter: "CategoryProduct" });
    axios
      .get("/productCategory?isPaginated=0")
      .then((res) => {
        if (res.data.status === 0) {
          // this.props.dispatch(ShowAlert(true, res.data.message));
        }
        if (res.data.status) {
          // this.props.dispatch(ShowAlert(true, res.data.message));

          this.setState({ categoryFilterData: res.data.data });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  CheckBoxClick = (id, title) => {
    if (this.state.categorySelected.includes(id)) {
      this.state.categorySelected.splice(
        this.state.categorySelected.indexOf(id),
        1
      );
      this.state.categoryNameSelected.splice(
        this.state.categorySelected.indexOf(id),
        1
      );
      this.forceUpdate();
    } else {
      this.state.categorySelected.push(id);
      this.state.categoryNameSelected.push(title);
      this.forceUpdate();
    }
  };
  backMainFilter = () => {
    this.setState({ whichFilter: null });
  };
  startDate = () => {
    this.state.datestart = !this.state.datestart;
    this.state.dateend = false;
    this.forceUpdate();
  };
  endDate = () => {
    this.state.dateend = !this.state.dateend;
    this.state.datestart = false;
    this.forceUpdate();
  };
  variablePriceOnchange = () => {
    this.state.typeProduct = "variableProducts";
    this.state.typeProductShow = "متغیر";
    this.forceUpdate();
  };
  simpleProceOnchange = () => {
    this.state.typeProduct = "simpleProducts";
    this.state.typeProductShow = "ساده";
    this.forceUpdate();
  };
  InventoryProductAvalable = () => {
    this.state.InventoryProduct = "available-products";
    this.state.InventoryProductShow = "موجود";

    this.forceUpdate();
  };
  InventoryProductNotAvalable = () => {
    this.state.InventoryProduct = "not-available-products";
    this.state.InventoryProductShow = "ناموجود";

    this.forceUpdate();
  };
  InventoryProductManage = () => {
    this.state.InventoryProduct = "management-available";
    this.state.InventoryProductShow = "مدیریت";

    this.forceUpdate();
  };
  handlePriceChange = (event, newValue, activeThumb) => {
    if (activeThumb === 0) {
      this.setState({ priceValueShowStart: newValue[0] * 350000 });
    } else if (activeThumb === 1) {
      this.setState({ priceValueShowEnd: newValue[1] * 350000 });
    }
  };
  navigateToMakeProduct = () => {
    this.setState({ makeProduct: true });
    this.forceUpdate();
  };

  componentDidMount() {
    // set scroll position title and content for together
    this.scrollTitleRef.current.addEventListener(
      "scroll",
      (e) => {
        this.scrollContentRef.current.scrollLeft =
          this.scrollTitleRef.current.scrollLeft;
      },
      true
    );

    this.scrollContentRef.current.addEventListener(
      "scroll",
      (e) => {
        this.scrollTitleRef.current.scrollLeft =
          this.scrollContentRef.current.scrollLeft;
      },
      true
    );
  }

  render() {
    return (
      <div className="AllProductAll">
        <div className="AllProductTopBar">
          <TopBar />
        </div>
        <div className="AllProductButtonAndSearchBox">
          <div className="AllProductButtonAndSearchBoxButtonsBox">
            <button
              onClick={this.showFilterClick.bind(this)}
              className="AllProductTopShowFilterButton"
            >
              <img src={FilterLogo} />
              <p className="AllProductTopShowFilterButtonTittle">
                {this.state.showFilterButton}
              </p>
            </button>
            <button
              onClick={this.navigateToMakeProduct.bind(this)}
              className="AllProductTopShowMakeProductButton"
            >
              <img src={AllProductAdd} />
              {this.state.makeProduct === true && <Navigate to="/Product" />}
              <p className="AllProductTopShowMakeProductButtonTittle">
                ایجاد محصول
              </p>
            </button>
          </div>
          <div className="AllProductButtonAndSearchBoxSearchBox">
            <InputComponent
              width="337px"
              height="44px"
              type="text"
              inputType="inputWithIcon"
              iconSide="right"
              iconSrc={SearchLogo}
              backgroundColor="#f7f7f7"
              placeholder="جستجو محصول.."
              borderColor="#dcdcdc"
              onchange={this.searchHandelChange.bind(this)}
            />
          </div>
        </div>
        {/* {this.state.showProductInStore === true && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "1057px",
            }}
          >
            <ShowInStore data={this.state.showProductStoreData} />
          </div>
        )} */}
        <div
          className={
            this.state.showFilter === false
              ? "AllProductTableBox"
              : "AllProductTableBoxShowFilter"
          }
        >
          <div
            className={
              this.state.showFilter === true
                ? "AllProductTableBoxShowFilterBox"
                : "AllProductTableBoxShowFilterBoxHideFilter"
            }
          >
            <div
              className={
                this.state.minimizeFilterBox === false
                  ? "AllProductTableBoxShowFilterBoxChooseFilter"
                  : "AllProductTableBoxShowFilterBoxChooseFilterClose"
              }
            >
              <div className="AllProductTableBoxShowFilterBoxChooseFilterTiitleBox">
                <div className="AllProductTableBoxShowFilterBoxChooseFilterTiitleBox1">
                  <img
                    style={{ display: "flex", width: "20px", height: "20px" }}
                    src={showFilterIcon}
                  />
                  <p>افزودن فیلتر</p>
                </div>
                <div className="AllProductTableBoxShowFilterBoxChooseFilterTiitleBox2">
                  <img
                    onClick={this.minimizeFilterBoxClick.bind(this)}
                    className={
                      this.state.minimizeFilterBox === false
                        ? "AllProductTableBoxShowFilterBoxChooseFilterTiitleBox2CloseArrowFalse"
                        : "AllProductTableBoxShowFilterBoxChooseFilterTiitleBox2CloseArrowTrue"
                    }
                    src={AllProductFilterUpArrow}
                  />
                </div>
              </div>

              <div className="AllProductTableBoxShowFilterBoxChooseFilterBox">
                {this.state.whichFilter != null ? (
                  <button
                    onClick={this.backMainFilter.bind(this)}
                    className="AllProductTableBoxShowFilterBoxChooseFilterBack"
                  >
                    <img src={AllProductRightArrow} />
                    <p className="AllProductTableBoxShowFilterBoxChooseFilterBackTittle">
                      بازگشت
                    </p>
                  </button>
                ) : null}
                {this.state.whichFilter === null && (
                  <>
                    <button
                      onClick={this.dateFilter.bind(this)}
                      className="AllProductTableBoxShowFilterBoxChooseFilterBoxItems1"
                    >
                      <p>تاریخ انتشار</p>
                    </button>
                    <button
                      onClick={this.typeProductClick.bind(this)}
                      className="AllProductTableBoxShowFilterBoxChooseFilterBoxItems1"
                    >
                      <p> نوع محصول</p>
                    </button>
                    <button
                      onClick={this.priceRangeClick.bind(this)}
                      className="AllProductTableBoxShowFilterBoxChooseFilterBoxItems1"
                    >
                      <p> محدوده قیمت</p>
                    </button>
                    <button
                      onClick={this.avalableProductClick.bind(this)}
                      className="AllProductTableBoxShowFilterBoxChooseFilterBoxItems1"
                    >
                      <p> وضعیت موجودی</p>
                    </button>
                    <button
                      onClick={this.categoryProductClick.bind(this)}
                      className="AllProductTableBoxShowFilterBoxChooseFilterBoxItems1"
                    >
                      <p> انتخاب دسته بندی</p>
                    </button>
                  </>
                )}
                {this.state.whichFilter === "PriceRange" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      paddingBottom: "15px",
                      backgroundColor: "#f4fffd",
                    }}
                  >
                    <div className="AllProductInventoryProductTittleBox">
                      <p>محدوده قیمت</p>
                    </div>
                    <div className="AllProductPriceRangeBox">
                      <p className="AllProductPriceRangeBoxTittle">
                        قیمت ها به تومان است
                      </p>
                      <AirbnbSlider
                        style={{ width: "95%" }}
                        disabled={
                          this.state.disabledPrice === false ? false : true
                        }
                        onChange={this.handlePriceChange}
                        getAriaLabel={(index) =>
                          index === 0 ? "Minimum price" : "Maximum price"
                        }
                        defaultValue={this.state.value}
                      />
                      <div className="AllProductPriceRangeBoxShowPriceBox">
                        <div className="AllProductPriceRangeBoxShowPriceBoxFirstBox">
                          <p>از</p>
                          <p>{this.state.priceValueShowStart}</p>
                        </div>
                        <div className="AllProductPriceRangeBoxShowPriceBoxSecondBox">
                          <p>تا</p>
                          <p>{this.state.priceValueShowEnd}</p>
                        </div>
                      </div>
                    </div>
                    <button
                      id="CategoryButton"
                      // disabled={
                      //   this.state.priceValueShowStart != null ||
                      //     this.state.priceValueShowEnd != null
                      //     ? false
                      //     : true
                      // }
                      onClick={this.addDateFilter.bind(this)}
                      className={
                        "AllProductTableBoxShowFilterBoxChooseFilterBoxItems1DateFilterAddFilterButtonActive"
                      }
                    >
                      <p>اعمال فیلتر</p>
                    </button>
                  </div>
                )}
                {this.state.whichFilter === "CategoryProduct" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      paddingBottom: "15px",
                      backgroundColor: "#f4fffd",
                    }}
                  >
                    <div className="AllProductInventoryProductTittleBox">
                      <p>انتخاب دسته بندی</p>
                    </div>
                    <div className="AllProductCategoryProductBox">
                      {this.state.categoryFilterData.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="AllProductCategoryProductBoxItemBox"
                          >
                            <label
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row-reverse",
                              }}
                            >
                              <input
                                disabled={
                                  this.state.disabledCategory === true
                                    ? true
                                    : false
                                }
                                style={{ display: "none" }}
                                checked={
                                  this.state.categorySelected.includes(
                                    item.id
                                  ) === true
                                }
                                onChange={this.CheckBoxClick.bind(
                                  this,
                                  item.id,
                                  item.title
                                )}
                                value={item.id}
                                type="checkbox"
                              />

                              <p className="AllProductCategoryProductBoxItemTittle">
                                {item.title}
                              </p>
                              <img
                                style={{
                                  display: "flex",
                                  width: "20px",
                                  height: "20px",
                                }}
                                src={
                                  this.state.categorySelected.includes(
                                    item.id
                                  ) === true
                                    ? check
                                    : uncheck
                                }
                              />
                            </label>
                          </div>
                        );
                      })}
                    </div>
                    <button
                      // disabled={
                      //   this.state.categorySelected.length === 0 ? true : false
                      // }
                      onClick={this.addDateFilter.bind(this)}
                      className={
                        "AllProductTableBoxShowFilterBoxChooseFilterBoxItems1DateFilterAddFilterButtonActive"
                      }
                    >
                      <p>اعمال فیلتر</p>
                    </button>
                  </div>
                )}

                {this.state.whichFilter === "InventoryProduct" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      paddingBottom: "15px",
                      backgroundColor: "#f4fffd",
                    }}
                  >
                    <div className="AllProductInventoryProductTittleBox">
                      <p> وضعیت موجودی</p>
                    </div>
                    <div className="AllProductInventoryProductTittleBoxAvalableInputBox">
                      <label
                        htmlFor="AvailableProducts"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <div className="AllProductInventoryProductTittleBoxAvalableInputBox2">
                          <div
                            className={
                              this.state.InventoryProduct ===
                              "available-products"
                                ? "AllProductInventoryProducttTittleBoxAvalableInputActive"
                                : "AllProductInventoryProductTittleBoxAvalableInput"
                            }
                          ></div>
                        </div>
                        <p className="AllProductInventoryProductTittleBoxAvalableInpuTittle">
                          محصولات موجود
                        </p>
                      </label>
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        checked={
                          this.state.InventoryProduct === "available-products"
                        }
                        id="AvailableProducts"
                        name="InventoryProduct"
                        value="AvailableProducts"
                        onChange={this.InventoryProductAvalable.bind(this)}
                      />
                    </div>

                    <div className="AllProductInventoryProductTittleBoxNotAvalableInputBox">
                      <label
                        htmlFor="NotAvailableProducts"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <div className="AllProductInventoryProductTittleBoxNotAvalableInputBox2">
                          <div
                            className={
                              this.state.InventoryProduct ===
                              "not-available-products"
                                ? "AllProductInventoryProducttTittleBoxNotAvalableInputActive"
                                : "AllProductInventoryProductTittleBoxNotAvalableInput"
                            }
                          ></div>
                        </div>
                        <p className="AllProductInventoryProductTittleBoxNotAvalableInpuTittle">
                          محصولات ناموجود
                        </p>
                      </label>
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        checked={
                          this.state.InventoryProduct ===
                          "not-available-products"
                        }
                        id="NotAvailableProducts"
                        name="InventoryProduct"
                        value="NotAvailableProducts"
                        onChange={this.InventoryProductNotAvalable.bind(this)}
                      />
                    </div>

                    <div className="AllProductInventoryProductTittleBoxNotManageInputBox">
                      <label
                        htmlFor="ManageProducts"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <div className="AllProductInventoryProductTittleBoxManageInputBox2">
                          <div
                            className={
                              this.state.InventoryProduct ===
                              "management-available"
                                ? "AllProductInventoryProducttTittleBoxManageInputActive"
                                : "AllProductInventoryProductTittleBoxManageInput"
                            }
                          ></div>
                        </div>
                        <p className="AllProductInventoryProductTittleBoxManageInpuTittle">
                          مدیریت موجودی
                        </p>
                      </label>
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        checked={
                          this.state.InventoryProduct === "management-available"
                        }
                        id="ManageProducts"
                        name="InventoryProduct"
                        value="ManageProducts"
                        onChange={this.InventoryProductManage.bind(this)}
                      />
                    </div>

                    <button
                      // disabled={
                      //   this.state.InventoryProduct != "" ? false : true
                      // }
                      onClick={this.addDateFilter.bind(this)}
                      className={
                        "AllProductTableBoxShowFilterBoxChooseFilterBoxItems1DateFilterAddFilterButtonActive"
                      }
                    >
                      <p>اعمال فیلتر</p>
                    </button>
                  </div>
                )}

                {this.state.whichFilter === "TypeProduct" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#f4fffd",
                    }}
                  >
                    <div className="AllProductTypeProductTittleBox">
                      <p>نوع محصول</p>
                    </div>
                    <div className="AllProductTypeProductTittleBoxVariableInputBox">
                      <label
                        htmlFor="variableProducts"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <div className="AllProductTypeProductTittleBoxVariableInputBox2">
                          <div
                            className={
                              this.state.typeProduct === "variableProducts"
                                ? "AllProductTypeProductTittleBoxVariableInputActive"
                                : "AllProductTypeProductTittleBoxVariableInput"
                            }
                          ></div>
                        </div>
                        <p className="AllProductTypeProductTittleBoxVariableInpuTittle">
                          متغییر
                        </p>
                      </label>
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        checked={this.state.typeProduct === "variableProducts"}
                        id="variableProducts"
                        name="type_product"
                        value="variableProducts"
                        onChange={this.variablePriceOnchange.bind(this)}
                      />
                    </div>
                    <div className="AllProductTypeProductTittleBoxSimpleInputBox">
                      <label
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                        htmlFor="simpleProducts"
                      >
                        <div className="AllProductTypeProductTittleBoxSimpleInputBox2">
                          <div
                            className={
                              this.state.typeProduct === "simpleProducts"
                                ? "AllProductTypeProductTittleBoxSimpleInputActive"
                                : "AllProductTypeProductTittleBoxSimpleInput"
                            }
                          ></div>
                        </div>
                        <p className="AllProductTypeProductTittleBoxSimpleInputTittle">
                          ساده
                        </p>
                      </label>
                      <input
                        style={{ display: "none" }}
                        type="radio"
                        checked={this.state.typeProduct === "simpleProducts"}
                        id="simpleProducts"
                        name="type_product"
                        value="simpleProducts"
                        onChange={this.simpleProceOnchange.bind(this)}
                      />
                    </div>

                    <button
                      onClick={this.addDateFilter.bind(this)}
                      className={
                        "AllProductTableBoxShowFilterBoxChooseFilterBoxItems1DateFilterAddFilterButtonActive"
                      }
                    >
                      <p>اعمال فیلتر</p>
                    </button>
                  </div>
                )}
                {this.state.whichFilter === "Date" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#f4fffd",
                    }}
                  >
                    <div className="AllProductInventoryProductTittleBox">
                      <p>تاریخ انتشار</p>
                    </div>
                    <div
                      onClick={
                        this.state.startDateInput === true
                          ? this.startDate.bind(this)
                          : null
                      }
                      className={
                        this.state.startDateInput === true
                          ? "AllProductTableBoxShowFilterBoxChooseFilterBoxItems1DateFilterInput1"
                          : "AllProductTableBoxShowFilterBoxChooseFilterBoxItems1DateFilterInput1Disable"
                      }
                    >
                      <p className="AllProductTableBoxShowFilterBoxChooseFilterBoxItems1DateFilterInput1Tittle">
                        {this.state.minReleaseTime != ""
                          ? this.state.minReleaseTime
                          : "از"}
                      </p>
                      <button className="AllProductTableBoxShowFilterBoxChooseFilterBoxItems1DateFilterInput1Icon">
                        <img src={AllProductCalender} />
                      </button>
                    </div>
                    <div
                      onClick={
                        this.state.endDateInput === true
                          ? this.endDate.bind(this)
                          : null
                      }
                      className={
                        this.state.endDateInput === true
                          ? "AllProductTableBoxShowFilterBoxChooseFilterBoxItems1DateFilterInput1"
                          : "AllProductTableBoxShowFilterBoxChooseFilterBoxItems1DateFilterInput1Disable"
                      }
                    >
                      <p className="AllProductTableBoxShowFilterBoxChooseFilterBoxItems1DateFilterInput1Tittle">
                        {this.state.maxReleaseTime != ""
                          ? this.state.maxReleaseTime
                          : "تا"}
                      </p>
                      <button
                        onClick={this.endDate.bind(this)}
                        dateend
                        className="AllProductTableBoxShowFilterBoxChooseFilterBoxItems1DateFilterInput1Icon"
                      >
                        <img src={AllProductCalender} />
                      </button>
                    </div>
                    {this.state.datestart === true && (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          marginTop: "20px",
                          minHeight: "50px",
                          paddingBottom: "20px",
                        }}
                      >
                        <Calendar
                          isGregorian={this.state.isGregorian}
                          onChange={(value) => {
                            let date = moment(value._d, "YYYY/MM/DD")
                              .locale("fa")
                              .format("YYYY/MM/DD");

                            this.state.minReleaseTime = date;
                            this.state.minShowDate = date;
                            this.state.datestart = false;
                            this.state.minRange = value._d;
                            this.state.endDateInput = true;
                            this.state.dateSituation = true;
                            this.forceUpdate();
                          }}
                        />
                      </div>
                    )}

                    {this.state.dateend === true && (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          marginTop: "20px",
                          minHeight: "50px",
                          paddingBottom: "20px",
                        }}
                      >
                        <Calendar
                          min={this.state.minRange}
                          isGregorian={this.state.isGregorian}
                          onChange={(value) => {
                            let date = moment(value._d, "YYYY/MM/DD")
                              .locale("fa")
                              .format("YYYY/MM/DD");
                            this.state.maxShowDate = date;
                            this.setState({ maxReleaseTime: date });
                            this.setState({ dateend: false });
                          }}
                        />
                      </div>
                    )}
                    <button
                      onClick={this.addDateFilter.bind(this)}
                      className={
                        "AllProductTableBoxShowFilterBoxChooseFilterBoxItems1DateFilterAddFilterButtonActive"
                      }
                    >
                      <p>اعمال فیلتر</p>
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="AllProductTableBoxShowFilterBoxShowWichFilter">
              {this.state.allFilter.map((item, index) => {
                return (
                  <div className="AllProductTableBoxShowFilterBoxShowWichFilterBox">
                    <p className="AllProductTableBoxShowFilterBoxShowWichFilterBoxTittle">
                      {item.title}
                    </p>
                    <button
                      onClick={this.deleteFilterClick.bind(
                        this,
                        index,
                        item.id
                      )}
                      className="AllProductTableBoxShowFilterBoxShowWichFilterBoxButton"
                    >
                      <img src={AllProductDeleteFilter} />
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className={
              this.state.showFilter === false
                ? "AllProductTableBox2"
                : "AllProductTableBox2ShowFilter"
            }
          >
            {/* <Table
              tabBar={true}
              tabs={productsTab}
              garbage
             
            /> */}
            <SimpleBar
              forceVisible="x"
              // scrollableNodeProps={{ ref: this.scrollTitleRef }}
              style={{
                width: "calc(100% - 3px)",
                borderLeft: "solid 1px #dcdcdc",
                borderRight: "solid 1px #dcdcdc",
                borderBottom: "solid 1px #dcdcdc",
                borderRadius: "10px 10px 0 0",
                cursor: "grab",
              }}
            >
              <ScrollDrag innerRef={this.scrollTitleRef}>
                <div className={"all-products-header dragscroll"}>
                  <div className="all-products-tab-bar">
                    <div className="all-products-tab-bar-right">
                      <p
                        style={
                          window.location.pathname == "/AllProduct"
                            ? {
                                color: "#00457b",
                                fontWeight: "bold",
                              }
                            : {}
                        }
                      >
                        {this.state.goToReleasedProducts && (
                          <Navigate to="/AllProduct" />
                        )}
                        محصولات منتشر شده
                      </p>

                      <p
                        onClick={() => {
                          this.setState({ goToDraftsProducts: true });
                          this.forceUpdate();
                        }}
                        style={
                          window.location.pathname == "/AllDraftProduct"
                            ? {
                                color: "#00457b",
                                fontWeight: "bold",
                              }
                            : {}
                        }
                      >
                        {this.state.goToDraftsProducts == true && (
                          <Navigate to="/AllDraftProduct" />
                        )}
                        محصولات پیش نویس
                      </p>

                      <p
                        style={
                          window.location.pathname == "/GarbageProduct"
                            ? {
                                color: "#00457b",
                                fontWeight: "bold",
                              }
                            : {}
                        }
                        onClick={() => {
                          this.setState({ goToTrash: true });
                          this.forceUpdate();
                        }}
                      >
                        {this.state.goToTrash && (
                          <Navigate to="/GarbageProduct" />
                        )}
                        زباله دان
                      </p>
                    </div>

                    <div className="all-products-tab-bar-left">
                      <button
                        style={
                          this.state.tableChecked.length > 0
                            ? { backgroundColor: "#f23b3b" }
                            : {}
                        }
                        onClick={this.garbageClick.bind(this)}
                        className="AllProductTopShowTrashButton"
                      >
                        {this.state.tableChecked.length > 0 ? (
                          <img src={AllProductTrash} />
                        ) : (
                          <img src={AllProductTrashRed} />
                        )}
                        <p
                          style={
                            this.state.tableChecked.length > 0
                              ? { color: "#fff" }
                              : {}
                          }
                          className="AllProductTopShowTrashButtonTittle"
                        >
                          زباله ‌دان
                        </p>
                      </button>
                    </div>
                  </div>

                  <div className={"AllProductTableBoxTopBar"}>
                    <div className="AllProductTableBoxTopBarHashtagBox">
                      <p>#</p>
                    </div>

                    <div className="AllProductTableBoxTopBarCheckBox">
                      <img
                        onClick={this.TableAllCheckBoxClick.bind(this)}
                        src={
                          this.state.tableCheckBoxAll === false
                            ? uncheck
                            : check
                        }
                      />
                    </div>

                    <div className="AllProductTableBoxTopBarImageBox">
                      <p>تصویر</p>
                    </div>

                    <div className="AllProductTableBoxTopBarNameBox">
                      <p>نام</p>
                    </div>

                    <div className="AllProductTableBoxTopBarBarcodeBox">
                      <p>شناسه</p>
                    </div>

                    <div className="AllProductTableBoxTopBarPriceBox">
                      <p>قیمت (تومان)</p>
                    </div>

                    <div className="AllProductTableBoxTopBarDiscontBox">
                      <p>تخفیف (تومان)</p>
                    </div>

                    <div className="AllProductTableBoxTopBarInventoryBox">
                      <p>موجودی</p>
                    </div>

                    <div className="AllProductTableBoxTopBarCategoryBox">
                      <p>دسته بندی</p>
                    </div>

                    <div className="AllProductTableBoxTopBarReleaseStatusBox">
                      <p>وضعیت انتشار</p>
                    </div>

                    <div className="AllProductTableBoxTopBaroperationsBox">
                      <p>عملیات</p>
                    </div>
                  </div>
                </div>
              </ScrollDrag>
            </SimpleBar>

            <SimpleBar
              forceVisible="x"
              // scrollableNodeProps={{ ref: this.scrollContentRef }}
              style={{
                width: "calc(100% - 3px)",
                borderLeft: "solid 1px #dcdcdc",
                borderRight: "solid 1px #dcdcdc",
                borderBottom: "solid 1px #dcdcdc",
                borderRadius: "0 0 10px 10px",
              }}
            >
              <ScrollDrag
                className="scroll-container"
                innerRef={this.scrollContentRef}
              >
                {this.state.allProductData.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        borderBottom:
                          this.state.allProductData.length != index + 1
                            ? "solid 1px #dcdcdc"
                            : "",
                        borderRadius:
                          this.state.allProductData.length == index + 1
                            ? "0 0 10px 10px"
                            : "",
                      }}
                      className="AllProductTableItems"
                    >
                      <div className="AllProductTableItemsNumber">
                        <p>{index + this.state.from}</p>
                      </div>
                      <div className="AllProductTableItemsCheckBox">
                        <label
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <input
                            style={{ display: "none" }}
                            checked={
                              this.state.tableChecked.includes(
                                item.productPrice_id
                              ) === true
                            }
                            onChange={this.TableCheckBoxClick.bind(
                              this,
                              item.productPrice_id,
                              index
                            )}
                            value={item.productPrice_id}
                            type="checkbox"
                          />
                          <img
                            style={{
                              display: "flex",
                            }}
                            src={
                              this.state.tableChecked.includes(
                                item.productPrice_id
                              ) === true
                                ? check
                                : uncheck
                            }
                          />
                        </label>
                      </div>
                      <div className="AllProductTableItemsImage">
                        <span>
                          <img
                            src={item.product_image}
                            className="AllProductTableItemsImageImage"
                          />
                        </span>
                      </div>
                      <div className="AllProductTableItemsName">
                        <p>{item.product}</p>
                      </div>

                      <div className="AllProductTableItemsBarcode">
                        <p>{item.barcode}</p>
                      </div>
                      <div className="AllProductTableItemsPrice">
                        <p>{item.price}</p>
                      </div>
                      <div className="AllProductTableItemsDiscont">
                        <p>{item.discount}</p>
                      </div>
                      <div
                        className={
                          item.available_type === "ناموجود"
                            ? "AllProductTableItemsInventory"
                            : "AllProductTableItemsInventory2"
                        }
                      >
                        <p>{item.available_type}</p>
                      </div>
                      <div className="AllProductTableItemsCategory">
                        <p>{item.product_category}</p>
                      </div>
                      <div className="AllProductTableItemsReleaseStatus">
                        <div className="AllProductTableItemsReleaseStatusDateBox">
                          <p>{item.releaseTime}</p>
                        </div>
                      </div>

                      <div className="AllProductTableItemsOperation">
                        <button className="AllProductTableItemsOperationShow">
                          <img src={ActiveEye} />
                        </button>

                        <button
                          onClick={this.showProductInStoreClick.bind(
                            this,
                            item.product_id
                          )}
                          className="AllProductTableItemsOperationShow"
                        >
                          {this.state.showProductINstore === true && (
                            <Navigate to="/AllProductUpdate" />
                          )}

                          <img src={EditActive} />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </ScrollDrag>
            </SimpleBar>
            <div className="AllProductPaginatedBox">
              <Pagination
                firstAxios={this.state.paginateUrl}
                currentAxios="/product?isPaginated=1&page="
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    PaginateData: state.PaginatedData,
  };
};

export default connect(mapStateToProps)(AllProduct);
