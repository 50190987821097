import update from "react-addons-update";
const iniState = {
  isProductEdit: 0,
  productProperties: [],
};
const SendPropertyValueIdReducer = (state = iniState, action) => {
  switch (action.type) {
    case "SEND_PROPERTY_NAME":
      const newProperties = update(state, {
        productProperties: { $set: action.propertyName },
      });
      state = newProperties;
      return state;

    case "SEND_PRODUCT_PROPERTY_VALUE_ID":
      
      const newValue = update(state, {
        productProperties: {
          [action.index]: {
            property_value_id: { $set: action.value_id },
          },
        },
      });
      state = newValue;
      return state;

    case "SEND_PRODUCT_PROPERTY_ISKEY":
      const newisKey = update(state, {
        productProperties: {
          [action.index]: {
            is_key: { $set: action.toggle },
          },
        },
      });
      state = newisKey;
      return state;
    case "DELETE_PRODUCT_PROPERTY_VALUE_ID": 
      
    
    const deleteProperties = update(state, {
      productProperties: { $set: action.propertyShow },
    });
    state = deleteProperties;
    return state;
    default:
      return state;
  }
};
export default SendPropertyValueIdReducer;
