import AddPropertNameReducer from "./AddPropertyName";
import SelectPropertyReducer from "./SelectProperty";
import AddTagReducer from "./AddTag";
import AddAdminInfoReducer from "./AddAdminInfo";
import LogInReducer from "./LogIn";
import ProductInfoReducer from "./ProductInfo";
import TagIdReducer from "./TagId";
import CloseDrawerReducer from "./CloseDrawer";
import SendCategoryIdReducer from "./SendCategoryId";
import SendPropertyValueIdReducer from "./SendPropertyInfoReducer";
import SendSeoInfoReducer from "./SendSeoInfo";
import SendSeoQuestionAnswerReducer from "./SendSeoQuestionAnswerReducer";
import SendProductReviewReducer from "./SendProductReviewReducer";
import SendProductGalleryInfoReducer from "./SendProductGalleryInfoReducer";
import SendSimpleProductPriceReducer from "./SendSimpleProductPriceReducer";
import SendVariableProductPriceReducer from "./SendVariableProductPriceReducer";
import ProductPriceTypeReducer from "./ProductPriceTypeReducer";
import PaginatedDataReducer from "./PaginateDataReducer";
import UpdateAllProductIdsReducer from "./UpdateAllProductIdsReducer";
import ProductInfoForEditReducer from "./ProductInfoForEditReducer";
import SendCategoryNameIdReducer from "./SendCategoryNameIdReducer";
import BlogInfoReducer from "./BlogReducer/BlogInfoReducer";
import BlogSeoInfoReducer from "./BlogReducer/BlogSeoInfoReducer";
import BlogSeoFaqReducer from "./BlogReducer/BlogSeoFaqReducer";
import ShowAlertReducer from "./ShowAlertReducer";
import BlogEditReducer from "./BlogReducer/BlogEditReducer";
import UserFilterReducer from "./UserFilterReducer";
import AxiosNameDiscountReducer from "./Discount/AxiosNameDiscountReducer";
import SendCoupenForEditReducer from "./Discount/SendCoupenForEditReducer";
import SendOrderIdReducer from "./Orders/SendOrderIdReducer";
import SendOrderEditReducer from "./Orders/SendOrderEditReducer";
import SendIdForEditReducer from "./Orders/SendIdForEditReducer";
import ErrorReducer from "./ErrorReducer";
import { combineReducers } from "redux";
import ReturnedOrderEditReducer from "./ReturnedOrderEditReducer";
import TableReducer from "./Table/TableReducer";

const AllReducers = combineReducers({
  AddPropertyName: AddPropertNameReducer,
  SelectProperty: SelectPropertyReducer,
  AddTag: AddTagReducer,
  AddAdminInfo: AddAdminInfoReducer,
  LogIn: LogInReducer,
  ProductInfo: ProductInfoReducer,
  TagId: TagIdReducer,
  CloseDrawer: CloseDrawerReducer,
  SendCategoryNameId: SendCategoryNameIdReducer,
  SendCategoryId: SendCategoryIdReducer,
  SendPropertyValueId: SendPropertyValueIdReducer,
  SendSeoInfo: SendSeoInfoReducer,
  SendSeoQuestionAnswer: SendSeoQuestionAnswerReducer,
  SendProductReview: SendProductReviewReducer,
  SendProductGalleryInfo: SendProductGalleryInfoReducer,
  SendSimpleProductPrice: SendSimpleProductPriceReducer,
  SendVariableProductPrice: SendVariableProductPriceReducer,
  ProductPriceType: ProductPriceTypeReducer,
  PaginatedData: PaginatedDataReducer,
  UpdateAllProductIds: UpdateAllProductIdsReducer,
  ProductInfoForEdit: ProductInfoForEditReducer,
  ShowAlert: ShowAlertReducer,
  BlogInfo: BlogInfoReducer,
  BlogSeoInfo: BlogSeoInfoReducer,
  BlogSeoFaq: BlogSeoFaqReducer,
  BlogEdit: BlogEditReducer,
  UserFilter: UserFilterReducer,
  AxiosNameDiscount: AxiosNameDiscountReducer,
  SendCoupenForEdit: SendCoupenForEditReducer,
  SendOrderId: SendOrderIdReducer,
  SendOrderEdit: SendOrderEditReducer,
  SendIdForEdit: SendIdForEditReducer,
  Error: ErrorReducer,
  ReturnedOrderEdit: ReturnedOrderEditReducer,
  Table: TableReducer,
});
const rootReducer = (state, action) => {
  if (action.type === "RESET_TO_INIT") state = undefined;
  return AllReducers(state, action);
};
export default rootReducer;
