import React, { useState, useEffect } from "react";
import { Routes, Route, Link, Navigate, useNavigate } from "react-router-dom";
import "../assets/styles/RouterStyle/Router.css";
import Drawer from "../components/SideBar/Drawer";
import MakeProduct from "../Pages/MakeProduct/MakeProduct";
import AllProductUpdate from "../Pages/AllProduct/AllProductUpdate";
import Alert from "../components/ShowAlert/ShowAlert";
import Tags from "../Pages/Tags/Tags";
import Auth from "../Pages/Auth/LogIn/Auth";
import ForgetPassword from "../Pages/Auth/ForgetPassword/ForgetPassword";
import Register from "../Pages/Auth/Register/Register";
import SetNewPassword from "../Pages/Auth/SetNewPassword/SetNewPassword";
import SuccessNewPassword from "../Pages/Auth/SeccessNewPassword/SuccessNewPassword";
import ChooseCategory from "../Pages/Auth/ChooseCategory/ChoooseCategory";
import PhoneNumberRegister from "../Pages/Auth/PhoneNumberRegister/PhoneNumberRegister";
import VerifyCode from "../Pages/Auth/VerifyCode/VerifyCode";
import Category from "../Pages/Category/Category";
import AllProduct from "../Pages/AllProduct/AllProduct";
import GarbageProduct from "../Pages/GarbageProduct/GarbageProduct";
import ManageCustomerFeedback from "../Pages/ManageCustomerFeedback/ManageCustomerFeedback";
import ManageUser from "../Pages/ManageUser/ManageUser";
import Property from "../Pages/Property/Property";
import AllDraftProduct from "../Pages/AllDraftProduct/AllDraftProduct";
import AllDraftProductUpdate from "../Pages/AllDraftProduct/AllDraftProductUpdate";
import Media from "../Pages/Media/Media";
import CreateBlog from "../Pages/Blog/CreateBlog/CreateBlog";
import EditBlog from "../Pages/Blog/EditBlog/EditBlog";
import ManageBlog from "../Pages/Blog/ManageBlog/ManageBlog";
import CommentBlog from "../Pages/Blog/CommentBlog/CommentBlog";
import GarbageBlog from "../Pages/Blog/GarbageBlog/GarbageBlog";
import GarbageBlogComment from "../Pages/Blog/GarbageBlogComment/GarbageBlogComment";
import GarbageCommentProduct from "../Pages/GarbageProduct/GarbageCommentProduct";
import Customers from "../Pages/Customers/Customers";
import Messages from "../Pages/Messages/Messages";
import CreateDiscount from "../Pages/Discount/CreateDiscount/CreateDiscount";
import UpdateDiscount from "../Pages/Discount/CreateDiscount/UpdateDiscount";
import ManageDiscount from "../Pages/Discount/ManageDiscount/ManageDiscount";
import InstanceDiscount from "../Pages/InstanceChangeDiscountOrPrice/InstanceDiscount/InstanceChange";
import FrequentlyQuestion from "../Pages/FrequentlyQuestion/FrequentlyQuestion";
import ContactVitsell from "../Pages/ContactVitsell/ContactVitsell";
import Tutorial from "../Pages/Tutorial/Tutorial";
import SmsSystem from "../Pages/SmsStystem/SmsSystem";
import SmsManage from "../Pages/SmsStystem/SmsManage";
import ContactUs from "../Pages/ContactUs/ContactUs";
import AboutUs from "../Pages/AboutUs/AboutUs";
import EditStore from "../Pages/EditStore/EditStore";
import VisitorStatistics from "../Pages/VisitorStatistics/VisitorStatistics";
import SalesReport from "../Pages/VisitorStatistics/SalesReport";
import Delivery from "../Pages/Delivery/Delivery";
import Order from "../Pages/Order/Order";
import { useSelector, useDispatch } from "react-redux";
import { CloseDrawerAction } from "../action/index";
import BlogCategory from "../Pages/Blog/BlogCategory/BlogCategory";
import BlogTag from "../Pages/Blog/BlogTag/BlogTag";
import CreateOrder from "../Pages/Order/CreateOrder";
import ReturnedOrder from "../Pages/Order/ReturnedOrder";
import CreateOrderDelivery from "../Pages/Order/CreateOrderDelivery";
import EditOrder from "../Pages/Order/EditOrder";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Role from "../Pages/Role/Role";
import { useLocation } from "react-router-dom";
import Seller from "../Pages/Seller/Seller";
import MarketPlace from "../Pages/MarketPlace/MarketPlace";

function Router() {
  const location = useLocation();
  const navigate = useNavigate();
  const id = useSelector((state) => state.UpdateAllProductIds);
  const closeDrawer = useSelector((state) => state.CloseDrawer);
  const [token, setToken] = useState("");
  useEffect(() => {
    const tk = localStorage.getItem("access_token");
    setToken(tk);
  });
  useEffect(() => {
    if (
      location.pathname !== "/login" &&
      location.pathname !== "/ForgetPassword" &&
      location.pathname !== "/Register" &&
      location.pathname !== "/SetNewPassword" &&
      location.pathname !== "/SuccessNewPassword" &&
      location.pathname !== "/ChooseCategory" &&
      location.pathname !== "/PhoneNumberRegister" &&
      location.pathname !== "/VerifyCode"
    ) {
      if (localStorage.getItem("access_token") === null) {
        navigate("login");
      }
    }
  }, []);

  if (token === null) {
    return (
      <div style={{ direction: "rtl" }}>
        <Alert />
        <Routes>
          <Route path="login" element={<Auth />} />
          <Route path="ForgetPassword" element={<ForgetPassword />} />

          <Route path="Register" element={<Register />} />
          <Route path="SetNewPassword" element={<SetNewPassword />} />

          <Route path="SuccessNewPassword" element={<SuccessNewPassword />} />

          <Route path="ChooseCategory" element={<ChooseCategory />} />
          <Route path="PhoneNumberRegister" element={<PhoneNumberRegister />} />
          <Route path="VerifyCode" element={<VerifyCode />} />
        </Routes>
      </div>
    );
  } else {
    return (
      <div className="All">
        <Alert />
        <div className={closeDrawer == 1 ? "DrawerBox" : "DrawerBoxClose"}>
          <Drawer />
        </div>
        <div
          className={closeDrawer == 1 ? "DrawerPageBox" : "DrawerPageBoxClose"}
        >
          <Routes>
            <Route path="Product" element={<MakeProduct />} />
            <Route path="Seller" element={<Seller />} />
            <Route path="marketPlace" element={<MarketPlace />} />
            <Route path="Tags" element={<Tags />} />
            <Route path="Category" element={<Category />} />
            <Route path="AllProduct" element={<AllProduct />} />
            <Route path="Property" element={<Property />} />
            <Route
              path="ManageCustomerFeedback"
              element={<ManageCustomerFeedback />}
            />
            <Route path="AllProductUpdate" element={<AllProductUpdate />} />
            <Route path="AllDraftProduct" element={<AllDraftProduct />} />
            <Route
              path="AllDraftProductUpdate"
              element={<AllDraftProductUpdate />}
            />
            <Route path="GarbageProduct" element={<GarbageProduct />} />
            <Route path="ManageUser" element={<ManageUser />} />
            <Route path="Media" element={<Media />} />
            <Route path="CreateBlog" element={<CreateBlog />} />

            <Route path="BlogTag" element={<BlogTag />} />
            <Route path="BlogCategory" element={<BlogCategory />} />
            <Route path="ManageBlog" element={<ManageBlog />} />
            <Route path="EditBlog" element={<EditBlog />} />
            <Route path="CommentBlog" element={<CommentBlog />} />
            <Route path="GarbageBlog" element={<GarbageBlog />} />
            <Route path="GarbageBlogComment" element={<GarbageBlogComment />} />
            <Route
              path="GarbageCommentProduct"
              element={<GarbageCommentProduct />}
            />
            <Route path="Customers" element={<Customers />} />
            <Route path="Messages" element={<Messages />} />
            <Route path="CreateDiscount" element={<CreateDiscount />} />
            <Route path="UpdateDiscount" element={<UpdateDiscount />} />
            <Route path="ManageDiscount" element={<ManageDiscount />} />
            <Route path="InstanceDiscount" element={<InstanceDiscount />} />
            <Route path="InstanceChangePrice" element={<InstanceDiscount />} />
            <Route path="FrequentlyQuestion" element={<FrequentlyQuestion />} />
            <Route path="Tutorial" element={<Tutorial />} />
            <Route path="ContactVitsell" element={<ContactVitsell />} />
            <Route path="SmsSystem" element={<SmsSystem />} />
            <Route path="SmsManage" element={<SmsManage />} />
            <Route path="ContactUs" element={<ContactUs />} />
            <Route path="AboutUs" element={<AboutUs />} />
            <Route path="EditStore" element={<EditStore />} />
            <Route path="VisitorStatistics" element={<VisitorStatistics />} />
            <Route path="Delivery" element={<Delivery />} />
            <Route path="Order" element={<Order />} />
            <Route path="createOrder" element={<CreateOrder />} />
            <Route
              path="CreateOrderDelivery"
              element={<CreateOrderDelivery />}
            />
            <Route path="EditOrder" element={<EditOrder />} />
            <Route path="SalesReport" element={<SalesReport />} />
            <Route path="Role" element={<Role />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="ReturnedOrder" element={<ReturnedOrder />} />
          </Routes>
        </div>
      </div>
    );
  }
}
export default Router;
