import React, { useEffect, useState } from "react";
import {
  AddBlogCategory,
  SendCategoryId,
  sendCategoryNameAndId,
} from "../../action";
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import check from "../../assets/icons/check.svg";
import uncheck from "../../assets/icons/uncheck.svg";
import useForceUpdate from "use-force-update";
import Childs from "./Childs";
import { isObjectEmpty } from "../../helper/Helper";

function TreeMenu(props) {
  const ProductInfoForEdit = useSelector((state) => state.ProductInfoForEdit);
  const blogEdit = useSelector((state) => state.BlogEdit);
  let { data, childs, type } = props;
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const location = useLocation();
  const [selectedItems, setSelectedItems] = useState([]);
  const [categoryNameId, setCategoryNameId] = useState([]); // state for save category and id for sendig to redux
  const [blogCategorySelected, setBlogCategorySelected] = useState([]);

  useEffect(() => {
    if (!isObjectEmpty(blogEdit) && type == "blog") {
      if (location.pathname === "/EditBlog") {
        data.map((dItem) => {
          blogEdit.blogCategories.map((item) => {
            console.log("test");
            if (dItem.id == item.id) {
              selectedItems.push(item.id);
              dispatch(AddBlogCategory(item.id));
              forceUpdate();
            }
          });
        });
      }
    }
  }, [blogEdit, data]);

  useEffect(() => {
    if (
      isObjectEmpty(ProductInfoForEdit) === false &&
      (location.pathname === "/AllProductUpdate" ||
        location.pathname === "/AllDraftProductUpdate") &&
      type == "product"
    ) {
      // if (data.length != 0) {
        console.log(data);
      ProductInfoForEdit.state.productCategories.map((item) => {
        data.map((dItem) => {
          if (dItem.id == item.id) {
            selectedItems.push(item.id);
            categoryNameId.push({
              title: item.title,
              id: item.id,
            });
            dispatch(sendCategoryNameAndId(categoryNameId));
            dispatch(SendCategoryId(item.id));
            forceUpdate();
          }
        });
      });
    }
    // }
  }, [ProductInfoForEdit, data]);

  const checkBoxHandel = (e, myindex, id, title) => {
    if (selectedItems.includes(id)) {
      selectedItems.splice(selectedItems.indexOf(id), 1);
      if (type == "product") {
        console.log("tree",selectedItems);
        // dispatch(SendCategoryId(selectedItems));
        dispatch(SendCategoryId(id));
      } else if (type == "blog") {
        console.log("blog" + " " + id);

        dispatch(AddBlogCategory(id));
      }
      forceUpdate();
    } else {
      selectedItems.push(id);
      if (type == "product") {
        console.log("tree", selectedItems);
        dispatch(SendCategoryId(id));
      } else if (type == "blog") {
        console.log("blog" + " " + id);
        dispatch(AddBlogCategory(id));
      }
      forceUpdate();
    }

    if (type == "product") {
      if (categoryNameId.length === 0) {
        categoryNameId.push({
          title: title,
          id: id,
        });
        forceUpdate();
        dispatch(sendCategoryNameAndId(categoryNameId));
      } else if (categoryNameId.some((i) => i.id === id)) {
        categoryNameId.splice(
          categoryNameId.findIndex((item) => item.id === id),
          1
        );
        dispatch(sendCategoryNameAndId(categoryNameId));
      } else {
        categoryNameId.push({
          title: title,
          id: id,
        });
        dispatch(sendCategoryNameAndId(categoryNameId));
        forceUpdate();
      }
    }
  };

  return (
    <>
      {data != undefined &&
        data.map((myitem, myindex) => {
          return (
            <>
              <div
                style={{ marginTop: "15px" }}
                key={myindex}
                className="CategoryCategoryCheckBoxItemBox"
              >
                <label
                  htmlFor={`parent_id${myitem.id}`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row-reverse",
                  }}
                >
                  <input
                    style={{ display: "none" }}
                    checked={selectedItems.includes(myitem.id) === true}
                    onChange={(e) =>
                      checkBoxHandel(e, myindex, myitem.id, myitem.title)
                    }
                    value={myitem.id}
                    id={`parent_id${myitem.id}`}
                    name="category"
                    type="checkbox"
                  />

                  <p className="CategoryCategoryCheckBoxItemTittle">
                    {myitem.title}
                  </p>
                  <img
                    src={
                      selectedItems.includes(myitem.id) === true
                        ? check
                        : uncheck
                    }
                    className="CategoryChooseCategoryCheckBoxPure"
                  />
                </label>
              </div>

              <div style={{ marginRight: `${(myindex + 1) * 15}px` }}>
                <Childs
                  index={myindex}
                  childs={childs}
                  type={type}
                  data={myitem[childs]}
                />
              </div>
            </>
          );
        })}
    </>
  );
}

export default TreeMenu;
