import React, { useEffect, useState } from "react";
import "../../assets/styles/VisitorStatisticsStyle/VisitorStatisticsSignUp.css";
import axios from "../../js/Axios/Axios";
export default function VisitorStatisticsSignUp() {
  const [info, setInfo] = useState(null);
  const [month, setMonth] = useState("");
  useEffect(() => {
    axios
      .get("/visitInfo/numberVisitsReport?")
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          setInfo(res.data.data);
          if (res.data.data.most_visit_month === 1) {
            setMonth("فروردین");
          } else if (res.data.data.most_visit_month === 2) {
            setMonth("اردیبهشت");
          } else if (res.data.data.most_visit_month === 3) {
            setMonth("خرداد");
          } else if (res.data.data.most_visit_month === 4) {
            setMonth("تیر");
          } else if (res.data.data.most_visit_month === 5) {
            setMonth("مرداد");
          } else if (res.data.data.most_visit_month === 6) {
            setMonth("شهریور");
          } else if (res.data.data.most_visit_month === 7) {
            setMonth("مهر");
          } else if (res.data.data.most_visit_month === 8) {
            setMonth("آبان");
          } else if (res.data.data.most_visit_month === 9) {
            setMonth("آذر");
          } else if (res.data.data.most_visit_month === 10) {
            setMonth("دی");
          } else if (res.data.data.most_visit_month === 11) {
            setMonth("بهمن");
          } else if (res.data.data.most_visit_month === 12) {
            setMonth("اسفند");
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div className="visitor-statistics-signup-all-box">
      <div className="visitor-statistics-signup-all-box-1">
        <p> تعداد افراد ثبت نام کرده :</p>
        <p>پربازدیدترین ماه :</p>
        <p> پربازدیدترین روز :</p>
        <p> میانگین تعداد بازدید در روز :</p>
        <p> میانگین تعداد بازدید در ماه :</p>
      </div>
      {info != null && (
        <div className="visitor-statistics-signup-all-box-2">
          <div className="visitor-statistics-signup-all-box-3">
            <p>{info.visit_count}</p>
            <p className="visitor-statistics-signup-all-box-3-title">(نفر)</p>
          </div>
          <div className="visitor-statistics-signup-all-box-3">
            <p>{month}</p>
            {/* <p>{() => witchMonth(info.most_visit_month)}</p> */}
          </div>
          <div className="visitor-statistics-signup-all-box-3">
            <p>{info.most_visit_day}</p>
          </div>
          <div className="visitor-statistics-signup-all-box-3">
            <p>{info.average_visit_day}</p>
            <p className="visitor-statistics-signup-all-box-3-title">(نفر)</p>
          </div>
          <div className="visitor-statistics-signup-all-box-3">
            <p>{info.average_visit_month}</p>
            <p className="visitor-statistics-signup-all-box-3-title">(نفر)</p>
          </div>
        </div>
      )}
    </div>
  );
}
