import React, { useEffect, useState } from "react";
import "../../assets/styles/MediaStyle/Media.css";
import axios from "../../js/Axios/Axios";
import { useSelector, useDispatch } from "react-redux";
import { ShowAlert } from "../../action/index";
import Modal from "@mui/material/Modal";
import useForceUpdate from "use-force-update";
import Pagination from "../../components/Pagination/Pagination";
import TopBar from "../../components/TopBar/TopBar";
import AllProductAdd from "../../assets/icons/AllProductAdd.svg";
import DropDownComponent from "../../components/DropDown/DropDown";
import SearchLogo from "../../assets/icons/SearchLogo.svg";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import Edit from "../../assets/icons/Edit.svg";
import EditActive from "../../assets/icons/EditActive.svg";
import EyeActive from "../../assets/icons/AllProductEyeActive.svg";
import Delete from "../../assets/icons/Delete.svg";
import DeleteRed from "../../assets/icons/DeleteRed.svg";
import cross from "../../assets/icons/cross.svg";
import AllProductTrash from "../../assets/icons/GarbageDelte.svg";
import AllProductTrashRed from "../../assets/icons/GarbageDelteRed.svg";
import UploadImg from "../../assets/icons/UploadImg.svg";
import {
  convertObjToArray,
  convertObjectToArrOnlyValue,
} from "../../helper/Helper";
import Uploadinmedia from "../../assets/icons/Uploadinmedia.svg";
import { Calendar } from "react-datepicker2";
import * as moment from "jalali-moment";
import { borderTop } from "@mui/system";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
function Media() {
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const [createMediaModal, setCreateMediaModal] = useState(false);
  const [allMediaData, setAllMediaData] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [axiosURL, setAxiosURL] = useState("/media?isPaginated=1");
  const [currentURL, setCurrentURL] = useState("/media?isPaginated=1&page=");
  const [from, setFrom] = useState(null);
  const paginatedData = useSelector((state) => state.PaginatedData); // get paginated from reducer
  const [image, setImage] = useState(null);
  const [isEdit, setIsEdit] = useState(false); // is Edit
  const [editModal, setEditModal] = useState(false); // modal for edit
  const [editLink, setEditLink] = useState(null); // image link for edit modal
  const [editId, setEditId] = useState(null); // edit id
  const [fromDateModal, setFromDateModal] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [englishFromDate, setEnglishFromDate] = useState(null);
  const [endDateModal, setEndDateModal] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [typeSearch, setTypeSearch] = useState("");
  const [typeFilterValue, setTypeFilterValue] = useState(null);
  const [showTypeFilter, setShowTypeFilter] = useState(null);
  const [imgSelected, setImgSelected] = useState([]);
  const [typeFilter, setTypeFilter] = useState([
    {
      title: "همه",
      id: 0,
    },
    {
      title: "عکس",
      id: "image",
    },
    {
      title: "ویدیو",
      id: "video",
    },
  ]);
  const [editMediaInfo, setEditMediaInfo] = useState({
    title: null,
    alt: null,
    width: null,
    height: null,
    type: null,
    created_at: null,
  });
  const [uploadInfo, setUploadInfo] = useState({
    media: [],
    width: null,
    height: null,
    title: null,
    alt: null,
  });

  const [hover, setHover] = useState(null);

  useEffect(() => {
    axios
      .get("/media?isPaginated=1")
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          setAllMediaData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  useEffect(() => {
    if (paginatedData.length != 0) {
      setAllMediaData(paginatedData.data);
      setCurrentPage(paginatedData.meta.current_page);
      setFrom(paginatedData.meta.from);
    }
  }, [paginatedData]);

  const searchHandelChange = (e) => {
    axios
      .get(`/media?isPaginated=1&search=${e.target.value}`)
      .then((res) => {
        if (res.data.status) {
          setAllMediaData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          setAxiosURL(`/media?isPaginated=1&search=${e.target.value}`);
          forceUpdate();
        }
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  // open modal create media
  const createMedia = () => {
    setCreateMediaModal(!createMediaModal);
    imgDetail.map((item, index) => {
      imgDetail.splice(index);
    });
    forceUpdate();
  };
  // upload image onchange
  const onUploadChange = (e) => {
    console.log(e.target.files);
    setUploadInfo((prev) => {
      prev.media = e.target.files[0];
      forceUpdate();
      return prev;
    });
    let img = e.target.files[0];
    URL.createObjectURL(img);
    // console.log(URL.createObjectURL(img));
    setImage(URL.createObjectURL(img));
    forceUpdate();
  };
  useEffect(() => {
    console.log(image);
  }, [image]);

  const header = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const [imgDetail, setImgDetail] = useState([]);
  const uploadImage = (e) => {
    let array = convertObjToArray(e.target.files);

    let media = convertObjectToArrOnlyValue(e.target.files);

    // ? set upload info media
    setUploadInfo((prev) => {
      prev.media = [...prev.media, ...media];
      forceUpdate();
      return prev;
    });
    let Url = [];
    array.map((item) => {
      imgDetail.push({
        details: item.value,
        Url: URL.createObjectURL(item.value),
      });
    });
    forceUpdate();
  };
  // upload image to server
  const shareImg = () => {
    let form = new FormData();
    uploadInfo.media.forEach((file, index) => {
      form.append(`media[${index}]`, file);
    });
    // form.append("title", uploadInfo.title);
    // form.append("width", uploadInfo.width);
    // form.append("height", uploadInfo.height);
    // form.append("alt", uploadInfo.alt);
    axios
      .post("/media/multipleUpload", form, header)
      .then((res) => {
        console.log(res);
        if (res.data.status == 0) {
          dispatch(ShowAlert(true, res.data.message));
        }
        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));
          setCreateMediaModal(!createMediaModal);
          setUploadInfo({
            media: [],
            width: null,
            height: null,
            title: null,
            alt: null,
          });
          setImage(null);
          axios
            .get(`/media?isPaginated=1&page=${allMediaData.length === 25 ? currentPage + 1 : currentPage}`)
            .then((res) => {
              console.log(res);
              if (res.data.status) {
                setAllMediaData(res.data.data);
                setCurrentPage(res.data.meta.current_page);
                setFrom(res.data.meta.from);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const deleteImgClick = (id) => {
    axios
      .delete(`/media/${id}`)
      .then((res) => {
        console.log(res);
        if (res.data.status === 0) {
          dispatch(ShowAlert(true, res.data.message));
        }
        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));
          console.log(res);
          axios
            .get("/media?isPaginated=1")
            .then((res) => {
              console.log(res);
              if (res.data.status) {
                setAllMediaData(res.data.data);
                setCurrentPage(res.data.meta.current_page);
                setFrom(res.data.meta.from);
              }
            })
            .catch((e) => {
              console.log(e);
            });
          forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const editimgClick = (item) => {
    setIsEdit(true);
    setEditModal(true);
    setEditLink(item.media_link);
    setEditMediaInfo((prev) => {
      return {
        title: item.title,
        alt: item.alt,
        width: item.width,
        height: item.height,
        type: item.media_link.split(".")[item.media_link.split(".").length - 1],
        created_at: item.created_at,
      };
    });
    setEditId(item.id);
  };

  const closeEditMedia = () => {
    setEditModal(!editModal);
    setEditMediaInfo((prev) => {
      return {
        title: null,
        alt: null,
        width: null,
        height: null,
        type: null,
        created_at: null,
      };
    });
    setEditId(null);
    setEditLink(null);
  };
  const shareEditMediaClick = () => {
    axios
      .put(`/media/${editId}`, editMediaInfo)
      .then((res) => {
        console.log(res);
        if (res.data.status === 0) {
          if (res.data.message != undefined) {
            dispatch(ShowAlert(true, res.data.message));
          } else if (res.data.messages != undefined) {
            dispatch(ShowAlert(true, res.data.messages));
          }
        }
        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));
          setEditModal(!editModal);
          setEditMediaInfo((prev) => {
            return {
              title: null,
              alt: null,
            };
          });
          setEditId(null);
          setEditLink(null);

          axios
            .get("/media?isPaginated=1")
            .then((res) => {
              console.log(res);
              if (res.data.status) {
                setAllMediaData(res.data.data);
                setCurrentPage(res.data.meta.current_page);
                setFrom(res.data.meta.from);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const closeDateModal = () => {
    setFromDateModal(false);
  };
  const endDateClick = (value) => {
    let date = moment(value._d, "YYYY/MM/DD").locale("fa").format("YYYY/MM/DD");
    setEndDate(date);
    let min = `${fromDate} 00:00:00`;
    let end = `${date} 23:59:59`;
    setAxiosURL(
      `/media?isPaginated=1&fromDate=${min}&upToDate=${end}&mediaType=`
    );
    setCurrentURL(
      `/media?isPaginated=1&fromDate=${min}&upToDate=${end}&mediaType=&page=`
    );

    axios
      .get(`/media?isPaginated=1&fromDate=${min}&upToDate=${end}&mediaType=`)
      .then((res) => {
        console.log(res);
        setEndDateModal(false);
        if (res.data.status) {
          setAllMediaData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
    // setFromDateModal(false);
  };
  const deleteFliterClick = () => {
    axios
      .get("/media?isPaginated=1")
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          setFromDate("");
          setEndDate("");
          setAxiosURL("/media?isPaginated=1");
          setCurrentURL("/media?isPaginated=1&page=");
          setAllMediaData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const typeFilterClick = (id, title) => {
    setTypeFilterValue(title);
    setShowTypeFilter(false);
    setTimeout(() => {
      setShowTypeFilter(true);
    }, 100);
    if (fromDate === "" || endDate === "") {
      var min = "";
      var end = "";
    } else {
      var min = `${fromDate} 00:00:00`;
      var end = `${endDate} 23:59:59`;
    }
    setTypeSearch("");
    if (id === 0) {
      var type = "";
    } else {
      var type = id;
    }

    setAxiosURL(
      `/media?isPaginated=1&fromDate=${min}&upToDate=${end}&mediaType=${type}`
    );
    setCurrentURL(
      `/media?isPaginated=1&fromDate=${min}&upToDate=${end}&mediaType=${type}&page=`
    );

    axios
      .get(
        `/media?isPaginated=1&fromDate=${min}&upToDate=${end}&mediaType=${type}`
      )
      .then((res) => {
        console.log(res);
        setEndDateModal(false);
        if (res.data.status) {
          setAllMediaData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const selectImg = (id) => {
    if (!imgSelected.includes(id)) {
      imgSelected.push(id);
    } else {
      let index = imgSelected.indexOf(id);
      imgSelected.splice(index, 1);
    }
    forceUpdate();
  };

  const removeImg = (index) => {
    imgDetail.splice(index, 1);
    uploadInfo.media.splice(index, 1);
    forceUpdate();
  };

  // ? method for multiple delete media
  const multipleDelete = () => {
    if (imgSelected.length > 0) {
      // ? axios for multiple delete media
      axios
        .delete("/media/multipleDelete", {
          data: { media_ids: imgSelected },
        })
        .then((res) => {
          if (res.data.status) {
            // ? empty image selected
            
            // ? success alert
            dispatch(ShowAlert(true, res.data.message));

            // ? check allMediaData length and current page
            /* if (allMediaData.length == imgSelected.length) {
              setCurrentPage(async (prev) => {
                prev = prev - 1;
                forceUpdate();
                return await prev;
              });
            }
            &page=${currentPage}
            */
            // ? get all media
            console.log(imgSelected.length , allMediaData.length);
            axios
              .get(`/media?isPaginated=1&page=${(imgSelected.length === allMediaData.length && currentPage !== 1) ? currentPage - 1 : currentPage }`)
              .then((response) => {
                console.log(response);
                if (response.data.status) {
                  setAxiosURL(`/media?isPaginated=1&page=${(imgSelected.length === allMediaData.length && currentPage !== 1)? currentPage - 1 : currentPage }`)
                  setImgSelected([]);
                  setAllMediaData(response.data.data);
                  setCurrentPage(response.data.meta.current_page);
                  setFrom(response.data.meta.from);
                  forceUpdate()
                }
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            // ? err alert
            dispatch(ShowAlert(true, res.data.message));
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      // ? alert for select media
      dispatch(ShowAlert(true, "ابتدا رسانه خود را انتخاب نمایید ."));
    }
  };

  return (
    <div className="media-all">
      <Modal
        onBackdropClick={() => closeEditMedia()}
        className="media-modal-edit"
        open={editModal}
      >
        <div className="media-modal-edit-box">
          <div className="media-modal-edit-top-bar">
            <p>ویرایش رسانه</p>
            <img onClick={() => closeEditMedia()} src={cross} />
          </div>
          <div className="media-modal-edit-box-2">
            <div className="media-modal-edit-input-box">
              <InputComponent
                width="100%"
                height="44px"
                id={"editMedia_title"}
                value={editMediaInfo.title}
                placeholder="عنوان رسانه "
                borderColor="#dcdcdc"
                inputType="input"
                type="text"
                onchange={(e) => {
                  setEditMediaInfo((prev) => {
                    return {
                      title: e.target.value,
                      alt: prev.alt,
                    };
                  });
                }}
              />
            </div>
            <div className="media-modal-edit-input-box">
              <InputComponent
                width="100%"
                height="44px"
                id={"editMedia_alt"}
                value={editMediaInfo.alt}
                placeholder="متن جایگزین"
                borderColor="#dcdcdc"
                inputType="input"
                type="text"
                onchange={(e) => {
                  setEditMediaInfo((prev) => {
                    return {
                      title: prev.title,
                      alt: e.target.value,
                    };
                  });
                }}
              />
            </div>

            <div className="media-modal-edit-info">
              <div className="media-modal-edit-image-box">
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  src={editLink}
                />
              </div>

              <div className="media-modal-edit-info-detail">
                <p>
                  {" "}
                  فرمت : <span>{editMediaInfo.type}</span>
                </p>
                <p>
                  {" "}
                  ابعاد :{" "}
                  <span>
                    {`${editMediaInfo.width}x${editMediaInfo.height}`}
                  </span>
                </p>
                <p>
                  {" "}
                  تاریخ ایجاد : <span>{editMediaInfo.created_at}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="media-modal-edit-button-box">
            <button
              onClick={() => closeEditMedia()}
              className="media-modal-edit-cancel-button"
            >
              <p>لغو</p>
            </button>
            <button
              onClick={() => shareEditMediaClick()}
              className="media-modal-edit-save-button"
            >
              <p>اعمال</p>
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        onBackdropClick={() => createMedia()}
        className="media-modal-media"
        open={createMediaModal}
      >
        <div className="media-modal-create-box">
          <div className="media-modal-create-top-bar">
            <p>افزودن رسانه</p>
            <img onClick={() => createMedia()} src={cross} />
          </div>
          <SimpleBar
            forceVisible="x"
            style={{
              width: "calc(100% - 20px)",
              height: "calc(450px - 20px)",
              padding: "20px 0px 0px 20px",
            }}
          >
            <div className="media-modal-create-media-box">
              <label
                htmlFor="upload_img"
                className="media-modal-create-media-box-1"
              >
                <div className="img-modal-img-box-upload-box-button">
                  آپلود عکس
                </div>
                <img src={UploadImg} />
                <input
                  style={{ display: "none" }}
                  type="file"
                  id="upload_img"
                  multiple
                  onChange={(e) => uploadImage(e)}
                />
              </label>
              {imgDetail.map((item, index) => {
                return (
                  <div key={index} className="media-modal-create-media-box-2">
                    <div
                      onClick={() => removeImg(index)}
                      className="media-modal-create-media-box-2-remove-box"
                    >
                      <img src={AllProductTrashRed} />
                    </div>
                    <img src={item.Url} />
                  </div>
                );
              })}
            </div>
          </SimpleBar>
          <div className="media-modal-add-img-button-box">
            <button
              onClick={() => createMedia()}
              className="media-modal-add-img-cancel"
            >
              <p>لغو</p>
            </button>
            <button
              onClick={() => shareImg()}
              className="media-modal-add-img-share"
            >
              <p>انتشار</p>
            </button>
          </div>
        </div>
      </Modal>
      <div className="media-top-bar-box">
        <TopBar />
      </div>
      <div className="media-filter-search-button-box">
        <div className="media-button-box">
          <button onClick={() => createMedia()} className="media-add-button">
            <img src={AllProductAdd} />
            <p className="media-add-button-title">افزودن</p>
          </button>
          <button
            onClick={() => deleteFliterClick()}
            className="media-cancel-filter-button"
          >
            <p>حذف فیلتر</p>
          </button>
        </div>
        <div className="media-filter-box">
          <div className="media-filter-box-1">
            <p className="media-filter-box-1-title">تاریخ ها :</p>
            <div
              onClick={() => setFromDateModal(true)}
              className="media-date-from-filter-box"
            >
              {fromDate === "" ? (
                <p className="media-date-from-title">از</p>
              ) : (
                <p className="media-date-from-title">{fromDate}</p>
              )}
            </div>
            <Modal
              onBackdropClick={() => closeDateModal()}
              className="media-modal-edit"
              open={fromDateModal}
            >
              <div>
                <Calendar
                  isGregorian={false}
                  onChange={(value) => {
                    let date = moment(value._d, "YYYY/MM/DD")
                      .locale("fa")
                      .format("YYYY/MM/DD");
                    setEnglishFromDate(value._d);
                    setFromDate(date);
                    setFromDateModal(false);
                  }}
                />
              </div>
            </Modal>
            <div
              onClick={() => {
                if (fromDate == "") {
                  dispatch(
                    ShowAlert(
                      true,
                      "برای فیلتر زمان ابتدا فیلد (از) را انتخاب نمایید ."
                    )
                  );
                } else {
                  setEndDateModal(true);
                }
              }}
              className="media-date-from-filter-box"
            >
              {endDate === "" ? (
                <p className="media-date-from-title">تا</p>
              ) : (
                <p className="media-date-from-title">{endDate}</p>
              )}
            </div>
            <Modal
              onBackdropClick={() => setEndDateModal(false)}
              className="media-modal-edit"
              open={endDateModal}
            >
              <div>
                <Calendar
                  isGregorian={false}
                  min={englishFromDate}
                  onChange={
                    (value) => endDateClick(value)
                    //   {
                    //   let date = moment(value._d, "YYYY/MM/DD")
                    //     .locale("fa")
                    //     .format("YYYY/MM/DD");
                    //   setFromDate(date);
                    //   setDateModal(false);
                    // }
                  }
                />
              </div>
            </Modal>
          </div>
          <div className="media-filter-box-2">
            <p className="media-filter-box-2-title">موارد رسانه‌ای :</p>
            <DropDownComponent
              width="calc(100% - 118px)"
              height="44px"
              dropDownHeight="150px"
              zindex="1"
              data={typeFilter}
              search={typeSearch}
              show={showTypeFilter}
              value={typeFilterValue}
              itemClick={(id, title) => typeFilterClick(id, title)}
              placeholder="همه"
            />
          </div>
        </div>
        <div className="media-search-box">
          <InputComponent
            width="337px"
            height="44px"
            type="text"
            inputType="inputWithIcon"
            iconSide="right"
            iconSrc={SearchLogo}
            backgroundColor="#f7f7f7"
            placeholder="جستجو رسانه.."
            borderColor="#dcdcdc"
            onchange={(e) => searchHandelChange(e)}
          />
        </div>
      </div>
      <div className="media-table-box">
        {/* <div className="media-table-top-bar">
          <p className="media-hashtag">#</p>
          <p className="media-media">پرونده</p>
          <p className="media-title">عنوان</p>
          <p className="media-alt">متن جایگزین</p>
          <p className="media-date">تاریخ ایجاد</p>
          <p className="media-size">ابعاد(طول × عرض)</p>
          <p className="media-operation">عملیات</p>
        </div> */}

        <div className="media-table-box-2">
          <div className="media-table-box-3">
            <button
              onClick={() => multipleDelete()}
              className={
                imgSelected.length === 0
                  ? "media-garbage-button"
                  : "media-garbage-button-active"
              }
            >
              <img
                src={
                  imgSelected.length === 0
                    ? AllProductTrashRed
                    : AllProductTrash
                }
              />
              حذف
            </button>
          </div>

          <div className="media-table-item-box">
            {allMediaData.map((item, index) => {
              return (
                <div
                  onClick={() => selectImg(item.id)}
                  onMouseMove={() => setHover(index)}
                  onMouseLeave={() => setHover(null)}
                  key={index}
                  className={
                    imgSelected.includes(item.id)
                      ? "media-table-media-active"
                      : "media-table-media"
                  }
                >
                  <div
                    style={
                      hover == index ? { display: "flex" } : { display: "none" }
                    }
                    className="media-table-hover"
                  >
                    <img onClick={() => editimgClick(item)} src={EditActive} />

                    {/* <img onClick={() => deleteImgClick(item.id)}
                    src={DeleteRed} /> */}
                  </div>
                  <img
                    src={item.media_link}
                    className="media-table-media-show"
                  />
                </div>

                /* <div className="media-table-operation">
                <img
                  onClick={() =>
                    editimgClick(item.id, item.title, item.alt, item.media_link)
                  }
                  src={EditActive}
                // onMouseOver={(e) => (e.currentTarget.src = EditActive)}
                // onMouseOut={(e) => (e.currentTarget.src = Edit)}
                ></img>
                <img onClick={() => window.open(item.media_link)} src={EyeActive} />
                <img
                  onClick={() => deleteImgClick(item.id)}
                  src={DeleteRed}
                // onMouseOver={(e) => (e.currentTarget.src = DeleteRed)}
                // onMouseOut={(e) => (e.currentTarget.src = Delete)}
                />
                </div> */
              );
            })}
          </div>
        </div>
      </div>
      <div className="media-pagination-box">
        <Pagination firstAxios={axiosURL} currentAxios={currentURL} />
      </div>
    </div>
  );
}
export default Media;
