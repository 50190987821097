import React, { useEffect, useState } from "react";
import "../../assets/styles/VisitorStatisticsStyle/SalesReportStyle/SalesReportChart.css";
import axios from "../../js/Axios/Axios";
import SelectBox from "../SelectBox/SelectBox";
import { objInObjToArray } from "../../helper/Helper";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  defaults,
} from "chart.js";
import { Line } from "react-chartjs-2";
import useForceUpdate from "use-force-update";
ChartJS.register([
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
]);
export default function VisitorStatisticsChart() {
  defaults.font.family = "IRANSansFaNum";
  const forceUpdate = useForceUpdate();
  const [allData, setAllData] = useState(null);
  const [value, setValue] = useState([]);
  const [priceValue, setPriceValue] = useState([]);
  const [orderValue, setOrderValue] = useState([]);
  const [OrderItemValue, setOrderItemValue] = useState([]);
  const [monthValue, setMonthValue] = useState([
    { x: "فروردین", y: 0, z: "1" },
    { x: "اردیبهشت", y: 0, z: "2" },
    { x: "خرداد", y: 0, z: "3" },
    { x: "تیر", y: 0, z: "4" },
    { x: "مرداد", y: 0, z: "5" },
    { x: "شهریور", y: 0, z: "6" },
    { x: "مهر", y: 0, z: "7" },
    { x: "آبان", y: 0, z: "8" },
    { x: "آذر", y: 0, z: "9" },
    { x: "دی", y: 0, z: "10" },
    { x: "بهمن", y: 0, z: "11" },
    { x: "اسفند", y: 0, z: "12" },
  ]);
  const [label, setLabel] = useState(null);
  const [labelChart, setLabelChart] = useState(null);
  useEffect(() => {
    axios
      .get("/Order/chart")
      .then((res) => {
        console.log("zzzzzzzzzzzzzzz", res);
        let array = [];
        let OrderArray = [];
        let OrderItem = [];
        let Price = [];
        setLabel("روزانه");
        setLabelChart("تعداد سفارش");
        array = objInObjToArray(res.data.data);
        array.map((item, index) => {
          OrderArray.push({
            x: item.y,
            y: item.orders_count,
          });
          OrderItem.push({
            x: item.y,
            y: item.orderItems_count,
          });
          Price.push({
            x: item.y,
            y: item.final_price,
          });
          forceUpdate();
        });
        setValue(OrderArray);
        setOrderValue(OrderArray);
        setOrderItemValue(OrderItem);
        setPriceValue(Price);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const itemClik = (key) => {
    setLabelChart(key);
    if (label !== "ماهانه") {
      if (key === "قیمت") {
        setValue(priceValue);
      } else if (key === "تعداد سفارش") {
        setValue(orderValue);
      } else if (key === "تعداد محصولات") {
        setValue(OrderItemValue);
      }
    } else {
      if (key === "قیمت") {
        priceValue.map((item) => {
          monthValue.map((month) => {
            if (month.z === item.x) {
              month.y = item.y;
            }
            forceUpdate();
          });
          setValue(monthValue);
        });
      } else if (key === "تعداد سفارش") {
        orderValue.map((item) => {
          monthValue.map((month) => {
            if (month.z === item.x) {
              month.y = item.y;
            }
            forceUpdate();
          });
          setValue(monthValue);
        });
      } else if (key === "تعداد محصولات") {
        OrderItemValue.map((item) => {
          monthValue.map((month) => {
            if (month.z === item.x) {
              month.y = item.y;
            }
            forceUpdate();
          });
          setValue(monthValue);
        });
      }
    }
    forceUpdate();
  };
  const dateClick = (key) => {
    setLabel(key);
    let filter = "";
    if (key === "روزانه") {
      filter = "daily";
    } else if (key === "ماهانه") {
      filter = "monthly";
    } else if (key === "سالانه") {
      filter = "yearly";
    }
    axios
      .get(`/Order/chart?filter=${filter}`)
      .then((res) => {
        console.log(res);
        let array = [];
        let OrderArray = [];
        let OrderItem = [];
        let Price = [];
        array = objInObjToArray(res.data.data);
        array.map((item, index) => {
          OrderArray.push({
            x: item.y,
            y: item.orders_count,
          });
          OrderItem.push({
            x: item.y,
            y: item.orderItems_count,
          });
          Price.push({
            x: item.y,
            y: item.final_price,
          });
          forceUpdate();
        });
        setOrderValue(OrderArray);
        setOrderItemValue(OrderItem);
        setPriceValue(Price);
        if (key !== "ماهانه") {
          if (labelChart === "قیمت") {
            setValue(Price);
          } else if (labelChart === "تعداد سفارش") {
            setValue(OrderArray);
          } else if (labelChart === "تعداد محصولات") {
            setValue(OrderItem);
          }
        } else {
          if (labelChart === "قیمت") {
            Price.map((item) => {
              monthValue.map((month) => {
                if (month.z === item.x) {
                  month.y = item.y;
                }
                forceUpdate();
              });
              setValue(monthValue);
            });
          } else if (labelChart === "تعداد سفارش") {
            OrderArray.map((item) => {
              monthValue.map((month) => {
                if (month.z === item.x) {
                  month.y = item.y;
                }
                forceUpdate();
              });
              setValue(monthValue);
            });
          } else if (labelChart === "تعداد محصولات") {
            OrderItem.map((item) => {
              monthValue.map((month) => {
                if (month.z === item.x) {
                  month.y = item.y;
                }
                forceUpdate();
              });
              setValue(monthValue);
            });
          }
        }

        forceUpdate();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const data = {
    datasets: [
      {
        label: "",
        data: value,
        fill: false,
        borderColor: "#2d99ff",
        backgroundColor: "#2d99ff",
        tension: 0.1,
        fontFamily: "IRANSansFaNum",
      },
    ],
  };
  const options = {
    responsive: true,
    showTooltips: true,
    font: { family: "IRANSansFaNum" },
    plugins: {
      tooltip: {
        intersect: false,
      },
      legend: {
        display: false,
        position: "top",
        text: "zzzz",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };
  return (
    <div className="sales-report-Chart">
      <div className="sales-report-Chart-2">
        <div className="sales-report-Chart-2-title-box">
          <p>نمودار میزان فروش</p>
        </div>
        <div className="sales-report-Chart-2-select-box">
          <div style={{marginLeft:"15px"}}>
          <SelectBox
            label={labelChart}
            data={[
              { key: "قیمت" },
              { key: "تعداد سفارش" },
              { key: "تعداد محصولات" },
            ]}
            width="calc(150px - 20px)"
            heightBox="100px"
            height="39px"
            backgroundColor="#f5f6fa"
            itemClik={(key) => itemClik(key)}
          />

          </div>

          <SelectBox
            label={label}
            data={[{ key: "روزانه" }, { key: "ماهانه" }, { key: "سالانه" }]}
            width="calc(150px - 20px)"
            heightBox="100px"
            height="39px"
            backgroundColor="#f5f6fa"
            itemClik={(key) => dateClick(key)}
          />
        </div>
      </div>
      <div className="sales-report-Chart-box">
        <Line options={options} data={data} />
      </div>
    </div>
  );
}
