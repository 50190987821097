import React, { useEffect, useRef } from "react";
import { useState } from "react";
import "../../assets/styles/OrderStyle/ReturnedOrder.css";
import TopBar from "../../components/TopBar/TopBar";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import Pagination from "../../components/Pagination/Pagination";
import Filter from "../../components/Filter/Filter";
import axios from "../../js/Axios/Axios";
import { useSelector, useDispatch } from "react-redux";
import {
  SendIdForEdit,
  SendReturnedOrderId,
  ShowAlert,
} from "../../action/index";
import SimpleBar from "simplebar-react";
import ScrollDrag from "react-indiana-drag-scroll";
import FilterLogo from "../../assets/icons/Filter.svg";
import AllProductAdd from "../../assets/icons/AllProductAdd.svg";
import SearchLogo from "../../assets/icons/SearchLogo.svg";
import openTablee from "../../assets/icons/openTable.svg";
import closeTable from "../../assets/icons/closeTable.svg";
import AllProductTrashRed from "../../assets/icons/GarbageDelteRed.svg";
import uncheck from "../../assets/icons/uncheck.svg";
import check from "../../assets/icons/check.svg";
import useForceUpdate from "use-force-update";
import EditActive from "../../assets/icons/EditActive.svg";
import { Navigate } from "react-router-dom";
import WhiteCheck from "../../assets/icons/WhiteCheck.svg";
import WhiteCross from "../../assets/icons/WhiteCross.svg";
import restore from "../../assets/icons/restore.svg";
import restoreActive from "../../assets/icons/restoreActive.svg";
import CreateReturnedOrder from "./CreateReturnedOrder";

export default function ReturnedOrder() {
  const adminDes = useRef();
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const paginatedData = useSelector((state) => state.PaginatedData);
  const [firstAxios, setFirstAxios] = useState("/ReturnedOrder");
  const [currentAxios, setCurrentAxios] = useState("/ReturnedOrder?page=");
  const [garbageAxios, setGarbageAxios] = useState("/ReturnedOrder/trash");

  const [showFilter, setShowFilter] = useState(false);
  const [tab, setTab] = useState("همه");
  const [openTable, setOpenTable] = useState(null);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [from, setFrom] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const scrollTitleRefff = useRef();
  const scrollContentRef = useRef();
  const [deleteArray, setDeleteArray] = useState([]);

  const [isOpenProp, setIsOpenProp] = useState(false);
  const [isOpenPropForEdit, setIsOpenPropForEdit] = useState(false);

  const [returnedEditInfo, setReturnedEditInfo] = useState({
    returned_id: "",
    returned_count: "",
    returned_userDescription: "",
    returned_adminDescription: "",
    returned_price: "",
    returned_status: "",
  });

  const [detailsInfo, setDetailsInfo] = useState({
    id: "",
    order_barcode: "",
    delivery_date: "",
    transaction_id: "",
    payment_type: "",
    full_name: "",
    phone_number: "",
    returned_status: "",
    returned_user_description: "",
    returned_admin_description: "",
    card_number: "",
    shaba_number: "",
    final_price: 0,
    returned_price: 0,
  });

  useEffect(() => {
    // if (user_id.id === null) {
    axios
      .get(`/ReturnedOrder`)
      .then((res) => {
        if (res.data.status) {
          setTab("همه");
          setData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          setLastPage(res.data.meta.last_page);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    // }
  }, []);

  const showFilterClick = () => {
    setShowFilter(!showFilter);
    forceUpdate();
  };

  useEffect(() => {
    if (paginatedData.length != 0) {
      setData(paginatedData.data);
      setCurrentPage(paginatedData.meta.current_page);
      setFrom(paginatedData.meta.from);
      setLastPage(paginatedData.meta.last_page);
      setOpenTable(null);
    }
  }, [paginatedData]);

  useEffect(() => {
    // set scroll position title and content for together

    const handleScrollTitle = (e) => {
      scrollContentRef.current.scrollLeft = scrollTitleRefff.current.scrollLeft;
    };
    scrollTitleRefff.current.addEventListener("scroll", handleScrollTitle);
    const handleScrollContent = (e) => {
      scrollTitleRefff.current.scrollLeft = scrollContentRef.current.scrollLeft;
    };
    scrollContentRef.current.addEventListener("scroll", handleScrollContent);
  }, []);

  const allCheckBoxOnChange = () => {
    if (data.every((i) => deleteArray.includes(i.id))) {
      data.map((item, ti) => {
        deleteArray.splice(deleteArray.indexOf(item.id), 1);
        forceUpdate();
      });
    } else {
      data.map((item, i) => {
        if (!deleteArray.includes(item.id)) {
          deleteArray.push(item.id);
        }
      });
      forceUpdate();
    }
  };

  // TODO Here
  const orderCheckBox = (e, id) => {
    e.stopPropagation();
    console.log(e.target);
    console.log(e.currentTarget);

    if (!deleteArray.includes(id)) {
      deleteArray.push(id);
    } else {
      deleteArray.splice(deleteArray.indexOf(id), 1);
    }
    forceUpdate();
  };

  const openTableClick = (index, id) => {
    if (openTable === index) {
      setOpenTable(null);
    } else {
      // axios for get detail info of returned order
      axios
        .get(`ReturnedOrder/${id}/edit`)
        .then((res) => {
          if (res.data.status) {
            // ? open details
            setOpenTable(index);
            // ? set data
            setDetailsInfo(res.data.data);
            setReturnedEditInfo((prev) => {
              prev.returned_adminDescription =
                res.data.data.returned_admin_description;
              prev.returned_id = res.data.data.id;

              forceUpdate();

              return prev;
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const editOrderClick = (id) => {
    dispatch(SendReturnedOrderId(id));
    setIsOpenPropForEdit(true);
  };

  function adminDescriptionChanges(e, id) {
    setReturnedEditInfo((prev) => {
      prev.returned_adminDescription = e.target.value;
      prev.returned_id = id;

      console.log(prev);
      forceUpdate();
      return prev;
    });
  }

  // ? function for update admin answer
  const updateAdminAnswer = (e) => {
    e.preventDefault();

    // http req for update admin answer
    axios
      .put(`ReturnedOrder/${returnedEditInfo.returned_id}`, returnedEditInfo)
      .then((res) => {
        if (res.data.status) {
          // ? success alert
          dispatch(ShowAlert(true, res.data.message));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // ? function for multiple update returned status
  function updateReturnedStatus(returned_status) {
    if (deleteArray.length == 0) {
      dispatch(
        ShowAlert(true, "ابتدا سفارش مرجوعی مورد نظر خود را انتخاب نمایید .")
      );
    } else {
      let updateReturnedStatus = {
        returned_status: returned_status,
        returned_ids: deleteArray,
      };
      axios
        .put(`ReturnedOrder/multipleChangeStatus`, updateReturnedStatus)
        .then((res) => {
          if (res.data.status) {
            // ? empty delete array
            setDeleteArray([]);

            // ? success alert
            dispatch(ShowAlert(true, res.data.message));

            // ? get returned orders

            let queryParams = `returnedStatus=${tab}`;
            if (tab == "همه") {
              queryParams = null;
            }

            axios
              .get(`/ReturnedOrder?page=${currentPage}&${queryParams}`)
              .then((res) => {
                if (res.data.status) {
                  setFirstAxios(`/ReturnedOrder?${queryParams}`);
                  setCurrentAxios(`/ReturnedOrder?&${queryParams}&page=`);
                  setTab(tab);
                  setData(res.data.data);
                  setCurrentPage(res.data.meta.current_page);
                  setFrom(res.data.meta.from);
                  setLastPage(res.data.meta.last_page);
                  forceUpdate();
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  // ? filter for get all returned orders

  const allTab = (id) => {
    axios
      .get(`/ReturnedOrder`)
      .then((res) => {
        if (res.data.status) {
          setFirstAxios(`/ReturnedOrder`);
          setCurrentAxios("/ReturnedOrder?page=");
          setTab(id);
          setData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          setLastPage(res.data.meta.last_page);
          forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const proccessingTab = (id) => {
    axios
      .get(`/ReturnedOrder?returnedStatus=${id}`)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setFirstAxios(`/ReturnedOrder?returnedStatus=${id}`);
          setCurrentAxios(`/ReturnedOrder?&returnedStatus=${id}&page=`);
          setTab(id);
          setData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          setLastPage(res.data.meta.last_page);
          forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // ? filter for get completed returned orders
  const completedTab = (id) => {
    axios
      .get(`/ReturnedOrder?returnedStatus=${id}`)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setTab(id);
          setFirstAxios(`/ReturnedOrder?returnedStatus=${id}`);
          setCurrentAxios(`/ReturnedOrder?&returnedStatus=${id}&page=`);
          setData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          setLastPage(res.data.meta.last_page);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // ? filter for get cancled returned orders

  const cancelOrder = (id) => {
    axios
      .get(`/ReturnedOrder?returnedStatus=${id}`)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setFirstAxios(`/ReturnedOrder?returnedStatus=${id}`);
          setCurrentAxios(`/ReturnedOrder?&returnedStatus=${id}&page=`);
          setTab(id);
          setData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          setLastPage(res.data.meta.last_page);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // ? get all returned orders into trash
  const garbageOrder = (id) => {
    deleteArray.map((item, index) => {
      deleteArray.splice(index);
      forceUpdate();
    });
    axios
      .get(`ReturnedOrder/trash`)
      .then((res) => {
        console.log(res);
        setTab(id);
        setData(res.data.data);
        setCurrentPage(res.data.meta.current_page);
        setFrom(res.data.meta.from);
        setLastPage(res.data.meta.last_page);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // ? throwing garbage for returned orders
  const deleteOrder = () => {
    if (deleteArray.length == 0) {
      dispatch(
        ShowAlert(true, "ابتدا سفارش مرجوعی مورد نظر خود را انتخاب نمایید .")
      );
    }
    axios
      .delete("ReturnedOrder/throwingGarbage", {
        data: { returnedOrder_ids: deleteArray },
      })
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));

          if (data.length === deleteArray.length) {
            setFirstAxios(`/ReturnedOrder?page=${currentPage - 1}`);
          } else {
            setFirstAxios(`/ReturnedOrder?page=${currentPage}`);
          }

          let queryParams = `returnedStatus=${tab}`;
          if (tab == "همه") {
            queryParams = null;
          }

          axios
            .get(
              `/ReturnedOrder?page=${
                data.length === deleteArray.length
                  ? currentPage - 1
                  : currentPage
              }&${queryParams}`
            )
            .then((res) => {
              if (res.data.status) {
                setData(res.data.data);
                setCurrentPage(res.data.meta.current_page);
                setFrom(res.data.meta.from);
                setLastPage(res.data.meta.last_page);
                deleteArray.map((item, index) => {
                  deleteArray.splice(index);
                  forceUpdate();
                });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // ? restore returned orders from garbage
  const recycleOrder = () => {
    if (deleteArray.length == 0) {
      dispatch(
        ShowAlert(true, "ابتدا سفارش مرجوعی مورد نظر خود را انتخاب نمایید .")
      );
    }
    axios
      .put("ReturnedOrder/restoreGarbage", { returnedOrder_ids: deleteArray })
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));

          if (data.length === deleteArray.length) {
            setGarbageAxios(`/ReturnedOrder/trash?page=${currentPage - 1}`);
          } else {
            setGarbageAxios(`/ReturnedOrder/trash?page=${currentPage}`);
          }
          forceUpdate();
          axios
            .get(
              `/ReturnedOrder/trash?page=${
                data.length === deleteArray.length
                  ? currentPage - 1
                  : currentPage
              }`
            )
            .then((res) => {
              console.log(res);
              setTab("زباله دان");
              setData(res.data.data);
              setCurrentPage(res.data.meta.current_page);
              setFrom(res.data.meta.from);
              setLastPage(res.data.meta.last_page);
              deleteArray.map((item, index) => {
                deleteArray.splice(index);
                forceUpdate();
              });
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // ? function for search returned orders
  const searchOrder = (e) => {
    axios
      .get(`/ReturnedOrder?search=${e.target.value}`)
      .then((res) => {
        if (res.data.status) {
          setTab("همه");
          setFirstAxios(`/ReturnedOrder?search=${e.target.value}`);
          setCurrentAxios(`/ReturnedOrder?search=${e.target.value}&page=`);
          setData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          setLastPage(res.data.meta.last_page);
          forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // ? function for search returned orders into trash
  const searchOrdertrash = (e) => {
    axios
      .get(`/ReturnedOrder/trash?search=${e.target.value}`)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setTab("زباله دان");
          setData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          setLastPage(res.data.meta.last_page);
          forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // ? useEffect for close create returned order in modal
  useEffect(() => {
    if (isOpenProp == false) {
      let queryParams = `returnedStatus=${tab}`;
      if (tab == "همه") {
        queryParams = null;
      }

      axios
        .get(`/ReturnedOrder?page=${currentPage}&${queryParams}`)
        .then((res) => {
          if (res.data.status) {
            setFirstAxios(`/ReturnedOrder?${queryParams}`);
            setCurrentAxios(`/ReturnedOrder?&${queryParams}&page=`);
            setTab(tab);
            setData(res.data.data);
            setCurrentPage(res.data.meta.current_page);
            setFrom(res.data.meta.from);
            setLastPage(res.data.meta.last_page);
            forceUpdate();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [isOpenProp]);

  // ? useEffect for close create returned order in modal
  useEffect(() => {
    let queryParams = `returnedStatus=${tab}`;
    if (tab == "همه") {
      queryParams = null;
    }

    axios
      .get(`/ReturnedOrder?page=${currentPage}&${queryParams}`)
      .then((res) => {
        if (res.data.status) {
          setFirstAxios(`/ReturnedOrder?${queryParams}`);
          setCurrentAxios(`/ReturnedOrder?&${queryParams}&page=`);
          setTab(tab);
          setData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          setLastPage(res.data.meta.last_page);
          forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [isOpenPropForEdit]);

  // ? function for get data of filter
  const Data = (Data, route) => {
    setTab("همه");
    setData(Data.data);
    setCurrentPage(Data.meta.current_page);
    setFrom(Data.meta.from);
    setLastPage(Data.meta.last_page);
    setFirstAxios(route);
    setCurrentAxios(`${route}&page=`);
  };

  return (
    <div className="returned-order-all">
      <CreateReturnedOrder
        isOpenProp={isOpenProp}
        closeModal={(status) => setIsOpenProp(status)}
        closeEditModal={(status) => setIsOpenPropForEdit(status)}
        isOpenPropForEdit={isOpenPropForEdit}
      />
      <TopBar />
      <div className="manage-order-button-serach-box">
        <div className="manage-order-button-box">
          <button
            onClick={() => setIsOpenProp(true)}
            className="manage-order-filter-button"
          >
            <img src={AllProductAdd} />
            ایجاد سفارش
          </button>
          <button
            onClick={() => showFilterClick()}
            className="manage-order-filter-button"
          >
            <img src={FilterLogo} />
            {showFilter === true ? "بستن فیلتر ها" : "نمایش فیلترها"}
          </button>
        </div>
        <div className="manage-order-serach-box">
          {tab === "زباله دان" ? (
            <InputComponent
              width="337px"
              height="44px"
              type="text"
              inputType="inputWithIcon"
              iconSide="right"
              iconSrc={SearchLogo}
              backgroundColor="#f7f7f7"
              placeholder="جستجو سفارش.."
              borderColor="#dcdcdc"
              onchange={(e) => searchOrdertrash(e)}
            />
          ) : (
            <InputComponent
              width="337px"
              height="44px"
              type="text"
              inputType="inputWithIcon"
              iconSide="right"
              iconSrc={SearchLogo}
              backgroundColor="#f7f7f7"
              placeholder="جستجو سفارش.."
              borderColor="#dcdcdc"
              onchange={(e) => searchOrder(e)}
            />
          )}
        </div>
      </div>
      <div className="order-table-box">
        <div
          className={
            showFilter === false ? "order-table-box-1" : "order-table-box-2"
          }
        >
          <SimpleBar
            forceVisible="x"
            // scrollableNodeProps={{ ref: this.scrollTitleRefff }}
            style={{
              width: "100%",
              borderRadius: "10px 10px 0 0",
              cursor: "grab",
              borderTop: "solid 1px #DCDCDC",
              borderRight: "solid 1px #DCDCDC",
              borderLeft: "solid 1px #DCDCDC",
            }}
          >
            <ScrollDrag innerRef={scrollTitleRefff}>
              <div className="all-order-tab-bar">
                <div className="order-products-tab-bar">
                  <p
                    onClick={() => allTab("همه")}
                    className={
                      tab === "همه"
                        ? "order-products-tab-bar-title-1-active"
                        : "order-products-tab-bar-title-1"
                    }
                  >
                    همه
                  </p>
                  <p
                    onClick={() => proccessingTab("در انتظار بررسی")}
                    className={
                      tab === "در انتظار بررسی"
                        ? "order-products-tab-bar-title-2-active"
                        : "order-products-tab-bar-title-2"
                    }
                  >
                    در انتظار بررسی
                  </p>

                  <p
                    onClick={() => completedTab("تایید شده")}
                    className={
                      tab === "تایید شده"
                        ? "order-products-tab-bar-title-2-active"
                        : "order-products-tab-bar-title-2"
                    }
                  >
                    تایید شده
                  </p>
                  <p
                    className={
                      tab === "رد شده"
                        ? "order-products-tab-bar-title-2-active"
                        : "order-products-tab-bar-title-2"
                    }
                    onClick={() => cancelOrder("رد شده")}
                  >
                    رد شده
                  </p>
                  <p
                    className={
                      tab === "زباله دان"
                        ? "order-products-tab-bar-title-2-active"
                        : "order-products-tab-bar-title-2"
                    }
                    onClick={() => garbageOrder("زباله دان")}
                  >
                    زباله دان
                  </p>
                </div>
                {tab !== "زباله دان" ? (
                  <div className="order-products-tab-bar-2">
                    <button
                      onClick={() => deleteOrder()}
                      className="order-products-tab-bar-2-delte-button"
                    >
                      <img src={AllProductTrashRed} />
                      زباله ‌دان
                    </button>

                    <button
                      onClick={() => updateReturnedStatus("تایید شده")}
                      className="product-comment-blog-accept-comment-button"
                    >
                      <img src={WhiteCheck} />
                      <p>تایید </p>
                    </button>

                    <button
                      onClick={() => updateReturnedStatus("رد شده")}
                      className="product-comment-blog-decline-comment-button"
                    >
                      <img src={WhiteCross} />
                      <p>رد کردن</p>
                    </button>
                  </div>
                ) : (
                  <div className="order-products-tab-bar-2">
                    <button
                      onClick={() => recycleOrder()}
                      className={
                        deleteArray.length === 0
                          ? "order-products-tab-bar-2-recycle-button"
                          : "order-products-tab-bar-2-recycle-button-active"
                      }
                    >
                      <img
                        src={deleteArray.length === 0 ? restore : restoreActive}
                      />
                      بازیابی مرجوعی
                    </button>
                  </div>
                )}
              </div>
              <div className="order-table-top-bar">
                <p className="order-table-top-bar-hashtag">#</p>
                <div className="order-table-top-bar-checkbox">
                  <label htmlFor="allCheckBox">
                    <img
                      src={
                        data.every((i) => deleteArray.includes(i.id)) &&
                        data.length != 0
                          ? check
                          : uncheck
                      }
                    />
                    <input
                      style={{ display: "none" }}
                      onChange={() => allCheckBoxOnChange()}
                      id="allCheckBox"
                      name="allCheckBox"
                      type="checkbox"
                    />
                  </label>
                </div>
                <p className="order-table-top-bar-name">محصول</p>
                <p className="order-table-top-bar-barcode">تاریخ ثبت</p>
                <p className="order-table-top-bar-date">علت مرجوعی</p>
                <p className="order-table-top-bar-price">مبلغ مرجوعی (تومان)</p>
                <p className="order-table-top-bar-payment">تعداد محصولات</p>
                <p className="order-table-top-bar-situation">وضعیت</p>
                <p className="order-table-top-bar-opration">جزئیات/عملیات</p>
              </div>
            </ScrollDrag>
          </SimpleBar>
          <SimpleBar
            forceVisible="x"
            // scrollableNodeProps={{ ref: this.scrollContentRef }}
            style={{
              width: "calc(100% - 3px)",
              borderLeft: "solid 1px #dcdcdc",
              borderRight: "solid 1px #dcdcdc",
              borderBottom: "solid 1px #dcdcdc",
              borderRadius: "0 0 10px 10px",
            }}
          >
            <ScrollDrag
              className="scroll-container"
              innerRef={scrollContentRef}
            >
              {data.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={
                      openTable === index
                        ? "order-table-item-box-3"
                        : "order-table-item-box"
                    }
                  >
                    <div className="order-table-item-box-2">
                      <div className="order-table-item-hashtag">
                        <p>{index + from}</p>
                      </div>
                      <label
                        htmlFor={`order${index}`}
                        className="order-table-item-check-box"
                      >
                        <input
                          style={{ display: "none" }}
                          type="checkbox"
                          id={`order${index}`}
                          name={`order${index}`}
                          onChange={(e) => orderCheckBox(e, item.id)}
                        />
                        <img
                          src={deleteArray.includes(item.id) ? check : uncheck}
                        />
                      </label>
                      <div className="order-table-item-name">
                        <span>
                          <img
                            src={item.product_image}
                            className="AllProductTableItemsImageImage"
                          />
                        </span>
                        <p style={{ marginRight: "15px" , textAlign:"right" }}>
                          {item.product_name}
                        </p>
                        {item.property_key !== null && 

<>
                        {item.property_key == "color" ||
                        item.property_key == "رنگ" ? (
                          <p
                            className="color-prop"
                            style={{
                              marginRight: "15px",
                              backgroundColor: item.alternative_property_value,
                            }}
                          ></p>
                        ) : (
                          <p
                            className="not-color-prop"
                            style={{ marginRight: "15px" }}
                          >
                            {item.main_property_value}
                          </p>
                        )}
                        </>
                      }
                      </div>
                      <div className="order-table-item-barcode">
                        <p>{item.returned_date}</p>
                      </div>
                      <div className="order-table-item-date">
                        <p>
                          {item.returned_user_description != null
                            ? item.returned_user_description.slice(0, 15) +
                              "..."
                            : ""}
                        </p>
                      </div>
                      <div className="order-table-item-price">
                        <p>
                          {item.returned_price != "" ? item.returned_price : 0}{" "}
                          تومان
                        </p>
                      </div>
                      <div className="order-table-item-payment">
                        <p>{item.product_count}</p>
                      </div>
                      <div className="order-table-item-situation">
                        {item.returned_status === "رد شده" && (
                          <div className="order-table-item-situation-cancel">
                            <p>{item.returned_status}</p>
                          </div>
                        )}
                        {item.returned_status === "در انتظار بررسی" && (
                          <div className="order-table-item-situation-processing">
                            <p>{item.returned_status}</p>
                          </div>
                        )}
                        {item.returned_status === "تایید شده" && (
                          <div className="order-table-item-situation-completed">
                            <p>{item.returned_status}</p>
                          </div>
                        )}
                      </div>
                      <div
                        style={
                          tab == "زباله دان"
                            ? { justifyContent: "center" }
                            : { justifyContent: "flex-start" }
                        }
                        className="order-table-item-operation"
                      >
                        <img
                          src={openTable === index ? closeTable : openTablee}
                          onClick={() => openTableClick(index, item.id)}
                        />
                        {tab != "زباله دان" && (
                          <img
                            onClick={() => editOrderClick(item.id)}
                            src={EditActive}
                          />
                        )}
                      </div>
                    </div>
                    {openTable === index && (
                      <div className="returned-order-table-item-open-box">
                        <div className="returned-order-table-item-open-title-box">
                          <div className="returned-order-table-item-open-title-box-1">
                            <p>اطلاعات سفارش</p>
                          </div>
                          <div className="returned-order-table-item-open-title-box-1">
                            <p>اطلاعات مشتری</p>
                          </div>
                          <div className="returned-order-table-item-open-title-box-1">
                            <p>مبلغ مرجوعی</p>
                          </div>
                          <div className="returned-order-table-item-open-title-box-1">
                            <p>علت مرجوعی</p>
                          </div>
                        </div>
                        <div className="returned-order-table-item-open-box-2">
                          <div className="returned-order-table-item-open-box-3">
                            <div className="returned-order-table-item-open-box-3-title">
                              <p>شناسه سفارش</p>
                              <p>تاریخ و زمان تحویل</p>
                              <p>نوع پرداخت</p>
                              <p>کد پیگیری</p>
                            </div>
                            <div className="returned-order-table-item-open-box-3-description">
                              <p>{detailsInfo.order_barcode}</p>
                              <p>{detailsInfo.delivery_date}</p>
                              <p>{detailsInfo.payment_type}</p>
                              <p>{detailsInfo.transaction_id}</p>
                            </div>
                          </div>

                          <div className="returned-order-table-item-open-box-3">
                            <div className="returned-order-table-item-open-box-3-title">
                              <p>نام و نام خانوادگی</p>
                              <p>شماره تماس</p>
                              <p>شماره کارت</p>
                              <p>شماره شبا</p>
                            </div>
                            <div className="returned-order-table-item-open-box-3-description">
                              <p>{detailsInfo.full_name}</p>
                              <p>{detailsInfo.phone_number}</p>
                              <p>
                                {detailsInfo.card_number == null
                                  ? ""
                                  : detailsInfo.card_number}
                              </p>
                              <p>
                                {detailsInfo.shaba_number == null
                                  ? ""
                                  : "IR-" + detailsInfo.shaba_number}
                              </p>
                            </div>
                          </div>

                          <div className="returned-order-table-item-open-box-3">
                            <div className="returned-order-table-item-open-box-3-title">
                              <p>مجموع سفارش</p>
                              <p>مبلغ مرجوعی</p>
                            </div>
                            <div className="returned-order-table-item-open-box-3-description">
                              <p>{detailsInfo.final_price} تومان</p>
                              <p
                                style={{
                                  fontFamily: "IRANSansFaNum",
                                  fontFtyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "18px",
                                  lineHeight: "25px",
                                  color: "#008efc",
                                }}
                              >
                                {detailsInfo.returned_price != ""
                                  ? detailsInfo.returned_price
                                  : 0}{" "}
                                تومان
                              </p>
                            </div>
                          </div>

                          <div className="returned-order-table-item-open-box-4">
                            <p>{detailsInfo.returned_user_description}</p>
                            {tab != "زباله دان" && (
                              <div className="returned-order-table-item-open-box-4-button-box">
                                <InputComponent
                                  width="calc(100% - 25px)"
                                  height="94px"
                                  type="text"
                                  inputType="textarea"
                                  value={
                                    returnedEditInfo.returned_adminDescription
                                  }
                                  // iconSide="right"
                                  // iconSrc={SearchLogo}
                                  backgroundColor="#fff"
                                  placeholder="توضیحات فروشگاه.."
                                  borderColor="#dcdcdc"
                                  reference={adminDes}
                                  onchange={(e) =>
                                    adminDescriptionChanges(e, detailsInfo.id)
                                  }
                                />
                                <button
                                  onClick={(e) => updateAdminAnswer(e)}
                                  className="returned-order-table-item-open-box-4-button"
                                >
                                  ثبت
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </ScrollDrag>
          </SimpleBar>

          <div className="order-paginate-box">
            <Pagination firstAxios={firstAxios} currentAxios={currentAxios} />
          </div>
        </div>
        {showFilter === true && (
          <Filter
            Date={true}
            Category={false}
            Situation={false}
            Price={false}
            User={false}
            // UserAxios="User?isNotPaginated=1"
            Data={Data}
            axiosURL="/ReturnedOrder"
          />
        )}
      </div>
    </div>
  );
}
