import update from "react-addons-update";
const iniState = {
  categorysinfo: [],
};
const SendCategoryNameIdReducer = (state = iniState, action) => {
  switch (action.type) {
    case "SEND_CATEGORY_NAME_AND_ID":
      const necategory = update(state, {
        categorysinfo: { $set: action.payload },
      });
      state = necategory;
      return state;

    default:
      return state;
  }
};
export default SendCategoryNameIdReducer;
