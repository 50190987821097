import update from "react-addons-update";
const iniState = {
  simpleProductPrice: [
    {
      product_id: null,
      key: null,
      main_value: null,
      alternative_value: null,
      available_type: "همیشه موجود",
      price: null,
      discount: null,
      min_order: null,
      max_order: null,
      available_count: null,
      alter_threshold: null,
      weight: null,
      length: null,
      height: null,
      width: null,
    },
  ],
};
const SendSimpleProductPriceReducer = (state = iniState, action) => {
  switch (action.type) {
    case "SEND_SIMPLE_PRODUCT_PRICE":
      const newPrice = update(state, {
        simpleProductPrice: {
          [0]: {
            product_id: { $set: action.productPrice.product_id },
            key: { $set: action.productPrice.key },
            main_value: { $set: action.productPrice.main_value },
            alternative_value: { $set: action.productPrice.alternative_value },
            available_type: { $set: action.productPrice.available_type },
            price: { $set: action.productPrice.price },
            discount: { $set: action.productPrice.discount },
            min_order: { $set: action.productPrice.min_order },
            max_order: { $set: action.productPrice.max_order },
            available_count: { $set: action.productPrice.available_count },
            alter_threshold: { $set: action.productPrice.alter_threshold },
            weight: { $set: action.productPrice.weight },
            length: { $set: action.productPrice.length },
            height: { $set: action.productPrice.height },
            width: { $set: action.productPrice.width },
          },
        },
      });
      state = newPrice;
      return state;
    case "CLEAR_SIMPLE_PRICE":
      state.simpleProductPrice[0].product_id = null;
      state.simpleProductPrice[0].key = null;
      state.simpleProductPrice[0].main_value = null;
      state.simpleProductPrice[0].alternative_value = null;
      state.simpleProductPrice[0].available_type = "همیشه موجود";
      state.simpleProductPrice[0].price = null;
      state.simpleProductPrice[0].discount = null;
      state.simpleProductPrice[0].min_order = null;
      state.simpleProductPrice[0].max_order = null;
      state.simpleProductPrice[0].available_count = null;
      state.simpleProductPrice[0].alter_threshold = null;
      state.simpleProductPrice[0].weight = null;
      state.simpleProductPrice[0].length = null;
      state.simpleProductPrice[0].height = null;
      state.simpleProductPrice[0].width = null;

      return state;
    default:
      return state;
  }
};
export default SendSimpleProductPriceReducer;
