import React, { useState, useContext, useEffect } from "react";
import "../../assets/styles/VariableProduct/VariableProductPrice.css";
import InputComponent from "../TextInputComponent/TextInputComponent";
import DropDownComponent from "../DropDown/DropDown";
import axios from "../../js/Axios/Axios";
import { isObjectEmpty } from "../../helper/Helper";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  DeleteVariableProductPrice,
  SendVariableProductPrice,
  ShowAlert,
} from "../../action/index";
import ArrowDown from "../../assets/icons/ArrowDown.svg";
import colorPicker from "../../assets/icons/colorPicker.svg";
import Delete from "../../assets/icons/Delete.svg";
import DeleteRed from "../../assets/icons/DeleteRed.svg";
import Edit from "../../assets/icons/Edit.svg";
import EditActive from "../../assets/icons/EditActive.svg";
import cross from "../../assets/icons/cross.svg";
import { HexColorPicker } from "react-colorful";
import useForceUpdate from "use-force-update";
import Modal from "@mui/material/Modal";

function VariableProductPrice(props) {
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const location = useLocation();
  const [modal, setModal] = useState(false);
  const [addVariableButton, setAddVariableButton] = useState("افزودن متغییر");
  const [show, setShow] = useState(); //for show dropdown box
  const [searchProperty, setSearchProperty] = useState(""); //search in dropdown input
  const [disabledInput, setDisabledInput] = useState(false); // disabled input of dropdown
  const [Manage, setManage] = useState(false);
  const [Avalabel, setAvalabel] = useState(true);
  const [NotAvalabel, setNotAvalabel] = useState(false);
  const [color, setColor] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [showVariableProductModal, setShowVariableProductModal] =
    useState(false);
  const ProductInfoForEdit = useSelector((state) => state.ProductInfoForEdit);
  const Error = useSelector((state) => state.Error);
  const [productPriceArray, setProductPriceArray] = useState([]);
  const [dropLable, setDropLable] = useState(null);
  const [situation, setSituation] = useState(null);
  const [errors, setErrors] = useState({
    price: false,
    key: false,
    weight: false,
    length: false,
    height: false,
    width: false,
    min_order: false,
    max_order: false,
  });
  const [productPrice, setProductPrice] = useState({
    product_id: null,
    key: null,
    main_value: null,
    alternative_value: null,
    available_type: "همیشه موجود",
    price: null,
    discount: null,
    min_order: null,
    max_order: null,
    available_count: null,
    alter_threshold: null,
    weight: null,
    length: null,
    height: null,
    width: null,
    is_default_price: false,
  });
  useEffect(() => {
    if (productPriceArray.length === 0) {
      if (productPrice.price !== null && productPrice.price !== "") {
        setSituation("variable");
        props.Situation("variable");
      } else if (productPrice.price == null || productPrice.price == "") {
        setSituation(null);
        props.Situation(null);
      }
    } else {
      setSituation("variable");
      props.Situation("variable");
    }
  }, [productPrice.price]);

  const EditVariableProduct = (index, item) => {
    productPriceArray.splice(index, 1);
    setProductPrice(() => {
      return {
        product_id: item.product_id,
        key: item.key,
        main_value: item.main_value,
        alternative_value: item.alternative_value,
        available_type: item.available_type,
        price: item.price,
        discount: item.discount,
        min_order: item.min_order,
        max_order: item.max_order,
        available_count: item.available_count,
        alter_threshold: item.alter_threshold,
        weight: item.weight,
        length: item.length,
        height: item.height,
        width: item.width,
        is_default_price: item.is_default_price,
      };
    });
    setDropLable(item.key);
    if (item.available_type == "مدیریت موجودی") {
      setManage(true);
      setAvalabel(false);
      setNotAvalabel(false);
    } else if (item.available_type == "ناموجود") {
      setNotAvalabel(true);
      setManage(false);
      setAvalabel(false);
    } else if (item.available_type == "همیشه موجود") {
      setAvalabel(true);
      setNotAvalabel(false);
      setManage(false);
    }
    setAddVariableButton("ویرایش متغییر");
    setShowVariableProductModal(false);
    setIsEdit(true);
    forceUpdate();
  };
  const DeleteVariableProductPriceClick = (index , id) => {
    if (index === 0) {
      dispatch(DeleteVariableProductPrice(index));
      setShowVariableProductModal(true);
      forceUpdate();
      if (productPriceArray.length === 0) {
        props.Situation(null);
      }
    } else {
      productPriceArray.splice(index, 1);
      if (productPriceArray.length === 0) {
        props.Situation(null);
      }
      dispatch(DeleteVariableProductPrice(index));
      setShowVariableProductModal(true);
      forceUpdate();
    }
    if (
      location.pathname === "/AllProductUpdate" ||
      location.pathname === "/AllDraftProductUpdate"
    ) {
      let array = [];
      array.push(id)
      let ids = JSON.stringify(array)
      axios
        .delete(`/productPrice/throwingGarbage?productPrice_ids=${ids}`)
        .then((res) => {
          console.log(res);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  const showVariableProductClick = () => {
    console.log(productPriceArray);
    if (isEdit === false) {
      setShowVariableProductModal(true);
    }
  };
  const closeShowVariableProductClick = () => {
    setShowVariableProductModal(false);
  };
  useEffect(() => {
    if (
      Error.height === true &&
      Error.length === true &&
      Error.price === true &&
      Error.weight === true &&
      Error.width === true &&
      Error.min_order === true &&
      Error.max_order === true
    ) {
      setErrors((prev) => {
        prev.price = true;
        prev.height = true;
        prev.length = true;
        prev.weight = true;
        prev.width = true;
        prev.max_order = true;
        prev.min_order = true;
        forceUpdate();
        return prev;
      });
    }
  }, [Error]);
  const checkDefault = productPriceArray.some((item) => {
    if (item.is_default_price === true) {
      return true;
    } else {
      return false;
    }
  });

  //add price to array and send to redux
  const addVariableProductClick = () => {
    if (productPrice.price === null || productPrice.price === "") {
      setErrors((prev) => {
        prev.price = true;
        forceUpdate();
        return prev;
      });
    }
    if (productPrice.height === null || productPrice.height === "") {
      setErrors((prev) => {
        prev.height = true;
        forceUpdate();
        return prev;
      });
    }
    if (productPrice.length === null || productPrice.length === "") {
      setErrors((prev) => {
        prev.length = true;
        forceUpdate();
        return prev;
      });
    }
    if (productPrice.weight === null || productPrice.weight === "") {
      setErrors((prev) => {
        prev.weight = true;
        forceUpdate();
        return prev;
      });
    }
    if (productPrice.width === null || productPrice.width === "") {
      setErrors((prev) => {
        prev.width = true;
        forceUpdate();
        return prev;
      });
    }
    if (productPrice.key === null || productPrice.key === "") {
      setErrors((prev) => {
        prev.key = true;
        forceUpdate();
        return prev;
      });
    }
    if (productPrice.max_order === null || productPrice.max_order === "") {
      setErrors((prev) => {
        prev.max_order = true;
        forceUpdate();
        return prev;
      });
    }
    if (productPrice.min_order === null || productPrice.min_order === "") {
      setErrors((prev) => {
        prev.min_order = true;
        forceUpdate();
        return prev;
      });
    }
    if (
      productPrice.price != null &&
      productPrice.price != "" &&
      productPrice.weight != null &&
      productPrice.weight != "" &&
      productPrice.width != null &&
      productPrice.width != "" &&
      productPrice.height != null &&
      productPrice.height != "" &&
      productPrice.key !== null &&
      productPrice.key !== "" &&
      productPrice.length != null &&
      productPrice.length != "" &&
      productPrice.min_order != null &&
      productPrice.min_order != "" &&
      productPrice.max_order != null &&
      productPrice.max_order != ""
    ) {
      if (productPriceArray != 0) {
        if (productPriceArray[0].key === productPrice.key) {
          productPriceArray.push(productPrice);
          forceUpdate();
          console.log(checkDefault);
          if (checkDefault === false) {
            productPriceArray[0].is_default_price = true;
          }

          setProductPrice(() => {
            return {
              product_id: null,
              key: null,
              main_value: null,
              alternative_value: null,
              available_type: "همیشه موجود",
              price: null,
              discount: null,
              min_order: null,
              max_order: null,
              available_count: null,
              alter_threshold: null,
              weight: null,
              length: null,
              height: null,
              width: null,
              is_default_price: false,
            };
          });
          setManage(false);
          setAvalabel(true);
          setNotAvalabel(false);
          let main_value = document.getElementById("main_value");
          main_value.value = null;
          let alternative_value = document.getElementById("alternative_value");
          alternative_value.value = null;
          let price = document.getElementById("price");
          price.value = null;
          let discount = document.getElementById("discount");
          discount.value = null;
          let min_order = document.getElementById("min_order");
          min_order.value = null;
          let max_order = document.getElementById("max_orderr");
          max_order.value = null;
          let available_count = document.getElementById("available_count");
          available_count.value = null;
          let alter_threshold = document.getElementById("alter_threshold");
          alter_threshold.value = null;
          let weight = document.getElementById("weight");
          weight.value = null;
          let length = document.getElementById("length");
          length.value = null;
          let height = document.getElementById("height");
          height.value = null;
          let width = document.getElementById("width");
          width.value = null;
          // if () {

          // }
          let key = document.getElementById("key");
          key.value = null;
          setAddVariableButton("افزودن متغییر");
          forceUpdate();
          setDisabledInput(true);
          setIsEdit(false);

          dispatch(SendVariableProductPrice(productPriceArray));
        } else {
          dispatch(ShowAlert(true, "برابر بودن ویژیگی متغیر الزامی است ."));
        }
      } else {
        productPriceArray.push(productPrice);
        forceUpdate();
        if (checkDefault === false) {
          productPriceArray[0].is_default_price = true;
        }
        // productPriceArray.map((item, index) => {
        //   if (item.is_default_price != true && index == 0) {
        //     item.is_default_price = true;
        //   }
        // });
        setProductPrice(() => {
          return {
            product_id: null,
            key: null,
            main_value: null,
            alternative_value: null,
            available_type: "همیشه موجود",
            price: null,
            discount: null,
            min_order: null,
            max_order: null,
            available_count: null,
            alter_threshold: null,
            weight: null,
            length: null,
            height: null,
            width: null,
            is_default_price: false,
          };
        });
        setManage(false);
        setAvalabel(true);
        setNotAvalabel(false);
        let main_value = document.getElementById("main_value");
        main_value.value = null;
        let alternative_value = document.getElementById("alternative_value");
        alternative_value.value = null;
        let price = document.getElementById("price");
        price.value = null;
        let discount = document.getElementById("discount");
        discount.value = null;
        let min_order = document.getElementById("min_order");
        min_order.value = null;
        let max_order = document.getElementById("max_orderr");
        max_order.value = null;
        let available_count = document.getElementById("available_count");
        available_count.value = null;
        let alter_threshold = document.getElementById("alter_threshold");
        alter_threshold.value = null;
        let weight = document.getElementById("weight");
        weight.value = null;
        let length = document.getElementById("length");
        length.value = null;
        let height = document.getElementById("height");
        height.value = null;
        let width = document.getElementById("width");
        width.value = null;
        // if () {

        // }
        let key = document.getElementById("key");
        key.value = null;
        setAddVariableButton("افزودن متغییر");
        forceUpdate();
        setDisabledInput(true);
        setIsEdit(false);

        dispatch(SendVariableProductPrice(productPriceArray));
      }
    } else {
      let text = `برای افزودن متغیر فیلد های 
      ${productPrice.price == null || productPrice.price == "" ? "قیمت و" : ""} 
      ${
        productPrice.key == null || productPrice.key == ""
          ? "ویژگی متغیر و"
          : ""
      } 
      ${
        productPrice.max_order == null || productPrice.max_order == ""
          ? "حداکثر سفارش و"
          : ""
      } 
      ${
        productPrice.min_order == null || productPrice.min_order == ""
          ? "حداقل سفارش و"
          : ""
      } 
      ${
        productPrice.height == null || productPrice.height == ""
          ? "ارتفاع و"
          : ""
      } 
      ${
        productPrice.length == null || productPrice.length == "" ? "طول و" : ""
      } 
      ${
        productPrice.weight == null || productPrice.weight == "" ? "وزن و" : ""
      } 
      ${productPrice.width == null || productPrice.width == "" ? "عرض" : ""}
      الزامی است . 
      `;
      dispatch(ShowAlert(true, text));
    }
  };
  const openColorPickerModal = () => {
    setModal(true);
  };
  const addColorCode = () => {
    setProductPrice((prev) => {
      return {
        product_id: prev.product_id,
        key: prev.key,
        main_value: prev.main_value,
        alternative_value: color,
        available_type: prev.available_type,
        price: prev.price,
        discount: prev.discount,
        min_order: prev.min_order,
        max_order: prev.max_order,
        available_count: prev.available_count,
        alter_threshold: prev.alter_threshold,
        weight: prev.weight,
        length: prev.length,
        height: prev.height,
        width: prev.width,
        is_default_price: prev.is_default_price,
      };
    });
    forceUpdate();
    setModal(false);
  };
  // drpdown onchange
  const handelSearchProperty = (e) => {
    setSearchProperty(e.target.value);
    setDropLable(e.target.value);
    setProductPrice((prev) => {
      return {
        product_id: prev.product_id,
        key: e.target.value,
        main_value: prev.main_value,
        alternative_value: prev.alternative_value,
        available_type: prev.available_type,
        price: prev.price,
        discount: prev.discount,
        min_order: prev.min_order,
        max_order: prev.max_order,
        available_count: prev.available_count,
        alter_threshold: prev.alter_threshold,
        weight: prev.weight,
        length: prev.length,
        height: prev.height,
        width: prev.width,
        is_default_price: prev.is_default_price,
      };
    });
  };

  const setTrue = () => {
    if (Manage === true) {
      return "VariableProductBlueToggleManage";
    } else if (Avalabel === true) {
      return "VariableProductBlueToggleAvalable";
    } else if (NotAvalabel === true) {
      return "VariableProductBlueToggleNotAvalable";
    } else {
      return "VariableProductBlueToggle";
    }
  };
  const addPropertyClick = (key) => {
    setProductPrice((prev) => {
      return {
        product_id: prev.product_id,
        key: key,
        main_value: prev.main_value,
        alternative_value: prev.alternative_value,
        available_type: prev.available_type,
        price: prev.price,
        discount: prev.discount,
        min_order: prev.min_order,
        max_order: prev.max_order,
        available_count: prev.available_count,
        alter_threshold: prev.alter_threshold,
        weight: prev.weight,
        length: prev.length,
        height: prev.height,
        width: prev.width,
        is_default_price: prev.is_default_price,
      };
    });
    setErrors((prev) => {
      prev.key = false;
      forceUpdate();
      return prev;
    });
    setDropLable(key);
    setShow(false);
    setSearchProperty("");
    setTimeout(() => {
      setShow(true);
    }, 100);
    forceUpdate();
  };
  const ManageClick = (manage) => {
    setManage(true);
    setProductPrice((prev) => {
      return {
        product_id: prev.product_id,
        key: prev.key,
        main_value: prev.main_value,
        alternative_value: prev.alternative_value,
        available_type: manage,
        price: prev.price,
        discount: prev.discount,
        min_order: prev.min_order,
        max_order: prev.max_order,
        available_count: prev.available_count,
        alter_threshold: prev.alter_threshold,
        weight: prev.weight,
        length: prev.length,
        height: prev.height,
        width: prev.width,
        is_default_price: prev.is_default_price,
      };
    });
    setAvalabel(false);

    setNotAvalabel(false);
  };
  const AvalabelClick = (manage) => {
    setAvalabel(true);
    let min_order = document.getElementById("min_order");
    min_order.value = null;
    let max_order = document.getElementById("max_orderr");
    max_order.value = null;
    let available_count = document.getElementById("available_count");
    available_count.value = null;
    let alter_threshold = document.getElementById("alter_threshold");
    alter_threshold.value = null;
    setProductPrice((prev) => {
      return {
        product_id: prev.product_id,
        key: prev.key,
        main_value: prev.main_value,
        alternative_value: prev.alternative_value,
        available_type: manage,
        price: prev.price,
        discount: prev.discount,
        min_order: null,
        max_order: null,
        available_count: null,
        alter_threshold: null,
        weight: prev.weight,
        length: prev.length,
        height: prev.height,
        width: prev.width,
        is_default_price: prev.is_default_price,
      };
    });
    setManage(false);
    setNotAvalabel(false);
    forceUpdate();
  };
  const NotAvalabelClick = (manage) => {
    setNotAvalabel(true);
    setProductPrice((prev) => {
      return {
        product_id: prev.product_id,
        key: prev.key,
        main_value: prev.main_value,
        alternative_value: prev.alternative_value,
        available_type: manage,
        price: prev.price,
        discount: prev.discount,
        min_order: prev.min_order,
        max_order: prev.max_order,
        available_count: prev.available_count,
        alter_threshold: prev.alter_threshold,
        weight: prev.weight,
        length: prev.length,
        height: prev.height,
        width: prev.width,
        is_default_price: prev.is_default_price,
      };
    });
    setAvalabel(false);
    setManage(false);
  };
  // get data from server and send to redux and show in update product
  useEffect(() => {
    if (
      !isObjectEmpty(ProductInfoForEdit) &&
      (location.pathname === "/AllProductUpdate" ||
        location.pathname === "/AllDraftProductUpdate")
    ) {
      if (
        ProductInfoForEdit.state.productPrices.length !== 0 &&
        ProductInfoForEdit.state.productPrices[0].key !== null
      ) {
        props.Situation("variable");
        ProductInfoForEdit.state.productPrices.map((item, index) => {
          productPriceArray.push(item);
          setDropLable(item.key);
        });
        setSituation("variable");
      }
      // productPriceArray.map((item, index) => {
      //   if (item.is_default_price === 1) {
      //     item.is_default_price = true;
      //   } else if (item.is_default_price === 0) {
      //     item.is_default_price = false;
      //   }
      //   forceUpdate();
      // });
      forceUpdate();
    }
    dispatch(SendVariableProductPrice(productPriceArray));
  }, [ProductInfoForEdit]);

  const closeModalClick = () => {
    setModal(false);
  };
  const closeEditModalClick = () => {
    setShowVariableProductModal(false);
  };
  const defaultClick = (index) => {
    productPriceArray.map((item, i) => {
      item.is_default_price = false;

      if (i === index) {
        item.is_default_price = true;
      }
      forceUpdate();
    });
  };

  return (
    <div className="VariableProductPriceAll">
      <Modal
        onBackdropClick={() => closeModalClick()}
        className="VariableProductModal"
        open={modal}
      >
        <div className="VariableProductModalBox">
          <HexColorPicker
            className="VariableProductModalColorPicker"
            color={color}
            onChange={setColor}
          />

          <button
            onClick={() => addColorCode()}
            className="VariableProductModalColorPickerButton"
          >
            <p>تایید رنگ</p>
          </button>
        </div>
      </Modal>

      <Modal
        onBackdropClick={() => closeEditModalClick()}
        className="VariableProductModal"
        open={showVariableProductModal}
      >
        <div className="VariableProductModalShowArrayBox">
          <div className="VariableProductModalShowArrayBoxTopBarBlue">
            <button
              onClick={() => closeShowVariableProductClick()}
              className="VariableProductModalShowArrayBoxTopBarBlueButton"
            >
              <img src={cross} />
            </button>
            <p className="VariableProductModalShowArrayBoxTopBarBlueTittle">
              نمایش محصولات
            </p>
          </div>

          <div className="VariableProductModalShowArrayBoxShowItemBox">
            <div className="VariableProductModalShowArrayBoxShowItemBox-title">
              <div className="VariableProductModalShowArrayBoxShowItemBox-title-1">
                <p>پیش فرض</p>
              </div>
              <div className="VariableProductModalShowArrayBoxShowItemBox-title-2">
                <p>نام</p>
              </div>
              <div className="VariableProductModalShowArrayBoxShowItemBox-title-3">
                <p>قیمت</p>
              </div>
              <div className="VariableProductModalShowArrayBoxShowItemBox-title-4">
                <p>عملیات</p>
              </div>
            </div>
            <div className="VariableProductModalShowArrayBoxShowItemBox-2">
              {productPriceArray.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="VariableProductModalShowArrayBoxShowItemBox2"
                  >
                    <div className="VariableProductModalShowArrayBoxShowItemBox2ButtonBox">
                      <button
                        onClick={() => DeleteVariableProductPriceClick(index , item.id)}
                        className="VariableProductModalShowArrayBoxShowItemBox2ButtonDelte"
                      >
                        <img
                          src={Delete}
                          onMouseOver={(e) => (e.currentTarget.src = DeleteRed)}
                          onMouseOut={(e) => (e.currentTarget.src = Delete)}
                        />
                      </button>
                      <button
                        onClick={() => EditVariableProduct(index, item)}
                        className="VariableProductModalShowArrayBoxShowItemBox2ButtonEdit"
                      >
                        <img
                          mg
                          src={Edit}
                          onMouseOver={(e) =>
                            (e.currentTarget.src = EditActive)
                          }
                          onMouseOut={(e) => (e.currentTarget.src = Edit)}
                        />
                      </button>
                    </div>
                    <div
                      onClick={() => defaultClick(index)}
                      className={
                        item.is_default_price === true ||
                        item.is_default_price === 1
                          ? "ariableProductModalShowArrayBoxShowItemBorder-active"
                          : "ariableProductModalShowArrayBoxShowItemBorder"
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className={
                            item.is_default_price === true ||
                            item.is_default_price === 1
                              ? "sms-component-input-radio-2"
                              : "sms-component-input-radio"
                          }
                        />
                        <p className="ariableProductModalShowArrayBoxShowItemBorderNameShow">
                          {item.main_value}
                        </p>
                        {item.key === "رنگ" ? (
                          <div
                            style={{
                              display: "flex",
                              width: "30px",
                              height: "10px",
                              borderRadius: "10px",
                              marginRight: "10px",
                              backgroundColor: item.alternative_value,
                            }}
                          />
                        ) : (
                          <p className="ariableProductModalShowArrayBoxShowItemBorderNameShow">
                            {item.alternative_value}
                          </p>
                        )}
                      </div>
                      <p className="ariableProductModalShowArrayBoxShowItemBorderPriceShow">
                        {item.price} ریال
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Modal>

      <div className="VariableProductPriceinputBox">
        <InputComponent
          width="45%"
          height="44px"
          id={"price"}
          value={productPrice.price}
          placeholder="قیمت (تومان)"
          borderColor={errors.price === true ? "#F23B3B" : "#dcdcdc"}
          inputType="input"
          type="number"
          onchange={(e) => {
            setProductPrice((prev) => {
              return {
                product_id: prev.product_id,
                key: prev.key,
                main_value: prev.main_value,
                alternative_value: prev.alternative_value,
                available_type: prev.available_type,
                price: e.target.value,
                discount: prev.discount,
                min_order: prev.min_order,
                max_order: prev.max_order,
                available_count: prev.available_count,
                alter_threshold: prev.alter_threshold,
                weight: prev.weight,
                length: prev.length,
                height: prev.height,
                width: prev.width,
                is_default_price: prev.is_default_price,
              };
            });
            setErrors((prev) => {
              prev.price = false;
              forceUpdate();
              return prev;
            });
          }}
        />
        <DropDownComponent
          id={"key"}
          value={productPrice.key}
          // dropLable={dropLable}
          width="45%"
          height="44px"
          dropDownHeight="300px"
          zindex="1"
          show={show}
          search={searchProperty}
          borderColor={errors.key === true ? "#F23B3B" : "#dcdcdc"}
          // disabledInput={disabledInput}
          onchange={(e) => handelSearchProperty(e)}
          itemClick={(key) => addPropertyClick(key)}
          axiosurl={"/property?isPaginated=0"}
          axiosname="variablePrice"
          placeholder="ویژگی متغییر را انتخاب یا وارد کنید"
        />
      </div>

      <div className="VariableProductPropertyinputBox">
        <InputComponent
          width="45%"
          height="44px"
          id={"main_value"}
          value={productPrice.main_value}
          placeholder="مقدار اصلی را وارد کنید"
          borderColor="#dcdcdc"
          inputType="input"
          type="text"
          onchange={(e) => {
            setProductPrice((prev) => {
              return {
                product_id: prev.product_id,
                key: prev.key,
                main_value: e.target.value,
                alternative_value: prev.alternative_value,
                available_type: prev.available_type,
                price: prev.price,
                discount: prev.discount,
                min_order: prev.min_order,
                max_order: prev.max_order,
                available_count: prev.available_count,
                alter_threshold: prev.alter_threshold,
                weight: prev.weight,
                length: prev.length,
                height: prev.height,
                width: prev.width,
                is_default_price: prev.is_default_price,
              };
            });
          }}
        />

        <div className="VariableProductPropertyinputBox2">
          <InputComponent
            width="100%"
            height="44px"
            id={"alternative_value"}
            value={productPrice.alternative_value}
            placeholder="وارد کردن مقدار"
            borderColor="#dcdcdc"
            inputType={
              productPrice.key === "رنگ" || dropLable === "رنگ"
                ? "inputWithIcon"
                : "input"
            }
            iconSide={
              productPrice.key === "رنگ" || dropLable === "رنگ" ? "left" : null
            }
            onClick={
              productPrice.key === "رنگ" ? () => openColorPickerModal() : null
            }
            iconSrc={productPrice.key === "رنگ" ? colorPicker : null}
            type="text"
            onchange={(e) => {
              setProductPrice((prev) => {
                return {
                  product_id: prev.product_id,
                  key: prev.key,
                  main_value: prev.main_value,
                  alternative_value: e.target.value,
                  available_type: prev.available_type,
                  price: prev.price,
                  discount: prev.discount,
                  min_order: prev.min_order,
                  max_order: prev.max_order,
                  available_count: prev.available_count,
                  alter_threshold: prev.alter_threshold,
                  weight: prev.weight,
                  length: prev.length,
                  height: prev.height,
                  width: prev.width,
                  is_default_price: prev.is_default_price,
                };
              });
            }}
          />
        </div>
      </div>
      <div className="VariableProductPropertyinputBox3">
        <InputComponent
          width="45%"
          height="44px"
          id={"discount"}
          value={productPrice.discount}
          placeholder="تخفیف"
          borderColor="#dcdcdc"
          inputType="input"
          type="number"
          onchange={(e) => {
            setProductPrice((prev) => {
              return {
                product_id: prev.product_id,
                key: prev.key,
                main_value: prev.main_value,
                alternative_value: prev.alternative_value,
                available_type: prev.available_type,
                price: prev.price,
                discount: e.target.value,
                min_order: prev.min_order,
                max_order: prev.max_order,
                available_count: prev.available_count,
                alter_threshold: prev.alter_threshold,
                weight: prev.weight,
                length: prev.length,
                height: prev.height,
                width: prev.width,
                is_default_price: prev.is_default_price,
              };
            });
          }}
        />

        <InputComponent
          width="20%"
          height="44px"
          id={"min_order"}
          // disabled={
          //   productPrice.available_type === "همیشه موجود" ? true : false
          // }
          borderColor={errors.min_order === true ? "#F23B3B" : "#dcdcdc"}
          value={productPrice.min_order}
          placeholder="حداقل سفارش"
          inputType="input"
          type="number"
          onchange={(e) => {
            setProductPrice((prev) => {
              return {
                product_id: prev.product_id,
                key: prev.key,
                main_value: prev.main_value,
                alternative_value: prev.alternative_value,
                available_type: prev.available_type,
                price: prev.price,
                discount: prev.discount,
                min_order: e.target.value,
                max_order: prev.max_order,
                available_count: prev.available_count,
                alter_threshold: prev.alter_threshold,
                weight: prev.weight,
                length: prev.length,
                height: prev.height,
                width: prev.width,
                is_default_price: prev.is_default_price,
              };
            });
            setErrors((prev) => {
              prev.min_order = false;
              forceUpdate();
              return prev;
            });
          }}
        />

        <InputComponent
          width="20%"
          height="44px"
          id={"max_orderr"}
          value={productPrice.max_order}
          // disabled={
          //   productPrice.available_type === "همیشه موجود" ? true : false
          // }
          borderColor={errors.max_order === true ? "#F23B3B" : "#dcdcdc"}
          placeholder="حداکثر سفارش"
          inputType="input"
          type="number"
          onchange={(e) => {
            setProductPrice((prev) => {
              return {
                product_id: prev.product_id,
                key: prev.key,
                main_value: prev.main_value,
                alternative_value: prev.alternative_value,
                available_type: prev.available_type,
                price: prev.price,
                discount: prev.discount,
                min_order: prev.min_order,
                max_order: e.target.value,
                available_count: prev.available_count,
                alter_threshold: prev.alter_threshold,
                weight: prev.weight,
                length: prev.length,
                height: prev.height,
                width: prev.width,
                is_default_price: prev.is_default_price,
              };
            });
            setErrors((prev) => {
              prev.max_order = false;
              forceUpdate();
              return prev;
            });
          }}
        />
      </div>
      <div className="VariableProductSizeTittleBox">
        <p className="VariableProductSizeTittle">وزن و ابعاد محصول</p>
      </div>
      <div className="VariableProductPropertyinputBox4">
        <InputComponent
          width="20%"
          height="44px"
          id={"weight"}
          value={productPrice.weight}
          placeholder="وزن"
          borderColor={errors.weight === true ? "#F23B3B" : "#dcdcdc"}
          inputType="input"
          type="number"
          onchange={(e) => {
            setProductPrice((prev) => {
              return {
                product_id: prev.product_id,
                key: prev.key,
                main_value: prev.main_value,
                alternative_value: prev.alternative_value,
                available_type: prev.available_type,
                price: prev.price,
                discount: prev.discount,
                min_order: prev.min_order,
                max_order: prev.max_order,
                available_count: prev.available_count,
                alter_threshold: prev.alter_threshold,
                weight: e.target.value,
                length: prev.length,
                height: prev.height,
                width: prev.width,
                is_default_price: prev.is_default_price,
              };
            });
            setErrors((prev) => {
              prev.weight = false;
              forceUpdate();
              return prev;
            });
          }}
        />

        <InputComponent
          width="20%"
          height="44px"
          id={"height"}
          value={productPrice.height}
          placeholder=" ارتفاع"
          borderColor={errors.height === true ? "#F23B3B" : "#dcdcdc"}
          inputType="input"
          type="number"
          onchange={(e) => {
            setProductPrice((prev) => {
              return {
                product_id: prev.product_id,
                key: prev.key,
                main_value: prev.main_value,
                alternative_value: prev.alternative_value,
                available_type: prev.available_type,
                price: prev.price,
                discount: prev.discount,
                min_order: prev.min_order,
                max_order: prev.max_order,
                available_count: prev.available_count,
                alter_threshold: prev.alter_threshold,
                weight: prev.weight,
                length: prev.length,
                height: e.target.value,
                width: prev.width,
                is_default_price: prev.is_default_price,
              };
            });
            setErrors((prev) => {
              prev.height = false;
              forceUpdate();
              return prev;
            });
          }}
        />

        <InputComponent
          width="20%"
          height="44px"
          id={"length"}
          value={productPrice.length}
          placeholder=" طول"
          borderColor={errors.length === true ? "#F23B3B" : "#dcdcdc"}
          inputType="input"
          type="number"
          onchange={(e) => {
            setProductPrice((prev) => {
              return {
                product_id: prev.product_id,
                key: prev.key,
                main_value: prev.main_value,
                alternative_value: prev.alternative_value,
                available_type: prev.available_type,
                price: prev.price,
                discount: prev.discount,
                min_order: prev.min_order,
                max_order: prev.max_order,
                available_count: prev.available_count,
                alter_threshold: prev.alter_threshold,
                weight: prev.weight,
                length: e.target.value,
                height: prev.height,
                width: prev.width,
                is_default_price: prev.is_default_price,
              };
            });
            setErrors((prev) => {
              prev.length = false;
              forceUpdate();
              return prev;
            });
          }}
        />

        <InputComponent
          width="20%"
          height="44px"
          id={"width"}
          value={productPrice.width}
          placeholder=" عرض"
          borderColor={errors.width === true ? "#F23B3B" : "#dcdcdc"}
          inputType="input"
          type="number"
          onchange={(e) => {
            setProductPrice((prev) => {
              return {
                product_id: prev.product_id,
                key: prev.key,
                main_value: prev.main_value,
                alternative_value: prev.alternative_value,
                available_type: prev.available_type,
                price: prev.price,
                discount: prev.discount,
                min_order: prev.min_order,
                max_order: prev.max_order,
                available_count: prev.available_count,
                alter_threshold: prev.alter_threshold,
                weight: prev.weight,
                length: prev.length,
                height: prev.height,
                width: e.target.value,
                is_default_price: prev.is_default_price,
              };
            });
            setErrors((prev) => {
              prev.width = false;
              forceUpdate();
              return prev;
            });
          }}
        />
      </div>
      <div className="VariableProductSizeTittleBox2">
        <p className="VariableProductSizeTittle2">وزن و ابعاد محصول</p>
      </div>

      <div className="VariableProductPropertyinputBox5">
        <div style={{ display: "flex", width: "100%", marginBottom: "30px" }}>
          <div className="VariableProductToggleBody">
            <div className={setTrue()}></div>

            <button
              onClick={() => NotAvalabelClick("ناموجود")}
              className={
                NotAvalabel === true
                  ? "VariableProductToggleTittle3After"
                  : "VariableProductToggleTittle3"
              }
            >
              موجود نمی باشد
            </button>

            <button
              onClick={() => AvalabelClick("همیشه موجود")}
              className={
                Avalabel === true
                  ? "VariableProductToggleTittle2After"
                  : "VariableProductToggleTittle2"
              }
            >
              همیشه موجود
            </button>

            <button
              onClick={() => ManageClick("مدیریت موجودی")}
              className={
                Manage === true
                  ? "VariableProductToggleTittle1After"
                  : "VariableProductToggleTittle1"
              }
            >
              مدیریت
            </button>
          </div>
        </div>

        <div className="MahsoolRightMiddleBoxInputsBox">
          <InputComponent
            width="45%"
            height="44px"
            id={"available_count"}
            disabled={
              productPrice.available_type === "همیشه موجود" ? true : false
            }
            value={productPrice.available_count}
            placeholder=" موجودی"
            borderColor="#dcdcdc"
            inputType="input"
            type="number"
            onchange={(e) => {
              setProductPrice((prev) => {
                return {
                  product_id: prev.product_id,
                  key: prev.key,
                  main_value: prev.main_value,
                  alternative_value: prev.alternative_value,
                  available_type: prev.available_type,
                  price: prev.price,
                  discount: prev.discount,
                  min_order: prev.min_order,
                  max_order: prev.max_order,
                  available_count: e.target.value,
                  alter_threshold: prev.alter_threshold,
                  weight: prev.weight,
                  length: prev.length,
                  height: prev.height,
                  width: prev.width,
                  is_default_price: prev.is_default_price,
                };
              });
            }}
          />
          <InputComponent
            width="45%"
            height="44px"
            id={"alter_threshold"}
            disabled={
              productPrice.available_type === "همیشه موجود" ? true : false
            }
            value={productPrice.alter_threshold}
            placeholder=" آستانه هشدار"
            borderColor="#dcdcdc"
            inputType="input"
            type="number"
            onchange={(e) => {
              setProductPrice((prev) => {
                return {
                  product_id: prev.product_id,
                  key: prev.key,
                  main_value: prev.main_value,
                  alternative_value: prev.alternative_value,
                  available_type: prev.available_type,
                  price: prev.price,
                  discount: prev.discount,
                  min_order: prev.min_order,
                  max_order: prev.max_order,
                  available_count: prev.available_count,
                  alter_threshold: e.target.value,
                  weight: prev.weight,
                  length: prev.length,
                  height: prev.height,
                  width: prev.width,
                  is_default_price: prev.is_default_price,
                };
              });
            }}
          />
        </div>
      </div>
      <div className="VariableProductPropertyinputBox6">
        <button
          onClick={() => showVariableProductClick()}
          className="VariableProductPropertyinputBox6ButtonShowProduct"
        >
          <p className="VariableProductPropertyinputBox6ButtonTittle2">
            {productPriceArray.length}
          </p>
          <p>نمایش محصولات</p>
        </button>
        <button
          // disabled={
          //   productPrice.price != null &&
          //   productPrice.price != "" &&
          //   productPrice.weight != null &&
          //   productPrice.weight != "" &&
          //   productPrice.width != null &&
          //   productPrice.width != "" &&
          //   productPrice.height != null &&
          //   productPrice.height != "" &&
          //   productPrice.length != null &&
          //   productPrice.length != ""
          //     ? false
          //     : true
          // }
          onClick={() => addVariableProductClick()}
          // className={
          //   productPrice.price != null &&
          //   productPrice.price != "" &&
          //   productPrice.weight != null &&
          //   productPrice.weight != "" &&
          //   productPrice.width != null &&
          //   productPrice.width != "" &&
          //   productPrice.height != null &&
          //   productPrice.height != "" &&
          //   productPrice.length != null &&
          //   productPrice.length != ""
          //     ? "VariableProductPropertyinputBox6ButtonEnable"
          //     : "VariableProductPropertyinputBox6Button"
          // }
          className="VariableProductPropertyinputBox6ButtonEnable"
        >
          <p className="VariableProductPropertyinputBox6ButtonTittle">
            {addVariableButton}
          </p>
        </button>
      </div>
    </div>
  );
}
export default VariableProductPrice;
