import React, { useEffect, useState } from "react";
import "../../assets/styles/Role/Role.css";
import TopBar from "../../components/TopBar/TopBar";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import SearchLogo from "../../assets/icons/SearchLogo.svg";
import Star from "../../assets/icons/Star.svg";
import check from "../../assets/icons/check.svg";
import uncheck from "../../assets/icons/uncheck.svg";
import DeleteRed from "../../assets/icons/DeleteRed.svg";
import EditActive from "../../assets/icons/EditActive.svg";
import axios from "../../js/Axios/Axios";
import useForceUpdate from "use-force-update";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/Pagination/Pagination";
import { ShowAlert } from "../../action";

export default function Role() {
  const dispatch = useDispatch();
  const [from, setFrom] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const paginatedData = useSelector((state) => state.PaginatedData); // get paginated from reducer
  const forceUpdate = useForceUpdate();
  const [firstRoute, setFirstRute] = useState("UserSystemRole");
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);

  // state for (store and update) new role
  const [newRole, setNewRole] = useState({
    title: "",
    title_fa: "",
    permissions: [],
    edit_id: "",
  });

  const [search, setSearch] = useState("");

  // get initial roles data
  useEffect(() => {
    axios
      .get("UserSystemRole")
      .then((res) => {
        setRoles(res.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  // get all permissions
  useEffect(() => {
    axios
      .get("/UserSystemAccess")
      .then(async (res) => {
        await res.data.data.map((permission) => {
          permission.checked = false;
        });
        setPermissions(res.data.data);
        forceUpdate();
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  // useEffect for pagination component
  useEffect(() => {
    console.log(paginatedData);
    if (paginatedData.length != 0) {
      // if (topBarChecked === true || checkedIds.length != 0) {
      //   setTopBarChecked(false);
      //   garbageData.map((i, index) => {
      //     checkedIds.splice(index);
      //   });
      // }
      setRoles(paginatedData.data);
      setCurrentPage(paginatedData.meta.current_page);
      setFrom(paginatedData.meta.from);
    }
  }, [paginatedData]);

  // checked permissions
  const checkedPermissions = (index, permssion_id) => {
    // checked permission
    setPermissions((prev) => {
      prev[index].checked = !prev[index].checked;
      forceUpdate();
      return prev;
    });
    setError((prev) => {
      prev.permission = false;
      forceUpdate();
      return prev;
    });

    // set permssions for store or update role

    if (permissions[index].checked) {
      // find index of that permission_id
      let pIndex = newRole.permissions.indexOf(permssion_id);

      if (pIndex !== -1) {
        setNewRole((prev) => {
          prev.permissions.splice(pIndex, 1);
          forceUpdate();
          return prev;
        });
      }
    } else {
      setNewRole((prev) => {
        prev.permissions.push(permssion_id);
        forceUpdate();
        return prev;
      });
    }
  };
  const [error, setError] = useState({
    title: false,
    title_fa: false,
    permission: false,
  });
  // add new role method
  const addNewRole = () => {
    // axios for create add new role
    console.log(newRole);
    if (
      newRole.title != "" &&
      newRole.title_fa != "" &&
      newRole.permissions.length > 0
    ) {
      axios
        .post("UserSystemRole", newRole)
        .then((res) => {
          console.log(res);
          if (res.data.status) {
            // empty state
            setNewRole((prev) => {
              prev.title = "";
              prev.title_fa = "";
              prev.permissions = [];
              forceUpdate();
              return prev;
            });
            dispatch(ShowAlert(true, res.data.message));
            // update table
            let route = `UserSystemRole?page=${currentPage}`;
            if (roles.length == 5) {
              route = `UserSystemRole?page=${currentPage + 1}`;
              setFirstRute(`UserSystemRole?page=${currentPage + 1}`);
            }
            setFirstRute(`UserSystemRole?page=${currentPage}`);
            axios
              .get(route)
              .then((res) => {
                setRoles(res.data.data);
              })
              .catch((e) => {
                console.log(e);
              });

            axios
              .get("/UserSystemAccess")
              .then(async (res) => {
                await res.data.data.map((permission) => {
                  permission.checked = false;
                });
                setPermissions(res.data.data);
                forceUpdate();
              })
              .catch((e) => {
                console.log(e);
              });
          } else {
            // show error messages
            // dispatch(ShowAlert(true, res.data.message));
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      let text = `برای افزودن نقش پر کردن فیلد های 
      ${newRole.title == "" ? "عنوان انگلیسی و" : ""}
      ${newRole.title_fa == "" ? "عنوان فارسی و" : ""}
      ${newRole.permissions == 0 ? "دسترسی ها" : ""}
      الزامی است .
      `;
      dispatch(ShowAlert(true, text));
      if (newRole.title === "") {
        setError((prev) => {
          prev.title = true;
          forceUpdate();
          return prev;
        });
      }
      if (newRole.title_fa === "") {
        setError((prev) => {
          prev.title_fa = true;
          forceUpdate();
          return prev;
        });
      }
      if (newRole.permissions.length === 0) {
        setError((prev) => {
          prev.permission = true;
          forceUpdate();
          return prev;
        });
      }
    }
  };

  // delete role method
  const destroyRole = (role_id) => {
    axios
      .delete(`UserSystemRole/${role_id}`)
      .then((res) => {
        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));

          // update table
          let route = `UserSystemRole?page=${currentPage}`;
          if (roles.length == 1) {
            route = `UserSystemRole?page=${currentPage - 1}`;
            setFirstRute(`UserSystemRole?page=${currentPage - 1}`);
          }

          setFirstRute(`UserSystemRole?page=${currentPage}`);

          // update table
          axios
            .get("UserSystemRole")
            .then((res) => {
              setRoles(res.data.data);
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          // show error messages
          dispatch(ShowAlert(true, res.data.message));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // edit role method
  const editRole = async (item) => {
    // seitems
    await setNewRole((prev) => {
      prev.title = item.title;
      prev.title_fa = item.title_fa;
      prev.edit_id = item.role_id;
      prev.permissions = Array.from(
        item.accessors,
        (permission) => permission.access_id
      );
      forceUpdate();
      return prev;
    });

    // checked permissions
    permissions.map((permission) => {
      if (newRole.permissions.includes(permission.access_id)) {
        permission.checked = true;
        forceUpdate();
      } else {
        permission.checked = false;
        forceUpdate();
      }
    });
  };

  // method for update role
  const updateRole = () => {
    axios
      .put(`UserSystemRole/${newRole.edit_id}?page=${currentPage}`, newRole)
      .then((res) => {
        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));
          // empty states
          setNewRole((prev) => {
            prev.title = "";
            prev.title_fa = "";
            prev.edit_id = "";
            prev.permissions = [];
            forceUpdate();
            return prev;
          });

          axios
            .get(`UserSystemRole?page=${currentPage}`)
            .then((res) => {
              setRoles(res.data.data);
            })
            .catch((e) => {
              console.log(e);
            });

          axios
            .get("/UserSystemAccess")
            .then(async (res) => {
              await res.data.data.map((permission) => {
                permission.checked = false;
              });
              setPermissions(res.data.data);
              forceUpdate();
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          dispatch(ShowAlert(true, res.data.message));
        }
      });
  };

  // method for search filter
  const searchFilter = async (e) => {
    // set state search
    await setSearch(e.target.value);
      axios
        .get(`UserSystemRole?search=${e.target.value}`)
        .then((res) => {
          setRoles(res.data.data);
          setFirstRute(`UserSystemRole?search=${e.target.value}`)
          forceUpdate();
        })
        .catch((e) => {
          console.log(e);
        });
  };

  return (
    <div className="role-all">
      <TopBar />
      <div className="role-title-search-box">
        <p>افزودن نقش جدید</p>
        <InputComponent
          width="337px"
          height="44px"
          type="text"
          inputType="inputWithIcon"
          iconSide="right"
          iconSrc={SearchLogo}
          value={search}
          backgroundColor="#f7f7f7"
          placeholder="جستجو نقش .."
          borderColor="#dcdcdc"
          onchange={(e) => searchFilter(e)}
        />
      </div>
      <div className="role-table-box">
        <div className="role-inputs-box">
          <div className="role-inputs-box-description">
            <img src={Star} />
            <p>
              نقش ها و سطح دسترسی کاربران هر نقش را از اینجا می توان مدیریت کرد
            </p>
          </div>
          <div className="role-inputs-box-2">
            <p>عنوان فارسی:</p>
            <InputComponent
              value={newRole.title_fa}
              width="377px"
              height="44px"
              type="text"
              inputType="input"
              placeholder="عنوان فارسی"
              borderColor={error.title_fa === false ? "#dcdcdc" : "#F23B3B"}
              onchange={(e) => {
                setNewRole((prev) => {
                  prev.title_fa = e.target.value;
                  forceUpdate();
                  return prev;
                });

                setError((prev) => {
                  prev.title_fa = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
          </div>
          <div className="role-inputs-box-2">
            <p>عنوان انگلیسی:</p>
            <InputComponent
              value={newRole.title}
              width="377px"
              height="44px"
              type="text"
              inputType="input"
              placeholder="عنوان انگلیسی"
              borderColor={error.title === false ? "#dcdcdc" : "#F23B3B"}
              onchange={(e) => {
                setNewRole((prev) => {
                  prev.title = e.target.value;
                  forceUpdate();
                  return prev;
                });
                setError((prev) => {
                  prev.title = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
          </div>
          <div className="role-inputs-box-3">
            <div
              className={
                error.permission === false
                  ? "role-inputs-box-3-checkbox-all-box"
                  : "role-inputs-box-3-checkbox-all-box-error"
              }
            >
              {permissions.length > 0 &&
                permissions.map((permission, pI) => {
                  return (
                    <label
                      htmlFor={`permission${pI}`}
                      key={pI}
                      className="role-inputs-box-3-checkbox-box"
                    >
                      <input
                        value={permission.access_id}
                        style={{ display: "none" }}
                        checked={permission.checked}
                        type="checkbox"
                      />

                      <img
                        onClick={() =>
                          checkedPermissions(pI, permission.access_id)
                        }
                        src={permission.checked ? check : uncheck}
                      />

                      <p
                        onClick={() =>
                          checkedPermissions(pI, permission.access_id)
                        }
                      >
                        {permission.title_fa}
                      </p>
                    </label>
                  );
                })}
            </div>
          </div>
          <div className="role-inputs-box-button-box">
            {newRole.edit_id == "" ? (
              <button
                onClick={() => addNewRole()}
                className="role-inputs-box-button-box-button"
              >
                افزودن نقش جدید
              </button>
            ) : (
              <button
                onClick={() => updateRole()}
                className="role-inputs-box-button-box-button"
              >
                ویرایش نقش
              </button>
            )}
          </div>
        </div>

        <div className="role-table-box-2">
          <div className="role-table-box-2-table-border">
            <div className="role-table-box-2-table-border-top-bar">
              <div className="role-table-box-2-table-border-top-bar-hashtag">
                <p>#</p>
              </div>
              <div className="role-table-box-2-table-border-top-bar-title">
                <p>عنوان</p>
              </div>
              <div className="role-table-box-2-table-border-top-bar-permission">
                <p>دسترسی ها</p>
              </div>
              <div className="role-table-box-2-table-border-top-bar-operation">
                <p>عملیات</p>
              </div>
            </div>

            {roles.length > 0 &&
              roles.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="role-table-box-2-table-border-item-box"
                  >
                    <div className="role-table-box-2-table-border-item-box-number">
                      <p>{index + 1}</p>
                    </div>
                    <div className="role-table-box-2-table-border-item-box-title">
                      <p>{item.title_fa}</p>
                    </div>
                    <div className="role-table-box-2-table-border-item-box-permission">
                      {item.accessors.length > 0 &&
                        item.accessors.map((permission, pIndex) => {
                          if (pIndex < 4) {
                            return (
                              <>
                                <span />
                                <p key={pIndex}>{permission.title_fa}</p>
                              </>
                            );
                          } else if (item.accessors.length == pIndex + 1) {
                            return <p>...</p>;
                          }
                        })}
                    </div>
                    <div className="role-table-box-2-table-border-item-box-operation">
                      <img onClick={() => editRole(item)} src={EditActive} />
                      <img
                        onClick={() => destroyRole(item.role_id)}
                        src={DeleteRed}
                      />
                    </div>
                  </div>
                );
              })}
          </div>

          <Pagination
            firstAxios={firstRoute}
            currentAxios="/UserSystemRole?page="
          />
        </div>
      </div>
    </div>
  );
}
