const iniState = {
  order: null,
};
const SendOrderEditReducer = (state = iniState, action) => {
  switch (action.type) {
    case "SEND_ORDER_Edit":
      return { ...state, order: action.data };

    default:
      return state;
  }
};
export default SendOrderEditReducer;
