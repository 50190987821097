import React, { useEffect, useState } from "react";
import "../../../assets/styles/Blog/GarbageBlog/GarbageBlog.css";
import TopBar from "../../../components/TopBar/TopBar";
import axios from "../../../js/Axios/Axios";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
  useLocation
} from "react-router-dom";
import { ShowAlert } from "../../../action/index";
import InputComponent from "../../../components/TextInputComponent/TextInputComponent";
import Pagination from "../../../components/Pagination/Pagination";
import Modal from "@mui/material/Modal";
import { useSelector, useDispatch } from "react-redux";
import useForceUpdate from "use-force-update";
import AllProductAdd from "../../../assets/icons/AllProductAdd.svg";
import GarbageDelte from "../../../assets/icons/GarbageDelte.svg";
import restore from "../../../assets/icons/restore.svg";
import restoreActive from "../../../assets/icons/restoreActive.svg";

import AllProductTrash from "../../../assets/icons/GarbageDelte.svg";
import AllProductTrashRed from "../../../assets/icons/GarbageDelteRed.svg";



import SearchLogo from "../../../assets/icons/SearchLogo.svg";
import headerCheck from "../../../assets/icons/headerCheck.svg";
import check from "../../../assets/icons/check.svg";
import Delete from "../../../assets/icons/Delete.svg";
import DeleteRed from "../../../assets/icons/DeleteRed.svg";
import Eye from "../../../assets/icons/AllProductEye.svg";
import uncheck from "../../../assets/icons/uncheck.svg";

export default function GarbageProduct() {
  const location = useLocation();
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false); // for open modal
  const [garbageData, setGarbageData] = useState([]); // all garbage data
  const [searchGarbage, setSearchGarbage] = useState(); // seasrch state
  const [currentPage, setCurrentPage] = useState(null); // currentpage for paginated
  const [from, setFrom] = useState(null); // from to paginated
  const [checkedIds, setCheckedIds] = useState([]);
  const [topBarChecked, setTopBarChecked] = useState(false);
  const [blogIds, setBlogIds] = useState({
    blog_ids: [],
  });
  const paginatedData = useSelector((state) => state.PaginatedData);
  // get all garbage data
  useEffect(() => {
    axios
      .get("/blog/trash??isPaginated=1")
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setGarbageData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  useEffect(() => {
    if (paginatedData.length != 0) {
      if (topBarChecked === true || checkedIds.length != 0) {
        setTopBarChecked(false);
        garbageData.map((i, index) => {
          checkedIds.splice(index);
        });
      }
      setGarbageData(paginatedData.data);
      setCurrentPage(paginatedData.meta.current_page);
      setFrom(paginatedData.meta.from);
    }
  }, [paginatedData]);
  const restoreBlog = (id) => {
    let array = {
      blog_ids: [],
    };
    array.blog_ids.push(id);
    axios
      .put("/blog/restoreGarbage", array)
      .then((res) => {
        console.log(res);
        if (res.data.status === 0) {
          dispatch(ShowAlert(true, res.data.message));
        }

        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));
          axios
            .get("/blog/trash??isPaginated=1")
            .then((res) => {
              if (res.data.status) {
                console.log(res);
                setGarbageData(res.data.data);
                setCurrentPage(res.data.meta.current_page);
                setFrom(res.data.meta.from);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const checkBoxOnChange = (id) => {
    if (!blogIds.blog_ids.includes(id)) {
      blogIds.blog_ids.push(id);
    } else {
      var index = blogIds.blog_ids.indexOf(id);
      blogIds.blog_ids.splice(index, 1);
    }
    forceUpdate();
  };
  const allCheckBoxOnChange = () => {
    if (blogIds.blog_ids.length != garbageData.length) {
      garbageData.map((item) => {
        if (!blogIds.blog_ids.includes(item.id)) blogIds.blog_ids.push(item.id);
      });
    } else if (blogIds.blog_ids.length === garbageData.length) {
      garbageData.map((item, i) => {
        blogIds.blog_ids.splice(i);
      });
    }
    forceUpdate();
  };
  const allRestor = () => {
    if (blogIds.blog_ids.length != 0) {
      axios
        .put("/blog/restoreGarbage", blogIds)
        .then((res) => {
          console.log(res);
          if (res.data.status === 0) {
            dispatch(ShowAlert(true, res.data.message));
          }

          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));
            blogIds.blog_ids.map((item, i) => {
              blogIds.blog_ids.splice(i);
            });
            axios
              .get("/blog/trash??isPaginated=1")
              .then((res) => {
                if (res.data.status) {
                  console.log(res);
                  setGarbageData(res.data.data);
                  setCurrentPage(res.data.meta.current_page);
                  setFrom(res.data.meta.from);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      dispatch(ShowAlert(true, "لطفا وبلاگ ها را انتخاب کنید ."))
    }
  };
  const openModalForDelte = () => {
    if (blogIds.blog_ids.length != 0) {
      setModal(true);
      forceUpdate();
    } else {
      dispatch(ShowAlert(true, "لطفا وبلاگ ها را انتخاب کنید ."))
    }
  };
  const cancelDeleteClick = () => {
    setModal(false);
    blogIds.blog_ids.map((item, i) => {
      blogIds.blog_ids.splice(i);
    });
  };
  const compeleteDelete = () => {
    var blogids = JSON.stringify(blogIds.blog_ids);
    axios
      .delete(`/blog/forceDelete?blog_ids=${blogids}`)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          dispatch(ShowAlert(true, res.data.message));
          blogIds.blog_ids.map((item, index) => {
            blogIds.blog_ids.splice(index);
          });
          setModal(false);
          axios
            .get("/blog/trash??isPaginated=1")
            .then((res) => {
              if (res.data.status) {
                console.log(res);
                setGarbageData(res.data.data);
                setCurrentPage(res.data.meta.current_page);
                setFrom(res.data.meta.from);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const searchHandelChange = (e) => {
    axios
      .get(`/blog/trash??isPaginated=1&search=${e.target.value}`)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setGarbageData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const delteBlog = (id) => {
    blogIds.blog_ids.push(id);
    setModal(true);
  };
  return (
    <div className="garbage-All">
      <div className="garbage-top-bar">
        <TopBar />
      </div>
      <div className="garbage-button-search-box">
        <Modal
          onBackdropClick={() => cancelDeleteClick()}
          className="garbage-modal"
          open={modal}
        >
          <div className="garbage-modal-delete-box">
            <div className="garbage-modal-delete-icon-box">
              <p>حذف بلاگ</p>
              <img src={DeleteRed} />
            </div>
            <div className="garbage-modal-delete-box-title-box">
              <p className="garbage-modal-delete-box-title">
                ایا از پاک کردن بلاگ به طور کامل مطمئن هستید ؟
              </p>
            </div>
            <div className="garbage-modal-delete-box-button-box">
              <button
                onClick={() => cancelDeleteClick()}
                className="garbage-modal-delete-box-button-cancel"
              >
                <p className="garbage-modal-delete-box-button-cancel-title">
                  انصراف
                </p>
              </button>

              <button
                onClick={() => compeleteDelete()}
                className="garbage-modal-delete-box-button-delete"
              >
                <p className="garbage-modal-delete-box-button-delete-title">
                  حذف
                </p>
              </button>
            </div>
          </div>
        </Modal>
        <div className="garbage-button-box">
          <button
            onClick={() => navigate("/CreateBlog")}
            className="garbage-add-button"
          >
            <img src={AllProductAdd} />
            <p className="garbage-add-button-title">ایجاد بلاگ</p>
          </button>

          {/* <button
            onClick={() => allRestor()}
            className={
              blogIds.blog_ids.length != 0
                ? "garbage-restor-button"
                : "garbage-restor-button-deactive"
            }
          >
            <img src={restoreActive} />
            <p className="garbage-restor-button-title">بازیابی محصولات</p>
          </button> */}

          {/* <button
            onClick={() => openModalForDelte()}
            className={
              blogIds.blog_ids.length != 0
                ? "garbage-delete-button"
                : "garbage-delete-button-deactive"
            }
          >
            <img src={GarbageDelte} />
            <p className="garbage-delete-button-title">حذف</p>
          </button> */}
        </div>
        <div className="garbage-search-box">
          <InputComponent
            width="337px"
            height="44px"
            type="text"
            inputType="inputWithIcon"
            iconSide="right"
            iconSrc={SearchLogo}
            backgroundColor="#f7f7f7"
            placeholder="جستجو محصول.."
            borderColor="#dcdcdc"
            onchange={(e) => searchHandelChange(e)}
          />
        </div>
      </div>
      <div className="garbage-tabel-box">



        <div style={{
          minWidth: "1500px",
          borderRadius: "10px 10px 0px 0px",
          borderLeft: "solid 1px #dcdcdc",
          borderRight: "solid 1px #dcdcdc",
          cursor: "auto",
          borderTop: "solid 1px #dcdcdc",
          minWidth: "1400px"
        }} className="all-products-header dragscroll">
          <div style={{ cursor: "auto" }} className="all-products-tab-bar">

            <div style={{ cursor: "auto" }} className="all-products-tab-bar-right">

              <p onClick={() => navigate("/ManageBlog")}
                style={
                  location.pathname == "/ManageBlog" ? {
                    color: "#00457b",
                    fontWeight: "bold"
                  } : {}
                }>

                وبلاگ
              </p>



              <p onClick={() => navigate('/GarbageBlog')} style={
                location.pathname == "/GarbageBlog" ? {
                  color: "#00457b",
                  fontWeight: "bold"
                } : {}
              }>
                زباله دان
              </p>
            </div>

            <div style={{ cursor: "auto" }} className="all-products-tab-bar-left">


              <button
                onClick={() => allRestor()}
                style={
                  blogIds.blog_ids.length > 0 ? { backgroundColor: "#008753" } : {}
                }
                className={"restore-button-deactive"}
              // className={
              //   checkedIds != 0
              //     ? "garbage-restor-button"
              //     : "garbage-restor-button-deactive"
              // }
              >

                {
                  blogIds.blog_ids.length > 0 ?
                    <img src={restoreActive} />

                    :
                    <img src={restore} />
                }
                <p style={
                  blogIds.blog_ids.length > 0 ? { color: "#fff" } : {}
                } className="garbage-restor-button-title">بازیابی محصولات</p>
              </button>

              <button style={
                blogIds.blog_ids.length > 0 ? { backgroundColor: "#f23b3b" } : {}
              }
                onClick={() => openModalForDelte()}
                className="manage-blog-trash-button"
              >

                {

                  blogIds.blog_ids.length > 0 ?

                    <img src={AllProductTrash} />
                    :

                    <img src={AllProductTrashRed} />

                }

                <p style={
                  blogIds.blog_ids.length > 0 ? { color: "#fff" } : {}
                }
                >حذف</p>
              </button>




            </div>

          </div>


          <div className="garbage-table-top-bar">
            <p className="manage-blog-top-bar-hashtag">#</p>
            <div className="manage-blog-top-bar-checkbox">
              <label>
                <img
                  src={
                    blogIds.blog_ids.length === garbageData.length &&
                      garbageData.length != 0
                      ? headerCheck
                      : uncheck
                  }
                />
                <input
                  style={{ display: "none" }}
                  onChange={() => allCheckBoxOnChange()}
                  id="allCheckBox"
                  name="allCheckBox"
                  type="checkbox"
                />
              </label>
            </div>
            <p className="manage-blog-top-bar-title">عنوان بلاگ</p>
            <p className="manage-blog-top-bar-visit">تعداد بازدید</p>
            <p className="manage-blog-top-bar-comment">تعداد نظرات</p>
            <p className="manage-blog-top-bar-category">دسته بندی</p>
            <p className="manage-blog-top-bar-writer">نویسنده</p>
            <p className="manage-blog-top-bar-date">تاریخ انتشار</p>
            <p className="manage-blog-top-bar-opation">عملیات</p>
          </div>
        </div>
        {garbageData.map((item, index) => {
          return (
            <div key={index} style={(garbageData.length == index + 1) ?

              {
                borderRadius: "0 0 10px 10px",
                borderLeft: "1px solid #dcdcdc",
                borderRight: "1px solid #dcdcdc",
                borderBottom: "1px solid #dcdcdc"

              } : {
                borderLeft: "1px solid #dcdcdc",
                borderRight: "1px solid #dcdcdc",
                borderBottom: "1px solid #dcdcdc"
              }
            } className="manage-blog-item-box">
              <div className="manage-blog-item-number">
                <p>{from + index}</p>
              </div>
              <div className="manage-blog-item-checkbox">
                <label htmlFor={`name${index}`}>
                  <img
                    src={blogIds.blog_ids.includes(item.id) ? check : uncheck}
                  />
                  <input
                    style={{ display: "none" }}
                    id={`name${index}`}
                    name={`name${index}`}
                    onChange={() => checkBoxOnChange(item.id)}
                    type="checkbox"
                  />
                </label>
              </div>
              <div className="manage-blog-item-title">
                <p>{item.title}</p>
              </div>
              <div className="manage-blog-item-visit">
                <p>{item.visit_count}</p>
              </div>
              <div className="manage-blog-item-comment">
                <p>{item.blog_comments}</p>
              </div>
              <div className="manage-blog-item-category">
                <p>{item.blog_category}</p>
              </div>
              <div className="manage-blog-item-writer">
                <p>{item.author}</p>
              </div>

              <div className="manage-blog-item-date">
                <div
                  className={
                    item.release_status === 1
                      ? "manage-blog-item-date-box"
                      : "manage-blog-item-date-box-2"
                  }
                >
                  <p>
                    {item.release_status === 1 ? item.release_time : "پیش نویس"}
                  </p>
                </div>
              </div>
              <div className="manage-blog-item-operation">
                <img onClick={() => restoreBlog(item.id)} src={restore} />
                <img
                  onClick={() => delteBlog(item.id)}
                  src={DeleteRed}
                // onMouseOver={(e) => (e.currentTarget.src = DeleteRed)}
                // onMouseOut={(e) => (e.currentTarget.src = Delete)}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="garbage-pagination-box">
        <Pagination
          firstAxios="/blog/trash??isPaginated=1"
          currentAxios="/blog/trash??isPaginated=1&page="
        />
      </div>
    </div >
  );
}
