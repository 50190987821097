import React, { useState, useEffect } from "react";
import "../../../assets/styles/ChooseCategoryStyle/ChooseCategory.css";
import CreateIcon from "@mui/icons-material/Create";
import useForceUpdate from "use-force-update";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../../js/Axios/Axios";
import { ShowAlert } from "../../../action/index";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import editIcon from "../../../assets/icons/Iconly-Bold-Edit.svg";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { AddAdminInfo, updateToken } from "../../../action/index";
import CircularProgress from "@mui/material/CircularProgress";
import { maxHeight } from "@mui/system";

function Register() {
  const user_info = useSelector((state) => state.AddAdminInfo);
  const [activityIndicator, setActivityIndicator] = useState(false);
  const [RadioButtonData, setRadioButtonData] = useState([
    {
      tittle: "لوازم خانه",
      EnglishTittle: "Home Appliances",
    },
    {
      tittle: " محصولات دیجیتال",
      EnglishTittle: "Digital products",
    },
    {
      tittle: "لوازم تحریر ",
      EnglishTittle: "Stationery",
    },
    {
      tittle: "ماشین آلات ",
      EnglishTittle: "machinery",
    },
    {
      tittle: "لوازم صنعتی ",
      EnglishTittle: "Industrial supplies",
    },
    {
      tittle: "مد و پوشاک ",
      EnglishTittle: "Fashion and clothing",
    },
    {
      tittle: "خوراکی ",
      EnglishTittle: "Food",
    },
  ]);
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [StoreCategory, setStoreCategory] = useState("");
  const [StoreCategoryEnglish, setStoreCategoryEnglish] = useState("");
  const [StoreInfo, setStoreInfo] = useState({
    name: isEmpty(user_info) ? "" : user_info.user_info["name"],
    family: isEmpty(user_info) ? "" : user_info.user_info["family"],
    phone_number: isEmpty(user_info) ? "" : user_info.user_info["phone_number"],
    password: isEmpty(user_info) ? "" : user_info.user_info["password"],
    storeName: "",
    productSaleCategory: "",
    productSaleCategoryFa: "",
    storeShortDes: "",
  });

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  const eventCategorySelected = (e, myItem, prev) => {
    if (
      (e.target.value = myItem.tittle) ||
      (e.target.value = myItem.EnglishTittle)
    ) {
      setStoreCategory(myItem.tittle);
      setStoreCategoryEnglish(myItem.EnglishTittle);
    }
    setStoreInfo((prev) => {
      return {
        name: prev.name,
        family: prev.family,
        phone_number: prev.phone_number,
        password: prev.password,
        storeName: prev.storeName,
        storeShortDes: prev.storeShortDes,
        productSaleCategoryFa: myItem.tittle,
        productSaleCategory: myItem.EnglishTittle,
      };
    });
  };

  const SendStoreInfo = () => {
    setActivityIndicator(true);
    forceUpdate();
    axios
      .post("/Store", StoreInfo)
      .then((res) => {
        if (res.data.status === 0) {
          dispatch(ShowAlert(true, res.data.message));
          setActivityIndicator(false);
          forceUpdate();
        }
        if (res.data.status) {
          console.log(res);
          dispatch(ShowAlert(true, res.data.message));
          setStoreInfo((prev) => {
            return {
              name: prev.name,
              family: prev.family,
              phone_number: prev.phone_number,
              password: prev.password,
              storeName: prev.storeName,
              storeShortDes: prev.storeShortDes,
              productSaleCategoryFa: prev.productSaleCategoryFa,
              productSaleCategory: prev.productSaleCategory,
              access_token: res.data.access_token,
            };
          });
          setActivityIndicator(false);
          // localStorage.setItem("store_name" , res.data.storeName);
          forceUpdate();
          console.log(StoreInfo);
          dispatch(AddAdminInfo(StoreInfo));
          dispatch(updateToken(res.data.access_token));
          localStorage.setItem("access_token", res.data.access_token);


          // ? redirect to dashboard
          window.location.href = "/";
          // navigate("/");
        }
      })
      .catch((e) => {
        console.log(e);
        setActivityIndicator(false);
        forceUpdate();
      });
  };
  const SendStoreInfoEnter = (e) => {
    if (e.key === "Enter") {
      SendStoreInfo();
    }
  };

  // const reformatData = () => {};

  // const renderThumb = ({ style, ...props }) => {
  //   const thumbStyle = {
  //     borderRadius: 6,
  //     backgroundColor: 'rgba(35, 49, 86, 0.8)',

  //   };
  //   return <div style={{ ...style, ...thumbStyle }} {...props} />;
  // };

  // const CustomScrollbars = props => (
  //   <Scrollbars
  //     renderThumbVertical={renderThumb}
  //     {...props}
  //   />
  // );

  return (
    <div className="ChooseCategoryAll">
      <div className="aut-img">{/* <img  src={login_img}/> */}</div>
      <div className="Verify-Register-All-2">
        <div className="Verify-Register-All-3">
          <div className="Verify-Register-All-4">
            <div className="Verify-Register-All-3-border">
              <div className="Verify-Register-All-3-circle">
                <p>1</p>
              </div>
            </div>
            <hr className="Verify-Register-All-3-line-blue" />
            <div className="Verify-Register-All-3-border">
              <div className="Verify-Register-All-3-circle">
                <p>2</p>
              </div>
            </div>
            <hr className="Verify-Register-All-3-line-blue" />
            <div className="Verify-Register-All-3-border">
              <div className="Verify-Register-All-3-circle">
                <p>3</p>
              </div>
            </div>
          </div>
          <div className="Verify-Register-All-5">
            <p>تایید هویت</p>
            <p>اطلاعات حساب</p>
            <p>اطلاعات فروشگاه</p>
          </div>
        </div>
        <div className="ChooseCategoryBox">
          <h3 className="ChooseCategoryFirstTittleBox">
            فروشگاه شما آماده است
          </h3>
          <p className="ChooseCategorySecondTittle">
            مشخصات نهایی را انتخاب و وارد بخش نهایی شوید
          </p>

          <hr className="ChooseCategoryEdge" />

          <div className="ChooseCategoryInputBox">
            <div className="ChooseCategoryInputBoxName">
              <img
                src={editIcon}
                alt=""
                className="ChooseCategoryInputNameIcon"
              />

              <input
                onChange={(e) =>
                  setStoreInfo((prev) => {
                    return {
                      name: prev.name,
                      family: prev.family,
                      phone_number: prev.phone_number,
                      password: prev.password,
                      storeName: e.target.value,
                    };
                  })
                }
                className="ChooseCategoryNameInput"
                placeholder="عنوان فروشگاه شما به فارسی"
              />
            </div>

            <div className="ChooseCategoryInputBox2Name">
              <img
                src={editIcon}
                alt=""
                className="ChooseCategoryInputNameIcon"
              />

              <input
                placeholder="توضیحات نام فروشگاه"
                className="ChooseCategoryNameInput2"
                onChange={(e) =>
                  setStoreInfo((prev) => {
                    return {
                      name: prev.name,
                      family: prev.family,
                      phone_number: prev.phone_number,
                      password: prev.password,
                      storeName: prev.storeName,
                      storeShortDes: e.target.value,
                    };
                  })
                }
              />
            </div>
          </div>

          <div className="ChooseCategoryRadioButtonBox">
            <p className="ChooseCategoryRadioButtonBoxTittle">
              محصول شما در کدامیک از دسته بندی های زیر قرار میگیرد؟
            </p>

            <div className="chooseCategoryBoxMain">
              <SimpleBar
                forceVisible="y"
                style={{
                  maxHeight: "150px",
                  padding: "0 10px",
                }}
              >
                {RadioButtonData.map((myitem, myindex) => {
                  return (
                    <div
                      key={myindex}
                      className="ChooseCategoryRadioButtonBox2"
                    >
                      <div className="ChooseCategoryRadioButton3">
                        <label style={{ cursor: "pointer" }}>
                          <input
                            className="ChooseCategoryRadioButton"
                            value={myitem.tittle}
                            checked={myitem.tittle == StoreCategory}
                            type="radio"
                            onChange={(e) => eventCategorySelected(e, myitem)}
                          />

                          <div
                            className={
                              StoreCategory == myitem.tittle
                                ? "ChooseCategoryRadioButtonHandMade2"
                                : "ChooseCategoryRadioButtonHandMade"
                            }
                          ></div>
                        </label>
                      </div>

                      <div className="ChooseCategoryRadioButtonTittleBox">
                        <p
                          onClick={() => setStoreCategory(myitem.tittle)}
                          className="ChooseCategoryRadioButtonTittle"
                        >
                          {myitem.tittle}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </SimpleBar>
            </div>
          </div>
          <div className="ChooseCategoryLoginButtonsBox">
            <button
              onKeyDown={(e) => SendStoreInfoEnter(e)}
              onClick={SendStoreInfo}
              className="ChooseCategoryLoginButton"
            >
              {activityIndicator === false ? (
                "ورود به مدیریت"
              ) : (
                <CircularProgress className="aut-button-progress" />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Register;
