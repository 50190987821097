import React, { useState, useEffect } from "react";
import TopBar from "../../components/TopBar/TopBar";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import SearchLogo from "../../assets/icons/SearchLogo.svg";
import Star from "../../assets/icons/Star.svg";
import EditActive from "../../assets/icons/EditActive.svg";
import DeleteRed from "../../assets/icons/DeleteRed.svg";
import Pagination from "../../components/Pagination/Pagination";
import "../../assets/styles/Seller/seller.css";
import useForceUpdate from "use-force-update";
import check from "../../assets/icons/check.svg";
import headerCheck from "../../assets/icons/headerCheck.svg";
import uncheck from "../../assets/icons/uncheck.svg";
import axios from "../../js/Axios/Axios";
import { useSelector, useDispatch } from "react-redux";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { ShowAlert } from "../../action";

export default function Seller() {
  const dispatch = useDispatch();
  // force update
  const forceUpdate = useForceUpdate();
  // state for define new product
  const [sellerProduct, setSellerProduct] = useState({
    available_count: "",
    price: "",
    productPrice_id: "",
  });

  // state for search value
  const [searchValue, setSearchValue] = useState("");

  // state for show all products or seller's product
  const [stateTableData, setStateTableData] = useState("products");

  const [currentPage, setCurrentPage] = useState(null);
  // get paginated from reducer
  const paginatedData = useSelector((state) => state.PaginatedData);
  const [tableData, setTableData] = useState([]);
  const [from, setFrom] = useState(null);

  const [error, setError] = useState({
    available_count: false,
    price: false,
    productPrice_id: false,
  });

  //   initial load
  useEffect(() => {
    axios
      .get("/productPrice")
      .then((res) => {
        if (res.data.status) {
          setTableData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          console.log(res);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  //   paginate effect
  useEffect(() => {
    console.log(paginatedData);
    if (paginatedData.length != 0) {
      setTableData(paginatedData.data);
      setCurrentPage(paginatedData.meta.current_page);
      setFrom(paginatedData.meta.from);
    }
  }, [paginatedData]);

  // stateTableData effect
  useEffect(() => {
    setSearchValue("");
    if (stateTableData == "products") {
      axios
        .get("/productPrice")
        .then((res) => {
          if (res.data.status) {
            setTableData(res.data.data);
            setCurrentPage(res.data.meta.current_page);
            setFrom(res.data.meta.from);
            forceUpdate();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      axios
        .get("/seller/sellerProducts")
        .then((res) => {
          if (res.data.status) {
            setTableData(res.data.data);
            setCurrentPage(res.data.meta.current_page);
            setFrom(res.data.meta.from);
            forceUpdate();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [stateTableData]);

  const addProductID = (id) => {
    setSellerProduct((prev) => {
      prev.productPrice_id == id
        ? (prev.productPrice_id = "")
        : (prev.productPrice_id = id);
      forceUpdate();
      return prev;
    });

    error.productPrice_id == true &&
      setError((prev) => {
        prev.price = false;
        forceUpdate();
        return prev;
      });
  };

  // submit for define seller product
  const submitSellerProduct = () => {
    // http request
    axios
      .post("/seller", sellerProduct)
      .then((res) => {
        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message, "green"));
          setError({
            available_count: false,
            price: false,
            productPrice_id: false,
          });
          setSellerProduct({
            available_count: "",
            price: "",
            productPrice_id: "",
          });
        } else {
          if ("available_count" in res.data.message) {
            setError((prev) => {
              prev.available_count = true;
              forceUpdate();
              return prev;
            });
            dispatch(ShowAlert(true, res.data.message.available_count[0]));
          } else if ("price" in res.data.message) {
            setError((prev) => {
              prev.price = true;
              forceUpdate();
              return prev;
            });
            dispatch(ShowAlert(true, res.data.message.price[0]));
          } else if ("productPrice_id" in res.data.message) {
            setError((prev) => {
              prev.productPrice_id = true;
              forceUpdate();
              return prev;
            });
            dispatch(ShowAlert(true, res.data.message.productPrice_id[0]));
          } else {
            dispatch(ShowAlert(true, res.data.message));
          }
        }
      })
      .catch((e) => {
        dispatch(ShowAlert(true, e.message));
      });
  };

  // function for product search handle
  const productSearchHandle = (e) => {
    setSearchValue(e.target.value);
    if (stateTableData == "products") {
      axios
        .get(`/productPrice?isPaginated=1&page&search=${e.target.value}`)
        .then((res) => {
          if (res.data.status) {
            setTableData(res.data.data);
            setCurrentPage(res.data.meta.current_page);
            setFrom(res.data.meta.from);
            forceUpdate();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      axios
        .get(`/seller/sellerProducts?page&search=${e.target.value}`)
        .then((res) => {
          if (res.data.status) {
            setTableData(res.data.data);
            setCurrentPage(res.data.meta.current_page);
            setFrom(res.data.meta.from);
            forceUpdate();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <div className="TagsAll">
      <div className="TagsTopBar">
        <TopBar />
      </div>
      <div className="TagsUpTitleAndSearchBox">
        <p className="TagsUpTitleAndSearchBoxTitle">انتخاب محصولات</p>
        <InputComponent
          width="337px"
          height="44px"
          type="text"
          inputType="inputWithIcon"
          iconSide="right"
          iconSrc={SearchLogo}
          value={searchValue}
          backgroundColor="#f7f7f7"
          placeholder="جستجو محصول.."
          borderColor="#dcdcdc"
          onchange={(e) => productSearchHandle(e)}
        />
      </div>
      <div className="TagsBox">
        <div style={{ width: "30%" }} className="TagAddTagBox">
          <div className="TagAddTagBoxTittleBox">
            <img className="TagAddTagBoxTittleIcon" src={Star} />
            <p className="TagAddTagBoxTittle">
              محصولات خود را از جدول روبرو انتخاب نموده و پس از تعیین قیمت فروش
              و موجودی، محصول را به فروشگاه خود اضافه نمایید.
            </p>
          </div>
          <div className="TagAddTagBoxInputBox">
            <div className="TagAddTagBoxInputBoxFirstInputBox">
              <p className="TagAddTagBoxInputBoxFirstInputTittle">موجودی :</p>

              <InputComponent
                value={sellerProduct.available_count}
                width="calc(100% - 130px)"
                height="44px"
                type="number"
                placeholder="تعداد موجودی شما از این محصول"
                borderColor={
                  error.available_count === true ? "#F23B3B" : "#dcdcdc"
                }
                inputType="input"
                onchange={(e) => {
                  error.available_count == true &&
                    setError((prev) => {
                      prev.available_count = false;
                      forceUpdate();
                      return prev;
                    });
                  setSellerProduct((prev) => {
                    prev.available_count = e.target.value;
                    forceUpdate();
                    return prev;
                  });
                }}
              />
            </div>

            <div className="TagAddTagBoxInputBoxSecondInputBox">
              <p className="TagAddTagBoxInputBoxSecondInputTittle"> قیمت : </p>
              <InputComponent
                value={sellerProduct.price}
                width="calc(100% - 130px)"
                height="44px"
                type="number"
                placeholder="قیمت به تومان است"
                borderColor={error.price === true ? "#F23B3B" : "#dcdcdc"}
                inputType="input"
                onchange={(e) => {
                  error.price == true &&
                    setError((prev) => {
                      prev.price = false;
                      forceUpdate();
                      return prev;
                    });
                  setSellerProduct((prev) => {
                    prev.price = e.target.value;
                    forceUpdate();
                    return prev;
                  });
                }}
              />
            </div>

            <div className="TagAddTagButtonBox">
              <button
                onClick={() => submitSellerProduct()}
                className="TagAddTagButtonActive"
              >
                {/* {this.state.ButtonTittle} */}
                اضافه کردن محصول
              </button>
            </div>
          </div>
        </div>

        {/* products table */}
        <div
          style={{ width: "calc(70% - 30px)" }}
          className="instance-discount-table-box"
        >
          {/* tabs */}
          <div
            style={{
              borderRadius: "10px 10px 0px 0px",
              borderLeft: "solid 1px #dcdcdc",
              borderRight: "solid 1px #dcdcdc",
              borderTop: "solid 1px #dcdcdc",
              cursor: "auto",
            }}
            className="all-products-tab-bar"
          >
            <div
              style={{ cursor: "auto" }}
              className="all-products-tab-bar-right"
            >
              <p
                onClick={() => setStateTableData("products")}
                style={
                  stateTableData == "products"
                    ? {
                        color: "#00457b",
                        fontWeight: "bold",
                      }
                    : {}
                }
              >
                همه محصولات
              </p>

              <p
                onClick={() => setStateTableData("sellerProducts")}
                style={
                  stateTableData == "sellerProducts"
                    ? {
                        color: "#00457b",
                        fontWeight: "bold",
                      }
                    : {}
                }
              >
                محصولات من
              </p>
            </div>
          </div>
          {/* titles */}
          <div
            style={{ borderRadius: "0" }}
            className="instance-discount-table-top-bar"
          >
            <div className="create-dicount-table-top-bar-hashtag">
              <p>#</p>
            </div>
            <div className="create-dicount-table-top-bar-checkBox">
              {/*  <label htmlFor="allproductCheckBox">
                <input
                  style={{
                    display: "none",
                  }}
                  id="allproductCheckBox"
                  name="allproductCheckBox"
                  type="checkbox"
                  onChange={() => allCheckBox()}
                />

                <img
                  src={
                    tableData.every((i) => productIDs.includes(i.id)) &&
                    tableData.length != 0
                      ? headerCheck
                      : uncheck
                  }
                />
              </label> */}
            </div>
            <div className="create-dicount-table-top-bar-img">
              <p>تصویر</p>
            </div>
            <div className="create-dicount-table-top-bar-name">
              <p>نام محصول</p>
            </div>
            <div className="create-dicount-table-top-bar-barcode">
              <p>شناسه</p>
            </div>
            <div className="create-dicount-table-top-bar-price">
              <p>
                {" "}
                {stateTableData == "products"
                  ? "قیمت پایه (تومان) "
                  : "قیمت شما (تومان)"}
              </p>
            </div>
            <div className="create-dicount-table-top-bar-Inventory">
              <p>ویژگی</p>
            </div>
          </div>

          {/* content */}
          {tableData.map((item, index) => {
            return (
              <div
                key={index}
                style={
                  tableData.length == index + 1
                    ? { borderRadius: "0 0 10px 10px" }
                    : {}
                }
                className="create-dicount-table-item-box"
              >
                <div className="create-dicount-table-item-box-hashtag">
                  <p>{index + from}</p>
                </div>

                <div className="create-dicount-table-item-box-checkbox">
                  <label htmlFor={`product${index}`}>
                    <input
                      style={{
                        display: "none",
                      }}
                      type="checkbox"
                      id={`product${index}`}
                      name={`product${index}`}
                      onChange={() => addProductID(item.id)}
                    />
                    <img
                      src={
                        sellerProduct.productPrice_id == item.id
                          ? check
                          : sellerProduct.productPrice_id == ""
                          ? uncheck
                          : uncheck
                      }
                    />
                  </label>
                </div>
                <div className="create-dicount-table-item-box-image">
                  <span>
                    <img src={item.product_image} />
                  </span>
                </div>
                <div className="create-dicount-table-item-box-name">
                  <p>{item.title}</p>
                </div>
                <div className="create-dicount-table-item-box-barcode">
                  <p>{item.barcode}</p>
                </div>
                <div className="create-dicount-table-item-box-price">
                  <p>
                    {stateTableData == "products"
                      ? item.price
                      : item.seller_price}
                  </p>
                </div>
                <div className="create-dicount-table-item-box-inventory">
                  {item.key != null ? (
                    <p>
                      {item.key == "color" || item.key == "رنگ" ? (
                        <div
                          style={{
                            backgroundColor: item.alternative_value,
                          }}
                          className="color-prop"
                        ></div>
                      ) : (
                        item.key + ":" + item.main_value
                      )}
                    </p>
                  ) : (
                    <span>&#8212;</span>
                  )}
                </div>
              </div>
            );
          })}
          <div className="change-discount-pagination-box">
            <Pagination
              firstAxios={
                stateTableData == "products"
                  ? "/productPrice"
                  : "/seller/sellerProducts"
              }
              currentAxios={
                stateTableData == "products"
                  ? "/productPrice?page="
                  : "/seller/sellerProducts?page="
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
