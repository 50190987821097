const iniState = {
  name: "/productPrice",
};
const AxiosNameDiscountReducer = (state = iniState, action) => {
  switch (action.type) {
    case "AXIOS_NAME_DISCOUNT":
      return { ...iniState, name: action.name };

    default:
      return state;
  }
};
export default AxiosNameDiscountReducer;
