import React, { useEffect, useRef, useState } from "react";
import "../../../assets/styles/Blog/ManageBlogStyle/ManageBlog.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import axios from "../../../js/Axios/Axios";
import { ShowAlert } from "../../../action/index";
import { SendBlogInfoForEdit } from "../../../action/index";
import Modal from "@mui/material/Modal";
import useForceUpdate from "use-force-update";
import { useSelector, useDispatch } from "react-redux";
import TopBar from "../../../components/TopBar/TopBar";
import InputComponent from "../../../components/TextInputComponent/TextInputComponent";
import DropDownComponent from "../../../components/DropDown/DropDown";
import Pagination from "../../../components/Pagination/Pagination";
import AllProductAdd from "../../../assets/icons/AllProductAdd.svg";
import AllProductTrash from "../../../assets/icons/GarbageDelte.svg";
import FilterLogo from "../../../assets/icons/Filter.svg";
import SearchLogo from "../../../assets/icons/SearchLogo.svg";
import AllProductCalender from "../../../assets/icons/AllProductCalender.svg";
import Delete from "../../../assets/icons/Delete.svg";
import DeleteRed from "../../../assets/icons/DeleteRed.svg";
import Eye from "../../../assets/icons/AllProductEye.svg";
import headerCheck from "../../../assets/icons/headerCheck.svg";
import check from "../../../assets/icons/check.svg";
import uncheck from "../../../assets/icons/uncheck.svg";
import * as moment from "jalali-moment";
import Filter from "../../../components/Filter/Filter";
import { Calendar } from "react-datepicker2";
import EditActive from "../../../assets/icons/EditActive.svg";

import WhiteCheck from "../../../assets/icons/WhiteCheck.svg";
import WhiteCross from "../../../assets/icons/WhiteCross.svg";

// import AllProductTrash from "../../../assets/icons/GarbageDelte.svg";
import AllProductTrashRed from "../../../assets/icons/GarbageDelteRed.svg";

import SimpleBar from "simplebar-react";
import ScrollDrag from "react-indiana-drag-scroll";

export default function ManageBlog() {
  const location = useLocation();
  const scrollTitleRef = useRef();
  const scrollContentRef = useRef();
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const [allBlogs, setAllBlogs] = useState([]);
  const [idSelected, setIdSelected] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [from, setFrom] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [firstAxios, setFirstAxios] = useState("/blog?isPaginated=1");
  const [currentAxios, setCurrentAxios] = useState("/blog?isPaginated=1&page=");
  const paginatedData = useSelector((state) => state.PaginatedData); // get paginated from reducer
  useEffect(() => {
    axios
      .get("/blog?isPaginated=1")
      .then((res) => {
        console.log(res);
        setAllBlogs(res.data.data);
        setFrom(res.data.meta.from);
        setCurrentPage(res.data.meta.current_page);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const Data = (data , route) => {

    setAllBlogs(data.data);
    setFrom(data.meta.from);
    setCurrentPage(data.meta.current_page);
    setFirstAxios(route);
    setCurrentAxios(`${route}&page=`);
  };
  useEffect(() => {
    console.log(paginatedData);
    if (paginatedData.length != 0) {
      // if (topBarChecked === true || checkedIds.length != 0) {
      //   setTopBarChecked(false);
      //   garbageData.map((i, index) => {
      //     checkedIds.splice(index);
      //   });
      // }
      setAllBlogs(paginatedData.data);
      setCurrentPage(paginatedData.meta.current_page);
      setFrom(paginatedData.meta.from);
    }
  }, [paginatedData]);

  // useeffect for SimpleBar and ScrollDrag
  useEffect(() => {
    // set scroll position title and content for together

    const handleScrollTitle = (e) => {
      scrollContentRef.current.scrollLeft = scrollTitleRef.current.scrollLeft;
    };

    scrollTitleRef.current.addEventListener("scroll", handleScrollTitle);

    const handleScrollContent = (e) => {
      scrollTitleRef.current.scrollLeft = scrollContentRef.current.scrollLeft;
    };
    scrollContentRef.current.addEventListener("scroll", handleScrollContent);
  }, []);

  const searchBlog = (e) => {
    axios
      .get(`/blog?isPaginated=1&search=${e.target.value}`)
      .then((res) => {
        if (res.data.status) {
          setFirstAxios(`/blog?isPaginated=1&search=${e.target.value}`);
          setCurrentAxios(`/blog?isPaginated=1&search=${e.target.value}&page=`)
          setAllBlogs(res.data.data);
          setFrom(res.data.meta.from);
          setCurrentPage(res.data.meta.current_page);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const checkBoxOnChange = (id) => {
    if (!idSelected.includes(id)) {
      idSelected.push(id);
    } else {
      var indexof = idSelected.indexOf(id);
      idSelected.splice(indexof, 1);
    }
    forceUpdate();
  };
  const allCheckBoxOnChange = () => {
    if (idSelected.length === allBlogs.length) {
      allBlogs.map((item, index) => {
        idSelected.splice(index);
      });
    } else {
      allBlogs.map((item, index) => {
        if (!idSelected.includes(item.id)) {
          idSelected.push(item.id);
        }
      });
    }
    forceUpdate();
  };

  const deleteBlog = () => {
    if (idSelected.length != 0) {
      console.log({ blog_ids: idSelected });
      let ids = JSON.stringify(idSelected);
      axios
        .delete(`/blog/throwingGarbage?blog_ids=${ids}`)
        .then((res) => {
          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));

            axios
              .get("/blog?isPaginated=1")
              .then((res) => {
                console.log(res);
                setAllBlogs(res.data.data);
                setFrom(res.data.meta.from);
                setIdSelected([]);
                setCurrentPage(res.data.meta.current_page);
              })
              .catch((e) => {
                console.log(e);
              });
          }
          console.log(res);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      console.log("teste delere");
      dispatch(ShowAlert(true, "لطفا وبلاگ ها را انتخاب کنید ."));
    }
  };

  const deleteOneBlog = (id) => {
    if (idSelected.length === 0) {
      let idArray = [];
      idArray.push(id);
      let ids = JSON.stringify(idArray);
      axios
        .delete(`/blog/throwingGarbage?blog_ids=${ids}`)
        .then((res) => {
          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));

            axios
              .get("/blog?isPaginated=1")
              .then((res) => {
                console.log(res);
                setAllBlogs(res.data.data);
                setFrom(res.data.meta.from);
                setCurrentPage(res.data.meta.current_page);
              })
              .catch((e) => {
                console.log(e);
              });
          }
          console.log(res);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  const editBlog = (id) => {
    axios
      .get(`/blog/${id}/edit`)
      .then((res) => {
        console.log(res);
        dispatch(SendBlogInfoForEdit(res.data.data));
        navigate("/EditBlog");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const showFilterClick = () => {
    setShowFilter(!showFilter);
  };

  return (
    <div className="manage-blog-all">
      <div className="manage-blog-top-bar">
        <TopBar />
      </div>
      <div className="manage-blog-search-dropdown-buttons-box">
        <div className="manage-blog-buttons-box">
          <button
            onClick={() => navigate("/CreateBlog")}
            className="manage-blog-create-blog-button"
          >
            <img src={AllProductAdd} />
            <p>ایجاد بلاگ</p>
          </button>
          <button
            onClick={() => showFilterClick()}
            className="manage-blog-show-filter-button"
          >
            <img src={FilterLogo} />
            {showFilter === false ? (
              <p>نمایش فیلتر ها</p>
            ) : (
              <p>بستن فیلتر ها</p>
            )}
          </button>
          {/* <button
            onClick={() => deleteBlog()}
            className="manage-blog-trash-button"
          >
            <img src={AllProductTrash} />
            <p>زباله دان</p>
          </button> */}
        </div>
        <div className="manage-blog-search-input-box">
          <InputComponent
            width="337px"
            height="44px"
            type="text"
            inputType="inputWithIcon"
            iconSide="right"
            iconSrc={SearchLogo}
            backgroundColor="#f7f7f7"
            placeholder="جستجو بلاگ.."
            borderColor="#dcdcdc"
            onchange={(e) => searchBlog(e)}
          />
        </div>
      </div>
      <div className="manage-blog-table-box">
        <div
          className={
            showFilter === false
              ? "manage-blog-table-box-2"
              : "manage-blog-table-box-3"
          }
        >
          <SimpleBar
            forceVisible="x"
            // scrollableNodeProps={{ ref: this.scrollTitleRef }}
            style={{
              width: "calc(100% - 3px)",
              borderLeft: "1px solid rgb(220, 220, 220)",
              borderRight: "1px solid rgb(220, 220, 220)",
              borderBottom: "1px solid rgb(220, 220, 220)",
              borderRadius: "10px 10px 0px 0px",
              cursor: "grab",
            }}
          >
            <ScrollDrag innerRef={scrollTitleRef}>
              <div
                style={{ minWidth: "1500px" }}
                className={"all-products-header dragscroll"}
              >
                <div className="all-products-tab-bar">
                  <div className="all-products-tab-bar-right">
                    <p
                      onClick={() => navigate("/ManageBlog")}
                      style={
                        location.pathname == "/ManageBlog"
                          ? {
                              color: "#00457b",
                              fontWeight: "bold",
                            }
                          : {}
                      }
                    >
                      وبلاگ
                    </p>

                    <p
                      onClick={() => navigate("/GarbageBlog")}
                      style={
                        location.pathname == "/GarbageBlog"
                          ? {
                              color: "#00457b",
                              fontWeight: "bold",
                            }
                          : {}
                      }
                    >
                      زباله دان
                    </p>
                  </div>

                  <div className="all-products-tab-bar-left">
                    <button
                      style={
                        idSelected.length > 0
                          ? { backgroundColor: "#f23b3b" }
                          : {}
                      }
                      onClick={() => deleteBlog()}
                      className="manage-blog-trash-button"
                    >
                      {idSelected.length > 0 ? (
                        <img src={AllProductTrash} />
                      ) : (
                        <img src={AllProductTrashRed} />
                      )}

                      <p style={idSelected.length > 0 ? { color: "#fff" } : {}}>
                        زباله دان
                      </p>
                    </button>
                  </div>
                </div>

                <div className="manage-blog-table-top-bar">
                  <p className="manage-blog-top-bar-hashtag">#</p>
                  <div className="manage-blog-top-bar-checkbox">
                    <label>
                      <img
                        src={
                          idSelected.length === allBlogs.length &&
                          allBlogs.length != 0
                            ? headerCheck
                            : uncheck
                        }
                      />
                      <input
                        style={{ display: "none" }}
                        onChange={() => allCheckBoxOnChange()}
                        id="allCheckBox"
                        name="allCheckBox"
                        type="checkbox"
                      />
                    </label>
                  </div>
                  <p className="manage-blog-top-bar-title">عنوان بلاگ</p>
                  <p className="manage-blog-top-bar-visit">تعداد بازدید</p>
                  <p className="manage-blog-top-bar-comment">تعداد نظرات</p>
                  <p className="manage-blog-top-bar-category">دسته بندی</p>
                  <p className="manage-blog-top-bar-writer">نویسنده</p>
                  <p className="manage-blog-top-bar-date">تاریخ انتشار</p>
                  <p className="manage-blog-top-bar-opation">عملیات</p>
                </div>
              </div>
            </ScrollDrag>
          </SimpleBar>

          <SimpleBar
            forceVisible="x"
            // scrollableNodeProps={{ ref: this.scrollContentRef }}
            style={{
              width: "calc(100% - 3px)",
              borderLeft: "solid 1px #dcdcdc",
              borderRight: "solid 1px #dcdcdc",
              borderBottom: "solid 1px #dcdcdc",
              borderRadius: "0 0 10px 10px",
            }}
          >
            <ScrollDrag
              className="scroll-container"
              innerRef={scrollContentRef}
            >
              {allBlogs.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={
                      allBlogs.length != index + 1
                        ? {
                            borderBottom: "solid 1px #dcdcdc",
                          }
                        : {}
                    }
                    className="manage-blog-item-box"
                  >
                    <div className="manage-blog-item-number">
                      <p>{from + index}</p>
                    </div>
                    <div className="manage-blog-item-checkbox">
                      <label htmlFor={`name${index}`}>
                        <img
                          src={idSelected.includes(item.id) ? check : uncheck}
                        />
                        <input
                          style={{ display: "none" }}
                          id={`name${index}`}
                          name={`name${index}`}
                          onChange={() => checkBoxOnChange(item.id)}
                          type="checkbox"
                        />
                      </label>
                    </div>
                    <div className="manage-blog-item-title">
                      <p>{item.title}</p>
                    </div>
                    <div className="manage-blog-item-visit">
                      <p>{item.visit_count}</p>
                    </div>
                    <div className="manage-blog-item-comment">
                      <p>{item.blog_comments}</p>
                    </div>
                    <div className="manage-blog-item-category">
                      <p>{item.blog_category}</p>
                    </div>
                    <div className="manage-blog-item-writer">
                      <p>{item.author}</p>
                    </div>

                    <div className="manage-blog-item-date">
                      <div
                        className={
                          item.release_status === 1
                            ? "manage-blog-item-date-box"
                            : "manage-blog-item-date-box-2"
                        }
                      >
                        <p>
                          {item.release_status === 1
                            ? item.release_time
                            : "پیش نویس"}
                        </p>
                      </div>
                    </div>
                    <div className="manage-blog-item-operation">
                      <img onClick={() => editBlog(item.id)} src={EditActive} />
                      <img
                        onClick={() => deleteOneBlog(item.id)}
                        src={DeleteRed}
                        // onMouseOver={(e) => (e.currentTarget.src = DeleteRed)}
                        // onMouseOut={(e) => (e.currentTarget.src = Delete)}
                      />
                    </div>
                  </div>
                );
              })}
            </ScrollDrag>
          </SimpleBar>

          <div className="manage-blog-paginate-box">
            <Pagination firstAxios={firstAxios} currentAxios={currentAxios} />
          </div>
        </div>
        {showFilter === true && (
          <Filter
            Date={true}
            Category={true}
            Situation={false}
            CategoryAxios={"/blogCategory"}
            Data={Data}
            axiosURL="/blog?isPaginated=1"
          />
        )}
      </div>
    </div>
  );
}
