import React from "react";
import "../../assets/styles/EditStore/EditStore.css";
import TopBar from "../../components/EditStoreComponents/EditStoreTopBar";
import SliderBox from "../../components/EditStoreComponents/EditStoreSlider";
import EditStoreOption from "../../components/EditStoreComponents/EditStoreOption";
import EditStoreTopProduct from "../../components/EditStoreComponents/EditStoreTopProduct";
import EditStoreFooter from "../../components/EditStoreComponents/EditStoreFooter";

export default function EditStore() {
  return (
    <div className="Edit-store-all">
      <div className="Edit-Store-header">
        <TopBar />
      </div>
      <div className="Edit-Store-slider">
        <SliderBox />
      </div>
      <div className="Edit-Store-Option">
        <EditStoreOption />
      </div>
      <div className="Edit-Store-top-product">
        <EditStoreTopProduct/>
      </div>
      <div className="Edit-Store-footer">
        <EditStoreFooter/>
      </div>
    </div>
  );
}
