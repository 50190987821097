import React from "react";
import "../../../assets/styles/SuccessNewPasswordStyle/SuccessNewPassword.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";

function SuccessNewPassword() {

  const navigate = useNavigate();

  return (
    <div className="SuccessPasswordAll">


      <div className="SuccessPasswordBox">
        <h3 className="SuccessPasswordFirstTittleBox">
          بازیابی رمز عبور
        </h3>
        <p className="SuccessPasswordSecondTittle">
          اکنون رمز عبور شما تغییر کرد
        </p>

        <hr className="SuccessPasswordEdge" />

        <div className="SuccessPasswordSuccessMessageTittleBox">
          <div className="SuccessPasswordSuccessMessageTittleBox2">
            <p className="SuccessPasswordSuccessMessageTittle">
              رمز عبور خود را با موفقیت تغییر داده‌اید برای ورود به فروشگاه
              اقدام کنید
            </p>
          </div>
        </div>

        <div className="SuccessPasswordButtonBox">
          <button onClick={() => navigate("/")} className="SuccessPasswordButton">
            ورود به فروشگاه
          </button>
        </div>
      </div>




      <div className="AutLoginUnderBox">
        <div className="AutLoginUnderBoxUpBox">
          <p className="AutLoginForgetPasswordTittle">
            رمز عبور خود را فراموش کرده‌اید؟
          </p>
          <button
            onClick={() => navigate("/ForgetPassword")}
            className="AutLoginUnderBoxUpBoxButton"
          >

            بازیابی رمز عبور

          </button>
        </div>


        <hr className="AutLoginUnderBoxEdge" />


        <div className="AutLoginUnderBoxDownBox">
          <p className="AutLoginForgetRegisterTittle">
            {" "}
            هنوز در ویتسل ثبت نام نکرده‌اید؟{" "}
          </p>
          <button
            onClick={() => navigate("/PhoneNumberRegister")}
            className="AutLoginUnderBoxDownBoxButton"
          >
            ثبت نام
          </button>
        </div>
      </div>

    </div>
  );
}
export default SuccessNewPassword;
