import React, { useEffect, useState } from "react";
import "../../assets/styles/DropDownStyle/DropDown.css";
import ArrowDown from "../../assets/icons/ArrowDown.svg";
import axios from "../../js/Axios/Axios";
import DeleteBlogTag from "../../assets/icons/DeleteBlogTag.svg";
import Edit from "../../assets/icons/Edit.svg";
import check from "../../assets/icons/check.svg";
import uncheck from "../../assets/icons/uncheck.svg";
import Location from "../../assets/icons/Location.svg";
import PostCode from "../../assets/icons/PostCode.svg";
import PostCodeBlack from "../../assets/icons/PostCodeBlack.svg";
import LocationBlack from "../../assets/icons/LocationBlack.svg";
import useForceUpdate from "use-force-update";
import { lineHeight } from "@mui/system";

export default function DropDown(props) {
  const {
    statcItem,
    width,
    onchange,
    height,
    value,
    show,
    id,
    itemClick,
    search,
    backgroundColor,
    borderColor,
    dropDownHeight,
    zindex,
    data,
    axiosurl,
    axiosname,
    placeholder,
    disabledInput,
    dropLable,
    iconClick,
    enterClick,
  } = props;

  // width = "WIDTH FOR INPUT BOX OF DROPDOWN"
  //  height = "HEIGHT FOR INPUT BOX OF DROPDOWN"
  // onchange = "INPUTS DROPDOWN ONCHANGE"
  // value = "INPUTS VALUE"
  // show = "FoR SHOWING DOWNBOX OF DROPDOWN"
  // id = "INPUT ID"
  // itemClick = "ONCLICK FOR ITEMS SHOW IN DOWN BOX OF DROPDOWN"
  // search = "STATE FOR SEARCH IN INPUT"
  // backgroundColor = "BACKGROUND COLOR"
  // borderColor = "BORDER COLOR"
  // dropDownHeight ="HEIGHT OF DOWN BOX OF DROPDOWN"
  //zindex = "ZINDEX"
  //data = "IF DATA IN PARENT PASS DATA WITH DATA PROP"
  //axiosurl = "IF YOU WANT AXIOS WHEN DROP DOWN OPEN SEND URL WITH AXIOSURL PROP"
  // axiosname = "FOR IF"
  // placeholder = "FOR PLACEHOLDER "
  // iconClick = onClick Icon
  // enterClick = enter onClick
  const forceUpdate = useForceUpdate();
  const [showDropDown, setShowDropDown] = useState(false);
  const [axiosData, setAxiosData] = useState([]);
  const [respons, setRespons] = useState(true);

  const showDropDownClick = () => {
    setShowDropDown(!showDropDown);
    if (showDropDown === false) {
      if (axiosurl != undefined) {
        if (axiosname === "PropertyValuedata") {
          axios
            .get(`${axiosurl}`)
            .then((res) => {
              console.log(res);
              const tags = res.data.data.property_values;
              setAxiosData(tags);
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          axios
            .get(`${axiosurl}`)
            .then((res) => {
              console.log(res);
              const tags = res.data.data;
              setAxiosData(tags);
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
    }
  };
  useEffect(() => {
    if (show === false) {
      setShowDropDown(false);
    }
  });
  const checkState = (id, title, index) => {
    data[index].checked = !data[index].checked;
    props.Data(data[index]);
    forceUpdate();
  };
  const [hoverIndex, setHoverIndex] = useState(null);
  const hover = (index) => {
    setHoverIndex(index);
    forceUpdate();
  };
  const cancelHover = () => {
    setHoverIndex(null);
    forceUpdate();
  };
  const enterOnClick = (e) => {
    if (enterClick !== undefined) {
      if (e.key === "Enter") {
        enterClick();
      }
    }
  };
  useEffect(() => {
    if (axiosData.length != 0) {
      if (search != "") {
        if (axiosname === "Propertydata") {
          var result = axiosData.filter((word) => word.key.includes(search));
        }
        if (axiosname === "PropertyValuedata") {
          var result = axiosData.filter((word) => word.value.includes(search));
        }
        if (axiosname === "Blogtagdata") {
          var result = axiosData.filter((word) => word.title.includes(search));
        }
        if (axiosname === "variablePrice") {
          var result = axiosData.filter((word) => word.key.includes(search));
        }

        if (
          axiosname != "Propertydata" &&
          axiosname != "PropertyValuedata" &&
          axiosname != "Blogtagdata" &&
          axiosname != "variablePrice" &&
          axiosname != "province"
        ) {
          var result = axiosData.filter((word) => word.title.includes(search));
        }
        if (result.length != 0) {
          setRespons(true);
        } else if (result.length === 0) {
          setRespons(false);
        }
      } else {
        setRespons(true);
      }
      console.log(respons);
    } else {
      if (data != undefined) {
        if (search != "") {
          if (axiosname === undefined) {
            var result = data.filter((word) => word.title.includes(search));
            console.log(result);
          }
          if (axiosname === "province" || axiosname === "Stateprovince") {
            var result = data.filter((word) => word.title.includes(search));
          }
          if (result != undefined) {
            console.log(result);
            if (result.length != 0) {
              setRespons(true);
            } else if (result.length === 0) {
              setRespons(false);
            }
          } else {
            setRespons(true);
          }
        } else {
          setRespons(true);
        }
      }
    }
  }, [search]);

  return (
    <>
      {showDropDown === true && (
        <span
          onClick={() => setShowDropDown(!showDropDown)}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0",
            right: "0",
            zIndex: `${zindex - 1}`,
            cursor: "pointer",
          }}
        ></span>
      )}
      {axiosname != "variablePrice" && (
        <div
          style={
            showDropDown === false
              ? {
                  display: "flex",
                  position: "relative",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: `${width}`,
                  height: `${height}`,
                  backgroundColor: "#fff",
                  transition: ".3s all",
                  borderWidth: "1px",
                  outline: "none",
                  borderRadius: "10px",
                  borderStyle: "solid",
                  borderColor: `${
                    borderColor != undefined ? borderColor : "#dcdcdc"
                  }`,
                  padding: "0px 10px",
                  direction: "rtl",
                }
              : {
                  display: "flex",
                  position: "relative",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: `${width}`,
                  height: `${height}`,
                  backgroundColor: "#fff",
                  transition: ".3s all",
                  borderLeftWidth: "1px",
                  borderTopWidth: "1px",
                  borderRightWidth: "1px",
                  outline: "none",
                  borderRadius: "10px 10px 0px 0px",
                  borderRightStyle: "solid",
                  borderLeftStyle: "solid",
                  borderTopStyle: "solid",
                  borderRightColor: `${
                    borderColor != undefined ? borderColor : "#dcdcdc"
                  }`,
                  borderLeftColor: `${
                    borderColor != undefined ? borderColor : "#dcdcdc"
                  }`,
                  borderTopColor: `${
                    borderColor != undefined ? borderColor : "#dcdcdc"
                  }`,
                  padding: "0px 10px",
                  direction: "rtl",
                }
          }
        >
          {axiosname === "address" ? (
            <p
              onClick={() => showDropDownClick()}
              style={{
                display: "flex",
                alignItems: "center",
                width: "95%",
                height: "100%",
                cursor: "pointer",
                fontFamily: "IRANSansFaNum",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "25px",
                color: "#BBBBBB",
              }}
            >
              آدرس
            </p>
          ) : (
            <input
              onChange={onchange}
              placeholder={placeholder}
              value={value}
              id={id}
              className="DropDownComponentInput"
              onKeyDown={(e) => enterOnClick(e)}
              onClick={() => showDropDownClick()}
              style={{
                display: "flex",
                height: "85%",
                width: "95%",
                border: "none",
                outline: "none",
                cursor: "pointer",
                backgroundColor: "inherit",
                fontFamily: "IRANSansFaNum",
                direction: "rtl",
                fontSize: "16px",
                color: "#bbb",
              }}
            />
          )}
          <button
            onClick={() => showDropDownClick()}
            style={
              showDropDown === false
                ? {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "85%",
                    cursor: "pointer",
                    width: "5%",
                    border: "none",
                    outline: "none",
                    backgroundColor: "unset",
                    transform: "rotate(0deg)",
                    transition: "transform 0.3s",
                  }
                : {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    height: "85%",
                    width: "5%",
                    border: "none",
                    outline: "none",
                    backgroundColor: "unset",
                    transform: "rotate(180deg)",
                    transition: "transform 0.3s",
                  }
            }
          >
            <img style={{ cursor: "pointer" }} src={ArrowDown} />
          </button>

          <div
            className="DropDownComponentBox"
            style={
              showDropDown === true
                ? {
                    display: "flex",
                    justifyContent: `${
                      respons === true ? "flex-start" : "center"
                    }`,
                    alignItems: `${respons === true ? "flex-start" : "center"}`,
                    flexDirection: "column",
                    position: "absolute",
                    borderRadius: "0px 0px 10px 10px",
                    borderLeftWidth: "1px",
                    borderRightWidth: "1px",
                    borderBottomWidth: "1px",
                    borderLeftColor: `${
                      borderColor != undefined ? borderColor : "#dcdcdc"
                    }`,
                    borderRightColor: `${
                      borderColor != undefined ? borderColor : "#dcdcdc"
                    }`,
                    borderBottomColor: `${
                      borderColor != undefined ? borderColor : "#dcdcdc"
                    }`,
                    borderLeftStyle: "solid",
                    borderRightStyle: "solid",
                    borderBottomStyle: "solid",
                    width: "100%",
                    height: `${respons === true ? dropDownHeight : "100px"}`,
                    zIndex: `${zindex}`,
                    backgroundColor: "#fff",
                    top: "43px",
                    right: "-1px",
                    transition: "height 0.3s",
                    overflow: "scroll",
                  }
                : {
                    display: "flex",
                    position: "absolute",
                    borderRadius: "0px 0px 10px 10px",
                    borderLeftWidth: "0px",
                    borderRightWidth: "0px",
                    borderBottomWidth: "0px",
                    borderLeftColor: `${
                      borderColor != undefined ? borderColor : "#dcdcdc"
                    }`,
                    borderRightColor: `${
                      borderColor != undefined ? borderColor : "#dcdcdc"
                    }`,
                    borderBottomColor: `${
                      borderColor != undefined ? borderColor : "#dcdcdc"
                    }`,
                    borderLeftStyle: "solid",
                    borderRightStyle: "solid",
                    borderBottomStyle: "solid",
                    width: "100%",
                    height: "0px",
                    backgroundColor: "#fff",
                    top: "43px",
                    right: "-1px",
                    transition: "height 0.3s",
                  }
            }
          >
            {respons === false && showDropDown === true && (
              <p
                style={{
                  alignItems: "center",
                  fontFamily: "IRANSansFaNum",
                  direction: "rtl",
                  fontSize: "18px",
                  color: "#bbb",
                }}
              >
                هیچ داده ای یافت نشد .
              </p>
            )}
            {/* /////////////////////// */}
            {/* /////////////////// */}
            {axiosData.length != 0 &&
              axiosname === "Blogtagdata" &&
              axiosData
                .filter((myitem) => {
                  if (search == "") {
                    return myitem;
                  } else if (
                    myitem.title.toLowerCase().includes(search.toLowerCase())
                  )
                    return {
                      myitem,
                    };
                })
                .map((item, index) => {
                  return (
                    // <div >
                    <>
                      {showDropDown === true && respons === true ? (
                        <div
                          key={index}
                          className="DropDownComponentItem"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            minHeight: "30px",
                            cursor: "pointer",
                            marginTop: "20px",
                          }}
                        >
                          <p
                            onClick={() =>
                              itemClick(item.title, item.id, axiosData)
                            }
                            style={{
                              display: "flex",
                              width: "80%",
                              height: "100%",
                              alignItems: "center",
                              marginRight: "10px",
                              fontFamily: "IRANSansFaNum",
                              direction: "rtl",
                              fontSize: "16px",
                              color: "#bbb",
                              cursor: "pointer",
                            }}
                          >
                            {item.title}
                          </p>
                          <button
                            onClick={() => iconClick(item.id)}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "15%",
                              height: "30px",
                              border: "none",
                              outline: "none",
                              backgroundColor: "unset",
                              cursor: "pointer",
                            }}
                          >
                            {/*
                            <img
                            style={{
                              display: "flex",
                              width: "20px",
                              height: "20px",
                                cursor: "pointer",
                                // marginLeft: "10px",
                              }}
                              src={DeleteBlogTag}
                            /> */}
                          </button>
                        </div>
                      ) : null}
                    </>
                    // </div>
                  );
                })}

            {/* ///////////////////////////////////////. */}
            {data !== undefined &&
              axiosname === "address" &&
              data.length === 0 &&
              showDropDown === true && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    padding: "10px 10px 16px 10px",
                    cursor: "pointer",
                    borderBottom: "solid 1px #DCDCDC",
                  }}
                >
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontFamily: "IRANSansFaNum",
                      fontWeight: "400px",
                      direction: "rtl",
                      fontSize: "16px",
                      color: "#818181",
                    }}
                  >
                    {" "}
                    هیچ داده ای یافت نشد
                  </p>
                </div>
              )}
            {data != undefined &&
              axiosname === "address" &&
              data.map((item, index) => {
                return (
                  <>
                    {showDropDown === true && (
                      <div
                        onClick={() => itemClick(item)}
                        onMouseEnter={() => hover(index)}
                        onMouseLeave={() => cancelHover()}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          width: "calc(100% - 20px)",
                          height: "auto",
                          padding: "10px 10px 16px 10px",
                          cursor: "pointer",
                          borderBottom: "solid 1px #DCDCDC",
                          backgroundColor: `${
                            hoverIndex === index ? "#F3F3F3" : "#fff"
                          }`,
                        }}
                        key={index}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            height: "auto",
                          }}
                        >
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontFamily: "IRANSansFaNum",
                              fontWeight: "900",
                              direction: "rtl",
                              fontSize: "16px",
                              color: "#000000",
                              cursor: "pointer",
                            }}
                          >
                            {item.title}
                          </p>
                          {/* <img src={Edit} /> */}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            height: "auto",
                            padding: "5px 0px 0px 0px",
                          }}
                        >
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontFamily: "IRANSansFaNum",
                              fontWeight: "400px",
                              direction: "rtl",
                              fontSize: "16px",
                              color: ` ${
                                hoverIndex === index ? "#333333" : "#818181"
                              }`,
                              cursor: "pointer",
                            }}
                          >
                            {item.address}
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            height: "auto",
                            padding: "12px 0px 0px 0px",
                          }}
                        >
                          <img
                            src={
                              hoverIndex === index ? LocationBlack : Location
                            }
                          />
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontFamily: "IRANSansFaNum",
                              fontWeight: "600",
                              direction: "rtl",
                              fontSize: "16px",
                              color: "#000000",
                              cursor: "pointer",
                              marginRight: "15px",
                            }}
                          >
                            {item.state}
                          </p>
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontFamily: "IRANSansFaNum",
                              fontWeight: "400",
                              direction: "rtl",
                              fontSize: "16px",
                              color: ` ${
                                hoverIndex === index ? "#333333" : "#818181"
                              }`,
                              cursor: "pointer",
                              marginRight: "5px",
                            }}
                          >
                            {item.city}
                          </p>
                          <img
                            style={{ marginRight: "37px" }}
                            src={
                              hoverIndex === index ? PostCodeBlack : PostCode
                            }
                          />
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontFamily: "IRANSansFaNum",
                              fontWeight: "400",
                              direction: "rtl",
                              fontSize: "16px",
                              color: ` ${
                                hoverIndex === index ? "#333333" : "#818181"
                              }`,
                              cursor: "pointer",
                              marginRight: "15px",
                            }}
                          >
                            {item.postCode}
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}

            {/* //////////////////////////////////// */}
            {data != undefined &&
              (axiosname === "province" || axiosname === "Stateprovince") &&
              data
                .filter((myitem) => {
                  if (search == "") {
                    return myitem;
                  } else if (
                    myitem.title.toLowerCase().includes(search.toLowerCase())
                  )
                    return {
                      myitem,
                    };
                })
                .map((item, index) => {
                  return (
                    // <div >
                    <>
                      {showDropDown === true && respons === true && (
                        <label
                          key={index}
                          htmlFor={`${axiosname}${index}`}
                          className="DropDownComponentItem"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            width: "100%",
                            minHeight: "30px",
                            marginTop: "20px",
                            padding: "0px 10px 0px 0px",
                          }}
                        >
                          <input
                            style={{ display: "none" }}
                            onChange={() =>
                              checkState(item.id, item.title, index)
                            }
                            type="checkbox"
                            id={`${axiosname}${index}`}
                            name={`${axiosname}${index}`}
                          />
                          <img
                            style={{
                              width: "20px",
                              height: "20px",
                              cursor: "pointer",
                            }}
                            src={item.checked === true ? check : uncheck}
                          />
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "10px",
                              fontFamily: "IRANSansFaNum",
                              direction: "rtl",
                              fontSize: "16px",
                              color: "#bbb",
                              cursor: "pointer",
                            }}
                          >
                            {item.title}
                          </p>
                        </label>
                      )}
                    </>
                    // </div>
                  );
                })}
            {data != undefined &&
              axiosname === undefined &&
              data
                .filter((myitem) => {
                  if (search == "") {
                    return myitem;
                  } else if (
                    myitem.title.toLowerCase().includes(search.toLowerCase())
                  )
                    return {
                      myitem,
                    };
                })
                .map((item, index) => {
                  return (
                    <>
                      {showDropDown === true && respons === true ? (
                        <div
                          style={
                            index != 0
                              ? { marginTop: "15px", width: "100%" }
                              : { marginTop: "10px", width: "100%" }
                          }
                          key={index}
                        >
                          <div
                            className="DropDownComponentItem"
                            onClick={() => itemClick(item.id, item.title)}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                              width: "100%",
                              minHeight: "30px",
                            }}
                          >
                            <p
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: "10px",
                                fontFamily: "IRANSansFaNum",
                                direction: "rtl",
                                fontSize: "16px",
                                color: "#bbb",
                                cursor: "pointer",
                              }}
                            >
                              {item.title}
                            </p>
                          </div>
                        </div>
                      ) : null}
                    </>
                  );
                })}

            {/*  ? here */}
            {axiosData.length != 0 &&
              axiosname != "Propertydata" &&
              axiosname != "PropertyValuedata" &&
              axiosname != "Blogtagdata" &&
              axiosname != "province" &&
              axiosData
                .filter((myitem) => {
                  if (search == "") {
                    return myitem;
                  } else if (
                    myitem.title.toLowerCase().includes(search.toLowerCase())
                  )
                    return {
                      myitem,
                    };
                })
                .map((item, index) => {
                  return (
                    <>
                      {index == 0 &&
                        statcItem != null &&
                        showDropDown === true &&
                        respons === true && search == '' &&
                        statcItem}

                      {showDropDown === true && respons === true ? (
                        <div
                          key={index}
                          className="DropDownComponentItem"
                          onClick={() =>
                            itemClick(item.title, item.id, axiosData)
                          }
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            cursor: "pointer",
                            minHeight: "30px",
                            marginTop: "20px",
                          }}
                        >
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "10px",
                              fontFamily: "IRANSansFaNum",
                              direction: "rtl",
                              fontSize: "16px",
                              color: "#bbb",
                              cursor: "pointer",
                            }}
                          >
                            {item.title}
                          </p>
                        </div>
                      ) : null}
                    </>
                  );
                })}

            {axiosData.length != 0 &&
              axiosname === "Propertydata" &&
              axiosData
                .filter((myitem) => {
                  if (search == "") {
                    return myitem;
                  } else if (
                    myitem.key.toLowerCase().includes(search.toLowerCase())
                  ) {
                    return {
                      myitem,
                    };
                  }
                })
                .map((item, index) => {
                  return (
                    // <div style={{}} key={index}>
                    <>
                      {showDropDown === true && respons === true && (
                        <div
                          key={index}
                          className="DropDownComponentItem"
                          onClick={() => itemClick(item.key, item.id)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            minHeight: "30px",
                            marginTop: "20px",
                            width: "100%",
                          }}
                        >
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "10px",
                              fontFamily: "IRANSansFaNum",
                              direction: "rtl",
                              fontSize: "16px",
                              color: "#bbb",
                              cursor: "pointer",
                            }}
                          >
                            {item.key}
                          </p>
                        </div>
                      )}
                    </>
                    //  </div>
                  );
                })}

            {axiosData.length != 0 &&
              axiosname === "PropertyValuedata" &&
              axiosData
                .filter((myitem) => {
                  if (search == "") {
                    return myitem;
                  } else if (
                    myitem.value.toLowerCase().includes(search.toLowerCase())
                  )
                    return {
                      myitem,
                    };
                })
                .map((item, index) => {
                  return (
                    // <div key={index}>
                    <>
                      {showDropDown === true && respons === true ? (
                        <div
                          key={index}
                          className="DropDownComponentItem"
                          onClick={() => itemClick(item.value, item.id)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            minHeight: "30px",
                            cursor: "pointer",
                            marginTop: "20px",
                          }}
                        >
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "10px",
                              fontFamily: "IRANSansFaNum",
                              direction: "rtl",
                              fontSize: "16px",
                              color: "#bbb",
                              cursor: "pointer",
                            }}
                          >
                            {item.value}
                          </p>
                        </div>
                      ) : null}
                    </>
                    // </div>
                  );
                })}
          </div>
        </div>
      )}
      {axiosname === "variablePrice" && (
        <div
          onClick={() => showDropDownClick()}
          style={
            showDropDown === false
              ? {
                  display: "flex",
                  position: "relative",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: `${width}`,
                  height: `${height}`,
                  backgroundColor: "#fff",
                  borderWidth: "1px",
                  outline: "none",
                  borderRadius: "10px",
                  borderStyle: "solid",
                  borderColor: `${
                    borderColor != undefined ? borderColor : "#dcdcdc"
                  }`,
                  padding: "0px 10px",
                  direction: "rtl",
                }
              : {
                  display: "flex",
                  position: "relative",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: `${width}`,
                  height: `${height}`,
                  backgroundColor: "#fff",
                  borderLeftWidth: "1px",
                  borderTopWidth: "1px",
                  borderRightWidth: "1px",
                  outline: "none",
                  borderRadius: "10px 10px 0px 0px",
                  borderRightStyle: "solid",
                  borderLeftStyle: "solid",
                  borderTopStyle: "solid",
                  borderRightColor: `${
                    borderColor != undefined ? borderColor : "#dcdcdc"
                  }`,
                  borderLeftColor: `${
                    borderColor != undefined ? borderColor : "#dcdcdc"
                  }`,
                  borderTopColor: `${
                    borderColor != undefined ? borderColor : "#dcdcdc"
                  }`,
                  padding: "0px 10px",
                  direction: "rtl",
                }
          }
        >
          {disabledInput === true ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: "85%",
                width: "95%",
                border: "none",
                outline: "none",
                backgroundColor: "inherit",
                fontFamily: "IRANSansFaNum",
                direction: "rtl",
                fontSize: "16px",
                color: "#bbb",
              }}
            >
              {dropLable}
            </div>
          ) : (
            <input
              onChange={onchange}
              placeholder={placeholder}
              value={value}
              id={id}
              className="DropDownComponentInput"
              style={{
                display: "flex",
                height: "85%",
                width: "95%",
                cursor: "pointer",
                border: "none",
                outline: "none",
                backgroundColor: "inherit",
                fontFamily: "IRANSansFaNum",
                direction: "rtl",
                fontSize: "16px",
                color: "#bbb",
              }}
            />
          )}

          <button
            // onClick={() => showDropDownClick()}
            style={
              showDropDown === false
                ? {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "85%",
                    width: "5%",
                    border: "none",
                    cursor: "pointer",
                    outline: "none",
                    backgroundColor: "unset",
                    transform: "rotate(0deg)",
                    transition: "transform 0.3s",
                  }
                : {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "85%",
                    width: "5%",
                    cursor: "pointer",
                    border: "none",
                    outline: "none",
                    backgroundColor: "unset",
                    transform: "rotate(180deg)",
                    transition: "transform 0.3s",
                  }
            }
          >
            <img style={{ cursor: "pointer" }} src={ArrowDown} />
          </button>
          <div
            className="DropDownComponentBox"
            style={
              showDropDown === true
                ? {
                    display: "flex",
                    justifyContent: `${
                      respons === true ? "flex-start" : "center"
                    }`,
                    alignItems: `${respons === true ? "flex-start" : "center"}`,
                    flexDirection: "column",
                    position: "absolute",
                    borderRadius: "0px 0px 10px 10px",
                    borderLeftWidth: "1px",
                    borderRightWidth: "1px",
                    borderBottomWidth: "1px",
                    backgroundColor: "#fff",
                    borderLeftColor: `${
                      borderColor != undefined ? borderColor : "#dcdcdc"
                    }`,
                    borderRightColor: `${
                      borderColor != undefined ? borderColor : "#dcdcdc"
                    }`,
                    borderBottomColor: `${
                      borderColor != undefined ? borderColor : "#dcdcdc"
                    }`,
                    borderLeftStyle: "solid",
                    borderRightStyle: "solid",
                    borderBottomStyle: "solid",
                    width: "100%",
                    height: `${respons === true ? dropDownHeight : "100px"}`,
                    zIndex: `${zindex}`,
                    backgroundColor: "#fff",
                    top: "43px",
                    right: "-1px",
                    transition: "height 0.3s",
                    overflow: "scroll",
                  }
                : {
                    display: "flex",
                    position: "absolute",
                    borderRadius: "0px 0px 10px 10px",
                    borderLeftWidth: "0px",
                    borderRightWidth: "0px",
                    borderBottomWidth: "0px",
                    borderLeftColor: "#dcdcdc",
                    borderRightColor: "#dcdcdc",
                    borderBottomColor: "#dcdcdc",
                    borderLeftStyle: "solid",
                    borderRightStyle: "solid",
                    borderBottomStyle: "solid",
                    width: "100%",
                    height: "0px",
                    backgroundColor: "#fff",
                    top: "43px",
                    right: "-1px",
                    transition: "height 0.3s",
                  }
            }
          >
            {respons === false && showDropDown === true && (
              <p
                style={{
                  alignItems: "center",
                  fontFamily: "IRANSansFaNum",
                  direction: "rtl",
                  fontSize: "18px",
                  color: "#bbb",
                }}
              >
                هیچ داده ای یافت نشد .
              </p>
            )}
            {disabledInput === true ? (
              <div>
                <div
                  className="DropDownComponentItem"
                  onClick={() => itemClick(dropLable)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    minHeight: "30px",
                    marginTop: "20px",
                  }}
                >
                  {showDropDown === true ? (
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "10px",
                        fontFamily: "IRANSansFaNum",
                        direction: "rtl",
                        fontSize: "16px",
                        color: "#bbb",
                        cursor: "pointer",
                      }}
                    >
                      {dropLable}
                    </p>
                  ) : null}
                </div>
              </div>
            ) : (
              axiosData.length != 0 &&
              axiosData
                .filter((myitem) => {
                  if (search == "") {
                    return myitem;
                  } else if (
                    myitem.key.toLowerCase().includes(search.toLowerCase())
                  )
                    return {
                      myitem,
                    };
                })
                .map((item, index) => {
                  return (
                    <>
                      {showDropDown === true ? (
                        <div
                          key={index}
                          className="DropDownComponentItem"
                          onClick={() => itemClick(item.key)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            minHeight: "30px",
                            cursor: "pointer",
                            marginTop: "20px",
                          }}
                        >
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "10px",
                              fontFamily: "IRANSansFaNum",
                              direction: "rtl",
                              fontSize: "16px",
                              color: "#bbb",
                              cursor: "pointer",
                            }}
                          >
                            {item.key}
                          </p>
                        </div>
                      ) : null}
                    </>
                  );
                })
            )}
          </div>
        </div>
      )}
    </>
  );
}
