import update from "react-addons-update";
const iniState = {
  editProduct_id: null,
  productGalleries: [],
};
const SendProductGalleryInfoReducer = (state = iniState, action) => {
  switch (action.type) {
    case "SEND_PRODUCT_GALLERY_INFO":
      const newGalleries = update(state, {
        productGalleries: { $set: action.productGalleries },
      });
      state = newGalleries;

      return state;

    case "SEND_PRODUCT_GALLERY_INFO_IS_MAIN":
      const newismain = update(state, {
        productGalleries: {
          [action.index]: { is_main: { $set: action.is_main } },
        },
      });
      state = newismain;

      return state;

    case "Delete_PRODUCT_GALLERY_INFO_IS_MAIN":
      const deleteImage = update(state, {
        productGalleries: { $splice: [[action.index, 1]] },
      });
      state = deleteImage;

      return state;

    default:
      return state;
  }
};
export default SendProductGalleryInfoReducer;
