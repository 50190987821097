const AddPropertNameReducer = (state = [] , action) =>{
    switch(action.type){
        case "ADD_PROPERTY_NAME":
        return[ 
            ...state,
            {
                CurrentName: action.payload,
                setCurrentName :action.payload
            },
            
        ]
        default: 
        
        return state
    }
}
export default AddPropertNameReducer;