import React, { useEffect, useState } from "react";
import "../../../assets/styles/DiscountStyle/CreateDiscount/CreateDiscount.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { isObjectEmpty } from "../../../helper/Helper";
import DiscountComponent from "../../../components/Discount/CreateDiscountComponent";
import TopBar from "../../../components/TopBar/TopBar";
import InputComponent from "../../../components/TextInputComponent/TextInputComponent";
import Pagination from "../../../components/Pagination/Pagination";
import axios from "../../../js/Axios/Axios";
import { useSelector, useDispatch } from "react-redux";
import useForceUpdate from "use-force-update";
import SearchLogo from "../../../assets/icons/SearchLogo.svg";
import headerCheck from "../../../assets/icons/headerCheck.svg";
import check from "../../../assets/icons/check.svg";
import uncheck from "../../../assets/icons/uncheck.svg";
import { AxiosNameDiscount } from "../../../action";

function CreateDiscount() {

  const dispatch = useDispatch();

  const location = useLocation();
  const paginatedData = useSelector((state) => state.PaginatedData); // get paginated from reducer
  const axiosName = useSelector((state) => state.AxiosNameDiscount);
  const coupenData = useSelector((state) => state.SendCoupenForEdit);
  const forceUpdate = useForceUpdate();
  const [tableDate, setTableData] = useState([]);
  const [from, setFrom] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [productIDs, setProductIDs] = useState([]);
  const [categoryIDs, setCategoryIDs] = useState([]);
  const [userIDs, setUserIDs] = useState([]);
  useEffect(() => {
    console.log(axiosName);
    axios
      .get(axiosName.name)
      .then((res) => {
        if (res.data.status) {
          setTableData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
        }
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
    let searchInput = document.getElementById("searchAll");
    searchInput.value = "";
  }, [axiosName.name]);
  useEffect(() => {
    console.log(paginatedData);
    if (paginatedData.length != 0) {
      setTableData(paginatedData.data);
      setCurrentPage(paginatedData.meta.current_page);
      setFrom(paginatedData.meta.from);
    }
  }, [paginatedData]);
  useEffect(() => {
    if (isObjectEmpty(coupenData) === false &&
      location.pathname === "/UpdateDiscount") {
      setProductIDs(coupenData.productPrice_ids)
      setCategoryIDs(coupenData.productCategory_ids)
      setUserIDs(coupenData.user_ids)
    }
  }, [])

  const addProductID = (id) => {
    if (axiosName.name === "/productPrice") {
      if (!productIDs.includes(id)) {
        productIDs.push(id);
      } else {
        var index = productIDs.indexOf(id);
        productIDs.splice(index, 1);
      }
    } else if (axiosName.name === "/productCategory?isPaginated=1") {
      if (!categoryIDs.includes(id)) {
        categoryIDs.push(id);
      } else {
        var index = categoryIDs.indexOf(id);
        categoryIDs.splice(index, 1);
      }
    } else if (axiosName.name === "/User") {
      if (!userIDs.includes(id)) {
        userIDs.push(id);
      } else {
        var index = userIDs.indexOf(id);
        userIDs.splice(index, 1);
      }
    }
    forceUpdate();
  };
  const allCheckBox = () => {
    if (axiosName.name === "/productPrice") {
      if (tableDate.every((i) => productIDs.includes(i.id))) {
        tableDate.map((titem, ti) => {
          productIDs.splice(productIDs.indexOf(titem.id), 1);

          forceUpdate();
        });
      } else {
        tableDate.map((item, i) => {
          if (!productIDs.includes(item.id)) {
            productIDs.push(item.id);
          }
        });
      }
    } else if (axiosName.name === "/productCategory?isPaginated=1") {
      if (tableDate.every((i) => categoryIDs.includes(i.id))) {
        tableDate.map((titem, ti) => {
          categoryIDs.splice(categoryIDs.indexOf(titem.id), 1);

          forceUpdate();
        });
      } else {
        tableDate.map((item, index) => {
          if (!categoryIDs.includes(item.id)) {
            categoryIDs.push(item.id);
          }
        });
      }
    } else if (axiosName.name === "/User") {
      if (tableDate.every((i) => userIDs.includes(i.id))) {
        tableDate.map((titem, ti) => {
          userIDs.splice(userIDs.indexOf(titem.id), 1);

          forceUpdate();
        });
      } else {
        tableDate.map((item, index) => {
          if (!userIDs.includes(item.id)) {
            userIDs.push(item.id);
          }
        });
      }
    }
    forceUpdate();
  };
  const SearchAll = (e) => {
    axios
      .get(
        `${axiosName.name === "/User" || axiosName.name === "/productPrice"
          ? `${axiosName.name}?search=${e.target.value}`
          : `${axiosName.name}&search=${e.target.value}`
        }`
      )
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setTableData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="create-discount-all">
      <div className="create-discount-topbar">
        <TopBar />
      </div>
      <div className="create-discount-button-serach-box">

        {
          location.pathname == "/CreateDiscount" ?
            <h3>ایجاد کوپن تخفیف</h3>
            : <h3>ویرایش کوپن تخفیف</h3>
        }

        <InputComponent
          id="searchAll"
          width="337px"
          height="44px"
          type="text"
          inputType="inputWithIcon"
          iconSide="right"
          iconSrc={SearchLogo}
          backgroundColor="#f7f7f7"
          placeholder="جستجو .."
          borderColor="#dcdcdc"
          onchange={(e) => SearchAll(e)}
        />
      </div>
      <div className="create-discount-box">
        <div className="create-discount-component-box">
          <DiscountComponent
            ProductsID={productIDs}
            CategoryID={categoryIDs}
            UserID={userIDs}
          />
        </div>
        <div className="create-discount-table-box">
          <div className="create-discount-table-box-2">
            <div style={{
              minWidth: "832px",
              borderRight: "1px solid #dcdcdc",
              borderLeft: "1px solid #dcdcdc",
              borderTop: "1px solid #dcdcdc",
              borderRadius: "10px 10px 0 0"

            }} className="all-products-header dragscroll">

              <div className="all-products-tab-bar">

                <div className="all-products-tab-bar-right">

                  <p onClick={() => dispatch(AxiosNameDiscount("/productPrice"))}
                    style={
                      axiosName.name == "/productPrice" ? {
                        color: "#00457b",
                        fontWeight: "bold"
                      } : {}
                    }>

                    محصولات
                  </p>
                  <p onClick={() => dispatch(AxiosNameDiscount("/productCategory?isPaginated=1"))} style={
                    axiosName.name == "/productCategory?isPaginated=1" ? {
                      color: "#00457b",
                      fontWeight: "bold"
                    } : {}
                  }>
                    دسته بندی ها
                  </p>

                  <p style={
                    axiosName.name == "/User" ? {
                      color: "#00457b",
                      fontWeight: "bold"
                    } : {}
                  } onClick={() => dispatch(AxiosNameDiscount("/User"))}>
                    مشتریان
                  </p>
                </div>
                {/* 
                <div className="all-products-tab-bar-left">
                  <button style={
                    this.state.tableChecked.length > 0 ?
                      { backgroundColor: "#f23b3b" } : {}
                  }
                    onClick={this.garbageClick.bind(this)}
                    className="AllProductTopShowTrashButton"
                  >

                    {
                      this.state.tableChecked.length > 0 ?
                        <img src={AllProductTrash} />
                        : <img src={AllProductTrashRed} />


                    }
                    <p style={
                      this.state.tableChecked.length > 0 ?
                        { color: "#fff" } : {}
                    }
                      className="AllProductTopShowTrashButtonTittle">زباله ‌دان</p>
                  </button>
                </div> */}

              </div>
              <div className="create-dicount-table-top-bar">
                <div className="create-dicount-table-top-bar-hashtag">
                  <p>#</p>
                </div>

                {axiosName.name === "/productPrice" && (
                  <>
                    <div className="create-dicount-table-top-bar-checkBox">
                      <label htmlFor="allproductCheckBox">
                        <input
                          style={{
                            display: "none",
                          }}
                          id="allproductCheckBox"
                          name="allproductCheckBox"
                          type="checkbox"
                          onChange={() => allCheckBox()}
                        />

                        <img
                          src={
                            tableDate.every((i) => productIDs.includes(i.id)) &&
                              tableDate.length != 0
                              ? headerCheck
                              : uncheck
                          }
                        />
                      </label>
                    </div>
                    <div className="create-dicount-table-top-bar-img">
                      <p>تصویر</p>
                    </div>
                    <div className="create-dicount-table-top-bar-name">
                      <p>نام محصول</p>
                    </div>
                    <div className="create-dicount-table-top-bar-barcode">
                      <p>شناسه</p>
                    </div>
                    <div className="create-dicount-table-top-bar-price">
                      <p>قیمت(تومان)</p>
                    </div>
                    <div className="create-dicount-table-top-bar-Inventory">
                      <p>موجودی</p>
                    </div>
                  </>
                )}
                {axiosName.name === "/productCategory?isPaginated=1" && (
                  <>
                    <div className="create-dicount-table-top-bar-checkBox">
                      <label htmlFor="allcategoryCheckBox">
                        <input
                          style={{
                            display: "none",
                          }}
                          id="allcategoryCheckBox"
                          name="allcategoryCheckBox"
                          type="checkbox"
                          onChange={() => allCheckBox()}
                        />

                        <img
                          src={
                            tableDate.every((i) => categoryIDs.includes(i.id)) &&
                              tableDate.length != 0
                              ? check
                              : uncheck
                          }
                        />
                      </label>
                    </div>
                    <div className="create-dicount-table-top-bar-name-2">
                      <p>نام دسته بندی</p>
                    </div>
                    <div className="create-dicount-table-top-bar-Inventory-2">
                      <p>تعداد</p>
                    </div>
                  </>
                )}
                {axiosName.name === "/User" && (
                  <>
                    <div className="create-dicount-table-top-bar-checkBox">
                      <label htmlFor="alluserCheckBox">
                        <input
                          style={{
                            display: "none",
                          }}
                          id="alluserCheckBox"
                          name="alluserCheckBox"
                          type="checkbox"
                          onChange={() => allCheckBox()}
                        />

                        <img
                          src={
                            tableDate.every((i) => userIDs.includes(i.id)) &&
                              tableDate.length != 0
                              ? check
                              : uncheck
                          }
                        />
                      </label>
                    </div>
                    <div className="create-dicount-table-top-bar-name-3">
                      <p>نام مشتری</p>
                    </div>
                    <div className="create-dicount-table-top-bar-phone-number">
                      <p>موبایل</p>
                    </div>
                    <div className="create-dicount-table-top-bar-email">
                      <p>ایمیل</p>
                    </div>
                    <div className="create-dicount-table-top-bar-situation">
                      <p>وضعیت</p>
                    </div>
                    <div className="create-dicount-table-top-bar-total-price">
                      <p>کل مبلغ خرید</p>
                    </div>
                  </>
                )}
              </div>
            </div>


            {tableDate.map((item, index) => {
              return (
                <div key={index}
                  style={tableDate.length == index + 1 ?
                    { borderRadius: "0 0 10px 10px" } : {}}
                  className="create-dicount-table-item-box">
                  <div className="create-dicount-table-item-box-hashtag">
                    <p>{index + from}</p>
                  </div>

                  {axiosName.name === "/productPrice" && (
                    <>
                      <div className="create-dicount-table-item-box-checkbox">
                        <label htmlFor={`product${index}`}>
                          <input
                            style={{
                              display: "none",
                            }}
                            type="checkbox"
                            id={`product${index}`}
                            name={`product${index}`}
                            onChange={() => addProductID(item.id)}
                          />
                          <img
                            src={productIDs.includes(item.id) ? check : uncheck}
                          />
                        </label>
                      </div>
                      <div className="create-dicount-table-item-box-image">
                        <span>
                          <img src={item.product_image}
                          />

                        </span>
                      </div>
                      <div className="create-dicount-table-item-box-name">
                        <p>{item.title}</p>
                      </div>
                      <div className="create-dicount-table-item-box-barcode">
                        <p>{item.barcode}</p>
                      </div>
                      <div className="create-dicount-table-item-box-price">
                        <p>{item.price}</p>
                      </div>
                      <div className="create-dicount-table-item-box-inventory">
                        <p>{item.available_type}</p>
                      </div>
                    </>
                  )}
                  {axiosName.name === "/productCategory?isPaginated=1" && (
                    <>
                      <div className="create-dicount-table-item-box-checkbox">
                        <label htmlFor={`category${index}`}>
                          <input
                            style={{
                              display: "none",
                            }}
                            type="checkbox"
                            id={`category${index}`}
                            name={`category${index}`}
                            onChange={() => addProductID(item.id)}
                          />
                          <img
                            src={
                              categoryIDs.includes(item.id) ? check : uncheck
                            }
                          />
                        </label>
                      </div>
                      <div className="create-dicount-table-item-box-name-2">
                        <p>{item.title}</p>
                      </div>
                      <div className="create-dicount-table-item-box-inventory-2">
                        <p>{item.products_count}</p>
                      </div>
                    </>
                  )}
                  {axiosName.name === "/User" && (
                    <>
                      <div className="create-dicount-table-item-box-checkbox">
                        <label htmlFor={`user${index}`}>
                          <input
                            style={{
                              display: "none",
                            }}
                            type="checkbox"
                            id={`user${index}`}
                            name={`user${index}`}
                            onChange={() => addProductID(item.id)}
                          />
                          <img
                            src={userIDs.includes(item.id) ? check : uncheck}
                          />
                        </label>
                      </div>
                      <div className="create-dicount-table-item-box-name-3">
                        <p>{item.fullName}</p>
                      </div>
                      <div className="create-dicount-table-item-box-phone-number">
                        <p>{item.mobile_number}</p>
                      </div>
                      <div className="create-dicount-table-item-box-email">
                        <p>{item.email}</p>
                      </div>
                      <div className="create-dicount-table-item-box-situation">
                        <div
                          className={
                            item.status === 1
                              ? "create-dicount-table-item-box-situation-1"
                              : "create-dicount-table-item-box-situation-2"
                          }
                        >
                          <p>{item.status === 1 ? "فعال" : "غیرفعال"}</p>
                        </div>
                      </div>
                      <div className="create-dicount-table-item-box-totla-price">
                        <p>{item.totalOrdersPrice}</p>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
          <div className="create-discount-pagination-box">
            <Pagination
              firstAxios={axiosName.name}
              currentAxios={`${axiosName.name === "/User" || axiosName.name === "/productPrice"
                ? `${axiosName.name}?page=`
                : `${axiosName.name}&page=`
                }`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default CreateDiscount;
