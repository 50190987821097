const iniState = {};
const SendCoupenForEditReducer = (state = iniState, action) => {
  switch (action.type) {
    case "SEND_COUPEN_FOR_EDIT":
      state = action.coupen;
      return state;
    default:
      return state;
  }
};
export default SendCoupenForEditReducer;
