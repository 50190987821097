const iniState = {
  Tag_id: [],
};
const TagIdReducer = (state = iniState, action) => {
  switch (action.type) {
    case "TAG_ID":
      return { ...iniState, Tag_id: action.payload };

    case "DELETE_TAG_ID":
        state.Tag_id.splice(action.index, 1);
      return state
    default:
      return state;
  }
};
export default TagIdReducer;
