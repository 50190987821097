import React, { useEffect, useState } from "react";
import "../../assets/styles/CustomerStyle/Customers.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import TopBar from "../../components/TopBar/TopBar";
import axios from "../../js/Axios/Axios";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import Pagination from "../../components/Pagination/Pagination";
import { SendUserIdForFilter } from "../../action/index";
import { useSelector, useDispatch } from "react-redux";
import useForceUpdate from "use-force-update";
import SearchLogo from "../../assets/icons/SearchLogo.svg";
import check from "../../assets/icons/check.svg";
import uncheck from "../../assets/icons/uncheck.svg";
import openTablee from "../../assets/icons/openTable.svg";
import closeTable from "../../assets/icons/closeTable.svg";
import Edit from "../../assets/icons/Edit.svg";
import EditActive from "../../assets/icons/EditActive.svg";

export default function Customers() {
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paginatedData = useSelector((state) => state.PaginatedData);
  const [allUser, setAllUser] = useState([]);
  const [from, setFrom] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [openTable, setOpenTable] = useState(null);
  const [userInformation, setUserInformation] = useState(null);
  const [firstAxios, setFirstAxios] = useState("/User");

  useEffect(() => {
    axios
      .get("/User")
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setAllUser(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  useEffect(() => {
    if (paginatedData.length != 0) {
      setAllUser(paginatedData.data);
      setCurrentPage(paginatedData.meta.current_page);
      setFrom(paginatedData.meta.from);
    }
  }, [paginatedData]);
  const searchUser = (e) => {
    axios
      .get(`/User?search=${e.target.value}`)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setAllUser(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          setFirstAxios(`/User?search=${e.target.value}`);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const openTableItem = (index, id) => {
    if (openTable === index) {
      setOpenTable(null);
      setUserInformation(null);
    } else {
      setUserInformation(null);
      setOpenTable(index);
      axios
        .get(`/User/${id}/edit`)
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            setUserInformation(res.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    forceUpdate();
  };
  const filterClick = (id, name) => {
    if (name === "blog") {
      dispatch(SendUserIdForFilter(id, name));
      navigate("/CommentBlog");
    }
    if (name === "product") {
      dispatch(SendUserIdForFilter(id, name));
      navigate("/ManageCustomerFeedback");
    }
    if (name === "message") {
      dispatch(SendUserIdForFilter(id, name));
      navigate("/Messages");
    }
    if (name === "order") {
      dispatch(SendUserIdForFilter(id, name));
      navigate("/Order");
    }
  };
  return (
    <div className="customers-all">
      <div className="customers-top-bar">
        <TopBar />
      </div>
      <div className="customers-button-search-box">
        <InputComponent
          width="25%"
          height="44px"
          type="text"
          inputType="inputWithIcon"
          iconSide="right"
          iconSrc={SearchLogo}
          backgroundColor="#f7f7f7"
          placeholder="جستجو .."
          borderColor="#dcdcdc"
          onchange={(e) => searchUser(e)}
        />
      </div>
      <div className="customers-table-box">
        <div className="customers-table-top-bar">
          <div className="customers-table-top-bar-hashtag">#</div>
          <div className="customers-table-top-bar-name">نام و نام خانوادگی</div>
          <div className="customers-table-top-bar-user-name">نام کاربری</div>
          <div className="customers-table-top-bar-total-price">
            مجموع مبلغ سفارشات
          </div>
          <div className="customers-table-top-bar-number-order">
            تعداد سفارشات
          </div>
          <div className="customers-table-top-bar-phone-number">موبایل</div>
          <div className="customers-table-top-bar-email">ایمیل</div>
          <div className="customers-table-top-bar-situation">وضعیت</div>
          <div className="customers-table-top-bar-operation">عملیات</div>
        </div>
        {allUser.map((item, index) => {
          return (
            <div
              key={index}
              style={
                allUser.length == index + 1
                  ? { borderRadius: "0 0 10px 10px" }
                  : {}
              }
              className={
                openTable === index
                  ? "customers-table-item-box-open"
                  : "customers-table-item-box"
              }
            >
              <div
                onClick={() => openTableItem(index, item.id)}
                className="customers-table-item-box-2"
              >
                <div className="customers-table-item-hashtag">
                  <p>{from + index}</p>
                </div>
                <div className="customers-table-item-name">
                  <p>{item.fullName}</p>
                </div>
                <div className="customers-table-item-user-name">
                  <p>{item.user_name}</p>
                </div>
                <div className="customers-table-item-total-price">
                  <p>{item.totalOrdersPrice}</p>
                </div>
                <div className="customers-table-item-number-order">
                  <p>{item.totalOrderCount}</p>
                </div>
                <div className="customers-table-item-phone-number">
                  <p>{item.mobile_number}</p>
                </div>
                <div className="customers-table-item-email">
                  <p>{item.email}</p>
                </div>
                <div className="customers-table-item-situation">
                  {item.status === 1 && (
                    <div className="customers-table-item-status">
                      <p>فعال</p>
                    </div>
                  )}
                </div>
                <div className="customers-table-item-operation">
                  <img
                    onClick={() => openTableItem(index, item.id)}
                    src={openTable === index ? closeTable : openTablee}
                  />
                </div>
              </div>

              {openTable === index && (
                <div className="customers-table-open-box">
                  <div className="customers-table-open-information-box">
                    <div className="customers-table-open-information-order-box">
                      <p>اطلاعات سفارشات</p>
                    </div>
                    <div className="customers-table-open-information-user-box">
                      <p>اطلاعات تکمیلی مشتری</p>
                    </div>
                    <div className="customers-table-open-activity-user-box">
                      <p>فعالیت های مشتری</p>
                    </div>
                  </div>
                  <div className="customers-table-open-line"></div>
                  {userInformation != null && (
                    <div className="customers-table-open-information-box-2">
                      <div className="customers-table-open-information-order-box-2">
                        <div className="customers-table-open-information-order-title-box">
                          <div className="customers-table-open-information-order-title-box-2">
                            <p>تعداد سفارشات</p>
                          </div>
                          <div className="customers-table-open-information-order-title-box-2">
                            <p>مجموع تعداد محصولات</p>
                          </div>
                          <div className="customers-table-open-information-order-title-box-2">
                            <p>مبلغ سفارشات</p>
                          </div>
                          <div className="customers-table-open-information-order-title-box-2">
                            <p>سفارشات مرجوعی</p>
                          </div>
                        </div>
                        <div className="customers-table-open-information-order-line"></div>
                        <div className="customers-table-open-information-order-title-box-info">
                          <div className="customers-table-open-information-order-title-box-3">
                            <p>{userInformation.orders_count} سفارش</p>
                          </div>
                          <div className="customers-table-open-information-order-title-box-3">
                            <p>{userInformation.product_count} محصول</p>
                          </div>
                          <div className="customers-table-open-information-order-title-box-3">
                            <p>{userInformation.orders_totalPrice}</p>
                          </div>
                          <div className="customers-table-open-information-order-title-box-3">
                            <p>{userInformation.returnedOrders_count} سفارش</p>
                          </div>
                        </div>
                      </div>
                      <div className="customers-table-open-information-user-box-2">
                        <div className="customers-table-open-information-user-title-box">
                          <div className="customers-table-open-information-user-title-box-2">
                            <p>کد پستی</p>
                          </div>
                          <div className="customers-table-open-information-user-title-box-2">
                            <p>شماره کارت</p>
                          </div>
                          <div className="customers-table-open-information-user-title-box-2">
                            <p>شماره شبا</p>
                          </div>
                          <div className="customers-table-open-information-user-title-box-2">
                            <p>آدرس ها (عنوان)</p>
                          </div>
                        </div>
                        <div className="customers-table-open-information-order-line"></div>
                        <div className="customers-table-open-information-user-title-box-info">
                          <div className="customers-table-open-information-user-title-box-3">
                            <p>{userInformation.post_code}</p>
                          </div>
                          <div className="customers-table-open-information-user-title-box-3">
                            <p>{userInformation.card_number}</p>
                          </div>
                          <div className="customers-table-open-information-user-title-box-3">
                            <p>{userInformation.shaba_number}</p>
                          </div>
                          <div className="customers-table-open-information-user-title-address-box">
                            {userInformation.addresses.map((item, index) => {
                              return (
                                <p key={index}>
                                  {item.title}
                                  {userInformation.addresses.length - 1 !=
                                    index && "-"}
                                </p>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="customers-table-open-buttons-box">
                        <button
                          onClick={() => filterClick(item.id, "blog")}
                          className="customers-table-open-button-comment-blog"
                        >
                          <p>دیدگاه ها در بلاگ</p>
                        </button>
                        <button
                          onClick={() => filterClick(item.id, "product")}
                          className="customers-table-open-button-comment-product"
                        >
                          <p>دیدگاه در محصول</p>
                        </button>
                        <button
                          onClick={() => filterClick(item.id, "message")}
                          className="customers-table-open-button-message"
                        >
                          <p>پیغام ها</p>
                        </button>
                        <button
                          onClick={() => filterClick(item.id, "order")}
                          className="customers-table-open-button-order"
                        >
                          <p>سفارشات</p>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="customers-pagination-box">
        <Pagination firstAxios={firstAxios} currentAxios="/User?page=" />
      </div>
    </div>
  );
}
