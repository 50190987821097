import React, { useEffect, useRef, useState } from "react";
import "../../assets/styles/OrderStyle/Order.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import TopBar from "../../components/TopBar/TopBar";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import Pagination from "../../components/Pagination/Pagination";
import Filter from "../../components/Filter/Filter";
import axios from "../../js/Axios/Axios";
import { ShowAlert } from "../../action/index";
import { SendIdForEdit } from "../../action/index";
import { useSelector, useDispatch } from "react-redux";
import SimpleBar from "simplebar-react";
import ScrollDrag from "react-indiana-drag-scroll";
import FilterLogo from "../../assets/icons/Filter.svg";
import AllProductAdd from "../../assets/icons/AllProductAdd.svg";
import SearchLogo from "../../assets/icons/SearchLogo.svg";
import check from "../../assets/icons/check.svg";
import Edit from "../../assets/icons/Edit.svg";
import Delete from "../../assets/icons/Delete.svg";
import DeleteRed from "../../assets/icons/DeleteRed.svg";
import AllProductTrash from "../../assets/icons/GarbageDelte.svg";
import AllProductTrashRed from "../../assets/icons/GarbageDelteRed.svg";
import restore from "../../assets/icons/restore.svg";
import restoreActive from "../../assets/icons/restoreActive.svg";
import openTablee from "../../assets/icons/openTable.svg";
import closeTable from "../../assets/icons/closeTable.svg";
import EditActive from "../../assets/icons/EditActive.svg";
import uncheck from "../../assets/icons/uncheck.svg";
import useForceUpdate from "use-force-update";
export default function Order() {
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paginatedData = useSelector((state) => state.PaginatedData);
  const user_id = useSelector((state) => state.UserFilter);
  const [data, setData] = useState([]);
  const [deleteArray, setDeleteArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [from, setFrom] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [openTable, setOpenTable] = useState(null);
  const [firstAxios, setFirstAxios] = useState("/Order");
  const [currentAxios, setCurrentAxios] = useState("/Order?page=");
  const [garbageAxios, setGarbageAxios] = useState("/Order/trash");
  const [tab, setTab] = useState("all");
  const [showFilter, setShowFilter] = useState(false);
  const scrollTitleRefff = useRef();
  const scrollContentRef = useRef();
  useEffect(() => {
    if (user_id.id === null) {
      axios
        .get("/Order")
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            setTab("all");
            setData(res.data.data);
            setCurrentPage(res.data.meta.current_page);
            setFrom(res.data.meta.from);
            setLastPage(res.data.meta.last_page);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  /*   useEffect(() => {
    data.forEach((item) => {
      const checkBox = document.getElementById(`checkBox${item.id}`);
      console.log(checkBox);
      checkBox.addEventListener("click", (e, item) => {
        e.stopPropagation();
        console.log(e.target);
        console.log(e.currentTarget);

        if (!deleteArray.includes(item.id)) {
          deleteArray.push(item.id);
        } else {
          deleteArray.splice(deleteArray.indexOf(item.id), 1);
        }
        forceUpdate();
      });
    });
  }, [data]); */

  useEffect(() => {
    if (user_id.nameid === "order") {
      setShowFilter(true);
    }
  }, [user_id]);
  useEffect(() => {
    console.log(paginatedData);
    if (paginatedData.length != 0) {
      setData(paginatedData.data);
      setCurrentPage(paginatedData.meta.current_page);
      setFrom(paginatedData.meta.from);
      setLastPage(paginatedData.meta.last_page);
      setOpenTable(null);
    }
  }, [paginatedData]);

  useEffect(() => {
    // set scroll position title and content for together

    const handleScrollTitle = (e) => {
      scrollContentRef.current.scrollLeft = scrollTitleRefff.current.scrollLeft;
    };
    scrollTitleRefff.current.addEventListener("scroll", handleScrollTitle);
    const handleScrollContent = (e) => {
      scrollTitleRefff.current.scrollLeft = scrollContentRef.current.scrollLeft;
    };
    scrollContentRef.current.addEventListener("scroll", handleScrollContent);
  }, []);

  const openTableClick = (index) => {
    if (openTable === index) {
      setOpenTable(null);
      forceUpdate();
    } else {
      setOpenTable(index);
      forceUpdate();
    }
  };
  const allTab = (id) => {
    axios
      .get("/Order")
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setFirstAxios(`/Order`);
          setCurrentAxios("/Order?page=");
          setTab(id);
          setData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          setLastPage(res.data.meta.last_page);
          forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const proccessingTab = (id) => {
    axios
      .get(`/Order?order_status=${id}`)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setFirstAxios(`/Order?order_status=${id}`);
          setCurrentAxios(`/Order?&order_status=${id}&page=`);
          setTab(id);
          setData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          setLastPage(res.data.meta.last_page);
          forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const completedTab = (id) => {
    axios
      .get(`/Order?order_status=${id}`)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setTab(id);
          setFirstAxios(`/Order?order_status=${id}`);
          setCurrentAxios(`/Order?&order_status=${id}&page=`);
          setData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          setLastPage(res.data.meta.last_page);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const waitingTab = (id) => {
    axios
      .get(`/Order?order_status=${id}`)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setFirstAxios(`/Order?order_status=${id}`);
          setCurrentAxios(`/Order?&order_status=${id}&page=`);
          setTab(id);
          setData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          setLastPage(res.data.meta.last_page);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const cancelOrder = (id) => {
    axios
      .get(`/Order?order_status=${id}`)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setFirstAxios(`/Order?order_status=${id}`);
          setCurrentAxios(`/Order?&order_status=${id}&page=`);
          setTab(id);
          setData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          setLastPage(res.data.meta.last_page);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const garbageOrder = (id) => {
    deleteArray.map((item, index) => {
      deleteArray.splice(index);
      forceUpdate();
    });
    axios
      .get(`Order/trash`)
      .then((res) => {
        console.log(res);
        setTab(id);
        setData(res.data.data);
        setCurrentPage(res.data.meta.current_page);
        setFrom(res.data.meta.from);
        setLastPage(res.data.meta.last_page);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const editOrderClick = (id) => {
    dispatch(SendIdForEdit(id));
    navigate("/EditOrder");
  };

  // TODO here
  const orderCheckBox = (e, id) => {
    e.stopPropagation();
    console.log(e.target);
    console.log(e.currentTarget);

    if (!deleteArray.includes(id)) {
      deleteArray.push(id);
    } else {
      deleteArray.splice(deleteArray.indexOf(id), 1);
    }
    forceUpdate();
  };
  const allCheckBoxOnChange = () => {
    if (data.every((i) => deleteArray.includes(i.id))) {
      data.map((item, ti) => {
        deleteArray.splice(deleteArray.indexOf(item.id), 1);
        forceUpdate();
      });
    } else {
      data.map((item, i) => {
        if (!deleteArray.includes(item.id)) {
          deleteArray.push(item.id);
        }
      });
      forceUpdate();
    }
  };
  // /Order?page=
  const deleteOrder = () => {
    axios
      .delete("Order/throwingGarbage", {
        data: { order_ids: deleteArray },
      })
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));

          if (data.length === deleteArray.length) {
            setFirstAxios(`/Order?page=${currentPage - 1}`);
          } else {
            setFirstAxios(`/Order?page=${currentPage}`);
          }
          axios
            .get(
              `/Order?page=${
                data.length === deleteArray.length
                  ? currentPage - 1
                  : currentPage
              } `
            )
            .then((res) => {
              if (res.data.status) {
                console.log(res);
                setData(res.data.data);
                setCurrentPage(res.data.meta.current_page);
                setFrom(res.data.meta.from);
                setLastPage(res.data.meta.last_page);
                deleteArray.map((item, index) => {
                  deleteArray.splice(index);
                  forceUpdate();
                });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const searchOrder = (e) => {
    axios
      .get(`/Order?search=${e.target.value}`)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setTab("all");
          setFirstAxios(`/Order?search=${e.target.value}`);
          setCurrentAxios(`/Order?search=${e.target.value}&page=`);
          setData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          setLastPage(res.data.meta.last_page);
          forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const searchOrdertrash = (e) => {
    axios
      .get(`/Order/trash?search=${e.target.value}`)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setTab("زباله دان");
          setData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          setLastPage(res.data.meta.last_page);
          forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const recycleOrder = () => {
    axios
      .put("Order/restoreGarbage", { order_ids: deleteArray })
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));

          if (data.length === deleteArray.length) {
            setGarbageAxios(`/Order/trash?page=${currentPage - 1}`);
          } else {
            setGarbageAxios(`/Order/trash?page=${currentPage}`);
          }
          forceUpdate();
          axios
            .get(
              `/Order/trash?page=${
                data.length === deleteArray.length
                  ? currentPage - 1
                  : currentPage
              }`
            )
            .then((res) => {
              console.log(res);
              setTab("زباله دان");
              setData(res.data.data);
              setCurrentPage(res.data.meta.current_page);
              setFrom(res.data.meta.from);
              setLastPage(res.data.meta.last_page);
              deleteArray.map((item, index) => {
                deleteArray.splice(index);
                forceUpdate();
              });
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const showFilterClick = () => {
    setShowFilter(!showFilter);
    forceUpdate();
  };
  const Data = (Data, route) => {
    setTab("all");
    setData(Data.data);
    setCurrentPage(Data.meta.current_page);
    setFrom(Data.meta.from);
    setLastPage(Data.meta.last_page);
    setFirstAxios(route);
    setCurrentAxios(`${route}&page=`);
  };

  return (
    <div className="manage-order-all">
      <TopBar />
      <div className="manage-order-button-serach-box">
        <div className="manage-order-button-box">
          <button
            onClick={() => navigate("/createOrder")}
            className="manage-order-filter-button"
          >
            <img src={AllProductAdd} />
            ایجاد سفارش
          </button>
          {tab !== "زباله دان" && (
            <button
              onClick={() => showFilterClick()}
              className="manage-order-filter-button"
            >
              <img src={FilterLogo} />
              {showFilter === true ? "بستن فیلتر ها" : "نمایش فیلترها"}
            </button>
          )}
        </div>
        <div className="manage-order-serach-box">
          {tab === "زباله دان" ? (
            <InputComponent
              width="337px"
              height="44px"
              type="text"
              inputType="inputWithIcon"
              iconSide="right"
              iconSrc={SearchLogo}
              backgroundColor="#f7f7f7"
              placeholder="جستجو سفارش.."
              borderColor="#dcdcdc"
              onchange={(e) => searchOrdertrash(e)}
            />
          ) : (
            <InputComponent
              width="337px"
              height="44px"
              type="text"
              inputType="inputWithIcon"
              iconSide="right"
              iconSrc={SearchLogo}
              backgroundColor="#f7f7f7"
              placeholder="جستجو سفارش.."
              borderColor="#dcdcdc"
              onchange={(e) => searchOrder(e)}
            />
          )}
        </div>
      </div>
      <div className="order-table-box">
        <div
          className={
            showFilter === false ? "order-table-box-1" : "order-table-box-2"
          }
        >
          <SimpleBar
            forceVisible="x"
            // scrollableNodeProps={{ ref: this.scrollTitleRefff }}
            style={{
              width: "100%",
              borderRadius: "10px 10px 0 0",
              cursor: "grab",
              borderTop: "solid 1px #DCDCDC",
              borderRight: "solid 1px #DCDCDC",
              borderLeft: "solid 1px #DCDCDC",
            }}
          >
            <ScrollDrag innerRef={scrollTitleRefff}>
              <div className="all-order-tab-bar">
                <div className="order-products-tab-bar">
                  <p
                    onClick={() => allTab("all")}
                    className={
                      tab === "all"
                        ? "order-products-tab-bar-title-1-active"
                        : "order-products-tab-bar-title-1"
                    }
                  >
                    همه
                  </p>
                  <p
                    onClick={() => proccessingTab("در حال انجام")}
                    className={
                      tab === "در حال انجام"
                        ? "order-products-tab-bar-title-2-active"
                        : "order-products-tab-bar-title-2"
                    }
                  >
                    در حال انجام
                  </p>
                  <p
                    onClick={() => completedTab("تکمیل شده")}
                    className={
                      tab === "تکمیل شده"
                        ? "order-products-tab-bar-title-2-active"
                        : "order-products-tab-bar-title-2"
                    }
                  >
                    تکمیل شده
                  </p>
                  <p
                    className={
                      tab === "در انتظار پرداخت"
                        ? "order-products-tab-bar-title-2-active"
                        : "order-products-tab-bar-title-2"
                    }
                    onClick={() => waitingTab("در انتظار پرداخت")}
                  >
                    در انتظار پرداخت{" "}
                  </p>
                  <p
                    onClick={() => cancelOrder("لغو شده")}
                    className={
                      tab === "لغو شده"
                        ? "order-products-tab-bar-title-2-active"
                        : "order-products-tab-bar-title-2"
                    }
                  >
                    لغو شده
                  </p>
                  <p
                    onClick={() => garbageOrder("زباله دان")}
                    className={
                      tab === "زباله دان"
                        ? "order-products-tab-bar-title-2-active"
                        : "order-products-tab-bar-title-2"
                    }
                  >
                    زباله دان
                  </p>
                </div>
                {tab !== "زباله دان" && (
                  <div className="order-products-tab-bar-2">
                    <button
                      onClick={() => deleteOrder()}
                      className={
                        deleteArray.length === 0
                          ? "order-products-tab-bar-2-delte-button"
                          : "order-products-tab-bar-2-delte-button-active"
                      }
                    >
                      <img
                        src={
                          deleteArray.length === 0
                            ? AllProductTrashRed
                            : AllProductTrash
                        }
                      />
                      زباله ‌دان
                    </button>
                  </div>
                )}
                {tab === "زباله دان" && (
                  <div className="order-products-tab-bar-2">
                    <button
                      onClick={() => recycleOrder()}
                      className={
                        deleteArray.length === 0
                          ? "order-products-tab-bar-2-recycle-button"
                          : "order-products-tab-bar-2-recycle-button-active"
                      }
                    >
                      <img
                        src={deleteArray.length === 0 ? restore : restoreActive}
                      />
                      بازیابی سفارشات
                    </button>
                  </div>
                )}
              </div>
              <div className="order-table-top-bar">
                <p className="order-table-top-bar-hashtag">#</p>
                <div className="order-table-top-bar-checkbox">
                  <label htmlFor="allCheckBox">
                    <img
                      src={
                        data.every((i) => deleteArray.includes(i.id)) &&
                        data.length != 0
                          ? check
                          : uncheck
                      }
                    />
                    <input
                      style={{ display: "none" }}
                      onChange={() => allCheckBoxOnChange()}
                      id="allCheckBox"
                      name="allCheckBox"
                      type="checkbox"
                    />
                  </label>
                </div>
                <p className="order-table-top-bar-name">نام مشتری</p>
                <p className="order-table-top-bar-barcode">تاریخ ثبت</p>
                <p className="order-table-top-bar-date">شناسه سفارش</p>
                <p className="order-table-top-bar-price">مبلغ سفارش (تومان)</p>
                <p className="order-table-top-bar-payment">پرداخت</p>
                <p className="order-table-top-bar-situation">وضعیت</p>
                <p className="order-table-top-bar-opration">جزئیات/عملیات</p>
              </div>
            </ScrollDrag>
          </SimpleBar>
          <SimpleBar
            forceVisible="x"
            // scrollableNodeProps={{ ref: this.scrollContentRef }}
            style={{
              width: "calc(100% - 3px)",
              borderLeft: "solid 1px #dcdcdc",
              borderRight: "solid 1px #dcdcdc",
              borderBottom: "solid 1px #dcdcdc",
              borderRadius: "0 0 10px 10px",
            }}
          >
            <ScrollDrag
              className="scroll-container"
              innerRef={scrollContentRef}
            >
              {data.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={
                      openTable === index
                        ? "order-table-item-box-3"
                        : "order-table-item-box"
                    }
                  >
                    <div
                      onClick={(e) => openTableClick(index)}
                      className="order-table-item-box-2"
                    >
                      <div className="order-table-item-hashtag">
                        <p>{index + from}</p>
                      </div>
                      <label
                        htmlFor={`order${index}`}
                        className="order-table-item-check-box"
                      >
                        <input
                          style={{ display: "none" }}
                          type="checkbox"
                          id={`order${index}`}
                          name={`order${index}`}
                          // onChange={(e) => orderCheckBox(e, item.id)}
                        />
                        <img
                          className="orderCheckBox"
                          id={`checkBox${item.id}`}
                          onClick={(e) => {
                            // TODO Here
                            e.isPropagationStopped();
                            // e.nativeEvent.stopImmediatePropagation();

                            if (!deleteArray.includes(item.id)) {
                              deleteArray.push(item.id);
                            } else {
                              deleteArray.splice(
                                deleteArray.indexOf(item.id),
                                1
                              );
                            }
                            // forceUpdate();
                          }}
                          src={deleteArray.includes(item.id) ? check : uncheck}
                        />
                      </label>
                      <div className="order-table-item-name">
                      <p>{item.customer_name}</p>     
                      </div>
                      <div className="order-table-item-barcode">
                        <p>{item.submit_date}</p>
                      </div>
                      <div className="order-table-item-date">

                        <p>{item.barcode}</p>
                      </div>
                      <div className="order-table-item-price">
                        <p>{item.final_order_price}</p>
                      </div>
                      <div className="order-table-item-payment">
                        <p>{item.final_order_price}</p>
                      </div>
                      <div className="order-table-item-situation">
                        {item.order_status === "لغو شده" && (
                          <div className="order-table-item-situation-cancel">
                            <p>{item.order_status}</p>
                          </div>
                        )}
                        {item.order_status === "در انتظار پرداخت" && (
                          <div className="order-table-item-situation-waiting">
                            <p>{item.order_status}</p>
                          </div>
                        )}
                        {item.order_status === "در حال انجام" && (
                          <div className="order-table-item-situation-processing">
                            <p>{item.order_status}</p>
                          </div>
                        )}
                        {item.order_status === "تکمیل شده" && (
                          <div className="order-table-item-situation-completed">
                            <p>{item.order_status}</p>
                          </div>
                        )}
                      </div>
                      <div className="order-table-item-operation">
                        <img
                          src={openTable === index ? closeTable : openTablee}
                          onClick={(e) => openTableClick(index)}
                        />
                        <img
                          onClick={() => editOrderClick(item.id)}
                          src={EditActive}
                        />
                      </div>
                    </div>

                    {openTable === index && (
                      <div className="order-table-item-open-box">
                        <div className="order-table-item-open-box-2">
                          <div className="order-table-item-open-box-2-title-box">
                            <div className="order-table-item-open-box-2-title-box-1">
                              <p>اطلاعات تحویل</p>
                            </div>
                            <div className="order-table-item-open-box-2-title-box-1">
                              <p>اطلاعات پرداخت</p>
                            </div>
                            <div className="order-table-item-open-box-2-title-box-1">
                              <p>اطلاعات ارسال</p>
                            </div>
                          </div>
                          <div className="order-table-item-open-box-2-detail-box">
                            <div className="order-table-item-open-box-2-detail-box-personal-box">
                              <div className="order-table-item-open-box-2-detail-box-personal-box-2-address">
                                <p>آدرس</p>
                                <p>کد پستی</p>
                                <p>گیرنده سفارش</p>
                                <p>شماره تماس</p>
                              </div>
                              <div className="order-table-item-open-box-2-detail-box-personal-box-3-address">
                                <p>
                                  {item.delivery_addresses.title} :{" "}
                                  {item.delivery_addresses.address_details}
                                </p>
                                <p>
                                  {item.delivery_addresses.state} ,{" "}
                                  {item.delivery_addresses.city}
                                </p>
                                <p>{item.delivery_addresses.post_code}</p>
                                <p>{item.transferee_fullName}</p>
                                <p>{item.transferee_mobile}</p>
                              </div>
                            </div>
                            <div className="order-table-item-open-box-2-detail-box-personal-box">
                              <div className="order-table-item-open-box-2-detail-box-personal-box-2">
                                <p>نوع پرداخت </p>
                                <p>مبلغ قابل پرداخت</p>
                                <p>تاریخ و زمان پرداخت</p>
                                <p>کد پیگیری پرداخت</p>
                              </div>
                              <div className="order-table-item-open-box-2-detail-box-personal-box-3">
                                {item.order_payments !== null && (
                                  <>
                                    <p>{item.order_payments.payment_type}</p>
                                    <p>
                                      {item.order_payments.total_payment_amount}
                                    </p>
                                    <p>{item.order_payments.payment_date}</p>
                                    <p>{item.order_payments.transaction_id}</p>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="order-table-item-open-box-2-detail-box-personal-box">
                              <div className="order-table-item-open-box-2-detail-box-personal-box-2">
                                <p>نوع ارسال</p>
                                <p>هزینه ارسال</p>
                                <p>تاریخ و زمان تحویل</p>
                              </div>
                              <div className="order-table-item-open-box-2-detail-box-personal-box-3">
                                <p>{item.delivery_type}</p>
                                <p>{item.delivery_price}</p>
                                <p>{item.delivery_dateTime}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {item.order_items.length !== 0 && (
                          <div className="order-table-item-open-box-3">
                            <div className="order-table-item-open-box-3-top-bar">
                              <div className="order-table-item-open-box-3-top-bar-hashtag">
                                <p>#</p>
                              </div>
                              <div className="order-table-item-open-box-3-top-bar-product">
                                <p>محصولات</p>
                              </div>
                              <div className="order-table-item-open-box-3-top-bar-variable">
                                <p>متغییر</p>
                              </div>
                              <div className="order-table-item-open-box-3-top-bar-counter">
                                <p>تعداد</p>
                              </div>
                              <div className="order-table-item-open-box-3-top-bar-price">
                                <p>قیمت (تومان)</p>
                              </div>
                            </div>

                            <div className="order-table-item-open-box-3-item">
                              {item.order_items.length !== 0 &&
                                item.order_items.map((item2, i) => {
                                  return (
                                    <div
                                      key={i}
                                      className={
                                        item.order_items.length - 1 === i
                                          ? "order-table-item-open-box-3-item-box-2"
                                          : "order-table-item-open-box-3-item-box"
                                      }
                                    >
                                      <div className="order-table-item-open-box-3-item-box-hashtag">
                                        <p>{i + 1}</p>
                                      </div>
                                      <div className="order-table-item-open-box-3-item-box-name">
                                        <div className="order-table-item-open-box-3-item-box-name-img">
                                          <img src={item2.product_image} />
                                        </div>
                                        <p>{item2.product_name}</p>
                                      </div>
                                      <div className="order-table-item-open-box-3-item-box-variable">
                                        {item2.key_property !== null &&
                                          item2.key_property === "رنگ" && (
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "36px",
                                                maxWidth: "36px",
                                                height: "10px",
                                                borderRadius: "10px",
                                                border: `solid 1px ${item2.alterVariable_property}`,
                                                backgroundColor: `${item2.alterVariable_property}`,
                                              }}
                                            ></div>
                                          )}
                                        {item2.key_property !== null &&
                                          item2.key_property !== "رنگ" && (
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "36px",
                                                maxWidth: "36px",
                                                height: "10px",
                                                borderRadius: "10px",
                                                border: "solid 1px #000000",
                                              }}
                                            >
                                              <p
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  fontFamily: "IRANSansFaNum",
                                                  fontWeight: "400",
                                                  direction: "rtl",
                                                  fontSize: "10px",
                                                  color: "#000000",
                                                }}
                                              >
                                                {item2.variable_property}
                                              </p>
                                            </div>
                                          )}
                                      </div>
                                      <div className="order-table-item-open-box-3-item-box-counter">
                                        {item2.product_count}
                                      </div>
                                      <div className="order-table-item-open-box-3-item-box-price">
                                        {item2.price}
                                        {item2.discount_amount !== 0 && (
                                          <div className="order-table-item-open-box-3-item-box-discount">
                                            {Math.round((item2.discount_amount / item2.price) * (100))}
                                            %
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </ScrollDrag>
          </SimpleBar>
          {tab === "زباله دان" ? (
            <div className="order-paginate-box">
              <Pagination
                firstAxios={garbageAxios}
                currentAxios="/Order/trash?page="
              />
            </div>
          ) : (
            <div className="order-paginate-box">
              <Pagination firstAxios={firstAxios} currentAxios={currentAxios} />
            </div>
          )}
        </div>
        {showFilter === true && (
          <Filter
            Date={true}
            Category={false}
            Situation={true}
            Price={true}
            User={true}
            UserAxios="User?isNotPaginated=1"
            Data={Data}
            axiosURL="/Order"
          />
        )}
      </div>
    </div>
  );
}
