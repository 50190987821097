import React, { useEffect, useState } from "react";
import "../../assets/styles/VisitorStatisticsStyle/VisitorStatisticsMostCity.css";
import { convertObjToArray } from "../../helper/Helper";
import axios from "../../js/Axios/Axios";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
export default function VisitorStatisticsMostCity() {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get("/visitInfo/mostVisitedCitiesReport")
      .then((res) => {
        console.log(res);
        setData(convertObjToArray(res.data.data));
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div className="visitor-statistics-most-city">
      <div className="visitor-statistics-most-city-title-box">
        <p>پر بازدیدترین شهر ها</p>
      </div>
      <div className="visitor-statistics-most-city-box">
        <div className="visitor-statistics-most-city-box-top-bar">
          <div className="visitor-statistics-most-city-box-top-bar-hashtag">
            <p>#</p>
          </div>
          <div className="visitor-statistics-most-city-box-top-bar-city">
            <p>شهر</p>
          </div>
          <div className="visitor-statistics-most-city-box-top-bar-count">
            <p>تعداد بازدیدکنندگان</p>
          </div>
        </div>
        <SimpleBar
          forceVisible="y"
          className="visitor-statistics-most-city-item-box"
        >
          {data.map((item, index) => {
            return (
              <div key={index} className="visitor-statistics-most-city-item-1">
                <div className="visitor-statistics-most-city-item-hashtag">
                  <p>{index + 1}</p>
                </div>
                <div className="visitor-statistics-most-city-item-city">
                  <p>{item.key}</p>
                </div>
                <div className="visitor-statistics-most-city-item-count">
                  <p>{item.value}</p>
                </div>
              </div>
            );
          })}
        </SimpleBar>
      </div>
    </div>
  );
}
