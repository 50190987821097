import React, { useState, useEffect } from "react";
import "../../assets/styles/AddSeoStyle/Seo.css";
import InputComponent from "../TextInputComponent/TextInputComponent";
import AddIcon from "../../assets/icons/AddIcon.svg";
import { useSelector, useDispatch } from "react-redux";
import useForceUpdate from "use-force-update";
import { SendSeoInfo, ShowAlert } from "../../action/index";
import { SendSeoQuestion } from "../../action/index";
import { SendSeoAnswer } from "../../action/index";
import { SendSeoQuestionAnswerArray } from "../../action/index";
import { isObjectEmpty } from "../../helper/Helper";
import { useLocation } from "react-router-dom";

function Seo() {
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const location = useLocation();
  const ProductInfoForEdit = useSelector((state) => state.ProductInfoForEdit);
  const [error, setError] = useState({
    title: false,
    url: false,
    canonical: false,
    meta: false,
    faqs: [{ question: false, answer: false }],
  });
  const [seoData, setSeoData] = useState({
    title: null,
    url: null,
    canonical: null,
    meta: null,
    seoId: null,
  });
  const [faqs, setFaqs] = useState([{ question: null, answer: null }]);

  const AddSeo = () => {
    if (seoData.title === null) {
      setError((prev) => {
        prev.title = true;
        forceUpdate();
        return prev;
      });
    }
    if (seoData.meta === null) {
      setError((prev) => {
        prev.meta = true;
        forceUpdate();
        return prev;
      });
    }
    if (seoData.canonical === null) {
      setError((prev) => {
        prev.canonical = true;
        forceUpdate();
        return prev;
      });
    }
    if (seoData.url === null) {
      setError((prev) => {
        prev.url = true;
        forceUpdate();
        return prev;
      });
    }
    if (faqs[faqs.length - 1].answer === null) {
      setError((prev) => {
        prev.faqs[error.faqs.length - 1].answer = true;
        forceUpdate();
        return prev;
      });
    }
    if (faqs[faqs.length - 1].question === null) {
      setError((prev) => {
        prev.faqs[error.faqs.length - 1].question = true;
        forceUpdate();
        return prev;
      });
    }
    if (
      seoData.title != null &&
      seoData.url != null &&
      seoData.canonical != null &&
      seoData.meta != null &&
      faqs[faqs.length - 1].answer != null &&
      faqs[faqs.length - 1].question != null
    ) {
      faqs.push({
        question: null,
        answer: null,
      });
      error.faqs.push({
        question: false,
        answer: false,
      });
      forceUpdate();
      dispatch(SendSeoQuestionAnswerArray());
    } else {
      dispatch(
        ShowAlert(
          true,
          "برای اضافه کردن سوال و جواب پر کردن فیلد های قرمز الزامی است"
        )
      );
    }
  };
  useEffect(() => {
    dispatch(SendSeoInfo(seoData));
    return () => {};
  }, [seoData]);

  const questionOnchangeHandler = (e, myindex) => {
    faqs[myindex].question = e.target.value;
    dispatch(SendSeoQuestion(myindex, faqs[myindex].question));
    error.faqs[myindex].question = false;
    forceUpdate();
  };
  const answerOnchangeHandler = (e, myindex) => {
    faqs[myindex].answer = e.target.value;
    dispatch(SendSeoAnswer(myindex, faqs[myindex].answer));
    error.faqs[myindex].answer = false;
    forceUpdate();
  };

  useEffect(() => {
    if (
      !isObjectEmpty(ProductInfoForEdit) &&
      (location.pathname === "/AllProductUpdate" ||
        location.pathname === "/AllDraftProductUpdate") &&
      ProductInfoForEdit.state.productSeo != null
    ) {
      setSeoData((prev) => {
        return {
          title: ProductInfoForEdit.state.productSeo.title,
          url: ProductInfoForEdit.state.productSeo.url,
          canonical: ProductInfoForEdit.state.productSeo.canonical,
          meta: ProductInfoForEdit.state.productSeo.meta,
          seoId: ProductInfoForEdit.state.productSeo.id,
        };
      });
      if (ProductInfoForEdit.state.productSeo.productSeofaqs.length != 0) {
        faqs.splice(0, 1);
        error.faqs.splice(0, 1);
        ProductInfoForEdit.state.productSeo.productSeofaqs.map(
          (item, myindex) => {
            faqs.push({
              question: item.answer,
              answer: item.question,
            });
            error.faqs.push({
              question: false,
              answer: false,
            });
            dispatch(SendSeoQuestionAnswerArray());
            dispatch(SendSeoQuestion(myindex, faqs[myindex].question));
            dispatch(SendSeoAnswer(myindex, faqs[myindex].answer));
          }
        );

        forceUpdate();
      }
    }
  }, [ProductInfoForEdit]);

  return (
    <div className="SeoBox">
      <div className="SeoTittleBox">
        <p className="SeoTittle">افزودن جزئیات سئو</p>
      </div>
      <div className="SeoUpInputsBox">
        <div className="SeoUpInputsBoxRight">
          <div className="SeoUpInputsBoxRightUp">
            <InputComponent
              value={seoData.title}
              width="45%"
              height="44px"
              placeholder="عنوان سئو"
              borderColor={error.title === true ? "#F23B3B" : "#dcdcdc"}
              inputType="input"
              type="text"
              onchange={(e) => {
                setSeoData((prev) => {
                  return {
                    title: e.target.value,
                    url: prev.url,
                    canonical: prev.canonical,
                    meta: prev.meta,
                    seoId: prev.seoId,
                  };
                });
                setError((prev) => {
                  prev.title = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />

            <InputComponent
              value={seoData.url}
              width="45%"
              height="44px"
              placeholder="URL"
              borderColor={error.url === true ? "#F23B3B" : "#dcdcdc"}
              inputType="input"
              type="text"
              onchange={(e) => {
                setSeoData((prev) => {
                  return {
                    title: prev.title,
                    url: e.target.value,
                    canonical: prev.canonical,
                    meta: prev.meta,
                    seoId: prev.seoId,
                  };
                });
                setError((prev) => {
                  prev.url = false;
                  // forceUpdate()
                  return prev;
                });
              }}
            />
          </div>
          <div className="SeoUpInputsBoxRightDown">
            <InputComponent
              value={seoData.canonical}
              width="100%"
              height="44px"
              placeholder="canonical"
              borderColor={error.canonical === true ? "#F23B3B" : "#dcdcdc"}
              inputType="input"
              type="text"
              onchange={(e) => {
                setSeoData((prev) => {
                  return {
                    title: prev.title,
                    url: prev.url,
                    canonical: e.target.value,
                    meta: prev.meta,
                    seoId: prev.seoId,
                  };
                });
                setError((prev) => {
                  prev.canonical = false;
                  // forceUpdate()
                  return prev;
                });
              }}
            />
          </div>
        </div>
        <div className="SeoUpInputsBoxLeft">
          <InputComponent
            value={seoData.meta}
            width="100%"
            height="100%"
            placeholder="متا"
            borderColor={error.meta === true ? "#F23B3B" : "#dcdcdc"}
            inputType="textarea"
            type="text"
            onchange={(e) => {
              setSeoData((prev) => {
                return {
                  title: prev.title,
                  url: prev.url,
                  canonical: prev.canonical,
                  meta: e.target.value,
                  seoId: prev.seoId,
                };
              });
              setError((prev) => {
                prev.meta = false;
                // forceUpdate()
                return prev;
              });
            }}
          />
        </div>
      </div>
      <div className="SeoTittleMiddleBox">
        <p className="SeoTittleMiddle">FAQ</p>
      </div>
      {faqs.map((myitem, myindex) => {
        return (
          <div key={myindex} className="SeoDownInputsBox">
            <InputComponent
              value={faqs[myindex].question}
              width="47%"
              height="44px"
              placeholder="سوال"
              borderColor={
                error.faqs[myindex].question === true ? "#F23B3B" : "#dcdcdc"
              }
              inputType="input"
              type="text"
              onchange={(e) => questionOnchangeHandler(e, myindex)}
            />
            <InputComponent
              value={faqs[myindex].answer}
              width="47%"
              height="44px"
              placeholder="جواب"
              borderColor={
                error.faqs[myindex].answer === true ? "#F23B3B" : "#dcdcdc"
              }
              inputType="input"
              type="text"
              onchange={(e) => answerOnchangeHandler(e, myindex)}
            />
          </div>
        );
      })}
      <div className="SeoAddButtonBox">
        <button onClick={() => AddSeo()} className="SeoAddButton">
          {/* <AddCircleIcon className="SeoAddButtonIcon" /> */}
          <img src={AddIcon} />
        </button>
      </div>
    </div>
  );
}

export default Seo;
