import React, { useEffect, useState } from "react";
import "../../assets/styles/VisitorStatisticsStyle/SalesReportStyle/SalesReportBestProduct.css";
import SimpleBar from "simplebar-react";
import axios from "../../js/Axios/Axios";
export default function VisitorStatisticsBestCustomer() {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get("/product/report")
      .then((res) => {
        console.log(res);
        setData(res.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div className="sales-report-best-customer">
      <div className="sales-report-best-customer-title">
        <p>محصولات پرفروش</p>
      </div>
      <div className="sales-report-best-customer-box">
        <div className="sales-report-best-customer-top-bar">
          <div className="sales-report-best-customer-top-bar-hashtag">
            <p>#</p>
          </div>
          <div className="sales-report-best-customer-top-bar-name">
            <p>محصول</p>
          </div>
          <div className="sales-report-best-customer-top-bar-variable">
            <p>متغییر</p>
          </div>
          <div className="sales-report-best-customer-top-bar-order-count">
            <p>تعداد فروش رفته</p>
          </div>
          <div className="sales-report-best-customer-top-bar-buy-count">
            <p>مجموع مبلغ (تومان)</p>
          </div>
        </div>
        <SimpleBar
          forceVisible="y"
          className="sales-report-best-customer-item-box"
        >
          {data.map((item, index) => {
            return (
              <div key={index} className="sales-report-best-customer-item">
                <div className="sales-report-best-customer-item-hashtag">
                  <p>{index + 1}</p>
                </div>
                <div className="sales-report-best-customer-item-name">
                  <p>{item.product_name}</p>
                </div>
                <div className="sales-report-best-customer-item-variable">
                  {item.key !== null && item.key === "رنگ" && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "70px",
                        maxWidth: "70px",
                        height: "30px",
                        marginTop: "17px",
                        borderRadius: "10px",
                        border: `solid 1px ${item.alter_value}`,
                      }}
                    >
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontFamily: "IRANSansFaNum",
                          fontWeight: "900",
                          direction: "rtl",
                          fontSize: "16px",
                          color: `${item.alter_value}`,
                        }}
                      >
                        {item.main_value}
                      </p>
                    </div>
                  )}
                  {item.key !== null && item.key !== "رنگ" && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "70px",
                        maxWidth: "70px",
                        height: "30px",
                        marginTop: "17px",
                        borderRadius: "10px",
                        border: "solid 1px #000000",
                      }}
                    >
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontFamily: "IRANSansFaNum",
                          fontWeight: "900",
                          direction: "rtl",
                          fontSize: "16px",
                          color: "#000000",
                        }}
                      >
                        {item.main_value}
                      </p>
                    </div>
                  )}
                </div>
                <div className="sales-report-best-customer-item-order-count">
                  <p>{item.final_sale_count}</p>
                </div>
                <div className="sales-report-best-customer-item-buy-count">
                  <p>{item.final_price}</p>
                </div>
              </div>
            );
          })}
        </SimpleBar>
      </div>
    </div>
  );
}
