const iniState ={
    productSeo_id : null,
	is_edit : 0,
    faqs : [
    {    
        question : null ,
        answer : null
    },
    ]
}
const SendSeoQuestionAnswerReducer = (state = iniState , action) =>{
   switch(action.type){
       case "SEND_SEO_QUESTION":
  state.faqs[action.myindex].question = action.question
  return state

   }
   switch(action.type){
    case "SEND_SEO_ANSWER":
        state.faqs[action.myindex].answer = action.answer
        return state
        
    }
    switch(action.type){
        case "SEND_SEO_QUESTION_ANSWER_ARRAY":
           let seofaqs ={
               question: null,
               answer:null
           }
           state.faqs.push(seofaqs)
            return state
            default : 
            return state
        }
   
}
export default SendSeoQuestionAnswerReducer;