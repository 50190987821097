import React, { useState, useEffect } from "react";
import "../../../assets/styles/Blog/CreateBlogStyle/BlogSeoStyle.css";
import TextInputComponent from "../../TextInputComponent/TextInputComponent";
import useForceUpdate from "use-force-update";
import { useSelector, useDispatch } from "react-redux";
import AddIcon from "../../../assets/icons/AddIcon.svg";
import { SendBlogSeoInfo } from "../../../action/index";
import { AddBlogSeoFaq } from "../../../action/index";
import { SendBlogSeoFaqQuestion } from "../../../action/index";
import { SendBlogSeoFaqAnswer } from "../../../action/index";
import { isObjectEmpty } from "../../../helper/Helper";
import { useLocation } from "react-router-dom";

export default function BlogSeo() {
  const blogEdit = useSelector((state) => state.BlogEdit);
  const location = useLocation();
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const [blogSeoInfo, setBlogSeoInfo] = useState({
    title: null,
    url: null,
    meta: null,
    canonical: null,
    blog_id: null,
  });
  const [errors, setErrors] = useState({
    title: false,
    url: false,
    meta: false,
    canonical: false,
    faq: [{ answer: false, question: false }],
  });
  const [blogSeoFaq, setBlogSeoFaq] = useState([
    { answer: null, question: null },
  ]);
  const addFaq = () => {
    if (blogSeoInfo.title === null) {
      setErrors((prev) => {
        prev.title = true;
        forceUpdate();
        return prev;
      });
    }
    if (blogSeoInfo.url === null) {
      setErrors((prev) => {
        prev.url = true;
        forceUpdate();
        return prev;
      });
    }
    if (blogSeoInfo.meta === null) {
      setErrors((prev) => {
        prev.meta = true;
        forceUpdate();
        return prev;
      });
    }
    if (blogSeoInfo.canonical === null) {
      setErrors((prev) => {
        prev.canonical = true;
        forceUpdate();
        return prev;
      });
    }
    if (blogSeoFaq[blogSeoFaq.length - 1].answer === null) {
      setErrors((prev) => {
        prev.faq[errors.faq.length - 1].answer = true;
        forceUpdate();
        return prev;
      });
    }
    if (blogSeoFaq[blogSeoFaq.length - 1].question === null) {
      setErrors((prev) => {
        prev.faq[errors.faq.length - 1].question = true;
        forceUpdate();
        return prev;
      });
    }
    if (
      blogSeoFaq[blogSeoFaq.length - 1].answer !== null &&
      blogSeoFaq[blogSeoFaq.length - 1].question !== null
    ) {
      blogSeoFaq.push({ answer: null, question: null });
      errors.faq.push({ answer: false, question: false });
      dispatch(AddBlogSeoFaq());
    }
    forceUpdate();
  };
  const faqQuestionOnChange = (e, index) => {
    blogSeoFaq[index].question = e.target.value;
    dispatch(SendBlogSeoFaqQuestion(blogSeoFaq[index].question, index));
    forceUpdate();
    errors.faq[index].question = false;
  };

  const faqAnswerOnChange = (e, index) => {
    blogSeoFaq[index].answer = e.target.value;
    dispatch(SendBlogSeoFaqAnswer(blogSeoFaq[index].answer, index));
    forceUpdate();
    errors.faq[index].answer = false;
  };
  useEffect(() => {
    dispatch(SendBlogSeoInfo(blogSeoInfo));
  }, [blogSeoInfo]);
  useEffect(() => {
    if (!isObjectEmpty(blogEdit)) {
      if (location.pathname === "/EditBlog") {
        blogEdit.blogSeos.map((item) => {
          setBlogSeoInfo((prev) => {
            return {
              title: item.title,
              url: item.url,
              meta: item.meta,
              canonical: item.canonical,
              blog_id: item.id,
            };
          });
          if (item.blogSeoFaqs.length > 0) {
            blogSeoFaq.splice(0, 1);
            errors.faq.splice(0, 1);
          }
          item.blogSeoFaqs.map((item, index) => {
            blogSeoFaq.push({ answer: null, question: null });
            errors.faq.push({ answer: false, question: false });
            dispatch(AddBlogSeoFaq());
            blogSeoFaq[index].question = item.question;
            dispatch(SendBlogSeoFaqQuestion(blogSeoFaq[index].question, index));
            blogSeoFaq[index].answer = item.answer;
            dispatch(SendBlogSeoFaqAnswer(blogSeoFaq[index].answer, index));
          });
        });
      }
    }
  }, [blogEdit]);

  return (
    <div className="blog-seo-all">
      <div className="blog-seo-title-box">
        <p>افزودن جزئیات سئو</p>
      </div>
      <div className="blog-seo-info-inputs-box">
        <div className="blog-seo-title-url-connecial-box">
          <div className="blog-seo-title-url-box">
            <TextInputComponent
              value={blogSeoInfo.title}
              width="45%"
              height="44px"
              placeholder="عنوان سئو"
              borderColor={errors.title === true ? "#F23B3B" : "#dcdcdc"}
              inputType="input"
              type="text"
              onchange={(e) => {
                setBlogSeoInfo((prev) => {
                  return {
                    title: e.target.value,
                    url: prev.url,
                    meta: prev.meta,
                    canonical: prev.canonical,
                    blog_id: prev.blog_id,
                  };
                });
                setErrors((prev) => {
                  prev.title = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />

            <TextInputComponent
              value={blogSeoInfo.url}
              width="45%"
              height="44px"
              placeholder="URL"
              borderColor={errors.url === true ? "#F23B3B" : "#dcdcdc"}
              inputType="input"
              type="text"
              onchange={(e) => {
                setBlogSeoInfo((prev) => {
                  return {
                    title: prev.title,
                    url: e.target.value,
                    meta: prev.meta,
                    canonical: prev.canonical,
                    blog_id: prev.blog_id,
                  };
                });
                setErrors((prev) => {
                  prev.url = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
          </div>
          <div className="blog-seo-canonical-box">
            <TextInputComponent
              value={blogSeoInfo.canonical}
              width="97%"
              height="44px"
              placeholder="canonical"
              borderColor={errors.canonical === true ? "#F23B3B" : "#dcdcdc"}
              inputType="input"
              type="text"
              onchange={(e) => {
                setBlogSeoInfo((prev) => {
                  return {
                    title: prev.title,
                    url: prev.url,
                    meta: prev.meta,
                    canonical: e.target.value,
                    blog_id: prev.blog_id,
                  };
                });
                setErrors((prev) => {
                  prev.canonical = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
          </div>
        </div>
        <div className="blog-seo-meta-box">
          <TextInputComponent
            value={blogSeoInfo.meta}
            width="100%"
            height="118px"
            placeholder="متا"
            borderColor={errors.meta === true ? "#F23B3B" : "#dcdcdc"}
            inputType="textarea"
            type="text"
            onchange={(e) => {
              setBlogSeoInfo((prev) => {
                return {
                  title: prev.title,
                  url: prev.url,
                  meta: e.target.value,
                  canonical: prev.canonical,
                  blog_id: prev.blog_id,
                };
              });
              setErrors((prev) => {
                prev.meta = false;
                forceUpdate();
                return prev;
              });
            }}
          />
        </div>
      </div>
      <div className="blog-seo-faq-title-box">
        <p>FAQ</p>
      </div>
      {blogSeoFaq.map((item, index) => {
        return (
          <div key={index} className="blog-seo-faq-input-box">
            <TextInputComponent
              value={blogSeoFaq[index].question}
              width="47%"
              height="44px"
              placeholder="سوال"
              borderColor={
                errors.faq[index].question === true ? "#F23B3B" : "#dcdcdc"
              }
              inputType="input"
              type="text"
              onchange={(e) => faqQuestionOnChange(e, index)}
            />
            <TextInputComponent
              value={blogSeoFaq[index].answer}
              width="47%"
              height="44px"
              placeholder="جواب"
              borderColor={
                errors.faq[index].answer === true ? "#F23B3B" : "#dcdcdc"
              }
              inputType="input"
              type="text"
              onchange={(e) => faqAnswerOnChange(e, index)}
            />
          </div>
        );
      })}

      <div className="blog-seo-add-faq-box">
        <img onClick={() => addFaq()} src={AddIcon} />
      </div>
    </div>
  );
}
