export const permissions = () => {
  return [
    {
      permission: "Dashboard",
      initPath: "/",
    },
    {
      permission: "Product",
      initPath: "AllProduct",
    },
    {
      permission: "Order",
      initPath: "Order",
    },
    {
      permission: "Blog",
      initPath: "ManageBlog",
    },
    {
      permission: "Media",
      initPath: "Media",
    },
    {
      permission: "Customer",
      initPath: "Customers",
    },
    {
      permission: "UserSystem",
      initPath: "ManageUser",
    },
    {
      permission: "Support",
      initPath: "FrequentlyQuestion",
    },
    {
      permission: "SmsSystem",
      initPath: "SmsManage",
    },
    {
      permission: "Report",
      initPath: "SalesReport",
    },
    {
      permission: "Setting",
      initPath: "EditStore",
    },
  ];
};
