const iniState = {
  id: null,
};
const SendOrderIdReducer = (state = iniState, action) => {
  switch (action.type) {
    case "SEND_ORDER_ID":
      return { ...state, id: action.id };

    default:
      return state;
  }
};
export default SendOrderIdReducer;
