const iniState = {};
const ProductInfoForEditReducer = (state = iniState, action) => {
  switch (action.type) {
    case "PRODUCT_INFO_FOR_EDIT":
      return { state: action.ProductInfo };

    default:
      return state;
  }
};
export default ProductInfoForEditReducer;
