import React, { useState } from "react";
import "../../assets/styles/ShowAlertStyle/ShowAlertStyle.css";
import AlertCheck from "../../assets/icons/AlertCheck.svg";
import GreenThik from "../../assets/icons/GreenThik.svg";
import GrayCross from "../../assets/icons/GrayCross.svg";
import RedCrossError from "../../assets/icons/RedCrossError.svg";
import BlueError from "../../assets/icons/BlueError.svg";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

function ShowAlert() {
  const [showAlert, setShowAlert] = useState(false);
  const [alertColor, setAlertColor] = useState(null);
  const alertData = useSelector((state) => state.ShowAlert);

  useEffect(() => {
    if (alertData.show === true) {
      setShowAlert(true);
      alertData.errors.map((item) => {
        let red = "الزامی";
        let green = "موفقیت";
        let red2 = "صحیح نمی باشد";
        let red3 = "نباید";
        let red4 = "معتبر نیست";
        let red5 = "باید";
        let red6 = "قبلا ساخته شده";
        let red7 =
          "برای بازگرداندن بیشتر از یک از کیلید بازیابی محصولات استفاده کنید ";
        let red9 = "تعداد محصول درخواستی شما بیش از موجودی انبار می باشد .";
        let blue = "امکان حذف نمی باشد";
        let blue2 = "انتخاب نمایید .";
        let blue3 = "امکان حذف مستقیم نمی باشد";
        let red8 = "لطفا";
        let red10 = "نبود";
        let red11 = "امکان پذیر نیست";
        let red12 = "شما اجازه دسترسی به این صفحه را ندارید ";
        if (item.includes(red)) {
          setAlertColor("#F23B3B");
        }
        if (item.includes(blue)) {
          setAlertColor("#2D8DD7");
        }
        if (item.includes(blue2)) {
          setAlertColor("#2D8DD7");
        }
        if (item.includes(blue3)) {
          setAlertColor("#2D8DD7");
        }
        if (item.includes(green)) {
          setAlertColor("#2CAE7C");
        }
        if (item.includes(red2)) {
          setAlertColor("#F23B3B");
        }
        if (item.includes(red3)) {
          setAlertColor("#F23B3B");
        }
        if (item.includes(red4)) {
          setAlertColor("#F23B3B");
        }
        if (item.includes(red5)) {
          setAlertColor("#F23B3B");
        }
        if (item.includes(red6)) {
          setAlertColor("#F23B3B");
        }
        if (item.includes(red7)) {
          setAlertColor("#F23B3B");
        }
        if (item.includes(red8)) {
          setAlertColor("#F23B3B");
        }
        if (item.includes(red9)) {
          setAlertColor("#F23B3B");
        }
        if (item.includes(red10)) {
          setAlertColor("#F23B3B");
        }
        if (item.includes(red11)) {
          setAlertColor("#F23B3B");
        }
        if (item.includes(red12)) {
          setAlertColor("#F23B3B");
        }
      });
    } else {
      setShowAlert(false);
    }
  }, [alertData]);
  const CloseNotif = () => {
    setShowAlert(false);
  };

  if (alertData.show === true) {
    setTimeout(() => {
      setShowAlert(false);
    }, 15000);
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "calc(400px - 30px)",
        minHeight: "40px",
        borderRadius: "10px",
        position: "fixed",
        transition: "width 0.5s",
        left: "20px",
        bottom: "20px",
        transform: `${
          showAlert === true ? "translatey(-40%)" : "translatey(130%)"
        }`,
        transition: "transform 0.5s",
        boxShadow: "0px 0px 15px rgba(0 , 0 , 0 , 0.2)",
        padding: "15px 15px 15px 15px",
        backgroundColor: "#FBFFFD",
        borderTop: `solid 4px ${alertColor}`,
        zIndex: "9999",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "90%",
          height: "auto",
        }}
      >
        <div>
          {alertColor === "#2CAE7C" && <img src={GreenThik} />}
          {alertColor === "#F23B3B" && <img src={RedCrossError} />}
          {alertColor === "#2D8DD7" && <img src={BlueError} />}
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {alertData.errors.map((item, index) => {
            return (
              <p
                key={index}
                style={{
                  display: "flex",
                  fontSize: "16px",
                  fontFamily: "IRANSansWebNoEn",
                  color: "#3D3D3D",
                  marginRight: "20px",
                  lineHeight: "35px",
                  lineBreak: "auto",
                }}
              >
                {item}
              </p>
            );
          })}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-start",
          width: "10%",
          height: "auto",
        }}
      >
        <img
          onClick={() => CloseNotif()}
          style={{ cursor: "pointer" }}
          src={GrayCross}
        />
      </div>
    </div>
  );
}
export default ShowAlert;
