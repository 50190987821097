import React, { useEffect, useState } from "react";
import "../../assets/styles/ManageUserStyle/ManageUser.css";
import TopBar from "../../components/TopBar/TopBar";
import axios from "../../js/Axios/Axios";
import useForceUpdate from "use-force-update";
import { useSelector, useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import { ShowAlert } from "../../action/index";
import { isArrayEqual } from "../../helper/Helper";
import DropDownComponent from "../../components/DropDown/DropDown";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import Pagination from "../../components/Pagination/Pagination";
import AllProductAdd from "../../assets/icons/AllProductAdd.svg";
import GreenTickSquare from "../../assets/icons/GreenTickSquare.svg";
import SearchLogo from "../../assets/icons/SearchLogo.svg";
import Deactive from "../../assets/icons/Deactive.svg";
import check from "../../assets/icons/check.svg";
import uncheck from "../../assets/icons/uncheck.svg";
import Edit from "../../assets/icons/Edit.svg";
import EditActive from "../../assets/icons/EditActive.svg";
import cross from "../../assets/icons/cross.svg";
import HidePassword from "../../assets/icons/HidePassword.svg";
import Eye from "../../assets/icons/AllProductEye.svg";

export default function ManageUser() {
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const [newPermission, setNewPermission] = useState([]);
  const [permissionDropDown, setPermissionDropDown] = useState([
    {
      title: "همه",
      id: "",
    },
  ]); // data for permission dropdown
  const [permissionSearch, setPermissionSearch] = useState(""); // permission serarch dropdow
  const [permissionVale, setPermissionVale] = useState(null); // permission value dropdown
  const [permissionShow, setPermissionShow] = useState(null); // permission show dropdwn
  const [firstAxios, setFirstAxios] = useState("/UserSystem"); // for settin axios for pagination
  const [isActiveValue, setIsActiveValue] = useState(null); // is active drop down value
  const [showIsActive, setShowIsActive] = useState(null); // show is active dropdown
  const [isActive, setIsActive] = useState([
    // is activ dropdown data
    {
      title: "فعال",
      id: 1,
    },
    {
      title: "غیر فعال",
      id: 0,
    },
    {
      title: "همه",
      id: "",
    },
  ]);
  const [searchIsActive, setSearchIsActive] = useState(""); // serach is active drop down
  const [userData, setUserData] = useState([]); // all user data
  const [allUserRole, setAllUserRole] = useState([
    {
      accessors: [],
      created_at: null,
      role_id: 0,
      title: "nothing",
      title_fa: "هیچکدام",
    },
  ]); // all user role
  const [allPermission, setAllPermission] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [from, setFrom] = useState(null);
  const [topBarChecked, setTopBarChecked] = useState(false); // checkbox top bar
  const [searchUser, setSearchUser] = useState(""); // for search user
  const [modal, setModal] = useState(false); // for open modal create user
  const [password, setPassword] = useState(false); // show password input and change icon
  const [verifyPassword, setVerifyPassword] = useState(false); // show verify password and change icon
  const [activeRadio, setActiveRadio] = useState(null); // for active radio button for role
  const [situationRadio, setSituationRadio] = useState(false); // for active situation radio button
  const [roleModal, setRoleModal] = useState(false); // for role modal
  const paginatedData = useSelector((state) => state.PaginatedData); // get paginated from reducer
  const [roleInfo, setRoleInfo] = useState({
    // save roll input for cerate rol
    title: null,
    title_fa: null,
    permissions: [],
  });
  const [userInfo, setUserInfo] = useState({
    // save user info
    name: null,
    family: null,
    user_name: null,
    phone_number: null,
    national_code: null,
    password: null,
    password_confirmation: null,
    address_details: null,
    biography: null,
    email: null,
    role_id: [],
    permission_id: [],
    is_active: 1,
  });
  useEffect(() => {
    axios
      .get("/UserSystem")
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          setUserData(res.data.data);
          console.log(userData);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
        }
      })
      .catch((e) => {
        console.log(e);
      });

    axios
      .get("/UserSystemAccess")
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          res.data.data.map((item) => {
            permissionDropDown.push({
              title: item.title_fa,
              id: item.access_id,
            });
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const permissionDropDownClick = (id, title) => {
    console.log(typeof id, title);
    setPermissionVale(title);
    setPermissionShow(false);
    setPermissionSearch("");
    setTimeout(() => {
      setPermissionShow(true);
    }, 100);

    setFirstAxios(`/UserSystem?permission=${id}`);
    axios
      .get(`/UserSystem?permission=${id}`)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          setUserData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          forceUpdate();
        }
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const isActiveDropDown = (id, title) => {
    setIsActiveValue(title);
    setShowIsActive(false);
    setTimeout(() => {
      setShowIsActive(true);
    }, 100);
    setSearchIsActive("");
    setFirstAxios(`/UserSystem?status=${id}`);
    axios
      .get(`/UserSystem?status=${id}`)
      .then((res) => {
        if (res.data.status) {
          setUserData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          forceUpdate();
        }
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    if (paginatedData.length != 0) {
      // if (topBarChecked === true || checkedIds.length != 0) {
      //   setTopBarChecked(false);
      //   garbageData.map((i, index) => {
      //     checkedIds.splice(index);
      //   });
      // }
      setUserData(paginatedData.data);
      setCurrentPage(paginatedData.meta.current_page);
      setFrom(paginatedData.meta.from);
    }
  }, [paginatedData]);
  // search user
  const searchHandelChange = (e) => {
    setSearchUser(e.target.value);
    console.log(searchUser);
    axios
      .get(`/UserSystem?search=${e.target.value}`)
      .then((res) => {
        if (res.data.status) {
          setUserData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          setFirstAxios(`/UserSystem?search=${e.target.value}`);
          forceUpdate();
        }
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  // add user stystem
  const addUserSystem = () => {
    axios
      .get("/UserSystemRole/relatedList")
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          if (allUserRole.length === 1) {
            res.data.data.map((i) => {
              allUserRole.push(i);
            });
          }
          axios
            .get("/UserSystemAccess")
            .then((res) => {
              if (res.data.status) {
                console.log(res);
                setAllPermission(res.data.data);
                setModal(true);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const showPassword = () => {
    setPassword(!password);
  };
  const showVerifyPassword = () => {
    setVerifyPassword(!verifyPassword);
  };

  // role onChange
  const roleOnchange = (e, id, accessors) => {
    setActiveRadio(id);

    if (userInfo.role_id.length != 0) {
      userInfo.role_id.map((item, index) => {
        userInfo.role_id.splice(index);
      });
      userInfo.role_id.push(id);
    } else {
      userInfo.role_id.push(id);
    }
    if (userInfo.permission_id.length != 0) {
      userInfo.permission_id.map((item, index) => {
        userInfo.permission_id.splice(index);
      });

      accessors.map((item) => {
        userInfo.permission_id.push(item.access_id);
      });
    } else {
      accessors.map((item) => {
        userInfo.permission_id.push(item.access_id);
      });
    }
    if (newPermission.length != 0) {
      newPermission.map((Iitem, i) => {
        newPermission.splice(i);
      });
    }

    forceUpdate();
  };
  // permisson onchange
  const permissonChangeClick = (id) => {
    if (userInfo.role_id.includes(0)) {
      if (userInfo.permission_id.includes(id) === false) {
        userInfo.permission_id.push(id);
      } else {
        userInfo.permission_id.map((i) => {
          if (i === id) {
            var indexof = userInfo.permission_id.indexOf(i);
            userInfo.permission_id.splice(indexof, 1);
          }
        });
      }
    }
    // if rol === hichkodam nabud I compair userInfoPermission with All Permission if is false and if id is not in userInfo.permission
    // i push id to userInfo.permission and push to newpermission

    if (userInfo.role_id.includes(0) === false) {
      let permissionArray = [];
      allPermission.map((item) => {
        permissionArray.push(item.access_id);
      });
      let permisionRole = [];
      userInfo.permission_id.map((item) => {
        permisionRole.push(item);
      });
      if (isArrayEqual(permisionRole, permissionArray) === false) {
        if (!userInfo.permission_id.includes(id)) {
          userInfo.permission_id.push(id);
          newPermission.push(id);
          id = null;
        }
      }
      if (newPermission.includes(id)) {
        let indexOfUserInfo = userInfo.permission_id.indexOf(id);
        userInfo.permission_id.splice(indexOfUserInfo, 1);

        let indexOfNewPermission = newPermission.indexOf(id);
        newPermission.splice(indexOfNewPermission, 1);
      }
    }
    console.log(userInfo.permission_id);
    console.log(newPermission);
    forceUpdate();
  };
  // onchange userInfo.is_active
  const activeChangeClick = () => {
    setUserInfo((prev) => {
      return {
        name: prev.name,
        family: prev.family,
        user_name: prev.user_name,
        phone_number: prev.phone_number,
        national_code: prev.national_code,
        password: prev.password,
        password_confirmation: prev.password_confirmation,
        address_details: prev.address_details,
        biography: prev.biography,
        role_id: prev.role_id,
        permission_id: prev.permission_id,
        is_active: 1,
      };
    });
    forceUpdate();
  };
  // onchange userInfo.is_active
  const deactiveChangeClick = () => {
    setUserInfo((prev) => {
      return {
        name: prev.name,
        family: prev.family,
        user_name: prev.user_name,
        phone_number: prev.phone_number,
        national_code: prev.national_code,
        password: prev.password,
        password_confirmation: prev.password_confirmation,
        address_details: prev.address_details,
        biography: prev.biography,
        role_id: prev.role_id,
        permission_id: prev.permission_id,
        is_active: 0,
      };
    });
    forceUpdate();
  };
  const [error, setError] = useState({
    name: false,
    family: false,
    user_name: false,
    phone_number: false,
    national_code: false,
    password: false,
    confirm_password: false,
    address: false,
    biography: false,
  });
  // create user
  const createUser = () => {
    if (isEdit === false) {
      if (!userInfo.role_id.includes(0) && newPermission.length != 0) {
        userInfo.permission_id = newPermission;
      }
      if (!userInfo.role_id.includes(0) && newPermission.length === 0) {
        userInfo.permission_id = [];
      }
      if (userInfo.role_id.includes(0)) {
        userInfo.role_id = [];
      }
      if (
        userInfo.name !== null &&
        userInfo.name !== "" &&
        userInfo.family !== null &&
        userInfo.family !== "" &&
        userInfo.user_name !== null &&
        userInfo.user_name !== "" &&
        userInfo.phone_number !== null &&
        userInfo.phone_number !== "" &&
        userInfo.national_code !== null &&
        userInfo.national_code !== "" &&
        userInfo.password !== null &&
        userInfo.password !== "" &&
        userInfo.confirm_password !== null &&
        userInfo.confirm_password !== "" &&
        userInfo.address_details !== null &&
        userInfo.address_details !== "" &&
        userInfo.biography !== null &&
        userInfo.biography !== ""
      ) {
        axios
          .post("/UserSystem", userInfo)
          .then((res) => {
            if (res.data.status) {
              dispatch(ShowAlert(true, res.data.message));
              setUserInfo((prev) => {
                return {
                  name: null,
                  family: null,
                  user_name: null,
                  phone_number: null,
                  national_code: null,
                  password: null,
                  password_confirmation: null,
                  address_details: null,
                  biography: null,
                  email: null,
                  role_id: [],
                  permission_id: [],
                  is_active: 1,
                };
              });
              if (newPermission.length != 0) {
                newPermission.map((item, index) => {
                  newPermission.splice(index);
                });
              }
              if (userInfo.role_id.length != 0) {
                userInfo.role_id.map((item, index) => {
                  userInfo.role_id.splice(index);
                });
              }
              axios
                .get("/UserSystem")
                .then((res) => {
                  console.log(res);
                  if (res.data.status) {
                    setUserData(res.data.data);
                    console.log(userData);
                    setCurrentPage(res.data.meta.current_page);
                    setFrom(res.data.meta.from);
                  }
                })
                .catch((e) => {
                  console.log(e);
                });
              setModal(false);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        let text = `برای ثبت کاربر پر کردن فیلد های ${
          userInfo.name === null || userInfo.name === "" ? "نام و" : ""
        } ${
          userInfo.family === null || userInfo.family === ""
            ? "نام خانوادگی و"
            : ""
        } ${
          userInfo.user_name === null || userInfo.user_name === ""
            ? "نام کاربری و"
            : ""
        } ${
          userInfo.phone_number === null || userInfo.phone_number === ""
            ? "شماره موبایل و"
            : ""
        }
      ${
        userInfo.national_code === null || userInfo.national_code === ""
          ? "کد ملی"
          : ""
      }
      ${
        userInfo.password === null || userInfo.password === ""
          ? "رمز عبور و"
          : ""
      }
      ${
        userInfo.password_confirmation === null ||
        userInfo.password_confirmation === ""
          ? "تکرار رمز و"
          : ""
      }
      ${
        userInfo.address_details === null || userInfo.address_details === ""
          ? "آدرس و"
          : ""
      }
      ${
        userInfo.biography === null || userInfo.biography === ""
          ? "بیوگرافی "
          : ""
      }
      الزامی است .
      `;
        dispatch(ShowAlert(true, text));
        if (userInfo.name === null || userInfo.name === "") {
          setError((prev) => {
            prev.name = true;
            forceUpdate();
            return prev;
          });
        }
        if (userInfo.family === null || userInfo.family === "") {
          setError((prev) => {
            prev.family = true;
            forceUpdate();
            return prev;
          });
        }
        if (userInfo.phone_number === null || userInfo.phone_number === "") {
          setError((prev) => {
            prev.phone_number = true;
            forceUpdate();
            return prev;
          });
        }
        if (userInfo.user_name === null || userInfo.user_name === "") {
          setError((prev) => {
            prev.user_name = true;
            forceUpdate();
            return prev;
          });
        }
        if (userInfo.password === null || userInfo.password === "") {
          setError((prev) => {
            prev.password = true;
            forceUpdate();
            return prev;
          });
        }
        if (
          userInfo.password_confirmation === null ||
          userInfo.password_confirmation === ""
        ) {
          setError((prev) => {
            prev.confirm_password = true;
            forceUpdate();
            return prev;
          });
        }
        if (
          userInfo.address_details === null ||
          userInfo.address_details === ""
        ) {
          setError((prev) => {
            prev.address = true;
            forceUpdate();
            return prev;
          });
        }
        if (userInfo.biography === null || userInfo.biography === "") {
          setError((prev) => {
            prev.biography = true;
            forceUpdate();
            return prev;
          });
        }
        if (userInfo.national_code === null || userInfo.national_code === "") {
          setError((prev) => {
            prev.national_code = true;
            forceUpdate();
            return prev;
          });
        }
      }
    } else if (isEdit === true) {
      axios
        .put(`/UserSystem/${editId}`, userInfo)
        .then((res) => {
          if (res.data.status === 0) {
            console.log(res);
            dispatch(ShowAlert(true, res.data.message));
          }
          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));
            setIsEdit(false);
            setEditId(null);
            setUserInfo((prev) => {
              return {
                name: null,
                family: null,
                user_name: null,
                phone_number: null,
                national_code: null,
                password: null,
                password_confirmation: null,
                address_details: null,
                biography: null,
                email: null,
                role_id: [],
                permission_id: [],
                is_active: 1,
              };
            });
            if (newPermission.length != 0) {
              newPermission.map((item, index) => {
                newPermission.splice(index);
              });
            }
            if (userInfo.role_id.length != 0) {
              userInfo.role_id.map((item, index) => {
                userInfo.role_id.splice(index);
              });
            }
            axios
              .get("/UserSystem")
              .then((res) => {
                console.log(res);
                if (res.data.status) {
                  setUserData(res.data.data);
                  console.log(userData);
                  setCurrentPage(res.data.meta.current_page);
                  setFrom(res.data.meta.from);
                }
              })
              .catch((e) => {
                console.log(e);
              });
            setModal(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  // cancel create user modal
  const cancelCreateUser = () => {
    setUserInfo((prev) => {
      return {
        name: null,
        family: null,
        user_name: null,
        phone_number: null,
        national_code: null,
        password: null,
        password_confirmation: null,
        address_details: null,
        biography: null,
        role_id: [],
        permission_id: [],
        is_active: true,
      };
    });
    setModal(false);
  };
  // permission onchnage in create role
  const rolePermissionOnchange = (id) => {
    if (roleInfo.permissions.includes(id)) {
      var indexof = roleInfo.permissions.indexOf(id);
      roleInfo.permissions.splice(indexof, 1);
    } else {
      roleInfo.permissions.push(id);
    }
    forceUpdate();
  };
  // cancel crate role
  const cancelCreateRole = () => {
    setRoleInfo((prev) => {
      return {
        title: null,
        title_fa: null,
        permissions: [],
      };
    });
    setRoleModal(false);
  };
  const createRole = () => {
    axios
      .post("/UserSystemRole", roleInfo)
      .then((res) => {
        if (res.data.status === 0) {
          dispatch(ShowAlert(true, res.data.message));
          setRoleModal(false);
          setModal(false);
        }
        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));
          setRoleModal(false);
          setRoleInfo((prev) => {
            prev.title = null;
            prev.title_fa = null;
            prev.permissions = [];
            forceUpdate();
            return prev;
          });
          allUserRole.map((item, index) => {
            allUserRole.splice(index);
            forceUpdate();
          });
          allUserRole.push({
            accessors: [],
            created_at: null,
            role_id: 0,
            title: "nothing",
            title_fa: "هیچکدام",
          });
          forceUpdate();
          axios
            .get("/UserSystemRole/relatedList")
            .then((res) => {
              if (res.data.status) {
                console.log(res);
                if (allUserRole.length === 1) {
                  res.data.data.map((i) => {
                    allUserRole.push(i);
                  });
                }
                axios
                  .get("/UserSystemAccess")
                  .then((res) => {
                    if (res.data.status) {
                      console.log(res);
                      setAllPermission(res.data.data);
                      setModal(true);
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const editUser = (id) => {
    setIsEdit(true);
    setEditId(id);
    axios
      .get("/UserSystemRole/relatedList")
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          if (allUserRole.length === 1) {
            res.data.data.map((i) => {
              allUserRole.push(i);
            });
            forceUpdate();
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
    axios
      .get("/UserSystemAccess")
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setAllPermission(res.data.data);
          setModal(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });

    axios
      .get(`/UserSystem/${id}/edit`)
      .then((res) => {
        if (res.data.status === 0) {
          dispatch(ShowAlert(true, res.data.message));
        }
        if (res.data.status) {
          // dispatch(ShowAlert(true, res.data.message));
          console.log(res);
          setUserInfo((prev) => {
            return {
              name: res.data.data.name,
              email: res.data.data.email,
              family: res.data.data.family,
              user_name: res.data.data.user_name,
              phone_number: res.data.data.phone_number,
              national_code: res.data.data.national_code,
              password: null,
              password_confirmation: null,
              address_details: res.data.data.address_details,
              biography: res.data.data.biography,
              role_id: prev.role_id,
              permission_id: prev.permission_id,
              is_active: res.data.data.is_active,
            };
          });
          if (res.data.data.roles.length != 0) {
            setActiveRadio(res.data.data.roles[0].id);
          }
          if (res.data.data.roles.length != 0) {
            res.data.data.roles.map((item) => {
              userInfo.role_id.push(item.id);
            });
          }
          if (res.data.data.permissions.length != 0) {
            res.data.data.permissions.map((item) => {
              console.log(item);
              userInfo.permission_id.push(item.id);
              newPermission.push(item.id);
              forceUpdate();
            });
          }
          setModal(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="manage-user-all">
      <Modal
        onBackdropClick={() => cancelCreateRole()}
        className="manage-user-modal-role"
        open={roleModal}
      >
        <div className="mange-user-modal-rol-all-box">
          <div className="mange-user-modal-rol-top-bar">
            <p>ایجاد نقش</p>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => cancelCreateRole()}
              src={cross}
            />
          </div>
          <div className="manage-user-modal-rol-input-box">
            <InputComponent
              width="45%"
              height="44px"
              id={"roletitle_fa"}
              value={roleInfo.title_fa}
              placeholder="عنوان فارسی "
              borderColor="#dcdcdc"
              inputType="input"
              type="text"
              onchange={(e) => {
                setRoleInfo((prev) => {
                  return {
                    title: prev.title,
                    title_fa: e.target.value,
                    permissions: prev.permissions,
                  };
                });
              }}
            />

            <InputComponent
              width="45%"
              height="44px"
              id={"roletitle"}
              value={roleInfo.title}
              placeholder="عنوان انگلیس "
              borderColor="#dcdcdc"
              inputType="input"
              type="text"
              onchange={(e) => {
                setRoleInfo((prev) => {
                  return {
                    title: e.target.value,
                    title_fa: prev.title_fa,
                    permissions: prev.permissions,
                  };
                });
              }}
            />
          </div>
          <div className="mange-user-modal-rol-permission-box">
            {allPermission.map((item, index) => {
              return (
                <label key={index}>
                  <input
                    checked={roleInfo.permissions.includes(item.access_id)}
                    style={{ display: "none" }}
                    id={`Rolepermisson${index}`}
                    name={`Rolepermisson${index}`}
                    onChange={() => rolePermissionOnchange(item.access_id)}
                    type="checkbox"
                  />
                  <div className="mange-user-modal-rol-permission-box2">
                    <img
                      style={{
                        display: "flex",
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                      src={
                        roleInfo.permissions.includes(item.access_id)
                          ? check
                          : uncheck
                      }
                    />
                    <p className="mange-user-modal-rol-permission-title">
                      {item.title_fa}
                    </p>
                  </div>
                </label>
              );
            })}
          </div>
          <div className="manage-user-modal-rol-button-box">
            <button
              onClick={() => cancelCreateRole()}
              className="manage-user-modal-rol-cancel-button"
            >
              <p>لغو</p>
            </button>
            <button
              onClick={() => createRole()}
              className="manage-user-modal-rol-save-button"
            >
              <p>ذخیره</p>
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        onBackdropClick={() => cancelCreateUser()}
        className="manage-user-modal"
        open={modal}
      >
        <div className="manage-user-modal-box">
          <div className="manage-user-modal-box-top-bar">
            <p>ایجاد کاربر</p>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => cancelCreateUser()}
              src={cross}
            />
          </div>
          <div
            style={{ padding: "30px 0 0 0" }}
            className="manage-user-modal-input-box-1"
          >
            <InputComponent
              width="45%"
              height="44px"
              id={"name"}
              value={userInfo.name}
              placeholder="نام "
              borderColor={error.name === true ? "#F23B3B" : "#dcdcdc"}
              inputType="input"
              type="text"
              onchange={(e) => {
                setUserInfo((prev) => {
                  return {
                    name: e.target.value,
                    email: prev.email,
                    family: prev.family,
                    user_name: prev.user_name,
                    phone_number: prev.phone_number,
                    national_code: prev.national_code,
                    password: prev.password,
                    password_confirmation: prev.password_confirmation,
                    address_details: prev.address_details,
                    biography: prev.biography,
                    role_id: prev.role_id,
                    permission_id: prev.permission_id,
                    is_active: prev.is_active,
                  };
                });
                setError((prev) => {
                  prev.name = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
            <InputComponent
              width="45%"
              height="44px"
              id={"family"}
              value={userInfo.family}
              placeholder="نام خانوادگی"
              borderColor={error.family === true ? "#F23B3B" : "#dcdcdc"}
              inputType="input"
              type="text"
              onchange={(e) => {
                setUserInfo((prev) => {
                  return {
                    name: prev.name,
                    family: e.target.value,
                    email: prev.email,
                    user_name: prev.user_name,
                    phone_number: prev.phone_number,
                    national_code: prev.national_code,
                    password: prev.password,
                    password_confirmation: prev.password_confirmation,
                    address_details: prev.address_details,
                    biography: prev.biography,
                    role_id: prev.role_id,
                    permission_id: prev.permission_id,
                    is_active: prev.is_active,
                  };
                });
                setError((prev) => {
                  prev.family = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
          </div>
          <div className="manage-user-modal-input-box-1">
            <InputComponent
              width="45%"
              height="44px"
              id={"user_name"}
              value={userInfo.user_name}
              placeholder="نام کاربری  "
              borderColor={error.user_name === true ? "#F23B3B" : "#dcdcdc"}
              inputType="input"
              type="text"
              onchange={(e) => {
                setUserInfo((prev) => {
                  return {
                    name: prev.name,
                    family: prev.family,
                    user_name: e.target.value,
                    email: prev.email,
                    phone_number: prev.phone_number,
                    national_code: prev.national_code,
                    password: prev.password,
                    password_confirmation: prev.password_confirmation,
                    address_details: prev.address_details,
                    biography: prev.biography,
                    role_id: prev.role_id,
                    permission_id: prev.permission_id,
                    is_active: prev.is_active,
                  };
                });
                setError((prev) => {
                  prev.user_name = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />

            <InputComponent
              width="45%"
              height="44px"
              id={"email"}
              value={userInfo.email}
              placeholder="ایمیل"
              borderColor="#dcdcdc"
              inputType="input"
              type="text"
              onchange={(e) => {
                setUserInfo((prev) => {
                  return {
                    name: prev.name,
                    family: prev.family,
                    user_name: prev.user_name,
                    email: e.target.value,
                    phone_number: prev.phone_number,
                    national_code: prev.national_code,
                    password: prev.password,
                    password_confirmation: prev.password_confirmation,
                    address_details: prev.address_details,
                    biography: prev.biography,
                    role_id: prev.role_id,
                    permission_id: prev.permission_id,
                    is_active: prev.is_active,
                  };
                });
              }}
            />
          </div>
          <div className="manage-user-modal-input-box-1">
            <InputComponent
              width="45%"
              height="44px"
              id={"phone_number"}
              value={userInfo.phone_number}
              placeholder="موبایل"
              borderColor={error.phone_number === true ? "#F23B3B" : "#dcdcdc"}
              inputType="input"
              type="number"
              onchange={(e) => {
                setUserInfo((prev) => {
                  return {
                    name: prev.name,
                    family: prev.family,
                    email: prev.email,
                    user_name: prev.user_name,
                    phone_number: e.target.value,
                    national_code: prev.national_code,
                    password: prev.password,
                    password_confirmation: prev.password_confirmation,
                    address_details: prev.address_details,
                    biography: prev.biography,
                    role_id: prev.role_id,
                    permission_id: prev.permission_id,
                    is_active: prev.is_active,
                  };
                });
                setError((prev) => {
                  prev.phone_number = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />

            <InputComponent
              width="45%"
              height="44px"
              id={"national_code"}
              value={userInfo.national_code}
              placeholder="کد ملی"
              borderColor={error.national_code === true ? "#F23B3B" : "#dcdcdc"}
              inputType="input"
              type="number"
              onchange={(e) => {
                setUserInfo((prev) => {
                  return {
                    name: prev.name,
                    family: prev.family,
                    user_name: prev.user_name,
                    email: prev.email,
                    phone_number: prev.phone_number,
                    national_code: e.target.value,
                    password: prev.password,
                    password_confirmation: prev.password_confirmation,
                    address_details: prev.address_details,
                    biography: prev.biography,
                    role_id: prev.role_id,
                    permission_id: prev.permission_id,
                    is_active: prev.is_active,
                  };
                });
                setError((prev) => {
                  prev.national_code = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
          </div>

          <div className="manage-user-modal-input-box-1">
            <InputComponent
              width="45%"
              height="44px"
              id={"password"}
              value={userInfo.password}
              placeholder="رمز عبور"
              borderColor={error.password === true ? "#F23B3B" : "#dcdcdc"}
              inputType="inputWithIcon"
              type={password === false ? "password" : "text"}
              iconSrc={password === false ? HidePassword : Eye}
              iconSide="left"
              onClick={() => showPassword()}
              onchange={(e) => {
                setUserInfo((prev) => {
                  return {
                    name: prev.name,
                    family: prev.family,
                    email: prev.email,
                    user_name: prev.user_name,
                    phone_number: prev.phone_number,
                    national_code: prev.national_code,
                    password: e.target.value,
                    password_confirmation: prev.password_confirmation,
                    address_details: prev.address_details,
                    biography: prev.biography,
                    role_id: prev.role_id,
                    permission_id: prev.permission_id,
                    is_active: prev.is_active,
                  };
                });
                setError((prev) => {
                  prev.password = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />

            <InputComponent
              width="45%"
              height="44px"
              id={"password_confirmation"}
              value={userInfo.password_confirmation}
              placeholder="تکرار رمز"
              borderColor={
                error.confirm_password === true ? "#F23B3B" : "#dcdcdc"
              }
              inputType="inputWithIcon"
              type={verifyPassword === false ? "password" : "text"}
              iconSrc={verifyPassword === false ? HidePassword : Eye}
              iconSide="left"
              onClick={() => showVerifyPassword()}
              onchange={(e) => {
                setUserInfo((prev) => {
                  return {
                    name: prev.name,
                    family: prev.family,
                    email: prev.email,
                    user_name: prev.user_name,
                    phone_number: prev.phone_number,
                    national_code: prev.national_code,
                    password: prev.password,
                    password_confirmation: e.target.value,
                    address_details: prev.address_details,
                    biography: prev.biography,
                    role_id: prev.role_id,
                    permission_id: prev.permission_id,
                    is_active: prev.is_active,
                  };
                });
                setError((prev) => {
                  prev.confirm_password = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
          </div>
          <div className="manage-user-modal-input-box-1">
            <InputComponent
              width="100%"
              height="44px"
              id={"address_details"}
              value={userInfo.address_details}
              placeholder="آدرس"
              borderColor={error.address === true ? "#F23B3B" : "#dcdcdc"}
              inputType="input"
              type="text"
              onchange={(e) => {
                setUserInfo((prev) => {
                  return {
                    name: prev.name,
                    family: prev.family,
                    user_name: prev.user_name,
                    email: prev.email,
                    phone_number: prev.phone_number,
                    national_code: prev.national_code,
                    password: prev.password,
                    password_confirmation: prev.password_confirmation,
                    address_details: e.target.value,
                    biography: prev.biography,
                    role_id: prev.role_id,
                    permission_id: prev.permission_id,
                    is_active: prev.is_active,
                  };
                });
                setError((prev) => {
                  prev.address = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
          </div>
          <div className="manage-user-modal-input-box-1">
            <InputComponent
              width="100%"
              height="44px"
              id={"biography"}
              value={userInfo.biography}
              placeholder="بیوگرافی"
              borderColor={error.biography === true ? "#F23B3B" : "#dcdcdc"}
              inputType="input"
              type="text"
              onchange={(e) => {
                setUserInfo((prev) => {
                  return {
                    name: prev.name,
                    family: prev.family,
                    email: prev.email,
                    user_name: prev.user_name,
                    phone_number: prev.phone_number,
                    national_code: prev.national_code,
                    password: prev.password,
                    password_confirmation: prev.password_confirmation,
                    address_details: prev.address_details,
                    biography: e.target.value,
                    role_id: prev.role_id,
                    permission_id: prev.permission_id,
                    is_active: prev.is_active,
                  };
                });
                setError((prev) => {
                  prev.biography = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
          </div>
          <div className="manage-user-modal-box-2">
            <div className="manage-user-modal-title-box">
              <p className="manage-user-modal-title">دسترسی کاربر</p>
              <button
                onClick={() => setRoleModal(true)}
                className="manage-user-modal-add-role"
              >
                <p>ایجاد نقش</p>
              </button>
            </div>
            <div className="manage-user-modal-line"></div>
            <div className="manage-user-modal-role-box">
              {allUserRole.map((item, index) => {
                return (
                  <div key={index} className="manage-user-modal-role-box-2">
                    <label
                      style={{ display: "flex", alignItems: "center" }}
                      htmlFor={`rol${index}`}
                    >
                      <input
                        style={{ display: "none" }}
                        id={`rol${index}`}
                        name="radiorol"
                        onChange={(e) =>
                          roleOnchange(e, item.role_id, item.accessors)
                        }
                        type="radio"
                      />

                      <div className="manage-user-modal-role-radio-box">
                        <div
                          className={
                            activeRadio === item.role_id
                              ? "manage-user-modal-role-radio-active"
                              : "manage-user-modal-role-radio"
                          }
                        ></div>
                      </div>
                      <p className="manage-user-modal-role-radio-title">
                        {item.title_fa}
                      </p>
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="manage-user-modal-Permission-box">
              {allPermission.map((item, index) => {
                return (
                  <label
                    style={{ display: "flex", marginRight: "15px" }}
                    key={index}
                    htmlFor={`permisson${index}`}
                  >
                    <div className="manage-user-modal-Permission-box-2">
                      <input
                        checked={userInfo.permission_id.includes(
                          item.access_id
                        )}
                        style={{ display: "none" }}
                        id={`permisson${index}`}
                        name={`permisson${index}`}
                        onChange={() => permissonChangeClick(item.access_id)}
                        type="checkbox"
                      />
                      <img
                        style={{
                          display: "flex",
                          width: "20px",
                          height: "20px",
                          cursor: "pointer",
                        }}
                        src={
                          userInfo.permission_id.includes(item.access_id)
                            ? check
                            : uncheck
                        }
                      />
                      <p className="manage-user-modal-Permission-title">
                        {item.title_fa}
                      </p>
                    </div>
                  </label>
                );
              })}
            </div>
          </div>
          <div className="manage-user-situation-box">
            <p className="manage-user-situation-title">وضعیت کاربر</p>
          </div>
          <div className="manage-user-modal-line"></div>
          <div className="manage-user-modal-situation-box-2">
            <label htmlFor={"active"}>
              <input
                style={{ display: "none" }}
                checked={userInfo.is_active === 1}
                value={userInfo.is_active}
                onChange={() => activeChangeClick()}
                name="active_deactive"
                id="active"
                type="radio"
              />
              <div className="manage-user-modal-situation-box-3">
                <div className="manage-user-modal-situation-box-4">
                  <div
                    className={
                      userInfo.is_active === 1
                        ? "manage-user-modal-situation-radio-active"
                        : "manage-user-modal-situation-radio"
                    }
                  ></div>
                </div>
                <p className="manage-user-modal-situation-radio-title">فعال</p>
              </div>
            </label>
            <label htmlFor={"deactive"}>
              <input
                style={{ display: "none" }}
                checked={userInfo.is_active === 0}
                value={userInfo.is_active}
                onChange={() => deactiveChangeClick()}
                name="active_deactive"
                id="deactive"
                type="radio"
              />
              <div className="manage-user-modal-situation-box-3">
                <div className="manage-user-modal-situation-box-4">
                  <div
                    className={
                      userInfo.is_active === 0
                        ? "manage-user-modal-situation-radio-active"
                        : "manage-user-modal-situation-radio"
                    }
                  ></div>
                </div>
                <p className="manage-user-modal-situation-radio-title">
                  غیر فعال
                </p>
              </div>
            </label>
          </div>
          <div className="manage-user-modal-buttons-box">
            <button
              onClick={() => cancelCreateUser()}
              className="manage-user-modal-cancel-button"
            >
              <p>لغو</p>
            </button>
            <button
              onClick={() => createUser()}
              className="manage-user-modal-save-button"
            >
              <p>ذخیره</p>
            </button>
          </div>
        </div>
      </Modal>
      <div className="manage-user-top-bar">
        <TopBar />
      </div>
      <div className="manage-user-button-search-box">
        <div className="manage-user-button-box">
          <button
            onClick={() => addUserSystem()}
            className="manage-user-add-user-button"
          >
            <img src={AllProductAdd} />
            <p className="manage-user-add-user-button-title">ایجاد کاربر</p>
          </button>
        </div>

        <div className="manage-user-dropdown-search-box">
          <div className="manage-user-access-dropdown-box">
            <p className="manage-user-access-dropdown-box-title">دسترسی :</p>
            <DropDownComponent
              width="212px"
              height="44px"
              dropDownHeight="150px"
              zindex="1"
              data={permissionDropDown}
              search={permissionSearch}
              show={permissionShow}
              // axiosname="permission"
              // axiosurl={"/UserSystemAccess"}
              // onchange={() => searchDropDownChange()}
              value={permissionVale}
              itemClick={(id, title) => permissionDropDownClick(id, title)}
              placeholder="همه"
            />
          </div>

          <div className="manage-user-access-dropdown-box">
            <p className="manage-user-access-dropdown-box-title">وضعیت :</p>
            <DropDownComponent
              width="212px"
              height="44px"
              dropDownHeight="150px"
              zindex="1"
              data={isActive}
              search={searchIsActive}
              show={showIsActive}
              value={isActiveValue}
              itemClick={(id, title) => isActiveDropDown(id, title)}
              placeholder="همه"
            />
          </div>

          <div className="manage-user-search-box">
            <InputComponent
              width="337px"
              height="44px"
              type="text"
              inputType="inputWithIcon"
              iconSide="right"
              iconSrc={SearchLogo}
              backgroundColor="#f7f7f7"
              placeholder="جستجو محصول.."
              borderColor="#dcdcdc"
              onchange={(e) => searchHandelChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="manage-user-tabel-box">
        <div className="manage-user-tabel-top-bar">
          <p className="manage-user-tabel-top-bar-1">#</p>
          <p className="manage-user-tabel-top-bar-3">نام و نام خانوادگی</p>
          <p className="manage-user-tabel-top-bar-4">نام کاربری</p>
          <p className="manage-user-tabel-top-bar-5">موبایل</p>
          <p className="manage-user-tabel-top-bar-6">ایمیل</p>
          <p className="manage-user-tabel-top-bar-7">نقش</p>
          <p className="manage-user-tabel-top-bar-8">وضعیت</p>
          <p className="manage-user-tabel-top-bar-9">عملیات</p>
        </div>
        {userData.map((item, index) => {
          return (
            <div
              key={index}
              style={
                userData.length == index + 1
                  ? { borderRadius: "0 0 10px 10px" }
                  : {}
              }
              className="manage-user-tabale-item-box"
            >
              <div className="manage-user-table-item-1">{from + index}</div>
              <div className="manage-user-table-item-3">{item.full_name}</div>
              <div className="manage-user-table-item-4">{item.user_name}</div>
              <div className="manage-user-table-item-5">
                {item.phone_number}
              </div>
              <div className="manage-user-table-item-6">{item.email}</div>
              <div className="manage-user-table-item-7">
                {item.role != null && item.role.title_fa}
              </div>
              <div className="manage-user-table-item-8">
                {item.status === 1 && (
                  <div className="manage-user-table-item-8-status-green-box">
                    <p>فعال</p>
                  </div>
                )}
                {item.status === 0 && (
                  <div className="manage-user-table-item-8-status-red-box">
                    <p> غیر فعال </p>
                  </div>
                )}
              </div>
              <div className="manage-user-table-item-9">
                <img
                  onClick={() => editUser(item.id)}
                  src={EditActive}
                  // onMouseOver={(e) => (e.currentTarget.src = EditActive)}
                  // onMouseOut={(e) => (e.currentTarget.src = Edit)}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="manage-user-pagination-box">
        <Pagination firstAxios={firstAxios} currentAxios="/UserSystem?page=" />
      </div>
    </div>
  );
}
