import React, { useEffect } from "react";
import "../../assets/styles/VisitorStatisticsStyle/VisitorStatisticsMostPage.css";
import { objInObjToArray } from "../../helper/Helper";
import axios from "../../js/Axios/Axios";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { useState } from "react";
export default function VisitorStatisticsMostPage() {
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get("/visitInfo/mostVisitedPagesReport")
      .then((res) => {
        console.log(res);
        setData(objInObjToArray(res.data.data));
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const openNewTab = (route) => {
    window.open(route)
  }

  return (
    <div className="visitor-statistics-most-page-all">
      <div className="visitor-statistics-most-page-title-box">
        <p>پربازدیدترین صفحات</p>
      </div>
      <div className="visitor-statistics-most-page-box">
        <div className="visitor-statistics-most-page-top-bar-box">
          <div className="visitor-statistics-most-page-top-bar-hashtag">
            <p>#</p>
          </div>
          <div className="visitor-statistics-most-page-top-bar-name">
            <p>نام صفحه</p>
          </div>
          <div className="visitor-statistics-most-page-top-bar-address">
            <p>آدرس</p>
          </div>
          <div className="visitor-statistics-most-page-top-bar-count">
            <p>تعداد بازدید (نفر)</p>
          </div>
        </div>

        <div className="visitor-statistics-most-page-item-box">
          <SimpleBar
            forceVisible="y"
            style={{ height: "100%", width: "100%" }}
          >
            {data.map((item, index) => {
              return (
                <div key={index} className="visitor-statistics-most-page-item-1">
                  <div className="visitor-statistics-most-page-item-1-hashtag">
                    <p>{index + 1}</p>
                  </div>
                  <div className="visitor-statistics-most-page-item-1-name">
                    <p>{item.route_name}</p>
                  </div>
                  <div className="visitor-statistics-most-page-item-1-address">
                    <p onClick={() => openNewTab(item.route_address)}>{item.route_address}</p>
                  </div>
                  <div className="visitor-statistics-most-page-item-1-count">
                    <p>{item.count_visit_perPage}</p>
                  </div>
                </div>
              );
            })}
          </SimpleBar>
        </div>
      </div>
    </div>
  );
}
