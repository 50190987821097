import React, { useEffect } from "react";
import { useState } from "react";
import useForceUpdate from "use-force-update";
import "../../assets/styles/VisitorStatisticsStyle/VisitorStatisticsIranChart.css";
import axios from "../../js/Axios/Axios";
import Iran from "../IranComponent/IranComponent";
export default function VisitorStatisticsIranChart() {
  const forceUpdate = useForceUpdate();
  const [data, setData] = useState({
    stateName: null,
    stateVisits: null,
    usersCount: null,
    cityName: null,
    cityVisits: null,
  });
  const stateNameClick = (name, stateId) => {
    axios
      .get(`/visitInfo/reportMap?province_id=${stateId}`)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          setData((prev) => {
            prev.stateName = name;
            prev.stateVisits = res.data.data.province_visitCount;
            prev.usersCount = res.data.data.users_count;
            prev.cityName = res.data.data.city;
            prev.cityVisits = res.data.data.city_visitCount;
            forceUpdate();
            return prev;
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="visitor-statisticsc-iran-chart-all">
      <div className="visitor-statisticsc-iran-chart-title-box">
        <p>گزارش بازدید در کشور</p>
      </div>

      <div className="visitor-statisticsc-iran-chart-box-parent">
        <div className="visitor-statisticsc-iran-chart-box">
          <div className="visitor-statisticsc-iran-chart-box-state-name-box">
            <div className="visitor-statisticsc-iran-chart-box-state-name-box-2">
            <div className="visitor-statisticsc-iran-chart-box-state-name-dot"/>
              <p>نام استان</p>
            </div>
            <div className="visitor-statisticsc-iran-chart-box-state-name-box-2">
            <div className="visitor-statisticsc-iran-chart-box-state-name-dot"/>
            <p>تعداد بازدید</p>
            </div>
            <div className="visitor-statisticsc-iran-chart-box-state-name-box-2">
            <div className="visitor-statisticsc-iran-chart-box-state-name-dot"/>
            <p>تعداد کاربران</p>
            </div>
            <div className="visitor-statisticsc-iran-chart-box-state-name-box-2">
            <div className="visitor-statisticsc-iran-chart-box-state-name-dot"/>
            <p>پربازدید ترین شهر</p>
            </div>
            <div className="visitor-statisticsc-iran-chart-box-state-name-box-2">
            <div className="visitor-statisticsc-iran-chart-box-state-name-dot"/>
            <p>تعدا بازدید</p>
            </div>

          </div>
          <div className="visitor-statisticsc-iran-chart-box-state-name-box-3">
          <div className="visitor-statisticsc-iran-chart-box-state-name-box-4">
          <p>{data.stateName}</p>
          </div>
          <div className="visitor-statisticsc-iran-chart-box-state-name-box-4">
          <p>{data.stateVisits}</p>
          </div>
          <div className="visitor-statisticsc-iran-chart-box-state-name-box-4">
          <p>{data.usersCount}</p>
          </div>
          <div className="visitor-statisticsc-iran-chart-box-state-name-box-4">
          <p>{data.cityName}</p>
          </div>
          <div className="visitor-statisticsc-iran-chart-box-state-name-box-4">
          <p>{data.cityVisits}</p>
          </div>
          </div>
          {/* <div className="visitor-statisticsc-iran-chart-box-state-name">
            <div className="chart-titles-and-border">
              <div className="visitor-statisticsc-iran-chart-box-state-name-dot"></div>
              <p>نام استان</p>
            </div>
            <span />
            <div className="visitor-statisticsc-iran-chart-box-state-name-1">
              <p>{data.stateName}</p>
            </div>
          </div> */}

          {/* <div className="visitor-statisticsc-iran-chart-box-state-name">
            <div className="chart-titles-and-border">
              <div className="visitor-statisticsc-iran-chart-box-state-name-dot"></div>
              <p>تعداد بازدید</p>
            </div>
            <span />
            <div className="visitor-statisticsc-iran-chart-box-state-name-1">
              <p>{data.stateVisits}</p>
            </div>
          </div> */}
          {/* <div className="visitor-statisticsc-iran-chart-box-state-name">
            <div className="chart-titles-and-border">
              <div className="visitor-statisticsc-iran-chart-box-state-name-dot"></div>
              <p>تعداد کاربران</p>
            </div>
            <span />
            <div className="visitor-statisticsc-iran-chart-box-state-name-1">
              <p>{data.usersCount}</p>
            </div>
          </div> */}

          {/* <div className="visitor-statisticsc-iran-chart-box-state-name">
            <div className="chart-titles-and-border">
              <div className="visitor-statisticsc-iran-chart-box-state-name-dot"></div>
              <p>پربازدید ترین شهر</p>
            </div>
            <span />
            <div className="visitor-statisticsc-iran-chart-box-state-name-1">
              <p>{data.cityName}</p>
            </div>
          </div> */}

          {/* <div className="visitor-statisticsc-iran-chart-box-state-name">
            <div className="chart-titles-and-border">
              <div className="visitor-statisticsc-iran-chart-box-state-name-dot"></div>
              <p>تعدا بازدید</p>
            </div>
            <span />
            <div className="visitor-statisticsc-iran-chart-box-state-name-1">
              <p>{data.cityVisits}</p>
            </div>
          </div> */}
        </div>
        <div className="visitor-statisticsc-iran-chart-box-2">
          <Iran name={stateNameClick} />
        </div>
      </div>
    </div>
  );
}
