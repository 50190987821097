const iniState = {
  Product_info: {
    title: "",
    latin_title: "",
    url: "",
    country: "",
    brand_name: "",
    barcode :"",
    product_introduction: "",
    productTags:null,
    temp_status: null,
  },
};
const ProductInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case "PRODUCT_INFO":
      return { ...iniState, Product_info: action.payload };

    default:
      return state;
  }
};
export default ProductInfoReducer;
