import React, { useEffect, useState } from "react";
import "../../assets/styles/InstanceChangeStyle/IntanceDiscount/IntanceDiscount.css";
import InputComponent from "../TextInputComponent/TextInputComponent";
import useForceUpdate from "use-force-update";
import { ShowAlert } from "../../action/index";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { Calendar } from "react-datepicker2";
import * as moment from "jalali-moment";
import RightThumbSlider from "../../assets/icons/RightThumbSlider.svg";
import LeftThumbSlider from "../../assets/icons/LeftThumbSlider.svg";
import AllProductCalender from "../../assets/icons/AllProductCalender.svg";
import check from "../../assets/icons/check.svg";
import uncheck from "../../assets/icons/uncheck.svg";
import axios from "../../js/Axios/Axios";
export default function InstanceDiscountComponentt(props) {
  const { productIDs } = props;
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const [showStartCalender, setShowStartCalender] = useState(false);
  const [englishStartDate, setEnglishStartDate] = useState(null);
  const [showExpCalender, setShowExpCalender] = useState(false);
  const [discountInfo, setDiscountInfo] = useState({
    productPrice_ids: [],
    is_percent: 1,
    change_amount: null,
    discount_start_date: null,
    discount_expiration_date: null,
    discount_start_announcement: 0,
  });
  const [error, setError] = useState({
    startDate: false,
    endDate: false,
    amount: false,
  });
  useEffect(() => {
    setDiscountInfo((prev) => {
      prev.productPrice_ids = productIDs;
      return prev;
    });
  }, [productIDs]);
  const iOSBoxShadow =
    "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";
  const IOSSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.mode === "dark" ? "#3880ff" : "#3880ff",
    height: 5,
    cursor: "pointer",
    padding: "15px 0",
    "& .MuiSlider-thumb": {
      height: "15px",
      width: "25px",
      cursor: "pointer",
      borderRadius: "10px",
      backgroundColor: "#fff",
      boxShadow: iOSBoxShadow,
      "&:focus, &:hover, &.Mui-active": {
        boxShadow:
          "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          boxShadow: iOSBoxShadow,
        },
      },
    },
    "& .MuiSlider-valueLabel": {
      fontSize: 14,
      fontWeight: "normal",
      top: 2,
      fontFamily: "IRANSansFaNum",
      backgroundColor: "unset",
      color: theme.palette.text.primary,
      "&:before": {
        display: "none",
      },
      "& *": {
        background: "transparent",
        color: theme.palette.mode === "dark" ? "#fff" : "#000",
      },
    },
    "& .MuiSlider-track": {
      border: "none",
      color: "#00589c",
      cursor: "pointer",
    },
    "& .MuiSlider-rail": {
      opacity: 0.5,
      backgroundColor: "#bfbfbf",
      cursor: "pointer",
    },
    "& .MuiSlider-mark": {
      backgroundColor: "#bfbfbf",
      height: 8,
      width: 1,
      "&.MuiSlider-markActive": {
        opacity: 1,
        backgroundColor: "currentColor",
      },
    },
  }));
  function AirbnbThumbComponent(props) {
    const { children, ...other } = props;
    return (
      <SliderThumb {...other}>
        {children}
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
          }}
        >
          <img
            style={{ width: "10px", height: "10px" }}
            src={RightThumbSlider}
          />
          <img
            style={{ width: "10px", height: "10px" }}
            src={LeftThumbSlider}
          />
        </div>
      </SliderThumb>
    );
  }
  const setIsPecentage = (id) => {
    setDiscountInfo((prev) => {
      prev.is_percent = id;
      forceUpdate();
      return prev;
    });
    if (discountInfo.change_amount != null) {
      setDiscountInfo((prev) => {
        prev.change_amount = null;
        forceUpdate();
        return prev;
      });
    }
  };
  const openStartCalender = () => {
    setShowStartCalender(!showStartCalender);
    forceUpdate();
  };
  const openExpCalender = () => {
    setShowExpCalender(!showExpCalender);
    forceUpdate();
  };
  const addStartDate = (value) => {
    let date = moment(value, "YYYY/MM/DD").locale("fa").format("YYYY/MM/DD");
    setEnglishStartDate(value);
    setDiscountInfo((prev) => {
      prev.discount_start_date = date;
      return prev;
    });
    setShowStartCalender(false);
    setError((prev) => {
      prev.startDate = false;
      forceUpdate();
      return prev;
    });
    forceUpdate();
  };
  const addExpDate = (value) => {
    let date = moment(value, "YYYY/MM/DD").locale("fa").format("YYYY/MM/DD");
    setDiscountInfo((prev) => {
      prev.discount_expiration_date = date;
      return prev;
    });
    setShowExpCalender(false);
    setError((prev) => {
      prev.endDate = false;
      forceUpdate();
      return prev;
    });
    forceUpdate();
  };
  const notificationOnChange = (id) => {
    if (discountInfo.discount_start_announcement !== id) {
      setDiscountInfo((prev) => {
        prev.discount_start_announcement = id;
        return prev;
      });
    } else {
      setDiscountInfo((prev) => {
        prev.discount_start_announcement = 0;
        return prev;
      });
    }
    forceUpdate();
  };

  const addDiscount = () => {
    console.log(discountInfo);
    if (discountInfo.productPrice_ids.length != 0) {
      if (
        discountInfo.discount_expiration_date !== null &&
        discountInfo.discount_start_date !== null &&
        discountInfo.change_amount != null
      ) {
        axios
          .post("/productPrice/changeDiscount", discountInfo)
          .then((res) => {
            if (res.data.status === 0) {
              dispatch(ShowAlert(true, res.data.message));
            } else if (res.data.status) {
              console.log(res);
              dispatch(ShowAlert(true, res.data.message));
              props.update("update");
              setDiscountInfo((prev) => {
                prev.discount_start_announcement = 0;
                prev.change_amount = null;
                prev.discount_expiration_date = null;
                prev.discount_start_date = null;
                prev.is_percent = 1;
                prev.productPrice_ids = [];
                forceUpdate();
                return prev;
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        let text = `برای اعمال تخفیف پر کردن فیلد های ${
          discountInfo.discount_start_date === null && "تاریخ شروع تخفیف و"
        }
        ${discountInfo.discount_expiration_date === null && "تاریخ انقضا تخفیف"}
        ${
          discountInfo.change_amount === null ||
          discountInfo.change_amount === ""
            ? "تخفیف"
            : ""
        }
        الزامی است .
        `;
        dispatch(ShowAlert(true, text));
        if (discountInfo.discount_start_date === null) {
          setError((prev) => {
            prev.startDate = true;
            forceUpdate();
            return prev;
          });
        }
        if (discountInfo.discount_expiration_date === null) {
          setError((prev) => {
            prev.endDate = true;
            forceUpdate();
            return prev;
          });
        }
        if (discountInfo.change_amount === null || discountInfo.change_amount === "") {
          setError((prev) => {
            prev.amount = true;
            forceUpdate();
            return prev;
          });
        }
      }
    } else {
      dispatch(
        ShowAlert(true, "برای ایجاد تخفیف باید حتما محصول خود را انتخاب کنید")
      );
    }
  };
  

  return (
    <div className="instance-discount-component-all">
      {showStartCalender === true && (
        <div
          onClick={() => openStartCalender()}
          className="instance-discount-component-show-calender"
        ></div>
      )}
      {showExpCalender === true && (
        <div
          onClick={() => openExpCalender()}
          className="instance-discount-component-show-calender"
        ></div>
      )}
      <div className="instance-discount-component-tite-box">
        <button
          onClick={() => setIsPecentage(1)}
          className="instance-discount-component-percentage-button"
        >
          <p
            className={
              discountInfo.is_percent === 1
                ? "instance-discount-component-percentage-button-active"
                : "instance-discount-component-percentage-button-disabled"
            }
          >
            تخفیف به درصد
          </p>
        </button>
        <button
          onClick={() => setIsPecentage(0)}
          className="instance-discount-component-number-button"
        >
          <p
            className={
              discountInfo.is_percent === 0
                ? "instance-discount-component-number-button-active"
                : "instance-discount-component-number-button-disabled"
            }
          >
            تخفیف به عدد
          </p>
        </button>
      </div>
      {discountInfo.is_percent === 1 && (
        <div className="instance-discount-component-slider-box">
          <IOSSlider
            // disabled={discountInfo.is_percentage === 1 ? false : true}
            components={{ Thumb: AirbnbThumbComponent }}
            aria-label="ios slider"
            defaultValue={
              discountInfo.change_amount != null
                ? discountInfo.change_amount
                : 0
            }
            valueLabelDisplay="on"
            onChange={(e) => {
              setDiscountInfo((prev) => {
                prev.change_amount = e.target.value;
                return prev;
              });
              setError((prev) => {
                prev.amount = false;
                return prev;
              });
            }}
          />
        </div>
      )}
      {discountInfo.is_percent === 0 && (
        <div className="instance-discount-component-discount-input-box">
          <InputComponent
            value={discountInfo.change_amount}
            width="100%"
            height="44px"
            type="number"
            inputType="input"
            placeholder="تخفیف (تومان)"
            borderColor={error.amount === true ? "#F23B3B" : "#dcdcdc"}
            onchange={(e) => {
              setDiscountInfo((prev) => {
                prev.change_amount = e.target.value;
                forceUpdate();
                return prev;
              });
              setError((prev) => {
                prev.amount = false;
                forceUpdate();
                return prev;
              });
            }}
          />
        </div>
      )}
      <div className="instance-discount-component-date-input-box">
        <div
          onClick={() => openStartCalender()}
          className={
            error.startDate === false
              ? "instance-discount-component-date-input-start-box"
              : "instance-discount-component-date-input-start-box-error"
          }
        >
          <p>
            {discountInfo.discount_start_date === null
              ? "تاریخ شروع تخفیف"
              : discountInfo.discount_start_date}
          </p>
          <img src={AllProductCalender} />
        </div>
        {showStartCalender === true && (
          <div className="instance-discount-component-start-calender-box">
            <Calendar
              min={moment().locale("en").format("YYYY/M/D")}
              onChange={(value) => addStartDate(value._d)}
              isGregorian={false}
            />
          </div>
        )}
        <div
          onClick={() => openExpCalender()}
          className={
            error.endDate === false
              ? "instance-discount-component-date-input-start-box"
              : "instance-discount-component-date-input-start-box-error"
          }
        >
          <p>
            {discountInfo.discount_expiration_date === null
              ? "تاریخ انقضا تخفیف"
              : discountInfo.discount_expiration_date}
          </p>
          <img src={AllProductCalender} />
        </div>
        {showExpCalender === true && (
          <div className="instance-discount-component-start-calender-box">
            <Calendar
              min={englishStartDate}
              onChange={(value) => addExpDate(value._d)}
              isGregorian={false}
            />
          </div>
        )}
      </div>
      {/* <div className="instance-discount-component-ckeckbox-box"> */}
        <label
        className="instance-discount-component-ckeckbox-box"
          // style={{
          //   display: "flex",
          //   alignItems: "center",
          //   flexDirection: "row",
          //   height: "100%",
          //   width: "50%",
          // }}
          htmlFor="notification"
        >
          <input
            style={{ display: "none" }}
            type="checkbox"
            id="notification"
            name="notification"
            onChange={() => notificationOnChange(1)}
          />
          {/* <div className="instance-discount-component-ckeckbox-box-2"> */}
            <img
              src={
                discountInfo.discount_start_announcement === 1 ? check : uncheck
              }
            />
          {/* </div> */}
          <p>اعلان شروع تخفیف</p>
        </label>
      {/* </div> */}
      <div className="instance-discount-component-button-box">
        <button
          onClick={() => addDiscount()}
          className="instance-discount-component-button"
        >
          <p>اعمال تخفیف</p>
        </button>
      </div>
    </div>
  );
}
