import React, { useEffect, useState } from "react";
import {
  AddBlogCategory,
  SendCategoryId,
  sendCategoryNameAndId,
} from "../../action";
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import check from "../../assets/icons/check.svg";
import uncheck from "../../assets/icons/uncheck.svg";
import useForceUpdate from "use-force-update";
import { isObjectEmpty } from "../../helper/Helper";
// import Childs from './Childs';

function Childs(props) {
  let { data, index, childs, type } = props;
  const location = useLocation();
  const ProductInfoForEdit = useSelector((state) => state.ProductInfoForEdit);
  const blogEdit = useSelector((state) => state.BlogEdit);
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const [selectedItems, setSelectedItems] = useState([]);
  const [categoryNameId, setCategoryNameId] = useState([]); // state for save category and id for sendig to redux

  useEffect(() => {
    if (
      isObjectEmpty(ProductInfoForEdit) === false &&
      (location.pathname === "/AllProductUpdate" ||
        location.pathname === "/AllDraftProductUpdate") &&
      type == "product"
    ) {
      ProductInfoForEdit.state.productCategories.map((item) => {
        data.map((dItem) => {
          if (dItem.id == item.id) {
            selectedItems.push(item.id);
            categoryNameId.push({
              title: item.title,
              id: item.id,
            });
            dispatch(sendCategoryNameAndId(categoryNameId));
            console.log(item.id);
            dispatch(SendCategoryId(item.id));
            forceUpdate();
          }
        });
      });
    }
  }, [ProductInfoForEdit]);

  useEffect(() => {
    if (!isObjectEmpty(blogEdit) && type == "blog") {
      if (location.pathname === "/EditBlog") {
        blogEdit.blogCategories.map((item) => {
          data.map((dItem) => {
            if (dItem.id == item.id) {
              selectedItems.push(item.id);
              dispatch(AddBlogCategory(item.id));
              forceUpdate();
            }
          });
        });
      }
    }
  }, [blogEdit]);

  const checkBoxHandel = (e, myindex, id, title) => {
    if (selectedItems.includes(id)) {
      selectedItems.splice(selectedItems.indexOf(id), 1);
      if (type == "product") {
        console.log(selectedItems);

        dispatch(SendCategoryId(id));
      } else if (type == "blog") {
        dispatch(AddBlogCategory(id));
      }
      forceUpdate();
    } else {
      selectedItems.push(id);
      if (type == "product") {
        console.log(selectedItems);

        dispatch(SendCategoryId(id));
      } else if (type == "blog") {
        dispatch(AddBlogCategory(id));
      }
      forceUpdate();
    }

    if (type == "product") {
      if (categoryNameId.length === 0) {
        categoryNameId.push({
          title: title,
          id: id,
        });
        forceUpdate();
        dispatch(sendCategoryNameAndId(categoryNameId));
      } else if (categoryNameId.some((i) => i.id === id)) {
        categoryNameId.splice(
          categoryNameId.findIndex((item) => item.id === id),
          1
        );
        dispatch(sendCategoryNameAndId(categoryNameId));
      } else {
        categoryNameId.push({
          title: title,
          id: id,
        });
        dispatch(sendCategoryNameAndId(categoryNameId));
        forceUpdate();
      }
    }
  };

  return (
    <>
      {data != undefined &&
        data.map((myitem, myindex) => {
          return (
            <div
              key={myindex}
              style={{
                marginTop: "15px",
              }}
            >
              <div key={myindex} className="CategoryCategoryCheckBoxItemBox">
                <label
                  htmlFor={`child_id${myitem.id}`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row-reverse",
                  }}
                >
                  <input
                    style={{ display: "none" }}
                    checked={selectedItems.includes(myitem.id) === true}
                    onChange={(e) =>
                      checkBoxHandel(e, myindex, myitem.id, myitem.title)
                    }
                    value={myitem.id}
                    id={`child_id${myitem.id}`}
                    name="category"
                    type="checkbox"
                  />

                  <p className="CategoryCategoryCheckBoxItemTittle">
                    {myitem.title}
                  </p>
                  <img
                    src={
                      selectedItems.includes(myitem.id) === true
                        ? check
                        : uncheck
                    }
                    className="CategoryChooseCategoryCheckBoxPure"
                  />
                </label>
              </div>

              <div style={{ marginRight: `${(myindex + 1) * 15}px` }}>
                <Childs data={myitem[childs]} type={type} childs={childs} />
              </div>
            </div>
          );
        })}
    </>
  );
}

export default Childs;
