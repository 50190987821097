import React, { useEffect, useState } from "react";
import "../../assets/styles/SmsSystemStyle/SmsSystem.css";
import InputComponent from "../TextInputComponent/TextInputComponent";
import axios from "../../js/Axios/Axios";
import { ShowAlert } from "../../action/index";
import useForceUpdate from "use-force-update";
import { Calendar } from "react-datepicker2";
import { useSelector, useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import * as moment from "jalali-moment";
import AllProductCalender from "../../assets/icons/AllProductCalender.svg";
import SearchLogo from "../../assets/icons/SearchLogo.svg";
import cross from "../../assets/icons/cross.svg";
import Delete from "../../assets/icons/Delete.svg";
import DeleteRed from "../../assets/icons/DeleteRed.svg";
import Edit from "../../assets/icons/Edit.svg";
import EditActive from "../../assets/icons/EditActive.svg";
export default function SmsComponent(props) {
  const { UserID } = props;
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [showCalender, setShowCalender] = useState(false);
  const [data, setData] = useState([]);
  const [witchDraft, setWitchDraft] = useState(null);
  const [smsType, setSmsType] = useState("send");
  const [isEdit, setIsEdit] = useState(null);
  const [smsInfo, setSmsInfo] = useState({
    sms_title: null,
    sms_text: null,
    sms_status: null,
    send_time: "",
    user_ids: [],
  });
  useEffect(() => {
    setSmsInfo((prev) => {
      prev.user_ids = UserID;
      forceUpdate();
      return prev;
    });
    console.log("===>", UserID);
  }, [UserID]);
  console.log(smsInfo);
  const inputOnChange = (id) => {
    if (id === "now") {
      setSmsInfo((prev) => {
        prev.send_time = "";
        forceUpdate();
        return prev;
      });
    } else if (id === "date") {
      setSmsInfo((prev) => {
        prev.send_time = true;
        forceUpdate();
        return prev;
      });
    }
  };
  const showCalenderClick = () => {
    if (smsInfo.send_time === true || smsInfo.send_time != "") {
      setShowCalender(!showCalender);
    }
  };
  const addDate = (value) => {
    let date = moment(value, "YYYY/MM/DD").locale("fa").format("YYYY/MM/DD");
    setSmsInfo((prev) => {
      prev.send_time = date;
      forceUpdate();
      return prev;
    });
    setShowCalender(!showCalender);
    setError((prev) => {
      prev.time = false
      forceUpdate()
      return prev
    })
  };
  const changeSmsType = (id) => {
    setSmsType(id);
    forceUpdate();
  };
  const [error, setError] = useState({
    sms_text: false,
    user_id: false,
    time: false,
  });

  const sendSms = (id) => {
    console.log(id);
    if(id !== "پیش نویس"){

    
    if (
      smsInfo.sms_text != null &&
      smsInfo.sms_text != "" &&
      smsInfo.user_ids.length > 0
    ) {
      if (smsInfo.send_time === "") {
        console.log("kkk");
        if (isEdit === null) {
          smsInfo.sms_status = id;
          axios
            .post("/SmsSystem", smsInfo)
            .then((res) => {
              console.log(res);
              if (res.data.status === 0) {
                dispatch(ShowAlert(true, res.data.message));
              }
              if (res.data.status) {
                console.log(res);
                dispatch(ShowAlert(true, res.data.message));
                props.update("update");
                setSmsInfo((prev) => {
                  prev.sms_status = null;
                  prev.send_time = "";
                  prev.sms_text = "";
                  prev.sms_title = "";

                  forceUpdate();
                  return prev;
                });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } else if (isEdit !== null) {
          axios
            .put(`/SmsSystem/${isEdit}`, smsInfo)
            .then((res) => {
              console.log(res);
              if (res.data.status) {
                dispatch(ShowAlert(true, res.data.message));
                props.update("update");
                setSmsInfo((prev) => {
                  prev.sms_status = null;
                  prev.send_time = "";
                  prev.sms_text = "";
                  prev.sms_title = "";
                  // prev.user_ids = [];
                  forceUpdate();
                  return prev;
                });
                setIsEdit(null);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      } else if (smsInfo.send_time === true) {
        dispatch(ShowAlert(true, "برای ارسال پیامک  فیلد تاریخ الزامی است ."));
        setError((prev) => {
          prev.time = true;
          forceUpdate();
          return prev;
        });
      }else if(smsInfo.send_time.length != 0){
        if (isEdit === null) {
          smsInfo.sms_status = id;
          axios
            .post("/SmsSystem", smsInfo)
            .then((res) => {
              console.log(res);
              if (res.data.status === 0) {
                dispatch(ShowAlert(true, res.data.message));
              }
              if (res.data.status) {
                console.log(res);
                dispatch(ShowAlert(true, res.data.message));
                props.update("update");
                setSmsInfo((prev) => {
                  prev.sms_status = null;
                  prev.send_time = "";
                  prev.sms_text = "";
                  prev.sms_title = "";
                  forceUpdate();
                  return prev;
                });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } else if (isEdit !== null) {
          axios
            .put(`/SmsSystem/${isEdit}`, smsInfo)
            .then((res) => {
              console.log(res);
              if (res.data.status) {
                dispatch(ShowAlert(true, res.data.message));
                props.update("update");
                setSmsInfo((prev) => {
                  prev.sms_status = null;
                  prev.send_time = "";
                  prev.sms_text = "";
                  prev.sms_title = "";
                  // prev.user_ids = [];
                  forceUpdate();
                  return prev;
                });
                setIsEdit(null);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
    } else {
      let text = `
      برای ارسال پیامک پر کردن فیلد های  
      ${
        smsInfo.sms_text === "" || smsInfo.sms_text === null
          ? "متن پیامک و "
          : ""
      }
      ${smsInfo.user_ids.length === 0 ? "انتخاب مشتری " : ""}
      الزامی است .`;
      dispatch(ShowAlert(true, text));
      if (smsInfo.sms_text === "" || smsInfo.sms_text === null) {
        setError((prev) => {
          prev.sms_text = true;
          forceUpdate();
          return prev;
        });
      }
      if (smsInfo.user_ids.length > 0) {
        setError((prev) => {
          prev.user_id = true;
          forceUpdate();
          return prev;
        });
      }
    }
  } else {
    if (
      smsInfo.sms_text != null &&
      smsInfo.sms_text != "" 
    ) {
      // if (smsInfo.send_time === "") {
        if (isEdit === null) {
          smsInfo.sms_status = id;
          axios
            .post("/SmsSystem", smsInfo)
            .then((res) => {
              console.log(res);
              if (res.data.status === 0) {
                dispatch(ShowAlert(true, res.data.message));
              }
              if (res.data.status) {
                console.log(res);
                dispatch(ShowAlert(true, res.data.message));
                props.update("update");
                setSmsInfo((prev) => {
                  prev.sms_status = null;
                  prev.send_time = "";
                  prev.sms_text = "";
                  prev.sms_title = "";

                  forceUpdate();
                  return prev;
                });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } else if (isEdit !== null) {
          axios
            .put(`/SmsSystem/${isEdit}`, smsInfo)
            .then((res) => {
              console.log(res);
              if (res.data.status) {
                dispatch(ShowAlert(true, res.data.message));
                props.update("update");
                setSmsInfo((prev) => {
                  prev.sms_status = null;
                  prev.send_time = "";
                  prev.sms_text = "";
                  prev.sms_title = "";
                  // prev.user_ids = [];
                  forceUpdate();
                  return prev;
                });
                setIsEdit(null);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        // }
      // } else if (smsInfo.send_time === true) {
      //   dispatch(ShowAlert(true, "برای ارسال پیامک  فیلد تاریخ الزامی است ."));
      //   setError((prev) => {
      //     prev.time = true;
      //     forceUpdate();
      //     return prev;
      //   });
      // }
      // else if(smsInfo.send_time.length != 0){
      //   if (isEdit === null) {
      //     smsInfo.sms_status = id;
      //     axios
      //       .post("/SmsSystem", smsInfo)
      //       .then((res) => {
      //         console.log(res);
      //         if (res.data.status === 0) {
      //           dispatch(ShowAlert(true, res.data.message));
      //         }
      //         if (res.data.status) {
      //           console.log(res);
      //           dispatch(ShowAlert(true, res.data.message));
      //           props.update("update");
      //           setSmsInfo((prev) => {
      //             prev.sms_status = null;
      //             prev.send_time = "";
      //             prev.sms_text = "";
      //             prev.sms_title = "";
      //             forceUpdate();
      //             return prev;
      //           });
      //         }
      //       })
      //       .catch((e) => {
      //         console.log(e);
      //       });
        // } else if (isEdit !== null) {
        //   axios
        //     .put(`/SmsSystem/${isEdit}`, smsInfo)
        //     .then((res) => {
        //       console.log(res);
        //       if (res.data.status) {
        //         dispatch(ShowAlert(true, res.data.message));
        //         props.update("update");
        //         setSmsInfo((prev) => {
        //           prev.sms_status = null;
        //           prev.send_time = "";
        //           prev.sms_text = "";
        //           prev.sms_title = "";
        //           // prev.user_ids = [];
        //           forceUpdate();
        //           return prev;
        //         });
        //         setIsEdit(null);
        //       }
        //     })
        //     .catch((e) => {
        //       console.log(e);
        //     });
        // }
      }
    } else {
      let text = `
      برای ارسال پیامک پر کردن فیلد های  
      ${
        smsInfo.sms_text === "" || smsInfo.sms_text === null
          ? "متن پیامک  "
          : ""
      }
      الزامی است .`;
      dispatch(ShowAlert(true, text));
      if (smsInfo.sms_text === "" || smsInfo.sms_text === null) {
        setError((prev) => {
          prev.sms_text = true;
          forceUpdate();
          return prev;
        });
      }
      // if (smsInfo.user_ids.length > 0) {
      //   setError((prev) => {
      //     prev.user_id = true;
      //     forceUpdate();
      //     return prev;
      //   });
      // }
    }
  }
  };
  const openModalClick = () => {
    if (modal === true) {
      setModal(false);
    } else {
      setModal(true);
      axios
        .get("/SmsSystem/search")
        .then((res) => {
          console.log(res);
          setData(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    forceUpdate();
  };
  const SearchDraftSms = (e) => {
    axios
      .get(`/SmsSystem/search?search=${e.target.value}`)
      .then((res) => {
        console.log(res);
        setData(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const adddraft = (item) => {
    setWitchDraft(item);
    forceUpdate();
  };
  const addSms = () => {
    setSmsInfo((prev) => {
      prev.sms_text = witchDraft.sms_text;
      prev.sms_title = witchDraft.sms_title;
      forceUpdate();
      return prev;
    });
    setModal(false);
    setWitchDraft(null);
  };
  const editDraftSms = (id) => {
    axios
      .get(`/SmsSystem/${id}/edit`)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setSmsInfo((prev) => {
            prev.sms_text = res.data.data.sms_text;
            prev.sms_title = res.data.data.sms_title;
            prev.user_ids = res.data.data.users;
            forceUpdate();
            return prev;
          });
          setSmsType("draft");
          setModal(false);
          setWitchDraft(null);
          setIsEdit(id);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const delteDraftSms = (id) => {
    axios
      .delete(`/SmsSystem/${id}`)
      .then((res) => {
        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));
          setModal(false);
          axios
            .get("/SmsSystem/search")
            .then((res) => {
              console.log(res);
              setData(res.data);
            })
            .catch((e) => {
              console.log(e);
            });
        }

        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <div
      className={
        smsType === "send" ? "sms-component-all" : "sms-component-all-2"
      }
    >
      {showCalender === true && (
        <div
          onClick={() => showCalenderClick()}
          className="sms-calender-all"
        ></div>
      )}
      <Modal
        onBackdropClick={() => openModalClick()}
        className="sms-component-modal"
        open={modal}
      >
        <div className="sms-component-modal-box">
          <div className="sms-component-modal-title-box">
            <p>پیامک های پیش نویس شده</p>
            <img onClick={() => openModalClick()} src={cross} />
          </div>
          <div className="sms-component-modal-search-box">
            <InputComponent
              id="searchAll"
              width="100%"
              height="44px"
              type="text"
              inputType="inputWithIcon"
              iconSide="right"
              iconSrc={SearchLogo}
              backgroundColor="#f7f7f7"
              placeholder="جستجو .."
              borderColor="#dcdcdc"
              onchange={(e) => SearchDraftSms(e)}
            />
          </div>
          <div className="sms-component-modal-choose-box">
            {data.map((item, index) => {
              return (
                <div key={index} className="sms-component-modal-item-box">
                  <div className="sms-component-modal-item-box-2">
                    <label
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        height: "auto",
                        cursor: "pointer",
                      }}
                      htmlFor={`radio${index}`}
                    >
                      <div className="sms-component-modal-item-radio-box">
                        <input
                          style={{ display: "none" }}
                          type="radio"
                          id={`radio${index}`}
                          name="modal-radio"
                          onChange={() => adddraft(item)}
                        />
                        <div
                          className={
                            witchDraft != null && witchDraft.id === item.id
                              ? "sms-component-modal-item-radio-2"
                              : "sms-component-modal-item-radio"
                          }
                        ></div>
                      </div>
                      <div className="sms-component-modal-item-title-text-box">
                        <div className="sms-component-modal-item-title-box">
                          <p>
                            {item.sms_title.slice(0, 30) +
                              (item.sms_title.length > 30 ? "..." : "")}{" "}
                          </p>
                        </div>
                        <div className="sms-component-modal-item-text-box">
                          <p>
                            {item.sms_text.slice(0, 110) +
                              (item.sms_text.length > 110 ? "..." : "")}
                          </p>
                        </div>
                      </div>
                    </label>

                    <div className="sms-component-modal-icon-box">
                      <div className="sms-component-modal-icon-box-2">
                        <img
                          onClick={() => editDraftSms(item.id)}
                          src={EditActive}
                          // onMouseOver={(e) =>
                          //   (e.currentTarget.src = EditActive)
                          // }
                          // onMouseOut={(e) => (e.currentTarget.src = Edit)}
                        />

                        <img
                          onClick={() => delteDraftSms(item.id)}
                          src={DeleteRed}
                          // onMouseOver={(e) => (e.currentTarget.src = DeleteRed)}
                          // onMouseOut={(e) => (e.currentTarget.src = Delete)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="sms-component-modal-button-box">
            <button
              onClick={() => addSms()}
              className={
                witchDraft != null
                  ? "sms-component-modal-button"
                  : "sms-component-modal-button-2"
              }
            >
              <p>انتخاب پیامک </p>
            </button>
          </div>
        </div>
      </Modal>
      <div className="sms-component-title-box">
        {/* <div className="sms-component-title-box-2"> */}
          <button
            onClick={() => changeSmsType("send")}
            className={
              smsType === "send"
                ? "sms-component-title-2"
                : "sms-component-title"
            }
          >
            ارسال پیامک
          </button>
          <button
            onClick={() => changeSmsType("draft")}
            className={
              smsType === "draft"
                ? "sms-component-title-2"
                : "sms-component-title"
            }
          >
            ایجاد پیامک
          </button>
        {/* </div> */}
      </div>
      <div className="sms-component-input-box">
        <InputComponent
          value={smsInfo.sms_title}
          width="calc(100% - 20px)"
          height="44px"
          type="text"
          inputType="input"
          placeholder="عنوان پیامک"
          borderColor="#dcdcdc"
          onchange={(e) => {
            setSmsInfo((prev) => {
              prev.sms_title = e.target.value;
              forceUpdate();
              return prev;
            });
          }}
        />

        <InputComponent
          value={smsInfo.sms_text}
          width="calc(100% - 20px)"
          height="100px"
          type="text"
          inputType="textarea"
          placeholder="متن پیامک"
          borderColor={error.sms_text === true ? "#F23B3B" : "#dcdcdc"}
          onchange={(e) => {
            setSmsInfo((prev) => {
              prev.sms_text = e.target.value;
              forceUpdate();
              return prev;
            });
            setError((prev) => {
              prev.sms_text = false;
              forceUpdate();
              return prev;
            });
          }}
        />
      </div>

      {smsType !== "draft" && (
        <div className="sms-component-second-box">
          <label
            style={{ marginBottom: "15px" }}
            htmlFor="now_sms"
            className="sms-component-input-radio-box"
          >
            <div className="sms-component-input-radio-box-2">
              <div
                className={
                  smsInfo.send_time == ""
                    ? "sms-component-input-radio-2"
                    : "sms-component-input-radio"
                }
              ></div>
              <input
                style={{ display: "none" }}
                type="radio"
                id="now_sms"
                name="sms_service"
                onChange={() => inputOnChange("now")}
              />
            </div>
            <p>ارسال پیامک در لحظه</p>
          </label>
          <div className="sms-component-input-radio-date-box">
            <label htmlFor="date_sms" className="sms-component-input-radio-box-3">
              <div className="sms-component-input-radio-box-2">
                <div
                  className={
                    smsInfo.send_time !== ""
                      ? "sms-component-input-radio-2"
                      : "sms-component-input-radio"
                  }
                ></div>
                <input
                  style={{ display: "none" }}
                  type="radio"
                  id="date_sms"
                  name="sms_service"
                  onChange={() => inputOnChange("date")}
                />
              </div>
              <p>ارسال پیامک در تاریخ</p>
            </label>
            <div
              onClick={() => showCalenderClick()}
              className={error.time === false ?  "sms-component-date-input" : "sms-component-date-input-error"}
            >
              <p>
                {smsInfo.send_time === "" || smsInfo.send_time === true
                  ? "زمان ارسال"
                  : smsInfo.send_time}
              </p>
              <img style={{ cursor: "pointer" }} src={AllProductCalender} />
            </div>
          </div>
          {showCalender === true && (
            <div className="sms-calender-box">
              <Calendar
                min={moment().locale("en").format("YYYY/M/D")}
                onChange={(value) => addDate(value._d)}
                isGregorian={false}
              />
            </div>
          )}
          <div className="sms-component-button-box">
            <div className="sms-component-choose-person-button-box">
              <button
                onClick={() => openModalClick()}
                className="sms-component-choose-person-button"
              >
                <p>انتخاب از پیش نویس</p>
              </button>
            </div>
          </div>
        </div>
      )}

      {smsType === "send" && (
        <div className="sms-component-button-box2">
          <button
            onClick={() => sendSms("منتشر شود")}
            className="sms-component-share-button"
          >
            <p>ارسال شود</p>
          </button>
        </div>
      )}
      {smsType === "draft" && (
        <div
          style={{ backgroundColor: "#fff" }}
          className="sms-component-button-box2"
        >
          <button
            onClick={() => sendSms("پیش نویس")}
            className="sms-component-draft-button"
          >
            {isEdit !== null ? <p>ویرایش شود</p> : <p>پیش نویس شود</p>}
          </button>
        </div>
      )}
    </div>
  );
}
