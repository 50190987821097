import React, { useEffect, useState } from "react";
import "../../assets/styles/TutorialStyle/Tutorial.css";
import TopBar from "../../components/TopBar/TopBar";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import axios from "../../js/Axios/Axios";
import useForceUpdate from "use-force-update";
import SearchLogo from "../../assets/icons/SearchLogo.svg";
import openTablee from "../../assets/icons/openTable.svg";
import closeTable from "../../assets/icons/closeTable.svg";
import ReactPlayer from "react-player";

export default function Tutorial() {
  const forceUpdate = useForceUpdate();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(null);
  useEffect(() => {
    axios
      .get("/Tutorial")
      .then((res) => {
        console.log(res);
        setData(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const SearchOnChange = (e) => {
    axios
      .get(`/Tutorial?search=${e.target.value}`)
      .then((res) => {
        console.log(res);
        setData(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const openClick = (index) => {
    if (open === index) {
      setOpen(null);
    } else {
      setOpen(index);
    }
    forceUpdate();
  };

  return (
    <div className="Tutorial-all">
      <div className="turorial-top-bar">
        <TopBar />
      </div>
      <div className="tutorial-all-box">
        <div className="tutorial-search-box">
          <InputComponent
            width="433px"
            height="44px"
            type="text"
            inputType="inputWithIcon"
            iconSide="right"
            iconSrc={SearchLogo}
            backgroundColor="#f7f7f7"
            placeholder="جستجو .."
            borderColor="#dcdcdc"
            onchange={(e) => SearchOnChange(e)}
          />
        </div>
        {data.map((item, index) => {
          return (
            <div
              style={data.length == index + 1 ? { marginBottom: "50px" } : {}}
              key={index}
              className={open === index ? "tutorial-box-3" : "tutorial-box"}
            >
              <div onClick={() => openClick(index)} className="tutorial-box-2">
                <div className="tutorial-title-box">
                  <p className="tutorial-title">{item.title}</p>
                  <p className="tutorial-title-2">
                    ({item.tutorials.length} ویدئو)
                  </p>
                </div>
                <div className="tutorial-icon-box">
                  <img
                    onClick={() => openClick(index)}
                    src={open === index ? closeTable : openTablee}
                  />
                </div>
              </div>
              <div
                className={
                  open === index
                    ? "tutorial-all-videp-box"
                    : "tutorial-all-videp-box-2"
                }
              >
                <div className="tutorial-line"></div>
                <div className="tutorial-video-box">
                  {data.length != 0 &&
                    data[index].tutorials.map((item2, i) => {
                      return (
                        <div key={i} className="tutorial-video-box-2">
                          <div className="tutorial-video-box-3">
                            <ReactPlayer
                              width="100%"
                              height="100%"
                              pip={true}
                              url={item2.media}
                              controls={true}
                            />
                          </div>
                          <div className="tutorial-video-title-box">
                            <div className="tutorial-video-title-box-2">
                              <p>{item2.description}</p>
                            </div>
                            <p>"{item2.time}</p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
