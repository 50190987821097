import React from "react";
import "../../assets/styles/ContactVitsellStyle/ContsctVitsell.css";
import TopBar from "../../components/TopBar/TopBar";
import ContactUsPhoneNumber from "../../assets/icons/ContactUsPhoneNumber.svg";
import ContactUsEmail from "../../assets/icons/ContactUsEmail.svg";
export default function ContactUs() {
  return (
    <div className="contact-vitsell-all">
      <div className="contact-vitsell-top-bar">
        <TopBar />
      </div>

      <div className="contact-vitsell-box-paren">
        <div className="contact-vitsell-box">
          <p className="contact-vitsell-box-title">ارتباط با ویتسل</p>
          <div className="contact-vitsell-line"></div>
          <div className="contact-vitsell-description-box">
            <p>
              اگر راهنمای پنل را خوانده باشید و قوانین و مقررات ویتسل را هم از
              نظرتان گذرانده باشید، بعید است به مشکلی بربخورید؛ اما در هر صـورت
              اگر بنا به هـر دلیلـی به مشـورت با همکاران ما و بخش پشتیبانیِ
              شبانه‌روزی ما احتیاج داشتید، مـی‌توانید در هر لحظه، یا با شمـاره
              تلفن
              88551470-021 تمـاس بگیرید و موضوع را با ما در
              میان بگذارید تا هرچه زودتر به آن رسیدگی کنیم.{" "}
            </p>
          </div>
          <div className="contact-vitsell-email-phone-box">
            <div className="contact-vitsell-email-phone-box-2">
              <div className="contact-vitsell-phone-box">
                <img src={ContactUsPhoneNumber} />
                <p>تلفن پشتیبانی</p>
              </div>
              <div className="contact-vitsell-phone-box">
                <img src={ContactUsEmail} />
                <p>پست الکترونیکی</p>
              </div>
            </div>
            <div className="contact-vitsell-email-phone-box-3">
              <div className="contact-vitsell-phone-title">
                <p>021-88551470</p>
              </div>
              <div className="contact-vitsell-phone-title">
                <p>support@vitsell.ir</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
