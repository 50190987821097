import React, { useEffect, useState } from "react";
import "../../assets/styles/GarbageProductStyle/GarbageProduct.css";
import TopBar from "../../components/TopBar/TopBar";
import axios from "../../js/Axios/Axios";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { ShowAlert } from "../../action/index";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import Pagination from "../../components/Pagination/Pagination";
import Modal from "@mui/material/Modal";
import { useSelector, useDispatch } from "react-redux";
import useForceUpdate from "use-force-update";
import AllProductAdd from "../../assets/icons/AllProductAdd.svg";
import GarbageDelte from "../../assets/icons/GarbageDelte.svg";
import restore from "../../assets/icons/restore.svg";
import restoreActive from "../../assets/icons/restoreActive.svg";
import AllProductTrash from "../../assets/icons/AllProductShare.svg";
import SearchLogo from "../../assets/icons/SearchLogo.svg";
import check from "../../assets/icons/check.svg";
import uncheck from "../../assets/icons/uncheck.svg";
import Delete from "../../assets/icons/Delete.svg";
import DeleteRed from "../../assets/icons/DeleteRed.svg";
import AllProductTrashRed from "../../assets/icons/GarbageDelteRed.svg";

export default function GarbageProduct() {
  const location = useLocation();
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false); // for open modal
  const [garbageData, setGarbageData] = useState([]); // all garbage data
  const [searchGarbage, setSearchGarbage] = useState(); // seasrch state
  const [currentPage, setCurrentPage] = useState(null); // currentpage for paginated
  const [from, setFrom] = useState(null); // from to paginated
  const [checkedIds, setCheckedIds] = useState([]);
  const [topBarChecked, setTopBarChecked] = useState(false);
  const [firstAxios, setFirsAxios] = useState(
    "/productPrice/trash??isPaginated=1"
  );
  const paginatedData = useSelector((state) => state.PaginatedData);
  // get all garbage data
  useEffect(() => {
    axios
      .get("/productPrice/trash??isPaginated=1")
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setGarbageData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  useEffect(() => {
    if (paginatedData.length != 0) {
      if (topBarChecked === true || checkedIds.length != 0) {
        setTopBarChecked(false);
        garbageData.map((i, index) => {
          checkedIds.splice(index);
        });
      }
      setGarbageData(paginatedData.data);
      setCurrentPage(paginatedData.meta.current_page);
      setFrom(paginatedData.meta.from);
    }
  }, [paginatedData]);
  const searchHandelChange = (e) => {
    setSearchGarbage(e.target.value);
    axios
      .get(`/productPrice/trash?search=${e.target.value}`)
      .then((res) => {
        if (res.data.status) {
          setGarbageData(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          setFirsAxios(`/productPrice/trash?search=${e.target.value}`);
          forceUpdate();
        }
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  // add id to array checkbox
  const itemCheckBoxClick = (id, index) => {
    if (checkedIds.includes(id) === false) {
      checkedIds.push(id);
    } else {
      checkedIds.map((i) => {
        if (i === id) {
          var indexof = checkedIds.indexOf(i);
          checkedIds.splice(indexof, 1);
        }
      });
    }
    if (garbageData.length === checkedIds.length) {
      setTopBarChecked(true);
    }
    if (garbageData.length !== checkedIds.length) {
      setTopBarChecked(false);
    }
    forceUpdate();
  };
  // add all id to array all checkbox
  const allCheckBoxClick = () => {
    if (garbageData.length !== checkedIds.length) {
      setTopBarChecked(true);
      garbageData.map((i) => {
        if (!checkedIds.includes(i.productPrice_id)) {
          checkedIds.push(i.productPrice_id);
        }
      });
    } else if (garbageData.length === checkedIds.length) {
      setTopBarChecked(false);
      garbageData.map((i, index) => {
        checkedIds.splice(index);
      });
    }
    forceUpdate();
  };

  // restor products
  const allRestor = () => {
    if (checkedIds != 0) {
      axios
        .put(`/productPrice/restoreGarbage`, {
          productPrice_ids: checkedIds,
        })
        .then((res) => {
          if (res.data.status === 0) {
            dispatch(ShowAlert(true, res.data.message));
          }

          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));
            checkedIds.map((i, index) => {
              checkedIds.splice(index);
            });
            axios
              .get("/productPrice/trash??isPaginated=1")
              .then((res) => {
                if (res.data.status) {
                  console.log(res);
                  setGarbageData(res.data.data);
                  setCurrentPage(res.data.meta.current_page);
                  setFrom(res.data.meta.from);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      dispatch(ShowAlert(true, "لطفا محصولات را انتخاب کنید ."));
    }
  };
  // restore one product
  const restorOneProduct = (id) => {
    if (checkedIds != 0) {
      dispatch(
        ShowAlert(
          true,
          "برای بازگرداندن بیشتر از یک محصول از کلید بازیابی محصولات استفاده کنید ."
        )
      );
    } else {
      checkedIds.push(id);
      forceUpdate();
      axios
        .put(`/productPrice/restoreGarbage`, {
          productPrice_ids: checkedIds,
        })
        .then((res) => {
          if (res.data.status === 0) {
            dispatch(ShowAlert(true, res.data.message));
          }

          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));
            checkedIds.map((i, index) => {
              checkedIds.splice(index);
            });
            axios
              .get("/productPrice/trash??isPaginated=1")
              .then((res) => {
                if (res.data.status) {
                  console.log(res);
                  setGarbageData(res.data.data);
                  setCurrentPage(res.data.meta.current_page);
                  setFrom(res.data.meta.from);
                  forceUpdate();
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  // open modar for delte product
  const openModalForDelte = () => {
    if (checkedIds != 0) {
      setModal(true);
    } else {
      dispatch(ShowAlert(true, "لطفا محصولات را انتخاب کنید ."));
    }
  };

  // cancel delet and close modal
  const cancelDeleteClick = () => {
    setModal(false);
    checkedIds.map((i, index) => {
      checkedIds.splice(index);
    });
  };
  // compelete delete product
  const compeleteDelete = () => {
    const newids = JSON.stringify(checkedIds);
    axios
      .delete(`/productPrice/forceDelete?productPrice_ids=${newids}`)
      .then((res) => {
        console.log(res);
        if (res.data.status === 0) {
          dispatch(ShowAlert(true, res.data.message));
        }

        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));
          checkedIds.map((i, index) => {
            checkedIds.splice(index);
          });
          setModal(false);

          axios
            .get("/productPrice/trash??isPaginated=1")
            .then((res) => {
              if (res.data.status) {
                console.log(res);
                setGarbageData(res.data.data);
                setCurrentPage(res.data.meta.current_page);
                setFrom(res.data.meta.from);
                forceUpdate();
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const delteProduct = (id) => {
    checkedIds.push(id);
    setModal(true);
  };

  return (
    <div className="garbage-All">
      <div className="garbage-top-bar">
        <TopBar />
      </div>
      <div className="garbage-button-search-box">
        <Modal
          onBackdropClick={() => cancelDeleteClick()}
          className="garbage-modal"
          open={modal}
        >
          <div className="garbage-modal-delete-box">
            <div className="garbage-modal-delete-icon-box">
              <p>حذف محصول</p>
              <img src={DeleteRed} />
            </div>
            <div className="garbage-modal-delete-box-title-box">
              <p className="garbage-modal-delete-box-title">
                ایا از پاک کردن محصول به طور کامل مطمئن هستید ؟
              </p>
            </div>
            <div className="garbage-modal-delete-box-button-box">
              <button
                onClick={() => cancelDeleteClick()}
                className="garbage-modal-delete-box-button-cancel"
              >
                <p className="garbage-modal-delete-box-button-cancel-title">
                  انصراف
                </p>
              </button>

              <button
                onClick={() => compeleteDelete()}
                className="garbage-modal-delete-box-button-delete"
              >
                <p className="garbage-modal-delete-box-button-delete-title">
                  حذف
                </p>
              </button>
            </div>
          </div>
        </Modal>
        <div className="garbage-button-box">
          <button
            onClick={() => navigate("/Product")}
            className="garbage-add-button"
          >
            <img src={AllProductAdd} />
            <p className="garbage-add-button-title">ایجاد محصول</p>
          </button>

          {/* <button
            onClick={() => openModalForDelte()}
            className={
              checkedIds != 0
                ? "garbage-delete-button"
                : "garbage-delete-button-deactive"
            }
          >
            <img src={GarbageDelte} />
            <p className="garbage-delete-button-title">حذف</p> 
          </button>*/}
        </div>
        <div className="garbage-search-box">
          <InputComponent
            width="337px"
            height="44px"
            type="text"
            inputType="inputWithIcon"
            iconSide="right"
            iconSrc={SearchLogo}
            backgroundColor="#f7f7f7"
            placeholder="جستجو محصول.."
            borderColor="#dcdcdc"
            onchange={(e) => searchHandelChange(e)}
          />
        </div>
      </div>
      <div className="garbage-tabel-box">
        <div
          style={{
            borderLeft: "1px solid #dcdcdc",
            borderRight: "1px solid #dcdcdc",
            borderRadius: "10px 10px 0 0",
            minWidth: "1400px",
          }}
          className={"all-products-header dragscroll"}
        >
          <div className="all-products-tab-bar">
            <div className="all-products-tab-bar-right">
              <p
                onClick={() => navigate("/AllProduct")}
                style={
                  location.pathname == "/AllProduct"
                    ? {
                        color: "#00457b",
                        fontWeight: "bold",
                      }
                    : {}
                }
              >
                محصولات منتشر شده
              </p>

              <p
                onClick={() => navigate("/AllDraftProduct")}
                style={
                  location.pathname == "/AllDraftProduct"
                    ? {
                        color: "#00457b",
                        fontWeight: "bold",
                      }
                    : {}
                }
              >
                محصولات پیش نویس
              </p>

              <p
                style={
                  location.pathname == "/GarbageProduct"
                    ? {
                        color: "#00457b",
                        fontWeight: "bold",
                      }
                    : {}
                }
              >
                زباله دان
              </p>
            </div>

            <div className="all-products-tab-bar-left">
              <button
                onClick={() => allRestor()}
                style={
                  checkedIds.length > 0 ? { backgroundColor: "#008753" } : {}
                }
                className={"restore-button-deactive"}
                // className={
                //   checkedIds != 0
                //     ? "garbage-restor-button"
                //     : "garbage-restor-button-deactive"
                // }
              >
                {checkedIds.length > 0 ? (
                  <img src={restoreActive} />
                ) : (
                  <img src={restore} />
                )}
                <p
                  style={checkedIds.length > 0 ? { color: "#fff" } : {}}
                  className="garbage-restor-button-title"
                >
                  بازیابی محصولات
                </p>
              </button>

              <button
                onClick={() => openModalForDelte()}
                style={
                  checkedIds.length > 0 ? { backgroundColor: "#f23b3b" } : {}
                }
                // onClick={this.garbageClick.bind(this)}
                className="AllProductTopShowTrashButton"
              >
                {checkedIds.length > 0 ? (
                  <img src={GarbageDelte} />
                ) : (
                  <img src={AllProductTrashRed} />
                )}
                <p
                  style={checkedIds.length > 0 ? { color: "#fff" } : {}}
                  className="AllProductTopShowTrashButtonTittle"
                >
                  حذف
                </p>
              </button>
            </div>
          </div>

          <div className="garbage-table-top-bar">
            <p className="garbage-table-top-bar-1">#</p>
            <div className="garbage-table-top-bar-2">
              <img
                onClick={() => allCheckBoxClick()}
                style={{ display: "flex" }}
                src={
                  checkedIds.length === garbageData.length &&
                  garbageData.length != 0
                    ? check
                    : uncheck
                }
              />
            </div>
            <p className="garbage-table-top-bar-3">تصویر</p>
            <p className="garbage-table-top-bar-4">نام</p>
            <p className="garbage-table-top-bar-5">شناسه</p>
            <p className="garbage-table-top-bar-6">قیمت(تومان)</p>
            <p className="garbage-table-top-bar-7">موجودی</p>
            <p className="garbage-table-top-bar-8">دسته بندی</p>
            <p className="garbage-table-top-bar-9">عملیات</p>
          </div>
        </div>

        {garbageData.map((item, index) => {
          return (
            <div
              key={index}
              style={
                garbageData.length == index + 1
                  ? { borderRadius: "0 0 10px 10px" }
                  : {}
              }
              className="garbage-table-item-box"
            >
              <div className="garbage-table-item-1">{index + from}</div>

              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  style={{ display: "none" }}
                  checked={checkedIds.includes(item.productPrice_id) === true}
                  onChange={() =>
                    itemCheckBoxClick(item.productPrice_id, index)
                  }
                  value={item.productPrice_id}
                  type="checkbox"
                />
                <img
                  style={{
                    display: "flex",
                  }}
                  src={
                    checkedIds.includes(item.productPrice_id) === true
                      ? check
                      : uncheck
                  }
                />
              </label>
              <div className="garbage-table-item-3">
                <span>
                  <img
                    className="garbage-table-item-3-img"
                    src={item.product_image}
                  ></img>
                </span>
              </div>
              <div className="garbage-table-item-4">{item.product}</div>
              <div className="garbage-table-item-5">{item.barcode}</div>
              <div className="garbage-table-item-6">{item.price}</div>
              <div
                className={
                  item.available_type === "ناموجود"
                    ? "garbage-table-item-7"
                    : "garbage-table-item-7-new"
                }
              >
                {item.available_type}
              </div>
              <div className="garbage-table-item-8">
                {item.product_category}
              </div>
              <div className="garbage-table-item-9">
                <img
                  onClick={() => restorOneProduct(item.productPrice_id)}
                  src={restore}
                />
                <img
                  onClick={() => delteProduct(item.productPrice_id)}
                  src={DeleteRed}
                  // onMouseOver={(e) => (e.currentTarget.src = DeleteRed)}
                  // onMouseOut={(e) => (e.currentTarget.src = Delete)}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="garbage-pagination-box">
        <Pagination
          firstAxios={firstAxios}
          currentAxios="/productPrice/trash??isPaginated=1&page="
        />
      </div>
    </div>
  );
}
