import React, { useState, useEffect } from "react";
import "../../assets/styles/PaginationStyle/Pagination.css";
import Ghabli from "../../assets/icons/ghabli.svg";
import baadi from "../../assets/icons/baadi.svg";
import axios from "../../js/Axios/Axios";
import { useSelector, useDispatch } from "react-redux";
import { PaginateData } from "../../action/index";

export default function Pagination(props) {
  const dispatch = useDispatch();
  const { currentAxios, firstAxios } = props;
  const [paginateNumber, setPaginateNumber] = useState([]);
  const [currentPage, setCurrentPage] = useState();

  useEffect(() => {
    console.log(firstAxios);
    axios
      // .get("/product?isPaginated=0")
      .get(`${firstAxios}`)

      .then((res) => {
        console.log(res);
        setPaginateNumber(res.data.meta.links);
        setCurrentPage(res.data.meta.current_page);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [firstAxios]);

  const currentClick = (url) => {
    if (url !== null) {
      let index = url.indexOf("page=");
      index += 5;

      let page = url.substr(index, url.length);
      console.log(`${currentAxios}${page}`);
      axios
        .get(`${currentAxios}${page}`)
        .then((res) => {
          console.log(res);

          setPaginateNumber(res.data.meta.links);
          setCurrentPage(res.data.meta.current_page);
          dispatch(PaginateData(res.data));
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {paginateNumber != undefined &&
        paginateNumber.map((myitem, myindex) => {
          return (
            <button
              key={myindex}
              onClick={() => currentClick(myitem.url)}
              disabled={currentPage === myitem.number ? true : false}
              className={
                myindex === 0 || myindex === paginateNumber.length - 1
                  ? "CategoryPreviewsPaginationButton"
                  : "CategoryPagination"
              }
              onMouseMove={(e) =>
                !myitem.active
                  ? (e.currentTarget.style.backgroundColor =
                      "rgba(216 ,	229 ,	240 , .5)")
                  : "#fff"
              }
              onMouseLeave={(e) =>
                !myitem.active
                  ? (e.currentTarget.style.backgroundColor = "#fff")
                  : ""
              }
              style={{
                border: myitem.active
                  ? "1px solid #00589c"
                  : ".5px solid #b5cfe2",
                color: myitem.active ? "#00589c" : "#878787",
                fontWeight: myitem.active ? "bold" : "normal",
                backgroundColor: myitem.active ? "#fff" : "",
              }}
            >
              <p>{myindex === 0 ? "قبلی" : ""}</p>

              {myindex === 0 ? (
                <img className="CategoryPreviewsIcon" src={baadi} />
              ) : null}

              <p>
                {myindex != 0 && myindex != paginateNumber.length - 1
                  ? myitem.label
                  : ""}
              </p>
              {myindex === paginateNumber.length - 1 ? (
                <img className="CategoryNextIcon" src={Ghabli} />
              ) : null}
              <p>{myindex === paginateNumber.length - 1 ? "بعدی" : ""}</p>
            </button>
          );
        })}
    </div>
  );
}
