import React, { useState, useEffect } from "react";
import { convertObjectToArrOnlyValue } from "../../helper/Helper";
import "../../assets/styles/ProductGalleryStyle/UploadImage.css";
import { useSelector, useDispatch } from "react-redux";
import { SendProductGalleryInfo } from "../../action/index";
import { SendProductGalleryInfoIsMain } from "../../action/index";
import { DeleteProductGalleryInfoIsMain } from "../../action/index";
import { ShowAlert } from "../../action/index";
import useForceUpdate from "use-force-update";
import DownloadIcon from "../../assets/icons/Download.svg";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import cross from "../../assets/icons/cross.svg";
import UploadImg from "../../assets/icons/UploadImg.svg";
import axios from "../../js/Axios/Axios";
import Modal from "@mui/material/Modal";
import { useLocation } from "react-router-dom";
import { isObjectEmpty } from "../../helper/Helper";
import ScrollDrag from "react-indiana-drag-scroll";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

export default function UploadImage() {
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const location = useLocation();
  const ProductInfoForEdit = useSelector((state) => state.ProductInfoForEdit);
  const [allImg, setAllImg] = useState([]);
  const [productGalleryInfo, setProductGalleryInfo] = useState({
    editProduct_id: null,
    productGalleries: [
      // {
      //   product_id : null ,
      //   media_id : null,
      //   is_main : null
      // }
    ],
  });
  const [modal, setModal] = useState(false);
  const [imgModal, setImgModal] = useState(false);
  const [media, setMedia] = useState(null);
  const [ImageURls, setImageURLs] = useState([]);
  const [imgSelected, setImgSelected] = React.useState();
  const [imgDeleted, setImageDeleted] = useState([]); // for progress bar when delete img

  const header = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  // for edit send galleries data to redux
  useEffect(() => {
    if (
      isObjectEmpty(ProductInfoForEdit) === false &&
      (location.pathname === "/AllProductUpdate" ||
        location.pathname === "/AllDraftProductUpdate")
    ) {
      console.log(ProductInfoForEdit);
      if (ProductInfoForEdit.state.productGalleries.length != 0) {
        ProductInfoForEdit.state.productGalleries.map((item) => {
          ImageURls.push({
            product_id: null,
            media_id: item.media.id,
            is_main: item.is_main === 1 ? true : false,
            imgLink: item.media.media_link,
          });
          productGalleryInfo.productGalleries.push({
            product_id: null,
            media_id: item.media.id,
            imgLink: item.media.media_link,

            is_main: item.is_main === 1 ? true : false,
          });
          imgDeleted.push({ imgDeleted: 0 });
        });
        console.log(ImageURls, productGalleryInfo);
        dispatch(SendProductGalleryInfo(productGalleryInfo.productGalleries));
        forceUpdate();
      }
    }
  }, [ProductInfoForEdit]);
  // upload img and dispatch
  // const onImageChange = (e) => {
  //   setModal(true);
  //   setMedia(e.target.files[0]);
  //   let formData = new FormData();
  //   formData.append("media", e.target.files[0]);
  //   axios
  //     .post("/media", formData, header)
  //     .then((res) => {
  //       console.log(res);
  //       if (res.data.status === 0) {
  //         setModal(false);
  //         dispatch(ShowAlert(true, res.data.message));
  //       }
  //       if (res.data.status) {
  //         const imageLink = res.data.data;
  //         ImageURls.push(imageLink);
  //         dispatch(ShowAlert(true, res.data.message));
  //         if (productGalleryInfo.productGalleries.length === 0) {
  //           productGalleryInfo.productGalleries.push({
  //             product_id: null,
  //             media_id: res.data.data.id,
  //             is_main: true,
  //             imgLink: res.data.data.media_link,
  //           });
  //         } else {
  //           productGalleryInfo.productGalleries.push({
  //             product_id: null,
  //             media_id: res.data.data.id,
  //             is_main: false,
  //             imgLink: res.data.data.media_link,
  //           });
  //         }
  //         imgDeleted.push({ imgDeleted: 0 });
  //         dispatch(SendProductGalleryInfo(productGalleryInfo.productGalleries));

  //         setModal(false);
  //         forceUpdate();
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       setModal(false);
  //     });
  // };
  // slected image for delte or set is main
  const eventImgSelected = (e, index) => {
    productGalleryInfo.productGalleries.forEach((item, i) => {
      if (index === i) {
        productGalleryInfo.productGalleries[i].is_main = true;
        forceUpdate();

        dispatch(
          SendProductGalleryInfoIsMain(
            index,
            productGalleryInfo.productGalleries[i].is_main
          )
        );
      } else if (index !== i) {
        productGalleryInfo.productGalleries[i].is_main = false;
        forceUpdate();

        dispatch(
          SendProductGalleryInfoIsMain(
            i,
            productGalleryInfo.productGalleries[i].is_main
          )
        );
      }
    });
  };
  // deleted image
  const DeleteImage = (index, id) => {
    if (productGalleryInfo.productGalleries[index].is_main === true) {
      productGalleryInfo.productGalleries[0].is_main = true;
      dispatch(
        SendProductGalleryInfoIsMain(
          0,
          productGalleryInfo.productGalleries[0].is_main
        )
      );
      ImageURls.splice(index, 1);
      productGalleryInfo.productGalleries.splice(index, 1);
      dispatch(DeleteProductGalleryInfoIsMain(index));
      setImgSelected();
      forceUpdate();
    }
  };
  const openModal = () => {
    if (imgModal === false) {
      ImageURls.map((item, index) => {
        ImageURls.splice(index);
      });
      imgDeleted.map((item, index) => {
        imgDeleted.splice(index);
      });
      axios
        .get("/media?isPaginated=0")
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            setImgModal(!imgModal);
            setAllImg(res.data.data);
          }
          forceUpdate();
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setImgModal(!imgModal);
      if (productGalleryInfo.productGalleries.length != 0) {
        productGalleryInfo.productGalleries.map((item, index) => {
          productGalleryInfo.productGalleries.splice(index);
        });
      }
    }
  };

  // upload img
  const uploadImage = (e) => {
    setModal(true);
    let media = convertObjectToArrOnlyValue(e.target.files);
    let formData = new FormData();

    media.forEach((file, index) => {
      formData.append(`media[${index}]`, file);
    });

    axios
      .post("/media/multipleUpload", formData, header)
      .then((res) => {
        if (res.data.status === 0) {
          setModal(false);
          dispatch(ShowAlert(true, res.data.message));
        }
        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));
          setModal(false);
          axios
            .get("/media?isPaginated=0")
            .then((res) => {
              if (res.data.status) {
                console.log(res);
                setAllImg(res.data.data);
              }
              forceUpdate();
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
        setModal(false);
      });
  };
  const addImg = (link, id, item) => {
    if (productGalleryInfo.productGalleries.length < 20) {
      if (productGalleryInfo.productGalleries.every((i) => i.media_id !== id)) {
        productGalleryInfo.productGalleries.push({
          product_id: null,
          media_id: id,
          is_main: false,
          imgLink: link,
        });
      } else {
        productGalleryInfo.productGalleries.map((item, index) => {
          if (item.media_id === id) {
            productGalleryInfo.productGalleries.splice(index, 1);
          }
        });
      }
    } else {
      productGalleryInfo.productGalleries.map((item, index) => {
        if (item.media_id === id) {
          productGalleryInfo.productGalleries.splice(index, 1);
        }
      });
    }
    forceUpdate();
  };
  const finalAdd = () => {
    ImageURls.map((item, index) => {
      ImageURls.splice(index);
    });
    productGalleryInfo.productGalleries.map((item, index) => {
      ImageURls.push(item);
      if (
        productGalleryInfo.productGalleries.every(
          ({ is_main }) => is_main !== true
        )
      ) {
        if (index === 0) {
          item.is_main = true;
        }
      }
    });
    dispatch(SendProductGalleryInfo(productGalleryInfo.productGalleries));
    setImgModal(false);
    forceUpdate();
  };

  return (
    <div className="GalleryBox2">
      <div className="GalleryTittleBox">
        <p className="GalleryTittle"> گالری محصول</p>
      </div>

      <div className="UploadAll">
        {/* <Modal className="UploadModal" open={modal}>
          <div className="UploadModalBox">
            <CircularProgress />
            <p className="UploadModalTittle">...در حال اپلود </p>
          </div>
        </Modal> */}
        <Modal
          onBackdropClick={() => openModal()}
          className="img-modal"
          open={imgModal}
        >
          <div className="img-modal-box">
            <div className="img-modal-top-bar-box">
              <p>انتخاب تصویر</p>
              <img src={cross} onClick={() => openModal()} />
            </div>
            <div className="img-modal-img-box">
              <div className="img-modal-img-box-top-bar">
                <p>گالری</p>
                <p>حداکثر 20 تصویر</p>
              </div>
              <SimpleBar
                forceVisible="x"
                style={{
                  width: "100%",
                  height: "450px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <div className="img-modal-img-box-2">
                  <label
                    htmlFor="upload_img"
                    className="img-modal-img-box-upload-box"
                  >
                    {modal === true ? (
                      <CircularProgress />
                    ) : (
                      <>
                        <div className="img-modal-img-box-upload-box-button">
                          آپلود عکس
                        </div>
                        <img src={UploadImg} />
                        <input
                          style={{ display: "none" }}
                          type="file"
                          id="upload_img"
                          multiple
                          onChange={(e) => uploadImage(e)}
                        />
                      </>
                    )}
                  </label>
                  {allImg.map((item, index) => {
                    return (
                      <div
                        onClick={() => addImg(item.media_link, item.id, item)}
                        key={index}
                        className={
                          productGalleryInfo.productGalleries.some(
                            (i) => i.media_id === item.id
                          )
                            ? "img-modal-img-box-img-show-active"
                            : "img-modal-img-box-img-show"
                        }
                      >
                        <img src={item.media_link} />
                      </div>
                    );
                  })}
                </div>
              </SimpleBar>
            </div>
            <div className="img-modal-img-box-3">
              <div>
                <button
                  onClick={() => openModal()}
                  className="img-modal-img-box-3-cancel-Button"
                >
                  لغو
                </button>
              </div>
              <div>
                <p>{productGalleryInfo.productGalleries.length}</p>
                <p>از 20 تصویر انتخاب شده</p>
                <button
                  onClick={() => finalAdd()}
                  className="img-modal-img-box-3-send-Button"
                >
                  افزودن
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <div className="UploadButtonBox">
          <button onClick={() => openModal()} className="UploadButton">
            انتخاب عکس
          </button>
        </div>
        <SimpleBar
          forceVisible="y"
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "100%",
          }}
        >
          <ScrollDrag>
            <div className="UploadImageBox">
              {ImageURls.map((imagesrc, index) => (
                <div key={index} className="UploadImageBox2">
                  <div
                    className={
                      productGalleryInfo.productGalleries[index].is_main ===
                      true
                        ? "UploadImageBorderBoxx"
                        : "UploadImageBorderBox"
                    }
                  >
                    <label
                      style={{ width: "160px", height: "185px", zIndex: 1 }}
                      htmlFor={`img${index}`}
                    >
                      <input
                        className="UploadImageInputRadio"
                        id={`img${index}`}
                        name="productImage"
                        onChange={(e) => eventImgSelected(e, index)}
                        type="checkbox"
                      />

                      <img className="Imagebox" src={imagesrc.imgLink} alt="" />
                    </label>
                  </div>
                  <div className="UploadImageButtonAndMainBox">
                    {productGalleryInfo.productGalleries[index].is_main ===
                    true ? (
                      <p className="UploadImageButtonAndMainTitle">عکس اصلی</p>
                    ) : (
                      <p></p>
                    )}

                    <button
                      className="DeleteUploadButton"
                      onClick={() => DeleteImage(index, imagesrc.id)}
                    >
                      <DeleteIcon
                        className={
                          productGalleryInfo.productGalleries[index].is_main ===
                          true
                            ? "DeleteUploadIconn"
                            : "DeleteUploadIcon"
                        }
                      />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </ScrollDrag>
        </SimpleBar>
      </div>
    </div>
  );
}
