import React, { useEffect } from "react";
import "../../assets/styles/DeliveryStyle/CityDelivery.css";
import axios from "../../js/Axios/Axios";
import { ShowAlert } from "../../action/index";
import check from "../../assets/icons/check.svg";
import uncheck from "../../assets/icons/uncheck.svg";
import DropDown from "../DropDown/DropDown";
import InputComponent from "../TextInputComponent/TextInputComponent";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { useState } from "react";
import useForceUpdate from "use-force-update";
import { useSelector, useDispatch } from "react-redux";
export default function StateDelivery() {
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const [deliveryType, setDeliveryType] = useState([]);
  const [storeDelivery, setStoreDelivery] = useState([]);
  const [cityArray, setCityArray] = useState([]);
  const [citySearch, setCitySearch] = useState("");
  const [activeButton, setActiveButton] = useState(null);
  const [show, setShow] = useState(false);
  useEffect(() => {
    axios
      .get("/StoreDeliveryType/search?isInProvince=0")
      .then((res) => {
        // console.log(res);
        let data = res.data.data;
        data.map((item) => {
          item.inputs.map((input) => {
            input["checked"] = false;
          });
        });
        forceUpdate();
        if(data.length === 0){
          setShow(true)
          setActiveButton("send")
        }
        data.map((item) => {
          storeDelivery.push(item);
        });
        forceUpdate();
        // console.log(storeDelivery);
      })
      .catch((e) => {
        console.log(e);
      });
    axios
      .get("/DeliveryType")
      .then((res) => {
        // console.log(res);
        res.data.data.map((item) => {
          deliveryType.push({
            title: item.title,
            is_inProvince: item.is_inProvince,
            checked: false,
            status: 1,
            price: null,
            deliveryType_id: item.id,
            province_id: null,
            storeDeliveryType_id: null,
          });
          forceUpdate();
        });
        axios
          .get(`/Province`)
          .then((res) => {
            // console.log(res);
            res.data.data.map((item, index) => {
              cityArray.push({
                title: item.province,
                id: item.id,
                checked: false,
                deleted: false,
              });
            });
            cityArray.map((item) => {
              storeDelivery.map((item2) => {
                if (item.id === item2.id) {
                  item.checked = true;
                  item.deleted = true;
                }
                forceUpdate();
              });
            });
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const cityOnChange = (e) => {
    setCitySearch(e.target.value);
  };
  const deliveryTypeClick = (index, title, id) => {
    setDeliveryType((prev) => {
      prev[index].checked = !prev[index].checked;
      forceUpdate();
      return prev;
    });
    forceUpdate()
  };
  // data come from drop down and set province id in delivery type and create array for show and send too  backend
  const Data = (data) => {
    // console.log(data);
    if (data.checked === true) {
      deliveryType.map((item, index) => {
        item.province_id = data.id;
      });
      forceUpdate();
      let deliverywitoutMostor = [];

      deliveryType.map((item, index) => {
        if (item.title != "پیک موتور") {
          deliverywitoutMostor.push(item);
        }
      });
      storeDelivery.push({
        title: data.title,
        id: data.id,
        inputs: deliverywitoutMostor,
      });
      forceUpdate();
    } else {
      storeDelivery.map((item, index) => {
        if (item.title === data.title && data.checked === false) {
          if (data.deleted === false) {
            storeDelivery.splice(index, 1);
          } else {
            axios
              .delete(`/StoreDeliveryType/throwingGarbageFromProvince`, {
                data: {
                  province_id: data.id,
                },
              })
              .then((res) => {
                // console.log(res);
                if (res.data.status) {
                  dispatch(ShowAlert(true, res.data.message));
                  storeDelivery.map((item, index) => {
                    storeDelivery.splice(index);
                  });
                  cityArray.map((item, index) => {
                    cityArray.splice(index);
                  });
                  forceUpdate();
                  setShow(false);
                  setTimeout(() => {
                    setShow(true);
                  }, 100);
                  forceUpdate();
                  /////////////////////
                  axios
                    .get("/StoreDeliveryType/search?isInProvince=0")
                    .then((res) => {
                      let data = res.data.data;
                      data.map((item) => {
                        item.inputs.map((input) => {
                          input["checked"] = false;
                        });
                      });
                      forceUpdate();
                      data.map((item) => {
                        storeDelivery.push(item);
                      });
                      forceUpdate();
                      // console.log(storeDelivery);
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                  ////////////
                  //////////////
                  axios
                    .get(`/Province`)
                    .then((res) => {
                      res.data.data.map((item, index) => {
                        cityArray.push({
                          title: item.province,
                          id: item.id,
                          checked: false,
                          deleted: false,
                        });
                        forceUpdate();
                      });
                      cityArray.map((item) => {
                        storeDelivery.map((item2) => {
                          if (item.id === item2.id) {
                            item.checked = true;
                            item.deleted = true;
                          }
                          forceUpdate();
                        });
                      });
                      // console.log(cityArray);
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                  /////////
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        }
        forceUpdate();
      });
    }
  };
  // set price for input
  const priceCHange = (e, inputs, index, i) => {
    const newArray = [...storeDelivery];
    newArray[index].inputs[i] = {
      ...newArray[index].inputs[i],
      price: e.target.value,
    };
    setStoreDelivery(newArray);
    forceUpdate();
  };
  // create array of new object and send this array to backend
  const shareLocationPrice = (id) => {
    setActiveButton(null);
    setShow(false)
    // console.log(storeDelivery);
    if (activeButton === "send") {
      let array = [];
      storeDelivery.map((item) => {
        item.inputs.map((item2) => {
          if (item2.price !== null) {
            let obj = {};
            // obj["title"] = item.title;
            obj["edit_id"] = item2.storeDeliveryType_id;
            obj["province_id"] = item.id;
            obj["deliveryType_id"] = item2.deliveryType_id;
            obj["status"] = item2.status;
            obj["price"] = item2.price;
            array.push(obj);
          }
        });
      });
      axios
        .post("/StoreDeliveryType", array)
        .then((res) => {
          // console.log(res);
          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));
            storeDelivery.map((item, index) => {
              storeDelivery.splice(index);
            });
            cityArray.map((item, index) => {
              cityArray.splice(index);
            });
            /////////////////////
            axios
              .get("/StoreDeliveryType/search?isInProvince=0")
              .then((res) => {
                let data = res.data.data;
                data.map((item) => {
                  item.inputs.map((input) => {
                    input["checked"] = false;
                  });
                });
                forceUpdate();
                data.map((item) => {
                  storeDelivery.push(item);
                });
                forceUpdate();
                // console.log(storeDelivery);
              })
              .catch((e) => {
                console.log(e);
              });
            ////////////
            //////////////
            axios
              .get(`/Province`)
              .then((res) => {
                res.data.data.map((item, index) => {
                  cityArray.push({
                    title: item.province,
                    id: item.id,
                    checked: false,
                    deleted: false,
                  });
                  forceUpdate();
                });
                cityArray.map((item) => {
                  storeDelivery.map((item2) => {
                    if (item.id === item2.id) {
                      item.checked = true;
                      item.deleted = true;
                    }
                    forceUpdate();
                  });
                });
                // console.log(cityArray);
              })
              .catch((e) => {
                console.log(e);
              });
            /////////
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  // fuction for compairing two array of object
  function getDifference(array1, array2) {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return object1.deliveryType_id === object2.deliveryType_id;
      });
    });
  }
  // edit add all input to array and active inputs
  const editLocationPrice = (id) => {
    setActiveButton(id);
    setShow(true)
    if (activeButton === null) {
      storeDelivery.map((item) => {
        const difference = [
          ...getDifference(item.inputs, deliveryType),
          ...getDifference(deliveryType, item.inputs),
        ];
        if (item.inputs.length !== deliveryType.length) {
          difference.map((dif) => {
            item.inputs.push({
              checked: false,
              deliveryType_id: dif.deliveryType_id,
              is_inProvince: 0,
              price: null,
              province_id: item.id,
              status: "1",
              storeDeliveryType_id: null,
              title: dif.title,
            });
          });
        }
      });

      storeDelivery.map((item) => {
        item.inputs.map((input) => {
          input.checked = true;
        });
      });
      deliveryType.map((item) => {
        item.checked = true;
      });
      forceUpdate();
    }
  };

  return (
    <div className="state-deliver-all">
      <p>ارسال به استان های دیگر</p>
      <div className="state-deliver-send-model-box">
        <p>مدل ارسال را انتخاب کنید</p>
        {deliveryType.map((item, index) => {
          return (
            <label
              key={index}
              htmlFor={`${index}peyk_motorii`}
              className="state-deliver-send-model-box-2"
            >
              <input
                style={{ display: "none" }}
                type="checkbox"
                id={`${index}peyk_motorii`}
                name={`${index}peyk_motorii`}
                onChange={() =>
                  deliveryTypeClick(index, item.title, item.deliveryType_id)
                }
              />
              <img
                src={item.checked === true ? check : uncheck}
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
              />
              <p>{item.title}</p>
            </label>
          );
        })}
      </div>
      <div className="state-deliver-send-model-drop-down-box">
        <DropDown
          width="100%"
          height="44px"
          dropDownHeight="300px"
          zindex="1"
          onchange={(e) => cityOnChange(e)}
          data={cityArray}
          search={citySearch}
          Data={Data}
          show={show}
          axiosname="Stateprovince"
          placeholder="شهر"
        />
      </div>
      <div className="state-deliver-send-model-own-city-box-2-state">
        <SimpleBar
          forceVisible="y"
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          {storeDelivery.map((item, index) => {
            return (
              <div
                key={index}
                className="state-deliver-send-model-own-city-box"
              >
                <p>{item.title}</p>
                <div className="state-deliver-send-model-input-box">
                  {storeDelivery.length != 0 &&
                    item.inputs.map((item_inputs, i) => {
                      return (
                        <div
                          key={i}
                          className="state-deliver-send-model-input-box-city-box"
                        >
                          <div
                            className={
                              item_inputs.checked === true
                                ? "state-deliver-send-model-input-box-2"
                                : "state-deliver-send-model-input-box-2-active"
                            }
                          >
                            <p>{item_inputs.title}</p>
                            {item_inputs.checked === true ? (
                              <InputComponent
                                disabled={
                                  item_inputs.checked === true ? false : true
                                }
                                width="auto"
                                height="40px"
                                value={storeDelivery[index].inputs[i].price}
                                placeholder="(تومان)"
                                borderColor="#fff"
                                inputType="input"
                                type="number"
                                onchange={(e) =>
                                  priceCHange(e, item.inputs, index, i)
                                }
                              />
                            ) : (
                              <p>{storeDelivery[index].inputs[i].price}</p>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
        </SimpleBar>
      </div>
      <div className="state-deliver-send-button-box">
        <button
          className={activeButton === null ? "state-deliver-edit-button" : "state-deliver-edit-button-disabled"}
          onClick={() => editLocationPrice("send")}
        >
          ویرایش
        </button>
        <button
          onClick={() => shareLocationPrice()}
          className={activeButton === "send" ? "state-deliver-send-button" : "state-deliver-send-button-disabled"}
        >
          ثبت
        </button>
      </div>
    </div>
  );
}
