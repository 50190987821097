const initLoginStaate = {
  productTittle: false,
  price: false,
  weight: false,
  height: false,
  length: false,
  width: false,
  blogText: false,
  min_order: false,
  max_order: false,
};
const ErrorReducer = (state = initLoginStaate, action) => {
  switch (action.type) {
    case "PRODUCT_ERROR":
      console.log(action.error);
      if (action.error.title !== undefined) {
        return { ...state, productTittle: action.error.title };
      }
      if (
        action.error.height !== undefined &&
        action.error.price !== undefined &&
        action.error.weight !== undefined &&
        action.error.length !== undefined &&
        action.error.width !== undefined &&
        action.error.min_order !== undefined &&
        action.error.max_order !== undefined
      ) {
        return {
          ...state,
          height: action.error.height,
          price: action.error.price,
          weight: action.error.weight,
          length: action.error.length,
          width: action.error.width,
          min_order : action.error.min_order,
          max_order : action.error.max_order
        };
      }
    case "BLOG_ERROR":
      if (action.error.title !== undefined) {
        return { ...state, productTittle: action.error.title };
      }
      if (action.error.BlogText !== undefined) {
        return { ...state, blogText: action.error.BlogText };
      }
    default:
      return state;
  }
};
export default ErrorReducer;
