const iniState = {
    id: null,
  };
  const SendIdForEditReducer = (state = iniState, action) => {
    switch (action.type) {
      case "SEND_ID_FOR_EDIT":
        return { ...state, id: action.id };
  
      default:
        return state;
    }
  };
  export default SendIdForEditReducer;
  