import React, { useEffect, useState } from "react";
import "../../assets/styles/EditStore/EditStoreTopProduct.css";
import { ShowAlert } from "../../action/index";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import useForceUpdate from "use-force-update";
import Modal from "@mui/material/Modal";
import UploadImg from "../../assets/icons/UploadImg.svg";
import axios from "../../js/Axios/Axios";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import bannerBrand from "../../assets/images/banner-brand.png";
import cross from "../../assets/icons/cross.svg";
import whiteEdit from "../../assets/icons/whiteEdit.svg";
import PictureIcon from "../../assets/images/PictureIcon.svg";
import Uploadinmedia from "../../assets/icons/Uploadinmedia.svg";
import Delete from "../../assets/icons/Delete.svg";
import DeleteRed from "../../assets/icons/DeleteRed.svg";
import leftArrowBanner from "../../assets/icons/leftArrowBanner.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { convertObjectToArrOnlyValue } from "../../helper/Helper";
export default function EditStoreTopProduct() {
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [bannerModal, setBannerModal] = useState(false);
  const [progress, setProgress] = useState(false);
  const [banner, setBanner] = useState({
    tittle: null,
    img: null,
    description: null,
    alt: null,
    link: null,
  });
  const [imgInfo, setImgInfo] = useState({
    link: false,
    id: false,
  });
  const [modalImg, setModalImg] = useState(null);
  const [allImg, setAllImg] = useState([]);
  const [bannerInfo, setBannerinfo] = useState({
    media_id: null,
    banner_title: null,
    banner_link: null,
    banner_description: null,
    banner_type: 3,
    alt: null,
    storeBanner_id: null,
  });
  useEffect(() => {
    axios
      .get("/StoreBanner")
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          res.data.data.map((item, index) => {
            if (item.banner_type === 3) {
              setBannerinfo((prev) => {
                prev.media_id = item.media_id;
                prev.alt = item.alt;
                prev.banner_description = item.banner_description;
                prev.banner_link = item.banner_link;
                prev.banner_title = item.banner_title;
                prev.storeBanner_id = item.id;
                return prev;
              });
              setBanner((prev) => {
                prev.alt = item.alt;
                prev.description = item.banner_description;
                prev.link = item.banner_link;
                prev.tittle = item.banner_title;
                prev.img = item.media_link;
                return prev;
              });
              setModalImg(item.media_link);
            }
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const openModal = () => {
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };
  const header = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  // const UploadBanner = (e) => {
  //   let formData = new FormData();
  //   formData.append("media", e.target.files[0]);
  //   axios
  //     .post("/media", formData, header)
  //     .then((res) => {
  //       if (res.data.status) {
  //         console.log(res);
  //         setModalImg(res.data.data.media_link);
  //         setBannerinfo((prev) => {
  //           prev.media_id = res.data.data.id;
  //           return prev;
  //         });
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  // multiple upload
  const uploadImage = (e) => {
    setProgress(true);

    let media = convertObjectToArrOnlyValue(e.target.files);

    let formData = new FormData();

    media.forEach((file, index) => {
      formData.append(`media[]`, media[index]);
    });

    axios
      .post("/media/multipleUpload", formData, header)
      .then((res) => {
        if (res.data.status === 0) {
          setProgress(false);
        }
        if (res.data.status) {
          setProgress(false);
          dispatch(ShowAlert(true, res.data.message));
          axios
            .get("/media?isPaginated=0")
            .then((res) => {
              if (res.data.status) {
                console.log(res);
                // setImgModal(!imgModal);
                setAllImg(res.data.data);
              }
              forceUpdate();
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        setProgress(false);
        console.log(e);
      });
  };
  const delteImg = () => {
    if (modalImg !== null) {
      setModalImg(null);
      setBannerinfo((prev) => {
        prev.media_id = null;
        return prev;
      });
    }
  };
  const saveBanner = () => {
    axios
      .post("/StoreBanner", bannerInfo)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          dispatch(ShowAlert(true, res.data.message));
          setModal(false);
          setModalImg(null);
          setBannerinfo((prev) => {
            prev.media_id = null;
            prev.alt = null;
            prev.banner_description = null;
            prev.banner_link = null;
            prev.banner_title = null;
            prev.storeBanner_id = null;
            return prev;
          });
          axios
            .get("/StoreBanner")
            .then((res) => {
              if (res.data.status) {
                console.log(res);
                res.data.data.map((item, index) => {
                  if (item.banner_type === 3) {
                    setBannerinfo((prev) => {
                      prev.media_id = item.media_id;
                      prev.alt = item.alt;
                      prev.banner_description = item.banner_description;
                      prev.banner_link = item.banner_link;
                      prev.banner_title = item.banner_title;
                      prev.storeBanner_id = item.id;
                      return prev;
                    });
                    setBanner((prev) => {
                      prev.alt = item.alt;
                      prev.description = item.banner_description;
                      prev.link = item.banner_link;
                      prev.tittle = item.banner_title;
                      prev.img = item.media_link;
                      return prev;
                    });
                    setModalImg(item.media_link);
                  }
                });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const BannerClick = () => {
    if (banner.link !== null) {
      navigate(`/${banner.link}`);
    }
  };
  const openBannerModal = () => {
    if (bannerModal === false) {
      setImgInfo((prev) => {
        prev.id = null;
        prev.link = null;
        forceUpdate();
        return prev;
      });
      setBannerModal(!bannerModal);
      axios
        .get("/media?isPaginated=0")
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            // setImgModal(!imgModal);
            setAllImg(res.data.data);
          }
          forceUpdate();
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setBannerModal(!bannerModal);
      setImgInfo((prev) => {
        prev.id = null;
        prev.link = null;
        forceUpdate();
        return prev;
      });
    }
    forceUpdate();
  };
  const chooseImg = (media_link, id) => {
    setImgInfo((prev) => {
      prev.id = id;
      prev.link = media_link;
      forceUpdate();
      return prev;
    });
  };
  const finalAddBanner = () => {
    setModalImg(imgInfo.link);
    setBannerinfo((prev) => {
      prev.media_id = imgInfo.id;
      forceUpdate();
      return prev;
    });
    setBannerModal(!bannerModal);
  };
  return (
    <div className="edit-store-top-product-all">
      {/* ///////////////////////////////// */}
      <Modal
        onBackdropClick={() => openBannerModal()}
        className="img-modal"
        open={bannerModal}
      >
        <div className="img-modal-box">
          <div className="img-modal-top-bar-box">
            <p>انتخاب تصویر</p>
            <img src={cross} onClick={() => openBannerModal()} />
          </div>
          <div className="img-modal-img-box">
            <div className="img-modal-img-box-top-bar">
              <p>گالری</p>
              {/* <p>حداکثر 20 تصویر</p> */}
            </div>
            <SimpleBar
              forceVisible="x"
              style={{
                width: "100%",
                height: "450px",
                marginTop: "10px",
              }}
            >
              <div className="img-modal-img-box-2">
                <label
                  htmlFor="upload_img"
                  className="img-modal-img-box-upload-box"
                >
                  {progress === true ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <div className="img-modal-img-box-upload-box-button">
                        آپلود عکس
                      </div>
                      <img src={UploadImg} />
                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="upload_img"
                        multiple
                        onChange={(e) => uploadImage(e)}
                      />
                    </>
                  )}
                </label>
                {allImg.map((item, index) => {
                  return (
                    <div
                      onClick={() => chooseImg(item.media_link, item.id, item)}
                      key={index}
                      className={
                        imgInfo.id === item.id
                          ? "img-modal-img-box-img-show-active"
                          : "img-modal-img-box-img-show"
                      }
                    >
                      <img src={item.media_link} />
                    </div>
                  );
                })}
              </div>
            </SimpleBar>
          </div>
          <div className="img-modal-img-box-3">
            <div>
              <button
                onClick={() => openBannerModal()}
                className="img-modal-img-box-3-cancel-Button"
              >
                لغو
              </button>
            </div>
            <div>
              {/* <p>{productGalleryInfo.productGalleries.length}</p> */}
              {/* <p>از 20 تصویر انتخاب شده</p> */}
              <button
                onClick={() => finalAddBanner()}
                className="img-modal-img-box-3-send-Button"
              >
                افزودن
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* /////////////////////////////////// */}
      <Modal
        open={modal}
        onBackdropClick={() => closeModal()}
        className="edit-store-top-product-modal-all"
      >
        <div className="edit-store-top-product-modal-box">
          <div className="edit-store-top-product-modal-header">
            <p>ویرایش بنر</p>
            <img
              style={{ cursor: "pointer" }}
              src={cross}
              onClick={() => closeModal()}
            />
          </div>
          <div className="edit-store-top-product-modal-img-box">
            <div
              className={
                modalImg === null
                  ? "edit-store-top-product-modal-img-box-1"
                  : "edit-store-top-product-modal-img-box-1-active"
              }
            >
              {modalImg === null ? (
                <label
                  onClick={() => openBannerModal()}
                  htmlFor="upload_banne_2"
                >
                  {/* <input
                    type="file"
                    id="upload_banne_2"
                    onChange={(e) => UploadBanner(e)}
                  /> */}
                  <img
                    className="edit-store-top-product-modal-img-box-1-modal-icon"
                    src={Uploadinmedia}
                  />
                  <p>آپلود تصویر</p>
                </label>
              ) : (
                <img
                  className="edit-store-top-product-modal-img-box-1-modal-img"
                  src={modalImg}
                />
              )}
            </div>
            <img
              onClick={() => delteImg()}
              className="edit-store-top-product-modal-img-box-delete-img"
              src={modalImg === null ? Delete : DeleteRed}
            />
          </div>
          <div className="edit-store-top-product-modal-inputs-box">
            <div className="edit-store-top-product-modal-inputs-box-1">
              <InputComponent
                value={bannerInfo.banner_title}
                width="45%"
                height="44px"
                type="text"
                inputType="input"
                placeholder="عنوان تصویر"
                borderColor="#dcdcdc"
                onchange={(e) => {
                  setBannerinfo((prev) => {
                    prev.banner_title = e.target.value;
                    forceUpdate();
                    return prev;
                  });
                }}
              />
              <InputComponent
                value={bannerInfo.banner_description}
                width="45%"
                height="44px"
                type="text"
                inputType="input"
                placeholder="توضیحات تصویر"
                borderColor="#dcdcdc"
                onchange={(e) => {
                  setBannerinfo((prev) => {
                    prev.banner_description = e.target.value;
                    forceUpdate();
                    return prev;
                  });
                }}
              />
            </div>
            <div className="edit-store-top-product-modal-inputs-box-1">
              <InputComponent
                value={bannerInfo.alt}
                width="45%"
                height="44px"
                type="text"
                inputType="input"
                placeholder="متن جایگزین تصویر"
                borderColor="#dcdcdc"
                onchange={(e) => {
                  setBannerinfo((prev) => {
                    prev.alt = e.target.value;
                    forceUpdate();
                    return prev;
                  });
                }}
              />
              <InputComponent
                value={bannerInfo.banner_link}
                width="45%"
                height="44px"
                type="text"
                inputType="input"
                placeholder="آدرس لینک"
                borderColor="#dcdcdc"
                onchange={(e) => {
                  setBannerinfo((prev) => {
                    prev.banner_link = e.target.value;
                    forceUpdate();
                    return prev;
                  });
                }}
              />
            </div>
          </div>
          <div className="edit-store-top-product-modal-buttons-box">
            <button
              className="edit-store-top-product-modal-buttons-cancel"
              onClick={() => closeModal()}
            >
              لغو
            </button>
            <button
              className="edit-store-top-product-modal-buttons-save"
              onClick={() => saveBanner()}
            >
              ذخیره
            </button>
          </div>
        </div>
      </Modal>
      {banner.img === null ? (
        <div className="edit-store-top-product-img-box">
          <img src={PictureIcon} />
        </div>
      ) : (
        <div
          onClick={() => BannerClick()}
          className="edit-store-top-product-img-box-2"
        >
          <img src={banner.img} />
          {banner.tittle !== null && banner.tittle !== "" ? (
            <div className="edit-store-top-product-img-box-3"></div>
          ) : null}

          <div className="edit-store-top-product-img-box-3-title-box">
            <p>{banner.tittle}</p>
            {banner.link !== null && banner.link !== "" ? (
              <button>
                خرید
                <img src={leftArrowBanner} />
              </button>
            ) : null}
          </div>
        </div>
      )}
      <button
        onClick={() => openModal()}
        className="edit-store-top-product-button"
      >
        <img style={{ marginLeft: "10px" }} src={whiteEdit} />
        ویرایش
      </button>
    </div>
  );
}
