const iniState = {
  title: null,
  latin_title: null,
  blog_description: null,
  blog_text: null,
  blog_url: null,
  comments_status: null,
  blogCategories: [],
  blogTags: [],
  author: null,
  release_status: null
};
const BlogInfoReducer = (state = iniState, action) => {
  switch (action.type) {
    case "SEND_BLOG_INFO":
      return {
        ...state,
        title: action.blogInfo.title,
        latin_title: action.blogInfo.latin_title,
        blog_description: action.blogInfo.blog_description,
        blog_url: action.blogInfo.blog_url,
        comments_status: action.blogInfo.comments_status,
        author: action.blogInfo.author,
        release_status : state.release_status
      };
    case "SEND_BLOG_CONTENT":
      state.blog_text = action.content;
      return state;
    case "SEND_BLOG_TAG":
      state.blogTags.push(action.id);
      return state;
    case "DELETE_BLOG_TAG":
      state.blogTags.splice(action.index, 1);
      return state;
    case "ADD_BLOG_CATEGORY":
      if (!state.blogCategories.includes(action.id)) {
        state.blogCategories.push(action.id);
      } else {
        var indexof = state.blogCategories.indexOf(action.id);
        state.blogCategories.splice(indexof, 1);
      }
      console.log(state);
      return state;
      case "RESET_TO_INIT":
      let array = [];
      let array2 = []
      return { ...iniState,  blogCategories : array , blogTags : array2 };
    default:
      return state;
  }
};
export default BlogInfoReducer;
