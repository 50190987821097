import { React, useCallback, useEffect, useRef, useState } from "react";
import "../../assets/styles/SideBarStyle/Drawer.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import { ResetToInit } from "../../action/index";
import { useSelector, useDispatch } from "react-redux";
import { CloseDrawerAction } from "../../action/index";
import { useLocation } from "react-router-dom";
import useForceUpdate from "use-force-update";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DescriptionIcon from "@mui/icons-material/Description";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import PersonIcon from "@mui/icons-material/Person";
import LanguageIcon from "@mui/icons-material/Language";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import SettingsIcon from "@mui/icons-material/Settings";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PhoneIcon from "@mui/icons-material/Phone";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchLogo from "../../assets/icons/SideBarSearch.svg";
import SideBarArrowRight from "../../assets/icons/SideBarArrowRight.svg";
import SideBarArrowLeftBlue from "../../assets/icons/SideBarArrowLeftBlue.svg";
import Dashboard from "../../assets/icons/sidebar/Dashboard.svg";
import Products from "../../assets/icons/sidebar/Products.svg";
import Settings from "../../assets/icons/sidebar/Settings.svg";
import Orders from "../../assets/icons/sidebar/Orders.svg";
import Blog from "../../assets/icons/sidebar/Blog.svg";
import Media from "../../assets/icons/sidebar/Media.svg";
import Customers from "../../assets/icons/sidebar/Customers.svg";
import customerService from "../../assets/icons/sidebar/customer-service.svg";
import users from "../../assets/icons/sidebar/users.svg";
import ReportCenter from "../../assets/icons/sidebar/ReportCenter.svg";
import Trashcan from "../../assets/icons/GarbageDelte.svg";
import Logout from "../../assets/icons/sidebar/Logout.svg";
import Support from "../../assets/icons/sidebar/Support.svg";
import DashboardActive from "../../assets/icons/sidebarActive/Dashboard.svg";
import ProductsActive from "../../assets/icons/sidebarActive/Products.svg";
import SettingsActive from "../../assets/icons/sidebarActive/Settings.svg";
import OrdersActive from "../../assets/icons/sidebarActive/Orders.svg";
import BlogActive from "../../assets/icons/sidebarActive/Blog.svg";
import MediaActive from "../../assets/icons/sidebarActive/Media.svg";
import CustomersActive from "../../assets/icons/sidebarActive/Customers.svg";
import customerServiceActive from "../../assets/icons/sidebarActive/customer-service.svg";
import usersActive from "../../assets/icons/sidebarActive/users.svg";
import ReportCenterActive from "../../assets/icons/sidebarActive/ReportCenter.svg";
import TrashcanActive from "../../assets/icons/GarbageDelteActive.svg";
import LogoutActive from "../../assets/icons/sidebarActive/Logout.svg";
import SupportActive from "../../assets/icons/sidebarActive/Support.svg";
import Axios from "../../js/Axios/Axios";

function Drawer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const forceUpdate = useForceUpdate();

  // TODO here
  const [DrawerButtonData, setDrawerButtonData] = useState([
    {
      ArrowSign: <KeyboardArrowDownIcon className="DrawerArrowButtonIcon" />,
      ButtonName: "داشبورد",
      buttonEnName: "Dashboard",
      showSearch: true,
      havePermission: false,
      ButtonIcon: <img src={Dashboard} className="DrawerButtonIcon" />,
      upsign: <KeyboardArrowUpIcon />,
      OpenICon: <img src={DashboardActive} className="DrawerOpenButtonIcon" />,
      isOpen: false,
      items: [
        {
          item: "داشبورد ",
          showSearch: true,
          navigates: () => navigate("/"),
          name: ["/"],
        },
        // TODO { item: " مشاهده فروشگاه", showSearch: true, name: [] },
      ],
    },

    /*{
          item: "محصولات منتشر نشده",
          navigates: () => navigate("/AllDraftProduct"),
          showSearch: true,
        },*/
    {
      ArrowSign: <KeyboardArrowDownIcon className="DrawerArrowButtonIcon" />,
      ButtonName: " محصولات",
      buttonEnName: "Product",
      havePermission: false,
      showSearch: true,
      ButtonIcon: <img src={Products} className="DrawerButtonIcon" />,
      upsign: <KeyboardArrowUpIcon />,
      OpenICon: <img src={ProductsActive} className="DrawerOpenButtonIcon" />,
      isOpen: false,
      items: [
        {
          item: "محصولات",
          navigates: () => navigate("/AllProduct"),
          showSearch: true,
          name: [
            "/AllProduct",
            "/AllDraftProduct",
            "/GarbageProduct",
            "/AllProductUpdate",
            "/AllDraftProductUpdate",
          ],
        },

        {
          item: " ایجاد محصول",
          navigates: () => navigate("/Product"),
          showSearch: true,
          name: ["/Product"],
        },
        {
          item: "دسته بندی",
          navigates: () => navigate("/Category"),
          showSearch: true,
          name: ["/Category"],
        },
        {
          item: "ویژگی ها",
          navigates: () => navigate("/Property"),
          showSearch: true,
          name: ["/Property"],
        },

        {
          item: "برچسب ها",
          navigates: () => navigate("/Tags"),
          showSearch: true,
          name: ["/Tags"],
        },
        {
          item: "مدیریت بازارچه",
          navigates: () => navigate("/marketPlace"),
          showSearch: true,
          name: ["/marketPlace"],
        },
        {
          item: "تغییر قیمت سریع",
          navigates: () => navigate("/InstanceChangePrice"),
          showSearch: true,
          name: ["/InstanceChangePrice"],
        },
        {
          item: "  تخفیف سریع",
          navigates: () => navigate("/InstanceDiscount"),
          showSearch: true,
          name: ["/InstanceDiscount"],
        },
        {
          item: "   دیدگاه ها",
          navigates: () => navigate("/ManageCustomerFeedback"),
          showSearch: true,
          name: ["/ManageCustomerFeedback", "/GarbageCommentProduct"],
        },
      ],
    },
    {
      ArrowSign: <KeyboardArrowDownIcon className="DrawerArrowButtonIcon" />,
      ButtonName: "فروشندگان",
      buttonEnName: "Seller",
      showSearch: true,
      havePermission: false,
      ButtonIcon: <img src={Customers} className="DrawerButtonIcon" />,
      upsign: <KeyboardArrowUpIcon />,
      OpenICon: <img src={CustomersActive} className="DrawerOpenButtonIcon" />,
      isOpen: false,
      items: [
        {
          item: "فروشندگان",
          navigates: () => navigate("/seller"),
          showSearch: true,
          name: ["/seller"],
        },
      ],
    },
    {
      ArrowSign: <KeyboardArrowDownIcon className="DrawerArrowButtonIcon" />,
      ButtonName: " سفارشات",
      buttonEnName: "Order",
      showSearch: true,
      havePermission: false,
      ButtonIcon: <img src={Orders} className="DrawerButtonIcon" />,
      upsign: <KeyboardArrowUpIcon />,
      OpenICon: <img src={OrdersActive} className="DrawerOpenButtonIcon" />,
      isOpen: false,
      items: [
        {
          item: "لیست سفارشات",
          showSearch: true,
          navigates: () => navigate("/Order"),
          name: ["/Order"],
        },
        {
          item: "ایجاد سفارش",
          showSearch: true,
          navigates: () => navigate("/createOrder"),
          name: ["/createOrder"],
        },
        {
          item: "مدیریت کوپن ها",
          navigates: () => navigate("/ManageDiscount"),
          showSearch: true,
          name: ["/ManageDiscount"],
        },
        {
          item: "ایجاد کوپن تخفیف",
          navigates: () => navigate("/CreateDiscount"),
          showSearch: true,
          name: ["/CreateDiscount"],
        },
        {
          item: "سفارشات مرجوعی",
          showSearch: true,
          navigates: () => navigate("/ReturnedOrder"),
          name: ["/ReturnedOrder"],
        },
      ],
    },
    {
      ArrowSign: <KeyboardArrowDownIcon className="DrawerArrowButtonIcon" />,
      ButtonName: "وبلاگ",
      buttonEnName: "Blog",
      showSearch: true,
      havePermission: false,
      ButtonIcon: <img src={Blog} className="DrawerButtonIcon" />,
      upsign: <KeyboardArrowUpIcon />,
      OpenICon: <img src={BlogActive} className="DrawerOpenButtonIcon" />,
      isOpen: false,
      items: [
        {
          item: "مدیریت وبلاگ",
          navigates: () => navigate("/ManageBlog"),
          showSearch: true,
          name: ["/ManageBlog", "/GarbageBlog", "/EditBlog"],
        },
        {
          item: "ایجاد وبلاگ",
          navigates: () => navigate("/CreateBlog"),
          showSearch: true,
          name: ["/CreateBlog"],
        },
        {
          item: "برچسب وبلاگ",
          navigates: () => navigate("/BlogTag"),
          showSearch: true,
          name: ["/BlogTag"],
        },
        {
          item: "دسته بندی وبلاگ",
          navigates: () => navigate("/BlogCategory"),
          showSearch: true,
          name: ["/BlogCategory"],
        },
        {
          item: "دیدگاه ها",
          navigates: () => navigate("/CommentBlog"),
          showSearch: true,
          name: ["/CommentBlog", "/GarbageBlogComment"],
        },
      ],
    },
    {
      ArrowSign: <KeyboardArrowDownIcon className="DrawerArrowButtonIcon" />,
      ButtonName: " رسانه",
      buttonEnName: "Media",
      showSearch: true,
      havePermission: false,
      ButtonIcon: <img src={Media} className="DrawerButtonIcon" />,
      upsign: <KeyboardArrowUpIcon />,
      OpenICon: <img src={MediaActive} className="DrawerOpenButtonIcon" />,
      isOpen: false,
      items: [
        {
          item: "مدیریت رسانه",
          navigates: () => navigate("/Media"),
          showSearch: true,
          name: ["/Media"],
        },
      ],
    },
    {
      ArrowSign: <KeyboardArrowDownIcon className="DrawerArrowButtonIcon" />,
      ButtonName: " مشتریان",
      buttonEnName: "Customer",
      showSearch: true,
      havePermission: false,
      ButtonIcon: <img src={Customers} className="DrawerButtonIcon" />,
      upsign: <KeyboardArrowUpIcon />,
      OpenICon: <img src={CustomersActive} className="DrawerOpenButtonIcon" />,
      isOpen: false,
      items: [
        {
          item: "مدیریت مشتریان",
          navigates: () => navigate("/Customers"),
          showSearch: true,
          name: ["/Customers"],
        },
      ],
    },
    {
      ArrowSign: <KeyboardArrowDownIcon className="DrawerArrowButtonIcon" />,
      ButtonName: " کاربران سیستم",
      buttonEnName: "UserSystem",
      showSearch: true,
      havePermission: false,
      ButtonIcon: <img src={users} className="DrawerButtonIcon" />,
      upsign: <KeyboardArrowUpIcon />,
      OpenICon: <img src={usersActive} className="DrawerOpenButtonIcon" />,
      isOpen: false,
      items: [
        {
          item: " مدیریت کاربران",
          navigates: () => navigate("/ManageUser"),
          showSearch: true,
          name: ["/ManageUser"],
        },
        {
          item: " نقش ها",
          navigates: () => navigate("/Role"),
          showSearch: true,
          name: ["/Role"],
        },
      ],
    },
    {
      ArrowSign: <KeyboardArrowDownIcon className="DrawerArrowButtonIcon" />,
      ButtonName: " پشتیبانی",
      buttonEnName: "Support",
      showSearch: true,
      havePermission: false,
      ButtonIcon: <img src={Support} className="DrawerButtonIcon" />,
      upsign: <KeyboardArrowUpIcon />,
      OpenICon: <img src={SupportActive} className="DrawerOpenButtonIcon" />,
      isOpen: false,
      items: [
        {
          item: "سوالات متداول ",
          navigates: () => navigate("/FrequentlyQuestion"),
          showSearch: true,
          name: ["/FrequentlyQuestion"],
        },
        {
          item: "ویدیوهای اموزشی",
          navigates: () => navigate("/Tutorial"),
          showSearch: true,
          name: ["/Tutorial"],
        },
        {
          item: " ارتباط با ویتسل",
          navigates: () => navigate("/ContactVitsell"),
          showSearch: true,
          name: ["/ContactVitsell"],
        },
        {
          item: " پیغام ها",
          navigates: () => navigate("/Messages"),
          showSearch: true,
          name: ["/Messages"],
        },
      ],
    },
    {
      ArrowSign: <KeyboardArrowDownIcon className="DrawerArrowButtonIcon" />,
      ButtonName: "سامانه پیامکی",
      buttonEnName: "SmsSystem",
      showSearch: true,
      havePermission: false,
      ButtonIcon: <img src={customerService} className="DrawerButtonIcon" />,
      upsign: <KeyboardArrowUpIcon />,
      OpenICon: (
        <img src={customerServiceActive} className="DrawerOpenButtonIcon" />
      ),
      isOpen: false,
      items: [
        {
          item: "ایجاد پیام",
          navigates: () => navigate("/SmsSystem"),
          showSearch: true,
          name: ["/SmsSystem"],
        },
        {
          item: "مدیریت پیامک",
          navigates: () => navigate("/SmsManage"),
          showSearch: true,
          name: ["/SmsManage"],
        },
      ],
    },
    {
      ArrowSign: <KeyboardArrowDownIcon className="DrawerArrowButtonIcon" />,
      ButtonName: "  گزارشات",
      buttonEnName: "Report",
      showSearch: true,
      havePermission: false,
      ButtonIcon: <img src={ReportCenter} className="DrawerButtonIcon" />,
      upsign: <KeyboardArrowUpIcon />,
      OpenICon: (
        <img src={ReportCenterActive} className="DrawerOpenButtonIcon" />
      ),
      isOpen: false,
      items: [
        {
          item: " گزارش فروش",
          navigates: () => navigate("/SalesReport"),
          showSearch: true,
          name: ["/SalesReport"],
        },
        {
          item: " امار بازدید کنندگان",
          navigates: () => navigate("/VisitorStatistics"),
          showSearch: true,
          name: ["/VisitorStatistics"],
        },
      ],
    },
    {
      ArrowSign: <KeyboardArrowDownIcon className="DrawerArrowButtonIcon" />,
      ButtonName: "تنظیمات",
      buttonEnName: "Setting",
      showSearch: true,
      havePermission: false,
      ButtonIcon: <img src={Settings} className="DrawerButtonIcon" />,
      upsign: <KeyboardArrowUpIcon />,
      OpenICon: <img src={SettingsActive} className="DrawerOpenButtonIcon" />,
      isOpen: false,
      items: [
        {
          item: "تنظیمات فروشگاه",
          navigates: () => navigate("/EditStore"),
          showSearch: true,
          name: ["/EditStore"],
        },
        {
          item: "تماس با ما",
          navigates: () => navigate("/ContactUs"),
          showSearch: true,
          name: ["/ContactUs"],
        },
        {
          item: "درباره ی ما",
          navigates: () => navigate("/AboutUs"),
          showSearch: true,
          name: ["/AboutUs"],
        },
        {
          item: " حمل و نقل",
          navigates: () => navigate("/Delivery"),
          showSearch: true,
          name: ["/Delivery"],
        },
        // TODO {item: " امور مالی و اشتراک",showSearch: true,name: [],},
      ],
    },

    // {
    //   ArrowSign: <KeyboardArrowDownIcon className="DrawerArrowButtonIcon" />,
    //   ButtonName: " زباله دان",
    //   showSearch: true,
    //   ButtonIcon: <img src={Trashcan} className="DrawerButtonIcon" />,
    //   upsign: <KeyboardArrowUpIcon />,
    //   OpenICon: <img src={TrashcanActive} className="DrawerOpenButtonIcon" />,
    //   isOpen: false,
    //   items: [
    //     /*{
    //       item: " محصولات",
    //       navigates: () => navigate("/GarbageProduct"),
    //       showSearch: true,
    //     },*/
    //     { item: "سفارشات", showSearch: true },
    //     {
    //       item: " وبلاگ",
    //       navigates: () => navigate("/GarbageBlog"),
    //       showSearch: true,
    //     },
    //     {
    //       item: " دیدگاه های محصول",
    //       navigates: () => navigate("/GarbageCommentProduct"),
    //       showSearch: true,
    //     },
    //     {
    //       item: "دیدگاه های وبلاگ",
    //       navigates: () => navigate("/GarbageBlogComment"),
    //       showSearch: true,
    //     },
    //     {
    //       item: " حمل و نقل",
    //       showSearch: true,
    //     },
    //     {
    //       item: "  نقش های کاربران سیستم ",
    //       showSearch: true,
    //     },
    //   ],
    // },
    {
      ArrowSign: <KeyboardArrowDownIcon className="DrawerArrowButtonIcon" />,
      ButtonName: "خروج",
      havePermission: true,
      showSearch: true,
      ButtonIcon: <img src={Logout} className="DrawerButtonIcon" />,
      upsign: <KeyboardArrowUpIcon />,
      OpenICon: <img src={LogoutActive} className="DrawerOpenButtonIcon" />,
      isOpen: false,
      items: [],
    },
  ]);

  const [Select, setSelect] = useState();
  const clickHandler = (myitem, myindex) => {
    if (myitem.ButtonName != "خروج") {
      if (Select === myindex) {
        setSelect(null);
      } else {
        setSelect(myindex);
        setSelectItem(null);
      }

      if (CloseDrawer === 1) {
        setCloseDarwer(0);
        dispatch(CloseDrawerAction(CloseDrawer));
      }
    } else {
      // Remove Token And  LogOut Admin
      localStorage.removeItem("access_token");

      // redirect to login page
      navigate("/login");
    }
  };

  // ? use Effect for check permissions
  useEffect(() => {
    Axios.get("/UserSystemAccess/relatedList")
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          res.data.data.forEach((permission) => {
            let btnActive = DrawerButtonData.find((btn) => {
              return btn.buttonEnName == permission.title;
            });

            btnActive.havePermission = true;

            forceUpdate();
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const [CloseDrawer, setCloseDarwer] = useState(0);

  const closedrawer = () => {
    if (CloseDrawer === 0) {
      setCloseDarwer(1);
    } else if (CloseDrawer === 1) {
      setCloseDarwer(0);
    }
    dispatch(CloseDrawerAction(CloseDrawer));
  };

  const [SelectItem, setSelectItem] = useState();

  const clickItem = (item, i) => {
    dispatch(ResetToInit());
    item();
    setSelectItem(i);
  };

  // sidebar search
  const sideBarSearch = (e) => {
    let searchText = e.target.value;

    DrawerButtonData.forEach((item, index) => {
      item.items.forEach((subItem, subIndex) => {
        //  check item titles contains search text or not
        if (subItem.item.indexOf(searchText) == -1) {
          // sub Item
          setDrawerButtonData((prev) => {
            prev[index].items[subIndex].showSearch = false;
            forceUpdate();
            return prev;
          });
        } else if (subItem.item.indexOf(searchText) != -1) {
          // sub Item
          setDrawerButtonData((prev) => {
            prev[index].items[subIndex].showSearch = true;
            forceUpdate();
            return prev;
          });

          // main item
          setDrawerButtonData((prev) => {
            prev[index].showSearch = true;
            forceUpdate();
            return prev;
          });
        }
      });

      // check main titles contains search text
      if (
        item.items.length > 0 &&
        item.items.every(({ showSearch }) => showSearch == false)
      ) {
        // main item
        setDrawerButtonData((prev) => {
          prev[index].showSearch = false;
          forceUpdate();
          return prev;
        });
      } else if (item.items.every(({ showSearch }) => showSearch != false)) {
        // main item
        setDrawerButtonData((prev) => {
          prev[index].showSearch = true;
          forceUpdate();
          return prev;
        });
      }

      // check length of searchText
      if (searchText.length == 0) {
        setDrawerButtonData((prev) => {
          prev[index].showSearch = true;
          prev[index].items.forEach((it) => {
            it.showSearch = true;
          });

          forceUpdate();
          return prev;
        });
      }
    });
  };

  return (
    <body className={CloseDrawer === 1 ? "DrawerBodyClose" : "DrawerBody"}>
      <div className={CloseDrawer === 1 ? "CloseDrawerAll" : "DrawerAll"}>
        <div className="DrawerSearchBox">
          <div
            className={CloseDrawer === 1 ? "CloseDrawerSearch" : "DrawerSearch"}
          >
            <img className="DrawerSearchImage" src={SearchLogo} />
            {/* <SearchIcon className="DrawerSearchIcon" /> */}
            <input
              onChange={(e) => sideBarSearch(e)}
              className="DrawerSearchInput"
              placeholder="جستجو..."
            ></input>
          </div>
          <button
            onClick={() => closedrawer()}
            className={
              CloseDrawer === 1
                ? "CloseDrawerLeftArrowButton"
                : "DrawerLeftArrowButton"
            }
          >
            <img
              className="DrawerArrowImg"
              src={CloseDrawer === 1 ? SideBarArrowLeftBlue : SideBarArrowRight}
            />
            {/* <ArrowForwardIosIcon className="DrawerArrowSign" /> */}
          </button>
        </div>

        <div className="DrawerButtonsBox">
          {DrawerButtonData.map((myitem, myindex) => {
            return (
              myitem.havePermission && (
                <div key={myindex}>
                  {myitem.showSearch && CloseDrawer === 0 ? (
                    <div
                      style={
                        myitem.ButtonName == "خروج"
                          ? {
                              width: "auto",
                              justifyContent: "flex-start",
                              cursor: "auto",
                            }
                          : {}
                      }
                      className={
                        Select === myindex
                          ? "DrawerButtonActive"
                          : "DrawerButon"
                      }
                    >
                      <div
                        className={
                          Select === myindex
                            ? "DrawerOpenIconBox2"
                            : "DrawerOpenBoxIconBox"
                        }
                      >
                        <div className="DrawerOpenIcon">{myitem.OpenICon}</div>

                        <button
                          onClick={() => clickHandler(myitem, myindex)}
                          className={
                            CloseDrawer === 1
                              ? "DrawerOpenIconTittleBoxClose"
                              : "DrawerOpenIconTittleBox"
                          }
                        >
                          <p className="DrawerOpenIconTittle">
                            {myitem.ButtonName}
                          </p>
                        </button>
                        <button
                          onClick={() => clickHandler(myitem, myindex)}
                          className={
                            CloseDrawer === 1
                              ? "DrawerOpenUpsignClose"
                              : "DrawerOpenUpsign"
                          }
                        >
                          {myitem.upsign}
                        </button>
                      </div>
                      <div
                        className={
                          Select === myindex || CloseDrawer === 1
                            ? "DrawerOpenItemBox"
                            : "DrawerOpenItemBox2"
                        }
                      >
                        {myitem.items.map((item, i) => {
                          return (
                            item.showSearch && (
                              <button
                                key={i}
                                onClick={() => clickItem(item.navigates, i)}
                                className={
                                  CloseDrawer === 1
                                    ? "DrawerOpenBoxItemClose"
                                    : "DrawerOpenBoxItem"
                                }
                              >
                                <div
                                  className={
                                    // (SelectItem === i) ||

                                    item.name.includes(location.pathname)
                                      ? "DrawerOpenBoxItemCircleAfter"
                                      : "DrawerOpenBoxItemCircle"
                                  }
                                ></div>

                                <p
                                  className={
                                    item.name.includes(location.pathname)
                                      ? "DrawerOpenBoxItemTittleAfter"
                                      : "DrawerOpenBoxItemTittle"
                                  }
                                >
                                  {item.item}
                                </p>
                              </button>
                            )
                          );
                        })}
                      </div>

                      <div
                        className={
                          Select === myindex
                            ? "DrawerBoxIcon"
                            : "DrawerBoxIcon2"
                        }
                      >
                        {myitem.ButtonIcon}
                      </div>

                      <button
                        style={
                          myitem.ButtonName == "خروج"
                            ? {
                                width: "auto",
                              }
                            : {}
                        }
                        onClick={() => clickHandler(myitem, myindex)}
                        className={
                          CloseDrawer === 1 || Select === myindex
                            ? "CloseDrawerButtonTittleBox"
                            : "DrawerButtonTittleBox"
                        }
                      >
                        <p className="DrawerButtonTittle">
                          {myitem.ButtonName}
                        </p>
                      </button>

                      {myitem.ButtonName == "خروج" ? null : (
                        <div
                          onClick={() => clickHandler(myitem, myindex)}
                          className={
                            CloseDrawer === 1 || Select === myindex
                              ? "CloseDrawerButtonArrowSign"
                              : "DrawerButtonArrowSign"
                          }
                        >
                          {myitem.ArrowSign}
                        </div>
                      )}
                    </div>
                  ) : (
                    myitem.showSearch && (
                      <button
                        onClick={() => clickHandler(myitem, myindex)}
                        className={
                          Select === myindex
                            ? "CloseDrawerButtonIconBoxActive"
                            : "CloseDrawerButtonIconBox"
                        }
                      >
                        {myitem.ButtonIcon}
                        <div
                          className={
                            Select === myindex
                              ? "CloseDrawerButtonIconBoxActiveIconBox"
                              : "CloseDrawerButtonIconBoxActiveIconBox2"
                          }
                        >
                          {myitem.OpenICon}
                        </div>
                      </button>
                    )
                  )}
                </div>
              )
            );
          })}
        </div>

        <div className="DrawerPhoneBox">
          <PhoneIcon
            className={
              CloseDrawer === 1 ? "DrawerPhoneIconClose" : "DrawerPhoneIcon"
            }
          />
          <p
            className={
              CloseDrawer === 1 ? "DrawerPhoneTittleClose" : "DrawerPhoneTittle"
            }
          >
            پشتیبانی و تماس با ویتسل
          </p>
        </div>
      </div>
    </body>
  );
}
export default Drawer;
