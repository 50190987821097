import React, { useEffect, useRef, useState } from "react";
import "../../../assets/styles/Blog/CommentBlogStyle/CommentBlog.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import { useLocation } from "react-router-dom";
import { SendUserIdForFilter } from "../../../action/index";
import axios from "../../../js/Axios/Axios";
import { ShowAlert } from "../../../action/index";
import { SendBlogInfoForEdit } from "../../../action/index";
import Modal from "@mui/material/Modal";
import useForceUpdate from "use-force-update";
import { useSelector, useDispatch } from "react-redux";
import TopBar from "../../../components/TopBar/TopBar";
import InputComponent from "../../../components/TextInputComponent/TextInputComponent";
import DropDownComponent from "../../../components/DropDown/DropDown";
import Pagination from "../../../components/Pagination/Pagination";
import AllProductAdd from "../../../assets/icons/AllProductAdd.svg";
import AllProductTrash from "../../../assets/icons/GarbageDelte.svg";
import SearchLogo from "../../../assets/icons/SearchLogo.svg";
import AllProductCalender from "../../../assets/icons/AllProductCalender.svg";
import Delete from "../../../assets/icons/Delete.svg";
import DeleteRed from "../../../assets/icons/DeleteRed.svg";
import Eye from "../../../assets/icons/AllProductEye.svg";
import headerCheck from "../../../assets/icons/headerCheck.svg";
import check from "../../../assets/icons/check.svg";
import uncheck from "../../../assets/icons/uncheck.svg";
import openTable from "../../../assets/icons/openTable.svg";
import closeTable from "../../../assets/icons/closeTable.svg";
import cross from "../../../assets/icons/cross.svg";
import FilterLogo from "../../../assets/icons/Filter.svg";
import GreenTickSquare from "../../../assets/icons/GreenTickSquare.svg";
import RedCloseSquare from "../../../assets/icons/RedCloseSquare.svg";
import Filter from "../../../components/Filter/Filter";
import WhiteCheck from "../../../assets/icons/WhiteCheck.svg";
import WhiteCross from "../../../assets/icons/WhiteCross.svg";
// import AllProductTrash from "../../../assets/icons/GarbageDelte.svg";
import AllProductTrashRed from "../../../assets/icons/GarbageDelteRed.svg";
import SimpleBar from "simplebar-react";
import ScrollDrag from "react-indiana-drag-scroll";
export default function ManageBlog() {
  const scrollTitleRef = useRef();
  const scrollContentRef = useRef();
  const answerComment = useRef();
  const location = useLocation();
  const paginatedData = useSelector((state) => state.PaginatedData);
  const user_id = useSelector((state) => state.UserFilter);
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const [allComment, setAllComment] = useState([]);
  const [from, setFrom] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [tableOpen, setTableOpen] = useState(null);
  const [fullComment, setFullComment] = useState(null);
  const [commentModal, setCommentModal] = useState(false);
  const [blogSearch, setBlogSearch] = useState("");
  const [blogValue, setBlogValue] = useState("");
  const [blogShow, setBlogShow] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [firstAxios, setFirstAxios] = useState("/blogComment/search");
  const [currentAxios, setCurrentAxios] = useState("/blogComment/search?page=");
  const [idSelected, setIdSelected] = useState({
    comment_status: null,
    blogComment_ids: [],
  });
  const [commentInfo, setCommentInfo] = useState({
    comment_text: null,
    fakeUserName: null,
    blog_id: null,
    user_id: null,
    parent_id: null,
  });
  const [commentTableInfo, setCommentTableInfo] = useState({
    comment_text: null,
    blog_id: null,
    parent_id: null,
  });
  const [errors, setErrors] = useState([]);
  const [modalError, setModalError] = useState({
    text: false,
    blog: false,
  });
  useEffect(() => {
    console.log(paginatedData);
    if (paginatedData.length != 0) {
      // if (topBarChecked === true || checkedIds.length != 0) {
      //   setTopBarChecked(false);
      //   garbageData.map((i, index) => {
      //     checkedIds.splice(index);
      //   });
      // }
      setAllComment(paginatedData.data);
      setCurrentPage(paginatedData.meta.current_page);
      setFrom(paginatedData.meta.from);
      setTableOpen(null);
      errors.map((item, index) => {
        errors.splice(index);
      });
      paginatedData.data.map((item) => {
        errors.push({ comment: false });
      });
    }
  }, [paginatedData]);

  useEffect(() => {
    if (user_id.nameid === "blog") {
      setShowFilter(true);
    }
  }, [user_id]);

  useEffect(() => {
    if (user_id.id === null) {
      axios
        .get("/blogComment/search")
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            setAllComment(res.data.data);
            setFrom(res.data.meta.from);
            setCurrentPage(res.data.meta.current_page);
            errors.map((item, index) => {
              errors.splice(index);
            });
            res.data.data.map((item) => {
              errors.push({ comment: false });
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  // useeffect for SimpleBar and ScrollDrag
  useEffect(() => {
    // set scroll position title and content for together
    const handleScrollTitle = (e) => {
      scrollContentRef.current.scrollLeft = scrollTitleRef.current.scrollLeft;
    };
    scrollTitleRef.current.addEventListener("scroll", handleScrollTitle);

    const handleScrollContent = (e) => {
      scrollTitleRef.current.scrollLeft = scrollContentRef.current.scrollLeft;
    };
    scrollContentRef.current.addEventListener("scroll", handleScrollContent);
  }, []);

  // useEffect(() => {
  //   console.log("xxxx");
  //   if(location.pathname !== "/CommentBlog"){
  //     dispatch(SendUserIdForFilter(null,null))

  //   }
  // }, [location.pathname])
  // get data from filter props
  const Data = (data) => {
    console.log(data);
    setAllComment(data.data);
    setFrom(data.meta.from);
    setCurrentPage(data.meta.current_page);
  };
  const Url = (Url) => {
    setFirstAxios(Url);
  };

  const openTableItem = (index, id) => {
    if (tableOpen === null) {
      axios
        .get(`/blogComment/relatedList?comment_id=${id}`)
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            setTableOpen(index);
            setFullComment(res.data.data.comment_text);
            // setCommentInfo((prev) => {
            //   return {
            //     comment_text: prev.comment_text,
            //     fakeUserName: prev.fakeUserName,
            //     blog_id: res.data.data.blog_id,
            //     user_id: prev.user_id,
            //     parent_id: id,
            //   };
            // });
            setCommentTableInfo((prev) => {
              return {
                comment_text: prev.comment_text,
                blog_id: res.data.data.blog_id,
                parent_id: id,
              };
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else if (tableOpen === index) {
      setTableOpen(null);
    } else if (tableOpen != null && tableOpen != index) {
      axios
        .get(`/blogComment/relatedList?comment_id=${id}`)
        .then((res) => {
          if (res.data.status) {
            setTableOpen(index);
            console.log(res);
            setFullComment(res.data.data.comment_text);
            // setCommentInfo((prev) => {
            //   return {
            //     comment_text: prev.comment_text,
            //     fakeUserName: prev.fakeUserName,
            //     blog_id: res.data.data.blog_id,
            //     user_id: prev.user_id,
            //     parent_id: id,
            //   };
            // });
            setCommentTableInfo((prev) => {
              return {
                comment_text: prev.comment_text,
                blog_id: res.data.data.blog_id,
                parent_id: id,
              };
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    forceUpdate();
  };
  const sendcommentReply = (index) => {
    if (
      commentTableInfo.comment_text !== null &&
      commentTableInfo.comment_text !== ""
    ) {
      axios
        .post("/blogComment", commentTableInfo)
        .then((res) => {
          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));
            setCommentTableInfo((prev) => {
              prev.comment_text = "";
              prev.blog_id = null;
              prev.parent_id = null;
              forceUpdate();

              return prev;
            });
            axios
              .get(
                `/blogComment/search`
              )
              .then((res) => {
                if (res.data.status) {
                  // setFirstAxios(
                  //   `/blogComment/search?page=${
                  //     allComment.length === 5 ? currentPage + 1 : currentPage
                  //   }`
                  // );
                  // setCurrentAxios(
                  //   `/blogComment/search?page=${
                  //     allComment.length === 5 ? currentPage + 1 : currentPage
                  //   }`
                  // );
                  console.log(res);
                  setAllComment(res.data.data);
                  setFrom(res.data.meta.from);
                  setCurrentPage(res.data.meta.current_page);
                  errors.map((item, index) => {
                    errors.splice(index);
                  });
                  res.data.data.map((item) => {
                    errors.push({ comment: false });
                  });
                  forceUpdate();
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      dispatch(
        ShowAlert(
          true,
          "برای پاسخ به دیدگاه پر کردن فیلد پاسخ دیدگاه الزامی است ."
        )
      );
      setErrors((prev) => {
        prev[index].comment = true;
        forceUpdate();
        return prev;
      });
    }
  };
  const openCreateCommentModal = () => {
    setCommentModal(!commentModal);
    setCommentInfo((prev) => {
      return {
        comment_text: null,
        fakeUserName: null,
        blog_id: null,
        user_id: null,
        parent_id: null,
      };
    });
    setBlogValue(null);
  };
  const blogSearchChange = (e) => {
    setBlogValue(e.target.value);
    setBlogSearch(e.target.value);
  };
  const blogItemClick = (title, id) => {
    setBlogShow(false);
    setTimeout(() => {
      setBlogShow(true);
    }, 100);
    setBlogValue(title);
    setCommentInfo((prev) => {
      return {
        comment_text: prev.comment_text,
        fakeUserName: prev.fakeUserName,
        blog_id: id,
        user_id: prev.user_id,
        parent_id: prev.parent_id,
      };
    });
    setModalError((prev) => {
      prev.blog = false;
      forceUpdate();
      return prev;
    });
  };

  const shareCommentClick = () => {
    console.log(commentInfo);
    if (
      commentInfo.blog_id !== null &&
      commentInfo.comment_text !== null &&
      commentInfo.comment_text !== ""
    ) {
      axios
        .post("/blogComment", commentInfo)
        .then((res) => {
          console.log(res);
          if (res.data.status === 0) {
            dispatch(ShowAlert(true, res.data.data));
          }
          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));
            setCommentModal(false);
            setCommentInfo((prev) => {
              return {
                comment_text: null,
                fakeUserName: null,
                blog_id: null,
                user_id: null,
                parent_id: null,
              };
            });
            setBlogValue(null);
            axios
              .get(
                `/blogComment/search?page=${
                  allComment.length === 5 ? currentPage + 1 : currentPage
                }`
              )
              .then((res) => {
                if (res.data.status) {
                  setFirstAxios(
                    `/blogComment/search?page=${
                      allComment.length === 5 ? currentPage + 1 : currentPage
                    }`
                  );
                  setCurrentAxios(
                    `/blogComment/search?page=${
                      allComment.length === 5 ? currentPage + 1 : currentPage
                    }`
                  );
                  console.log(res);
                  setAllComment(res.data.data);
                  setFrom(res.data.meta.from);
                  setCurrentPage(res.data.meta.current_page);
                  errors.map((item, index) => {
                    errors.splice(index);
                  });
                  res.data.data.map((item) => {
                    errors.push({ comment: false });
                  });
                  forceUpdate();
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      let text = `برای انتشار دیدگاه پر کردن فیلد ${
        commentInfo.comment_text === null || commentInfo.comment_text === ""
          ? "دیدگاه و"
          : ""
      } ${commentInfo.blog_id === null ? "انتخاب بلاگ" : ""} الزامی است.`;
      dispatch(ShowAlert(true, text));
      if (commentInfo.blog_id === null) {
        setModalError((prev) => {
          prev.blog = true;
          forceUpdate();
          return prev;
        });
      }
      if (
        commentInfo.comment_text === null ||
        commentInfo.comment_text === ""
      ) {
        setModalError((prev) => {
          prev.text = true;
          forceUpdate();
          return prev;
        });
      }
    }
  };
  const searchComment = (e) => {
    axios
      .get(`/blogComment/search?search=${e.target.value}`)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setAllComment(res.data.data);
          setFrom(res.data.meta.from);
          setCurrentPage(res.data.meta.current_page);
          setFirstAxios(`/blogComment/search?search=${e.target.value}`);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const checkBoxOnChange = (e, id) => {
    e.stopPropagation();
    if (!idSelected.blogComment_ids.includes(id)) {
      idSelected.blogComment_ids.push(id);
    } else {
      var index = idSelected.blogComment_ids.indexOf(id);
      idSelected.blogComment_ids.splice(index, 1);
    }
    forceUpdate();
  };
  const allCheckBoxOnChange = () => {
    if (idSelected.blogComment_ids.length === allComment.length) {
      allComment.map((item, index) => {
        idSelected.blogComment_ids.splice(index);
      });
    } else if (idSelected.blogComment_ids.length !== allComment.length) {
      allComment.map((item) => {
        if (!idSelected.blogComment_ids.includes(item.id)) {
          idSelected.blogComment_ids.push(item.id);
        }
      });
    }
    forceUpdate();
  };
  const acceptCommentClick = () => {
    if (idSelected.blogComment_ids.length != 0) {
      idSelected.comment_status = "تایید شده";
      axios
        .put("/blogComment/changeStatus", idSelected)
        .then((res) => {
          console.log(res);
          idSelected.comment_status = null;
          idSelected.blogComment_ids.map((item, index) => {
            idSelected.blogComment_ids.splice(index);
          });
          axios
            .get(`/blogComment/search?page=${currentPage}`)
            .then((res) => {
              if (res.data.status) {
                console.log(res);
                setAllComment(res.data.data);
                setFrom(res.data.meta.from);
                setCurrentPage(res.data.meta.current_page);
                errors.map((item, index) => {
                  errors.splice(index);
                });
                res.data.data.map((item) => {
                  errors.push({ comment: false });
                });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  const declineCommentClick = () => {
    if (idSelected.blogComment_ids.length != 0) {
      idSelected.comment_status = "رد شده";
      axios
        .put("/blogComment/changeStatus", idSelected)
        .then((res) => {
          console.log(res);
          idSelected.comment_status = null;
          idSelected.blogComment_ids.map((item, index) => {
            idSelected.blogComment_ids.splice(index);
          });
          axios
            .get(`/blogComment/search?page=${currentPage}`)
            .then((res) => {
              if (res.data.status) {
                console.log(res);
                setAllComment(res.data.data);
                setFrom(res.data.meta.from);
                setCurrentPage(res.data.meta.current_page);
                errors.map((item, index) => {
                  errors.splice(index);
                });
                res.data.data.map((item) => {
                  errors.push({ comment: false });
                });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  const garbageClick = () => {
    if (idSelected.blogComment_ids.length != 0) {
      console.log(idSelected);
      let ids = JSON.stringify(idSelected.blogComment_ids);
      console.log(ids);
      axios
        .delete(`/blogComment/throwingGarbage?blogComment_ids=${ids}`)
        .then((res) => {
          console.log(res);
          if (res.data.status) {
            axios
              .get(
                `/blogComment/search?page=${
                  idSelected.blogComment_ids.length === allComment.length
                    ? currentPage - 1
                    : currentPage
                }`
              )
              .then((res) => {
                if (res.data.status) {
                  setFirstAxios(
                    `/blogComment/search?page=${
                      idSelected.blogComment_ids.length === allComment.length
                        ? currentPage - 1
                        : currentPage
                    }`
                  );
                  setCurrentAxios(
                    `/blogComment/search?page=${
                      idSelected.blogComment_ids.length === allComment.length
                        ? currentPage - 1
                        : currentPage
                    }`
                  );
                  idSelected.blogComment_ids.map((item, index) => {
                    idSelected.blogComment_ids.splice(index);
                  });
                  console.log(res);
                  setAllComment(res.data.data);
                  setFrom(res.data.meta.from);
                  setCurrentPage(res.data.meta.current_page);
                  errors.map((item, index) => {
                    errors.splice(index);
                  });
                  res.data.data.map((item) => {
                    errors.push({ comment: false });
                  });
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  const delteComment = (e, id) => {
    e.stopPropagation();
    idSelected.blogComment_ids.push(id);
    let ids = JSON.stringify(idSelected.blogComment_ids);
    axios
      .delete(`/blogComment/throwingGarbage?blogComment_ids=${ids}`)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          axios
            .get(
              `/blogComment/search?page=${
                allComment.length === 1 ? currentPage - 1 : currentPage
              }`
            )
            .then((res) => {
              if (res.data.status) {
                setFirstAxios(
                  `/blogComment/search?page=${
                    allComment.length === 1 ? currentPage - 1 : currentPage
                  }`
                );
                setCurrentAxios(
                  `/blogComment/search?page=${
                    allComment.length === 1 ? currentPage - 1 : currentPage
                  }`
                );
                console.log(res);
                setAllComment(res.data.data);
                setFrom(res.data.meta.from);
                setCurrentPage(res.data.meta.current_page);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const openFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <div className="comment-blog-all">
      <Modal
        onBackdropClick={() => openCreateCommentModal()}
        className="comment-blog-comment-modal"
        open={commentModal}
      >
        <div className="comment-blog-comment-modal-box">
          <div className="comment-blog-comment-modal-top-bar">
            <p>ایجاد دیدگاه</p>
            <img onClick={() => openCreateCommentModal()} src={cross} />
          </div>
          <div className="comment-blog-comment-modal-input-box-1">
            <InputComponent
              value={commentInfo.fakeUserName}
              width="45%"
              height="44px"
              placeholder="نام کاربری"
              borderColor="#dcdcdc"
              inputType="input"
              type="text"
              onchange={(e) =>
                setCommentInfo((prev) => {
                  return {
                    comment_text: prev.comment_text,
                    fakeUserName: e.target.value,
                    blog_id: prev.blog_id,
                    user_id: prev.user_id,
                    parent_id: prev.parent_id,
                  };
                })
              }
            />
            <DropDownComponent
              width="45%"
              height="44px"
              dropDownHeight="200px"
              zindex="1"
              //   data={permissionDropDown}
              search={blogSearch}
              show={blogShow}
              borderColor={modalError.blog === true ? "#F23B3B" : "#dcdcdc"}
              //   axiosname="permission"
              axiosurl={"/blog"}
              onchange={(e) => blogSearchChange(e)}
              value={blogValue}
              itemClick={(id, title) => blogItemClick(id, title)}
              placeholder="انتخاب بلاگ"
            />
          </div>
          <div className="comment-blog-comment-modal-input-box-2">
            {/* <InputComponent
              value={commentInfo.comment_text}
              width="100%"
              height="200px"
              type="text"
              inputType="textarea"
              placeholder=" پاسخ دیدگاه را بنویسید .."
              borderColor="#dcdcdc"
              onchange={(e) =>
                setCommentInfo((prev) => {
                  return {
                    comment_text: e.target.value,
                    fakeUserName: prev.fakeUserName,
                    blog_id: prev.blog_id,
                    user_id: prev.user_id,
                    parent_id: prev.parent_id,
                  };
                })
              }
            /> */}
            <textarea
              value={commentInfo.comment_text}
              onChange={(e) => {
                setCommentInfo((prev) => {
                  return {
                    comment_text: e.target.value,
                    fakeUserName: prev.fakeUserName,
                    blog_id: prev.blog_id,
                    user_id: prev.user_id,
                    parent_id: prev.parent_id,
                  };
                });
                setModalError((prev) => {
                  prev.text = false;
                  forceUpdate();
                  return prev;
                });
              }}
              className={
                modalError.text === false
                  ? "comment-blog-comment-modal-input-box-2-text-area"
                  : "comment-blog-comment-modal-input-box-2-text-area-error"
              }
              placeholder=" دیدگاه را بنویسید .."
            />
          </div>
          <div className="comment-blog-comment-modal-button-box">
            <button
              onClick={() => openCreateCommentModal()}
              className="comment-blog-comment-modal-cancel-button"
            >
              <p>لغو</p>
            </button>
            <button
              onClick={() => shareCommentClick()}
              className="comment-blog-comment-modal-share-button"
            >
              <p>انتشار</p>
            </button>
          </div>
        </div>
      </Modal>
      <div className="comment-blog-top-bar">
        <TopBar />
      </div>
      <div className="comment-blog-search-dropdown-buttons-box">
        <div className="comment-blog-buttons-box">
          <button
            onClick={() => openCreateCommentModal()}
            className="comment-blog-create-blog-button"
          >
            <img src={AllProductAdd} />
            <p>ایجاد دیدگاه</p>
          </button>

          <button
            onClick={() => openFilter()}
            className="comment-blog-show-filter-button"
          >
            <img src={FilterLogo} />
            <p>{showFilter === false ? "نمایش فیلتر" : "بستن فیلتر"}</p>
          </button>

          {/* <button
            onClick={() => garbageClick()}
            className="comment-blog-garbage-button"
          >
            <img src={AllProductTrash} />
            <p>زباله دان</p>
          </button> */}

          {/* <button
            onClick={() => acceptCommentClick()}
            className="comment-blog-accept-comment-button"
          >
            <img src={GreenTickSquare} />
            <p>تایید شده</p>
          </button>

          <button
            onClick={() => declineCommentClick()}
            className="comment-blog-decline-comment-button"
          >
            <img src={RedCloseSquare} />
            <p>رد شده</p>
          </button> */}
        </div>

        <div className="comment-blog-search-input-box">
          <InputComponent
            width="337px"
            height="44px"
            type="text"
            inputType="inputWithIcon"
            iconSide="right"
            iconSrc={SearchLogo}
            backgroundColor="#f7f7f7"
            placeholder="جستجو .."
            borderColor="#dcdcdc"
            onchange={(e) => searchComment(e)}
          />
        </div>
      </div>
      <div className="comment-blog-table-box">
        <div
          className={
            showFilter === false
              ? "comment-blog-table-box-2"
              : "comment-blog-table-box-3"
          }
        >
          <SimpleBar
            forceVisible="x"
            // scrollableNodeProps={{ ref: this.scrollTitleRef }}
            style={{
              width: "100%",
              borderRadius: "10px 10px 0 0",
              borderLeft: "1px solid rgb(220, 220, 220)",
              borderRight: "1px solid rgb(220, 220, 220)",
              borderBottom: "1px solid rgb(220, 220, 220)",
              cursor: "grab",
            }}
          >
            <ScrollDrag innerRef={scrollTitleRef}>
              <div
                style={{ minWidth: "1500px" }}
                className="all-products-header dragscroll"
              >
                <div className="all-products-tab-bar">
                  <div className="all-products-tab-bar-right">
                    <p
                      onClick={() => navigate("/CommentBlog")}
                      style={
                        location.pathname == "/CommentBlog"
                          ? {
                              color: "#00457b",
                              fontWeight: "bold",
                            }
                          : {}
                      }
                    >
                      دیدگاه ها
                    </p>

                    <p
                      onClick={() => navigate("/GarbageBlogComment")}
                      style={
                        location.pathname == "/GarbageBlogComment"
                          ? {
                              color: "#00457b",
                              fontWeight: "bold",
                            }
                          : {}
                      }
                    >
                      زباله دان
                    </p>
                  </div>

                  <div className="all-products-tab-bar-left">
                    <button
                      onClick={() => garbageClick()}
                      className="product-comment-blog-garbage-button"
                    >
                      <img src={AllProductTrashRed} />

                      <p>زباله دان</p>
                    </button>

                    <button
                      onClick={() => acceptCommentClick()}
                      className="product-comment-blog-accept-comment-button"
                    >
                      <img src={WhiteCheck} />
                      <p>تایید </p>
                    </button>

                    <button
                      style={
                        idSelected.blogComment_ids.length > 0
                          ? { backgroundColor: "#f23b3b" }
                          : {}
                      }
                      onClick={() => declineCommentClick()}
                      className="product-comment-blog-decline-comment-button"
                    >
                      <img src={WhiteCross} />
                      <p
                        style={
                          idSelected.blogComment_ids.length > 0
                            ? { color: "#fff" }
                            : {}
                        }
                      >
                        رد کردن
                      </p>
                    </button>
                  </div>
                </div>

                <div className="comment-blog-table-top-bar">
                  <p className="comment-blog-top-bar-hashtag">#</p>
                  <div className="comment-blog-top-bar-checkbox">
                    <label>
                      <img
                        src={
                          idSelected.blogComment_ids.length ===
                            allComment.length && allComment != 0
                            ? headerCheck
                            : uncheck
                        }
                      />
                      <input
                        style={{ display: "none" }}
                        onChange={() => allCheckBoxOnChange()}
                        id="allCheckBox"
                        name="allCheckBox"
                        type="checkbox"
                      />
                    </label>
                  </div>
                  <p className="comment-blog-top-bar-limit-comment">
                    متن دیدگاه
                  </p>
                  <p className="comment-blog-top-bar-blog-title">عنوان بلاگ</p>
                  <p className="comment-blog-top-bar-username">نام کاربری</p>
                  <p className="comment-blog-top-bar-date">تاریخ ثبت</p>
                  <p className="comment-blog-top-bar-situation">وضعیت</p>
                  <p className="comment-blog-top-bar-opation">پاسخ/عملیات</p>
                </div>
              </div>
            </ScrollDrag>
          </SimpleBar>

          <SimpleBar
            forceVisible="x"
            // scrollableNodeProps={{ ref: this.scrollContentRef }}
            style={{
              width: "calc(100% - 3px)",
              borderLeft: "solid 1px #dcdcdc",
              borderRight: "solid 1px #dcdcdc",
              borderBottom: "solid 1px #dcdcdc",
              borderRadius: "0 0 10px 10px",
            }}
          >
            <ScrollDrag
              className="scroll-container"
              innerRef={scrollContentRef}
            >
              {allComment.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={
                      allComment.length != index + 1
                        ? { borderBottom: "solid 1px #dcdcdc" }
                        : {}
                    }
                    className={
                      tableOpen === index
                        ? "comment-blog-item-box-open"
                        : "comment-blog-item-box"
                    }
                  >
                    <div
                      onClick={() => openTableItem(index, item.id)}
                      className="comment-blog-item-detail-box"
                    >
                      <div className="comment-blog-item-number">
                        <p>{from + index}</p>
                      </div>
                      <div className="comment-blog-item-checkbox">
                        <label htmlFor={`id${index}`}>
                          <img
                          onClick={(e) => checkBoxOnChange(e, item.id)}
                            src={
                              idSelected.blogComment_ids.includes(item.id)
                                ? check
                                : uncheck
                            }
                          />
                          {/* <input
                            style={{ display: "none" }}
                            id={`id${index}`}
                            name={`id${index}`}
                            onChange={(e) => checkBoxOnChange(e, item.id)}
                            type="checkbox"
                          /> */}
                        </label>
                      </div>
                      <div className="comment-blog-item-limit-comment">
                        <p>{item.comment_text}</p>
                      </div>
                      <div className="comment-blog-item-blog-title">
                        <p>{item.blog}</p>
                      </div>
                      <div className="comment-blog-item-username">
                        <p>{item.user_name}</p>
                      </div>
                      <div className="comment-blog-item-date">
                        <p>{item.created_at}</p>
                      </div>
                      <div className="comment-blog-item-situation">
                        {item.comment_status === "تایید شده" && (
                          <div className="comment-blog-item-situation-1">
                            <p>{item.comment_status}</p>
                          </div>
                        )}

                        {item.comment_status === "رد شده" && (
                          <div className="comment-blog-item-situation-2">
                            <p>{item.comment_status}</p>
                          </div>
                        )}

                        {item.comment_status === "در انتظار بررسی" && (
                          <div className="comment-blog-item-situation-3">
                            <p>{item.comment_status}</p>
                          </div>
                        )}
                      </div>
                      <div className="comment-blog-item-operation">
                        <img
                          onClick={() => openTableItem(index, item.id)}
                          src={tableOpen === index ? closeTable : openTable}
                        />
                        <img
                          onClick={(e) => delteComment(e, item.id)}
                          src={DeleteRed}
                          // onMouseOver={(e) => (e.currentTarget.src = DeleteRed)}
                          // onMouseOut={(e) => (e.currentTarget.src = Delete)}
                        />
                      </div>
                    </div>
                    {tableOpen === index && (
                      <div className="comment-blog-item-answer-box">
                        <div className="comment-blog-item-answer-title-box">
                          <div>
                            <p>متن کامل دیدگاه</p>
                          </div>
                          <div>
                            <p>پاسخ دهید</p>
                          </div>
                        </div>
                        <div className="comment-blog-item-answer-line"></div>
                        <div className="comment-blog-item-answer-input-box">
                          <div className="comment-blog-item-answer-full-comment-box">
                            <p>{fullComment}</p>
                          </div>
                          <InputComponent
                            reference={answerComment}
                            value={commentTableInfo.comment_text}
                            width="43%"
                            height="200px"
                            type="text"
                            inputType="textarea"
                            placeholder=" پاسخ دیدگاه را بنویسید .."
                            borderColor={
                              errors[index].comment === true
                                ? "#F23B3B"
                                : "#dcdcdc"
                            }
                            onchange={(e) => {
                              setCommentTableInfo((prev) => {
                                return {
                                  comment_text: e.target.value,
                                  blog_id: prev.blog_id,
                                  parent_id: prev.parent_id,
                                };
                              });
                              setErrors((prev) => {
                                prev[index].comment = false;
                                forceUpdate();
                                return prev;
                              });
                            }}
                          />
                          <button
                            onClick={() => sendcommentReply(index)}
                            className="comment-blog-item-answer-send-answer"
                          >
                            <p>ارسال</p>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </ScrollDrag>
          </SimpleBar>

          <div className="comment-blog-paginate-box">
            <Pagination firstAxios={firstAxios} currentAxios={currentAxios} />
          </div>
        </div>
        {showFilter === true && (
          <Filter
            Date={true}
            Situation={true}
            Category={false}
            User={true}
            UserAxios="User?isNotPaginated=1"
            Data={Data}
            Url={Url}
            axiosURL="/blogComment/search"
          />
        )}
      </div>
    </div>
  );
}
