import React, { Component } from "react";
import "../../../assets/styles/TagsStyle/Tags.css";
import { connect } from "react-redux";
import { ShowAlert } from "../../../action/index";
import { PaginateData } from "../../../action/index";
import InputComponent from "../../../components/TextInputComponent/TextInputComponent";
import Pagination from "../../../components/Pagination/Pagination";
import TopBar from "../../../components/TopBar/TopBar";
import SearchLogo from "../../../assets/icons/SearchLogo.svg";
import Delete from "../../../assets/icons/Delete.svg";
import DeleteRed from "../../../assets/icons/DeleteRed.svg";
import Edit from "../../../assets/icons/Edit.svg";
import EditActive from "../../../assets/icons/EditActive.svg";
import Star from "../../../assets/icons/Star.svg";
import axios from "../../../js/Axios/Axios";

class BlogTag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tagData: [],
      numberPage: null,
      numberPagination: [],
      currentpage: null,
      prevPage: null,
      nextPage: null,
      from: null,
      search: null,
      paginationLink: [],
      name_lilname: {
        title: "",
        lil_name: "",
        description: "",
        blogTag_id: "",
      },
      ButtonTittle: "افزودن برچسب جدید",
      lastPage: null,
      firstAxios: "/blogTag?isPaginated=1",
      error: false,
    };

    axios
      .get("/blogTag?isPaginated=1")
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          const tags = res.data.data;
          this.setState({ tagData: tags });
          this.setState({ numberPage: res.data.meta.last_page });
          this.setState({ currentpage: res.data.meta.current_page });
          this.setState({ prevPage: res.data.links.prev });
          this.setState({ nextPage: res.data.links.next });
          this.setState({ from: res.data.meta.from });
          this.setState({ lastPage: res.data.meta.last_page });
          this.forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.PaginateData.data && props.PaginateData.data.length > 0) {
      state.tagData = props.PaginateData.data;
      state.currentpage = props.PaginateData.meta.current_page;
      state.from = props.PaginateData.meta.from;
      state.lastPage = props.PaginateData.meta.last_page;
    }
  }
  searchHandelChange = (e) => {
    this.state.search = e.target.value;
    axios
      .get(`/blogTag?isPaginated=1&page&search=${this.state.search}`)
      .then((res) => {
        if (res.data.status) {
          console.log(res.data.data);
          const tags = res.data.data;
          this.setState({ tagData: tags });
          this.setState({ currentpage: res.data.meta.current_page });
          this.setState({ from: res.data.meta.from });
          this.setState({ prevPage: res.data.links.prev });
          this.setState({ nextPage: res.data.links.next });
          this.setState({ paginationLink: res.data.meta.links });
          this.setState({ lastPage: res.data.meta.last_page });
          this.setState({ firstAxios: `/blogTag?isPaginated=1&page&search=${this.state.search}` });
          this.forceUpdate();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  TagNameHandelChange = (e) => {
    this.state.name_lilname.title = e.target.value;
    this.state.error = false;
    this.forceUpdate();
  };
  TagLilNameHandelChange = (e) => {
    this.state.name_lilname.lil_name = e.target.value;
    this.forceUpdate();
  };

  tagDescriptionHandleChange = (e) => {
    this.state.name_lilname.description = e.target.value;
    this.forceUpdate();

    console.log(this.state.name_lilname.description);
  };

  addTagClick = () => {
    if (this.state.name_lilname.title === "") {
      this.props.dispatch(
        ShowAlert(true, "پر کردن فیلد نام برچسب الزامی است .")
      );
      this.state.error = true;
      this.forceUpdate();
    } else {
      axios
        .post("/blogTag", this.state.name_lilname)
        .then((res) => {
          if (res.data.status === 0) {
            this.props.dispatch(ShowAlert(true, res.data.message));
          }

          if (res.data.status) {
            this.props.dispatch(ShowAlert(true, res.data.message));

            console.log(this.state.name_lilname);

            this.state.ButtonTittle = "افزودن برچسب جدید";
            axios
              .get(
                `/blogTag?isPaginated=1&page=${
                  this.state.currentpage == this.state.lastPage &&
                  this.state.tagData.length === 5 &&
                  this.state.name_lilname.blogTag_id == ""
                    ? this.state.currentpage + 1
                    : this.state.currentpage
                }`
              )
              .then((res) => {
                this.setState({
                  firstAxios: `/blogTag?isPaginated=1&page=
                          ${
                            this.state.currentpage == this.state.lastPage &&
                            this.state.tagData.length === 5 &&
                            this.state.name_lilname.blogTag_id == ""
                              ? this.state.currentpage + 1
                              : this.state.currentpage
                          }`,
                });
                console.log(res);
                this.props.dispatch(PaginateData(res.data));
                const tags = res.data.data;

                this.setState({
                  name_lilname: {
                    title: "",
                    description: "",
                    lil_name: "",
                    blogTag_id: "",
                  },
                });

                this.setState({ tagData: tags });
                this.setState({ numberPage: res.data.meta.last_page });
                this.setState({ currentpage: res.data.meta.current_page });
                this.setState({ prevPage: res.data.links.prev });
                this.setState({ nextPage: res.data.links.next });
                this.setState({ from: res.data.meta.from });
                this.setState({ paginationLink: res.data.meta.links });
                this.setState({ lastPage: res.data.meta.last_page });
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  DeleteTagHandel = (id) => {
    axios
      .delete(`/blogTag/${id}`, id)
      .then((res) => {
        if (res.data.status === 0) {
          this.props.dispatch(ShowAlert(true, res.data.message));
        }
        console.log(res);
        if (res.data.status) {
          this.props.dispatch(ShowAlert(true, res.data.message));
          axios
            .get(
              `/blogTag?isPaginated=1&page=${
                this.state.tagData.length == 1
                  ? this.state.currentpage - 1
                  : this.state.currentpage
              }`
            )
            .then((res) => {
              console.log(res);
              this.setState({
                firstAxios: `/blogTag?isPaginated=1&page=${
                  this.state.tagData.length == 1
                    ? this.state.currentpage - 1
                    : this.state.currentpage
                }`,
              });
              this.props.dispatch(PaginateData(res.data));
              const tags = res.data.data;
              this.setState({ tagData: tags });
              this.setState({ numberPage: res.data.meta.last_page });
              this.setState({ currentpage: res.data.meta.current_page });
              this.setState({ prevPage: res.data.links.prev });
              this.setState({ nextPage: res.data.links.next });
              this.setState({ from: res.data.meta.from });
              this.setState({ paginationLink: res.data.meta.links });
              this.setState({ lastPage: res.data.meta.last_page });
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  EditTagHandel = (id, title, lil_name, description) => {
    this.props.dispatch(
      ShowAlert(true, "برچسب با موفقیت برای ویرایش ارسال گردید")
    );
    this.state.name_lilname.title = title;
    this.state.name_lilname.description = description;
    this.state.name_lilname.lil_name = lil_name;
    this.state.name_lilname.blogTag_id = id;
    this.state.ButtonTittle = "ویرایش برچسب";
    this.forceUpdate();
  };

  render() {
    return (
      <div className="TagsAll">
        <div className="TagsTopBar">
          <TopBar />
        </div>
        <div className="TagsUpTitleAndSearchBox">
          <p className="TagsUpTitleAndSearchBoxTitle">افزودن برچسب جدید</p>
          <InputComponent
            width="337px"
            height="44px"
            type="text"
            inputType="inputWithIcon"
            iconSide="right"
            iconSrc={SearchLogo}
            backgroundColor="#f7f7f7"
            placeholder="جستجو برچسب.."
            borderColor="#dcdcdc"
            onchange={this.searchHandelChange.bind(this)}
          />
        </div>
        <div className="TagsBox">
          <div className="TagAddTagBox">
            <div className="TagAddTagBoxTittleBox">
              <img className="TagAddTagBoxTittleIcon" src={Star} />
              <p className="TagAddTagBoxTittle">
                برچسب های محصولات شما را از اینجا می‌توان مدیریت کرد.
              </p>
            </div>
            <div className="TagAddTagBoxInputBox">
              <div className="TagAddTagBoxInputBoxFirstInputBox">
                <p className="TagAddTagBoxInputBoxFirstInputTittle">
                  نام برچسب :
                </p>
                <InputComponent
                  value={this.state.name_lilname.title}
                  width="calc(100% - 130px)"
                  height="44px"
                  type="text"
                  placeholder="برای نمایش به کاربران سایت"
                  borderColor={
                    this.state.error === true ? "#F23B3B" : "#dcdcdc"
                  }
                  inputType="input"
                  onchange={this.TagNameHandelChange.bind(this)}
                />
              </div>

              <div className="TagAddTagBoxInputBoxSecondInputBox">
                <p className="TagAddTagBoxInputBoxSecondInputTittle">
                  {" "}
                  نامک :{" "}
                </p>
                <InputComponent
                  value={this.state.name_lilname.lil_name}
                  width="calc(100% - 130px)"
                  height="44px"
                  type="text"
                  placeholder="باید انگلیسی بوده و کوتاهتر از 28 کاراکتر باشد"
                  borderColor="#dcdcdc"
                  inputType="input"
                  onchange={this.TagLilNameHandelChange}
                />
              </div>

              <div className="CategoryAddCategoryBoxTextAreaBox">
                <p className="CategoryAddCategoryBoxTextAreaBoxTittle">
                  توضیحات :
                </p>
                <InputComponent
                  value={this.state.name_lilname.description}
                  width="80%"
                  height="100%"
                  type="text"
                  placeholder="توضیحات دسته بندی "
                  borderColor="#dcdcdc"
                  inputType="textarea"
                  onchange={this.tagDescriptionHandleChange}
                />
              </div>

              <div className="TagAddTagButtonBox">
                <button
                  onClick={this.addTagClick.bind(this)}
                  //   disabled={this.state.name_lilname.title != "" ? false : true}
                  className="TagAddTagButtonActive"
                >
                  {this.state.ButtonTittle}
                </button>
              </div>
            </div>
          </div>

          <div className="TagsShowBox">
            <div className="TagsShowBox2">
              <div className="TagshowBox2TopBar">
                <p className="TagshowBox2TopBarTittle5">#</p>
                <p className="TagshowBox2TopBarTittle4">نام</p>
                <p className="TagshowBox2TopBarTittle3">نامک</p>
                <p className="TagshowBox2TopBarTittle2">تعداد</p>
                <p className="TagshowBox2TopBarTittle1">عملیات</p>
              </div>

              {this.state.tagData.map((myitem, myindex) => {
                return (
                  <div
                    key={myindex}
                    style={
                      this.state.tagData.length == myindex + 1
                        ? {
                            borderRadius: "0 0 10px 10px",
                            borderBottom: "1px solid #00589c",
                          }
                        : {}
                    }
                    className="TagshowBox2ItemBox"
                  >
                    <div className="TagshowBox2ItemNumberListBox">
                      <p className="TagshowBox2ItemNumberListTittle">
                        {this.state.from + myindex}
                      </p>
                    </div>
                    <div className="TagshowBox2ItemNameBox">
                      <p className="TagshowBox2ItemNameTittle">
                        {myitem.title}
                      </p>
                    </div>
                    <div className="TagshowBox2ItemLilNameBox">
                      <p className="TagshowBox2ItemLilNameTittle">
                        {myitem.lil_name}
                      </p>
                    </div>
                    <div className="TagshowBox2ItemNumberBox">
                      <p className="TagshowBox2ItemNumberTittle">
                        {myitem.blogs_count}
                      </p>
                    </div>
                    <div className="TagshowBox2ItemBoxIconBox">
                      <button
                        onClick={this.EditTagHandel.bind(
                          this,
                          myitem.id,
                          myitem.title,
                          myitem.lil_name,
                          myitem.description
                        )}
                        className="TagshowBox2ItemBoxIconEditButton"
                      >
                        <img
                          src={EditActive}
                          // onMouseOver={(e) =>
                          //   (e.currentTarget.src = EditActive)
                          // }
                          // onMouseOut={(e) => (e.currentTarget.src = Edit)}
                        />
                      </button>

                      <button
                        onClick={this.DeleteTagHandel.bind(this, myitem.id)}
                        className="TagshowBox2ItemBoxIconDelteButton"
                      >
                        <img
                          src={DeleteRed}
                          // onMouseOver={(e) => (e.currentTarget.src = DeleteRed)}
                          // onMouseOut={(e) => (e.currentTarget.src = Delete)}
                        />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="TagsPaginationBox">
              <Pagination
                firstAxios={this.state.firstAxios}
                currentAxios="/blogTag?isPaginated=1&page="
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    PaginateData: state.PaginatedData,
  };
};
export default connect(mapStateToProps)(BlogTag);
