import React, { useState } from "react";
import "../../../assets/styles/SetNewPassword/SetNewPassword.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import { ShowAlert } from "../../../action/index";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "../../../js/Axios/Axios";
import { useSelector, useDispatch } from "react-redux";
import { AddAdminInfo } from "../../../action/index";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import lock from "../../../assets/icons/Lock-login.svg";
import verifyCode from "../../../assets/icons/verifyCode.svg";
import useForceUpdate from "use-force-update";
function SetNewPassword() {
  const dispatch = useDispatch();
  const forcUpdate = useForceUpdate();
  const user_info = useSelector((state) => state.AddAdminInfo);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [activityIndicator, setActivityIndicator] = useState(false);
  const [NewPassword, SetNewPassword] = useState({
    phone_number: user_info.user_info,
    verifyCode: "",
    password: "",
    password_confirmation: "",
  });
  const sendnewpassword = () => {
    setActivityIndicator(true)
    forcUpdate()
    axios
      .post("/UserSystem/forgetPass/changePass", NewPassword)
      .then((res) => {
        if (res.data.status === 0) {
          dispatch(ShowAlert(true, res.data.message));
          setActivityIndicator(false)
          forcUpdate()
        }
        console.log(res);
        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));
          dispatch(AddAdminInfo(NewPassword));
          setActivityIndicator(false)
          forcUpdate()
          navigate("/");
        }
      })
      .catch((e) => {
        console.log(e);
        setActivityIndicator(false)
        forcUpdate()
      });
  };
  const showpasswordClick = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
    forcUpdate();
  };
  const showConfirmPasswordClick = (e) => {
    e.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
    forcUpdate();
  };
  const sendnewpasswordEnter = (e) => {
    if (e.key  === "Enter") {
      sendnewpassword();
    }
  }

  return (
    <div className="NewPasswordAll">
      <div className="aut-img">{/* <img  src={login_img}/> */}</div>
      <div className="NewPasswordBox">
        <h3 className="AutLoginTittleBox">بازیابی رمز عبور</h3>
        <p className="AutLoginDEtails">
          اطلاعات کاربری خود را در فیلدهای زیر نوشته و وارد شوید
        </p>
        <hr className="AutLoginEdge" />
        <form className="NewPasswordInputsBox1">
          <div className="NewPasswordInputsBox">
            <label className="NewPasswordInputsLable">
              <img className="NewPasswordInputsLable-img" src={verifyCode} />
              <input
                onChange={(e) =>
                  SetNewPassword((prev) => {
                    return {
                      phone_number: prev.phone_number,
                      verifyCode: e.target.value,
                      password: prev.password,
                      password_confirmation: prev.password_confirmation,
                    };
                  })
                }
                className="NewPasswordMessageNumberInput"
                placeholder="کد بازیابی"
              />
            </label>
            <label className="NewPasswordInputsLable">
              <img src={lock} />
              <input
                onChange={(e) =>
                  SetNewPassword((prev) => {
                    return {
                      phone_number: prev.phone_number,
                      verifyCode: prev.verifyCode,
                      password: e.target.value,
                      password_confirmation: prev.password_confirmation,
                    };
                  })
                }
                type={showPassword === true ? "text" : "password"}
                className="NewPasswordNewPasswordInput"
                placeholder="رمز عبور جدید"
              />
              <button
                className="AutLoginSecondInputIconButton"
                onClick={(e) => showpasswordClick(e)}
              >
                {showPassword === false ? (
                  <VisibilityOffIcon className="AutLoginSecondInputIcon" />
                ) : (
                  <VisibilityIcon className="AutLoginSecondInputIcon" />
                )}
              </button>
            </label>
            <label className="NewPasswordInputsLable">
              <img src={lock} />
              <input
                onChange={(e) =>
                  SetNewPassword((prev) => {
                    return {
                      phone_number: prev.phone_number,
                      verifyCode: prev.verifyCode,
                      password: prev.password,
                      password_confirmation: e.target.value,
                    };
                  })
                }
                type={showConfirmPassword === true ? "text" : "password"}
                className="NewPasswordNewPasswordAgainInput"
                placeholder="تکرار رمز عبور جدید"
              />
              <button
                className="AutLoginSecondInputIconButton"
                onClick={(e) => showConfirmPasswordClick(e)}
              >
                {showConfirmPassword === false ? (
                  <VisibilityOffIcon className="AutLoginSecondInputIcon" />
                ) : (
                  <VisibilityIcon className="AutLoginSecondInputIcon" />
                )}
              </button>
            </label>
          </div>
        </form>
        <div className="NewPassWordChangePasswordBox">
          <button
          onKeyDown={(e)=>sendnewpasswordEnter(e)}
            onClick={sendnewpassword}
            className="NewPassWordChangePasswordButton"
          >
            {activityIndicator === false ? (
              "تغییر رمز"
            ) : (
              <CircularProgress className="aut-button-progress" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
export default SetNewPassword;
