import React, { useEffect, useRef, useState } from "react";
import "../../assets/styles/MessageStyle/Message.css";
import axios from "../../js/Axios/Axios";
import TopBar from "../../components/TopBar/TopBar";
import InputComponent from "../../components/TextInputComponent/TextInputComponent";
import Pagination from "../../components/Pagination/Pagination";
import Filter from "../../components/Filter/Filter";
import { ShowAlert } from "../../action/index";
import useForceUpdate from "use-force-update";
import { useSelector, useDispatch } from "react-redux";
import SearchLogo from "../../assets/icons/SearchLogo.svg";
import GarbageDelte from "../../assets/icons/GarbageDelte.svg";
import headerCheck from "../../assets/icons/headerCheck.svg";
import check from "../../assets/icons/check.svg";
import uncheck from "../../assets/icons/uncheck.svg";
import openTable from "../../assets/icons/openTable.svg";
import closeTable from "../../assets/icons/closeTable.svg";
import Delete from "../../assets/icons/Delete.svg";
import DeleteRed from "../../assets/icons/DeleteRed.svg";
import FilterLogo from "../../assets/icons/Filter.svg"
import SimpleBar from "simplebar-react";
import ScrollDrag from "react-indiana-drag-scroll";

export default function Messages() {
  const answerRef = useRef();
  const scrollTitleRef = useRef();
  const scrollContentRef = useRef();
  const [error, setError] = useState({
    answer: false,
  });
  const paginatedData = useSelector((state) => state.PaginatedData);
  const user_id = useSelector((state) => state.UserFilter);
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch();
  const [tableOpen, setTableOpen] = useState(null);
  const [allMessage, setAllMessage] = useState([]);
  const [currentPage, setCurrentPage] = useState(null); // currentpage for paginated
  const [from, setFrom] = useState(null); // from to paginated
  const [relatedMessage, setRelatedMessage] = useState(null);
  const [messageID, setMessageID] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [checkIds, setCheckIds] = useState([]);
  const [firstAxios, setFirstAxios] = useState("/UserMessage");
  const [answer, setAnswer] = useState({
    message_status: "پاسخ داده",
    message_answer: null,
  });
  // useeffect for SimpleBar and ScrollDrag
  useEffect(() => {
    // set scroll position title and content for together

    const handleScrollTitle = (e) => {
      scrollContentRef.current.scrollLeft = scrollTitleRef.current.scrollLeft;
    };

    scrollTitleRef.current.addEventListener("scroll", handleScrollTitle);

    const handleScrollContent = (e) => {
      scrollTitleRef.current.scrollLeft = scrollContentRef.current.scrollLeft;
    };
    scrollContentRef.current.addEventListener("scroll", handleScrollContent);
  }, []);

  useEffect(() => {
    if (user_id.nameid === "message") {
      setShowFilter(true);
    }
  }, [user_id]);
  useEffect(() => {
    if (user_id.id === null) {
      axios
        .get("/UserMessage")
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            setAllMessage(res.data.data);
            setCurrentPage(res.data.meta.current_page);
            setFrom(res.data.meta.from);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);
  useEffect(() => {
    if (paginatedData.length != 0) {
      setAllMessage(paginatedData.data);
      setCurrentPage(paginatedData.meta.current_page);
      setFrom(paginatedData.meta.from);
    }
  }, [paginatedData]);
  const Data = (data) => {
    console.log(data);
    setAllMessage(data.data);
    setFrom(data.meta.from);
    setCurrentPage(data.meta.current_page);
  };
  const Url = (Url) => {
    setFirstAxios(Url);
  };
  const openTableClick = (index, id) => {
    if (tableOpen === index) {
      setError((prev) => {
        prev.answer = false;
        forceUpdate();
        return prev;
      });
      setTableOpen(null);
      setRelatedMessage(null);
      setAnswer((prev) => {
        return {
          message_status: prev.message_status,
          message_answer: null,
        };
      });
    } else {
      setError((prev) => {
        prev.answer = false;
        forceUpdate();
        return prev;
      });
      setRelatedMessage(null);
      setAnswer((prev) => {
        return {
          message_status: prev.message_status,
          message_answer: null,
        };
      });
      setTableOpen(index);
      setMessageID(id);
      axios
        .get(`/UserMessage/${id}/edit`)
        .then((res) => {
          if (res.data.status) {
            console.log(res);
            setRelatedMessage(res.data.data);

            setAnswer((prev) => {
              return {
                message_status: prev.message_status,
                message_answer: res.data.data.message_answer,
              };
            });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const shareMessage = () => {
    if (answer.message_answer != null && answer.message_answer != "") {
      axios
        .put(`/UserMessage/${messageID}`, answer)
        .then((res) => {
          console.log(res);
          if (res.data.status) {
            setTableOpen(null);
            setRelatedMessage(null);
            setAnswer((prev) => {
              return {
                message_status: prev.message_status,
                message_answer: null,
              };
            });

            axios
              .get(`/UserMessage?page=${currentPage}`)
              .then((res) => {
                if (res.data.status) {
                  console.log(res);
                  setAllMessage(res.data.data);
                  setCurrentPage(res.data.meta.current_page);
                  setFrom(res.data.meta.from);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      dispatch(
        ShowAlert(true, "برای ارسال پاسخ پر کردن فیلد پاسخ الزامی است .")
      );
      setError((prev) => {
        prev.answer = true;
        forceUpdate();
        return prev;
      });
    }
  };
  const searchMessage = (e) => {
    axios
      .get(`/UserMessage?search=${e.target.value}`)
      .then((res) => {
        if (res.data.status) {
          console.log(res);
          setAllMessage(res.data.data);
          setCurrentPage(res.data.meta.current_page);
          setFrom(res.data.meta.from);
          setFirstAxios(`/UserMessage?search=${e.target.value}`);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const shoeFilterOnClick = () => {
    setShowFilter(!showFilter);
  };
  const checkBoxOnchange = (id) => {
    if (!checkIds.includes(id)) {
      checkIds.push(id);
    } else {
      var index = checkIds.indexOf(id);
      checkIds.splice(index, 1);
    }
    forceUpdate();
  };
  const allCheckBoxOnChange = () => {
    if (checkIds.length === allMessage.length) {
      allMessage.map((item, i) => {
        checkIds.splice(i);
      });
    } else if (checkIds.length !== allMessage.length) {
      allMessage.map((item) => {
        if (!checkIds.includes(item.userMessage_id)) {
          checkIds.push(item.userMessage_id);
        }
      });
    }
    forceUpdate();
  };
  const allDelteMessage = () => {
    if (checkIds.length != 0) {
      let array = JSON.stringify(checkIds);
      axios
        .delete(`/UserMessage/deleteMultiple?userMessage_ids=${array}`)
        .then((res) => {
          if (res.data.status) {
            dispatch(ShowAlert(true, res.data.message));
            axios
              .get(`/UserMessage?page=${checkIds.length === allMessage.length ? currentPage -1 : currentPage}`)
              .then((res) => {
                if (res.data.status) {
                  console.log(res);
                  setFirstAxios(`/UserMessage?page=${checkIds.length === allMessage.length ? currentPage -1 : currentPage}`)
                  checkIds.map((iten , i) => {
                    checkIds.splice(i , 1)
                  })
                  setAllMessage(res.data.data);
                  setCurrentPage(res.data.meta.current_page);
                  setFrom(res.data.meta.from);
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  const deleteOneMessage = (id) => {
    let ids = [];
    ids.push(id);
    let array = JSON.stringify(ids);
    axios
      .delete(`/UserMessage/deleteMultiple?userMessage_ids=${array}`)
      .then((res) => {
        if (res.data.status) {
          dispatch(ShowAlert(true, res.data.message));
          console.log(res);
          axios
            .get(`/UserMessage?page=${allMessage.length === ids.length ? currentPage - 1 : currentPage} `)
            .then((res) => {
              if (res.data.status) {
                console.log(res);
                setFirstAxios(`/UserMessage?page=${allMessage.length === ids.length ? currentPage - 1 : currentPage} `)
                setAllMessage(res.data.data);
                setCurrentPage(res.data.meta.current_page);
                setFrom(res.data.meta.from);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="messages-all">
      <div className="messages-top-bar">
        <TopBar />
      </div>
      <div className="messages-buttons-serach-box">
        <div className="messages-buttons-box">
          <button
            onClick={() => shoeFilterOnClick()}
            className="message-filter-button"
          >
            <img src={FilterLogo}/>
            {showFilter === false ? <p>نمایش فیلتر</p> : <p>بستن فیلتر</p>}
          </button>
          <button
            onClick={() => allDelteMessage()}
            className="message-delte-button"
          >
            <img src={GarbageDelte} />
            <p>حذف</p>
          </button>
        </div>
        <InputComponent
          width="337px"
          height="44px"
          type="text"
          inputType="inputWithIcon"
          iconSide="right"
          iconSrc={SearchLogo}
          backgroundColor="#f7f7f7"
          placeholder=" جستجو پیغام.."
          borderColor="#dcdcdc"
          onchange={(e) => searchMessage(e)}
        />
      </div>
      <div className="message-table-box">
        <div
          className={
            showFilter === false ? "message-table-box-2" : "message-table-box-3"
          }
        >
          <SimpleBar
            forceVisible="x"
            // scrollableNodeProps={{ ref: this.scrollTitleRef }}
            style={{
              width: "100%",
              borderRadius: "10px 10px 0 0",
              cursor: "grab",
            }}
          >
            <ScrollDrag innerRef={scrollTitleRef}>
              <div className="message-table-top-bar">
                <div className="message-table-top-bar-hashtag">
                  <p>#</p>
                </div>
                <div className="message-table-top-bar-checkbox">
                  <label htmlFor="allCheckBox">
                    <input
                      style={{ display: "none" }}
                      onChange={() => allCheckBoxOnChange()}
                      id="allCheckBox"
                      name="allCheckBox"
                      type="checkbox"
                    />
                    <img
                      src={
                        checkIds.length === allMessage.length &&
                        allMessage.length != 0
                          ? headerCheck
                          : uncheck
                      }
                    />
                  </label>
                </div>
                <div className="message-table-top-bar-text-message">
                  <p>متن پیغام</p>
                </div>
                <div className="message-table-top-bar-full-name">
                  <p>نام و نام خانوادگی</p>
                </div>
                <div className="message-table-top-bar-phone-number">
                  <p>موبایل</p>
                </div>
                <div className="message-table-top-bar-email">
                  <p>ایمیل</p>
                </div>
                <div className="message-table-top-bar-situation">
                  <p>وضعیت</p>
                </div>
                <div className="message-table-top-bar-operation">
                  <p>پاسخ/عملیات</p>
                </div>
              </div>
            </ScrollDrag>
          </SimpleBar>

          <SimpleBar
            forceVisible="x"
            // scrollableNodeProps={{ ref: this.scrollContentRef }}
            style={{
              width: "calc(100% - 3px)",
              borderLeft: "solid 1px #dcdcdc",
              borderRight: "solid 1px #dcdcdc",
              borderBottom: "solid 1px #dcdcdc",
              borderRadius: "0 0 10px 10px",
            }}
          >
            <ScrollDrag
              className="scroll-container"
              innerRef={scrollContentRef}
            >
              {allMessage.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={
                      allMessage.length == index + 1
                        ? { borderRadius: "0 0 10px 10px" }
                        : { borderBottom: "1px solid #dcdcdc" }
                    }
                    className={
                      tableOpen === index
                        ? "message-table-item-box-2"
                        : "message-table-item-box"
                    }
                  >
                    <div
                      onClick={() => openTableClick(index, item.userMessage_id)}
                      className="message-table-item-box-3"
                    >
                      <div className="message-table-item-box-hashtag">
                        <p>{from + index}</p>
                      </div>

                      <div className="message-table-item-box-checkbox">
                        <label
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          htmlFor={`checkbox${index}`}
                        >
                          <input
                            style={{ display: "none" }}
                            onChange={() =>
                              checkBoxOnchange(item.userMessage_id)
                            }
                            type="checkbox"
                            name={`checkbox${index}`}
                            id={`checkbox${index}`}
                          />
                          <img
                            src={
                              checkIds.includes(item.userMessage_id)
                                ? check
                                : uncheck
                            }
                          />
                        </label>
                      </div>
                      <div className="message-table-item-box-text-message">
                        <p>{item.message_text}</p>
                      </div>
                      <div className="message-table-item-box-full-name">
                        <p>{item.full_name}</p>
                      </div>
                      <div className="message-table-item-box-phone-number">
                        <p>{item.phone_number}</p>
                      </div>
                      <div className="message-table-item-box-email">
                        <p>{item.email}</p>
                      </div>
                      <div className="message-table-item-box-situation">
                        <div
                          className={
                            item.message_status === "در انتظار پاسخ"
                              ? "message-table-item-box-situation-2"
                              : "message-table-item-box-situation-3"
                          }
                        >
                          <p>{item.message_status}</p>
                        </div>
                      </div>
                      <div className="message-table-item-box-operation">
                        <img
                          onClick={() =>
                            openTableClick(index, item.userMessage_id)
                          }
                          src={openTable}
                        />
                        <img
                          onClick={() => deleteOneMessage(item.userMessage_id)}
                          src={DeleteRed}
                          // onMouseOver={(e) => (e.currentTarget.src = DeleteRed)}
                          // onMouseOut={(e) => (e.currentTarget.src = Delete)}
                        />
                      </div>
                    </div>
                    {tableOpen === index && relatedMessage != null && (
                      <div className="message-table-item-answer-box">
                        <div className="message-table-item-answer-title-box">
                          <p className="message-table-item-answer-title-1">
                            متن کامل سوال
                          </p>
                          <p className="message-table-item-answer-title-2">
                            پاسخ دهید
                          </p>
                        </div>
                        <div className="message-table-item-answer-line"></div>
                        <div className="message-table-item-answer-box-2">
                          <div className="message-table-item-answer-full-message-box">
                            <p>{relatedMessage.message_text}</p>
                          </div>
                          <InputComponent
                            reference={answerRef}
                            value={answer.message_answer}
                            width="45%"
                            height="165px"
                            type="text"
                            zIndex="1000"
                            inputType="textarea"
                            placeholder="پاسخ پیام را بنویسید.."
                            borderColor={
                              error.answer === true ? "#F23B3B" : "#dcdcdc"
                            }
                            onchange={(e) => {
                              setAnswer((prev) => {
                                return {
                                  message_answer: e.target.value,
                                  message_status: prev.message_status,
                                };
                              });
                              setError((prev) => {
                                prev.answer = false;
                                forceUpdate();
                                return prev;
                              });
                            }}
                          />
                          <button
                            onClick={() => shareMessage()}
                            className="message-table-item-answer-button"
                          >
                            <p>ارسال</p>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </ScrollDrag>
          </SimpleBar>

          <div className="message-pagination-box">
            <Pagination
              firstAxios={firstAxios}
              currentAxios="/UserMessage?page="
            />
          </div>
        </div>
        {showFilter === true && (
          <Filter
            Date={true}
            Situation={true}
            Category={false}
            User={true}
            UserAxios="User?isNotPaginated=1"
            Data={Data}
            Url={Url}
            axiosURL="/UserMessage"
          />
        )}
      </div>
    </div>
  );
}
