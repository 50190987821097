import React from "react";
import "../../assets/styles/VisitorStatisticsStyle/VisitorStatistics.css";
import TopBar from "../../components/TopBar/TopBar";
import VisitorStatisticsChart from "../../components/VisitorStatisticsComponents/VisitorStatisticsChart";
import Filter from "../../components/Filter/Filter";
import VisitorStatisticsSignUp from "../../components/VisitorStatisticsComponents/VisitorStatisticsSignUp";
import VisitorStatisticsWords from "../../components/VisitorStatisticsComponents/VisitorStatisticsWords";
import VisitorStatisticsIranChart from "../../components/VisitorStatisticsComponents/VisitorStatisticsIranChart";
import VisitorStatisticsMostState from "../../components/VisitorStatisticsComponents/VisitorStatisticsMostState";
import VisitorStatisticsMostPage from "../../components/VisitorStatisticsComponents/VisitorStatisticsMostPage";
import VisitorStatisticsMostCity from "../../components/VisitorStatisticsComponents/VisitorStatisticsMostCity";
import VisitorStatisticsDevices from "../../components/VisitorStatisticsComponents/VisitorStatisticsDevices";
import VisitorStatisticsSites from "../../components/VisitorStatisticsComponents/VisitorStatisticsSites";
import VisitorStatisticsBestCustomer from "../../components/VisitorStatisticsComponents/VisitorStatisticsBestCustomer";
export default function VisitorStatistics() {
  return (
    <div className="visitor-statistics-all">
      <TopBar />
      <div className="visitor-statistics-all-box">
        <div className="visitor-statistics-all-box-2">
          <div className="visitor-statistics-all-box-3">
            <div className="visitor-statistics-filter-box">
              <Filter
                width="800px"
                Date={false}
                Category={false}
                Situation={true}
                //    CategoryAxios={"/blogCategory"}
                //   Data={Data}
                axiosURL="/Discount"
              />
            </div>
            <div className="visitor-statistics-user-sign-up-box">
              <VisitorStatisticsSignUp />
            </div>
          </div>
          <div className="visitor-statistics-all-box-chart-box">
            <VisitorStatisticsChart />
          </div>
        </div>
        <div className="visitor-statistics-all-box-4">
          <div className="visitor-statistics-most-word-box">
            <VisitorStatisticsWords />
          </div>
          <div className="visitor-statistics-most-iran-chart">
            <VisitorStatisticsIranChart />
          </div>
        </div>
        <div className="visitor-statistics-all-box-5">
          <div className="visitor-statistics-all-most-state-box">
            <VisitorStatisticsMostState />
          </div>
          <div className="visitor-statistics-all-most-page-box">
            <VisitorStatisticsMostPage />
          </div>
        </div>
        <div className="visitor-statistics-all-box-6">
          <div className="visitor-statistics-all-box-7">
            <div className="visitor-statistics-all-box-9">
              <VisitorStatisticsMostCity />
            </div>
            <div className="visitor-statistics-all-box-10">
              <VisitorStatisticsDevices />
            </div>
            <div className="visitor-statistics-all-box-11">
              <VisitorStatisticsSites />
            </div>
          </div>
          <div className="visitor-statistics-all-box-8">
            <VisitorStatisticsBestCustomer />
          </div>
        </div>
      </div>
    </div>
  );
}
