import React, { useEffect, useState } from "react";
import "../../assets/styles/IntroductionProductionStyle/IntroductionProduction.css";
import InputComponent from "../TextInputComponent/TextInputComponent";
import Barcode from "../../assets/icons/Barcode.svg";
import axios from "../../js/Axios/Axios";
import { useSelector, useDispatch } from "react-redux";
import { ProductInfo } from "../../action/index";
import { isObjectEmpty } from "../../helper/Helper";
import { useLocation } from "react-router-dom";
import useForceUpdate from "use-force-update";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

function IntroductionProduction() {
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const location = useLocation();
  const ProductInfoForEdit = useSelector((state) => state.ProductInfoForEdit);
  const Error = useSelector((state) => state.Error);
  const [error, setError] = useState({
    title: false,
  });
  const [productInfo, setProductInfo] = useState({
    title: "",
    latin_title: "",
    url: "",
    country: "",
    brand_name: "",
    product_introduction: "",
    barcode: "",
    temp_status: null,
  });
  const createBarcodeClick = () => {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 10; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    setProductInfo((prev) => {
      return {
        title: prev.title,
        latin_title: prev.latin_title,
        brand_name: prev.brand_name,
        url: prev.url,
        country: prev.country,
        barcode: text,
        product_introduction: prev.product_introduction,
        temp_status: prev.temp_status,
      };
    });
    let barcodeInput = document.getElementById("barcodeInput");
    barcodeInput.value = text;
    forceUpdate();
  };

  useEffect(() => {
    dispatch(ProductInfo(productInfo));
    return () => {};
  }, [productInfo]);

  useEffect(() => {
    if (
      !isObjectEmpty(ProductInfoForEdit) &&
      (location.pathname === "/AllProductUpdate" ||
        location.pathname === "/AllDraftProductUpdate")
    ) {
      setProductInfo((prev) => {
        return {
          title: ProductInfoForEdit.state.title,
          latin_title: ProductInfoForEdit.state.latin_title,
          brand_name: ProductInfoForEdit.state.brand_name,
          url: ProductInfoForEdit.state.url,
          country: ProductInfoForEdit.state.country,
          barcode: ProductInfoForEdit.state.barcode,
          product_introduction: ProductInfoForEdit.state.product_introduction,
          temp_status: ProductInfoForEdit.state.temp_status,
        };
      });
      console.log(ProductInfoForEdit);
      forceUpdate();
    }
  }, [ProductInfoForEdit]);

  useEffect(() => {
    if (Error.productTittle === true) {
      setError((prev) => {
        prev.title = true;
        forceUpdate();
        return prev;
      });
    }
  }, [Error]);

  return (
    <div className="IntroductionBox2">
      <div className="IntroductionTittleBox">
        <p className="IntroductionTittle">معرفی محصول</p>
      </div>
      <div className="IntroductionBox3">
        <div className="IntroductionBox5">
          <div className="IntroductionBox6">
            <InputComponent
              value={productInfo.title}
              width="calc(95% - 20px)"
              height="44px"
              placeholder="عنوان محصول"
              borderColor={error.title === true ? "#F23B3B" : "#dcdcdc"}
              inputType="input"
              type="text"
              onchange={(e) => {
                setProductInfo((prev) => {
                  return {
                    title: e.target.value,
                    latin_title: prev.latin_title,
                    brand_name: prev.brand_name,
                    url: prev.url,
                    country: prev.country,
                    barcode: prev.barcode,
                    product_introduction: prev.product_introduction,
                    temp_status: prev.temp_status,
                  };
                });
                setError((prev) => {
                  prev.title = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
            <InputComponent
              value={productInfo.latin_title}
              width="calc(95% - 20px)"
              height="44px"
              type="text"
              inputType="input"
              placeholder="عنوان محصول به لاتین"
              borderColor="#dcdcdc"
              onchange={(e) =>
                setProductInfo((prev) => {
                  return {
                    title: prev.title,
                    latin_title: e.target.value,
                    url: prev.url,
                    country: prev.country,
                    barcode: prev.barcode,
                    brand_name: prev.brand_name,
                    product_introduction: prev.product_introduction,
                    temp_status: prev.temp_status,
                  };
                })
              }
            />
          </div>
          <div className="IntroductionBox7">
            <div className="IntroductionBox8">
              <InputComponent
                value={productInfo.brand_name}
                width="calc(47% - 20px)"
                height="44px"
                type="text"
                inputType="input"
                placeholder="نام برند"
                borderColor="#dcdcdc"
                onchange={(e) =>
                  setProductInfo((prev) => {
                    return {
                      title: prev.title,
                      latin_title: prev.latin_title,
                      url: prev.url,
                      country: prev.country,
                      barcode: prev.barcode,
                      brand_name: e.target.value,
                      product_introduction: prev.product_introduction,
                      temp_status: prev.temp_status,
                    };
                  })
                }
              />
              <InputComponent
                margin="0 25px 0 0"
                value={productInfo.country}
                width="calc(48% - 20px)"
                height="44px"
                type="text"
                placeholder=" کشور سازنده "
                borderColor="#dcdcdc"
                inputType="input"
                onchange={(e) =>
                  setProductInfo((prev) => {
                    return {
                      title: prev.title,
                      latin_title: prev.latin_title,
                      url: prev.url,
                      country: e.target.value,
                      barcode: prev.barcode,
                      brand_name: prev.brand_name,
                      product_introduction: prev.product_introduction,
                      temp_status: prev.temp_status,
                    };
                  })
                }
              />
            </div>
            <div className="IntroductionBox7Input1Box">
              <InputComponent
                value={productInfo.barcode}
                width="calc(95% - 20px)"
                height="44px"
                type="text"
                id={"barcodeInput"}
                placeholder="شناسه محصول "
                borderColor="#dcdcdc"
                inputType="inputWithIcon"
                iconSide="left"
                onClick={() => createBarcodeClick()}
                iconSrc={Barcode}
                onchange={(e) =>
                  setProductInfo((prev) => {
                    return {
                      title: prev.title,
                      latin_title: prev.latin_title,
                      brand_name: prev.brand_name,
                      url: prev.url,
                      barcode: e.target.value,
                      country: prev.country,
                      product_introduction: prev.product_introduction,
                      temp_status: prev.temp_status,
                    };
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="IntroductionBox4">
          <InputComponent
            className="IntroductionBox4-input"
            value={productInfo.product_introduction}
            width="95%"
            height="calc(100% - 74px)"
            type="text"
            inputType="textarea"
            placeholder="درباره محصول&nbsp;(شرح مختصری کمتر از 1000 حرف در مورد محصول بنویسید)"
            borderColor="#dcdcdc"
            onchange={(e) =>
              setProductInfo((prev) => {
                return {
                  title: prev.title,
                  latin_title: prev.latin_title,
                  brand_name: prev.brand_name,
                  url: prev.url,
                  barcode: prev.barcode,
                  country: prev.country,
                  product_introduction: e.target.value,
                  temp_status: prev.temp_status,
                };
              })
            }
          />

          <div className="IntroductionBox9">
            <InputComponent
              value={productInfo.url}
              width="45%"
              height="44px"
              type="text"
              placeholder="URL"
              borderColor="#dcdcdc"
              inputType="input"
              onchange={(e) =>
                setProductInfo((prev) => {
                  return {
                    title: prev.title,
                    latin_title: prev.latin_title,
                    url: e.target.value,
                    country: prev.country,
                    barcode: prev.barcode,
                    brand_name: prev.brand_name,
                    product_introduction: prev.product_introduction,
                    temp_status: prev.temp_status,
                  };
                })
              }
            />
            <div className="IntroductionBox9link">
              <p className="IntroductionBox9linkTittle2">پیوند یکتا :</p>

              <SimpleBar
                forceVisible="x"
                style={{
                  width: "calc(100% - 90px)",
                  // height : "100%"
                }}
              >
                <p className="IntroductionBox9linkTittle1">
                  {`http://vitsell.vistaapp.ir/${productInfo.url}`}
                </p>
              </SimpleBar>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default IntroductionProduction;
