import React from "react";
import { useState } from "react";
import useForceUpdate from "use-force-update";
import ArrowDown from "../../assets/icons/ArrowDown.svg";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
function SelectBox(props) {
  const { label, data, width, height, backgroundColor, border, heightBox } = props;
  const forceUpdate = useForceUpdate();
  const [isOpen, setIsOpen] = useState(false);
  const openDropDown = () => {
    setIsOpen(!isOpen);
    forceUpdate();
};
const itemClick = (key) => {
    props.itemClik(key);
    setIsOpen(!isOpen);
    forceUpdate();
  }
  return (
    <div
      style={{ display: "flex", flexDirection: "column", position: "relative" }}
    >
      <div
        onClick={() => openDropDown()}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: `${width != undefined ? width : "100%"} `,
          height: `${height != undefined ? height : "39px"}`,
          backgroundColor: `${
            backgroundColor != undefined ? backgroundColor : "#fff"
          }`,
          borderRadius: `${isOpen === false ? "10px" : "10px 10px 0px 0px"}`,
          border: `${border != undefined && border}`,
          padding: "0px 10px 0px 10px",
          cursor:"pointer"
        }}
      >
        <p
          style={{
            fontFamily: "IRANSansFaNum",
            fontSize: "16px",
            fontWeight: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: "left",
            color: "#656565",
            cursor:"pointer"
          }}
        >
          {label != undefined ? label : ""}
        </p>
        <img  
        style={{
            transform: `${isOpen === true ? "rotate(180deg)" : "rotate(0deg)"}`,
            transition: "transform 0.3s",
            cursor:"pointer"
        }}
        src={ArrowDown} />
      </div>
      <SimpleBar
        forceVisible="y"
        style={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          width: `${width != undefined ? width : "100%"} `,
          height: `${isOpen == true ? heightBox : "0px"}`,
          padding: `${isOpen == true ? "0px 10px 10px 10px" : "0px 10px 0px 10px" }`,
          top: "38px",
          backgroundColor: `${
            backgroundColor != undefined ? backgroundColor : "#fff"
          }`,
          borderRadius: "0px 0px 10px 10px",
          transition: "height 0.3s",
        }}
      >
        {data !== undefined &&
          data.map((item, index) => {
            return (
              <div
              onClick={() => itemClick(item.key)}
                style={{
                  display: "flex",
                  width: "100%",
                  height: "auto",
                  marginTop:"10px"
                }}
              >
                <p
                  style={{
                    fontFamily: "IRANSansFaNum",
                    fontSize: "16px",
                    fontWeight: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#656565",
                    cursor:"pointer"
                  }}
                >
                  {item.key}
                </p>
              </div>
            );
          })}
      </SimpleBar>
    </div>
  );
}

export default SelectBox;
