const AddTagReducer = (state = [] , action) =>{
    switch(action.type){
        case "ADD_TAG":
        return[ 
            ...state,
            {
                Tags : action.payload
            },
            
        ]
        default: 
        
        return state
    }
}
export default AddTagReducer;