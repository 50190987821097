import React, { useEffect } from "react";
import "../../assets/styles/VisitorStatisticsStyle/VisitorStatisticsSites.css";
import { convertObjToArray } from "../../helper/Helper";
import axios from "../../js/Axios/Axios";
import { useState } from "react";
import SimpleBar from "simplebar-react";
export default function VisitorStatisticsSites() {
  const [data, setData] = useState([]);
  const [witchData, setWitchData] = useState(null);
  useEffect(() => {
    axios
      .get("/visitInfo/referrerReport")
      .then((res) => {
        console.log(res);
        setData(convertObjToArray(res.data.data));
        console.log(data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  console.log(data);

  return (
    <div className="visitor-statistics-sites">
      <div className="visitor-statistics-sites-title-box">
        <p>بیشترین ارجاع دهنده</p>
      </div>
      <div className="visitor-statistics-sites-details">
        <div className="visitor-statistics-words-top-bar">
          <div className="visitor-statistics-words-top-bar-hashtag">
            <p>#</p>
          </div>
          <div className="visitor-statistics-words-top-bar-word">
            <p>لینک</p>
          </div>
          <div className="visitor-statistics-words-top-bar-count">
            <p>تعداد تکرار</p>
          </div>
        </div>
        <SimpleBar
          forceVisible="y"
          className="visitor-statistics-words-item-box"
        >
          {data.map((item, index) => {
            return (
              <div key={index} className="visitor-statistics-words-item-1">
                <div className="visitor-statistics-words-item-number">
                  <p>{index + 1}</p>
                </div>
                <div className="visitor-statistics-words-item-word">
                  <p>{item.value.referrer_url}</p>
                </div>
                <div className="visitor-statistics-words-item-count">
                  <p>{item.value.count_entry}</p>
                </div>
              </div>
            );
          })}
        </SimpleBar>
      </div>
    </div>
  );
}
