import React, { useState, useEffect } from "react";
import "../../assets/styles/SimpleProductStyle/SimpleProduct.css";
import InputComponent from "../TextInputComponent/TextInputComponent";
import { useSelector, useDispatch } from "react-redux";
import { SendSimpleProductPrice, ShowAlert } from "../../action/index";
import {
  ProductPriceType,
  ClearSimplePrice,
  ClearVariablePrice,
} from "../../action/index";
import VariableProductPrice from "../VariableProductPrice/VariableProductPrice";
import useForceUpdate from "use-force-update";
import { isObjectEmpty } from "../../helper/Helper";
import { useLocation } from "react-router-dom";
function SimpleProduct() {
  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  const location = useLocation();
  const ProductInfoForEdit = useSelector((state) => state.ProductInfoForEdit);
  const Error = useSelector((state) => state.Error);
  const [VariableProduct, setVariableProduct] = useState(0); // for showing variable or simple
  const [Manage, setManage] = useState(false);
  const [Avalabel, setAvalabel] = useState(true);
  const [NotAvalabel, setNotAvalabel] = useState(false);
  const [buttonName, setButtonName] = useState("افزودن متغییر");
  const [situation, setSituation] = useState(null);
  const [errors, setErrors] = useState({
    price: false,
    weight: false,
    length: false,
    height: false,
    width: false,
    min_order: false,
    max_order: false,
  });
  const [productPrice, setProductPrice] = useState({
    product_id: null,
    key: null,
    main_value: null,
    alternative_value: null,
    available_type: "همیشه موجود",
    price: null,
    discount: null,
    min_order: null,
    max_order: null,
    available_count: null,
    alter_threshold: null,
    weight: null,
    length: null,
    height: null,
    width: null,
  });
  useEffect(() => {
    if (productPrice.price !== null && productPrice.price !== "") {
      setSituation("simple");
    } else if (productPrice.price == null || productPrice.price == "") {
      setSituation(null);
    }
  }, [productPrice.price]);
  const Situation = (Situation) => {
    if (Situation == "variable") {
      setSituation("variable");
    } else {
      setSituation(null);
    }
  };

  useEffect(() => {
    if (
      !isObjectEmpty(ProductInfoForEdit) &&
      (location.pathname === "/AllProductUpdate" ||
        location.pathname === "/AllDraftProductUpdate")
    ) {
      if (
        ProductInfoForEdit.state.productPrices.length !== 0 &&
        ProductInfoForEdit.state.productPrices[0].key !== null
      ) {
        console.log(ProductInfoForEdit);
        setVariableProduct(1);
        forceUpdate();
      } else if (
        ProductInfoForEdit.state.productPrices.length === 1 &&
        ProductInfoForEdit.state.productPrices[0].key == null
      ) {
        setVariableProduct(0);
        setSituation("simple");
        ProductInfoForEdit.state.productPrices.map((item) => {
          setProductPrice((prev) => {
            return {
              product_id: item.product_id,
              key: item.key,
              main_value: item.main_value,
              alternative_value: item.alternative_value,
              available_type: item.available_type,
              price: item.price,
              discount: item.discount,
              min_order: item.min_order,
              max_order: item.max_order,
              available_count: item.available_count,
              alter_threshold: item.alter_threshold,
              weight: item.weight,
              length: item.length,
              height: item.height,
              width: item.width,
            };
          });
          if (item.available_type == "مدیریت موجودی") {
            setManage(true);
            setAvalabel(false);
            setNotAvalabel(false);
          } else if (item.available_type == "ناموجود") {
            setNotAvalabel(true);
            setManage(false);
            setAvalabel(false);
          } else if (item.available_type == "همیشه موجود") {
            setAvalabel(true);
            setNotAvalabel(false);
            setManage(false);
          }
        });
      }
    }
  }, [ProductInfoForEdit]);

  useEffect(() => {
    if (
      location.pathname === "/AllProductUpdate" ||
      location.pathname === "/AllDraftProductUpdate"
    ) {
      dispatch(SendSimpleProductPrice(productPrice));
    }
  }, [productPrice]);

  useEffect(() => {
    if (
      Error.height === true &&
      Error.length === true &&
      Error.price === true &&
      Error.weight === true &&
      Error.width === true &&
      Error.weight === true &&
      Error.width === true &&
      Error.min_order === true &&
      Error.max_order === true
    ) {
      setErrors((prev) => {
        prev.price = true;
        prev.height = true;
        prev.length = true;
        prev.weight = true;
        prev.width = true;
        prev.max_order = true;
        prev.min_order = true;
        forceUpdate();
        return prev;
      });
    }
  }, [Error]);
  // const enterPriceClick = (e) => {
  //   if (e.key  === "Enter") {

  //   }
  // }
  const ProductPriceClick = () => {
    if (productPrice.price === null || productPrice.price === "") {
      setErrors((prev) => {
        prev.price = true;
        forceUpdate();
        return prev;
      });
    }
    if (productPrice.height === null || productPrice.height === "") {
      setErrors((prev) => {
        prev.height = true;
        forceUpdate();
        return prev;
      });
    }
    if (productPrice.length === null || productPrice.length === "") {
      setErrors((prev) => {
        prev.length = true;
        forceUpdate();
        return prev;
      });
    }
    if (productPrice.weight === null || productPrice.weight === "") {
      setErrors((prev) => {
        prev.weight = true;
        forceUpdate();
        return prev;
      });
    }
    if (productPrice.width === null || productPrice.width === "") {
      setErrors((prev) => {
        prev.width = true;
        forceUpdate();
        return prev;
      });
    }
    if (productPrice.max_order === null || productPrice.max_order === "") {
      setErrors((prev) => {
        prev.max_order = true;
        forceUpdate();
        return prev;
      });
    }
    if (productPrice.min_order === null || productPrice.min_order === "") {
      setErrors((prev) => {
        prev.min_order = true;
        forceUpdate();
        return prev;
      });
    }
    if (
      productPrice.price != null &&
      productPrice.price != "" &&
      productPrice.weight != null &&
      productPrice.weight != "" &&
      productPrice.width != null &&
      productPrice.width != "" &&
      productPrice.height != null &&
      productPrice.height != "" &&
      productPrice.length != null &&
      productPrice.length != "" &&
      productPrice.max_order != null &&
      productPrice.max_order != "" &&
      productPrice.min_order != null &&
      productPrice.min_order != ""
    ) {
      if (buttonName === "افزودن متغییر") {
        dispatch(SendSimpleProductPrice(productPrice));
        let price = document.getElementById("Simpleprice");
        price.disabled = true;
        let discount = document.getElementById("Simplediscount");
        discount.disabled = true;
        let min_order = document.getElementById("Simplemin_order");
        min_order.disabled = true;
        let max_order = document.getElementById("Simplemax_orderr");
        max_order.disabled = true;
        let available_count = document.getElementById("Simpleavailable_count");
        available_count.disabled = true;
        let alter_threshold = document.getElementById("Simplealter_thresholdd");
        alter_threshold.disabled = true;
        let weight = document.getElementById("Simpleweight");
        weight.disabled = true;
        let length = document.getElementById("Simplelength");
        length.disabled = true;
        let height = document.getElementById("Simpleheight");
        height.disabled = true;
        let width = document.getElementById("Simplewidthh");
        width.disabled = true;
        let SimpleNotAvalabel = document.getElementById("SimpleNotAvalabel");
        SimpleNotAvalabel.disabled = true;
        let SimpleAvalable = document.getElementById("SimpleAvalable");
        SimpleAvalable.disabled = true;
        let SimpleManage = document.getElementById("SimpleManage");
        SimpleManage.disabled = true;
        setButtonName("ویرایش متغیر");
      } else if (buttonName === "ویرایش متغیر") {
        let price = document.getElementById("Simpleprice");
        price.disabled = false;
        let discount = document.getElementById("Simplediscount");
        discount.disabled = false;
        let min_order = document.getElementById("Simplemin_order");
        min_order.disabled = false;
        let max_order = document.getElementById("Simplemax_orderr");
        max_order.disabled = false;
        let available_count = document.getElementById("Simpleavailable_count");
        available_count.disabled = false;
        let alter_threshold = document.getElementById("Simplealter_thresholdd");
        alter_threshold.disabled = false;
        let weight = document.getElementById("Simpleweight");
        weight.disabled = false;
        let length = document.getElementById("Simplelength");
        length.disabled = false;
        let height = document.getElementById("Simpleheight");
        height.disabled = false;
        let width = document.getElementById("Simplewidthh");
        width.disabled = false;
        let SimpleNotAvalabel = document.getElementById("SimpleNotAvalabel");
        SimpleNotAvalabel.disabled = false;
        let SimpleAvalable = document.getElementById("SimpleAvalable");
        SimpleAvalable.disabled = false;
        let SimpleManage = document.getElementById("SimpleManage");
        SimpleManage.disabled = false;
        setButtonName("ثبت ویرایش");
      } else if (buttonName === "ثبت ویرایش") {
        dispatch(SendSimpleProductPrice(productPrice));
        let price = document.getElementById("Simpleprice");
        price.disabled = true;
        let discount = document.getElementById("Simplediscount");
        discount.disabled = true;
        let min_order = document.getElementById("Simplemin_order");
        min_order.disabled = true;
        let max_order = document.getElementById("Simplemax_orderr");
        max_order.disabled = true;
        let available_count = document.getElementById("Simpleavailable_count");
        available_count.disabled = true;
        let alter_threshold = document.getElementById("Simplealter_thresholdd");
        alter_threshold.disabled = true;
        let weight = document.getElementById("Simpleweight");
        weight.disabled = true;
        let length = document.getElementById("Simplelength");
        length.disabled = true;
        let height = document.getElementById("Simpleheight");
        height.disabled = true;
        let width = document.getElementById("Simplewidthh");
        width.disabled = true;
        let SimpleNotAvalabel = document.getElementById("SimpleNotAvalabel");
        SimpleNotAvalabel.disabled = true;
        let SimpleAvalable = document.getElementById("SimpleAvalable");
        SimpleAvalable.disabled = true;
        let SimpleManage = document.getElementById("SimpleManage");
        SimpleManage.disabled = true;
        setButtonName("ویرایش متغیر");
      }
    } else {
      let text = `برای افزودن متغیر فیلد های 
      ${productPrice.price == null || productPrice.price == "" ? "قیمت و" : ""} 
      ${
        productPrice.max_order == null || productPrice.max_order == ""
          ? "حداکثر سفارش و"
          : ""
      } 
      ${
        productPrice.min_order == null || productPrice.min_order == ""
          ? "حداقل سفارش و"
          : ""
      } 
      ${
        productPrice.height == null || productPrice.height == ""
          ? "ارتفاع و"
          : ""
      } 
      ${
        productPrice.length == null || productPrice.length == "" ? "طول و" : ""
      } 
      ${
        productPrice.weight == null || productPrice.weight == "" ? "وزن و" : ""
      } 
      ${productPrice.width == null || productPrice.width == "" ? "عرض" : ""}
      الزامی است . 
      `;
      dispatch(ShowAlert(true, text));
    }
  };
  const ManageClick = (manage) => {
    setManage(true);
    setProductPrice((prev) => {
      return {
        product_id: prev.product_id,
        key: prev.key,
        main_value: prev.main_value,
        alternative_value: prev.alternative_value,
        available_type: manage,
        price: prev.price,
        discount: prev.discount,
        min_order: prev.min_order,
        max_order: prev.max_order,
        available_count: prev.available_count,
        alter_threshold: prev.alter_threshold,
        weight: prev.weight,
        length: prev.length,
        height: prev.height,
        width: prev.width,
      };
    });
    forceUpdate();
    setAvalabel(false);
    setNotAvalabel(false);
  };

  const AvalabelClick = (manage) => {
    setAvalabel(true);
    let min_order = document.getElementById("Simplemin_order");
    min_order.value = null;
    let max_order = document.getElementById("Simplemax_orderr");
    max_order.value = null;
    let available_count = document.getElementById("Simpleavailable_count");
    available_count.value = null;
    let alter_threshold = document.getElementById("Simplealter_thresholdd");
    alter_threshold.value = null;
    setProductPrice((prev) => {
      return {
        product_id: prev.product_id,
        key: prev.key,
        main_value: prev.main_value,
        alternative_value: prev.alternative_value,
        available_type: manage,
        price: prev.price,
        discount: prev.discount,
        min_order: null,
        max_order: null,
        available_count: null,
        alter_threshold: null,
        weight: prev.weight,
        length: prev.length,
        height: prev.height,
        width: prev.width,
      };
    });
    forceUpdate();
    setManage(false);
    setNotAvalabel(false);
  };

  const NotAvalabelClick = (manage) => {
    setNotAvalabel(true);
    setProductPrice((prev) => {
      return {
        product_id: prev.product_id,
        key: prev.key,
        main_value: prev.main_value,
        alternative_value: prev.alternative_value,
        available_type: manage,
        price: prev.price,
        discount: prev.discount,
        min_order: prev.min_order,
        max_order: prev.max_order,
        available_count: prev.available_count,
        alter_threshold: prev.alter_threshold,
        weight: prev.weight,
        length: prev.length,
        height: prev.height,
        width: prev.width,
      };
    });
    forceUpdate();
    setAvalabel(false);
    setManage(false);
  };
  const setTrue = () => {
    if (Manage === true) {
      return "simpleProductBlueToggleManage";
    } else if (Avalabel === true) {
      return "simpleProductBlueToggleAvalable";
    } else if (NotAvalabel === true) {
      return "simpleProductBlueToggleNotAvalable";
    } else {
      return "simpleProductBlueToggle";
    }
  };
  const changeTovariableProductPrice = () => {
    if (situation === null || situation === "variable") {
      setVariableProduct(1);
      dispatch(ClearSimplePrice());
      forceUpdate();
    }
  };
  const changeToSimpleProductPrice = () => {
    if (situation === null || situation === "simple") {
      setVariableProduct(0);
      dispatch(ClearVariablePrice());
      forceUpdate();
    }
  };
  useEffect(() => {
    dispatch(ProductPriceType(VariableProduct));
  }, [VariableProduct]);

  return (
    <div className="MahsoolRightBox2">
      <div className="MahsoolRightBox2tittleBox">
        <button
          onClick={() => changeToSimpleProductPrice()}
          className="MahsoolRighttBox2tittleButton"
        >
          <p
            className={
              VariableProduct === 0
                ? "MahsoolRighttBox2tittle"
                : "MahsoolRighttBox2tittleAfter"
            }
          >
            محصول ساده
          </p>
        </button>
        <button
          onClick={() => changeTovariableProductPrice()}
          className="MahsoolRighttBox2tittle2Button"
        >
          <p
            className={
              VariableProduct === 0
                ? "MahsoolRighttBox2tittle2"
                : "MahsoolRighttBox2tittle2After"
            }
          >
            محصول متغییر
          </p>
        </button>
      </div>
      {VariableProduct === 0 ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <div className="MahsoolRightUpInputsBox">
            <InputComponent
              value={productPrice.price}
              width="45%"
              height="44px"
              id={"Simpleprice"}
              placeholder="قیمت (تومان)"
              borderColor={errors.price === true ? "#F23B3B" : "#dcdcdc"}
              inputType="input"
              type="number"
              onchange={(e) => {
                setProductPrice((prev) => {
                  return {
                    product_id: prev.product_id,
                    key: prev.key,
                    main_value: prev.main_value,
                    alternative_value: prev.alternative_value,
                    available_type: prev.available_type,
                    price: e.target.value,
                    discount: prev.discount,
                    min_order: prev.min_order,
                    max_order: prev.max_order,
                    available_count: prev.available_count,
                    alter_threshold: prev.alter_threshold,
                    weight: prev.weight,
                    length: prev.length,
                    height: prev.height,
                    width: prev.width,
                  };
                });
                setErrors((prev) => {
                  prev.price = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
          </div>
          <div className={"MahsoolRightUpInputsBox2"}>
            <InputComponent
              value={productPrice.discount}
              width="45%"
              height="44px"
              id={"Simplediscount"}
              placeholder="(تومان) تخفیف"
              borderColor="#dcdcdc"
              inputType="input"
              type="number"
              onchange={(e) => {
                setProductPrice((prev) => {
                  return {
                    product_id: prev.product_id,
                    key: prev.key,
                    main_value: prev.main_value,
                    alternative_value: prev.alternative_value,
                    available_type: prev.available_type,
                    price: prev.price,
                    discount: e.target.value,
                    min_order: prev.min_order,
                    max_order: prev.max_order,
                    available_count: prev.available_count,
                    alter_threshold: prev.alter_threshold,
                    weight: prev.weight,
                    length: prev.length,
                    height: prev.height,
                    width: prev.width,
                  };
                });
              }}
            />

            <InputComponent
              value={productPrice.min_order}
              // disabled={
              //   productPrice.available_type === "همیشه موجود" ? true : false
              // }
              width="20%"
              height="44px"
              id={"Simplemin_order"}
              placeholder=" حداقل سفارش"
              borderColor={errors.min_order === true ? "#F23B3B" : "#dcdcdc"}
              inputType="input"
              type="number"
              onchange={(e) => {
                setProductPrice((prev) => {
                  return {
                    product_id: prev.product_id,
                    key: prev.key,
                    main_value: prev.main_value,
                    alternative_value: prev.alternative_value,
                    available_type: prev.available_type,
                    price: prev.price,
                    discount: prev.discount,
                    min_order: e.target.value,
                    max_order: prev.max_order,
                    available_count: prev.available_count,
                    alter_threshold: prev.alter_threshold,
                    weight: prev.weight,
                    length: prev.length,
                    height: prev.height,
                    width: prev.width,
                  };
                });
                setErrors((prev) => {
                  prev.min_order = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />

            <InputComponent
              value={productPrice.max_order}
              // disabled={
              //   productPrice.available_type === "همیشه موجود" ? true : false
              // }
              width="20%"
              height="44px"
              id={"Simplemax_orderr"}
              placeholder="حداکثر سفارش"
              borderColor={errors.max_order === true ? "#F23B3B" : "#dcdcdc"}
              inputType="input"
              type="number"
              onchange={(e) => {
                setProductPrice((prev) => {
                  return {
                    product_id: prev.product_id,
                    key: prev.key,
                    main_value: prev.main_value,
                    alternative_value: prev.alternative_value,
                    available_type: prev.available_type,
                    price: prev.price,
                    discount: prev.discount,
                    min_order: prev.min_order,
                    max_order: e.target.value,
                    available_count: prev.available_count,
                    alter_threshold: prev.alter_threshold,
                    weight: prev.weight,
                    length: prev.length,
                    height: prev.height,
                    width: prev.width,
                  };
                });
                setErrors((prev) => {
                  prev.max_order = false;
                  forceUpdate();
                  return prev;
                });
              }}
            />
          </div>
          <div className="MahsoolRightMiddleBox">
            <div className="MahsoolRightMiddleBoxTittleBox">
              <p className="MahsoolRightMiddleBoxTittle">وزن و ابعاد محصول</p>
            </div>
            <div className="MahsoolRightMiddleBoxInputsBox">
              <InputComponent
                value={productPrice.weight}
                width="20%"
                height="44px"
                id={"Simpleweight"}
                placeholder="وزن"
                borderColor={errors.weight === true ? "#F23B3B" : "#dcdcdc"}
                inputType="input"
                type="number"
                onchange={(e) => {
                  setProductPrice((prev) => {
                    return {
                      product_id: prev.product_id,
                      key: prev.key,
                      main_value: prev.main_value,
                      alternative_value: prev.alternative_value,
                      available_type: prev.available_type,
                      price: prev.price,
                      discount: prev.discount,
                      min_order: prev.min_order,
                      max_order: prev.max_order,
                      available_count: prev.available_count,
                      alter_threshold: prev.alter_threshold,
                      weight: e.target.value,
                      length: prev.length,
                      height: prev.height,
                      width: prev.width,
                    };
                  });
                  setErrors((prev) => {
                    prev.weight = false;
                    forceUpdate();
                    return prev;
                  });
                }}
              />

              <InputComponent
                value={productPrice.height}
                width="20%"
                height="44px"
                id={"Simpleheight"}
                borderColor={errors.height === true ? "#F23B3B" : "#dcdcdc"}
                placeholder="ارتفاع"
                inputType="input"
                type="number"
                onchange={(e) => {
                  setProductPrice((prev) => {
                    return {
                      product_id: prev.product_id,
                      key: prev.key,
                      main_value: prev.main_value,
                      alternative_value: prev.alternative_value,
                      available_type: prev.available_type,
                      price: prev.price,
                      discount: prev.discount,
                      min_order: prev.min_order,
                      max_order: prev.max_order,
                      available_count: prev.available_count,
                      alter_threshold: prev.alter_threshold,
                      weight: prev.weight,
                      length: prev.length,
                      height: e.target.value,
                      width: prev.width,
                    };
                  });
                  setErrors((prev) => {
                    prev.height = false;
                    forceUpdate();
                    return prev;
                  });
                }}
              />

              <InputComponent
                value={productPrice.length}
                width="20%"
                height="44px"
                id={"Simplelength"}
                placeholder="طول"
                borderColor={errors.length === true ? "#F23B3B" : "#dcdcdc"}
                inputType="input"
                type="number"
                onchange={(e) => {
                  setProductPrice((prev) => {
                    return {
                      product_id: prev.product_id,
                      key: prev.key,
                      main_value: prev.main_value,
                      alternative_value: prev.alternative_value,
                      available_type: prev.available_type,
                      price: prev.price,
                      discount: prev.discount,
                      min_order: prev.min_order,
                      max_order: prev.max_order,
                      available_count: prev.available_count,
                      alter_threshold: prev.alter_threshold,
                      weight: prev.weight,
                      length: e.target.value,
                      width: prev.width,
                      height: prev.height,
                    };
                  });
                  setErrors((prev) => {
                    prev.length = false;
                    forceUpdate();
                    return prev;
                  });
                }}
              />

              <InputComponent
                value={productPrice.width}
                width="20%"
                height="44px"
                id={"Simplewidthh"}
                placeholder="عرض"
                borderColor={errors.width === true ? "#F23B3B" : "#dcdcdc"}
                inputType="input"
                type="number"
                onchange={(e) => {
                  setProductPrice((prev) => {
                    return {
                      product_id: prev.product_id,
                      key: prev.key,
                      main_value: prev.main_value,
                      alternative_value: prev.alternative_value,
                      available_type: prev.available_type,
                      price: prev.price,
                      discount: prev.discount,
                      min_order: prev.min_order,
                      max_order: prev.max_order,
                      available_count: prev.available_count,
                      alter_threshold: prev.alter_threshold,
                      weight: prev.weight,
                      length: prev.length,
                      height: prev.height,
                      width: e.target.value,
                    };
                  });
                  setErrors((prev) => {
                    prev.width = false;
                    forceUpdate();
                    return prev;
                  });
                }}
              />
            </div>
            <div className="MahsoolRightMiddleBoxTittleBox2">
              <p className="MahsoolRightMiddleBoxTittle2">وضعیت انبارداری</p>
            </div>
            <div className="MahsoolRightMiddleBoxInputsBox2">
              <div className="MahsoolRightMiddleBoxInputsBox2ToggleBox">
                <div className="SimplePRoductToggleBody">
                  <div className={setTrue()}></div>

                  <button
                    id={"SimpleNotAvalabel"}
                    onClick={() => NotAvalabelClick("ناموجود")}
                    className={
                      NotAvalabel === true
                        ? "SimplePRoductToggleTittle3After"
                        : "SimplePRoductToggleTittle3"
                    }
                  >
                    موجود نمی باشد
                  </button>

                  <button
                    id={"SimpleAvalable"}
                    onClick={() => AvalabelClick("همیشه موجود")}
                    className={
                      Avalabel === true
                        ? "SimplePRoductToggleTittle2After"
                        : "SimplePRoductToggleTittle2"
                    }
                  >
                    همیشه موجود
                  </button>

                  <button
                    id={"SimpleManage"}
                    onClick={() => ManageClick("مدیریت موجودی")}
                    className={
                      Manage === true
                        ? "SimplePRoductToggleTittle1After"
                        : "SimplePRoductToggleTittle1"
                    }
                  >
                    مدیریت
                  </button>
                </div>
              </div>

              <div className="MahsoolRightMiddleBoxInputsBox2InputBox">
                <InputComponent
                  value={productPrice.available_count}
                  disabled={
                    productPrice.available_type === "همیشه موجود" ? true : false
                  }
                  width="20%"
                  height="44px"
                  id={"Simpleavailable_count"}
                  placeholder="موجودی"
                  borderColor="#dcdcdc"
                  inputType="input"
                  type="number"
                  onchange={(e) => {
                    setProductPrice((prev) => {
                      return {
                        product_id: prev.product_id,
                        key: prev.key,
                        main_value: prev.main_value,
                        alternative_value: prev.alternative_value,
                        available_type: prev.available_type,
                        price: prev.price,
                        discount: prev.discount,
                        min_order: prev.min_order,
                        max_order: prev.max_order,
                        available_count: e.target.value,
                        alter_threshold: prev.alter_threshold,
                        weight: prev.weight,
                        length: prev.length,
                        height: prev.height,
                        width: prev.width,
                      };
                    });
                  }}
                />

                <InputComponent
                  value={productPrice.alter_threshold}
                  width="20%"
                  height="44px"
                  disabled={
                    productPrice.available_type === "همیشه موجود" ? true : false
                  }
                  id={"Simplealter_thresholdd"}
                  placeholder="آستانه هشدار"
                  borderColor="#dcdcdc"
                  inputType="input"
                  type="number"
                  onchange={(e) => {
                    setProductPrice((prev) => {
                      return {
                        product_id: prev.product_id,
                        key: prev.key,
                        main_value: prev.main_value,
                        alternative_value: prev.alternative_value,
                        available_type: prev.available_type,
                        price: prev.price,
                        discount: prev.discount,
                        min_order: prev.min_order,
                        max_order: prev.max_order,
                        available_count: prev.available_count,
                        alter_threshold: e.target.value,
                        weight: prev.weight,
                        length: prev.length,
                        height: prev.height,
                        width: prev.width,
                      };
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="MahsoolRightEndBox">
            <button
              id={"AddPriceButton"}
              onClick={() => ProductPriceClick()}
              // onKeyDown={(e)=>enterPriceClick(e)}
              // disabled={
              //   productPrice.price != null &&
              //   productPrice.price != "" &&
              //   productPrice.weight != null &&
              //   productPrice.weight != "" &&
              //   productPrice.width != null &&
              //   productPrice.width != "" &&
              //   productPrice.height != null &&
              //   productPrice.height != "" &&
              //   productPrice.length != null &&
              //   productPrice.length != ""
              //     ? false
              //     : true
              // }
              // className={
              //   productPrice.price != null &&
              //   productPrice.price != "" &&
              //   productPrice.weight != null &&
              //   productPrice.weight != "" &&
              //   productPrice.width != null &&
              //   productPrice.width != "" &&
              //   productPrice.height != null &&
              //   productPrice.height != "" &&
              //   productPrice.length != null &&
              //   productPrice.length != ""
              //     ? "MahsoolRightEndBoxButtonBlue"
              //     : "MahsoolRightEndBoxButton"
              // }
              className="MahsoolRightEndBoxButtonBlue"
            >
              {buttonName}
            </button>
          </div>
        </div>
      ) : (
        <VariableProductPrice Situation={Situation} />
      )}
    </div>
  );
}
export default SimpleProduct;
