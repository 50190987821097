import React, { useEffect, useState, useMemo, useRef } from "react";
import "../../assets/styles/ShowProductInStoreStyle/ShowProductInStore.css";
import Tik from "../../assets/icons/tik.svg";
import Modal from "@mui/material/Modal";
import { useSelector, useDispatch } from "react-redux";
import useForceUpdate from "use-force-update";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { isObjectEmpty } from "../../helper/Helper";

export default function ShowProductInStore(props) {
  const { data } = props;
  const forceUpdate = useForceUpdate();
  const [modal, setModal] = useState(false);
  const [imgSelected, setImageSelected] = useState(0);
  const [mainProp, setMainProp] = useState(false);
  const [swiper, setSwiper] = useState(null);
  const Product_info = useSelector((state) => state.ProductInfo);
  const CategoriesInfo = useSelector((state) => state.SendCategoryNameId);
  const productProperty = useSelector((state) => state.SendPropertyValueId);
  const productReview = useSelector((state) => state.SendProductReview);
  const productgalleryinfo = useSelector(
    (state) => state.SendProductGalleryInfo
  );
  const simpleProductPrice = useSelector(
    (state) => state.SendSimpleProductPrice
  );
  const variableProductPrice = useSelector(
    (state) => state.SendVariableProductPrice
  );

  const [price, setPrice] = useState(null);
  const [checkedInput, setCheckedInput] = useState(0);
  const [moreIntroduction, setMoreIntroduction] = useState(false);
  const [moreButtonName, setMoreButtonName] = useState("بیشتر");

  function isEmpty(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  // if (price === null && !isEmpty(data)) {
  //   setPrice(data.productPrices[0].price);
  // }

  const setColorHandelChange = (e, item, index) => {
    setPrice(item.price);
    setCheckedInput(index);
  };
  const openMainProp = () => {
    setMainProp(!mainProp);
    forceUpdate();
  };

  const moreIntroductionClick = () => {
    setMoreIntroduction(!moreIntroduction);
    if (moreButtonName === "بیشتر") {
      setMoreButtonName("بستن");
    } else if (moreButtonName === "بستن") {
      setMoreButtonName("بیشتر");
    }
  };

  // set scroll for productReviews
  function setScroll(index) {
    const productReview = document.getElementById("productReview");
    let location = document.getElementById(`productReview${index}`);
    productReview.scrollTop = getOffset(location).top - 962;
  }

  function getOffset(el) {
    var _x = 0;
    var _y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: _y, left: _x };
  }
  const openModalClick = () => {
    setModal(true);
  };
  const chooseImg = (index) => {
    setImageSelected(index);
    swiper.slideTo(index);
    forceUpdate();
  };
  const swiperOnChange = (e) => {
    setImageSelected(e.activeIndex);
  };
  const closeModal = () => {
    setModal(false);
    setImageSelected(0);
  };
  return (
    <div className="ShowStoreAll">
      <Modal
        onBackdropClick={() => closeModal()}
        className="show-store-modal-all"
        open={modal}
      >
        <div className="show-store-modal-box">
          <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={50}
            slidesPerView={1}
            // pagination={{}}
            navigation={true}
            onSwiper={setSwiper}
            onSlideChange={(e) => swiperOnChange(e)}
            className="swiper-show-product"
          >
            {productgalleryinfo.productGalleries.length != 0 &&
              productgalleryinfo.productGalleries.map((item, index) => {
                return (
                  <SwiperSlide className="swiper-img-box-show-product">
                    <img
                      className="swiper-image-show-product"
                      src={item.imgLink}
                    />
                  </SwiperSlide>
                );
              })}
          </Swiper>
          <div className="show-product-modal-image-box-navar">
            {productgalleryinfo.productGalleries.length != 0 &&
              productgalleryinfo.productGalleries.map((item, index) => {
                return (
                  <div
                    onClick={() => chooseImg(index)}
                    className={
                      imgSelected === index
                        ? "show-product-navar-image-box-2"
                        : "show-product-navar-image-box"
                    }
                  >
                    <img
                      src={item.imgLink}
                      className="show-product-navar-image"
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </Modal>
      <div className="ShowStoreAllTopBar">
        <p className="ShowStoreAllTopBarPersianTittle">
          {Product_info.Product_info != undefined &&
            Product_info.Product_info.title}
        </p>
        <p className="ShowStoreAllTopBarEnglishTittle">
          {Product_info.Product_info != undefined &&
            Product_info.Product_info.latin_title}
        </p>
      </div>
      <div className="ShowStoreAllProductDescriptionBox">
        <div className="ShowStoreAllProductDescriptionImageBox">
          <div className="ShowStoreAllProductDescriptionImageBoxAllImage">
            {productgalleryinfo.productGalleries.length != 0 &&
              productgalleryinfo.productGalleries.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="ShowStoreAllProductDescriptionImageBoxAllImageBorderBox"
                  >
                    <img
                      onClick={() => openModalClick()}
                      src={item.imgLink}
                      className="ShowStoreAllProductDescriptionImageBoxAllImageBorderBoxImage"
                    />
                  </div>
                );
              })}
          </div>
          {productgalleryinfo.productGalleries.length != 0 &&
            productgalleryinfo.productGalleries.map((item, index) => {
              return (
                item.is_main === true && (
                  <img
                    onClick={() => openModalClick()}
                    src={item.imgLink}
                    className="ShowStoreAllProductDescriptionImageBoxMainImage"
                  />
                )
              );
            })}
        </div>
        <div className="ShowStoreAllProductDescriptionBoxBorderLine" />
        <div className="ShowStoreAllProductDescriptionBoxDescriptionBox">
          <div className="ShowStoreAllProductDescriptionBoxDescriptionTittleBox">
            <p className="ShowStoreAllProductDescriptionBoxDescriptionTittleBoxCountryTittle">
              ساخت کشور :{" "}
              <span>
                {Product_info.Product_info != undefined &&
                  Product_info.Product_info.country}
              </span>
            </p>
            <p className="ShowStoreAllProductDescriptionBoxDescriptionTittleBoxCountryTittle">
              دسته بندی :{" "}
              <span>
                {CategoriesInfo.categorysinfo.length !== 0 &&
                  CategoriesInfo.categorysinfo[
                    CategoriesInfo.categorysinfo.length - 1
                  ].title}
              </span>
            </p>
          </div>
          <div className="ShowStoreAllProductDescriptionBoxDescriptionColorBox">
            {variableProductPrice.variableProductPrice.length != 0 && (
              <>
                <p className="ShowStoreAllProductDescriptionBoxDescriptionColorBoxTittle">
                  انتخاب {variableProductPrice.variableProductPrice[0].key} :
                </p>
                <div className="ShowStoreAllProductDescriptionBoxDescriptionColorBox2">
                  {variableProductPrice.variableProductPrice.length != 0 &&
                    variableProductPrice.variableProductPrice.map(
                      (item, index) => {
                        return (
                          <label
                            style={{ cursor: "pointer" }}
                            key={index}
                            htmlFor={index}
                          >
                            <input
                              style={{ display: "none", cursor: "pointer" }}
                              type="radio"
                              name="ColorInput"
                              id={index}
                              value={item.id}
                              checked={checkedInput === index}
                              onChange={(e) =>
                                setColorHandelChange(e, item, index)
                              }
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "row",
                                minWidth: "50px",
                                width: "auto",
                                height: "auto",
                                marginTop: "10px",
                                marginRight: "35px",
                                // position: "relative",
                                // backgroundColor: "red",
                                borderRadius: "5px",
                                border: `${
                                  checkedInput === index
                                    ? "solid 1px #4990e2"
                                    : "solid 1px #dcdcdc"
                                }`,
                                outline: "none",
                                cursor: "pointer",
                                padding: "5px 15px 5px 15px",
                              }}
                            >
                              {/* <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  width: "100%",
                                  height: "100%",
                                  // backgroundColor: `${item.alternative_value}`,
                                  // opacity: "15%",
                                  borderRadius: "5px",
                                  cursor:"pointer"
                                }}
                              ></div> */}
                              {item.key === "رنگ" && (
                                <div
                                  style={{
                                    display: "flex",
                                    // position: "absolute",
                                    // top: "5px",
                                    // right: "15px",
                                    width: "32px",
                                    height: "24px",
                                    borderRadius: "5px",
                                    backgroundColor: `${item.alternative_value}`,
                                    border: "solid 1px #e0e0e2",
                                    opacity: "100%",
                                    cursor: "pointer",
                                  }}
                                ></div>
                              )}
                              <p
                                style={{
                                  display: "flex",
                                  // position: "absolute",
                                  // right: "55px",
                                  // top: "3px",
                                  marginRight: `${
                                    item.key === "رنگ" && "10px"
                                  }`,
                                  fontFamily: "IRANSansFaNum",
                                  fontSize: "16px",
                                  fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAlign: "left",
                                  color: "#393939",
                                  cursor: "pointer",
                                }}
                              >
                                {item.main_value}
                              </p>
                            </div>
                          </label>
                        );
                      }
                    )}
                </div>
              </>
            )}
          </div>
          <div className="ShowStoreAllProductDescriptionBoxDescriptionBoxPriceBox">
            <div className="ShowStoreAllProductDescriptionBoxDescriptionBoxPriceBoxKeyProperty0">
              <div
                className={
                  mainProp === false
                    ? "ShowStoreAllProductDescriptionBoxDescriptionBoxPriceBoxKeyProperty"
                    : "ShowStoreAllProductDescriptionBoxDescriptionBoxPriceBoxKeyProperty-open"
                }
              >
                <p className="ShowStoreAllProductDescriptionBoxDescriptionBoxPriceBoxKeyPropertyTittle">
                  ویژگی‌های کلیدی :
                </p>
                {productProperty != undefined &&
                  productProperty.productProperties.map((item, index) => {
                    return (
                      <>
                        {item.is_key === true ? (
                          <div
                            key={index}
                            className="ShowStoreAllProductDescriptionBoxDescriptionBoxPriceBoxKeyPropertyTittleItemPropertyBox"
                          >
                            <div
                            style={{display:"flex", alignItems:"center"}}
                            >
                              <div className="ShowStoreAllProductDescriptionBoxDescriptionBoxPriceBoxKeyPropertyTittleItemPropertyBoxCircle" />

                              <p className="ShowStoreAllProductDescriptionBoxDescriptionBoxPriceBoxKeyPropertyTittleItemPropertyBoxCircleTittle">
                                {item.key}
                              </p>
                            </div>
                            <p className="ShowStoreAllProductDescriptionBoxDescriptionBoxPriceBoxKeyPropertyTittleItemPropertyBoxCircleTittle2">
                              {`( ${item.valuetittle} )`}
                            </p>
                          </div>
                        ) : null}
                      </>
                    );
                  })}
              </div>
              {productProperty != undefined &&
                productProperty.productProperties.length != 0 && (
                  <p
                    onClick={() => openMainProp()}
                    className="ShowStoreAllProductDescriptionBoxDescriptionBoxPriceBoxKeyProperty0title"
                  >
                    {mainProp === false ? "بیشتر" : "بستن"}
                  </p>
                )}
            </div>

            {variableProductPrice.variableProductPrice.length != 0 ? (
              <div className="ShowStoreAllProductDescriptionBoxDescriptionBoxPriceBoxPriceBox">
                <div className="ShowStoreAllProductDescriptionBoxDescriptionBoxPriceBoxPriceBoxDiscountBox">
                  <div className="ShowStoreAllProductDescriptionBoxDescriptionBoxPriceBoxPriceBoxDiscountBoxDiscountBorder">
                    {
                      variableProductPrice.variableProductPrice[checkedInput]
                        .price
                    }
                  </div>
                  <div className="ShowStoreAllProductDescriptionBoxDescriptionBoxPriceBoxPriceBoxDiscountBoxDiscountBorderDarsad">
                    {Math.round(
                      (variableProductPrice.variableProductPrice[checkedInput]
                        .discount *
                        100) /
                        variableProductPrice.variableProductPrice[checkedInput]
                          .price
                    )}{" "}
                    %
                  </div>
                </div>
                <div className="ShowStoreAllProductDescriptionBoxDescriptionBoxPriceBoxPriceBoxDPrice">
                  {variableProductPrice != undefined && (
                    <p>
                      <span>
                        {`${
                          variableProductPrice.variableProductPrice[
                            checkedInput
                          ].price -
                          variableProductPrice.variableProductPrice[
                            checkedInput
                          ].discount
                        }  `}
                      </span>
                      تومان
                    </p>
                  )}
                </div>
              </div>
            ) : (
              <div className="ShowStoreAllProductDescriptionBoxDescriptionBoxPriceBoxPriceBox">
                <div className="ShowStoreAllProductDescriptionBoxDescriptionBoxPriceBoxPriceBoxDiscountBox">
                  <div className="ShowStoreAllProductDescriptionBoxDescriptionBoxPriceBoxPriceBoxDiscountBoxDiscountBorder">
                    {simpleProductPrice.simpleProductPrice[0].price}
                  </div>
                  <div className="ShowStoreAllProductDescriptionBoxDescriptionBoxPriceBoxPriceBoxDiscountBoxDiscountBorderDarsad">
                    {Math.round(
                      (simpleProductPrice.simpleProductPrice[0].discount *
                        100) /
                        simpleProductPrice.simpleProductPrice[0].price
                    )}{" "}
                    %
                  </div>
                </div>
                <div className="ShowStoreAllProductDescriptionBoxDescriptionBoxPriceBoxPriceBoxDPrice">
                  {simpleProductPrice != undefined && (
                    <p>
                      <span>
                        {`${
                          simpleProductPrice.simpleProductPrice[0].price -
                          simpleProductPrice.simpleProductPrice[0].discount
                        } `}
                      </span>
                      تومان
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="ShowStoreAllProductDescriptionBoxBorderLine" />
        <div className="ShowStoreAllProductDescriptionBoxIntroduction">
          <div className="ShowStoreAllProductDescriptionBoxIntroductionTittle">
            <p> معرفی محصول :</p>
          </div>
          <p
            className={
              moreIntroduction === false
                ? "ShowStoreAllProductDescriptionBoxIntroductionIntroduction"
                : "ShowStoreAllProductDescriptionBoxIntroductionIntroduction2"
            }
          >
            {Product_info.Product_info != undefined &&
              Product_info.Product_info.product_introduction}
          </p>
          <button
            className="ShowStoreAllProductDescriptionBoxIntroductionButton"
            onClick={() => moreIntroductionClick()}
          >
            {moreButtonName}
          </button>
        </div>
      </div>
      <div className="ShowStoreAllDownBox">
        <div className="ShowStoreAllDownBoxSpecificationsBox">
          <div className="ShowStoreAllDownBoxSpecificationsBoxTittleBox">
            مشخصات کلی
          </div>
          <div className="ShowStoreAllDownBoxSpecificationsBoxScrollBox">
            {productProperty != undefined &&
              productProperty.productProperties.map((item, index) => {
                return item.is_key == 0 ? (
                  <div className="ShowStoreAllDownBoxSpecificationsBoxScrollBoxItemBox">
                    <p className="ShowStoreAllDownBoxSpecificationsBoxScrollBoxItemBoxTittle1">
                      {item.key}
                    </p>
                    <p className="ShowStoreAllDownBoxSpecificationsBoxScrollBoxItemBoxTittle2">
                      {item.valuetittle}
                    </p>
                  </div>
                ) : null;
              })}
          </div>
        </div>
        <div className="ShowStoreAllDownBoxReviwBox">
          <div className="ShowStoreAllDownBoxReviwBoxTopBar">
            <div className="ShowStoreAllDownBoxReviwBoxTopBarTittle">
              بررسی محصول
            </div>
            <div className="ShowStoreAllDownBoxReviwBoxTopBarPreviewLableBox">
              {productReview != undefined &&
                productReview.map((item, index) => {
                  return (
                    <>
                      <a
                        onClick={() => setScroll(index)}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          width: `calc((100% / ${productReview.length}) - 50px)`,
                          height: "auto",
                          TextDecoder: "none",
                        }}
                      >
                        <div className="ShowStoreAllDownBoxReviwBoxTopBarPreviewLableBoxBorderCircle">
                          <div className="ShowStoreAllDownBoxReviwBoxTopBarPreviewLableBoxircle">
                            <img src={Tik} />
                          </div>
                        </div>
                        {item.title != null && (
                          <p
                            style={{
                              fontFamily: "IRANSansFaNum",
                              fontSize: "18px",
                              fontWeight: "normal",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "30px",
                              letterSpacing: "normal",
                              textAlign: "center",
                              color: "#ff5185",
                              cursor: "pointer",
                              marginTop: "10px",
                            }}
                          >
                            {item.title.slice(0, 30) +
                              (item.title.length > 30 ? "..." : "")}
                          </p>
                        )}
                      </a>
                      {productReview.length - 1 != index && (
                        <div
                          style={{
                            display: "flex",
                            width: `calc((100% / ${productReview.length}) - 50px)`,
                            height: "1px",
                            backgroundColor: "#ff5185",
                            cursor: "pointer",
                            marginTop: "20px",
                          }}
                        />
                      )}
                    </>
                  );
                })}
            </div>
          </div>
          <div id="productReview" className="ShowStoreAllDownBoxReviwBox2">
            {productReview != undefined &&
              productReview.map((item, index) => {
                return (
                  <>
                    <div
                      key={index}
                      id={`productReview${index}`}
                      className="ShowStoreAllDownBoxReviwBox3Tittle"
                    >
                      {item.title}
                    </div>
                    <div className="ShowStoreAllDownBoxReviwBox3ReviwBoxImageBox">
                      <div className="ShowStoreAllDownBoxReviwBox3ReviwBox">
                        <p className="ShowStoreAllDownBoxReviwBox3ReviwBoxTittle">
                          {item.description}
                        </p>
                      </div>
                      <div className="ShowStoreAllDownBoxReviwBox3ImageBox">
                        <img
                          style={{
                            display: "flex",
                            width: "175px",
                            height: "175px",
                          }}
                          src={item.imgLink}
                        />
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
